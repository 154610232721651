import { Observable, from } from 'rxjs';

import { DexieService } from './dexieService';
import { GenericClassTranslate } from '../model/genericClass';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { OnlineService } from './online.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ExecutionStatusService {

    constructor(private http: HttpClient,
        private dexieService: DexieService,
        private onlineService: OnlineService) {
    }

    findAll(): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + '/executionStatus/';

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.executionStatus.toArray().timeout(30000));
        }
    }

    saveExecutionStatusToDexie(): Promise<void> {
        return new Promise<void>((resolve) => this.findAll().subscribe((res: GenericClassTranslate[]) => {
            if (res) {
                void this.dexieService.executionStatus.clear().then(() => {
                    res.forEach(type => {
                        void this.dexieService.executionStatus.put(type, type.id).then(() => resolve());
                    });
                });
            }
        }));
    }
}
