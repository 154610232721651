import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Calibration, CalibrationAsLeft, CalibrationFilter, CalibrationStatus } from 'src/app/model/calibration';
import { Location, ViewportScroller } from '@angular/common';
import { Router, Scroll } from '@angular/router';
import { Subject, merge } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { CalibrationDataSource } from 'src/app/model/calibrationDataSource';
import { CalibrationEditGenerateReportComponent } from '../calibration-edit/calibration-edit-generate-report.component';
import { CalibrationService } from 'src/app/services/calibration.service';
import { CheckWarnsErrorsComponent } from '../../shared/check-warns-errors/check-warns-errors.component';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { GenericWarnError } from 'src/app/model/genericClass';
import { LangFlag } from 'src/app/services/translation.service';
import { LanguageDialogComponent } from '../../shared/language-dialog/language-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectGroupToCloneComponent } from '../../shared/select-group-to-clone/select-group-to-clone.component';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StringUtils } from 'src/app/utils/stringUtils';
import { ThemeService } from 'src/app/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { saveAs } from 'file-saver';
import { GenericCopyMasive } from '../../../model/genericClass';

@Component({
  selector: 'app-calibration-list',
  templateUrl: './calibration-list.component.html'
})
export class CalibrationListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  currentUser: User;

  dataSource: CalibrationDataSource;
  filter: CalibrationFilter = new CalibrationFilter();

  public checkedItems = new Map<Calibration, boolean>();

  displayedColumns: string[] = [
    'checkbox', 'projectNo', 'certificateNum', 'client', 'name', 'equipment', 'serialNum', 'status', 'calibrateDate', 'expirationDate',
    'realizationDate', 'download', 'edit', 'delete', 'clone', 'more'
  ];

  hasFiltered = false;
  langs: LangFlag[];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private location: Location,
    private calibrationService: CalibrationService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private themeService: ThemeService,
    private userService: UserService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private viewportScroller: ViewportScroller) {
    this.router.events.pipe(filter(e => e instanceof Scroll)).pipe(takeUntil(this.destroy$)).subscribe((e: Scroll) => {
      setTimeout(() => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
    });
  }

  get pageTitle(): string {
    return this.translate.instant('calibrateEquipmentList.title') as string;
  }

  ngOnInit(): void {
    this.currentUser = this.userService.currentProfile;

    const excludedCols: string[] = [];

    if (!this.currentUser.showClone) {
      excludedCols.push('clone');
    }

    this.displayedColumns = this.displayedColumns.filter(c => !excludedCols.includes(c));

    const filterResult = localStorage.getItem('filter');
    if (filterResult) {
      this.hasFiltered = true;
      this.filter = JSON.parse(filterResult) as CalibrationFilter;
    }
    this.loadCalibrationList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  new(): void {
    let url: string;
    if (this.router.url.includes('calibrates')){
      url = 'calibrates/calibration?id=0'
    } else if (this.router.url.includes('thermal')) {
      url = 'thermal/calibration?id=0';
    }
    void this.router.navigateByUrl(url);
  }

  editRow(id: number): void {
    let url: string;
    if (this.router.url.includes('calibrates')){
      url = `calibrates/calibration?id=${id}`
    } else if (this.router.url.includes('thermal')) {
      url = `thermal/calibration?id=${id}`
    }
    void this.router.navigateByUrl(url);
  }

  deleteRow(id: number): void {
    if (id < 0) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.translate.instant('calibrateEquipmentList.dialog.delete.confirmation') as string
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result === true) {
        this.spinnerService.show();

        this.calibrationService.delete(id).pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.loadCalibrationList();

          this.snackBarService.sendSuccess(this.translate.instant('calibrateEquipmentList.dialog.delete.ok') as string);

          this.spinnerService.hide();
        }, () => {
          this.snackBarService.sendError(this.translate.instant('calibrateEquipmentList.dialog.delete.error') as string);

          this.spinnerService.hide();
        });
      }
    });

  }

  canClone(calibration: Calibration): boolean {
    return calibration?.idStatus === CalibrationStatus.FIRMADO;
  }

  cloneRow(id: number): void {
    const dialogRefSelectGroupToClone = this.dialog.open(SelectGroupToCloneComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRefSelectGroupToClone.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((idGroup: number) => {
      if (idGroup != null) {

        this.spinnerService.show();

        this.calibrationService.checkCopyToGroup(id, idGroup).subscribe((res: GenericWarnError) => {
          this.spinnerService.hide();

          const dialogRef = this.dialog.open(CheckWarnsErrorsComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {
              warns: res.warns,
              errors: res.errors
            }
          });

          dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((canContinue: boolean) => {
            if (canContinue === true) {
              this.spinnerService.show();
              this.calibrationService.copyToGroup(id, idGroup).subscribe(() => {
                this.spinnerService.hide();

                this.snackBarService.sendSuccess(this.translate.instant('calibrateEquipmentEdit.form.clone.ok') as string);
              }, () => {
                this.spinnerService.hide();
                this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.clone.error') as string);
              });
            }
          });
        }, () => {
          this.spinnerService.hide();
          this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.clone.error') as string);
        });
      }
    });
  }

  getThemeIconPath(): string {
    const url = this.router.url;
    return this.themeService.getThemeIconPathSelection(url);
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadCalibrationList())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  doFilter(event: CalibrationFilter): void {
    this.filter = event;

    this.hasFiltered = true;

    this.loadCalibrationList();
  }

  loadCalibrationList(): void {
    this.checkedItems = new Map<Calibration, boolean>();

    this.dataSource = new CalibrationDataSource(this.calibrationService);

    this.filter.sortBy = this.sort.active || 'calibrateDate';
    this.filter.sortDirection = this.sort.direction || 'desc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 20;

    if (this.hasFiltered) {
      this.saveSearchFilter(this.filter);
      this.dataSource.loadCalibration(this.filter);
    }
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  isSelected(item: Calibration): boolean {
    if (item == null) {
      return false;
    }

    return this.checkedItems.get(item);
  }

  showSelectAll(): boolean {
    return this.dataSource.data.length > 0;
  }

  isAllSelected(): boolean {
    return this.dataSource.data.every(item => this.isSelected(item));
  }

  showCheckbox(calibration: Calibration): boolean {
    const allowedStatuses = [CalibrationStatus.EN_EJECUCION, CalibrationStatus.PENDIENTE_FIRMA, CalibrationStatus.FIRMADO];

    return allowedStatuses.includes(calibration.idStatus);
  }

  onCheckItem(event: MatCheckboxChange): void {
    const elem = event.source.value as unknown as Calibration;
    this.checkedItems.set(elem, event.checked);
  }

  onCheckAllItems(event: MatCheckboxChange): void {
    this.dataSource.data.forEach(elem => {
      if (CalibrationStatus.NO_VALIDO !== elem.idStatus) {
        this.checkedItems.set(elem, event.checked);
      }
    });
  }

  showAutomaticSign(): boolean {
    const current = this.currentUser;

    return current.automaticSign;
  }

  automaticSignBulk(): void {

    const checked: Calibration[] = [];

    this.checkedItems.forEach((value, key) => {
      if (value && key.idStatus === CalibrationStatus.PENDIENTE_FIRMA) {
        checked.push(key);
      }
    });

    if (ArrayUtils.isEmpty(checked)) {
      this.snackBarService.sendError(this.translate.instant('calibrateEquipmentList.automatic.sign.bulk.empty') as string);
      this.spinnerService.hide();
      return;
    }

    this.requestLanguage('calibrateEquipmentEdit.dialog.signReport.title', (lang) => {
      const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
        minWidth: '20%',
        maxHeight: '95vh',
        data: {}
      });
      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {

        if (token != null) {
          this.spinnerService.show();

          this.calibrationService.automaticSignBulk(checked.map(c => c.id), token, lang).pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.snackBarService.sendSuccess(this.translate.instant('calibrateEquipmentEdit.form.sign.ok') as string);
            this.downloadCheckedCertificates(checked, lang);
            this.cloneRowMasive(checked.map(c => c.id)).finally(() => {
              this.loadCalibrationList();
            });
            this.spinnerService.hide();
          }, () => {
            this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.sign.error') as string);

            this.spinnerService.hide();
          });
        } else {
          this.spinnerService.hide();
        }
      });

    });

  }

  downloadCertificates(): void {
    this.spinnerService.show();

    const checked: Calibration[] = [];
    let pendiente: boolean;

    this.checkedItems.forEach((value, key) => {
      if (value) {
        checked.push(key);
      }
      if (key.idStatus === CalibrationStatus.PENDIENTE_FIRMA) {
        pendiente = true;
      }
    });

    this.spinnerService.hide();
    if (ArrayUtils.isEmpty(checked)) {
      this.snackBarService.sendError(this.translate.instant('calibrateEquipmentList.automatic.download.bulk.empty') as string);
      return;
    }

    if (pendiente) {
      this.requestLanguage('calibrateEquipmentEdit.dialog.downloadReport.title', (lang) => {
        this.downloadCheckedCertificates(checked, lang);
      });
    } else {
      this.downloadCheckedCertificates(checked);
    }
  }

  downloadPdf(calibration: Calibration): void {
    const errors = this.checkCalibration(calibration);

    if (errors.length !== 0) {
      this.snackBarService.sendError(errors.join('\n'));

      return;
    }

    this.dialog.open(CalibrationEditGenerateReportComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {
        calibration,
        toSign: false
      }
    });
  }

  canDelete(calibration: Calibration): boolean {
    const allowedStatuses = [CalibrationStatus.EN_EJECUCION];

    return allowedStatuses.includes(calibration.idStatus) && +calibration.signedVersion === 0;
  }

  canDownload(calibration: Calibration): boolean {
    const allowedStatusesDownload = [CalibrationStatus.PENDIENTE_FIRMA, CalibrationStatus.FIRMADO];

    return allowedStatusesDownload.includes(calibration.idStatus);
  }

  exportTable(): void {
    this.filter.cols = this.displayedColumns;

    this.spinnerService.show();
    this.calibrationService.getExcel(this.filter).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name = this.pageTitle + '.xlsx';
      saveAs(res, name);
      this.spinnerService.hide();
    }, error => {
      console.error(error);
      this.spinnerService.hide();
    });
  }

  cancel(): void {
    this.location.back();
  }

  highlight(calibration: Calibration): string {
    return this.isCanceled(calibration) ? 'deactivate' : 'action';
  }

  saveSearchFilter(execution: CalibrationFilter): void {
    localStorage.setItem('filter', JSON.stringify(execution));
  }

  private requestLanguage(title: string, callback: (reason: string) => void) {
    const dialogRef = this.dialog.open(LanguageDialogComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        title
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      this.spinnerService.hide();
      if (result != null) {
        callback(result);
      }
    });
  }

  private checkCalibration(calibration: Calibration): string[] {
    const errors: string[] = [];

    if (calibration.idEquipment == null) {
      errors.push(this.translate.instant('calibrateEquipmentEdit.general.name.error') as string);
    }

    if (StringUtils.isEmpty(calibration.certificateNum)) {
      errors.push(this.translate.instant('calibrateEquipmentEdit.general.certificateNum.error') as string);
    }

    if (calibration.calibrationDate == null) {
      errors.push(this.translate.instant('calibrateEquipmentEdit.general.calibrateDate.error') as string);
    }

    if (calibration.id != null && calibration.idPlace == null) {
      errors.push(this.translate.instant('calibrateEquipmentEdit.calibrationData.place.error') as string);
    }

    if (calibration.id != null) {
      if (ArrayUtils.isEmpty(calibration.variables)) {
        errors.push(this.translate.instant('calibrateEquipmentEdit.form.variables.atLeastOne') as string);
      } else {
        calibration.variables.forEach(v => {
          const variableName = this.translate.instant('variable.' + v.variableTranslation) as string;

          if (v.idProcedure == null) {
            errors.push(
              this.translate.instant('calibrateEquipmentEdit.form.variables.procedure.error', { variable: variableName }) as string);
          }

          if (ArrayUtils.isEmpty(v.config)) {
            errors.push(
              this.translate.instant('calibrateEquipmentEdit.form.variables.config.atLeastOne', { variable: variableName }) as string);
          } else {
            const emptyList: CalibrationAsLeft[] = [];
            const asLeftList: CalibrationAsLeft[] = emptyList.concat(...v.config.map(c => c.asLeft));

            asLeftList.filter(aL => aL.pattern == null).forEach(aL => {
              errors.push(this.translate.instant('calibrateEquipmentEdit.form.variables.asLeft.error',
                { point: aL.point, variable: variableName }) as string);
            });

            const countValues = asLeftList.map(aL => ArrayUtils.isEmpty(aL.values) ? 0 : aL.values.length).filter(ArrayUtils.unique)
              .filter(l => l !== 0);
            if (countValues.length > 1) {
              errors.push(this.translate.instant('calibrateEquipmentEdit.form.variables.asLeft.sameValues',
                { variable: variableName }) as string);
            }

          }

          if (ArrayUtils.isEmpty(v.patterns)) {
            errors.push(
              this.translate.instant('calibrateEquipmentEdit.form.variables.patterns.atLeastOne', { variable: variableName }) as string);
          }
        });
      }
    }

    return errors;
  }

  private downloadCheckedCertificates(checked: Array<Calibration>, lang: string = null) {
    this.spinnerService.show();

    this.calibrationService.downloadCertificates(checked.map(c => c.id), lang).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      saveAs(res, 'calibraciones.zip');

      this.spinnerService.hide();

      this.checkedItems = new Map<Calibration, boolean>();
    }, error => {
      console.log(error);
      this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.sign.error') as string);

      this.spinnerService.hide();
    });
  }

  private isCanceled(calibration: Calibration): boolean {
    return calibration.idStatus === CalibrationStatus.NO_VALIDO;
  }

  private cloneRowMasive(id: number[]): Promise<void> {

    return new Promise<void>((resolve) => {

      const dialogRefConfirm = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('generic.clone.sign') as string
        }
      });

      dialogRefConfirm.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: boolean) => {
        if (result === true) {

          this.spinnerService.show();
          this.calibrationService.checkCopyMasiveToGroup(id).subscribe((res: GenericCopyMasive) => {
            this.spinnerService.hide();
            if (res.ids?.length === 0) {
              res.checkErrors.errors = res.checkErrors.warns;
              res.checkErrors.warns = null;
            }
            const dialogRef = this.dialog.open(CheckWarnsErrorsComponent, {
              minWidth: '20%',
              maxHeight: '95vh',
              data: {
                warns: res.checkErrors.warns,
                errors: res.checkErrors.errors
              }
            });

            dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((canContinue: boolean) => {
              if (canContinue === true) {
                this.spinnerService.show();
                this.calibrationService.copyMasiveToGroup(res.ids).subscribe(() => {
                  this.spinnerService.hide();

                  this.snackBarService.sendSuccess(this.translate.instant('calibrateEquipmentEdit.form.clone.ok') as string);
                  resolve();
                }, () => {
                  this.spinnerService.hide();
                  this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.clone.error') as string);
                  resolve();
                });
              } else {
                resolve();
              }
            });
          }, () => {
            this.spinnerService.hide();
            this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.clone.error') as string);
            resolve();
          });
       } else {
         resolve();
       }
      });
    });
  }

}
