<h1 mat-dialog-title>{{ 'executionEdit.dialog.confirmSave.title' | translate }}</h1>
<form [formGroup]="form">
  <div mat-dialog-content class="thermal-theme">

    <mat-form-field class="campoFormulario">
      <textarea matInput placeholder="{{ 'execution.dialog.confirmSave.reason.label' | translate }}"
        [(ngModel)]="data.reason" formControlName="reason" [errorStateMatcher]="matcher" required></textarea>
      <mat-error>
        {{ 'protocolEdit.dialog.confirmSave.reason.error' | translate }}
      </mat-error>
    </mat-form-field>

  </div>
  <div mat-dialog-actions class="thermal-theme">
    <div fxFlex></div>

    <button mat-raised-button color="primary" (click)="onOkClick()"
      cdkFocusInitial>{{ 'button.save' | translate }}</button>

    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>