import * as _ from 'lodash-es';
import * as moment from 'moment';

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
  EssayTypeEnum,
  EssayUtils,
  EventValueEssay,
  HotColdValueEssay,
  LethalityValueEssay,
  MinMaxValueEssay
} from 'src/app/utils/essayUtils';
import { PeakEventType, PeakEventTypeEnum } from 'src/app/model/peakEvent';
import { SensorData, SensorDataGraph, SensorDataGraphData } from 'src/app/model/sensorData';

import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { DateUtils } from 'src/app/utils/dateUtils';
import { Defrost } from 'src/app/model/defrost';
import { ExecutionService } from 'src/app/services/execution.service';
import { ExecutionStatus } from 'src/app/model/execution';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatRadioChange } from '@angular/material/radio';
import { NumberUtils } from 'src/app/utils/numberUtils';
import { PeakTypeService } from 'src/app/services/peakType.service';
import { Sensor } from 'src/app/model/sensor';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { VariableTypeEnum } from 'src/app/model/variable';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { Criteria } from '../../../model/criteria';
import { CriteriaTypeEnum } from 'src/app/model/criteriaType';

@Component({
  selector: 'app-execution-edit-graph',
  templateUrl: './execution-edit-graph.component.html'
})
export class ExecutionEditGraphComponent implements OnInit, OnDestroy {
  @Output() fcStartEssayEmitter = new EventEmitter<any>();
  @Output() copyDateFirstSensorEmitter = new EventEmitter<any>();
  @Output() onHotColdSpotEmitter = new EventEmitter<any>();
  @Output() onIdPeakEmitter = new EventEmitter<any>();

  idEssay: number;
  idVariable: number;
  idPeak: number;
  idTypeEssay: number;
  idTypeVariable: number;
  idUnit: number;
  idStatus: number;
  essayIndex: number;
  variableIndex: number;
  copyDateFirstSensor: boolean;
  reasonModifyExpositionTime: string;

  showMkt: boolean;

  dateZoomInit: Date;
  dateZoomEnd: Date;

  dateZoomInitStr: string;
  dateZoomEndStr: string;

  datasource: SensorDataGraph[];
  sensors: any[];

  calculatedData: SensorDataGraphData[];

  fcStartEssay: Date;
  fcEndEssay: Date;

  maxDate: any;
  minDate: any;

  minMaxValue: MinMaxValueEssay;
  hotColdSpotValue: HotColdValueEssay;
  eventValue: EventValueEssay;
  lethalityValue: LethalityValueEssay[];
  diffAvgValue: number;
  criterias: Criteria[];
  avgSensors: string[];
  diffAvarages: string[];
  diffProbeRespectAvg: string[];

  fullEssay = false;

  originalSensors: Sensor[];
  defrosts: Defrost[];
  changeTimezone: boolean;
  peakTypes: PeakEventType[];

  graphOpen = false;

  errorMinMaxValue: boolean;

  private _data: SensorData[];

  private daysRange = 0;
  private hoursRange = 0;
  private minutesRange = 0;

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private executionService: ExecutionService,
    private peakTypeService: PeakTypeService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) { }

  set data(data: SensorData[]) {

    this.graphOpen = data?.length < 100000;

    if (data) {
      data.forEach(d => {
        if (typeof d.date === 'string') {
          d.date = DateUtils.anyToDate(d.date);
        }
      });
    }

    this._data = data;

    this.dateZoomInit = null;
    this.dateZoomEnd = null;

    if (data != null) {
      data.forEach((value, i, arr) => {
        if (this.dateZoomInit == null || this.dateZoomInit >= value.date) {
          this.dateZoomInit = value.date;
        }

        if (this.dateZoomEnd == null || this.dateZoomEnd <= value.date) {
          this.dateZoomEnd = value.date;
        }

        if (i === (arr.length - 1)) {
          this.recalculateDatasource(data);
        }

      });
    } else {
      this.recalculateDatasource(data);
    }

    if (this.dateZoomInit != null) {
      this.dateZoomInitStr = moment(this.dateZoomInit).format('DD/MM/YYYY HH:mm:ss');
    } else {
      this.dateZoomInitStr = null;
    }

    if (this.dateZoomEnd != null) {
      this.dateZoomEndStr = moment(this.dateZoomEnd).format('DD/MM/YYYY HH:mm:ss');
    } else {
      this.dateZoomEndStr = null;
    }

  }

  set range(range: string) {
    let rangeSplit = [];
    if (range.indexOf(';') > -1) {
      rangeSplit = range.split(';');
    } else {
      rangeSplit = range.split(',');
    }

    this.daysRange = +rangeSplit[0] || 0;
    this.hoursRange = +rangeSplit[1] || 0;
    this.minutesRange = +rangeSplit[2] || 0;
  }

  recalculateGraph(): void {
    this.recalculateDatasource(this._data);
  }

  ngOnInit(): void {
    this.peakTypes = [];
    this.peakTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: PeakEventType[]) => this.peakTypes = res);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showLoading(): void {
    this.spinnerService.show();
  }

  hideLoading(): void {
    this.spinnerService.hide();
  }

  disableGraph(): boolean {
    return this.datasource == null || this.datasource.length === 0;
  }

  isFixedRange(): boolean {
    if (this.idTypeEssay == null) {
      return true;
    }

    let res = !this.isEventEssay();

    if (res && this.idTypeVariable != null) {
      res = this.isLethalityEssay();
    }

    return res;
  }

  customizeTooltip(arg: any): any {
    const date = moment(arg.argument as Date).format('DD/MM/YYYY HH:mm:ss');
    const sensorName = arg.seriesName as string;
    const value = arg.originalValue as number;

    const msg = '<strong>' + sensorName + '</strong><br />' + date + ': ' + (value.toString());

    return { text: msg };
  }

  isEventEssay(): boolean {
    if (this.idTypeEssay == null) {
      return true;
    }

    const allowedStatuses = [EssayTypeEnum.ENSAYO_APERTURA_DE_PUERTA, EssayTypeEnum.ENSAYO_CORTE_ELECTRICO];

    const res = allowedStatuses.includes(this.idTypeEssay);

    return res;
  }

  isLethalityEssay(): boolean {
    if (this.idTypeVariable == null) {
      return true;
    }

    const allowedVariable = [VariableTypeEnum.LETHALITY];

    const res = !allowedVariable.includes(this.idTypeVariable);

    return res;
  }

  recalculateDatasource(data: SensorData[]): void {

    if (data == null || ArrayUtils.isEmpty(data)) {
      data = this._data;
    }

    if (data == null || ArrayUtils.isEmpty(data)) {
      this.datasource = [];
      this.sensors = [];

      return;
    }

    this.spinnerService.show();

    const res: SensorDataGraph[] = [];
    const sensors = [];

    if (data != null && this.dateZoomInit != null && this.dateZoomEnd != null && this.isFixedRange()) {
      data = data.filter(item => {
        return this.dateZoomInit == null || this.dateZoomEnd == null
          || (DateUtils.isDateBetweenEq(item.date, this.dateZoomInit, this.dateZoomEnd));
      });
    }

    data = data.sort((s1, s2) => s1.date.getTime() - s2.date.getTime());

    const groupBy = ArrayUtils.groupBy(data, (item: SensorData) => item.date.toISOString());
    groupBy.forEach((values: SensorData[], key: string) => {
      values.forEach(value => {
        let sen = new SensorDataGraph();
        sen.date = DateUtils.anyToDate(key);

        const index = res.findIndex(r => r.date.getTime() === sen.date.getTime());
        const alreadyExists = index !== -1;

        if (alreadyExists) {
          sen = res[index];
        }

        if (!value.equipmentName) {
          return;
        }

        const beautySerialNum = value.equipmentName.replace('-', '').replace(' ', '').toLowerCase();

        sen[beautySerialNum] = value.value;

        const currentSensors = sensors.map(item => item.name);

        const originalSensor = this.originalSensors.find(s => value.equipmentName === s.equipmentName);

        if (originalSensor != null) {
          const evaluate = originalSensor.evaluate;
          const outer = originalSensor.outer;
          const hotColdSpot = originalSensor.hotColdSpot;
          const hasEndotoxin = originalSensor.hasEndotoxin;
          const hasBioindicator = originalSensor.hasBioindicator;
          const location = originalSensor.location;

          if (!currentSensors.includes(value.equipmentName)) {

            sensors.push({
              name: value.equipmentName,
              serialNum: value.serialNum,
              value: beautySerialNum,
              evaluate,
              outer,
              hotColdSpot,
              hasEndotoxin,
              hasBioindicator,
              location,
              mkt: originalSensor.mkt
            });
          }

          if (!alreadyExists && (sen == null || evaluate)) {
            res.push(sen);
          }
        }

      });
    });

    this.datasource = res;

    this.sensors = sensors;

    this.minDate = this.calculateMinDate();
    this.maxDate = this.calculateEndDate(this.minDate as Date, this.fcEndEssay);

    this._data.forEach(item => item.date = DateUtils.anyToDate(item.date));
    let dataToUse = this._data;
    if (this.isFixedRange()) {
      dataToUse = dataToUse.filter(item => DateUtils.isDateBetweenEq(item.date, this.minDate as Date, this.maxDate as Date));
    } else {
      dataToUse = dataToUse.filter(item => {
        const name = item.equipmentName;

        const sensor = this.sensors.find(s => s.name === name);

        if (sensor == null) {
          return false;
        }

        return !sensor.outer;
      });
    }

    this.recalculateValues(dataToUse);

    this.spinnerService.hide();
  }

  disableRange(): boolean {
    const disallowedStatuses = [ExecutionStatus.PENDIENTE_FIRMA, ExecutionStatus.FIRMADO];

    const res = disallowedStatuses.includes(this.idStatus);

    return res || (this.showCopyDateFirstSensor() && this.copyDateFirstSensor === true);
  }

  onValueChanged: ((data: any) => void) = (e) => {
    this.spinnerService.show();

    let initDate = DateUtils.anyToDate(_.cloneDeep(e.value[0] as Date));
    let endDate = DateUtils.anyToDate(_.cloneDeep(e.value[1] as Date));

    initDate = EssayUtils.calculateNearDate(initDate, this._data);
    endDate = EssayUtils.calculateNearDate(this.calculateEndDate(initDate, endDate), this._data);

    this.minDate = initDate;
    this.maxDate = endDate;

    let dataToUse = this._data;
    if (this.isFixedRange()) {
      dataToUse = this._data.filter(item => DateUtils.isDateBetweenEq(item.date, initDate, endDate));
    }

    this.recalculateValues(dataToUse);

    let dateInitEvent: Date = null;
    let dateEndEvent: Date = null;

    if (initDate != null) {
      dateInitEvent = _.cloneDeep(initDate);
    }

    if (endDate != null) {
      dateEndEvent = _.cloneDeep(endDate);
    }

    if (!this.showCopyDateFirstSensor() || this.copyDateFirstSensor !== true) {
      this.fcStartEssayEmitter.next({
        essayIndex: this.essayIndex,
        variableIndex: this.variableIndex,
        fcStartEssay: dateInitEvent,
        fcEndEssay: dateEndEvent,
        changeTimezone: this.changeTimezone
      });
    }

    this.changeTimezone = true;

    this.spinnerService.hide();
  }

  recalculateDateFilter(): void {

    try {
      this.dateZoomInit = moment(this.dateZoomInitStr, 'DD/MM/YYYY HH:mm:ss').toDate();
    } catch (e) {
      this.dateZoomInit = null;
    }

    if (this.dateZoomInit == null) {
      this.snackBarService.sendError(this.translate.instant('executionEdit.essays.graph.form.error.zoomInit') as string);
      return;
    }

    try {
      this.dateZoomEnd = moment(this.dateZoomEndStr, 'DD/MM/YYYY HH:mm:ss').toDate();
    } catch (e) {
      this.dateZoomEnd = null;
    }

    if (this.dateZoomEnd == null) {
      this.snackBarService.sendError(this.translate.instant('executionEdit.essays.graph.form.error.zoomEnd') as string);
      return;
    }

    if (this.dateZoomInit > this.dateZoomEnd) {
      this.snackBarService.sendError(this.translate.instant('executionEdit.essays.graph.form.error.endBeforeInit') as string);
      return;
    }

    let gap = 0;
    if (!isNaN(this.daysRange) && this.daysRange > 0) {
      gap += this.daysRange * 86400000;
    }
    if (!isNaN(this.hoursRange) && this.hoursRange > 0) {
      gap += this.hoursRange * 3600000;
    }
    if (!isNaN(this.minutesRange) && this.minutesRange > 0) {
      gap += this.minutesRange * 60000;
    }

    const initDateToCheck = DateUtils.anyToDate(this.fcStartEssay) || this.dateZoomInit;
    const initDateAndRange = DateUtils.anyToDate(initDateToCheck.getTime() + gap);

    if (initDateAndRange > this.dateZoomEnd) {
      this.snackBarService.sendError(this.translate.instant('executionEdit.essays.graph.form.error.smallRange') as string);
      return;
    }

    this.recalculateDatasource(this._data);

  }

  recalculateValues(list: SensorData[]): void {
    this.calculatedData = [];
    this.eventValue = new EventValueEssay();

    if (list == null || list.length === 0) {
      return;
    }

    if (this.isEventEssay()) {
      this.spinnerService.show();
      this.executionService.getEventValue(this.idVariable).pipe(takeUntil(this.destroy$)).subscribe(event => {
        if (event != null) {
          this.eventValue = event;
        }

        this.spinnerService.hide();
      }, () => {
        this.spinnerService.hide();
      });
    } else {
      for (const sensor of this.sensors) {

        if (!sensor.evaluate) {
          continue;
        }

        let sensorDataList = list.filter(item => item.equipmentName === sensor.name);

        const defrosts = this.defrosts;
        if (defrosts != null && defrosts.length !== 0) {

          defrosts.forEach(defrost => {
            defrost.startDate = DateUtils.anyToDate(defrost.startDate);
            defrost.endDate = DateUtils.anyToDate(defrost.endDate);
          });

          const dats = [];

          sensorDataList.forEach(dat => {
            const anyResult = defrosts.find(defrost => DateUtils.isDateBetweenEq(dat.date, defrost.startDate, defrost.endDate));
            if (anyResult == null) {
              dats.push(dat);
            } else if (!environment.production) {
              console.error(`Descartado el tiempo ${dat.date}`);
            }
          });

          sensorDataList = dats;
        }

        const values = sensorDataList.map(item => item.value);

        list = list.filter(d => {
          const sensorFound = this.sensors.find(s => s.serialNum === d.serialNum);

          return sensorFound?.evaluate as string;
        });

        const data = new SensorDataGraphData();
        data.equipmentName = sensor.name;
        data.serialNum = sensor.serialNum;
        data.outer = sensor.outer;
        data.maxValue = Math.max(...values) || 0;
        data.minValue = Math.min(...values) || 0;
        data.standardDeviation = NumberUtils.standardDeviation(values);
        data.delta = data.maxValue - data.minValue;
        data.average = ArrayUtils.calcAvg(values);
        data.diffAvgMin = NumberUtils.fixPrecision(Math.abs(data.minValue - data.average));
        data.diffMaxAvg = NumberUtils.fixPrecision(Math.abs(data.maxValue - data.average));
        data.diffMaxMin = NumberUtils.fixPrecision(Math.abs(data.maxValue - data.minValue));
        data.isColdSpot = EssayUtils.checkColdSpot(data.equipmentName, this.hotColdSpotValue.coldspot, list);
        data.isHotSpot = EssayUtils.checkHotSpot(data.equipmentName, this.hotColdSpotValue.hotspot, list);
        data.isErrMin = this.isErrMin(data);
        data.isErrMax = this.isErrMax(data);
        data.isErrPeakMin = this.isErrPeakMin(data);
        data.isErrPeakMax = this.isErrPeakMax(data);
        data.isErrMinDelta = this.isErrMinDelta(data);
        data.isErrMaxDelta = this.isErrMaxDelta(data);
        data.isErrMaxStandardDeviation = this.isErrMaxStandardDeviation(data);
        data.hotColdSpot = sensor.hotColdSpot;

        if (this.showMkt) {
          data.mkt = +(sensor.mkt);
          data.isErrMin = this.isErrMin(data);
          data.isErrMax = this.isErrMax(data);
        }

        if (!this.calculatedData.map(c => c.equipmentName).includes(data.equipmentName)) {
          this.calculatedData.push(data);
        }

      }
    }

  }

  onHotColdSpotChange(equipmentName: string, event: MatRadioChange): void {
    const value = event.value;

    this.onHotColdSpotEmitter.emit({
      essayIndex: this.essayIndex,
      variableIndex: this.variableIndex,
      equipmentName,
      value
    });
  }

  onIdPeakChange(event: MatOptionSelectionChange): void {
    const value = event.source.value;

    this.onIdPeakEmitter.emit({
      essayIndex: this.essayIndex,
      variableIndex: this.variableIndex,
      value
    });
  }

  classForPeakMax(): string {
    const bold = [PeakEventTypeEnum.HIGH, PeakEventTypeEnum.BOTH];

    return bold.includes(this.idPeak) ? '' : 'tachado';
  }

  classForPeakMin(): string {
    const bold = [PeakEventTypeEnum.LOW, PeakEventTypeEnum.BOTH];

    return bold.includes(this.idPeak) ? '' : 'tachado';
  }

  public onError(event: any): void {

    if (event.target.id === '2203') {
      this.restartGraph();
    }
  }

  public restartGraph(): void {
    this.minDate = DateUtils.anyToDate(this.dateZoomInit);
    this.maxDate = this.calculateEndDate(this.minDate as Date, this.fcEndEssay);
  }

  calculateMinDate(): Date {
    let date: Date;

    this.datasource.forEach(value => {
      if (date == null) {
        date = value.date;
      } else if (date > value.date) {
        date = value.date;
      }

    });

    if (this.fcStartEssay != null && !this.fullEssay) {
      const dates: Date[] = [];
      dates.push(DateUtils.anyToDate(this.fcStartEssay));
      if(date!=null){
        dates.push(DateUtils.anyToDate(date));
      }
      // Comparamos las fechas a través del apply
      date = DateUtils.anyToDate(Math.max(... dates.map(d => d.getTime())));
    }

    return date;
  }

  maxDaysRange(): number {
    let res = this.daysRange;

    if (this.reasonModifyExpositionTime != null && this.reasonModifyExpositionTime !== '') {
      res = this.daysRange * 1.20;
    }

    return res;
  }

  maxHoursRange(): number {
    let res = this.hoursRange;

    if (this.reasonModifyExpositionTime != null && this.reasonModifyExpositionTime !== '') {
      res = this.hoursRange * 1.20;
    }

    return res;
  }

  maxMinutesRange(): number {
    let res = this.minutesRange;

    if (this.reasonModifyExpositionTime != null && this.reasonModifyExpositionTime !== '') {
      res = this.minutesRange * 1.20;
    }

    return res;
  }

  minDaysRange(): number {
    let res = this.daysRange;

    if (this.reasonModifyExpositionTime != null && this.reasonModifyExpositionTime !== '') {
      res = this.daysRange / 1.20;
    }

    return res;
  }

  minHoursRange(): number {
    let res = this.hoursRange;

    if (this.reasonModifyExpositionTime != null && this.reasonModifyExpositionTime !== '') {
      res = this.hoursRange / 1.20;
    }

    return res;
  }

  minMinutesRange(): number {
    let res = this.minutesRange;

    if (this.reasonModifyExpositionTime != null && this.reasonModifyExpositionTime !== '') {
      res = this.minutesRange / 1.20;
    }

    return res;
  }

  customizeSliderMarker: ((data: any) => string) = (data) => {
    return moment(data.value as Date).format('DD/MM/YYYY HH:mm:ss');
  }

  customizeLabel: ((data: any) => string) = (data) => {
    return moment(data.value as Date).format('DD/MM/YYYY HH:mm:ss');
  }

  customizeDateLabel: ((data: any) => string) = (data) => {
    let res = '';

    if (typeof data === 'string') {
      res = this.customizeDateLabel(DateUtils.anyToDate(data));
    } else if (typeof data === 'number') {
      res = this.customizeDateLabel(DateUtils.anyToDate(data));
    } else {
      res = data.toLocaleString();
    }

    return res;
  }

  showCopyDateFirstSensor(): boolean {
    return this.variableIndex !== 0;
  }

  onCopyTimeGraphChange(event: MatCheckboxChange): void {
    const value = event.checked;

    this.copyDateFirstSensor = value;

    this.copyDateFirstSensorEmitter.emit({
      essayIndex: this.essayIndex,
      variableIndex: this.variableIndex,
      value
    });
  }

  getClassError(data: SensorDataGraphData): string {
    return data.outer ? '' : 'error';
  }

  recalculateData(): void {
    this.data = this._data;
  }

  private calculateEndDate(initDate: Date, endDate: Date): Date {
    if (this.fullEssay) {
      let date: Date;

      this.datasource.forEach(value => {
        if (date == null) {
          date = value.date;
        } else if (date < value.date) {
          date = value.date;
        }
      });

      return date;
    }

    if (initDate == null || (this.reasonModifyExpositionTime != null && this.reasonModifyExpositionTime !== '')) {
      return endDate;
    }

    let gap = 0;
    if (!isNaN(this.daysRange) && this.daysRange > 0) {
      gap += this.daysRange * 86400000;
    }
    if (!isNaN(this.hoursRange) && this.hoursRange > 0) {
      gap += this.hoursRange * 3600000;
    }
    if (!isNaN(this.minutesRange) && this.minutesRange > 0) {
      gap += this.minutesRange * 60000;
    }

    const calculated = DateUtils.anyToDate(initDate.getTime() + gap);

    const listDates = [...this.datasource.map(d => d.date.getTime())];
    let lastDate = new Date(ArrayUtils.max(listDates));

    if (calculated != null) {
      const dates: Date[] = [];
      dates.push(DateUtils.anyToDate(calculated));
      dates.push(DateUtils.anyToDate(lastDate));
      // Comparamos las fechas a través del apply
      lastDate = DateUtils.anyToDate(Math.min(... dates.map(d => d.getTime())));
    }

    return lastDate;
  }

  private isErrMin(data: SensorDataGraphData): boolean {
    let res = false;
    let valueCompare = data.minValue;

    if (data.mkt != null) {
      valueCompare = data.mkt;
    }

    if (this.minMaxValue.minValue) {
      if (this.minMaxValue.canBeEqual) {
        res = valueCompare < this.minMaxValue.minValue;
      } else {
        res = valueCompare <= this.minMaxValue.minValue;
      }
    }

    return res;
  }

  private isErrMax(data: SensorDataGraphData): boolean {
    let res = false;
    let valueCompare = data.maxValue;

    if (data.mkt != null) {
      valueCompare = data.mkt;
    }

    if (this.minMaxValue.maxValue) {
      if (this.minMaxValue.canBeEqual) {
        res = valueCompare > this.minMaxValue.maxValue;
      } else {
        res = valueCompare >= this.minMaxValue.maxValue;
      }
    }

    return res;
  }

  private isErrMinDelta(data: SensorDataGraphData): boolean {
    let res = false;

    if (this.minMaxValue.minValueDelta) {
      if (this.minMaxValue.canBeEqualDelta) {
        res = data.delta < this.minMaxValue.minValueDelta;
      } else {
        res = data.delta <= this.minMaxValue.minValueDelta;
      }
    }

    return res;
  }

  private isErrMaxDelta(data: SensorDataGraphData): boolean {
    let res = false;

    if (this.minMaxValue.maxValueDelta) {
      if (this.minMaxValue.canBeEqualDelta) {
        res = data.delta > this.minMaxValue.maxValueDelta;
      } else {
        res = data.delta >= this.minMaxValue.maxValueDelta;
      }
    }

    return res;
  }

  private isErrMaxStandardDeviation(data: SensorDataGraphData): boolean {
    let res = false;

    if (this.minMaxValue.maxValueStandardDeviation) {
      if (this.minMaxValue.canBeEqualStandardDeviation) {
        res = data.standardDeviation > this.minMaxValue.maxValueStandardDeviation;
      } else {
        res = data.standardDeviation >= this.minMaxValue.maxValueStandardDeviation;
      }
    }

    return res;
  }

  private isErrPeakMax(data: SensorDataGraphData): boolean {
    let res = false;

    if (this.minMaxValue.minValuePeak) {
      res = data.minValue > this.minMaxValue.minValuePeak;
    }

    return res;
  }

  private isErrPeakMin(data: SensorDataGraphData): boolean {
    let res = false;

    if (this.minMaxValue.maxValuePeak) {
      res = data.maxValue < this.minMaxValue.maxValuePeak;
    }

    return res;
  }

  // Diff. Cada sonda
  private isErrAvgMin(data: SensorDataGraphData): boolean {
    let res = false;

    this.criterias.forEach(p => {
      if (p.idType === CriteriaTypeEnum.DEVIATION_VALUE_AVG) {
        this.diffAvgValue = p.criteriaValue1;

        if (this.diffAvgValue) {
          res = ((data.average + Number(this.diffAvgValue)) <= Number(data.maxValue)) ||
          ((data.average - Number(this.diffAvgValue)) >= Number(data.minValue));
          return res;
        }
      }
    })

    return res;
  }

  private isErrAvgMax(data: SensorDataGraphData): boolean {
    let res = false;

    for (let index = 0; index < this.criterias?.length; index++) {
      if (this.criterias[index].idType === CriteriaTypeEnum.DEVIATION_VALUE_AVG) {
        this.diffAvgValue = this.criterias[index].criteriaValue1;

        if (this.diffAvgValue)  {
          res = Number(data.diffMaxAvg) <= Number(this.diffAvgValue);

          return res;
        }
      }
    }
    return res;
  }
}
