import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { LoginService } from '../services/login.service';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class IsLoggedInterceptor implements CanActivate {

  constructor(private authService: LoginService, private router: Router, private cookieService: CookieService) { }

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn$.pipe(take(1), map((isLoggedIn: boolean) => {
      if (!isLoggedIn) {
        const rol = this.cookieService.get('rol');
        if (rol === 'guest') {
          void this.router.navigate(['/login-client']);
        } else {
          void this.router.navigate(['/login']);
        }
        return false;
      }
      return true;
    }));
  }
}
