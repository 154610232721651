import { Component } from '@angular/core';

@Component({
  selector: 'app-old-browser',
  templateUrl: './old-browser.component.html',
  styleUrls: ['old-browser.component.scss']
})
export class OldBrowserComponent {

}
