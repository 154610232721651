import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { CommonModule } from '@angular/common';
import { DevextremeModule } from 'src/app/external-components/devextreme.module';
import { ExecutionEditAttachmentDetailsComponent } from './execution-edit-attachment-details.component';
import { ExecutionEditAttachmentsComponent } from './execution-edit-attachments.component';
import { ExecutionEditAuditComponent } from './execution-edit-audit.component';
import { ExecutionEditBioindicatorsDetailsComponent } from './execution-edit-bioindicators-details.component';
import { ExecutionEditBottomSheetUploadComponent } from './execution-edit-bottom-sheet-upload.component';
import { ExecutionEditComponent } from './execution-edit.component';
import { ExecutionEditConfigSensorComponent } from './execution-edit-config-sensor.component';
import { ExecutionEditConfirmBiosComponent } from './execution-edit-confirm-bios.component';
import { ExecutionEditConfirmSaveComponent } from './execution-edit-dialog-confirmSave.component';
import { ExecutionEditCopyEssayComponent } from './execution-edit-copy-essay.component';
import { ExecutionEditCriteriaEditComponent } from './execution-edit-criteria-edit.component';
import { ExecutionEditCriticalInstrumentationEditComponent } from './execution-edit-critical-instrumentation-edit.component';
import { ExecutionEditDefrostEditComponent } from './execution-edit-defrost-edit.component';
import { ExecutionEditDefrostListComponent } from './execution-edit-defrost-list.component';
import { ExecutionEditDialogAlreadyExistsExecutionComponent } from './execution-edit-dialog-already-exists-execution.component';
import { ExecutionEditDialogAutomaticSignComponent } from './execution-edit-dialog-automatic-sign.component';
import { ExecutionEditDialogCorrectSensorsComponent } from './execution-edit-dialog-correct-sensors.component';
import { ExecutionEditDiscardTimeComponent } from './execution-edit-discard-time.component';
import { ExecutionEditEquipmentEditComponent } from './execution-edit-equipment-edit.component';
import { ExecutionEditEssayComponent } from './execution-edit-essay.component';
import { ExecutionEditFieldEditComponent } from './execution-edit-field-edit.component';
import { ExecutionEditGraphComponent } from './execution-edit-graph.component';
import { ExecutionEditMassiveUploadComponent } from './execution-edit-massive-upload.component';
import { ExecutionEditReasonExpositionTimeComponent } from './execution-edit-reason-exposition-time.component';
import { ExecutionEditValidateExecutionAutomaticSignComponent } from './execution-edit-validate-execution.component-automatic-sign';
import { ExecutionEditValidateExecutionComponent } from './execution-edit-validate-execution.component';
import { ExecutionEditValidatorEditComponent } from './execution-edit-validator-edit.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SharedModule } from '../../shared/shared.module';
import { ThermalModule } from '../thermal.module';

@NgModule({
    declarations: [ExecutionEditComponent, ExecutionEditAuditComponent, ExecutionEditEssayComponent,
        ExecutionEditMassiveUploadComponent, ExecutionEditGraphComponent, ExecutionEditAttachmentsComponent,
        ExecutionEditValidateExecutionComponent, ExecutionEditDefrostListComponent, ExecutionEditDefrostEditComponent,
        ExecutionEditConfigSensorComponent, ExecutionEditDiscardTimeComponent, ExecutionEditAttachmentDetailsComponent,
        ExecutionEditBioindicatorsDetailsComponent, ExecutionEditFieldEditComponent, ExecutionEditCriteriaEditComponent,
        ExecutionEditReasonExpositionTimeComponent, ExecutionEditEquipmentEditComponent, ExecutionEditCriticalInstrumentationEditComponent,
        ExecutionEditConfirmBiosComponent, ExecutionEditConfirmSaveComponent, ExecutionEditDialogAutomaticSignComponent,
        ExecutionEditValidateExecutionAutomaticSignComponent, ExecutionEditValidatorEditComponent, ExecutionEditCopyEssayComponent,
        ExecutionEditBottomSheetUploadComponent, ExecutionEditDialogAlreadyExistsExecutionComponent,
        ExecutionEditDialogCorrectSensorsComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ThermalModule,
        /* Custom modules */
        AngularMaterialModule,
        DevextremeModule,
        SharedModule,
        PipesModule,
        /* Otros */
        FlexLayoutModule
    ],
    exports: [ExecutionEditComponent, ExecutionEditAuditComponent, ExecutionEditEssayComponent,
        ExecutionEditMassiveUploadComponent, ExecutionEditGraphComponent, ExecutionEditValidateExecutionComponent]
})
export class ExecutionEditModule { }
