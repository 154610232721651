<div class="main-container">
  <form #manageGroupsForm="ngForm">

    <div class="toolbar-list">

      <div class="toolbar-list-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <h1>{{ 'manageGroups.title' | translate }}</h1>
        </div>
        <div class="breadcrumbs">
          <a href="#">{{ 'common.start.label' | translate }}</a> /
          <span>{{ 'manageGroups.title' | translate }}</span>
        </div>
      </div>

      <div class="toolbar-back-button">
        <a (click)="cancel()" mat-button>
          <mat-icon>undo</mat-icon> {{ 'button.back' | translate }}
        </a>
      </div>
    </div>

    <div class="table-container">
      <table #users mat-table matSort [dataSource]="dataSource">

        <!-- name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'manageGroups.cols.name' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.name }} </td>
        </ng-container>

        <!-- planType Column -->
        <ng-container matColumnDef="planType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'manageGroups.cols.planType' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.namePlanType }} </td>
        </ng-container>

        <!-- maxUsers Column -->
        <ng-container matColumnDef="maxUsers">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'manageGroups.cols.maxUsers' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ getMaxUsers(item) }} </td>
        </ng-container>

        <!-- maxExecutions Column -->
        <ng-container matColumnDef="maxExecutions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'manageGroups.cols.maxExecutions' | translate }}
          </th>
          <td mat-cell *matCellDef="let item"> {{ getMaxExecutions(item) }} </td>
        </ng-container>

        <!-- phasesAllowed Column -->
        <ng-container matColumnDef="phasesAllowed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'manageGroups.cols.phasesAllowed' | translate }}
          </th>
          <td mat-cell *matCellDef="let item"> {{ getPhasesAllowed(item) }} </td>
        </ng-container>

        <!-- canManageUsers Column -->
        <ng-container matColumnDef="canManageUsers">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'manageGroups.cols.canManageUsers' | translate }}
          </th>
          <td mat-cell *matCellDef="let item"> {{ (item.canManageUsers ? 'common.yes' : 'common.no') | translate }}
          </td>
        </ng-container>

        <!-- canGeneratePreReport Column -->
        <ng-container matColumnDef="canGeneratePreReport">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'manageGroups.cols.canGeneratePreReport' | translate }} </th>
          <td mat-cell *matCellDef="let item">
            {{ (item.canGeneratePreReport ? 'common.yes' : 'common.no') | translate }}
          </td>
        </ng-container>

        <!-- signs Column -->
        <ng-container matColumnDef="signs">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'manageGroups.cols.signs' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ getSignTypes(item) }} </td>
        </ng-container>

        <!-- showCharacterization Column -->
        <ng-container matColumnDef="showCharacterization">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'manageGroups.cols.showCharacterization' | translate }} </th>
          <td mat-cell *matCellDef="let item">
            {{ (item.showCharacterization ? 'common.yes' : 'common.no') | translate }}
          </td>
        </ng-container>

        <!-- Button edit Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
          <td mat-cell *matCellDef="let element" class="action">
            <button mat-icon-button (click)="editRow(element.id)" matTooltip="{{ 'button.edit' | translate }}">
              <mat-icon>mode_edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </div>

    <div class="table-footer-container">
      <div class="actions">
        <a [routerLink]="['.']" [fragment]="'table-container'" class="back-to-top">
          {{ 'button.backToTop' | translate }} </a>
      </div>
      <div class="paginator">
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons [length]="dataSource.totalItems$ | async"
          lang="es">
        </mat-paginator>
      </div>
    </div>
  </form>
</div>