import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalibrationReportFilter } from 'src/app/model/attachment';
import { Calibration } from 'src/app/model/calibration';
import { InternalEquipment } from 'src/app/model/internalEquipment';
import { CalibrationService } from 'src/app/services/calibration.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LangFlag, TranslationService } from 'src/app/services/translation.service';
import { CalibrationUtils } from 'src/app/utils/calibratesUtils';
import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { ManageUsersService } from '../../../services/manageUsers.service';
import { UserService } from '../../../services/user.service';
import { GroupEnum } from '../../../utils/groupUtils';

export interface DialogDataAutomaticSignCalibration {
  calibration: Calibration;
  equipment: InternalEquipment;
}

@Component({
  selector: 'app-calibration-edit-automatic-sign-report',
  templateUrl: './calibration-edit-automatic-sign-report.component.html'
})
export class CalibrationEditAutomaticSignReportComponent implements OnInit, OnDestroy {

  reportConfig: CalibrationReportFilter;
  langs: LangFlag[];
  selectedGroup: number;

  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CalibrationEditAutomaticSignReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataAutomaticSignCalibration,
    private calibrationService: CalibrationService,
    private translate: TranslateService,
    private translationService: TranslationService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private manageUsersService: ManageUsersService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.reportConfig = new CalibrationReportFilter();
    this.reportConfig.lang = this.translationService.currentLang;

    this.langs = this.translationService.configuredLangs;

    const currentProfile = this.userService.currentProfile;

    this.selectedGroup = currentProfile.profiles.find(p => p.idGroup === currentProfile.idActiveProfile)?.idGroup;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onOkClick(): void {
    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    const calibration = this.data.calibration;
    const equipment = this.data.equipment;

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {

      this.spinnerService.show();

      if (token != null) {
        const lang = this.reportConfig.lang;
        if (equipment.idClient) {
          this.manageUsersService.checkIfHasUser(equipment.idClient).subscribe(hasUser => {
            if(!hasUser && this.selectedGroup === GroupEnum.QUALIPHARMA){
              this.snackBarService.sendError(this.translate.instant('manageUsers.dialog.clientHasUsers.error') as string);
            } else {
              this.calibrationService.automaticSign(calibration.id, token, lang).pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.spinnerService.hide();
                this.snackBarService.sendSuccess(this.translate.instant('calibrateEquipmentEdit.form.sign.ok') as string);

                this.calibrationService.downloadPdfVersion(calibration.id, calibration.currentVersion).pipe(takeUntil(this.destroy$))
                  .subscribe((file: Blob) => {
                    const name = CalibrationUtils.getNameForCalibration(calibration, equipment, this.translate);
                    saveAs(file, name);

                    this.spinnerService.hide();
                    this.dialogRef.close(token);
                  });
              }, () => {
                this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.sign.error') as string);

                this.spinnerService.hide();
              });
            }
          });
        } else {
          this.calibrationService.automaticSign(calibration.id, token, lang).pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.spinnerService.hide();
            this.snackBarService.sendSuccess(this.translate.instant('calibrateEquipmentEdit.form.sign.ok') as string);

            this.calibrationService.downloadPdfVersion(calibration.id, calibration.currentVersion).pipe(takeUntil(this.destroy$))
              .subscribe((file: Blob) => {
                const name = CalibrationUtils.getNameForCalibration(calibration, equipment, this.translate);
                saveAs(file, name);

                this.spinnerService.hide();
                this.dialogRef.close(token);
              });
          }, () => {
            this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.sign.error') as string);

            this.spinnerService.hide();
          });
        }
      } else {
        this.spinnerService.hide();
      }
    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showChangeLangIcon(): boolean {
    return this.langs.length > 1;
  }

}
