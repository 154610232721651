import { GenericFilter } from "./genericClass";

export class GenericDocumentListFilter extends GenericFilter {
    id: number;
    documentCode: string;
    equipment: string;
    equipmentSerialNum: string;
    identificative: string;
    areaName: string;
    nextIntervention: string;
    idStatus: number;
    dType: number;
    realizationDate: Date;
    nextInterventionDate: Date;
    idClient: number;
}