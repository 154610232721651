<h1 mat-dialog-title>{{ 'protocolEdit.instrumentationUse.validator.dialog.title' | translate }}</h1>
<form>

    <div mat-dialog-content class="thermal-theme" style="min-height: 10vh;overflow-y: hidden;">

        <table style="width:100%;" class="tablaConBordes">
            <tr>
                <th>{{ 'protocolEdit.instrumentationUse.validator.calibrationPoint' | translate }}</th>
                <th>{{ 'protocolEdit.instrumentationUse.validator.valueTemp' | translate }}</th>
                <th>{{ 'protocolEdit.instrumentationUse.validator.maxDeviation' | translate }}</th>
                <th>{{ 'protocolEdit.instrumentationUse.validator.minStability' | translate }}</th>
            </tr>
            <tr>
                <td>{{ 'protocolEdit.instrumentationUse.validator.lowTemp' | translate }}</td>
                <td>
                    <mat-form-field class="campoFormulario" style="width: 3em">
                        <input matInput [(ngModel)]="data.validator.lowTemp" name="validatorEditLowTemp" type="number"
                            required size="5">
                    </mat-form-field>
                    <div *ngIf="data.validator.fromProtocol"> ( {{ data.validator.lowTempProtocol }} ) </div>
                </td>
                <td>
                    ≤
                    <mat-form-field class="campoFormulario" style="width: 3em">
                        <input matInput [(ngModel)]="data.validator.lowDesv" name="validatorEditLowDesv" type="number"
                            required size="5">
                    </mat-form-field>
                    {{ 'protocolEdit.instrumentationUse.validator.aboutRefTemp' | translate }}
                    <div *ngIf="data.validator.fromProtocol"> ( {{ data.validator.lowDesvProtocol }} ) </div>
                </td>
                <td>{{ 'protocolEdit.instrumentationUse.validator.notApplicable' | translate }}</td>
            </tr>
            <tr>
                <td>{{ 'protocolEdit.instrumentationUse.validator.highTemp' | translate }}</td>
                <td>
                    <mat-form-field class="campoFormulario" style="width: 3em">
                        <input matInput [(ngModel)]="data.validator.highTemp" name="validatorEditHighTemp" type="number"
                            required size="5">
                    </mat-form-field>
                    <div *ngIf="data.validator.fromProtocol"> ( {{ data.validator.highTempProtocol }}) </div>
                </td>
                <td>
                    ≤
                    <mat-form-field class="campoFormulario" style="width: 3em">
                        <input matInput [(ngModel)]="data.validator.highDesv" name="validatorEditHighDesv" type="number"
                            required size="5">
                    </mat-form-field>
                    {{ 'protocolEdit.instrumentationUse.validator.aboutRefTemp' | translate }}
                    <div *ngIf="data.validator.fromProtocol"> ( {{ data.validator.highDesvProtocol }} ) </div>
                </td>
                <td>{{ 'protocolEdit.instrumentationUse.validator.notApplicable' | translate }}</td>
            </tr>
            <tr>
                <td>{{ 'protocolEdit.instrumentationUse.validator.verifTemp' | translate }}</td>
                <td>
                    <mat-form-field class="campoFormulario" style="width: 3em">
                        <input matInput [(ngModel)]="data.validator.verifTemp" name="validatorEditVerifTemp"
                            type="number" required size="5">
                    </mat-form-field>
                    <div *ngIf="data.validator.fromProtocol">( {{ data.validator.verifTempProtocol }} )</div>
                </td>
                <td>
                    ±
                    <mat-form-field class="campoFormulario" style="width: 3em">
                        <input matInput [(ngModel)]="data.validator.verifDesv" name="validatorEditVerifDesv"
                            type="number" required size="5">
                    </mat-form-field>
                    {{ 'protocolEdit.instrumentationUse.validator.aboutRefTemp' | translate }}
                    <div *ngIf="data.validator.fromProtocol"> ( {{ data.validator.verifDesvProtocol }} ) </div>
                </td>
                <td>
                    <mat-form-field class="campoFormulario" style="width: 3em">
                        <input matInput [(ngModel)]="data.validator.minStability" name="validatorEditMinStability"
                            type="number" required size="5">
                    </mat-form-field>
                    {{ 'protocolEdit.instrumentationUse.validator.tempIn' | translate }}
                    <mat-form-field class="campoFormulario" style="width: 3em">
                        <input matInput [(ngModel)]="data.validator.minStabilityTime" type="number" required size="5"
                            name="validatorEditMinStabilityTime">
                    </mat-form-field>
                    {{ 'protocolEdit.instrumentationUse.validator.minutes' | translate }}
                    <div *ngIf="data.validator.fromProtocol">
                        ({{ data.validator.minStabilityProtocol }}
                        {{ 'protocolEdit.instrumentationUse.validator.tempIn' | translate }}
                        {{ data.validator.minStabilityTimeProtocol }}
                        {{ 'protocolEdit.instrumentationUse.validator.minutes' | translate }}
                        {{ 'common.inProtocol' | translate }})
                    </div>
                </td>
            </tr>
        </table>

        <div>
            <br />

            <mat-form-field class="campoFormulario" *ngIf="showReason()">
                <textarea matInput placeholder="{{ 'protocolEdit.instrumentationUse.validator.reason' | translate }}"
                    [(ngModel)]="data.validator.valueChangeReason" name="reason" style="resize: none;"
                    required></textarea>
                <mat-error>
                    {{ 'protocolEdit.instrumentationUse.validator.reason.error' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <br />

        <div fxLayout="column">
            <div fxFlex>
                <mat-checkbox name="showDiffInDeviations" [(ngModel)]="data.validator.showDiffInDeviations">
                    {{ 'protocolEdit.instrumentationUse.validator.showDiffInDeviations.label' | translate }}
                </mat-checkbox>
            </div>
            <div fxFlex>
                <mat-checkbox name="showDiffInObservations" [(ngModel)]="data.validator.showDiffInObservations">
                    {{ 'protocolEdit.instrumentationUse.validator.showDiffInObservations.label' | translate }}
                </mat-checkbox>
            </div>
        </div>

    </div>

    <div mat-dialog-actions class="thermal-theme">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()" cdkFocusInitial>
            {{ 'button.accept' | translate }}
        </button>

        <button mat-raised-button color="secondary" (click)="onNoClick()">
            {{ 'button.cancel' | translate }}
        </button>
    </div>

</form>