import { InternalEquipmentCustomSelectValue } from './internalEquipmentCustomSelectValue';

export class InternalEquipmentCustomFieldValue {
    id: number;

    value: string;
    idEquipment: number;
    idCustomField: number;
    idType: number;
    name: string;
    valueSelect?: InternalEquipmentCustomSelectValue[];
}
