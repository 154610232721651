import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ExecutionAir, ExecutionFilter, ExecutionStatus } from 'src/app/model/execution';
import { Location, ViewportScroller } from '@angular/common';
import { Router, Scroll } from '@angular/router';
import { Subject, merge } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { AccessoryEquipmentTypeService } from 'src/app/services/accessoryEquipmentType.service';
import { AccessoryFilterTypeService } from 'src/app/services/accessoryFilterType.service';
import { CheckWarnsErrorsComponent } from '../../shared/check-warns-errors/check-warns-errors.component';
import { ClientService } from 'src/app/services/client.service';
import { CompressedGasesTypeService } from 'src/app/services/compressedGasesType.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { CriteriaTypeService } from 'src/app/services/criteriaType.service';
import { EquipmentTypeAirService } from 'src/app/services/equipmentTypeAir.service';
import { EssayAirService } from 'src/app/services/essayAir.service';
import { EssayConfigAirService } from 'src/app/services/essayConfigAir.service';
import { ExecutionAirDataSource } from 'src/app/model/executionAirDataSource';
import { ExecutionAirService } from 'src/app/services/executionAir.service';
import { ExecutionEditGenerateReportComponent } from '../execution-edit/execution-edit-generate-report.component';
import { ExecutionReportFilter } from 'src/app/model/attachment';
import { ExecutionStatusService } from 'src/app/services/executionStatus.service';
import { FilterAirTypeService } from 'src/app/services/filterAirType.service';
import { GenericWarnError } from 'src/app/model/genericClass';
import { InternalEquipmentService } from 'src/app/services/internalEquipment.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { OnlineService } from 'src/app/services/online.service';
import { ProcessService } from 'src/app/services/process.service';
import { ProtocolAirService } from 'src/app/services/protocolAir.service';
import { RoomSubtypeAirService } from 'src/app/services/roomSubtypeAir.service';
import { RoomTypeAirService } from 'src/app/services/roomTypeAir.service';
import { SelectGroupToCloneComponent } from '../../shared/select-group-to-clone/select-group-to-clone.component';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ThemeService } from 'src/app/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { UsersService } from 'src/app/services/users.service';
import { VariableTypeService } from 'src/app/services/variableType.service';
import { saveAs } from 'file-saver';
import { GlobalStateService } from '../../../services/globalState.service';
import { AppTypeEnum } from '../../../model/appType';

@Component({
  selector: 'app-execution-list',
  templateUrl: './execution-list.component.html'
})
export class ExecutionListComponent implements OnInit {

  @Input() isProtocolDetail: boolean;
  @Input() idProtocolDetail: number;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: ExecutionAirDataSource;
  filter: ExecutionFilter = new ExecutionFilter();

  currentUser: User;

  hasFiltered = false;
  showCanCloneExecution = false;

  displayedColumns: string[] = [
    'documentCode', 'projectNo', 'area', 'client', 'protocol', 'status', 'endWorkDate', 'realizationDate',
    'download', 'edit', 'delete', 'offline', 'clone', 'clone-exec', 'more'
  ];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private location: Location,
    private accessoryEquipmentTypeService: AccessoryEquipmentTypeService,
    private accessoryFilterTypeService: AccessoryFilterTypeService,
    private compressedGasesTypeService: CompressedGasesTypeService,
    private clientService: ClientService,
    private criteriaTypeService: CriteriaTypeService,
    private equipmentTypeAirService: EquipmentTypeAirService,
    private essayAirService: EssayAirService,
    private essayConfigAirService: EssayConfigAirService,
    private executionService: ExecutionAirService,
    private executionStatusService: ExecutionStatusService,
    private internalEquipmentService: InternalEquipmentService,
    private filterAirTypeService: FilterAirTypeService,
    private processService: ProcessService,
    private protocolAirService: ProtocolAirService,
    private roomTypeService: RoomTypeAirService,
    private roomSubtypeAirService: RoomSubtypeAirService,
    private themeService: ThemeService,
    private translate: TranslateService,
    private usersService: UsersService,
    private variableTypeService: VariableTypeService,
    private userService: UserService,
    private viewportScroller: ViewportScroller,
    private onlineService: OnlineService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private globalStateService: GlobalStateService) {
    this.globalStateService.getTabState('main-tab').subscribe({});
    this.router.events.pipe(filter(e => e instanceof Scroll)).pipe(takeUntil(this.destroy$)).subscribe((e: Scroll) => {
      setTimeout(() => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
    });
  }

  get pageTitle(): string {
    return this.translate.instant('executionList.title') as string;
  }

  ngOnInit(): void {
    this.currentUser = this.userService.currentProfile;

    const excludedCols: string[] = [];

    if (!this.currentUser.showClone) {
      excludedCols.push('clone');
    }

    if (!this.currentUser.showCanCloneExecutions) {
      this.showCanCloneExecution = true;
    }

    this.displayedColumns = this.displayedColumns.filter(c => !excludedCols.includes(c));

    const filterResult = localStorage.getItem('filter');

    if (filterResult) {
      this.hasFiltered = true;
      this.filter = JSON.parse(filterResult) as ExecutionFilter;
    }
    this.loadExecutionList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadExecutionList())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  new(): void {
    void this.router.navigateByUrl('/air/execution?id=0');
  }

  editRow(id: number): void {
    void this.router.navigateByUrl(`/air/execution?id=${id}`);
  }

  canClone(ex: ExecutionAir): boolean {
    return ex?.idStatus === ExecutionStatus.FIRMADO;
  }

  cloneRow(id: number): void {
    const dialogRefSelectGroupToClone = this.dialog.open(SelectGroupToCloneComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRefSelectGroupToClone.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((idGroup: number) => {
      if (idGroup != null) {

        this.spinnerService.show();

        this.executionService.checkCopyToGroup(id, idGroup).subscribe((res: GenericWarnError) => {
          this.spinnerService.hide();

          const dialogRef = this.dialog.open(CheckWarnsErrorsComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {
              warns: res.warns,
              errors: res.errors
            }
          });

          dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((canContinue: boolean) => {
            if (canContinue === true) {
              this.spinnerService.show();
              this.executionService.copyToGroup(id, idGroup).subscribe(() => {
                this.spinnerService.hide();

                this.snackBarService.sendSuccess(this.translate.instant('executionEdit.form.clone.ok') as string);
              }, () => {
                this.spinnerService.hide();
                this.snackBarService.sendError(this.translate.instant('executionEdit.form.clone.error') as string);
              });
            }
          });
        }, () => {
          this.spinnerService.hide();
          this.snackBarService.sendError(this.translate.instant('executionEdit.form.clone.error') as string);
        });
      }
    });
  }

  loadExecutionList(): void {
    this.dataSource = new ExecutionAirDataSource(this.executionService);

    this.filter.sortBy = this.sort.active || 'documentCode';
    this.filter.sortDirection = this.sort.direction || 'desc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 10;

    if (this.hasFiltered) {
      this.saveSearchFilter(this.filter);
      this.dataSource.loadExecutions(this.filter);
    }

    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  getThemeIconPath(): string {
    const url = this.router.url;
    return this.themeService.getThemeIconPathSelection(url);
  }

  doFilter(event: ExecutionFilter): void {
    this.filter = event;

    this.hasFiltered = true;

    this.loadExecutionList();
  }

  showDownloadButton(execution: ExecutionAir): boolean {
    const validStatuses = [ExecutionStatus.PENDIENTE_FIRMA, ExecutionStatus.FIRMADO];

    return validStatuses.includes(execution.idStatus);
  }

  exportTable(): void {
    this.filter.cols = this.displayedColumns;

    this.spinnerService.show();
    this.executionService.getExcel(this.filter).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name = this.pageTitle + '.xlsx';
      saveAs(res, name);
      this.spinnerService.hide();
    }, error => {
      console.error(error);
      this.spinnerService.hide();
    });
  }

  showDeleteButton(execution: ExecutionAir): boolean {
    const validStatuses = [ExecutionStatus.EN_EJECUCION];

    return validStatuses.includes(execution.idStatus) && !this.isProtocolDetail;
  }

  showDownloadOfflineButton(execution: ExecutionAir): boolean {
    const validStatuses = [ExecutionStatus.EN_EJECUCION];

    return validStatuses.includes(execution.idStatus) && this.onlineService.latestOnline;
  }

  downloadPdf(execution: ExecutionAir): void {
    this.spinnerService.show();

    this.executionService.findOne(execution.id).subscribe((ex: ExecutionAir) => {
      this.spinnerService.hide();

      const dialogRef = this.dialog.open(ExecutionEditGenerateReportComponent, {
        minWidth: '40%',
        maxHeight: '95vh',
        data: {
          execution: ex,
          protocol: execution.protocol,
          reason: execution.reason
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionReportFilter) => {
        if (result != null) {
          this.loadExecutionList();
        }
      });
    });
  }

  cancel(): void {
    this.location.back();
  }

  deleteRow(id: number): void {

    if (id > 0) {

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('internalEquipmentList.dialog.delete.confirmation') as string
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
        if (result === true) {

          this.spinnerService.show();

          this.executionService.delete(id).pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.spinnerService.hide();

            this.loadExecutionList();
          });
        }
      });
    }
  }

  downloadOffline(execution: ExecutionAir): void {
    this.spinnerService.show();
    const promises = [];
    promises.push(this.executionService.saveExecutionToDexie(execution.id));
    promises.push(this.accessoryEquipmentTypeService.saveAccessoryTypesToDexie());

    if (execution.idProtocol != null) {
      promises.push(this.protocolAirService.saveProtocolToDexie(execution.idProtocol));
    }

    promises.push(this.compressedGasesTypeService.saveCompressedGasTypeToDexie());
    promises.push(this.criteriaTypeService.saveCriteriaTypeToDexie());
    promises.push(this.criteriaTypeService.saveCriteriaConfigToDexie());
    promises.push(this.criteriaTypeService.saveCustomValuesToDexie());
    promises.push(this.criteriaTypeService.saveCustomSubValuesToDexie());
    promises.push(this.equipmentTypeAirService.saveEquipmentTypeToDexie());
    promises.push(this.essayAirService.saveEssayTypeToDexie());
    promises.push(this.essayAirService.saveEssayFieldsToDexie());
    promises.push(this.essayConfigAirService.saveEssayConfigToDexie());
    promises.push(this.roomTypeService.saveRoomTypeToDexie());
    promises.push(this.internalEquipmentService.saveInternalEquipmentToDexie());
    promises.push(this.roomSubtypeAirService.saveSubRoomTypeToDexie());
    promises.push(this.filterAirTypeService.saveFilterTypeToDexie());
    promises.push(this.accessoryFilterTypeService.saveSubFilterTypeToDexie());
    promises.push(this.usersService.saveRespToDexie());
    promises.push(this.usersService.saveRespFromGroupToDexie());
    promises.push(this.variableTypeService.saveVariableTypeToDexie());
    promises.push(this.variableTypeService.saveVariableUnitToDexie());
    promises.push(this.processService.saveProcessTypeToDexie());
    promises.push(this.executionStatusService.saveExecutionStatusToDexie());
    promises.push(this.clientService.saveClientToDexie(execution.idClient));

    void Promise.all(promises).then(() => {
      this.spinnerService.hide();
      this.snackBarService.sendSuccess(this.translate.instant('execution.offline.download.dexie.ok') as string);
    });
  }

  saveSearchFilter(execution: ExecutionFilter): void {
    localStorage.setItem('filter', JSON.stringify(execution));
  }

  cloneExecution(id: number): void {
    this.spinnerService.show();
    this.executionService.cloneExecution(id).subscribe(data => {
      if (data) {
        const url = `air/execution?id=${data}`;

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        void this.router.navigateByUrl(url);
        this.spinnerService.hide();
      }
    }, () => {
      this.spinnerService.hide();
      this.snackBarService.sendError(this.translate.instant('executionEdit.form.clone.error') as string);
    });
  }
}
