import * as _ from 'lodash-es';

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExecutionAir, ExecutionAttachment, ExecutionAttachmentFilter, ExecutionStatus } from 'src/app/model/execution';
import { Observable, Subject, forkJoin, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { AttachmentAirService } from 'src/app/services/attachmentAir.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { EditAttachmentEditComponent } from '../../shared/edit-attachment-edit/edit-attachment-edit.component';
import { ExecutionAirAttachmentDataSource } from 'src/app/model/executionAirAttachmentDataSource';
import { ExecutionAirService } from 'src/app/services/executionAir.service';
import { GenericClass } from 'src/app/model/genericClass';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-execution-edit-attachments',
  templateUrl: './execution-edit-attachments.component.html'
})
export class ExecutionEditAttachmentsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('attachmentUpload') attachmentUpload: ElementRef;

  dataSource: ExecutionAirAttachmentDataSource;
  public displayedColumns = ['name', 'filename', 'date', 'type', 'edit', 'download', 'delete'];
  filter: ExecutionAttachmentFilter = new ExecutionAttachmentFilter();

  execution: ExecutionAir;

  private destroy$ = new Subject<void>();

  constructor(
    private executionService: ExecutionAirService,
    private attachmentAirService: AttachmentAirService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.dataSource = new ExecutionAirAttachmentDataSource(this.executionService);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadAttachments())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  setExecution(ex: ExecutionAir): void {
    this.execution = ex;

    this.loadAttachments();
  }

  showUploadButton(): boolean {
    const statusFinalized = [ExecutionStatus.FIRMADO];

    return !statusFinalized.includes(this.execution.idStatus);
  }

  uploadAttachment(event): void {
    if (this.execution.id == null) {
      this.snackBarService.sendError(this.translate.instant('executionEdit.attachments.form.upload.error.generic') as string);
    }

    const files = Array.from(event.target.files as FileList);

    const promises: Observable<void>[] = [];

    if (!ArrayUtils.isEmpty(files)) {
      this.spinnerService.show();
      files.forEach(file => promises.push(
        this.attachmentAirService.uploadAttachmentToExecution(this.execution.id, file) as Observable<void>));

      forkJoin(promises).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.loadAttachments();

        this.spinnerService.hide();

        this.snackBarService.sendSuccess(this.translate.instant('executionEdit.attachments.form.upload.ok') as string);
      }, () => {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('executionEdit.attachments.form.upload.error.generic') as string);
      });
    }

    this.attachmentUpload.nativeElement.value = '';
  }

  drop(event: CdkDragDrop<ExecutionAttachment[]>): void {
    this.executionService.moveAttachment(this.execution.id, event.previousIndex, event.currentIndex)
      .subscribe(() => this.loadAttachments(), () => this.loadAttachments());
  }

  editAttachment(att: ExecutionAttachment): void {
    this.attachmentAirService.getAllTypesForExecutionAir().pipe(takeUntil(this.destroy$)).subscribe((types: GenericClass[]) => {

      const dialogRef = this.dialog.open(EditAttachmentEditComponent, {
        minWidth: '50%',
        maxHeight: '95vh',
        data: {
          attachment: _.cloneDeep(att),
          types
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionAttachment) => {
        if (result != null) {

          this.attachmentAirService.updateAttachmentToExecution(result.idExecution, result.id, result.idType, result.name)
            .pipe(takeUntil(this.destroy$)).subscribe(() => {
              this.snackBarService.sendSuccess(this.translate.instant('executionEdit.attachments.form.update.ok') as string);
              this.loadAttachments();
            }, () => {
              this.snackBarService.sendError(this.translate.instant('executionEdit.attachments.form.update.error.generic') as string);
              this.loadAttachments();
            });
        }
      });
    });

  }

  downloadAttachment(idAtt: number, filename: string, idExecution: number): void {
    this.spinnerService.show();
    this.attachmentAirService.downloadAttachmentToExecution(idExecution, idAtt).pipe(takeUntil(this.destroy$)).subscribe((item: Blob) => {
      saveAs(item, filename);

      this.spinnerService.hide();
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.generateReport.form.error.generic') as string);
    }
    );
  }

  deleteAttachment(idAtt: number, idExecution: number): void {
    this.spinnerService.show();
    this.attachmentAirService.deleteAttachmentToExecution(idExecution, idAtt).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.loadAttachments();

      this.spinnerService.hide();

      this.snackBarService.sendSuccess(this.translate.instant('executionEdit.attachments.form.delete.ok') as string);
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('executionEdit.attachments.form.delete.error.generic') as string);
    });
  }

  private loadAttachments(): void {
    const id = this.execution.id;

    if (id == null) {
      this.dataSource = null;
      return;
    }

    this.filter.sortBy = this.sort.active;
    this.filter.sortDirection = this.sort.direction || 'asc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 5;

    this.dataSource.loadExecutionAttachment(id, this.filter);
  }

}
