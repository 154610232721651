import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class BroadcastChannelService {

    private broadcastChannel: BroadcastChannel;

    private MESSAGE_CLOSE_TAB = '¿Cierro la puerta?';
    private MESSAGE_FORCE_LOGOUT = 'Se ha caído Internet, todo el mundo a casa';
    private MESSAGE_NOT_UNIQUE_TAB = 'También está Piedi en la ofi';

    constructor(loginService: LoginService) {
        this.broadcastChannel = new BroadcastChannel(environment.broadcastChannel);

        this.broadcastChannel.onmessage = (message) => {
            if (message.data === this.MESSAGE_CLOSE_TAB) {
                this.broadcastChannel.postMessage(this.MESSAGE_NOT_UNIQUE_TAB);
            } else if(message.data === this.MESSAGE_FORCE_LOGOUT) {
                loginService.logout();
            }
        }
    }

    sendMessageLogout(): void {
        this.broadcastChannel.onmessage = (message) => {
            if (message.data === this.MESSAGE_NOT_UNIQUE_TAB) {
                // TODO: No cerrar pestaña
            }
        }

        this.broadcastChannel.postMessage(this.MESSAGE_CLOSE_TAB);
    }

    sendMessageForceLogout(): void {
        this.broadcastChannel.postMessage(this.MESSAGE_FORCE_LOGOUT);
    }

}
