import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Calibration, CalibrationStatus } from 'src/app/model/calibration';
import { Subject } from 'rxjs';
import { CalibrationReportFilter } from 'src/app/model/attachment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalibrationService } from 'src/app/services/calibration.service';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService, LangFlag } from 'src/app/services/translation.service';
import { InternalEquipmentService } from 'src/app/services/internalEquipment.service';
import { takeUntil } from 'rxjs/operators';
import { CalibrationUtils } from 'src/app/utils/calibratesUtils';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { InternalEquipment } from 'src/app/model/internalEquipment';

export interface DialogDataGenerateReport {
  calibration: Calibration;
  toSign: boolean;
  reason: string;
}

@Component({
  selector: 'app-calibration-edit-generate-report',
  templateUrl: './calibration-edit-generate-report.component.html'
})
export class CalibrationEditGenerateReportComponent implements OnInit, OnDestroy {

  reportConfig: CalibrationReportFilter;
  langs: LangFlag[];

  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CalibrationEditGenerateReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataGenerateReport,
    private calibrationService: CalibrationService,
    private internalEquipmentService: InternalEquipmentService,
    private translate: TranslateService,
    private translationService: TranslationService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.reportConfig = new CalibrationReportFilter();
    this.reportConfig.lang = this.translationService.currentLang;
    this.reportConfig.reason = this.data.reason;

    this.langs = this.translationService.configuredLangs;

    if (this.data.calibration.idStatus === CalibrationStatus.FIRMADO) {
      this.spinnerService.show();

      this.calibrationService.downloadPdfCalibrationVersion(this.data.calibration).pipe(takeUntil(this.destroy$)).subscribe((res: File) => {
        this.downloadFile(this.data.calibration, res);
      }, () => {
        this.snackBarService.sendError(
          this.translate.instant('calibrateEquipmentEdit.dialog.generateReport.form.error.notFound') as string);
        this.spinnerService.hide();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onOkClick(): void {
    this.spinnerService.show();

    this.reportConfig.toSign = this.data.toSign;
    this.reportConfig.reason = this.data.calibration.reason;

    this.calibrationService.downloadPdf(this.data.calibration.id, this.reportConfig).pipe(takeUntil(this.destroy$))
      .subscribe((res: File) => {
        this.downloadFile(this.data.calibration, res);
      }, () => {
        this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.dialog.generateReport.form.error.generic') as string);
        this.spinnerService.hide();
      });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showChangeLangIcon(): boolean {
    return this.langs.length > 1;
  }

  private downloadFile(calibration: Calibration, file: File) {
    this.internalEquipmentService.findOne(calibration.idEquipment).pipe(takeUntil(this.destroy$))
      .subscribe((equipment: InternalEquipment) => {
        const name = CalibrationUtils.getNameForCalibration(this.data.calibration, equipment, this.translate);
        saveAs(file, name);
        this.spinnerService.hide();

        this.dialogRef.close();
      });
  }

}
