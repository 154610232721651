import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../model/user';
import { UserService } from '../services/user.service';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class IsAdminInterceptor implements CanActivate {

    constructor(private userService: UserService, private router: Router, private cookieService: CookieService) { }

    canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {

        return this.userService.profile().pipe(take(1), map((user: User) => {
            if (user.username != null && user.admin) {
                return true;
            } else {
                const rol = this.cookieService.get('rol');
                if (rol === 'guest') {
                  void this.router.navigate(['/login-client']);
                } else {
                    void this.router.navigate(['/login']);
                }
                return false;
            }
        }));
    }
}
