import { Component, OnInit, Inject } from '@angular/core';
import { CalibrationVariableConfig, CalibrationAsLeft } from 'src/app/model/calibration';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { SnackBarService } from 'src/app/services/snackBar.service';

export interface DialogDataCalibrationConfig {
  item: CalibrationVariableConfig;
}

@Component({
  selector: 'app-calibration-edit-config-dialog',
  templateUrl: './calibration-edit-config-dialog.component.html'
})
export class CalibrationEditConfigDialogComponent implements OnInit {

  uniqueRange: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCalibrationConfig,
    public dialogRef: MatDialogRef<CalibrationEditConfigDialogComponent>,
    private translate: TranslateService,
    public snackBarService: SnackBarService) { }

  ngOnInit(): void {
    if (ArrayUtils.isEmpty(this.data.item.asLeft)) {
      this.data.item.asLeft = [];

      this.newValue();
    }

    this.uniqueRange = this.data.item.rangeInit != null && this.data.item.rangeEnd == null;
  }

  newValue(): void {
    this.data.item.asLeft.push(new CalibrationAsLeft());
  }

  removeValue(index: number): void {
    this.data.item.asLeft.splice(index, 1);
  }

  public setValue(index: number, value: number): void {
    this.data.item.asLeft[index].point = +value;
  }

  onOkClick(): void {

    const errs = [];

    if (this.data.item.rangeInit == null) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.calibrationData.range.init.error'));
    }

    if (!this.uniqueRange && this.data.item.rangeEnd == null) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.calibrationData.range.end.error'));
    }

    if (this.data.item.resolution == null) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.calibrationData.resolution.error'));
    }

    if (this.data.item.uncertaintyResolution == null) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.calibrationData.resolutionU.error'));
    }

    if (ArrayUtils.isEmpty(this.data.item.asLeft)) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.asLeft.dialog.values.error'));
    } else {
      if (this.data.item.asLeft.filter(v => v === null || v.point === null).length !== 0) {
        errs.push(this.translate.instant('calibrateEquipmentEdit.calibrationData.point.error'));
      }
    }

    if (errs.length === 0) {
      if (this.uniqueRange) {
        this.data.item.rangeEnd = null;
      }

      this.dialogRef.close(this.data.item);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
