import { EssayExecutionResultAir } from './essayExecutionResultAir';

export class Criteria {
    id: number;

    criteriaTranslation: string;

    idType: number;
    criteriaValue1: number;
    criteriaValue2: number;

    customValue1: string;
    customValue2: string;

    idTypeProtocol: number;
    criteriaValueProtocol1: number;
    criteriaValueProtocol2: number;
    criteriaValueChangeReason: string;

    showDiffInDeviations: boolean;
    showDiffInObservations: boolean;

    fromProtocol = true;

    values: EssayExecutionResultAir[] = [];

    showMkt: boolean;

    according = false;
}
