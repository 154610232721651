import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class VersionCheckService {

    // this will be replaced by actual hash post-build.js
    private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

    constructor(private http: HttpClient) { }

    /**
     * Checks in every set frequency the version of frontend application
     *
     * @param url The url to check
     * @param frequency - in milliseconds, defaults to 5 minutes
     */
    public initVersionCheck(url: string, frequency = 1000 * 60 * 5): void {
        setInterval(() => this.checkVersion(url), frequency);
        this.checkVersion(url);
    }

    /**
     * Will do the call and check if the hash has changed or not
     *
     * @param url The url to check
     */
    private checkVersion(url: string) {

        // If url is null, we won't check anything
        if (url == null) {
            console.log('Null URL. Don\'t checking version.');
            return;
        }

        // timestamp these requests to invalidate caches
        const urlCheck = `${url}?t=${new Date().getTime()}`;
        this.http.get(urlCheck).subscribe((response: any) => {
            const hash = response.hash as string;
            const hashChanged = this.hasHashChanged(this.currentHash, hash);
            // If new version, do something
            if (hashChanged) {
                // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
                // for an example: location.reload();
                if ('caches' in window) {
                    void caches.keys().then(names => {
                        for (const name of names) {
                            void caches.delete(name);
                        }
                    });
                }

                if (window.navigator && navigator.serviceWorker) {
                    void navigator.serviceWorker.getRegistrations().then(registrations => {
                        for (const registration of registrations) {
                            void registration.unregister();
                        }
                    });
                }

                location.reload(); // FIXME: Recargar sin caché
            }
            // store the new hash so we wouldn't trigger versionChange again
            // only necessary in case you did not force refresh
            this.currentHash = hash;
        }, err => console.error(err, 'Could not get version'));
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     *
     * @param currentHash The current hash
     * @param newHash The new hash
     */
    private hasHashChanged(currentHash: string, newHash: string): boolean {
        if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }
        return currentHash !== newHash;
    }
}
