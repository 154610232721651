<h1 mat-dialog-title>{{ 'protocolEdit.dialog.documentCode.title' | translate }}</h1>

<div mat-dialog-content class="thermal-theme">

  <div fxLayout="row" class="flexwrap">
    <div fxFlex>
      <mat-form-field class="campoFormulario">
        <input matInput name="documentCode" [(ngModel)]="data.documentCode" required
          placeholder="{{ 'protocolEdit.dialog.documentCode.documentCode.label' | translate }}">
        <mat-error>
          {{ 'protocolEdit.dialog.documentCode.documentCode.error' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

</div>

<div mat-dialog-actions align="end" class="getTheme()">
  <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
  <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
</div>