import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionConfirmPasswordComponent } from 'src/app/components/shared/action-confirm-password/action-confirm-password.component';
import { ProtocolQ } from 'src/app/model/protocol';
import { ProtocolService } from 'src/app/services/protocol.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ProtocolQEditGenerateReportComponent } from '../protocol-q-edit-generate-report/protocol-q-edit-generate-report.component';
import { ProtocolQService } from 'src/app/services/protocolQ.service';

export interface DialogDataManualSignProtocol {
  protocol: ProtocolQ;
  showReason: boolean;
}

@Component({
  selector: 'app-protocol-q-edit-dialog-manual-sign',
  templateUrl: './protocol-q-edit-dialog-manual-sign.component.html'
})
export class ProtocolQEditDialogManualSignComponent implements OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<ProtocolQEditDialogManualSignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataManualSignProtocol,
    private protocolQService: ProtocolQService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    if (data.showReason == null) {
      data.showReason = false;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  downloadDocument(): void {
    this.dialog.open(ProtocolQEditGenerateReportComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {
        protocol: this.data.protocol,
        toSign: true
      }
    });
  }

  onOkClick(event): void {
    const file = event.target.files[0] as File;

    const dialogRefPassword = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRefPassword.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
      this.spinnerService.show();

      if (token != null) {
        this.protocolQService.manualSign(this.data.protocol, file, token).pipe(takeUntil(this.destroy$)).subscribe(() => {

          this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.sign.ok') as string);

          this.spinnerService.hide();

          this.dialogRef.close(this.data);
        }, () => {
          this.snackBarService.sendError(this.translate.instant('protocolEdit.form.sign.error') as string);

          this.spinnerService.hide();
        });
      } else {
        this.spinnerService.hide();
      }

    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
