<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form [formGroup]="form">
  <div mat-dialog-content class="thermal-theme">

    <div fxLayout="row" class="flexwrap">

      <div fxFlex="100%">
        <mat-form-field class="campoFormulario">
            <mat-label>{{ 'protocolEdit.dialog.equipment.type.label' | translate }}</mat-label>
            <mat-select required formControlName="idType" [(ngModel)]="data.equipment.idType">
                <mat-option *ngFor="let item of equipmentTypes" [value]="item.id">
                    {{ 'equipmentType.' + item.translation | translate }}
                </mat-option>
            </mat-select>
            <mat-error>
                {{ 'protocolEdit.dialog.equipment.type.error' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.equipment.label' | translate }}"
            formControlName="equipment" [(ngModel)]="data.equipment.equipment" required [errorStateMatcher]="matcher">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.equipment.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.maker.label' | translate }}"
            formControlName="maker" [(ngModel)]="data.equipment.maker" [errorStateMatcher]="matcher">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.maker.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.model.label' | translate }}"
            formControlName="model" [(ngModel)]="data.equipment.model" [errorStateMatcher]="matcher">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.model.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.serialNum.label' | translate }}"
            formControlName="serialNum" [(ngModel)]="data.equipment.serialNum" [errorStateMatcher]="matcher">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.serialNum.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.internalId.label' | translate }}"
            formControlName="internalId" [(ngModel)]="data.equipment.internalId" [errorStateMatcher]="matcher">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.internalId.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.location.label' | translate }}"
            formControlName="location" [(ngModel)]="data.equipment.location" required [errorStateMatcher]="matcher">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.location.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

    </div>

  </div>
  <div mat-dialog-actions class="thermal-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>