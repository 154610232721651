<div>
    <div style="width: 350px;"></div>

    <div class="side-bar-fixed">
        <div *ngIf="showSideBarFixed()">
            <mat-nav-list class="side-bar-fixed-item">

                <span style="padding: 10px; font-weight: 700;">Selecciona servicio</span>
                <br>
                <br>
                <button class="button-selected-app" [matMenuTriggerFor]="selectedApp" [disabled]="disableOfflineButton">
                    <div>
                        <img class="size-img-sidebar" *ngIf="appActive != null"
                            [src]="'assets/img/text-'+appActive?.toLowerCase()+'.png'" />
                        <mat-icon role="img" class="mat-icon notranslate material-icons"
                            aria-hidden="true">arrow_drop_down
                        </mat-icon>
                    </div>
                </button>
                <mat-menu #selectedApp="matMenu">
                    <div *ngFor="let item of profils" class="container-menu-main">
                        <img width="35%" [src]="'assets/img/text-'+item.appName?.toLowerCase()+'.png'"
                            (click)="onSelectionOption(item.idApp)" />
                    </div>
                </mat-menu>

                <a class="first-level" mat-list-item (click)="redirectionPage()">
                    <mat-icon class="icon-menu-item-sidebar">dashboard</mat-icon>
                    {{ 'menu.home' | translate }}
                </a>

                <a class="first-level" mat-list-item *ngIf="showAirItem()"
                    [routerLink]="checkRouterDisabledOffline('/protocols')">
                    <img class="icon-menu-item-sidebar" src="{{ getThemeIconPath() }}protocols.svg">
                    {{ 'menu.manage-protocols' | translate }}
                </a>

                <a class="first-level" mat-list-item *ngIf="showAirItem()"
                    [routerLink]="checkRouterDisabledOffline('/executions')">
                    <img class="icon-menu-item-sidebar" src="{{ getThemeIconPath() }}executions.svg">
                    {{ 'menu.service-executions' | translate }}
                </a>

                <!-- Thermal-->
                <mat-expansion-panel *ngIf="showThermalItem()" class="exp-panel">
                    <mat-expansion-panel-header>
                        <a class="first-level" fxFlex [routerLink]="checkRouterDisabledOffline('/protocols')">
                            <img class="icon-menu-item-sidebar" src="{{ getThemeIconPath() }}protocols.svg">
                            <div class="textAlign">
                                <span>{{ 'menu.manage-protocols' | translate }}</span>
                            </div>
                        </a>
                    </mat-expansion-panel-header>
                    <div *ngFor="let item of phases; let index = index">
                        <a mat-list-item class="first-level-submenu-item" (click)="goToProtocol(item)"
                            *ngIf="item.enabled">{{ 'phase.'+ item.translation | translate }}</a>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel *ngIf="showThermalItem()" class="exp-panel">
                    <mat-expansion-panel-header>
                        <a class="first-level" fxFlex [routerLink]="checkRouterDisabledOffline('/executions')">
                            <img class="icon-menu-item-sidebar" src="{{ getThemeIconPath() }}executions.svg">
                            <div class="textAlign">
                                <span> {{ 'menu.service-executions' | translate }}</span>
                            </div>
                        </a>
                    </mat-expansion-panel-header>
                    <div *ngFor="let item of phases; let index = index">
                        <a mat-list-item class="first-level-submenu-item" (click)="goToExecutions(item);"
                            *ngIf="item.enabled">{{ 'phase.'+
                            item.translation | translate }}</a>
                    </div>
                </mat-expansion-panel>

                <a class="first-level" mat-list-item *ngIf="showCalibratesItem()"
                    [routerLink]="checkRouterDisabledOffline('/calibrations')"
                    [class.disabled]="disableOfflineButton ? null: true">
                    <img class="icon-menu-item-sidebar" src="{{ getThemeIconPath() }}calibrates.svg">
                    {{ 'menu.calibrate-equipments' | translate }}
                </a>

                <a class="first-level" mat-list-item
                    [routerLink]="checkRouterDisabledOffline('/isothermalCharacterizations')"
                    [class.disabled]="disableOfflineButton ? null: true" *ngIf="showCharacterizationItem()">
                    <img class="icon-menu-item-sidebar" src="{{ getThemeIconPath() }}isothermalCharacterization.svg">
                    {{ 'menu.isothermal-characterization' | translate }}
                </a>

                <mat-expansion-panel class="exp-panel">
                    <mat-expansion-panel-header>
                        <a class="first-level" fxFlex [routerLink]="checkRouterDisabledOffline('/internalEquipments')"
                            [class.disabled]="disableOfflineButton ? null: true">
                            <img class="icon-menu-item-sidebar" src="{{ getThemeIconPath() }}internalEquipments.svg">
                            <div class="textAlign">
                                <span style="margin: auto;">{{ 'menu.my-equipments' | translate }}</span>
                            </div>
                        </a>
                    </mat-expansion-panel-header>
                    <a class="second-level" mat-list-item
                        [routerLink]="checkRouterDisabledOffline('/admin/calibrationPlan')"
                        *ngIf="showCalibrationPlan()" [class.disabled]="disableOfflineButton ? null: true">
                        {{ 'menu.manage-calibratioPlan' | translate }}
                    </a>
                    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                        <!-- This is the tree node template for leaf nodes -->
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="5">
                            <!-- use a disabled button to provide padding for tree leaf -->
                            <button mat-icon-button disabled></button>
                            <a style="cursor: pointer;" (click)="goToInternalEquipment(node);">
                                {{ node.name | translate }}
                            </a>
                        </mat-tree-node>
                        <!-- This is the tree node template for expandable nodes -->
                        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding
                            matTreeNodePaddingIndent="10">
                            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                </mat-icon>
                            </button>
                            <a style="cursor: pointer;" (click)="goToInternalEquipment(node);">
                                {{ node.name | translate }}
                            </a>
                        </mat-tree-node>
                    </mat-tree>

                </mat-expansion-panel>

                <a class="first-level" mat-list-item *ngIf="showCalibratesItem() && user.showExternalEquipments"
                    [routerLink]="checkRouterDisabledOffline('/externalEquipments')"
                    [class.disabled]="disableOfflineButton ? null: true">
                    <img class="icon-menu-item-sidebar" src="{{ getThemeIconPath() }}externalEquipments.svg">
                    {{ 'menu.manage-external-equipments' | translate }}
                </a>

                <mat-divider *ngIf="(user.admin || showManageClients()) && !this.disableOfflineButton"></mat-divider>

                <mat-expansion-panel *ngIf="(user.admin || showManageClients()) && !this.disableOfflineButton" class="exp-panel">
                    <mat-expansion-panel-header>
                        <a class="first-level" [class.disabled]="disableOfflineButton ? null: true">
                            <mat-icon class="icon-menu-item-sidebar">settings</mat-icon>
                            <div class="textAlign">
                                <span> {{ 'menu.manage-setting' | translate }}</span>
                            </div>
                        </a>
                    </mat-expansion-panel-header>
                    <a class="second-level" mat-list-item [routerLink]="checkRouterDisabledOffline('/clients')"
                        *ngIf="showManageClients()" [class.disabled]="disableOfflineButton ? null: true">
                        <div class="textAlign">
                            {{ 'menu.manage-clients' | translate }}
                        </div>
                    </a>

                    <a class="second-level" mat-list-item
                        [routerLink]="checkRouterDisabledOffline('/admin/manageUsers')" *ngIf="user.admin"
                        [class.disabled]="disableOfflineButton ? null: true">
                        <div class="textAlign">
                            {{ 'menu.manage-users' | translate }}
                        </div>
                    </a>

                    <a class="second-level" mat-list-item
                        [routerLink]="checkRouterDisabledOffline('/admin/manageGroups')" *ngIf="user.admin"
                        [class.disabled]="disableOfflineButton ? null: true">
                        <div class="textAlign">
                            {{ 'menu.manage-groups' | translate }}
                        </div>
                    </a>

                    <a class="second-level" mat-list-item [routerLink]="checkRouterDisabledOffline('/admin/procedure')"
                       *ngIf="user.admin" [class.disabled]="disableOfflineButton ? null: true">
                        <div class="textAlign">
                            {{ 'menu.manage-procedures' | translate }}
                        </div>
                    </a>
                </mat-expansion-panel>

                <mat-expansion-panel *ngIf="(user.admin || showManageClients()) && !this.disableOfflineButton" class="exp-panel">
                    <mat-expansion-panel-header>
                        <a class="first-level" [class.disabled]="disableOfflineButton ? null: true">
                            <mat-icon class="icon-menu-item-sidebar">notifications_active</mat-icon>
                            <div class="textAlign">
                                <span style="margin: auto;"> {{ 'menu.item.manage-notifications' | translate}}</span>
                            </div>
                        </a>
                    </mat-expansion-panel-header>
                    <a class="second-level" mat-list-item
                        [routerLink]="checkRouterDisabledOffline('/admin/notifications')"
                        *ngIf="user.admin || showManageClients()" [class.disabled]="disableOfflineButton ? null: true">
                        {{ 'menu.manage-notifications' | translate }}
                    </a>
                    <a class="second-level" mat-list-item
                        [routerLink]="checkRouterDisabledOffline('/admin/sendNotifications')"
                        *ngIf="user.admin && !user.manager" [class.disabled]="disableOfflineButton ? null: true">
                        {{ 'menu.send-notifications' | translate }}
                    </a>
                </mat-expansion-panel>

                <a class="first-level" mat-list-item [routerLink]="checkRouterDisabledOffline('/admin/manageAppPlan')"
                    *ngIf="(user.admin && !user.manager) && !this.disableOfflineButton" [class.disabled]="disableOfflineButton ? null: true">
                    <mat-icon class="icon-menu-item-sidebar">work_outline</mat-icon>
                    {{ 'menu.manage-appPlan' | translate }}
                </a>

                <a class="first-level" mat-list-item [matMenuTriggerFor]="lang" *ngIf="showChangeLangIcon() && !this.disableOfflineButton">
                    <mat-icon class="icon-menu-item-sidebar">language</mat-icon>
                    <mat-icon svgIcon="{{activeFlag}}"></mat-icon>
                    <span style="margin-left: 10px;">{{activeNameFlag}}</span>
                    <mat-menu #lang="matMenu">
                        <div *ngFor="let item of langs">
                            <button mat-menu-item *ngIf="(translationService.lang$ | async) != item.lang"
                                (click)="changeLang(item)">
                                <mat-icon svgIcon="{{item.flag}}"></mat-icon>
                                <span>{{item.fullLang}}</span>
                            </button>
                        </div>
                    </mat-menu>
                </a>
            </mat-nav-list>
        </div>
    </div>
</div>