import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Calibration, CalibrationAudit, CalibrationAuditFilter } from 'src/app/model/calibration';
import { Subject, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { CalibrationAuditDataSource } from 'src/app/model/calibrationAuditDataSource';
import { CalibrationService } from 'src/app/services/calibration.service';
import { CalibrationUtils } from 'src/app/utils/calibratesUtils';
import { InternalEquipment } from 'src/app/model/internalEquipment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-calibration-edit-audit',
  templateUrl: './calibration-edit-audit.component.html'
})
export class CalibrationEditAuditComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() idCalibration: number;
  @Input() documentCode: string;
  @Input() calibration: Calibration;
  @Input() equipment: InternalEquipment;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public filter: CalibrationAuditFilter;

  public displayedColumns = ['date', 'username', 'action', 'status', 'currentVersion', 'signedVersion', 'changes', 'download'];
  public dataSource: CalibrationAuditDataSource;

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private calibrationService: CalibrationService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    this.spinnerService.show();
  }

  ngOnInit(): void {
    this.filter = new CalibrationAuditFilter();
    this.getAudit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.getAudit())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  getAudit(): void {

    this.dataSource = new CalibrationAuditDataSource(this.calibrationService);

    this.filter.sortBy = this.sort.active || 'date';
    this.filter.sortDirection = this.sort.direction || 'asc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 10;

    this.dataSource.loadCalibrationAudit(this.idCalibration, this.filter);
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));

  }

  downloadAuditPdf(): void {
    this.spinnerService.show();

    this.calibrationService.downloadAuditPdf(this.idCalibration).pipe(takeUntil(this.destroy$)).subscribe(
      (res: Blob) => saveAs(res, this.translate.instant('calibrateEquipmentEdit.files.audit', { name: this.documentCode }) as string),
      error => console.error(error),
      () => this.spinnerService.hide());
  }

  showDownloadSignedPdf(calibration: CalibrationAudit): boolean {
    return calibration.canDownload;
  }

  downloadSignedPdf(calibration: CalibrationAudit): void {

    this.spinnerService.show();

    this.calibrationService.downloadPdfVersion(this.idCalibration, calibration.signedVersion).pipe(takeUntil(this.destroy$))
      .subscribe((res: Blob) => {
        const name = this.getNameForCalibrationAudit();
        saveAs(res, name);
        this.spinnerService.hide();
      }, () => {
        this.snackBarService.sendError(
          this.translate.instant('calibrateEquipmentEdit.dialog.generateReport.form.error.notFound') as string);
        this.spinnerService.hide();
      });
  }

  private getNameForCalibrationAudit(): string {
    return CalibrationUtils.getNameForCalibration(this.calibration, this.equipment, this.translate);
  }

}
