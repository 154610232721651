import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxRangeSelectorModule } from 'devextreme-angular/ui/range-selector';

const modules = [
  DxChartModule, DxSelectBoxModule, DxRangeSelectorModule
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,

    ...modules

  ],
  exports: [
    ...modules,
    BrowserModule,
    BrowserAnimationsModule
  ]
})
export class DevextremeModule { }
