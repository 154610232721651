import { HttpClient } from '@angular/common/http';

import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Procedure, ProcedureFilter } from '../model/procedure';

@Injectable()
export class ProcedureService {

    constructor(private http: HttpClient) {
    }

    findAll(): Observable<any> {
        const url = environment.calibratesUrl + '/procedure/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    filter(filter: ProcedureFilter): Observable<any> {
        const url = environment.calibratesUrl + '/procedure/';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    delete(id: number): Observable<any> {
        const url = `${environment.calibratesUrl}/procedure/${id}`;
        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    save(procedure: Procedure): Observable<any> {
        let url = environment.calibratesUrl + '/procedure/';

        if (procedure.id != null && procedure.id !== undefined) {
            url += procedure.id;

            return this.http.put(url, procedure).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            url += 'create';

            return this.http.post(url, procedure).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        }
    }

    existsByName(name: string): Observable<any> {
        const url = environment.calibratesUrl + '/procedure/existByName/' + name;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
    /*
    downloadAuditPdf(id: number): Observable<any> {
        const url = environment.calibratesUrl + '/report/procedure/' + id + '/audit';

        return this.http.get(url, { responseType: 'blob' });
    }
    */
}
