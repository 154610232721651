import { Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTable } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import * as _ from 'lodash-es';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExternalCalibration, ExternalCalibrationValue } from 'src/app/model/calibration';
import { VariableUnit } from 'src/app/model/variable';
import { AttachmentCalibratesService } from 'src/app/services/attachmentCalibrates.service';
import { ExternalCalibrationService } from 'src/app/services/externalCalibration.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ThemeService } from 'src/app/services/theme.service';
import { VariableTypeService } from 'src/app/services/variableType.service';
import { CustomDatepickerHeaderComponent } from '../../shared/datepicker-custom-header/datepicker-custom-header.component';
import { InternalEquipmentEditCreateCalibrationValueComponent } from './internal-equipment-edit-create-calibration-value.component';

export interface DialogDataExternalCalibration {
  item: ExternalCalibration;
  idEquipment: number;
  nameEquipment: string;
}

@Component({
  selector: 'app-internal-equipment-edit-create-calibration',
  templateUrl: './internal-equipment-edit-create-calibration.component.html'
})
export class InternalEquipmentEditCreateCalibrationComponent implements OnInit, OnDestroy {

  @ViewChildren('valuesTable') asFoundTables: MatTable<any> | QueryList<MatTable<any>>;

  valuesCols = ['number', 'point', 'average', 'correction', 'U', 'edit', 'delete'];

  customDatepickerHeader = CustomDatepickerHeaderComponent;

  uploadFile: File = null;

  variableUnits: VariableUnit[];

  isEdit: boolean;

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogDataExternalCalibration,
    public dialogRef: MatDialogRef<InternalEquipmentEditCreateCalibrationComponent>,
    private externalCalibrationService: ExternalCalibrationService,
    private attachmentCalibratesService: AttachmentCalibratesService,
    private variableTypeService: VariableTypeService,
    private translate: TranslateService,
    private themeService: ThemeService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    this.isEdit = this.data.item.id != null;
  }

  ngOnInit(): void {
    this.onVariableTabChange(0);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  saveDate(field: string, event: any): void {

    let value: Date | string = event.value;
    if (value != null) {

      if (value instanceof Date) {
        value = moment(value).format('DD/MM/YYYY');
      }

      const date = moment(value + ' 12:00:00 + 0:00', 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }

      this.data.item[field] = date;
    } else {
      this.data.item[field] = null;
    }

  }

  onVariableTabChange(event: MatTabChangeEvent | number): void {

    let index: number;

    if (event instanceof MatTabChangeEvent) {
      index = event.index;
    } else {
      index = event;
    }

    const tab = this.data.item.variables[index];

    this.variableUnits = [];

    if (tab != null && tab.idVariable != null) {
      this.variableTypeService.findUnits(tab.idVariable).pipe(takeUntil(this.destroy$)).subscribe((res: VariableUnit[]) => {
        this.variableUnits = res;

        if (tab.idUnit == null || !this.variableUnits.map(v => v.id).includes(tab.idUnit)) {
          this.onUnitChange(index, res[0]);
        }
      });
    }

  }

  onUnitChange(indexVariable: number, event: MatSelectChange | VariableUnit): void {

    const variable = this.data.item.variables[indexVariable];

    let unit: VariableUnit;

    if (event instanceof MatSelectChange) {
      unit = this.variableUnits.find(v => v.id === event.value);
    } else {
      unit = event;
    }

    if (variable != null && unit != null) {
      variable.idUnit = unit.id;
      variable.unitName = unit.unit;

      this.data.item.variables[indexVariable] = variable;
    }
  }

  newValueRow(indexVariable: number): void {
    const variable = this.data.item.variables[indexVariable];

    const dialogRef = this.dialog.open(InternalEquipmentEditCreateCalibrationValueComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        item: new ExternalCalibrationValue()
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExternalCalibrationValue) => {

      if (result != null) {
        variable.values.push(result);

        this.updateTable(this.asFoundTables);
      }
    });
  }

  editValueRow(indexVariable: number, index: number): void {
    const variable = this.data.item.variables[indexVariable];

    const row = variable.values[index];

    const dialogRef = this.dialog.open(InternalEquipmentEditCreateCalibrationValueComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        item: _.cloneDeep(row)
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExternalCalibrationValue) => {

      if (result != null) {
        variable.values[index] = result;

        this.updateTable(this.asFoundTables);
      }
    });
  }

  uploadCertificateToEquipment(event): void {
    const file = event.target.files[0] as File;

    if (file == null) {
      return;
    }

    this.data.item.uploadFile = file;
  }

  downloadCertificateEquipment(): void {
    this.spinnerService.show();

    this.attachmentCalibratesService.downloadCertificateExternalCalibration(this.data.item.id).pipe(takeUntil(this.destroy$))
      .subscribe((res: Blob) => {
        saveAs(res, this.data.nameEquipment + '.pdf');
        this.spinnerService.hide();
      }, error => {
        console.error(error);
        this.spinnerService.hide();
      });

  }

  deleteValueRow(indexVariable: number, index: number): void {
    const variable = this.data.item.variables[indexVariable];
    variable.values.splice(index, 1);
    this.updateTable(this.asFoundTables);
  }

  onOkClick(): void {
    const errs = [];

    if (this.data.item.certificateNum == null) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.general.certificateNum.error'));
    }
    if (this.data.item.calibrationDate == null) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.general.calibrateDate.error'));
    }
    if (this.data.item.expirationDate == null) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.general.expirationDate.error'));
    }
    if (this.data.item.variables.some(vari => vari.idUnit == null)) {
      errs.push(this.translate.instant('calibrateEquipmentEdit.calibrationData.unit.error'));
    }

    this.data.item.idEquipment = this.data.idEquipment;

    if (errs.length === 0) {
      this.dialogRef.close(this.data.item);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
      this.spinnerService.hide();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTheme(): string {
    return this.themeService.getTheme();
  }

  private updateTable(table: MatTable<any> | QueryList<MatTable<any>>) {
    if (table instanceof QueryList) {
      table.forEach(t => t.renderRows());
    } else {
      table.renderRows();
    }
  }

}
