<a class="button-bottom-sheet" mat-list-item (click)="openMassiveUploadPhotoDialog()"
    [disabled]="disableUploadPhotoButton()">
    <span mat-line>
        <mat-icon style="max-height: 20px; vertical-align: text-bottom;">camera_alt</mat-icon>
        <span> {{ 'button.massive-upload-photo' | translate }} </span>
    </span>
</a>

<a class="button-bottom-sheet" mat-list-item (click)="uploadExcelMassive.click()"
    [disabled]="disableUploadExcelButton()">
    <span mat-line>
        <mat-icon style="max-height: 20px; vertical-align: text-bottom;">insert_chart</mat-icon>
        <span> {{ 'button.massive-upload-excel' | translate }} </span>
    </span>
</a>
<input accept=".xlsx, .xls, .csv, .pdf" hidden type="file" (change)="onExcelMassiveUploadSensor($event)"
    #uploadExcelMassive>

<a class="button-bottom-sheet" mat-list-item (click)="openMassiveUploadPrimaryDataDialog()"
    [disabled]="disableUploadOriginalButton()">
    <span mat-line>
        <mat-icon style="max-height: 20px; vertical-align: text-bottom;" svgIcon="doc_lock"> </mat-icon>
        <span> {{ 'button.massive-upload-primary' | translate }} </span>
    </span>
</a>

<a class="button-bottom-sheet" mat-list-item (click)="uploadAutomaticMassiveFile.click()"
    [disabled]="disableUploadExcelButton()">
    <span mat-line>
        <mat-icon style="max-height: 20px; vertical-align: text-bottom;">cloud_circle</mat-icon>
        <span> {{ 'button.massive-upload-automatic' | translate }} </span>
    </span>
</a>
<input accept="*" hidden type="file" multiple (change)="uploadAutomaticMassive($event)" #uploadAutomaticMassiveFile>