import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

const modules = [
  CKEditorModule
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,

    ...modules

  ],
  exports: [
    ...modules,
    BrowserModule,
    BrowserAnimationsModule
  ]
})
export class CKEditor5Module { }
