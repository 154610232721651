import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { ExecutionAir, ExecutionFilter } from './execution';
import { catchError, finalize } from 'rxjs/operators';

import { ExecutionAirService } from '../services/executionAir.service';
import { of } from 'rxjs';

export class ExecutionAirDataSource implements DataSource<ExecutionAir> {

    private executionsSubject = new BehaviorSubject<ExecutionAir[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public loading$ = this.loadingSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public totalItems$ = this.totalSubject.asObservable();

    constructor(private service: ExecutionAirService) { }

    connect(_collectionViewer: CollectionViewer): Observable<ExecutionAir[]> {
        return this.executionsSubject.asObservable();
    }

    disconnect(_collectionViewer: CollectionViewer): void {
        this.executionsSubject.complete();
        this.loadingSubject.complete();
        this.totalSubject.complete();
    }

    loadExecutions(filter = new ExecutionFilter()): void {

        this.loadingSubject.next(true);

        this.service.findAll(filter).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(data => {
            if (data && data.content) {
                this.executionsSubject.next(data.content as ExecutionAir[]);
                this.totalSubject.next(data.totalElements as number);
            } else if (data) {
                this.executionsSubject.next(data as ExecutionAir[]);
                this.totalSubject.next(data.length as number);
            } else {
                this.executionsSubject.next([]);
                this.totalSubject.next(0);
            }
        });
    }

}
