<h1 mat-dialog-title></h1>
<form>
  <div mat-dialog-content class="calibrates-theme">

    <div fxLayout="column" class="flexwrap">

      <div fxFlex>
        <mat-form-field class="campoFormulario">
          <input matInput name="point" [(ngModel)]="data.item.point" required
            placeholder="{{ 'calibrateEquipmentEdit.asFound.dialog.point.label' | translate }}">
          <mat-error>
            {{ 'calibrateEquipmentEdit.asFound.dialog.point.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex>
        <mat-form-field class="campoFormulario">
          <input matInput name="pattern" [(ngModel)]="data.item.pattern" required pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="{{ 'calibrateEquipmentEdit.asFound.dialog.pattern.label' | translate }}"
            (blur)="onChangePattern($event.target.value)">
          <mat-error>
            {{ 'calibrateEquipmentEdit.asFound.dialog.pattern.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex>
        <mat-form-field class="campoFormulario">
          <input matInput name="equipment" [(ngModel)]="data.item.equipment" required
            placeholder="{{ 'calibrateEquipmentEdit.asFound.dialog.equipment.label' | translate }}"
            (blur)="onChangeValue($event.target.value)">
          <mat-error>
            {{ 'calibrateEquipmentEdit.asFound.dialog.equipment.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

    </div>

  </div>
  <div mat-dialog-actions class="calibrates-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()" cdkFocusInitial>{{ 'button.save' | translate
      }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>