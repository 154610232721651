import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InternalEquipmentEditComponent } from './internal-equipment-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '../../shared/shared.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { InternalEquipmentEditCreateCalibrationComponent } from './internal-equipment-edit-create-calibration.component';
import { InternalEquipmentEditCreateCalibrationValueComponent } from './internal-equipment-edit-create-calibration-value.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

@NgModule({
    declarations: [InternalEquipmentEditComponent, InternalEquipmentEditCreateCalibrationComponent,
        InternalEquipmentEditCreateCalibrationValueComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        /* Custom modules */
        AngularMaterialModule,
        SharedModule,
        PipesModule,
        /* Otros */
        FlexLayoutModule,
        MatDatepickerModule, MatMomentDateModule
    ],
    providers: [
        {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
      ],
    exports: [InternalEquipmentEditComponent]
})
export class InternalEquipmentEditModule { }
