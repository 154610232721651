import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { ManageNotificationFilter, NotificationManage } from './notification';
import { catchError, finalize } from 'rxjs/operators';

import { ManageNotificationsService } from '../services/manageNotifications.service';
import { of } from 'rxjs';

export class ManageNotificationDataSource implements DataSource<NotificationManage> {

    private groupsSubject = new BehaviorSubject<NotificationManage[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public loading$ = this.loadingSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public totalItems$ = this.totalSubject.asObservable();

    constructor(private service: ManageNotificationsService) { }

    connect(_collectionViewer: CollectionViewer): Observable<NotificationManage[]> {
        return this.groupsSubject.asObservable();
    }

    disconnect(_collectionViewer: CollectionViewer): void {
        this.groupsSubject.complete();
        this.loadingSubject.complete();
        this.totalSubject.complete();
    }

    loadNotifications(filter = new ManageNotificationFilter()): void {

        this.loadingSubject.next(true);

        this.service.findAll(filter).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(data => {
            if (data && data.content) {
                this.groupsSubject.next(data.content as NotificationManage[]);
                this.totalSubject.next(data.totalElements as number);
            } else if (data) {
                this.groupsSubject.next(data as NotificationManage[]);
                this.totalSubject.next(data.length as number);
            } else {
                this.groupsSubject.next([]);
                this.totalSubject.next(0);
            }
        });
    }
}
