export class SensorData {
    id: number;
    idVariable: number;

    idEquipment: number;
    posEquipment: number;
    equipmentName: string;
    serialNum: string;

    date: Date;
    value: number;
}

export class SensorDataGraph {
    date: Date;
}

export class SensorDataGraphData {
    serialNum: string;
    equipmentName: string;

    average = 0;
    maxValue = 0;
    minValue = 0;
    delta = 0;

    diffAvgMin = 0;
    diffMaxAvg = 0;
    diffMaxMin = 0;

    isErrMin: boolean;
    isErrMax: boolean;

    isErrPeakMin: boolean;
    isErrPeakMax: boolean;

    isErrAvgMin: boolean;
    isErrAvgMax: boolean;

    isErrMinDelta: boolean;
    isErrMaxDelta: boolean;

    isErrMaxStandardDeviation: boolean;

    isColdSpot: boolean;
    isHotSpot: boolean;
    hotColdSpot: boolean;

    standardDeviation: number;
    mkt: number;
    countData: number;

    uncertainty: number;
    uncertaintyVariable: number;

    outer = false;
    isErrDiffAvg: boolean;
    isErrorDiffMax: boolean;
}
