import { Component, OnDestroy, OnInit } from '@angular/core';
import { MyErrorStateMatcher } from '../../../utils/errorStateMatcher';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '../../../services/spinner.service';
import { StringUtils } from '../../../utils/stringUtils';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-forget-password-client',
  templateUrl: './forget-password-client.component.html',
  styleUrls: ['./forget-password-client.component.scss']
})
export class ForgetPasswordClientComponent implements OnDestroy {

  matcher = new MyErrorStateMatcher();
  username: string = null;
  error: string;
  message: string;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private loginService: LoginService,
    private translate: TranslateService,
    private spinnerService: SpinnerService) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  forgotPassword(): void {
    this.spinnerService.show();

    this.error = null;
    this.message = null;

    if (StringUtils.isEmpty(this.username)) {
      this.error = this.translate.instant('login.username.error') as string;

      this.spinnerService.hide();

      return;
    }

    const username = this.username.trim();

    this.loginService.forgotPassword(username).pipe(takeUntil(this.destroy$))
      .subscribe(() => this.showMessageOk(), () => this.showMessageOk());

  }

  backLogin(): void {
    void this.router.navigate(['/login-client'], { queryParamsHandling: 'merge' });
  }

  private showMessageOk() {
    this.message = this.translate.instant('forgottenPassword.form.message') as string;

    this.spinnerService.hide();
  }

}

