<div class="main-container">
    <form #protocolListForm="ngForm">
      <div class="toolbar-list" *ngIf="data.isList">
  
        <div class="toolbar-list-title">
          <div fxLayout="row" fxLayoutAlign="start center">
            <h1 class="accent-color">{{ 'genericDocumentList.title' | translate }}</h1>
          </div>
        </div>
  
        <div class="toolbar-list-actions">
        </div>
  
        <div class="toolbar-back-button">
          <a (click)="cancel()" mat-button color="accent">
            <mat-icon color="accent">undo</mat-icon> {{ 'button.back' | translate }}
          </a>
        </div>
      </div>
  
      <app-customer-portal-generic-document-list-filter #filterComponent (filterEmitter)="doFilter($event)" class="filter-container">
      </app-customer-portal-generic-document-list-filter>
  
      <div class="table-container">
        <table #generics mat-table matSort [dataSource]="dataSource" >
          <caption></caption>
          <!-- documentCode Column -->
          <ng-container matColumnDef="documentCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'protocolList.cols.documentCode' | translate }}
            </th>
            <td mat-cell *matCellDef="let item"> {{ item.documentCode }} v.{{ item.signedVersion | number:'2.0' }} </td>
          </ng-container>

          <!-- projectNo Column -->
          <ng-container matColumnDef="equipment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'protocolList.cols.projectNum' | translate }} </th>
            <td mat-cell *matCellDef="let item"> {{ item.projectNo }} </td>
          </ng-container>
  
           <!-- projectNo Column -->
           <ng-container matColumnDef="serialNum">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'protocolList.cols.projectNum' | translate }} </th>
            <td mat-cell *matCellDef="let item"> {{ item.projectNo }} </td>
          </ng-container>

          <!-- area Column -->
          <ng-container matColumnDef="area">
            <th mat-header-cell *matHeaderCellDef> {{ 'protocolList.cols.area' | translate }} </th>
            <td mat-cell *matCellDef="let item"> {{item.area}} </td>
          </ng-container>
  
          <!-- realizationDate Column -->
          <ng-container matColumnDef="realizationDate">
            <th mat-header-cell *matHeaderCellDef> {{ 'protocolList.cols.realizationDate' | translate }} </th>
            <td mat-cell *matCellDef="let item">
              <div style="white-space: pre-line;">
                {{ item.realizationDate | fixDateTime }}
              </div>
            </td>
          </ng-container>
  
          <!-- Button download Column -->
          <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef colspan="3" fxHide.lt-lg> {{ 'protocolList.cols.actions' | translate }}
            </th>
            <td mat-cell *matCellDef="let item" fxHide.lt-lg class="action">
                <button mat-icon-button color="accent" (click)="downloadDocumentByIdAndVersion(item)" class="stroked-primary">
                  <mat-icon>cloud_download</mat-icon> 
                </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
        </table>
  
        <span *ngIf="!hasFiltered" class="prefilterMessage">
          <p [innerHtml]="'common.listPlaceholder' | translate | safe: 'html'"> </p>
        </span>
  
      </div>
  
      <div class="table-footer-container">
        <div class="actions">
          <button mat-raised-button (click)="exportTable()" class="stroked-primary" *ngIf="data.isList">
            <mat-icon fxHide.lt-sm>cloud_download</mat-icon> {{ 'button.downloadXls' | translate }}
          </button>
          <a [routerLink]="['.']" [fragment]="'table-container'" class="back-to-top accent-color" *ngIf="data.isList">
            {{ 'button.backToTop' | translate }} </a>
        </div>
  
        <div class="paginator">
          <mat-paginator [pageSize]="data.isList ? 10 : 5" [pageSizeOptions]="data.isList ? [5, 10, 20] : [5]"
            showFirstLastButtons [length]="dataSource.totalItems$ | async" lang="es">
          </mat-paginator>
        </div>
      </div>
    </form>
  </div>