import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileInfo } from 'src/app/model/attachment';
import { Sensor } from 'src/app/model/sensor';
import { SensorData } from 'src/app/model/sensorData';
import { AttachmentThermalService } from 'src/app/services/attachmentThermal.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ExcelUtils } from 'src/app/utils/excelUtils';
import { PhotoUtils } from 'src/app/utils/photoUtils';

export interface DialogDataAttachmentDetails {
  idExecution: number;
  idSensor: number;
  idAttachment: number;
  accept: string;
  isPhoto: boolean;
  isEssay: boolean;
  disableUpload: boolean;
  type: string;
  sensor: Sensor;
  idVariable: number;
}

@Component({
  selector: 'app-execution-edit-attachment-details',
  templateUrl: './execution-edit-attachment-details.component.html'
})
export class ExecutionEditAttachmentDetailsComponent implements OnInit, OnDestroy {

  fileData: FileInfo;
  image: any;
  base64: string;

  canRotate: boolean;

  private destroy$ = new Subject<void>();

  constructor(
    private sanitizer: DomSanitizer,
    private attachmentThermalService: AttachmentThermalService,
    private translate: TranslateService,
    private imageCompress: NgxImageCompressService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataAttachmentDetails,
    public dialogRef: MatDialogRef<ExecutionEditAttachmentDetailsComponent>,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.reloadAttachment();

    this.canRotate = !this.data.isEssay;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  reloadAttachment(): void {
    this.spinnerService.show();

    this.fileData = new FileInfo();

    if (this.data.isEssay) {
      this.attachmentThermalService.getAttachmentInfoToEssay(this.data.idExecution, this.data.idSensor, this.data.idAttachment)
        .pipe(takeUntil(this.destroy$)).subscribe((res: FileInfo) => this.fileData = res);
    } else {
      this.attachmentThermalService.getAttachmentInfoToSensor(this.data.idSensor, this.data.idAttachment).pipe(takeUntil(this.destroy$))
        .subscribe((res: FileInfo) => this.fileData = res);
    }


    if (this.data.isPhoto) {
      if (this.data.isEssay) {
        this.attachmentThermalService.downloadPhotoToEssay(this.data.idExecution, this.data.idSensor, this.data.idAttachment)
          .pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => this.printImage(res), () => {
            this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.downloadImage.error.generic') as string);

            this.spinnerService.hide();
          });
      } else {
        this.attachmentThermalService.downloadAttachmentToSensor(this.data.idSensor, this.data.idAttachment).pipe(takeUntil(this.destroy$))
          .subscribe((res: Blob) => this.printImage(res), () => {
            this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.downloadImage.error.generic') as string);

            this.spinnerService.hide();
          });
      }
    } else {
      this.spinnerService.hide();
    }
  }

  downloadAttachment(): void {
    this.spinnerService.show();

    this.attachmentThermalService.downloadAttachmentToSensor(this.data.idSensor, this.data.idAttachment).pipe(takeUntil(this.destroy$))
      .subscribe((res: Blob) => {
        saveAs(res, this.fileData.filename);
        this.spinnerService.hide();
      }, error => {
        console.error(error);
        this.spinnerService.hide();
      });
  }

  deleteAttachment(): void {
    this.spinnerService.show();

    this.attachmentThermalService.deleteAttachmentToSensor(this.data.idSensor, this.data.idAttachment).pipe(takeUntil(this.destroy$))
      .subscribe(() => {

        this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.deleteGeneric.ok') as string);
        this.spinnerService.hide();
      }, error => {
        console.error(error);
        this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.deleteGeneric.error.generic') as string);
        this.spinnerService.hide();
      });
  }

  uploadFile(event): void {

    const file = event.target.files[0] as File;

    if (file == null) {
      return;
    }

    this.spinnerService.show();

    if (this.data.type === 'photo') {
      PhotoUtils.fixImage(file, this.imageCompress, (res: File) => {

        this.attachmentThermalService.uploadPhotoToSensor(this.data.idSensor, res).pipe(takeUntil(this.destroy$))
          .subscribe((item: number) => {
            this.spinnerService.hide();

            this.data.sensor.idPhoto = item;
            this.data.idAttachment = item;
            this.reloadAttachment();

            this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.uploadGeneric.ok') as string);
          }, () => {
            this.spinnerService.hide();

            this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadGeneric.error.generic') as string);
          });

      });
    } else if (this.data.type === 'primary') {
      this.attachmentThermalService.uploadPrimaryFileToSensor(this.data.idSensor, file).pipe(takeUntil(this.destroy$))
        .subscribe((item: number) => {
          this.spinnerService.hide();

          this.data.sensor.idPrimaryDataFile = item;
          this.data.idAttachment = item;
          this.reloadAttachment();

          this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.uploadGeneric.ok') as string);
        }, () => {
          this.spinnerService.hide();

          this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadGeneric.error.generic') as string);
        });
    } else if (this.data.type === 'excel') {

      ExcelUtils.excelToSensorData(file, this.attachmentThermalService, this.data.idVariable, false, 1).then((data: SensorData[]) => {

        data.forEach(item => {
          item.serialNum = this.data.sensor.serialNum;
          item.equipmentName = this.data.sensor.equipmentName;
          item.idEquipment = this.data.sensor.idEquipment;
        });

        data.forEach(item => delete item.posEquipment);

        this.data.sensor.data = data;

        data = data.filter(item => item.date != null && !isNaN(item.date.getTime()));

        this.attachmentThermalService.uploadExcelToSensor(this.data.idSensor, file, data).pipe(takeUntil(this.destroy$))
          .subscribe((item: number) => {

            this.data.sensor.idExcel = item;
            this.data.idAttachment = item;
            this.reloadAttachment();

            this.spinnerService.hide();

            this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.uploadExcel.ok') as string);
          }, () => {
            this.spinnerService.hide();

            this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadExcel.error.generic') as string);
          }
          );

      }).catch(err => {
        this.snackBarService.sendError(err as string);
        this.spinnerService.hide();
      });
    } else if (this.data.type === 'generalPhoto') {
      PhotoUtils.fixImage(file, this.imageCompress, (res: File) => {

        this.attachmentThermalService.uploadGeneralPhoto(this.data.idExecution, this.data.idSensor, res).pipe(takeUntil(this.destroy$))
          .subscribe((item: number) => {
            this.spinnerService.hide();

            this.data.sensor.idPhoto = item;
            this.data.idAttachment = item;
            this.reloadAttachment();

            this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.uploadGeneric.ok') as string);
          }, () => {
            this.spinnerService.hide();

            this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadGeneric.error.generic') as string);
          });

      });
    } else {
      this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadGeneric.error.generic') as string);
      this.spinnerService.hide();
    }
  }

  base64ToImage(): SafeResourceUrl {
    if (this.base64 == null) {
      return '';
    }

    if (!this.base64.startsWith('data:image')) {
      this.base64 = 'data:image/jpg;base64,' + this.base64;
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(this.base64);
  }

  rotateImageLeft(): void {
    this.spinnerService.show();

    this.attachmentThermalService.rotateSensorImageLeft(this.data.idSensor).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.spinnerService.hide();
      this.reloadAttachment();
    });
  }

  rotateImageRight(): void {
    this.spinnerService.show();

    this.attachmentThermalService.rotateSensorImageRight(this.data.idSensor).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.spinnerService.hide();
      this.reloadAttachment();
    });
  }

  onNoClick(): void {
    this.dialogRef.close(this.data.sensor);
  }

  private printImage(res: Blob): void {
    this.image = res;

    const cthis = this;
    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result;

      let base64: any;
      if (typeof dataUrl !== 'string') {
        base64 = dataUrl;
      } else {
        base64 = dataUrl.split(',')[1];
      }

      cthis.base64 = base64;

      cthis.spinnerService.hide();
    };

    reader.readAsDataURL(this.image);
  }

}
