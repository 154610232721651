import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { CommonModule } from '@angular/common';
import { DevextremeModule } from 'src/app/external-components/devextreme.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ProtocolEditAttachmentsComponent } from './protocol-edit-attachments.component';
import { ProtocolEditAuditComponent } from './protocol-edit-audit.component';
import { ProtocolEditComponent } from './protocol-edit.component';
import { ProtocolEditDialogAreaComponent } from './protocol-edit-dialog-area.component';
import { ProtocolEditDialogAutomaticSignComponent } from './protocol-edit-dialog-automatic-sign.component';
import { ProtocolEditDialogCompressedGasAirComponent } from './protocol-edit-dialog-compressed-gas-air.component';
import { ProtocolEditDialogDuplicateComponent } from './protocol-edit-dialog-duplicate.component';
import { ProtocolEditDialogDuplicateEquipmentComponent } from './protocol-edit-dialog-duplicate-equipment.component';
import { ProtocolEditDialogEquipmentAirComponent } from './protocol-edit-dialog-equipment-air.component';
import { ProtocolEditDialogEssayAirComponent } from './protocol-edit-dialog-essay-air.component';
import { ProtocolEditDialogFilterComponent } from './protocol-edit-dialog-filter.component';
import { ProtocolEditDialogManualSignComponent } from './protocol-edit-dialog-manual-sign.component';
import { ProtocolEditDialogRoomAirComponent } from './protocol-edit-dialog-room-air.component';
import { ProtocolEditEquipmentDialogComponent } from './protocol-edit-dialog-equipment.component';
import { ProtocolEditEssayCriteriaAirComponent } from './protocol-edit-essay-criteria-air.component';
import { ProtocolEditGenerateReportComponent } from './protocol-edit-generate-report.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
    ProtocolEditComponent, ProtocolEditEquipmentDialogComponent, ProtocolEditAuditComponent,
    ProtocolEditAttachmentsComponent, ProtocolEditGenerateReportComponent, ProtocolEditDialogAutomaticSignComponent,
    ProtocolEditDialogManualSignComponent, ProtocolEditDialogAreaComponent, ProtocolEditDialogEquipmentAirComponent,
    ProtocolEditDialogEssayAirComponent, ProtocolEditEssayCriteriaAirComponent, ProtocolEditDialogRoomAirComponent,
    ProtocolEditDialogFilterComponent, ProtocolEditDialogDuplicateComponent, ProtocolEditDialogDuplicateEquipmentComponent,
    ProtocolEditDialogCompressedGasAirComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    /* Custom modules */
    AngularMaterialModule,
    DevextremeModule,
    SharedModule,
    PipesModule,

    /* Otros */
    FlexLayoutModule
  ]
})
export class ProtocolAirEditModule { }
