<h1 mat-dialog-title>{{ 'calibrateEquipmentEdit.dialog.createEquipment.title' | translate }}</h1>
<form>

  <div mat-dialog-content class="calibrates-theme" style="overflow-y: hidden;">

    <mat-form-field class="campoFormulario">
      <mat-label>{{ 'calibrateEquipmentEdit.dialog.createEquipment.equipmentType.label' | translate }}</mat-label>
      <mat-select name="optionSelected" [(ngModel)]="optionSelected" required>
        <mat-option *ngFor="let item of options" [value]="item.id">
          {{'calibrationResponsibleType.' + item.translation | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <br /><br />

  <div mat-dialog-actions class="calibrates-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()"
      [disabled]="optionSelected == null">{{ 'button.new' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>