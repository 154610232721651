<h1 mat-dialog-title></h1>
<form>
  <div mat-dialog-content class="calibrates-theme">

    <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

      <div fxFlex="50" fxFlex.lt-md="100%">

        <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
          <div fxFlex="33" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput name="rangeInit" [(ngModel)]="data.item.rangeInit" required
                placeholder="{{ 'calibrateEquipmentEdit.calibrationData.range.init.label' | translate }}">
              <mat-error>
                {{ 'calibrateEquipmentEdit.calibrationData.range.init.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="33" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput name="rangeEnd" [(ngModel)]="data.item.rangeEnd" [disabled]="uniqueRange" required
                placeholder="{{ 'calibrateEquipmentEdit.calibrationData.range.end.label' | translate }}">
              <mat-error>
                {{ 'calibrateEquipmentEdit.calibrationData.range.end.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="34" fxFlex.lt-md="100%" style="padding-top: 1em;">
            <mat-checkbox name="uniqueRange" [(ngModel)]="uniqueRange">
              {{'calibrateEquipmentEdit.calibrationData.range.unique.label' | translate }}
            </mat-checkbox>
          </div>
        </div>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput [(ngModel)]="data.item.resolution" name="resolution" required
            placeholder="{{ 'calibrateEquipmentEdit.calibrationData.resolution.label' | translate }}">
          <mat-error>
            {{ 'calibrateEquipmentEdit.calibrationData.resolution.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput name="tolerance" [(ngModel)]="data.item.tolerance"
            placeholder="{{ 'calibrateEquipmentEdit.calibrationData.tolerance.label' | translate }}">
          <mat-error>
            {{ 'calibrateEquipmentEdit.calibrationData.tolerance.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput [(ngModel)]="data.item.uncertaintyResolution" name="uResolution" required
            placeholder="{{ 'calibrateEquipmentEdit.calibrationData.resolutionU.label' | translate }}">
          <mat-error>
            {{ 'calibrateEquipmentEdit.calibrationData.resolutionU.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="100%">

        <span> {{ 'calibrateEquipmentEdit.calibrationData.points.label' | translate }} </span>

        <div fxLayout="row" fxLayout.xs="column" class="flexwrap"
          *ngFor="let value of data.item.asLeft; let indexValue = index">

          <div fxFlex="45" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput type="number" name="point{{indexValue}}" [(ngModel)]="value.point"
                (blur)="setValue(indexValue, $event.target.value)" required
                placeholder="{{ 'calibrateEquipmentEdit.calibrationData.point.label' | translate }}">
              <mat-error>
                {{ 'calibrateEquipmentEdit.calibrationData.point.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="5" fxFlex.lt-md="100%">
            <button mat-icon-button color="accent" (click)="removeValue(indexValue)"
              *ngIf="data.item.asLeft.length > 1">
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </div>

        <div>
          <button mat-raised-button color="primary" (click)="newValue()">
            {{ 'button.new' | translate }}
          </button>
        </div>

      </div>

    </div>

  </div>
  <div mat-dialog-actions class="calibrates-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()"
      cdkFocusInitial>{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>