import { BioindicatorConfig } from './bioindicator';
import { Criteria } from './criteria';
import { Field } from './field';
import { Sensor } from './sensor';

export class EssayProtocol {
    id: number;

    idProtocol: number;
    dsProtocol: string;

    idConfig: number;
    idConfigDependsOn: number;
    idTypeDependency: number;

    criterias: Criteria[];

    percentSensorsCanFail: number;

    idVariable: number;
    translationVariable: string;

    idUnit: number;
    unitName: string;

    fcStartEssay: any;
    fcEndEssay: any;
    reasonModifyExpositionTime: string;

    sensors: Sensor[];
    fields: Field[];
    bioindicatorsConfig: BioindicatorConfig;
    endotoxinsConfig: BioindicatorConfig;

    idImage: number;

    dsLoad: string;
    setpoint: string;

    showBowieDick: boolean;
    validBowieDick: boolean;

    changeEssayDate: boolean;
}
