import { Injectable } from '@angular/core';
import Dexie from 'dexie';

@Injectable()
export class DatabaseService extends Dexie {

    constructor() {
        super('Qloud');

        this.version(1).stores({
            cache: '++id,name,expires,data',
            cacheList: '++id,type,expires,idData,data',
            cacheAction: '++id,date,request'
        });
    }
}
