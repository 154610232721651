import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Notification, NotificationFilter } from 'src/app/model/notification';
import { Subject, merge } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { takeUntil, tap } from 'rxjs/operators';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NotificationDataSource } from 'src/app/model/notificationDataSource';
import { NotificationService } from 'src/app/services/notification.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class NotificationsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: NotificationDataSource;
  filter: NotificationFilter = new NotificationFilter();

  displayedColumns: string[] = ['date', 'subject'];

  private destroy$ = new Subject<void>();

  constructor(
    private notificationService: NotificationService,
    private userService: UserService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.loadNotificationList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => this.loadNotificationList()), takeUntil(this.destroy$)).subscribe();
  }

  doFilter(event: NotificationFilter): void {
    this.filter = event;

    this.loadNotificationList();
  }

  loadNotificationList(): void {
    this.dataSource = new NotificationDataSource(this.notificationService, this.userService);

    this.filter.sortBy = this.sort.active || 'date';
    this.filter.sortDirection = this.sort.direction || 'desc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 5;

    this.dataSource.loadNotifications(this.filter);
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  openNotification(notification: Notification): void {
    this.dataSource.openNotification(notification);
  }

  markAllAsRead(): void {
    this.dataSource.markAllAsRead(this.filter);
  }

}
