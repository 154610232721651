import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-protocol-edit-dialog-duplicate',
  templateUrl: './protocol-edit-dialog-duplicate.component.html'
})
export class ProtocolEditDialogDuplicateComponent {

  tituloDialog: string;
  form: FormGroup;
  name: string;

  constructor(
    fb: FormBuilder,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<ProtocolEditDialogDuplicateComponent>) {

    this.tituloDialog = this.translate.instant('protocolEdit.dialog.area.title.create') as string;


    this.form = fb.group({
      name: [this.name, Validators.required]
    });

  }

  onOkClick(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.name);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
