import { Component, OnInit, ViewChild, ElementRef, Inject, AfterViewInit, Input, HostListener, Renderer2, EventEmitter, Output, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemeService } from '../../../services/theme.service';
import { SpinnerService } from '../../../services/spinner.service';
import { PhotoUtils } from 'src/app/utils/photoUtils';
import { NgxImageCompressService } from 'ngx-image-compress';
import { FileUtils } from '../../../utils/fileUtils';

export interface DialogDataResizeImage {
  src: string;
}

export class DataResultResizeImage {
  file: File;
  width: number;
  height: number;
} 

@Component({
  selector: 'app-image-canvas',
  templateUrl: './resize-image.component.html',
  styleUrls: ['./resize-image.component.scss']
})
export class ResizeImageComponent implements OnInit, OnDestroy {
  width: number;
  height: number;
  @Output() sizeChange = new EventEmitter<{ width: number, height: number }>();
  @ViewChild('attachmentUpload') attachmentUpload: ElementRef;

  private startX: number = 0;
  private startY: number = 0;
  private startWidth: number = 0;
  private startHeight: number = 0;
  private resizing: boolean = false;

  imageSrc: string = null;

  constructor(private el: ElementRef, private renderer: Renderer2, 
    private dialogRef: MatDialogRef<ResizeImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataResizeImage,
    private themeService: ThemeService,
    private spinnerService: SpinnerService,
    private imageCompress: NgxImageCompressService
  ) { }

  ngOnInit() {
    this.setInitialStyles();

    if (this.data.src) {
      this.imageSrc = this.data.src.replace('base64,', 'data:image/png;base64,');
    }
  }

  private setInitialStyles() {
    this.renderer.setStyle(this.el.nativeElement.querySelector('.resizable'), 'width', `${this.width}px`);
    this.renderer.setStyle(this.el.nativeElement.querySelector('.resizable'), 'height', `${this.height}px`);
    this.renderer.setStyle(this.el.nativeElement.querySelector('.resizable'), 'position', 'relative');
    this.renderer.addClass(this.el.nativeElement.querySelector('.resizable'), 'resizable');
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (event.target instanceof HTMLElement && event.target.classList.contains('resize-handle')) {
      this.startResize(event);
    }
  }

  private startResize(event: MouseEvent) {
    this.resizing = true;
    this.startX = event.clientX;
    this.startY = event.clientY;
    this.startWidth = this.el.nativeElement.querySelector('.resizable').clientWidth;
    this.startHeight = this.el.nativeElement.querySelector('.resizable').clientHeight;

    document.addEventListener('mousemove', this.onMouseMove.bind(this));
    document.addEventListener('mouseup', this.onMouseUp.bind(this));

    event.preventDefault();
  }

  private onMouseMove(event: MouseEvent) {
    if (this.resizing) {
      const width = this.startWidth + (event.clientX - this.startX);
      const height = this.startHeight + (event.clientY - this.startY);
      
      this.width = width;
      this.height = height;
      this.renderer.setStyle(this.el.nativeElement.querySelector('.resizable'), 'width', `${width}px`);
      this.renderer.setStyle(this.el.nativeElement.querySelector('.resizable'), 'height', `${height}px`);
      
      this.sizeChange.emit({ width, height });
    }
  }

  private onMouseUp() {
    this.resizing = false;
    document.removeEventListener('mousemove', this.onMouseMove.bind(this));
    document.removeEventListener('mouseup', this.onMouseUp.bind(this));
  }

  ngOnDestroy() {
    document.removeEventListener('mousemove', this.onMouseMove.bind(this));
    document.removeEventListener('mouseup', this.onMouseUp.bind(this));
  }

  onImageUpload(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.imageSrc = e.target.result;
      };

      reader.readAsDataURL(file);
    }
  }

  onOkClick(file: File) {
   let res = new DataResultResizeImage();
   res.file = file;
   res.height = this.height;
   res.width = this.width;
   this.dialogRef.close(res);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTheme(): string {
    return this.themeService.getTheme();
  }

  uploadImageToVerification(): number {
   this.spinnerService.show();

   PhotoUtils.compressFileWithDimensions(this.imageSrc, this.imageCompress, this.width, this.height, (compresed: string) => {
    if (compresed) {
      console.log(compresed);
      this.onOkClick(FileUtils.toFile(compresed, 'image'));
    }
  });
    
    this.attachmentUpload.nativeElement.value = '';
    return null;
  }
}
