import * as _ from 'lodash-es';

import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, forkJoin, merge } from 'rxjs';
import { ProtocolAttachment, ProtocolAttachmentFilter } from 'src/app/model/protocol';
import { takeUntil, tap } from 'rxjs/operators';

import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { AttachmentAirService } from 'src/app/services/attachmentAir.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { EditAttachmentEditComponent } from '../../shared/edit-attachment-edit/edit-attachment-edit.component';
import { GenericClass } from 'src/app/model/genericClass';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ProtocolAirAttachmentDataSource } from 'src/app/model/protocolAirAttachmentDataSource';
import { ProtocolAirService } from 'src/app/services/protocolAir.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-protocol-edit-attachments',
  templateUrl: './protocol-edit-attachments.component.html'
})
export class ProtocolEditAttachmentsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @Input() disableOfflineButton: boolean;

  @ViewChild('attachmentUpload') attachmentUpload: ElementRef;

  dataSource: ProtocolAirAttachmentDataSource;
  public displayedColumns = ['name', 'filename', 'date', 'type', 'edit', 'download', 'delete'];
  filter: ProtocolAttachmentFilter = new ProtocolAttachmentFilter();

  idProtocol: number;

  private destroy$ = new Subject<void>();

  constructor(
    private protocolService: ProtocolAirService,
    private attachmentAirService: AttachmentAirService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.dataSource = new ProtocolAirAttachmentDataSource(this.protocolService);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadAttachments())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  setIdProtocol(id: number): void {
    this.idProtocol = id;

    this.loadAttachments();
  }

  uploadAttachment(event): void {
    if (this.idProtocol == null) {
      this.snackBarService.sendError(this.translate.instant('protocolEdit.attachments.dialog.form.error.generic') as string);
    }

    const files = Array.from(event.target.files as FileList);

    const promises: Observable<void>[] = [];

    if (!ArrayUtils.isEmpty(files)) {
      this.spinnerService.show();
      files.forEach(file => promises.push(this.attachmentAirService.uploadAttachmentToProtocol(this.idProtocol, file) as Observable<void>));

      forkJoin(promises).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.loadAttachments();

        this.spinnerService.hide();

        this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.attachments.dialog.form.upload.ok') as string);
      }, () => {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('protocolEdit.attachments.dialog.form.upload.error.generic') as string);
      });
    }

    this.attachmentUpload.nativeElement.value = '';
  }

  drop(event: CdkDragDrop<ProtocolAttachment[]>): void {
    this.protocolService.moveAttachment(this.idProtocol, event.previousIndex, event.currentIndex)
      .subscribe(() => this.loadAttachments(), () => this.loadAttachments());
  }

  editAttachment(att: ProtocolAttachment): void {
    this.attachmentAirService.getAllTypesForProtocolAir().pipe(takeUntil(this.destroy$)).subscribe((types: GenericClass[]) => {

      const dialogRef = this.dialog.open(EditAttachmentEditComponent, {
        minWidth: '50%',
        maxHeight: '95vh',
        data: {
          attachment: _.cloneDeep(att),
          types
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ProtocolAttachment) => {
        if (result != null) {

          this.attachmentAirService.updateAttachmentToProtocol(result.idProtocol, result.id, result.idType, result.name)
            .pipe(takeUntil(this.destroy$)).subscribe(() => {
              this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.attachments.dialog.form.upload.ok') as string);
              this.loadAttachments();
            }, () => {
              this.snackBarService.sendError(this.translate.instant('protocolEdit.attachments.dialog.form.upload.error.generic') as string);
              this.loadAttachments();
            });
        }
      });
    });
  }

  downloadAttachment(idAtt: number, filename: string, idProtocol: number): void {
    this.spinnerService.show();
    this.attachmentAirService.downloadAttachmentToProtocol(idProtocol, idAtt).pipe(takeUntil(this.destroy$)).subscribe((item: Blob) => {
      saveAs(item, filename);

      this.spinnerService.hide();
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.generateReport.form.error.generic') as string);
    }
    );
  }

  deleteAttachment(idAtt: number, idProtocol: number): void {
    this.spinnerService.show();
    this.attachmentAirService.deleteAttachmentToProtocol(idProtocol, idAtt).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.loadAttachments();

      this.spinnerService.hide();

      this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.attachments.dialog.form.delete.ok') as string);
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('protocolEdit.attachments.dialog.form.delete.error.generic') as string);
    });
  }

  private loadAttachments(): void {
    const id = this.idProtocol;

    if (id == null) {
      this.dataSource = null;
      return;
    }

    this.filter.sortBy = this.sort.active;
    this.filter.sortDirection = this.sort.direction || 'asc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 5;

    this.dataSource.loadProtocolAttachment(id, this.filter);
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

}
