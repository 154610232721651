import { FileUtils } from './fileUtils';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';

export class PhotoUtils {

    public static fixImage(file: File, imageCompress: NgxImageCompressService, callback: (res: File) => void): void {

        void FileUtils.toBase64(file).then((src: string) => {
            this.compressFile(src, imageCompress, (compresed: string) => {
                callback(FileUtils.toFile(compresed, file.name));
            });
        });
    }

    public static compressFile(image, imageCompress: NgxImageCompressService, callback: (str: string) => void) {
        void imageCompress.compressFile(image as string, DOC_ORIENTATION.Default, 50, 75).then(result => callback(result));
    }

    public static convertCanvasToFile(fileName: string, canvasRef): Promise<File> {
        return new Promise((resolve, reject) => {
            const canvas = canvasRef.nativeElement;
            canvas.toBlob((blob) => {
            if (blob) {
                const file = new File([blob], fileName, { type: blob.type });
                resolve(file);
            } else {
                reject(new Error('Failed to create blob from canvas'));
            }
            }, 'image/png');
        });
    }

    public static convertImageSrcToFile(imageSrc: string | ArrayBuffer | null, fileName: string): Promise<File> {
        return new Promise((resolve, reject) => {
            if (!imageSrc) {
                return reject(new Error('No image source provided'));
            }
    
            if (typeof imageSrc === 'string') {
                // Handle Base64 string
                const [header, base64Data] = imageSrc.split(',');
                const mimeType = header.match(/:(.*?);/)?.[1] || 'image/png';
                const byteCharacters = atob(base64Data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: mimeType });
                const file = new File([blob], fileName, { type: mimeType });
                resolve(file);
            } else if (imageSrc instanceof ArrayBuffer) {
                // Handle ArrayBuffer
                const blob = new Blob([imageSrc], { type: 'image/png' });
                const file = new File([blob], fileName, { type: 'image/png' });
                resolve(file);
            } else {
                reject(new Error('Unsupported image source type'));
            }
        });
    }

    public static compressFileWithDimensions(image, imageCompress: NgxImageCompressService, width: number, heigth: number, callback: (str: string) => void, ) {
        void imageCompress.compressFile(image as string, DOC_ORIENTATION.Default, width, heigth).then(result => callback(result));
    }

}
