import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CalibrationResponsibleEnum } from 'src/app/model/calibration';
import { GenericClassTranslate } from 'src/app/model/genericClass';
import { SnackBarService } from 'src/app/services/snackBar.service';

export interface DialogDataCalibrationCreateEquipment {
  idType: number;
  idClient: number;
}

@Component({
  selector: 'app-calibration-edit-create-equipment',
  templateUrl: './calibration-edit-create-equipment.component.html'
})
export class CalibrationEditCreateEquipmentComponent implements OnInit {

  options: GenericClassTranslate[];
  optionSelected: number;

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCalibrationCreateEquipment,
    public dialogRef: MatDialogRef<CalibrationEditCreateEquipmentComponent>,
    private translate: TranslateService,
    public snackBarService: SnackBarService) { }

  ngOnInit(): void {
    const internal = new GenericClassTranslate();
    internal.id = CalibrationResponsibleEnum.INTERNAL;
    internal.translation = 'internal';

    const external = new GenericClassTranslate();
    external.id = CalibrationResponsibleEnum.EXTERNAL;
    external.translation = 'external';

    this.options = [internal, external];
    this.optionSelected = this.data.idType;
  }

  onOkClick(): void {
    if (this.optionSelected == null) {
      this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.dialog.createEquipment.equipmentType.error') as string);

      return;
    }

    this.dialogRef.close();

    const type = this.getTypeEquipment();

    if (type == null) {
      this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.dialog.createEquipment.equipmentType.error') as string);

      return;
    }

    let urlReturn: string;
    if (this.router.url.includes('calibrates')){
      urlReturn = `calibrates/${type}Equipment?id=0&return=createCalibration`;
    } else if (this.router.url.includes('thermal')) {
      urlReturn = `thermal/${type}Equipment?id=0&return=createCalibration`;
    } else if(this.router.url.includes('air')) {
      urlReturn = `air/${type}Equipment?id=0&return=createCalibration`;
    }

    if (this.data.idClient != null) {
      urlReturn += `&idClient=${this.data.idClient}`;
    }

    void this.router.navigateByUrl(urlReturn);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private getTypeEquipment() {
    let res: string = null;

    if (this.optionSelected === CalibrationResponsibleEnum.EXTERNAL) {
      res = 'external';
    } else if (this.optionSelected === CalibrationResponsibleEnum.INTERNAL) {
      res = 'internal';
    }

    return res;
  }

}
