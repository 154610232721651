import { Component, OnDestroy, OnInit } from '@angular/core';

import { CookieService } from 'ngx-cookie';
import { LoginService } from '../../services/login.service';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { OnlineService } from 'src/app/services/online.service';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {

  isLoggedIn: boolean;

  matcher = new MyErrorStateMatcher();

  username: string;
  password: string;

  showPassword = false;

  error: string;

  userConnectedOffline: string;
  canConnectOffline: boolean;
  isOnline: boolean;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private loginService: LoginService,
    private translate: TranslateService,
    private spinnerService: SpinnerService,
    private onlineService: OnlineService,
    private cookieService: CookieService,
    private ngxCookieService: NgxCookieService) {
    loginService.isLoggedIn$.pipe(takeUntil(this.destroy$)).subscribe(item => this.isLoggedIn = item);

    this.userConnectedOffline = this.cookieService.get('User');
    this.canConnectOffline = this.cookieService.get('User') != null;
    this.onlineService.online$.subscribe(online => this.isOnline = online);
  }

  ngOnInit(): void {
    this.ngxCookieService.set('rol', 'qloud', 7);

    this.navigateHome();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  login(): void {
    this.error = null;

    if (!this.isOnline && this.canConnectOffline) {
      this.loginService.goHome().then((() => {
        return;
      })).catch(() => {
        return;
      });
    }

    if (this.username == null || this.password == null) {
      return;
    }

    const username = this.username.trim();
    const password = this.password.trim();

    if (this.username === '' || this.password === '') {
      return;
    }

    if (this.isOnline) {
      this.spinnerService.show();
    }

    this.loginService.login(username, password).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.loginService.goHome().then((res) => {
        if (!res) {
          this.error = this.translate.instant('login.error.generic') as string;
        }
        this.spinnerService.hide();
      }).catch(() => {
        this.error = this.translate.instant('login.error.generic') as string;
      }) ;
      this.spinnerService.hide();
    }, err => {
      if (err.error && err.error.error && (typeof err.error.error) === 'string') {
        this.error = err.error.error;
      } else {
        this.error = this.translate.instant('login.error.generic') as string;
      }

      this.spinnerService.hide();
    });

  }

  navigateHome(): void {
    if (this.isLoggedIn) {
      this.loginService.goHome().
      then(() => {}).catch(() => {});
    }
  }

  forgotPassword(): void {
    void this.router.navigate(['/forgotPassword'], { queryParamsHandling: 'merge' });
  }

  createAccount(): void {
    void this.router.navigate(['/signup']);
  }

  limpiar(): void {
    this.username = null;
    this.password = null;
  }

  isDev(): boolean {
    return environment.development;
  }

}
