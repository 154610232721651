<h1 mat-dialog-title class="planType">{{plan?.name}}</h1>
<form>
  <div mat-dialog-content class="thermal-theme">
    <p *ngIf="plan.name"> {{ 'signup.plan.' + (plan?.name | lowercase ) + '.short' | translate }} </p>

    <p> {{'signup.payment.initial' | translate: {price: plan?.initialPayment, currency: '€'} }} </p>

    <p>{{'signup.payment.monthly' | translate: {price: plan?.monthlyPayment, currency: '€'} }} </p>

    <mat-toolbar color="secondary">
      {{ 'payment.personalData.title' | translate }}
    </mat-toolbar>

    <div fxLayout="column">
      <div fxFlex>
        <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
          <div fxFlex="50" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput required placeholder="{{'payment.managerName.label' | translate }}"
                [(ngModel)]="payment.managerName" name="managerName" required>
              <mat-error>
                {{ 'payment.managerName.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="50" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput required placeholder="{{'payment.managerSurname.label' | translate }}"
                [(ngModel)]="payment.managerSurname" name="managerSurname" required>
              <mat-error>
                {{ 'payment.managerSurname.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxFlex>
        <mat-form-field class="campoFormulario">
          <input matInput required placeholder="{{'payment.managerEmail.label' | translate }}"
            [(ngModel)]="payment.managerEmail" name="managerEmail" required>
          <mat-error>
            {{ 'payment.managerEmail.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

    </div>

    <mat-toolbar color="secondary">
      {{ 'payment.companyData.title' | translate }}
    </mat-toolbar>

    <div fxLayout="column">

      <div fxFlex>
        <mat-form-field class="campoFormulario">
          <input matInput required placeholder="{{'payment.companyName.label' | translate }}"
            [(ngModel)]="payment.companyName" name="companyName" required>
          <mat-error>
            {{ 'payment.companyName.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex>
        <mat-form-field class="campoFormulario">
          <input matInput required placeholder="{{'payment.shortCompanyName.label' | translate }}"
            [(ngModel)]="payment.shortCompanyName" name="shortCompanyName">
          <mat-error>
            {{ 'payment.shortCompanyName.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex>
        <mat-form-field class="campoFormulario">
          <input matInput required placeholder="{{'payment.cifCompany.label' | translate }}"
            [(ngModel)]="payment.cifCompany" name="cifCompany">
          <mat-error>
            {{ 'payment.cifCompany.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex>
        <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
          <div fxFlex>
            <mat-form-field class="campoFormulario">
              <input matInput required placeholder="{{'payment.addressCompany.label' | translate }}"
                [(ngModel)]="payment.addressCompany" name="address">
              <mat-error>
                {{ 'payment.addressCompany.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="5em" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput required placeholder="{{'payment.postalCodeCompany.label' | translate }}"
                [(ngModel)]="payment.postalCodeCompany" name="postalCode">
              <mat-error>
                {{ 'payment.postalCodeCompany.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxFlex>
        <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
          <div fxFlex>
            <mat-form-field class="campoFormulario">
              <input matInput required placeholder="{{'payment.cityCompany.label' | translate }}"
                [(ngModel)]="payment.cityCompany" name="city">
              <mat-error>
                {{ 'payment.cityCompany.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex>
            <mat-form-field class="campoFormulario">
              <input matInput required placeholder="{{'payment.stateCompany.label' | translate }}"
                [(ngModel)]="payment.stateCompany" name="state">
              <mat-error>
                {{ 'payment.stateCompany.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex>
            <mat-form-field class="campoFormulario">
              <mat-select #countrySelect required name="country" [(ngModel)]="payment.countryCompany"
                placeholder="{{'payment.countryCompany.label' | translate }}">
                <mat-select-filter [displayMember]="'name'" [array]="countries"
                  (filteredReturn)="countriesFiltered =$event" [noResultsMessage]="'common.noResults' | translate"
                  [placeholder]="'common.selectFilterPlaceholder' | translate"> </mat-select-filter>
                <mat-option *ngFor="let country of countriesFiltered" [value]="country.id">
                  {{ country.name }}
                </mat-option>
                <mat-error>
                  {{ 'payment.countryCompany.error' | translate }}
                </mat-error>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

    </div>

    <mat-toolbar color="secondary">
      {{ 'payment.paymentData.title' | translate }}
    </mat-toolbar>

    <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>

    <br /><br />
  </div>
  <div mat-dialog-actions class="thermal-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.pay' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>