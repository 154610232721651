<mat-tab-group (selectedTabChange)="onTabChange($event)">
  <mat-tab label="{{ 'isothermalCharacterizationEdit.general.title' | translate }}">

    <form>

      <mat-card class="card">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'isothermalCharacterizationEdit.general.title' | translate }}
          </mat-toolbar>

          <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

            <div fxFlex="33" fxFlex.lt-md="100%">

              <div fxLayout="column" class="flexwrap">

                <div fxFlex="100%" *ngIf="isothermalCharacterization.documentCode != null">
                  <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
                    <div fxFlex>
                      <mat-form-field class="campoFormulario">
                        <input matInput
                          placeholder="{{ 'isothermalCharacterizationEdit.general.documentCode.label' | translate }}"
                          name="documentCode" [(ngModel)]="isothermalCharacterization.documentCode" disabled>
                      </mat-form-field>
                    </div>
                    <div fxFlex="65px" fxFlex.lt-md="50px" *ngIf="canEditDocumentCode()">
                      <button mat-icon-button (click)="editDocumentCode()">
                        <mat-icon>mode_edit</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>

                <div fxFlex="100%">
                  <div fxLayout="row" class="flexwrap">
                    <div fxFlex [hidden]="isothermalCharacterization.id != null">
                      <mat-form-field class="campoFormulario">
                        <mat-label>{{ 'isothermalCharacterizationEdit.general.client.label' | translate }}</mat-label>
                        <mat-select [disabled]="isothermalCharacterization.id != null" #clientSelect name="idClient"
                          [(ngModel)]="isothermalCharacterization.idClient" required>
                          <mat-select-filter *ngIf="clientSelect.focused" [displayMember]="'name'" [array]="clients"
                            (filteredReturn)="clientsFiltered =$event"
                            [noResultsMessage]="'common.noResults' | translate"
                            [placeholder]="'common.selectFilterPlaceholder' | translate"></mat-select-filter>
                          <mat-option *ngFor="let item of clientsFiltered" [value]="item.id">
                            {{item.name}}
                          </mat-option>
                        </mat-select>
                        <mat-error>
                          {{ 'isothermalCharacterizationEdit.general.client.error' | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxFlex *ngIf="isothermalCharacterization.id != null">
                      <mat-form-field class="campoFormulario">
                        <input matInput name="clientName" disabled
                          placeholder="{{ 'isothermalCharacterizationEdit.general.client.label' | translate }}"
                          [ngModel]="isothermalCharacterization?.client?.name">
                      </mat-form-field>
                    </div>

                  </div>
                </div>

                <div fxFlex="100%">
                  <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
                    <div fxFlex>
                      <mat-form-field class="campoFormulario">
                        <input matInput name="clientDocumentCode"
                          [(ngModel)]="isothermalCharacterization.clientDocumentCode"
                          placeholder="{{ 'isothermalCharacterizationEdit.general.clientDocumentCode.label' | translate }}">
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div fxFlex="50">
                  <mat-form-field class="campoFormulario">
                    <input matInput name="projectNo" [(ngModel)]="isothermalCharacterization.projectNo"
                      placeholder="{{ 'isothermalCharacterizationEdit.general.projectNumber.label' | translate }}">
                  </mat-form-field>
                </div>

                <div fxFlex="50">
                  <mat-form-field class="campoFormulario">
                    <input matInput name="department" [(ngModel)]="isothermalCharacterization.department"
                      placeholder="{{ 'isothermalCharacterizationEdit.general.department.label' | translate }}">
                  </mat-form-field>
                </div>

              </div>

            </div>
            <div fxFlex="67" fxFlex.lt-md="100%">

              <div fxLayout="row" fxLayout.xs="column" class="flexwrap"
                [hidden]="isothermalCharacterization.id == null">

                <div fxFlex="50" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <input matInput name="regUser" [ngModel]="isothermalCharacterization.regUser" disabled
                      placeholder="{{ 'isothermalCharacterizationEdit.general.regUser.label' | translate }}">
                  </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <input matInput name="modUser" [ngModel]="isothermalCharacterization.modUser" disabled
                      placeholder="{{ 'isothermalCharacterizationEdit.general.modUser.label' | translate }}">
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <input matInput name="regFc" [ngModel]="isothermalCharacterization.regFc | fixDate" disabled
                      placeholder="{{ 'isothermalCharacterizationEdit.general.regFc.label' | translate }}">
                  </mat-form-field>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <input matInput name="modFc" [ngModel]="isothermalCharacterization.modFc | fixDate" disabled
                      placeholder="{{ 'isothermalCharacterizationEdit.general.modFc.label' | translate }}">
                  </mat-form-field>
                </div>

                <div fxFlex="50" fxFlex.lt-md="100%">
                  <div fxLayout="row" fxLayout.xs="column" class="flexwrap"
                    [hidden]="isothermalCharacterization.id == null">
                    <div fxFlex fxFlex.lt-md="100%">
                      <mat-form-field class="campoFormulario">
                        <input matInput name="status" disabled
                          placeholder="{{ 'isothermalCharacterizationEdit.general.status.label' | translate }}"
                          value="{{ isothermalCharacterization.id != null ? ('isothermalCharacterizationStatus.' + isothermalCharacterization.statusTranslation | translate) : '' }}">
                      </mat-form-field>
                    </div>
                    <div fxFlex fxFlex.lt-md="100%" *ngIf="isothermalCharacterization.according != null">
                      <mat-form-field class="campoFormulario">
                        <input matInput name="according" disabled
                          placeholder="{{ 'protocolEdit.general.according.label' | translate }}"
                          value="{{ isothermalCharacterization.according ? ('common.yes' | translate) : ('common.no' | translate) }}">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div fxFlex="50">
                  <mat-form-field class="campoFormulario">
                    <input matInput name="currentVersion" [ngModel]="isothermalCharacterization.currentVersion" disabled
                      placeholder="{{ 'isothermalCharacterizationEdit.general.currentVersion.label' | translate }}">
                  </mat-form-field>
                </div>

              </div>

            </div>

          </div>

        </mat-card-content>
      </mat-card>

      <mat-card class="card" [hidden]="isothermalCharacterization.id == null">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'isothermalCharacterizationEdit.referenceDocs.title' | translate }}

            <button mat-icon-button color="accent" (click)="openNewRefDoc()">
              <mat-icon>add</mat-icon>
            </button>
          </mat-toolbar>

          <br />

          <div fxLayout="row">

            <div fxFlex fxFlex.lt-md="100%">

              <table mat-table matSort #refDocTable [dataSource]="isothermalCharacterization.referenceDocs"
                style="width: 95%;">

                <!-- code Column -->
                <ng-container matColumnDef="code">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'isothermalCharacterizationEdit.referenceDocs.code.label' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let item"> {{ item.code }} </td>
                </ng-container>

                <!-- name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'isothermalCharacterizationEdit.referenceDocs.name.label' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let item" style="white-space: pre;"> {{ item.name }} </td>
                </ng-container>

                <!-- Button edit Column -->
                <ng-container matColumnDef="edit">
                  <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
                  <td mat-cell *matCellDef="let element; let refDocIndex = index">
                    <button mat-icon-button (click)="editRefDoc(refDocIndex)">
                      <mat-icon>mode_edit</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <!-- Button delete Column -->
                <ng-container matColumnDef="delete">
                  <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
                  <td mat-cell *matCellDef="let element; let refDocIndex = index">
                    <button mat-icon-button (click)="removeRefDoc(refDocIndex)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColsRefDoc"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColsRefDoc;"></tr>

              </table>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card class="card">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'protocolEdit.equipment.title' | translate }}

            <button mat-icon-button color="accent" (click)="openDialogEquipment()" [disabled]="disableOfflineButton">
              <mat-icon>add</mat-icon>
            </button>
          </mat-toolbar>

          <table #equipmentTable mat-table [dataSource]="isothermalCharacterization?.equipments" style="min-width: 95%">

            <!-- equipment Column -->
            <ng-container matColumnDef="equipment">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.equipment.equipment.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.equipment}} </td>
            </ng-container>

            <!-- maker Column -->
            <ng-container matColumnDef="maker">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.equipment.maker.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.maker}} </td>
            </ng-container>

            <!-- model Column -->
            <ng-container matColumnDef="model">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.equipment.model.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.model}} </td>
            </ng-container>

            <!-- serialNum Column -->
            <ng-container matColumnDef="serialNum">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.equipment.serialNo.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.serialNum}} </td>
            </ng-container>

            <!-- internalId Column -->
            <ng-container matColumnDef="internalId">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.equipment.internalId.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.internalId}} </td>
            </ng-container>

            <!-- location Column -->
            <ng-container matColumnDef="location">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.equipment.location.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.location}} </td>
            </ng-container>

            <!-- Button edit Column -->
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element; let i = index">
                <div matTooltip="{{ 'button.disabled.offline' | translate }}"
                  [matTooltipDisabled]="!disableOfflineButton">
                  <button mat-icon-button (click)="openDialogEditEquipment(i)" [disabled]="disableOfflineButton">
                    <mat-icon>mode_edit</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>

            <!-- Button delete Column -->
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element; let i = index">
                <div matTooltip="{{ 'button.disabled.offline' | translate }}"
                  [matTooltipDisabled]="!disableOfflineButton">
                  <button mat-icon-button (click)="deleteEquipmentRow(i)" [disabled]="disableOfflineButton">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="equipmentCols"></tr>
            <tr mat-row *matRowDef="let row; columns: equipmentCols;"></tr>
          </table>

        </mat-card-content>

      </mat-card>

      <mat-card class="card" [hidden]="isothermalCharacterization.id == null">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'isothermalCharacterizationEdit.environmentalConditions.title' | translate }}
          </mat-toolbar>

          <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

            <div fxFlex="20" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput name="temperature" [(ngModel)]="isothermalCharacterization.temperature"
                  placeholder="{{ 'isothermalCharacterizationEdit.environmentalConditions.temperature.label' | translate }}">
              </mat-form-field>
            </div>
            <div fxFlex="20" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput name="humidity" [(ngModel)]="isothermalCharacterization.humidity"
                  placeholder="{{ 'isothermalCharacterizationEdit.environmentalConditions.humidity.label' | translate }}">
              </mat-form-field>
            </div>

            <div fxFlex="20" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput name="pressure" [(ngModel)]="isothermalCharacterization.pressure"
                  placeholder="{{ 'isothermalCharacterizationEdit.environmentalConditions.pressure.label' | translate }}">
              </mat-form-field>
            </div>

          </div>

        </mat-card-content>
      </mat-card>

      <mat-card class="card" [hidden]="isothermalCharacterization.id == null">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'isothermalCharacterizationEdit.essays.generalFieldsTitle' | translate }}
          </mat-toolbar>

          <div fxLayout="row">

            <div fxFlex="50" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput name="workingConditions" required
                  [(ngModel)]="isothermalCharacterization.workingConditions" [disabled]="disableChangeVariable()"
                  placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.workingConditions.label' | translate }}">
                <mat-error>
                  {{ 'isothermalCharacterizationEdit.dialog.essay.workingConditions.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="50" fxFlex.lt-md="100%">
              <div fxLayout="row">

                <div fxFlex="33%" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <input matInput name="rehearsalTimeDay" [ngModel]="getValue('rehearsalTime', 0)" min="0"
                      [disabled]="disableChangeVariable()" type="number" required
                      (blur)="setValue('rehearsalTime', 0, $event.target.value)"
                      placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.rehearsalTime.label' | translate }}: {{ 'common.days.label' | translate }}">
                    <mat-error>
                      {{ 'isothermalCharacterizationEdit.dialog.essay.rehearsalTime.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="33%" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <input matInput name="rehearsalTimeHour" [ngModel]="getValue('rehearsalTime', 1)" min="0"
                      [disabled]="disableChangeVariable()" type="number" required
                      (blur)="setValue('rehearsalTime', 1, $event.target.value)" [disabled]="disableChangeVariable()"
                      placeholder="{{ 'common.hours.label' | translate }}">
                    <mat-error>
                      {{ 'isothermalCharacterizationEdit.dialog.essay.rehearsalTime.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex="33%" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <input matInput name="rehearsalTimeMinute" [ngModel]="getValue('rehearsalTime', 2)" min="0"
                      [disabled]="disableChangeVariable()" type="number" required
                      (blur)="setValue('rehearsalTime', 2, $event.target.value)"
                      placeholder="{{ 'common.minutes.label' | translate }}">
                    <mat-error>
                      {{ 'isothermalCharacterizationEdit.dialog.essay.rehearsalTime.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div fxFlex="50" fxFlex.lt-md="100%">
              <div fxLayout="row">

                <div fxFlex="50%" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <input matInput name="frequencyMinute" [ngModel]="getValue('frequency', 0)" min="0"
                      placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.frequency.label' | translate }}: {{ 'common.minutes.label' | translate }}"
                      (blur)="setValue('frequency', 0, $event.target.value)" type="number" required
                      [disabled]="disableChangeVariable()">
                    <mat-error>
                      {{ 'isothermalCharacterizationEdit.dialog.essay.frequency.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="50%" fxFlex.lt-md="100%">
                  <mat-form-field class="campoFormulario">
                    <input matInput name="frequencySeconds" [ngModel]="getValue('frequency', 1)" min="0"
                      placeholder="{{ 'common.seconds.label' | translate }}" type="number" required
                      (blur)="setValue('frequency', 1, $event.target.value)" [disabled]="disableChangeVariable()">
                    <mat-error>
                      {{ 'isothermalCharacterizationEdit.dialog.essay.frequency.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div fxFlex="50" fxFlex.lt-md="100%">
              {{ 'isothermalCharacterizationEdit.general.variables.label' | translate }}:
              <div *ngFor="let item of variables">
                <mat-checkbox (change)="onChangeVariableCheck($event)" [value]="item"
                  [checked]="isVariableChecked(item)" [disabled]="disableChangeVariable()">
                  {{'variable.' + item.translation | translate }}
                </mat-checkbox>
              </div>
            </div>

          </div>

        </mat-card-content>

      </mat-card>

      <mat-card class="card" [hidden]="isothermalCharacterization.id == null">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'isothermalCharacterizationEdit.essays.title' | translate }}
          </mat-toolbar>

          <br />

          <app-isothermal-characterization-edit-essay #essaysComponent
            [idIsothermalCharacterization]="isothermalCharacterization.id"
            [rehearsalTime]="isothermalCharacterization.rehearsalTime"
            [idStatusIsothermalCharacterization]="isothermalCharacterization.idStatus"
            [idDisposal]="isothermalCharacterization.idDisposal" [idPhoto]="isothermalCharacterization.idPhoto"
            (fcStartEssayEmitter)="onFcStartEssayEmit($event)" (updateSensorEssayEmitter)="setSensor($event)"
            (reloadPageEmitter)="reloadPageEmit($event)" (idDisposalEmitter)="setIdDisposal($event)"
            (idPhotoEmitter)="setIdPhoto($event)">
          </app-isothermal-characterization-edit-essay>

        </mat-card-content>

      </mat-card>

      <mat-card class="card">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'isothermalCharacterizationEdit.observations.title' | translate }}
          </mat-toolbar>

          <div fxLayout="column">
            <div fxFlex="100%">
              <mat-form-field class="campoFormulario" style="width: 100%">
                <textarea rows="5" matAutosizeMinRows="5" matInput style="resize: none;"
                  placeholder="{{ 'isothermalCharacterizationEdit.observations.label' | translate }}"
                  [(ngModel)]="isothermalCharacterization.observations" name="observations"> </textarea>
              </mat-form-field>
            </div>
          </div>

        </mat-card-content>
      </mat-card>

      <mat-card class="card">
        <mat-card-actions>
          <div fxFlex></div>
          <button mat-raised-button color="primary" *ngIf="showBack()" (click)="calculateBack()">{{ 'button.retreat' |
            translate }}</button>
          <button mat-raised-button color="primary" *ngIf="showRevertSign()" (click)="revertSign()">
            {{ 'button.revertSign' | translate }} </button>
          <button mat-raised-button color="primary" *ngIf="showAdvance()" (click)="calculateAdvance()">{{
            'button.advance' | translate }}</button>
          <button mat-raised-button color="primary" *ngIf="showManualSign()" (click)="manualSign()">{{
            'button.sign-manual' | translate }}</button>
          <button mat-raised-button color="primary" *ngIf="showAutomaticSign()" (click)="automaticSign()">{{
            'button.sign-automatic' | translate }}</button>
          <div fxFlex="20px"></div>
          <button mat-raised-button color="primary" (click)="saveIsothermalCharacterization()">
            {{ 'button.save' | translate }}
          </button>

          <div matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton">
            <button mat-raised-button color="accent" *ngIf="showDownloadButton()" (click)="downloadPdf()"
              [disabled]="disableOfflineButton">{{ 'button.generateDoc' | translate }}</button>
          </div>
          <button mat-raised-button color="secondary" (click)="cancel()">{{ 'button.cancel' | translate }}</button>
        </mat-card-actions>
      </mat-card>
      <br />
      <br />
      <br />

    </form>

  </mat-tab>
  <mat-tab label="{{ 'isothermalCharacterizationEdit.historical.title' | translate }}"
    *ngIf="isothermalCharacterization.id != null">
    <app-isothermal-characterization-edit-audit [idIsothermalCharacterization]="isothermalCharacterization.id"
      [documentCode]="isothermalCharacterization.documentCode">
    </app-isothermal-characterization-edit-audit>
  </mat-tab>
</mat-tab-group>