import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, map } from 'rxjs';
import { DexieService } from './dexieService';
import { OnlineService } from './online.service';
import { HttpUtils } from '../utils/httpUtils';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class TestQDataOQService {

    protected readonly url = environment.qualificatesUrl + '/testQDataOQ';

    constructor(private http: HttpClient,
        private dexieService: DexieService,
        private onlineService: OnlineService) { }

    findTestQDataOQByIdTest(id: number): Observable<any> {
        if (this.onlineService.latestOnline) {
            return this.http.get(`${this.url}/test/${id}`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.roomType.toArray().timeout(30000));
        }
    }

    findAll(): Observable<any> {
        if (this.onlineService.latestOnline) {
            return this.http.get(`${this.url}/test`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.roomType.toArray().timeout(30000));
        }
    }
}
