import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Criteria } from 'src/app/model/criteria';
import { CriteriaType, CriteriaTypeCustomSubValue, CriteriaTypeCustomValue, ParticleSizeEnum } from 'src/app/model/criteriaType';
import { EssayAirTypeEnum } from 'src/app/model/essayType';
import { CriteriaTypeService } from 'src/app/services/criteriaType.service';
import { EssayConfigAirService } from 'src/app/services/essayConfigAir.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { AirUtils } from 'src/app/utils/airUtils';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { Constants } from 'src/app/utils/constants';
import { StringUtils } from 'src/app/utils/stringUtils';

export interface DialogDataCriteriaEssay {
  criteria: Criteria;
  idConfig: number;
  isEdit: boolean;
  criteriasRemove: number[];
  idEssayType: number;
  idEquipmentType: number;
}
@Component({
  selector: 'app-execution-edit-essay-criteria',
  templateUrl: './execution-edit-essay-criteria.component.html'
})
export class ExecutionEditEssayCriteriaComponent implements OnInit {

  criterias: CriteriaType[];
  criteriaSelected: CriteriaType;
  criteriaSelectedProtocol: CriteriaType;

  mapCustomValues: Map<CriteriaTypeCustomValue, CriteriaTypeCustomSubValue[]> = new Map();

  isAnexoI = false;
  isTextOneAnexI = false;
  isTextTwoAnexI = false;
  isMinParticleAnexI = false;
  isMaxParticleAnexI = false;
  textOneParticleAnexI: string;
  textTwoParticleAnexI: string;

  isRestAnexI = false;
  isWorkAnexI = false;
  valueCriteria1: number;
  valueCriteria2: number;
  private destroy$ = new Subject<void>();

  constructor(
    private essayConfigService: EssayConfigAirService,
    private criteriaTypeService: CriteriaTypeService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCriteriaEssay,
    public dialogRef: MatDialogRef<ExecutionEditEssayCriteriaComponent>,
    public snackBarService: SnackBarService) {
  }

  ngOnInit(): void {
    if (this.data.isEdit) {
      this.data.criteriasRemove.splice(this.data.criteriasRemove.indexOf(this.data.criteria.idType), 1);
    }

    this.criterias = [];
    this.criteriaTypeService.findByIdEssayAir(this.data.idConfig).pipe(takeUntil(this.destroy$)).subscribe((res: CriteriaType[]) => {
      this.criterias = res;

      if (this.data.criteria.fromProtocol) {
        this.criteriaSelectedProtocol = this.criterias.find(a => a.id === this.data.criteria.idTypeProtocol);
      }

      if (this.criterias.length === 1) {
        this.onCriteriaChange(this.criterias[0].id);
      } else if (this.data.criteria.idType != null) {
        this.onCriteriaChange(this.data.criteria.idType, false);
      }
    });

    let idCriteriaType: number = null;
    try {
      idCriteriaType = this.data.criteria.idType;
    } catch (e) { }

    if (idCriteriaType) {
      this.criteriaTypeService.findOne(idCriteriaType).pipe(takeUntil(this.destroy$))
        .subscribe((res: CriteriaType) => this.criteriaSelected = res, () => this.criteriaSelected = null);
    }

    if (this.data.criteria.customValue1 != null) {
      this.criteriaTypeService.findCustomValues(this.data.criteria.idType).subscribe((res: CriteriaTypeCustomValue[]) => {
        this.recalculateMapCustomValues(res);
        this.onCriteriaTypeChange(this.data.idEssayType);
      });
    }

    if ((EssayAirTypeEnum.ANEXOI_COUNT_REPOSE === this.data.idEssayType
      || EssayAirTypeEnum.ANEX_COUNT === this.data.idEssayType
      || EssayAirTypeEnum.ANEXOI_COUNT_WORKING === this.data.idEssayType ) && this.data.criteria.customValue2) {
      const resultValue = this.data.criteria.customValue2?.split(';');
      if (resultValue != null && resultValue[0]?.length > 0) {
        // eslint-disable-next-line radix
        this.valueCriteria1 = Number(resultValue[0]);
      }

      if (resultValue != null && resultValue[1]?.length > 0) {
        this.valueCriteria2 = Number(resultValue[1]);
      }
    }

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCriteriaChange(event: MatSelectChange | number, resetValues = false): void {
    let idCriteria: number;

    if (event instanceof MatSelectChange) {
      idCriteria = event.source.value as number
    } else {
      idCriteria = event;
    }

    this.data.criteria.idType = idCriteria;

    if (idCriteria == null) {
      this.criteriaSelected = null;
      this.data.criteria.criteriaValue1 = null;
      this.data.criteria.criteriaValue2 = null;
      this.data.criteria.criteriaTranslation = null;

      return;
    }

    const crit = this.criterias.find(cr => cr.id === idCriteria);

    if (crit !== null) {
      this.criteriaSelected = crit;
      this.data.criteria.criteriaTranslation = crit.translation;

      if (resetValues) {
        this.data.criteria.criteriaValue1 = crit.defaultValue1;
        this.data.criteria.criteriaValue2 = crit.defaultValue2;
      }

      if (crit.hasCustomValues) {
        this.criteriaTypeService.findCustomValues(crit.id).subscribe((res: CriteriaTypeCustomValue[]) => {
          this.recalculateMapCustomValues(res);
        }, () => {
          this.recalculateMapCustomValues([]);
        });
      } else {
        this.criteriaTypeService.findCustomValuesByEssay(this.data.idEssayType).subscribe((res: CriteriaTypeCustomValue[]) => {
          this.recalculateMapCustomValues(res);
        }, () => {
          this.recalculateMapCustomValues([]);

        });
      }

      this.criteriaTypeService.findCustomValues(crit.id).subscribe((res: CriteriaTypeCustomValue[]) => {
        this.recalculateMapCustomValues(res);
      }, () => {
        this.recalculateMapCustomValues([]);
      });
    } else {
      this.criteriaSelected = null;
      this.data.criteria.criteriaValue1 = null;
      this.data.criteria.criteriaValue2 = null;
      this.data.criteria.criteriaTranslation = null;

      this.recalculateMapCustomValues([]);
    }

  }

  showCriteriaCustomValue(): boolean {
    return this.mapCustomValues.size !== 0;
  }

  showReason(): boolean {
    const criteria = this.data.criteria;

    return criteria.fromProtocol && (criteria.idType !== criteria.idTypeProtocol
      || criteria.criteriaValue1 !== criteria.criteriaValueProtocol1 || criteria.criteriaValue2 !== criteria.criteriaValueProtocol2);
  }

  showCriteriaCustomSubValue(): boolean {
    return this.mapCustomValues.size !== 0;
  }

  getCustomValues(): CriteriaTypeCustomValue[] {
    return [...this.mapCustomValues.keys()].filter((value, index, arr) => {
      const arrIds = arr.map(a => a.id);

      return arrIds.indexOf(value.id) === index;
    });
  }

  getTotalSubvalues(): number {
    return ArrayUtils.flat([...this.mapCustomValues.values()]).length;
  }

  onCriteriaCustomSubValueChange(event: MatSelectChange | string): void {
    let valueCriteriaCustomSubValue: string;

    if (event instanceof MatSelectChange) {
      valueCriteriaCustomSubValue = event.source.value as string;
    } else {
      valueCriteriaCustomSubValue = event;
    }

    if (valueCriteriaCustomSubValue == null) {
      this.data.criteria.customValue1 = null;
      this.recalculateParticleSize();

      return;
    }

    this.data.criteria.customValue1 = valueCriteriaCustomSubValue;
    this.recalculateParticleSize();
    this.onCriteriaTypeChange(this.data.idEssayType);

  }

  showParticleSize(): boolean {
    return this.data.criteria.customValue1 != null && this.data.criteria.customValue1.toLowerCase().startsWith('iso');
  }

  recalculateParticleSize(): void {
    if (this.showParticleSize()) {
      const partSize = this.getParticleSize();
      this.data.criteria.customValue2 = [partSize[0], partSize[1]].join(';');
    } else {
      this.data.criteria.customValue2 = null;
    }
  }

  getParticleSize(): string[] {
    return Object.values(ParticleSizeEnum).map(a => a.valueOf().toString());
  }

  getParticleValue(index: number): string {
    return this.data.criteria.customValue2.split(';')[index];
  }

  onParticleValueChange(event: MatSelectChange, index: number): void {
    const value = event.source.value as string;

    const values = this.data.criteria.customValue2.split(';');
    values[index] = value;

    this.data.criteria.customValue2 = [values[0], values[1]].join(';');
  }

  showCustomText(): boolean {
    return this.showCustomText1() || this.showCustomText2();
  }

  getCustomText1(): string {
    let res: string = null;

    if (this.data.idEssayType === EssayAirTypeEnum.DEF_PRESSURE) {
      res = 'about';
    }

    return res;
  }

  showCustomText1(): boolean {
    return StringUtils.isNotEmpty(this.getCustomText1());
  }

  getCustomText2(): string {
    let res: string = null;

    if (this.data.criteria.customValue1 === 'tdClassOthers') {
      res = 'other';
    }

    return res;
  }

  showCustomText2(): boolean {
    return StringUtils.isNotEmpty(this.getCustomText2());
  }

  onOkClick(): void {
    const errors = [];

    const item = this.data.criteria;

    if (item.idType == null) {
      errors.push(this.translate.instant('protocolEdit.dialog.criteria.form.error.type'));
    } else {
      const crit = this.criterias.find(c => c.id === item.idType);

      if (crit.variablesToShow >= 1 && item.criteriaValue1 == null) {
        errors.push(this.translate.instant('protocolEdit.dialog.criteria.form.error.value1'));
      } else if (crit.variablesToShow >= 2 && item.criteriaValue2 == null) {
        errors.push(this.translate.instant('protocolEdit.dialog.criteria.form.error.value2'));
      }

      if (this.showCustomText1() && StringUtils.isEmpty(item.customValue1)) {
        errors.push(this.translate.instant('protocolEdit.dialog.criteria.form.error.value1'));
      } else if (this.showCriteriaCustomValue() && StringUtils.isEmpty(item.customValue1)) {
        errors.push(this.translate.instant('protocolEdit.dialog.criteria.form.error.value1'));
      }

      if (this.showCustomText2() && StringUtils.isEmpty(item.customValue2)) {
        errors.push(this.translate.instant('protocolEdit.dialog.criteria.form.error.value1'));
      } else if (this.showCriteriaCustomSubValue() && StringUtils.isEmpty(item.customValue2)) {
        const idsEssaysSkip = [
          EssayAirTypeEnum.PARTICLE_COUNT_REPOSE, EssayAirTypeEnum.PARTICLE_COUNT_WORKING, EssayAirTypeEnum.REN_FLOW_AND_RATE,
          EssayAirTypeEnum.AIR_SPEED, EssayAirTypeEnum.MICROBIO_ESSAY, EssayAirTypeEnum.DEW_POINT, EssayAirTypeEnum.HYDROCARBON_LEVEL,
          EssayAirTypeEnum.ANEXOI_COUNT_REPOSE, EssayAirTypeEnum.ANEXOI_COUNT_WORKING
        ];

        if (!idsEssaysSkip.includes(this.data.idEssayType) && this.showParticleSize()) {
          errors.push(this.translate.instant('protocolEdit.dialog.criteria.form.error.value2'));
        }
      }

      if (this.showReason() && StringUtils.isEmpty(this.data.criteria.criteriaValueChangeReason)) {
        errors.push(this.translate.instant('protocolEdit.instrumentationUse.validator.reason.error'));
      }

      if (EssayAirTypeEnum.ANEXOI_COUNT_WORKING === this.data.idEssayType) {
        if (this.data.criteria.customValue1 === 'gradeD') {
          if (!this.valueCriteria2) {
            errors.push(this.translate.instant('protocolEdit.dialog.essay.anexCount.error'))
          } else if (!this.valueCriteria1) {
              errors.push(this.translate.instant('protocolEdit.dialog.essay.anexCount.error'))
            }
        } else if (this.data.criteria.customValue1 === 'gradeA') {
          if (!this.valueCriteria1) {
            errors.push(this.translate.instant('protocolEdit.dialog.essay.anexCount.error'))
          }
        }
      } else if (EssayAirTypeEnum.ANEXOI_COUNT_REPOSE === this.data.idEssayType
        || EssayAirTypeEnum.ANEX_COUNT === this.data.idEssayType) {
        if (this.data.criteria.customValue1 === 'gradeA') {
          if (!this.valueCriteria1) {
            errors.push(this.translate.instant('protocolEdit.dialog.essay.anexCount.error'))
          }
        } else if (this.data.criteria.customValue1 === 'gradeB') {
          if (!this.valueCriteria1) {
            errors.push(this.translate.instant('protocolEdit.dialog.essay.anexCount.error'))
          }
        }
      }
    }

    if (ArrayUtils.isEmpty(errors)) {
      if (!this.showReason()) {
        this.data.criteria.criteriaValueChangeReason = null;
      }

      if (EssayAirTypeEnum.ANEXOI_COUNT_WORKING === this.data.idEssayType
        || EssayAirTypeEnum.ANEXOI_COUNT_REPOSE === this.data.idEssayType
        || EssayAirTypeEnum.ANEX_COUNT) {
        if (this.valueCriteria1) {
          item.customValue2 = this.valueCriteria1.toString();
          this.data.criteria.customValue2 = item.customValue2;
        }
        if (this.valueCriteria2) {
          item.customValue2 = [this.valueCriteria1, this.valueCriteria2].join(';');
          this.data.criteria.customValue2 = item.customValue2;
        }
      }

      this.dialogRef.close(item);
    } else {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);
      console.error(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCriteriaTypeChange(type: number): void {
    this.isAnexoI = false;
    this.isTextTwoAnexI = false;
    this.isTextOneAnexI = false;
    this.textTwoParticleAnexI = null;
    this.textOneParticleAnexI = null;
    if (EssayAirTypeEnum.ANEXOI_COUNT_WORKING === type) {
      if (this.data.criteria.customValue1 === 'gradeD') {
        this.isAnexoI = true;
        this.isTextTwoAnexI = true;
        this.isTextOneAnexI = true;
        this.isMinParticleAnexI = true;
        this.isMaxParticleAnexI = true;
      } else if (this.data.criteria.customValue1 === 'gradeA') {
        this.isAnexoI = true;
        this.isTextOneAnexI = true;
        // eslint-disable-next-line max-len, @typescript-eslint/restrict-template-expressions
        this.textOneParticleAnexI = `${this.translate.instant('protocolEdit.dialog.essay.particle0_5.label')} ${Constants.PARTICLE_COUNT_GRADE_A_0_5_ANEXOI_WORKING}`;
      }  else if (this.data.criteria.customValue1 === 'gradeB') {
        this.isAnexoI = true;
        // eslint-disable-next-line max-len, @typescript-eslint/restrict-template-expressions
        this.textOneParticleAnexI = `${this.translate.instant('protocolEdit.dialog.essay.particle0_5.label')} ${Constants.PARTICLE_COUNT_GRADE_B_0_5_ANEXOI_WORKING}`;
        // eslint-disable-next-line max-len, @typescript-eslint/restrict-template-expressions
        this.textTwoParticleAnexI = `${this.translate.instant('protocolEdit.dialog.essay.particle5_0.label')} ${Constants.PARTICLE_COUNT_GRADE_B_5_0_ANEXOI_WORKING}`;
      }  else if (this.data.criteria.customValue1 === 'gradeC') {
        this.isAnexoI = true;
        // eslint-disable-next-line max-len, @typescript-eslint/restrict-template-expressions
        this.textOneParticleAnexI = `${this.translate.instant('protocolEdit.dialog.essay.particle0_5.label')} ${Constants.PARTICLE_COUNT_GRADE_C_0_5_ANEXOI_WORKING}`;
        // eslint-disable-next-line max-len, @typescript-eslint/restrict-template-expressions
        this.textTwoParticleAnexI = `${this.translate.instant('protocolEdit.dialog.essay.particle5_0.label')} ${Constants.PARTICLE_COUNT_GRADE_C_5_0_ANEXOI_WORKING}`;

      }
    } else if (EssayAirTypeEnum.ANEXOI_COUNT_REPOSE === type || EssayAirTypeEnum.ANEX_COUNT === type) {
      if (this.data.criteria.customValue1 === 'gradeA') {
        this.isAnexoI = true;
        this.isTextOneAnexI = true;
        this.isMinParticleAnexI = false;
        // eslint-disable-next-line max-len, @typescript-eslint/restrict-template-expressions
        this.textOneParticleAnexI = `${this.translate.instant('protocolEdit.dialog.essay.particle0_5.label')} ${Constants.PARTICLE_COUNT_GRADE_A_0_5_ANEXOI_REPOSE}`;
      } else if (this.data.criteria.customValue1 === 'gradeB') {
        this.isAnexoI = true;
        this.isTextOneAnexI = true;
        this.isMinParticleAnexI = false;
        // eslint-disable-next-line max-len, @typescript-eslint/restrict-template-expressions
        this.textOneParticleAnexI = `${this.translate.instant('protocolEdit.dialog.essay.particle0_5.label')} ${Constants.PARTICLE_COUNT_GRADE_B_0_5_ANEXOI_REPOSE}`;
      }  else if (this.data.criteria.customValue1 === 'gradeC') {
        this.isAnexoI = true;
        // eslint-disable-next-line max-len, @typescript-eslint/restrict-template-expressions
        this.textOneParticleAnexI = `${this.translate.instant('protocolEdit.dialog.essay.particle0_5.label')} ${Constants.PARTICLE_COUNT_GRADE_C_0_5_ANEXOI_REPOSE}`;
        // eslint-disable-next-line max-len, @typescript-eslint/restrict-template-expressions
        this.textTwoParticleAnexI = `${this.translate.instant('protocolEdit.dialog.essay.particle5_0.label')} ${Constants.PARTICLE_COUNT_GRADE_C_5_0_ANEXOI_REPOSE}`;
      } else if (this.data.criteria.customValue1 === 'gradeD') {
        this.isAnexoI = true;
        // eslint-disable-next-line max-len, @typescript-eslint/restrict-template-expressions
        this.textOneParticleAnexI = `${this.translate.instant('protocolEdit.dialog.essay.particle0_5.label')} ${Constants.PARTICLE_COUNT_GRADE_D_0_5_ANEXOI_REPOSE}`;
         // eslint-disable-next-line max-len, @typescript-eslint/restrict-template-expressions
         this.textTwoParticleAnexI = `${this.translate.instant('protocolEdit.dialog.essay.particle5_0.label')} ${Constants.PARTICLE_COUNT_GRADE_D_5_0_ANEXOI_REPOSE}`;
      }
    }
  }

  private recalculateMapCustomValues(customValues: CriteriaTypeCustomValue[]): void {
    this.mapCustomValues = new Map();

    const promises = [];

    customValues.forEach(r => {
      promises.push(new Promise<void>((resolve) => this.criteriaTypeService.findCustomSubValues(r.id)
        .subscribe((subs: CriteriaTypeCustomSubValue[]) => {

          if (this.data.idEssayType === 2) {
            const mapAllowedTypes = AirUtils.getMapSpeedTypeCriteria();
            const allowedTypes = mapAllowedTypes.get(this.data.idEquipmentType) || [];
            subs = subs.filter(c => allowedTypes.includes(c.id));
          }

          this.mapCustomValues.set(r, subs);
          resolve();
        })));
    });

    void Promise.all(promises).then(() => {
      if (this.getTotalSubvalues() === 1) {
        this.onCriteriaCustomSubValueChange(this.mapCustomValues.get(this.getCustomValues()[0])[0].value);
      }
    });
  }

}
