<form [formGroup]="form">
  <mat-accordion>
    <mat-expansion-panel [expanded]="expandedFilter" (opened)="expandedFilter = true" (closed)="expandedFilter = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'clientList.filter.title' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

        <div fxFlex="25" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput placeholder="{{ 'clientList.filter.name.label' | translate }}"
                   formControlName="name" [(ngModel)]="filter.name">
          </mat-form-field>
        </div>

        <div fxFlex="25" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput placeholder="{{ 'clientList.filter.address.label' | translate }}"
                   formControlName="address" [(ngModel)]="filter.address">
          </mat-form-field>
        </div>

        <div fxFlex="25" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput placeholder="{{ 'clientList.filter.city.label' | translate }}"
                   formControlName="city" [(ngModel)]="filter.city">
          </mat-form-field>
        </div>

        <div fxFlex="25" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput placeholder="{{ 'clientList.filter.state.label' | translate }}"
                   formControlName="state" [(ngModel)]="filter.state">
          </mat-form-field>
        </div>

      </div>

      <mat-accordion>
        <mat-expansion-panel expanded="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'clientList.filter.advanced.title' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'clientList.filter.postalCode.label' | translate }}"
                       formControlName="postalCode" [(ngModel)]="filter.postalCode">
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'clientList.filter.country.label' | translate }}"
                       formControlName="country" [(ngModel)]="filter.country">
              </mat-form-field>
            </div>

          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <button mat-raised-button (click)="doFilter()"  color="primary">{{ 'button.filter' | translate }}</button>
        <button mat-raised-button (click)="cleanFilter()">{{ 'button.clean' | translate }}</button>
      </div>

    </mat-expansion-panel>
  </mat-accordion>
</form>
