<div fxFlex="20" fxHide.xs="true" fxHide.sm="true" fxHide.md="true" fxFlex.xs="100" *ngIf="showDivFixed()"></div>

<div [fxFlex]="showDivFixed() ? 80 : 100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100">
  <footer *ngIf="!isLoginClient">
    <div class="login" *ngIf="!isLoggedIn">
    </div>
  
    <div class="logged" *ngIf="isLoggedIn" fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="center center">
      <div class="footer-icon">
        <svg fxHide.lt-sm xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.94 99.4"><path d="M83.27 79.66c-1.72-.16-3.29-.32-4.7-.55-6.19-.79-8.15-2.74-11.12-4.78a32 32 0 01-17.39 5.4c-18.56 0-30.39-14.48-30.39-30A29.77 29.77 0 0149.9 19.67c17.31 0 30.31 13.33 30.31 29.83A29 29 0 0172 70.26c3.52 2.27 5.56 3.29 11.28 3.91zM49.82 25.38c-12.45 0-22.55 9.24-23.33 22.32a22.45 22.45 0 00.86 7.3 35.23 35.23 0 0111.82-2.12A29.8 29.8 0 0154.76 57c3 1.73 4.46 3 12.06 9.17a22.18 22.18 0 007-16.69c0-14.09-10.73-24.12-23.89-24.12zM50.14 74a22.69 22.69 0 0012.61-3.84c-1.57-1.49-7.52-6.11-8-6.58a28.14 28.14 0 00-16.29-5.17 25.53 25.53 0 00-9.95 2A23.61 23.61 0 0050.06 74z" fill="#244545"/><path d="M80.11 35.15v-15H65.37a32.53 32.53 0 0114.74 15z" fill="#48657b"/></svg>
        <svg fxHide.gt-xs xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220.27 119.02"><defs><style>.footer-icon{fill:#244545}</style></defs><path class="footer-icon" d="M85.22 52.81h4.2v22.66h14.18v3.7H85.22zM103.6 66.14a13.81 13.81 0 1113.75 13.53 13.52 13.52 0 01-13.75-13.53zm4.17-.15A9.62 9.62 0 10127 66c0-5.17-3.81-10-9.58-10a9.65 9.65 0 00-9.65 10zM139.56 52.81v16.46a7.78 7.78 0 001 4.6 4.69 4.69 0 004.06 2.1 4.52 4.52 0 004-2 7.7 7.7 0 001-4.67V52.81h4.17v16.46c0 3.49-.53 6.16-2.85 8.09a9.09 9.09 0 01-6.27 2.31 9.35 9.35 0 01-6.37-2.28c-2.6-2.31-2.92-4.91-2.89-8.12V52.81zM159.06 52.81h8.16c3.53 0 7.91.54 10.83 4.28a13.43 13.43 0 012.88 8.91c0 7.69-4.16 13.21-13.43 13.21h-8.44zm4.21 22.66h4.45c6.2 0 9.05-4 9.05-9.44a10.57 10.57 0 00-2.14-6.73c-1.39-1.61-3.49-2.78-6.88-2.78h-4.48zM59.63 39.34a20 20 0 00-20.29 20.19 20.49 20.49 0 005.52 14 20.26 20.26 0 0014.88 6.16A21.62 21.62 0 0071.41 76l.77.55c1.66 1.18 2.93 1.83 6.53 2.28 1 .17 3.26.3 3.26.3v-3.7c-3.81-.42-5.17-.52-7.57-2.07h-.06l.11-.11A19.39 19.39 0 0080 59.38a19.81 19.81 0 00-5.84-14.18 20.38 20.38 0 00-14.53-5.86zm8.69 34a15.36 15.36 0 01-8.53 2.59 15.92 15.92 0 01-14.56-9.21v-.06h.06a17.26 17.26 0 016.72-1.32 19 19 0 0111 3.49c.12.13.79.66 1.63 1.33 1.31 1 3.1 2.49 3.75 3.1h.05zm2.74-2.7L70.2 70c-4.39-3.57-5.44-4.42-7.33-5.52a20 20 0 00-10.46-2.78 23.46 23.46 0 00-7.84 1.39h-.17v-.06a15 15 0 01-.59-4.92 16.19 16.19 0 014.92-10.81 15.76 15.76 0 0110.85-4.23h.05A16.12 16.12 0 0171 47.65a16.32 16.32 0 014.74 11.73 15 15 0 01-4.68 11.27z"/><path d="M75.58 43.78a21.69 21.69 0 013.9 5.32v-9.26h-9.34a21.84 21.84 0 015.44 3.94z" fill="#48657b"/></svg>
      </div>
      <div class="footer-links" fxLayout="column" fxLayoutAlign.gt-xs="center center" fxLayoutAlign.gt-xs="center start">
        <p>{{'footer.title' | translate}}</p>
        <button mat-button (click)="mailTo('BUG')">{{'footer.link.error' | translate}}</button>
        <button mat-button (click)="mailTo('FEATURE')">{{'footer.link.feature' | translate}}</button>
      </div>
    </div>
  </footer>
</div>
