import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map, take } from 'rxjs/operators';

import { AppTypeEnum } from '../model/appType';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SnackBarService } from '../services/snackBar.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../services/user.service';

@Injectable()
export class CalibratesInterceptor implements CanActivate {

    constructor(
        private userService: UserService, private snackBarService: SnackBarService, private translate: TranslateService,
        private router: Router) { }

    canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {

        return this.userService.user$.pipe(take(1), map(() => {
            const user = this.userService.currentProfile;
            const app = user?.profiles.find(ap => ap.idApp === AppTypeEnum.CALIBRATES);

            if (user == null || user.username == null || (user.username != null && app)) {
                return true;
            } else {
                this.snackBarService.sendError(this.translate.instant('common.error.notInCalibrates') as string);
                void this.router.navigate(['/']);
                return false;
            }
        }));
    }
}
