import { AppPlanFilter, AppPlanType } from './appPlanType';
import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';

import { AppPlanTypeService } from '../services/appPlanType.service';
import { of } from 'rxjs';

export class AppPlanDataSource implements DataSource<AppPlanType> {

    private appPlansSubject = new BehaviorSubject<AppPlanType[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public loading$ = this.loadingSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public totalItems$ = this.totalSubject.asObservable();

    constructor(private service: AppPlanTypeService) { }

    connect(_collectionViewer: CollectionViewer): Observable<AppPlanType[]> {
        return this.appPlansSubject.asObservable();
    }

    disconnect(_collectionViewer: CollectionViewer): void {
        this.appPlansSubject.complete();
        this.loadingSubject.complete();
        this.totalSubject.complete();
    }

    loadAppPlans(filter = new AppPlanFilter()): void {

        this.loadingSubject.next(true);

        this.service.findAllFilter(filter).pipe(catchError(() => of([])), finalize(() => this.loadingSubject.next(false)))
            .subscribe(data => {
                if (data && data.content) {
                    this.appPlansSubject.next(data.content as AppPlanType[]);
                    this.totalSubject.next(data.totalElements as number);
                } else if (data) {
                    this.appPlansSubject.next(data as AppPlanType[]);
                    this.totalSubject.next(data.length as number);
                } else {
                    this.appPlansSubject.next([]);
                    this.totalSubject.next(0);
                }
            });
    }
}
