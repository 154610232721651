import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Essay } from 'src/app/model/essay';
import { EssayProtocol } from 'src/app/model/essayProtocol';
import { ExecutionStatus } from 'src/app/model/execution';
import { Sensor } from 'src/app/model/sensor';
import { SensorData } from 'src/app/model/sensorData';
import { AttachmentThermalService } from 'src/app/services/attachmentThermal.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { ExcelUtils } from 'src/app/utils/excelUtils';
import { ExecutionEditMassiveUploadComponent } from './execution-edit-massive-upload.component';


export interface DialogDataBottomSheetUpload {
  idStatusExecution: number;
  essays: Essay[];
  essay: EssayProtocol;
  essayIndex: number;
  variableIndex: number;
}

@Component({
  selector: 'app-execution-edit-bottom-sheet-upload',
  templateUrl: './execution-edit-bottom-sheet-upload.component.html'
})
export class ExecutionEditBottomSheetUploadComponent {

  @ViewChild('uploadExcelMassive') uploadExcelMassiveInput: ElementRef;
  @ViewChild('uploadAutomaticMassiveFile') uploadAutomaticMassiveInput: ElementRef;

  private destroy$ = new Subject<void>();

  constructor(
    private attachmentThermalService: AttachmentThermalService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DialogDataBottomSheetUpload,
    private bottomSheetRef: MatBottomSheetRef<ExecutionEditBottomSheetUploadComponent>,
    public snackBarService: SnackBarService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private spinnerService: SpinnerService) { }

  disableUploadPhotoButton(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return !allowedStatuses.includes(this.data.idStatusExecution);
  }

  disableUploadExcelButton(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return !allowedStatuses.includes(this.data.idStatusExecution);
  }

  disableUploadOriginalButton(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return !allowedStatuses.includes(this.data.idStatusExecution);
  }

  openMassiveUploadPhotoDialog(): void {
    this.openMassiveUploadGenericDialog('photo');
  }

  openMassiveUploadExcelDialog(): void {
    this.openMassiveUploadGenericDialog('excel');
  }

  openMassiveUploadPrimaryDataDialog(): void {
    this.openMassiveUploadGenericDialog('primary');
  }

  openMassiveUploadGenericDialog(type: string): void {
    const sensors = this.data.essay.sensors;

    const dialogRef = this.dialog.open(ExecutionEditMassiveUploadComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: { sensors, type }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Sensor[]) => {
      if (result != null) {
        this.data.essay.sensors = result;
        this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.uploadMassive.ok') as string);

        this.bottomSheetRef.dismiss();
      }
    });
  }

  onExcelMassiveUploadSensor(event): void {
    const variableSelected = this.data.essay;

    const file = event.target.files[0] as File;

    this.spinnerService.show();

    this.uploadExcelMassiveInput.nativeElement.value = '';

    if (file.name.endsWith('.pdf')) {
      this.spinnerService.show();

      this.attachmentThermalService.convertPdfToCsv(file, variableSelected.idVariable).pipe(takeUntil(this.destroy$)).subscribe(res => {
        res.name = file.name;
        this.uploadMassiveDataExcel(res as File);
      });
    } else {
      this.uploadMassiveDataExcel(file);
    }
  }

  uploadAutomaticMassive(event): void {
    this.spinnerService.show();

    const fileList: FileList = event.target.files as FileList;
    const files: File[] = Array.from(fileList);

    if (ArrayUtils.isEmpty(files)) {
      this.uploadAutomaticMassiveInput.nativeElement.value = '';

      this.spinnerService.hide();
      return;
    }

    void this.generatePromisesMassiveAutomatic(files).then(() => {
      this.spinnerService.show();
      setTimeout(() => {
        this.spinnerService.hide();

        this.bottomSheetRef.dismiss({ action: 'reloadPage' });
      }, files.length * 200);
    });

    this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.uploadMassiveAutomatic.ok') as string);
    this.uploadAutomaticMassiveInput.nativeElement.value = '';
    this.spinnerService.hide();
  }

  private generatePromisesMassiveAutomatic(files: File[]): Promise<any> {
    const filesPromises = new Map<File, Promise<any>>();
    files.forEach(f => filesPromises.set(f, new Promise<void>((resolve) => {

      const fileName = f.name;

      if (fileName.includes(',')) {
        const fileNameNoExt = fileName.substring(0, fileName.lastIndexOf('.'));
        const ext = fileName.substring(fileName.lastIndexOf('.'));

        fileNameNoExt.split(',').map(s => s.trim()).forEach(s => {
          const file = new File([f], s.concat(ext), { type: f.type });
          void this.generatePromiseMassiveAutomatic(file);
        });
      } else {
        void this.generatePromiseMassiveAutomatic(f);
      }

      resolve();
    })));

    return new Promise((resolve) => {
      void Promise.all(filesPromises).then(resolve);
    });
  }

  private async generatePromiseMassiveAutomatic(file: File): Promise<any> {
    const variableSelected = this.data.essay;
    const sensors = variableSelected.sensors;

    const fileName: string = file.name;
    let eqName = fileName.substring(0, file.name.indexOf(' '));

    if (eqName === '') {
      eqName = fileName.substring(0, fileName.lastIndexOf('.'));
    }

    return new Promise<void>((resolve) => {
      const possibleIndex = +eqName;

      if (!isNaN(possibleIndex) && typeof sensors[possibleIndex - 1] !== 'undefined') {
        const indexSensor = possibleIndex;
        const sensor = sensors[indexSensor - 1];

        this.resolvePromiseMassiveAutomatic(indexSensor, sensor, file, resolve);
      } else {
        let checked = false;

        for (let indexSensor = 0; !checked && indexSensor < sensors.length; indexSensor++) {
          const sensor = sensors[indexSensor];

          if (eqName.toLowerCase() === sensor.equipmentName.toLowerCase() || eqName.toLowerCase() === sensor.serialNum.toLowerCase()) {
            this.resolvePromiseMassiveAutomatic(indexSensor, sensor, file, resolve);
            checked = true;
          }
        }
      }
    });
  }

  private uploadMassiveDataExcel(file: File) {
    const variableSelected = this.data.essay;

    const allSensors = variableSelected.sensors;
    const sensors = allSensors.filter(s => s.evaluate);
    const totalSens = allSensors.length;

    const sensorsName = sensors.map(s => s.equipmentName);
    const sensorsSerialNum = sensors.map(s => s.serialNum);

    const idVariable = variableSelected.idVariable;

    ExcelUtils.excelToSensorData(file, this.attachmentThermalService, idVariable, true, totalSens).then((sensorsData: SensorData[]) => {

      if (sensorsData == null || sensorsData.length === 0) {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadExcel.error.generic') as string);

        this.bottomSheetRef.dismiss({ action: 'updateGraphByIndex' });

        return;
      }

      const uniqueName = sensorsData.map(sD => sD.equipmentName).filter((v, i, a) => a.indexOf(v) === i);
      const uniqueSerialNum = sensorsData.map(sD => sD.serialNum).filter((v, i, a) => a.indexOf(v) === i);

      let promises = [];

      const anyByName = uniqueName.length != null && uniqueName.filter(name => sensorsName.includes(name)).length !== 0;
      const anyBySerialNum = uniqueSerialNum.length != null && uniqueSerialNum.filter(name => sensorsSerialNum.includes(name)).length !== 0;

      if (anyByName) {
        promises = this.generatePromisesExcelName(sensorsData, allSensors, file);
      } else if (anyBySerialNum) {
        promises = this.generatePromisesExcelSerialNum(sensorsData, allSensors, file);
      } else {
        promises = this.generatePromisesExcelPosition(sensorsData, sensors, file);
      }

      Promise.all(promises.filter(p => p != null)).then(() => {
        this.spinnerService.hide();
        this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.uploadExcel.ok') as string);

        this.bottomSheetRef.dismiss({ action: 'updateGraphByIndex' });
      }).catch(err => {
        console.error(err);
        this.snackBarService.sendError(err as string);
        this.spinnerService.hide();

        this.bottomSheetRef.dismiss({ action: 'updateGraphByIndex' });
      });

    }).catch(err => {
      console.error(err);
      this.snackBarService.sendError(err as string);
      this.spinnerService.hide();
    });
  }

  private generatePromisesExcelName(sensorsData: SensorData[], sensors: Sensor[], file: File) {
    const promises: Promise<any>[] = [];

    const variableSelected = this.data.essay;

    const groupByNameAux = ArrayUtils.groupBy(sensorsData, (item: SensorData) => item.equipmentName) as Map<string, SensorData[]>;

    const groupByName = new Map<string, SensorData[]>();

    const sensorsConsider = sensors.filter(s => s.evaluate).map(s => s.equipmentName);

    groupByNameAux.forEach((val, key) => {
      if (sensorsConsider.includes(key)) {
        groupByName.set(key, val);
      }
    });

    let itsFirtsCall = true;

    groupByName.forEach((data, name) => {
      promises.push(new Promise<void>((resolve, reject) => {
        const sensor = sensors.find(s => s.equipmentName === name);

        if (sensor == null || !sensor.evaluate) {
          return;
        }

        const sensorIndex = sensors.indexOf(sensor);

        data.forEach(item => {
          item.serialNum = sensor.serialNum;
          item.equipmentName = sensor.equipmentName;
          item.idEquipment = sensor.idEquipment;
          delete item.posEquipment;
        });

        data = data.filter(item => item.date != null && !isNaN(item.date.getTime()));

        const map = ArrayUtils.groupBy(data, (item: SensorData) => item.idVariable) as Map<number, SensorData[]>;

        if (map.size > 1) {
          data = map.get(variableSelected.idVariable);
        }

        this.uploadExcelFromMassive(sensorIndex, file, data, resolve, reject, itsFirtsCall);
        itsFirtsCall = false;
      }));
    });

    return promises;
  }

  private generatePromisesExcelSerialNum(sensorsData: SensorData[], sensors: Sensor[], file: File) {
    const promises: Promise<any>[] = [];

    const variableSelected = this.data.essay;

    const groupBySerialNumAux = ArrayUtils.groupBy(sensorsData, (item: SensorData) => item.serialNum) as Map<string, SensorData[]>;
    const groupBySerialNum = new Map<string, SensorData[]>();

    const sensorsConsider = sensors.filter(s => s.evaluate).map(s => s.serialNum);

    groupBySerialNumAux.forEach((val, key) => {
      if (sensorsConsider.includes(key)) {
        groupBySerialNum.set(key, val);
      }
    });

    let itsFirtsCall = true;

    sensors.filter(s => !s.evaluate).map(s => s.serialNum).forEach(s => groupBySerialNum.delete(s));

    groupBySerialNum.forEach((data, name) => {
      promises.push(new Promise<void>((resolve, reject) => {
        const sensor = sensors.find(s => s.serialNum === name);

        if (sensor == null || !sensor.evaluate) {
          return;
        }

        const sensorIndex = sensors.indexOf(sensor);

        data.forEach(item => {
          item.serialNum = sensor.serialNum;
          item.equipmentName = sensor.equipmentName;
          item.idEquipment = sensor.idEquipment;
          delete item.posEquipment;
        });

        data = data.filter(item => item.date != null && !isNaN(item.date.getTime()));

        const map = ArrayUtils.groupBy(data, (item: SensorData) => item.idVariable) as Map<number, SensorData[]>;

        if (map.size > 1) {
          data = map.get(variableSelected.idVariable);
        }

        this.uploadExcelFromMassive(sensorIndex, file, data, resolve, reject, itsFirtsCall);
        itsFirtsCall = false;
      }));
    });

    return promises;
  }

  private generatePromisesExcelPosition(sensorsData: SensorData[], sensors: Sensor[], file: File) {
    const promises: Promise<any>[] = [];

    const variableSelected = this.data.essay;

    const groupByNum = ArrayUtils.groupBy(sensorsData, (item: SensorData) => item.posEquipment) as Map<number, SensorData[]>;

    let itsFirtsCall = true;

    groupByNum.forEach((data, sensorIndex) => {
      promises.push(new Promise<void>((resolve, reject) => {
        const sensor = sensors[sensorIndex];

        data.forEach(item => {
          item.serialNum = sensor.serialNum;
          item.equipmentName = sensor.equipmentName;
          item.idEquipment = sensor.idEquipment;
          delete item.posEquipment;
        });

        data = data.filter(item => item.date != null && !isNaN(item.date.getTime()));

        const map = ArrayUtils.groupBy(data, (item: SensorData) => item.idVariable) as Map<number, SensorData[]>;

        if (map.size > 1) {
          data = map.get(variableSelected.idVariable);
        }

        this.uploadExcelFromMassive(sensorIndex, file, data, resolve, reject, itsFirtsCall);
        itsFirtsCall = false;
      }));
    });

    return promises;
  }

  private uploadExcelFromMassive(sensorIndex: number, file: File, data: SensorData[], callback: () => void,
    errorCallback: () => void, itsFirtsCall: boolean) {
    const sensor = this.getSensor(sensorIndex);

    this.attachmentThermalService.uploadExcelToSensor(sensor.id, file, data).pipe(takeUntil(this.destroy$)).subscribe((item: number) => {
      sensor.idExcel = item;
      sensor.data = data;

      this.bottomSheetRef.dismiss({ action: 'updateSensor', data: { sensorIndex, sensor, itsFirtsCall } });

      callback();
    }, () => errorCallback());
  }

  private uploadPromiseMassiveDataExcel(sensorIndex: number, file: File, callback) {
    const variableSelected = this.data.essay;

    const sensor = this.getSensor(sensorIndex);

    void ExcelUtils.excelToSensorData(file, this.attachmentThermalService, variableSelected.idVariable, false, 1).then(data => {
      data.forEach(item => {
        item.serialNum = sensor.serialNum;
        item.equipmentName = sensor.equipmentName;
        item.idEquipment = sensor.idEquipment;
      });

      const map = ArrayUtils.groupBy(data, (item: SensorData) => item.idVariable);

      if (map.size > 1) {
        data = map.get(variableSelected.idVariable) as SensorData[];
      }

      data.forEach(item => delete item.posEquipment);

      data = data.filter(item => item.date != null && !isNaN(item.date.getTime()));

      this.attachmentThermalService.uploadExcelToSensor(sensor.id, file, data).pipe(takeUntil(this.destroy$)).subscribe((res: number) => {
        sensor.idExcel = res;
        sensor.data = data;

        this.bottomSheetRef.dismiss({ action: 'updateSensor', data: { sensorIndex, sensor, itsFirtsCall: true } });

        callback();
      });
    });
  }

  private resolvePromiseMassiveAutomatic(sensorIndex: number, sensor: Sensor, file: File,
    resolve: ((value: void | PromiseLike<void>) => void)): void {

    const fileName: string = file.name;

    const variableSelected = this.data.essay;

    const excelExts = ['.xls', '.xlsx', '.csv', '.pdf'];

    const ext = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

    if (excelExts.indexOf(ext) > -1) {

      if (file.name.endsWith('.pdf')) {
        this.spinnerService.show();

        this.attachmentThermalService.convertPdfToCsv(file, variableSelected.idVariable).pipe(takeUntil(this.destroy$))
          .subscribe(res => {
            res.name = file.name;
            this.uploadPromiseMassiveDataExcel(sensorIndex, res as File, resolve());
          });
      } else {
        this.uploadPromiseMassiveDataExcel(sensorIndex, file, resolve());
      }

    } else if (file.type.includes('image/')) {
      this.attachmentThermalService.uploadPhotoToSensor(sensor.id, file).pipe(takeUntil(this.destroy$))
        .subscribe((res: number) => {
          sensor.idPhoto = res;

          this.bottomSheetRef.dismiss({ action: 'updateSensorPhoto', data: { sensorIndex, sensor, itsFirtsCall: true } });

          resolve();
        });
    } else {
      this.attachmentThermalService.uploadPrimaryFileToSensor(sensor.id, file).pipe(takeUntil(this.destroy$))
        .subscribe((res: number) => {
          sensor.idPrimaryDataFile = res;

          this.bottomSheetRef.dismiss({ action: 'updateSensorPrimary', data: { sensorIndex, sensor, itsFirtsCall: true } });

          resolve();
        });
    }
  }

  private getSensor(sensorIndex: number): Sensor {
    return this.data.essay.sensors[sensorIndex];
  }

}
