import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExecutionAudit, ExecutionAuditFilter } from 'src/app/model/execution';
import { Subject, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';


import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { ExecutionQService } from 'src/app/services/executionQ.service';
import { ExecutionQAuditDataSource } from 'src/app/model/executionQAuditDataSource';

@Component({
  selector: 'app-execution-edit-q-audit',
  templateUrl: './execution-edit-q-audit.component.html'
})
export class ExecutionQEditAuditComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() idExecution: number;
  @Input() documentCode: string;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public filter: ExecutionAuditFilter;

  public displayedColumns = ['date', 'username', 'action', 'status', 'currentVersion', 'changes', 'download'];
  public dataSource: ExecutionQAuditDataSource;

  private destroy$ = new Subject<void>();

  constructor(
    private executionService: ExecutionQService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    this.spinnerService.show();
  }

  ngOnInit(): void {
    this.filter = new ExecutionAuditFilter();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.getAudit())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  getAudit(): void {
   
    this.dataSource = new ExecutionQAuditDataSource(this.executionService);

    this.filter.sortBy = this.sort.active || 'date';
    this.filter.sortDirection = this.sort.direction || 'asc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 10;
   
  }

  showDownloadSignedPdf(prot: ExecutionAudit): boolean {
    return prot.canDownload;
  }

  private getNameForExecution(documentCode: string, version: string): string {
    let name = documentCode + ' v.' + version.toString().padStart(2, '0');
    name = this.translate.instant('executionEdit.files.report', { name }) as string;

    return name;
  }

}
