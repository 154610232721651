<h1 mat-dialog-title>{{ 'protocolEdit.dialog.generateReport.title' | translate }}</h1>
<form>

  <div mat-dialog-content style="overflow-y: hidden;" class="air-theme">

    <mat-form-field class="campoFormulario">
      <mat-label>{{ 'common.reportType.options.reviewBy.label' | translate }}</mat-label>
      <mat-select [(ngModel)]="reportConfig.reviewBy" [ngModelOptions]="{standalone: true}">
        <mat-option>--</mat-option>
        <mat-option *ngFor="let item of respDocs" [value]="item.username">
          {{item.fullName}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="campoFormulario" *ngIf="showChangeLangIcon()">
      <mat-label>{{ 'common.reportType.options.language.label' | translate }}</mat-label>
      <mat-select [(ngModel)]="reportConfig.lang" [ngModelOptions]="{standalone: true}">
        <mat-option *ngFor="let item of langs" [value]="item.lang">
          <mat-icon svgIcon="{{item.flag}}"></mat-icon>
          {{item.fullLang}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <br /><br />

  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()"
      cdkFocusInitial>{{ 'button.download' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>