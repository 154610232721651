import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from 'src/app/services/snackBar.service';

export interface DialogDataReasonExpositionTime {
  reason: string;
}

@Component({
  selector: 'app-execution-edit-reason-exposition-time',
  templateUrl: './execution-edit-reason-exposition-time.component.html'
})
export class ExecutionEditReasonExpositionTimeComponent {

  constructor(
    private dialogRef: MatDialogRef<ExecutionEditReasonExpositionTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataReasonExpositionTime,
    private translate: TranslateService,
    public snackBarService: SnackBarService) { }

  onOkClick(): void {
    const errs = [];

    if (this.data.reason == null || this.data.reason === '') {
      errs.push(this.translate.instant('common.reasonChangeError'));
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.reason);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
