<form>
  <div mat-dialog-content class="air-theme">

    <div fxLayout="row" class="flexwrap">

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'protocolEdit.dialog.equipment.type.label' | translate }}</mat-label>
          <mat-select name="idType" [(ngModel)]="data.compressedGas.idType" required>
            <mat-option *ngFor="let item of this.data.compressedGasTypes" [value]="item.id">
              {{ 'compressedGasType.' + item.translation | translate }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.type.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario" *ngIf="data.compressedGas.idType === 4">
          <input matInput name="code" [(ngModel)]="data.compressedGas.others" required
            placeholder="{{ 'protocolEdit.dialog.equipment.other.label' | translate }}">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.other.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput name="code" [(ngModel)]="data.compressedGas.code" required
            placeholder="{{ 'protocolEdit.dialog.equipment.code.label' | translate }}">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.code.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput name="location" [(ngModel)]="data.compressedGas.location"
            placeholder="{{ 'protocolEdit.dialog.equipment.location.label' | translate }}">
        </mat-form-field>
      </div>

    </div>
  </div>
  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>