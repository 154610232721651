import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ThemeService } from 'src/app/services/theme.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';

export interface DialogDataDocumentCode {
  documentCode: string;
}

@Component({
  selector: 'app-edit-document-code-edit',
  templateUrl: './edit-document-code-edit.component.html'
})
export class EditDocumentCodeEditComponent {

  constructor(
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataDocumentCode,
    public dialogRef: MatDialogRef<EditDocumentCodeEditComponent>,
    public snackBarService: SnackBarService,
    private themeService: ThemeService) { }

  onOkClick(): void {
    const errors = [];

    if (this.data.documentCode == null) {
      errors.push(this.translate.instant('protocolEdit.dialog.documentCode.documentCode.error'));
    }

    if (ArrayUtils.isEmpty(errors)) {
      this.dialogRef.close(this.data.documentCode);
    } else {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);
      console.error(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTheme(): string {
    return this.themeService.getTheme();
  }

}
