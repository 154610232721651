export class Changes<T> {
    id: number;
    type: string;
    changes: T;
    father_id: number;
    execution_id: number;
    timestamp: string;

    areaId: number;
    areaIdOffline: string;

    equipmentId: number;
    equipmentIdOffline: string;

    roomId: number;
    roomIdOffline: string;

    gasId: number;
    gasIdOffline: string;

    essayId: number;
    essayIdOffline: string;

    volumeId: number;
    volumeIdOffline: string;

    filterIdOffline: string;

    dateDeleted: string;
    deleted: boolean;
    constructor(origin: any) {
        for (const prop in origin) {
            if (prop !== '') {
                this[prop] = origin[prop];
            }
        }
    }
}
