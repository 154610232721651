import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Procedure, ProcedureFilter } from '../../../../model/procedure';
import { ProcedureService } from '../../../../services/procedure.service';
import { Subject, merge, takeUntil, tap } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ProcedureDataSource } from '../../../../model/procedureDataSource';
import { SpinnerService } from '../../../../services/spinner.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ProcedureEditDialogComponent } from '../procedure-edit/procedure-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ActionConfirmPasswordComponent } from '../../../shared/action-confirm-password/action-confirm-password.component';
import * as _ from 'lodash-es';

@Component({
  selector: 'app-procedure-list',
  templateUrl: './procedure-list.component.html',
  styleUrls: ['./procedure-list.component.scss']
})
export class ProcedureListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  procedures: Procedure[] = [];
  dataSource: ProcedureDataSource;
  filter: ProcedureFilter = new ProcedureFilter();
  hasFiltered = false;

  displayedColumns: string[] = [
    'name', 'description', 'edit', 'delete'
  ];

  private destroy$ = new Subject<void>();

  constructor(private procedureService: ProcedureService,
    private spinnerService: SpinnerService,
    private router: Router,
    private location: Location,
    public dialog: MatDialog,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.loadProcedureList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadProcedureList()))
      .pipe(takeUntil(this.destroy$)).subscribe();
  }

  loadProcedureList(): void {
    this.dataSource = new ProcedureDataSource(this.procedureService);

    this.filter.sortBy = this.sort.active || 'name';
    this.filter.sortDirection = this.sort.direction || 'desc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 10;

    if (this.hasFiltered) {
      this.saveSearchFilter(this.filter);
      this.dataSource.loadProcedure(this.filter);
    }

    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }


  saveSearchFilter(execution: ProcedureFilter): void {
    localStorage.setItem('filter-procedure', JSON.stringify(execution));
  }

  newProcedure(): void {
    void this.router.navigateByUrl('admin/procedure?id=0');
  }

  cancel(): void {
    this.location.back();
  }

  doFilter(event: ProcedureFilter): void {
    this.filter = event;

    this.hasFiltered = true;

    this.loadProcedureList();
  }

  openDialogProcedure(): void {
    const dialogRef = this.dialog.open(ProcedureEditDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        procedure: new Procedure(),
        isEdit: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Procedure) => {
      if (result != null) {
        this.loadProcedureList();
      }
    });
  }

  editDialogProcedure(data: Procedure): void {
    const dialogRef = this.dialog.open(ProcedureEditDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        procedure:  _.cloneDeep(data),
        isEdit: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Procedure) => {
      if (result != null) {
        this.loadProcedureList();
      }
    });
  }

  deleteRow(id: number): void {
    if (id > 0) {

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('procedureEdit.delete.confirmation') as string
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
        if (result === true) {

          const dialogRefReason = this.dialog.open(ActionConfirmPasswordComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {}
          });
          dialogRefReason.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
            if (token != null) {
              this.spinnerService.show();

              this.procedureService.delete(id).pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.spinnerService.hide();

                this.loadProcedureList();
              });
            }
          });
        }
      });
    }
  }

}
