<h1 mat-dialog-title>{{ 'protocolEdit.dialog.essay.sensorsTitle' | translate }}</h1>
<form>
    <div mat-dialog-content class="thermal-theme">

        <mat-form-field class="campoFormulario">
            <textarea matInput placeholder="{{ 'protocolEdit.dialog.essay.sensorsTitle' | translate }}"
                [(ngModel)]="data.sensors" name="sensors" style="resize: none;" cdkTextareaAutosize></textarea>
        </mat-form-field>

    </div>
    <div mat-dialog-action class="thermal-theme"s>
        <div fxFlex></div>

        <button mat-raised-button color="primary" (click)="onOkClick()" cdkFocusInitial>
            {{ 'button.save' | translate }}</button>

        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>

</form>