<h1 mat-dialog-title>{{ 'Visualizador' | translate }}</h1>

<div mat-dialog-content  [class]="getTheme()">
    <button color="accent" mat-raised-button (click)="attachmentUpload.click()">
        <mat-icon>cloud_upload</mat-icon> {{ 'Cargar' | translate }}
    </button>
    <input hidden type="file" multiple (change)="onImageUpload($event)" #attachmentUpload>
    <div class="container">
        <div class="resizable">
          <img [src]="imageSrc" alt="Image" *ngIf="imageSrc" />
          <div class="resize-handle resize-handle-top-left"></div>
          <div class="resize-handle resize-handle-top-right"></div>
          <div class="resize-handle resize-handle-bottom-left"></div>
          <div class="resize-handle resize-handle-bottom-right"></div>
        </div>
      </div>

</div>

<div mat-dialog-actions align="end" [class]="getTheme()">
    <button mat-raised-button color="primary" (click)="uploadImageToVerification()">{{ 'button.save' | translate
        }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
</div>