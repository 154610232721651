import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { Sensor } from 'src/app/model/sensor';
import { SnackBarService } from 'src/app/services/snackBar.service';

export interface DialogDataConfigSensor {
  sensor: Sensor;
  isLastSensor: boolean;
}

@Component({
  selector: 'app-execution-edit-config-sensor',
  templateUrl: './execution-edit-config-sensor.component.html'
})
export class ExecutionEditConfigSensorComponent {

  constructor(
    private translate: TranslateService,
    private dialogRef: MatDialogRef<ExecutionEditConfigSensorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataConfigSensor,
    public snackBarService: SnackBarService) { }

  onEvaluateChange(event: MatSlideToggleChange): void {
    this.data.sensor.evaluate = event.checked;
  }

  onOuterChange(event: MatSlideToggleChange): void {
    this.data.sensor.outer = event.checked;
  }

  onOkClick(): void {
    const errs = [];

    const sensor = this.data.sensor;

    if (sensor.location == null || sensor.location === '') {
      errs.push(this.translate.instant('executionEdit.sensorConfig.form.error.location'));
    }

    if (sensor.fromProtocol) {
      if (sensor.evaluate) {
        sensor.evaluateReason = null;
      } else if (sensor.evaluateReason == null || sensor.evaluateReason === '') {
        errs.push(this.translate.instant('executionEdit.sensorConfig.form.error.evaluateReason'));
      }

      if (sensor.location === sensor.locationProtocol) {
        sensor.locationChangeReason = null;
      } else if (sensor.locationChangeReason == null || sensor.locationChangeReason === '') {
        errs.push(this.translate.instant('executionEdit.sensorConfig.form.error.locationReason'));
      }
    } else if (sensor.addingReason == null || sensor.addingReason === '') {
      errs.push(this.translate.instant('executionEdit.sensorConfig.form.error.addingReason'));
    }

    if (errs.length === 0) {
      this.dialogRef.close(sensor);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
