<form [formGroup]="form">
    <mat-accordion>
        <mat-expansion-panel [expanded]="expandedFilter" (opened)="expandedFilter = true"
            (closed)="expandedFilter = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'isothermalCharacterizationList.filter.title' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                <div fxFlex="25" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                        <input matInput formControlName="name" [(ngModel)]="filter.name"
                            placeholder="{{ 'procedure.filter.name.label' | translate }}">
                    </mat-form-field>
                </div>

                <div fxFlex="25" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                        <input matInput formControlName="description" [(ngModel)]="filter.description"
                            placeholder="{{ 'procedure.filter.description.label' | translate }}">
                    </mat-form-field>
                </div>

            </div>

            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
                <button mat-raised-button (click)="doFilter()" color="primary">
                    {{ 'button.filter' | translate }}</button>
                <button mat-button (click)="cleanFilter()">{{ 'button.clean' | translate }}</button>
            </div>

        </mat-expansion-panel>
    </mat-accordion>
</form>