import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { NotificationFilter } from '../model/notification';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class NotificationService {

    constructor(private http: HttpClient) {
    }

    findAll(filter: NotificationFilter): Observable<any> {
        const url = environment.thermalUrl + '/notification/';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    markAsRead(id: number): Observable<any> {
        const url = environment.thermalUrl + `/notification/${id}/read`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    markAllAsRead(): Observable<any> {
        const url = environment.thermalUrl + '/notification/readAll';

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
