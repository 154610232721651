import { Subject, takeUntil } from 'rxjs';

import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { Component } from '@angular/core';
import { Group } from 'src/app/model/group';
import { ManageGroupsService } from 'src/app/services/manageGroups.service';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ThemeService } from 'src/app/services/theme.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-group-to-clone',
  templateUrl: './select-group-to-clone.component.html'
})
export class SelectGroupToCloneComponent {

  groups: Group[];

  groupSelected: number;

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private manageGroupsService: ManageGroupsService,
    private themeService: ThemeService,
    public dialogRef: MatDialogRef<SelectGroupToCloneComponent>,
    public snackBarService: SnackBarService) {
    this.manageGroupsService.findAllToClone().pipe(takeUntil(this.destroy$)).subscribe((res: Group[]) => this.groups = res);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onOkClick(): void {
    const errors = [];

    if (this.groupSelected == null) {
      errors.push(this.translate.instant('generic.clone.groupSelected.error'));
    }

    if (ArrayUtils.isEmpty(errors)) {
      this.dialogRef.close(this.groupSelected);
    } else {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);
      console.error(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTheme(): string {
    return this.themeService.getTheme();
  }

}
