import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { Area } from 'src/app/model/area';
import { SnackBarService } from 'src/app/services/snackBar.service';

export interface DialogDataArea {
  area: Area;
  isEdit: boolean;
  isExecution: boolean;
  withoutProtocol: boolean;
}

@Component({
  selector: 'app-protocol-edit-dialog-area',
  templateUrl: './protocol-edit-dialog-area.component.html'
})
export class ProtocolEditDialogAreaComponent {

  tituloDialog: string;

  constructor(
    public snackBarService: SnackBarService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<ProtocolEditDialogAreaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataArea) {
    if (data.isEdit) {
      this.tituloDialog = this.translate.instant('protocolEdit.dialog.area.title.edit', { name: data.area.name }) as string;
    } else {
      this.tituloDialog = this.translate.instant('protocolEdit.dialog.area.title.create') as string;
    }

    if (data.isExecution === false) {
      data.withoutProtocol = false;
    }

  }


  onOkClick(): void {
    const errs: string[] = [];

    if (this.data.area.name == null) {
      errs.push(this.translate.instant('protocolEdit.dialog.area.name.error') as string);
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.area);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showReason(): boolean {
    const area = this.data.area;

    return this.data.isExecution && area.fromProtocol && (area.name !== area.nameProtocol);
  }

}
