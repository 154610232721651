import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { ProtocolQ, ProtocolQFilter } from './protocol';
import { catchError, finalize } from 'rxjs/operators';

import { of } from 'rxjs';
import { ProtocolQService } from '../services/protocolQ.service';
import { ExecutionQ, ExecutionQFilter } from './execution';
import { ExecutionQService } from '../services/executionQ.service';

export class ExecutionQDataSource implements DataSource<ExecutionQ> {

    private executionsSubject = new BehaviorSubject<ExecutionQ[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public loading$ = this.loadingSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public totalItems$ = this.totalSubject.asObservable();

    constructor(private service: ExecutionQService) { }

    connect(collectionViewer: CollectionViewer): Observable<ExecutionQ[]> {
        return this.executionsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.executionsSubject.complete();
        this.loadingSubject.complete();
        this.totalSubject.complete();
    }

    loadExecution(filter = new ExecutionQFilter()): void {

        this.loadingSubject.next(true);

        this.service.findAll(filter).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(data => {
            if (data && data.content) {
                this.executionsSubject.next(data.content as ExecutionQ[]);
                this.totalSubject.next(data.totalElements as number);
            } else if (data) {
                this.executionsSubject.next(data as ExecutionQ[]);
                this.totalSubject.next(data.length as number);
            } else {
                this.executionsSubject.next([]);
                this.totalSubject.next(0);
            }
        });
    }

}
