<div class="main-container-client" >

    <div fxFlex="100" fxLayout="column">
        <div fxFlex="50">
            <mat-card class="loginBoxClient" style="width: 30%; margin: auto;">
                <mat-card-content>
                  <form #userlogin="ngForm" class="form">
                    <h2 style=" text-align: center;">{{ 'forgottenPassword.title' | translate }}</h2>
                    <mat-form-field class="full-width">
                      <input matInput placeholder="{{ 'login.username.label' | translate }}" name="username"
                        autocomplete="username" [(ngModel)]="username" required [errorStateMatcher]="matcher"
                        (keydown.enter)="forgotPassword()">
                      <mat-error>
                        {{ 'login.username.error' | translate }}
                      </mat-error>
                    </mat-form-field>
                    <p *ngIf="message">
                      {{ message }}
                    </p>
                    <p *ngIf="error" class="error">
                      {{ error }}
                    </p>
                  </form>
                </mat-card-content>
                <mat-card-actions class="mat-card-actions">
                  <button mat-raised-button class="btnLogin" (click)="forgotPassword()">{{ 'button.send' | translate }}</button>
                </mat-card-actions>
              </mat-card>
        </div>
        <div fxFlex="50">
            <a class="under-card-link" (click)="backLogin()">
                {{ 'button.goBack' | translate }}
              </a>
        </div>
    </div>
  
  </div>
  