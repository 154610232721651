export class JsonUtil {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static convertJSObjToTSMap(jsObj: string): Map<any, any> {
        const tsMap = new Map();
        const arrayOfMapEntries = new Map<any, any>(Object.entries(jsObj));
        for (const [key, value] of arrayOfMapEntries.entries()) {
            tsMap.set(key, value);
        }
        return tsMap;
    }

    static convertTSMapToJSObj(tsMap: Map<number, string>): any {
        const jsObj = {};
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        tsMap.forEach(function (value: any, key: string | number) {
            jsObj[key] = value;
        });
        return jsObj;
    }
}