import { ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';

export function requiredIf(req: { value: boolean }): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;

    if ((value == null || value === '') && req.value) {
      return {
        requiredIf: { condition: req.value }
      };
    }
    return null;
  };

}
