import { GenericFilter } from './genericClass';

export class Notification {
    id: number;

    subject: string;
    message: string;

    date: Date;

    idStatus: number;
    nameStatus: string;
    statusTranslation: string;

    expanded = false;
}

export class NotificationManage {
    id: number;

    name: string;

    notifiedUsers: string[];
}

export class SendNotification {
    subject: string;
    text: string;

    sendMail = false;

    notifiedUsers: string[] = [];
}

export class NotificationFilter extends GenericFilter {
}


export class ManageNotificationFilter extends GenericFilter {
    name: string;
}