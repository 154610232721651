import { NumberUtils } from '../utils/numberUtils';

export class Volume {
    id: number;

    idRoom: number;

    surface: number;
    height: number;
    idOffline: string;

    get volume(): number {
        if (this.surface == null || this.height == null) {
            return null;
        }

        return NumberUtils.fixPrecision(this.surface * this.height);
    }
}