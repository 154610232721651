<mat-toolbar [color]="isLoggedIn ? 'primary' : ''" [class]="isLoggedIn ? 'mat-toolbar-border' : ''">

  <mat-toolbar-row>
    <div fxFlex="40" fxLayout fxLayoutGap="20px" fxLayoutAlign="flex-start" [fxHide]="!showMenu()">
      <button mat-stroked-button (click)="toggleDrawer()" *ngIf="isLoggedIn && menuHide" class="btn-sidebar-toggle stroked">
        <mat-icon class="icon">menu</mat-icon>
        <span class="text mayusculas">{{'common.menu' | translate}}</span>
      </button>
    </div>

    <a mat-list-item routerLink="/" fxFlex="20" fxFlex.xs="70" fxFlex.md="100" fxLayoutGap="20px" fxLayoutAlign="center"
      [ngClass]="showMenu() ? 'logo-header-container' : ''" [ngClass.sm]="'logo-header-container'" [ngClass.md]="'logo-header-container'" >
      <div *ngIf="isLoggedIn">
        <svg [ngClass]="showMenu() ? 'size-logo-menu' : 'size-logo-menu-start'" ngClass.xs="size-logo-menu-start" ngClass.sm="size-logo-menu"  ngClass.md="size-logo-menu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.6 40.33"><defs><style>.a{fill:#244545;}.b{fill:#48657b;}</style></defs><title>qloud_logo_web</title><path class="a" d="M45.88,13.47h4.21V36.13H64.26v3.7H45.88Z"/><path class="a" d="M64.26,26.79A13.81,13.81,0,1,1,78,40.33a13.52,13.52,0,0,1-13.75-13.5Zm4.17-.14a9.63,9.63,0,1,0,19.24,0c0-5.17-3.81-10-9.59-10a9.66,9.66,0,0,0-9.65,9.94Z"/><path class="a" d="M100.23,13.47V29.93a7.8,7.8,0,0,0,1,4.59,4.68,4.68,0,0,0,4.06,2.11,4.49,4.49,0,0,0,4-2c1-1.49,1-3,1-4.66V13.47h4.17V29.93c0,3.49-.54,6.16-2.85,8.08a9.07,9.07,0,0,1-6.27,2.32,9.37,9.37,0,0,1-6.38-2.28C96.31,35.73,96,33.13,96,29.93V13.47Z"/><path class="a" d="M119.73,13.47h8.15c3.53,0,7.91.54,10.83,4.28a13.38,13.38,0,0,1,2.89,8.87c0,7.69-4.17,13.21-13.43,13.21h-8.44Zm4.2,22.66h4.45c6.2,0,9.05-4,9.05-9.44A10.59,10.59,0,0,0,135.29,20c-1.39-1.6-3.49-2.77-6.87-2.77h-4.49Z"/><path class="a" d="M20.3,0A20,20,0,0,0,0,20.19a20.47,20.47,0,0,0,5.53,14A20.22,20.22,0,0,0,20.4,40.33,21.66,21.66,0,0,0,32.08,36.7l0,0,0,0c.27.18.53.37.78.55,1.66,1.18,2.93,1.83,6.52,2.28,1,.16,3.27.3,3.27.3v-3.7c-3.82-.43-5.18-.53-7.57-2.07l-.06,0,.1-.12A19.32,19.32,0,0,0,40.65,20,19.83,19.83,0,0,0,34.83,5.86,20.38,20.38,0,0,0,20.3,0ZM29,34a15.25,15.25,0,0,1-8.53,2.6H20.4A15.92,15.92,0,0,1,5.85,27.39l0-.06.06,0A17.22,17.22,0,0,1,12.6,26a19,19,0,0,1,11,3.49c.13.12.79.66,1.64,1.33,1.3,1.05,3.1,2.49,3.75,3.1L29,34Zm2.75-2.69,0,0-.83-.68c-4.39-3.58-5.43-4.43-7.32-5.52a20,20,0,0,0-10.47-2.79,23.39,23.39,0,0,0-7.83,1.39l-.17.05,0-.06a14.89,14.89,0,0,1-.58-4.92A16.1,16.1,0,0,1,9.4,8,15.69,15.69,0,0,1,20.24,3.73h.06A16.1,16.1,0,0,1,31.7,8.31,16.26,16.26,0,0,1,36.44,20,15,15,0,0,1,31.73,31.31Z"/><path class="b" d="M36.24,4.44a21.68,21.68,0,0,1,3.91,5.31V.5H30.81A21.79,21.79,0,0,1,36.24,4.44Z"/></svg>
      </div>

    </a>

    <div fxFlex="40" fxLayout fxLayoutGap="20px" fxLayoutAlign="flex-start" fxHide.xs="false"  fxHide.md="false" *ngIf="!showMenu()"></div>

    <div fxFlex="40" fxLayout fxLayoutAlign="flex-end" class="main-menu">

      <ul fxLayout>

        <li *ngIf="isLoggedIn">
          <button mat-menu-item [ngClass]="!isLoggedIn ? 'login' : ''" [routerLink]="'/notifications'"
            [ngStyle]="{'margin-right':getUnreadNotifications() ? '12px' : '0' }">
            <mat-icon class="icon-menu-item" [matBadge]="getUnreadNotifications()" matBadgeColor="accent">
              notifications
            </mat-icon>
          </button>
        </li>

        <li *ngIf="isLoggedIn">
          <button mat-menu-item name="myMenu" [ngClass]="!isLoggedIn ? 'login' : 'button-circle-identity'" ngClass.xs="button-circle-identity-mobile" ngClass.sm="button-circle-identity-mobile" ngClass.md="button-circle-identity-mobile" [matMenuTriggerFor]="auth">
          <div *ngIf="!disableOfflineButton">
            <mat-icon  class="icon-menu-item" fxHide="true" fxHide.xs="false" fxHide.sm="false" fxHide.md="false" [ngStyle]="{'margin-left':getUnreadNotifications() ? '8px' : '16px' }">perm_identity
            </mat-icon>
          </div>            
          <div *ngIf="disableOfflineButton">
            <mat-icon  class="icon-menu-item" fxHide="true" fxHide.xs="false" fxHide.sm="false" fxHide.md="false" [ngStyle]="{'margin-left':getUnreadNotifications() ? '8px' : '16px' }">perm_identity
            </mat-icon>
            <div class="user-info-container'" fxHide="false" fxHide.xs="true" fxHide.sm="true" fxHide.md="true">
              <div class="user-pic">
                <span class="circle mat-elevation-z2" fxLayout="row" fxLayoutAlign="center center">
                  <span class="initialLetters">{{ getInitials() }}</span>
                </span>
              </div>
            </div>
           </div>
          </button>

          <mat-menu #auth="matMenu">
            <button mat-menu-item [routerLink]="'/perfil'">
              <mat-icon>account_circle</mat-icon>
              <span>{{'menu.profile' | translate}}</span>
            </button>
            <button mat-menu-item *ngIf="isLoggedIn && showChangeProfile()" (click)="openChangeProfile()">
              <mat-icon>people_alt</mat-icon>
              <span>{{'menu.changeProfile' | translate}}</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item name="logoutButton" (click)="logout()">
              <mat-icon>exit_to_app</mat-icon>
              <span>{{'signout' | translate}}</span>
            </button>
          </mat-menu>
        </li>

        <li *ngIf="(!isLoggedIn || showLanguageInMain()) && disableOfflineButton">
          <button mat-menu-item [ngClass]="!isLoggedIn ? 'login' : ''" [matMenuTriggerFor]="lang"
            *ngIf="showChangeLangIcon()">
            <mat-icon class="icon-menu-item" fxHide="true" fxHide.xs="false" fxHide.sm="false" fxHide.md="false">language</mat-icon>
            <mat-icon class="icon-menu-item" fxHide="false" *ngIf="showLanguageInMain()" fxHide.xs="true" fxHide.sm="true" fxHide.md="true">language</mat-icon>

            <div fxHide.lt-md *ngIf="!showLanguageInMain()">
              LANGUAGE
              <mat-icon role="img" class="mat-icon notranslate material-icons" aria-hidden="true">arrow_drop_down
              </mat-icon>
            </div>
          </button>
          <mat-menu #lang="matMenu">
            <div *ngFor="let item of langs">
              <button mat-menu-item *ngIf="(translationService.lang$ | async) != item.lang"
                (click)="changeLang(item.lang)">
                <mat-icon svgIcon="{{item.flag}}"></mat-icon>
                <span>{{item.fullLang}}</span>
              </button>
            </div>
          </mat-menu>
        </li>


      </ul>

    </div>

  </mat-toolbar-row>
</mat-toolbar>