import { GenericClass } from './genericClass';

export class Defrost {
    id: number;
    idEssay: number;

    startDate: Date;
    endDate: Date;

    idType: number;
    nameType: string;
}

export class DefrostType extends GenericClass {
}

export enum DefrostTypeEnum {
    DESESCARCHE = 1,
    APERTURA_PUERTA = 2,
    CORTE_ELECTRICO = 3
}
