/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AfterViewInit, Component, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TestQConfig } from '../../../../model/testQConfig';
import { TestQType, TestTypeEnum } from '../../../../model/testQType';
import { TestQTypeService } from '../../../../services/testQType.service';
import { Subject, takeUntil } from 'rxjs';
import { TestQElementTypeService } from '../../../../services/testQElementType.service';
import { TestQElementType } from '../../../../model/testQElementType';
import { VerificationQTypeService } from '../../../../services/verificationQType.service';
import { VerificationQType } from '../../../../model/verificationQType';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ExpectedResultTypeService } from '../../../../services/expectedResultType.service';
import { ExpectedResultType } from 'src/app/model/expectedResultType';
import { ResultQTypeService } from '../../../../services/resultQType.service';
import { ResultQType } from '../../../../model/resultQType';
import { SnackBarService } from '../../../../services/snackBar.service';
import { TestQConfigService } from '../../../../services/testQConfig.service';
import { TestQDataVerificationService } from '../../../../services/testQDataVerification.service';
import { CustomDatepickerHeaderComponent } from '../../../shared/datepicker-custom-header/datepicker-custom-header.component';
import {
	ProtocolQTestGeneralData, ProtocolQTest,
	ProtocolQTestBioindicadores,
	ProtocolQTestData,
	ProtocolQTestDataOQ,
	ProtocolQTestItem,
	ProtocolQTestProbeTraceability,
	ProtocolQTestResults,
	ProtocolStatus,
	ProtocolQTestItemVerification,
	ProtocolQTestEssay,
	ProtocolQTestEssayGroup
} from '../../../../model/protocol';

import { TestQItemService } from '../../../../services/testQItem.service';
import { TestQItem } from '../../../../model/testQItem';
import { TranslateService } from '@ngx-translate/core';

import { TestQResultsService } from '../../../../services/testQResults.service';
import { TestQResults } from '../../../../model/testQResults';

import { TestQDataOQService } from '../../../../services/testQDataOQ.service';
import { TestQDataOQ } from '../../../../model/testQDataOQ';
import { TestGeneralDataConfig } from 'src/app/model/testGeneralDataConfig';
import { TestGeneralDataConfigService } from 'src/app/services/testGeneralDataConfig.service';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { ReasonDialogComponent } from 'src/app/components/shared/reason-dialog/reason-dialog.component';

import { MatCheckboxChange } from '@angular/material/checkbox';
import * as _ from 'lodash';
import { ResizeImageComponent } from '../../../shared/resize-image/resize-image.component';
import { AttachmentQualificatesService } from '../../../../services/attachmentQualificates.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { FileInfo } from '../../../../model/attachment';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ProtocolEditTestAttachmentsComponent } from '../protocol-edit-test-attachments/protocol-q-edit-test-attachments.component';
import { EquipmentQualificates } from 'src/app/model/equipment';
import { EssayUtils } from '../../../../utils/essayUtils';
import { FieldEnum } from '../../../../utils/fieldUtils';
import { ProtocolQEditTestEssayComponent } from '../protocol-q-edit-test-essay/protocol-q-edit-test-essay.component';

export class Group {
	level = 0;
	expanded = false;
	totalCounts = 0;
}

export interface DialogQEditTest {
	idItem: number;
	testQType: TestQType;
	reason: string;
	isEdit: boolean;
	isEditTest: boolean;
	data: ProtocolQTest;
	idStatus: number;
	numberTest: number;
	equipment: EquipmentQualificates[];
	idPhase: number;
	tests: ProtocolQTest[]
	nProtocol: string;
}

@Component({
	selector: 'app-protocol-q-edit-test',
	templateUrl: './protocol-q-edit-test.component.html',
	styleUrls: ['./protocol-q-edit-test.component.scss']
})
export class ProtocolQEditTestComponent implements OnInit, AfterViewInit {

	@ViewChild(MatSort) sort: MatSort;
	@ViewChild('tableTest', { static: true }) tableTest: MatTable<any>;
	@ViewChildren('attachmentsTestComponent') attachmentsComponent: ProtocolEditTestAttachmentsComponent;
	@ViewChild('essaysTable') essaysTable: MatTable<any>;

	allData: any[];
	_allGroup: any[];
	dataSource: ProtocolQTest = new ProtocolQTest();

	displayedColumns: string[] = ['verificationTypeDesc'];
	columnsToDisplay: string[] = this.displayedColumns.slice();
	groupByColumns: string[] = [];
	essaysCols = ['essayType', 'variables', 'criteria', 'load', 'edit', 'delete'];

	testConfig: TestQConfig = new TestQConfig();
	testQTypes: TestQType[];
	testQElementTypes: TestQElementType[];
	verificationQTypes: VerificationQType[];
	expectedResultTypes: ExpectedResultType[];
	resultQTypes: ResultQType[];
	columns: any[] = [];
	expandedCar: any[] = [];
	fileName: string | null = null;
	allowedDataIQ: number[] = [TestTypeEnum.VERIFICATION_CALIBRATION_INSTRUMENTATION_CRITICAL_EQUIPMENT, TestTypeEnum.VERIFICATION_COMPONENTS_EQUIPMENT_DIMENSIONS_MATERIALS, 
		TestTypeEnum.VERIFICATION_LOCATION_ASSOCIATED_SERVICES, TestTypeEnum.MATCHING_INSTALLATION_PLANS,  TestTypeEnum.EQUIPMENT_DOCUMENTATION_VERIFICATION,  TestTypeEnum.EQUIPMENT_VERIFICATION, 
		TestTypeEnum.VERIFICATION_APPROVAL_DESIGN_QUALIFICATION, TestTypeEnum.VERIFICATION_APPROVAL_PROTOCOL_QUALIFICATION];
	allowedDataOQ: number[] = [TestTypeEnum.VERIFICATION_PERFORMANCE_TESTING_INSTALLATION_QUALIFICATION, TestTypeEnum.VERIFICATION_CONTROL_PANEL, 
			TestTypeEnum.VERIFICATION_OF_EQUIPMENT_ALARMS_SAFEGUARDS, TestTypeEnum.VERIFICATION_OF_INTERLOCKS,  TestTypeEnum.TEST_TIGHTNESS_TEST_VACUUM_TEST_LEAK_TEST,  TestTypeEnum.LEAKAGE_TEST, 
			TestTypeEnum.TEST_BOWIE_DICK, TestTypeEnum.RELATIVE_MOISTURE_TEMPERATURE_DISTRIBUTION_TEST_CO2_IN_VACUUM];
	
	currentUser: User;
	customDatepickerHeader = CustomDatepickerHeaderComponent;

	quillConfig = {
		toolbar: [
			['bold', 'italic', 'underline'],
			[{ list: 'ordered' }, { list: 'bullet' }]
		]
	};

	isTacometer: boolean;
	tacometer: string;

	private destroy$ = new Subject<void>();

	constructor(private testQTypeService: TestQTypeService,
		private testQElementTypeService: TestQElementTypeService,
		private verificationQTypeService: VerificationQTypeService,
		private expectedResultTypeService: ExpectedResultTypeService,
		private resultQTypeService: ResultQTypeService,
		private snackBarService: SnackBarService,
		private testQConfigService: TestQConfigService,
		private testQResultsService: TestQResultsService,
		private testQDataVerificationService: TestQDataVerificationService,
		private testGeneralDataConfigService: TestGeneralDataConfigService,
		private testQDataOQService: TestQDataOQService,
		private testQItemService: TestQItemService,
		private dialogRef: MatDialogRef<ProtocolQEditTestComponent>,
		private translate: TranslateService,
		private userService: UserService,
		private attachmentService: AttachmentQualificatesService,
		private spinnerService: SpinnerService,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: DialogQEditTest) {
		this.currentUser = this.userService.currentProfile;
	}

	ngAfterViewInit(): void {
		if (this.attachmentsComponent instanceof QueryList) {
			this.attachmentsComponent = this.attachmentsComponent.first as ProtocolEditTestAttachmentsComponent;
		}

		if (this.attachmentsComponent) {
			this.attachmentsComponent.setIdProtocol(this.data.data.idProtocol);
			this.attachmentsComponent.setIdStatus(this.data.idStatus);
			this.attachmentsComponent.setIdTest(this.data.data.id);
		}
	}

	ngOnInit(): void {
		if (!this.data.data) {
			this.data.data = new ProtocolQTest();
		} else {
			this.dataSource = this.data.data;
		}
		this.data.data.testDescription = this.translate.instant('testQualification.' + this.data.testQType.translation) as string;

		this.testQTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((result: TestQType[]) => {
			this.testQTypes = result;
		});

		this.testQElementTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((result: TestQElementType[]) => {
			this.testQElementTypes = result;
		});

		this.verificationQTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((result: VerificationQType[]) => {
			this.verificationQTypes = result;
		});

		this.expectedResultTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((result: ExpectedResultType[]) => {
			this.expectedResultTypes = result;
		});

		this.resultQTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((result: ResultQType[]) => {
			this.resultQTypes = result;
		});

		if (this.data.testQType) {
			this.testQConfigService.findByIdTest(this.data.testQType.id).pipe(takeUntil(this.destroy$)).subscribe((result: TestQConfig) => {
				this.testConfig = result;
			});

			if (!this.data.data.protocolQTestItem) {
				// eslint-disable-next-line max-len
				this.testQItemService.findAllByIdTest(this.data.testQType.id).pipe(takeUntil(this.destroy$)).subscribe((result: TestQItem[]) => {
					result?.forEach(r => {
						const protocolQTestItem = new ProtocolQTestItem();
						protocolQTestItem.description = r.description;
						protocolQTestItem.idType = r.idType;
						protocolQTestItem.protocolQTestItemVerification = [];
						r.testQItemVerifications?.forEach(rt => {
							const protocolQTestItemVerification = new ProtocolQTestItemVerification();
							if (this.data.testQType.id === TestTypeEnum.VERIFICATION_APPROVAL_PROTOCOL_QUALIFICATION) {
								if (rt.verification === 'PRIQOQPQQXXXX') {
									protocolQTestItemVerification.verification = this.data.nProtocol;
								} else {
									protocolQTestItemVerification.verification = rt.verification;
								}
							} else {
								protocolQTestItemVerification.verification = rt.verification;
							}
							rt.testQDataMultiple.forEach(pte => {
								const protocolQTestData = new ProtocolQTestData();
								protocolQTestData.expectedResultRhDesc = pte.expectedResultRhDesc;
								if (this.data.testQType.id === TestTypeEnum.EQUIPMENT_VERIFICATION) {
									if (pte.expectedResultRhDesc) {
										protocolQTestData.expectedResultDesc = this.getEquipmentByField(pte.expectedResultRhDesc);
									}
								} else {
									protocolQTestData.expectedResultDesc = pte.expectedResultDesc;
								}
								protocolQTestData.resultDesc = pte.resultDesc;
								if (!protocolQTestItemVerification.protocolQTestDataMultiple) {
									protocolQTestItemVerification.protocolQTestDataMultiple = [];
								}
								protocolQTestItemVerification.protocolQTestDataMultiple.push(protocolQTestData);
							});

							protocolQTestItem.protocolQTestItemVerification.push(protocolQTestItemVerification);
						});

						if (!this.dataSource?.protocolQTestItem) {
							this.dataSource.protocolQTestItem = [];
						}
						this.dataSource?.protocolQTestItem.push(protocolQTestItem);
					})
				});

			} else {
				this.dataSource.protocolQTestItem = this.data.data.protocolQTestItem;
			}

			if (this.data.tests) {
				if (this.data.data.idTestType === TestTypeEnum.VERIFICATION_PERFORMANCE_TEST_QUALIFICATION_PERFORMANCE_TESTING) {
					const dataIQ = this.data.tests.filter(e => this.allowedDataOQ.includes(e.idTestType) && this.isCompletedTest(e.idTestType));
					if (dataIQ){
						let nPrueba = 1;
						dataIQ.sort((a, b) => {
							return a.id - b.id;
						}).forEach(r => {
							const protocolQTestOQ = new ProtocolQTestDataOQ();
							protocolQTestOQ.descriptionTest = r.testDescription;
							protocolQTestOQ.test = this.translate.instant('protocolEdit.test.data.iq.test', {nPrueba});
							protocolQTestOQ.resultDesc = ['according','not_according', 'not_applicable'];

							if (!this.dataSource.protocolQTestVerification) {
								this.dataSource.protocolQTestVerification = [];
							}
							this.dataSource?.protocolQTestVerification.push(protocolQTestOQ);
							nPrueba += 1;
						});
					}
				} else if (this.data.data.idTestType === TestTypeEnum.VERIFICATION_PERFORMANCE_TESTING_INSTALLATION_QUALIFICATION) {
					const dataIQ = this.data.tests.filter(e => this.allowedDataIQ.includes(e.idTestType) && this.isCompletedTest(e.idTestType));
					if (dataIQ){
						let nPrueba = 1;
						dataIQ.sort((a, b) => {
							return a.id - b.id;
						}).forEach(r => {
							const protocolQTestOQ = new ProtocolQTestDataOQ();
							protocolQTestOQ.descriptionTest = r.testDescription;
							protocolQTestOQ.test = this.translate.instant('protocolEdit.test.data.iq.test', {nPrueba});
							protocolQTestOQ.resultDesc = ['according','not_according', 'not_applicable'];

							if (!this.dataSource.protocolQTestVerification) {
								this.dataSource.protocolQTestVerification = [];
							}
							this.dataSource?.protocolQTestVerification.push(protocolQTestOQ);
							nPrueba += 1;
						});
					}
				}
			}

			if (!this.data.data.protocolQTestGeneralData) {
				this.testGeneralDataConfigService.getTestGeneralDataConfigById(this.data.testQType.id).pipe(takeUntil(this.destroy$)).subscribe((result: TestGeneralDataConfig[]) => {
					result?.forEach(r => {
						const protocolGeneral = new ProtocolQTestGeneralData();
						protocolGeneral.methodology = r.methodology;
						protocolGeneral.objective = r.objective;
						protocolGeneral.criteria = r.criteria;

						if (!this.dataSource.protocolQTestGeneralData) {
							this.dataSource.protocolQTestGeneralData = [];
						}
						this.dataSource?.protocolQTestGeneralData.push(protocolGeneral)
					});

				})
			} else {
				this.dataSource.protocolQTestGeneralData = this.data.data.protocolQTestGeneralData;
			}

			if (this.data.data.protocolQTestProbeTraceability) {
				this.dataSource.protocolQTestProbeTraceability = this.data.data.protocolQTestProbeTraceability;
			}

			if (this.data.data.protocolQTestBioindicadores) {
				this.dataSource.protocolQTestBioindicadores = this.data.data.protocolQTestBioindicadores;
			}

			if (!this.data.data.protocolQTestResults) {
				// eslint-disable-next-line max-len
				this.testQResultsService.findByIdTest(this.data.testQType.id).pipe(takeUntil(this.destroy$)).subscribe((result: TestQResults[]) => {
					result.forEach(rt => {
						const protocolQTestResult = new ProtocolQTestResults();
						protocolQTestResult.verification = rt.verification;
						protocolQTestResult.resultExpected = rt.resultExpected;
						protocolQTestResult.result = rt.result;
						if (!this.dataSource?.protocolQTestResults) {
							this.dataSource.protocolQTestResults = [];
						}
						this.dataSource?.protocolQTestResults.push(protocolQTestResult);
					})
				});
			} else {
				this.dataSource.protocolQTestResults = this.data.data.protocolQTestResults;

			}
		}

		this.onChangeSelectionOption();
	}

	onChangeSelectionOption(): void {
		this.displayedColumns = [];
		this.displayedColumns.push('verificationTypeDesc');
		if (this.testConfig.expectedResult) {
			this.displayedColumns.push('expectedResult')
		}

		if (this.testConfig.obtainedResult) {
			this.displayedColumns.push('obtainedResult')
		}

		if (this.testConfig.testResult) {
			this.displayedColumns.push('testResult')
		}

		this.columnsToDisplay = this.displayedColumns.slice();
	}

	deleteRowItemVerification(indexItem: number, itemData: number): void {
		if (this.dataSource.protocolQTestItem[indexItem]) {
			this.dataSource.protocolQTestItem[indexItem].protocolQTestItemVerification.splice(itemData, 1)
		}
	}

	deleteRowData(indexItem: number, itemValue: number, itemData: number): void {
		if (this.dataSource.protocolQTestItem[indexItem]) {
			this.dataSource.protocolQTestItem[indexItem].protocolQTestItemVerification[itemValue].protocolQTestDataMultiple.splice(itemData, 1)
		}
	}


	addRowData(indexItem: number, itemValue: number): void {
		if (this.dataSource.protocolQTestItem[indexItem]) {
			if (this.dataSource.protocolQTestItem[indexItem].protocolQTestItemVerification[itemValue]) {
				if (!this.dataSource.protocolQTestItem[indexItem].protocolQTestItemVerification[itemValue].protocolQTestDataMultiple) {
					this.dataSource.protocolQTestItem[indexItem].protocolQTestItemVerification[itemValue].protocolQTestDataMultiple = [];
				}

				let dataMultiple = new ProtocolQTestData();
				this.dataSource.protocolQTestItem[indexItem].protocolQTestItemVerification[itemValue].protocolQTestDataMultiple.push(dataMultiple);
			}
		}
	}

	deleteRowItem(indexItem: number): void {
		this.dataSource.protocolQTestItem.splice(indexItem, 1)
	}

	onOkClick(): void {
		const errs = [];

		if (this.testConfig.expectedResult) {
			if (!this.dataSource.protocolQTestItem) {
				errs.push(this.translate.instant('protocolTest.probe.add.error'));
			} else {
				// eslint-disable-next-line @typescript-eslint/prefer-for-of, @typescript-eslint/no-unsafe-member-access
				for (let index = 0; index < this.dataSource.protocolQTestItem.length; index++) {
					if (!this.dataSource.protocolQTestItem[index] && this.testConfig.item) {
						errs.push(this.translate.instant('protocolTest.verificationType.item.description.error'));
					} else {
						if (!this.dataSource.protocolQTestItem[index].description) {
							errs.push(this.translate.instant('protocolTest.verificationType.item.error'));
						}
						// eslint-disable-next-line @typescript-eslint/prefer-for-of, max-len
						for (let indexItemValue = 0; indexItemValue < this.dataSource.protocolQTestItem[index].protocolQTestItemVerification.length; indexItemValue++) {
							if (!this.dataSource.protocolQTestItem[index].protocolQTestItemVerification[indexItemValue]) {
								errs.push(this.translate.instant('protocolTest.verificationType.add.error'));
							} else {
								if (!this.dataSource.protocolQTestItem[index].protocolQTestItemVerification[indexItemValue].verification) {
									errs.push(this.translate.instant('protocolTest.verificationType.descripction.error'));
								}

								for (let indexData = 0; indexData < this.dataSource.protocolQTestItem[index].protocolQTestItemVerification[indexItemValue].protocolQTestDataMultiple.length; indexData++) {
									if (!this.dataSource.protocolQTestItem[index].protocolQTestItemVerification[indexItemValue].protocolQTestDataMultiple) {
										errs.push(this.translate.instant('protocolTest.verificationType.add.error'));
									} else {
										
										// eslint-disable-next-line max-len
										if (!this.dataSource.protocolQTestItem[index].protocolQTestItemVerification[indexItemValue].protocolQTestDataMultiple[indexData].resultDesc || this.dataSource.protocolQTestItem[index].protocolQTestItemVerification[indexItemValue].protocolQTestDataMultiple[indexData].resultDesc?.length === 0) {
											errs.push(this.translate.instant('protocolTest.verificationType.resultDesc.error'));
										}
									}
								}
							}
						}
					}
				}
			}
		}

		if (this.testConfig.probeTraceability) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (this.dataSource.protocolQTestEssayGroup?.length > 0) {
				for (let index = 0; index < this.dataSource.protocolQTestEssayGroup.length; index++) {
					const element = this.dataSource.protocolQTestEssayGroup[index];

					if (element.essayValues) {
						for (let index = 0; index < element.essayValues?.length; index++) {
							const elementEssay = element.essayValues[index];
	
							if (!elementEssay.sensors || elementEssay.sensors?.length === 0) {
								errs.push(this.translate.instant('protocolTest.probe.add.error'));
							} else {
								// eslint-disable-next-line @typescript-eslint/prefer-for-of, @typescript-eslint/no-unsafe-member-access
								for (let index = 0; index < elementEssay.sensors.length; index++) {
									if (!elementEssay.sensors[index].location) {
										errs.push(this.translate.instant('protocolTest.probe.descripction.error'));
									}
								}
							}
							
						}
					}
					
				}
			} else {
				errs.push(this.translate.instant('protocolTest.essay.add.error.empty'));
			}
		}

		if (this.testConfig.testResult) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (!this.dataSource.protocolQTestResults || this.dataSource.protocolQTestResults.length === 0) {
				errs.push(this.translate.instant('protocolTest.probe.add.error'));
			} else {
				// eslint-disable-next-line @typescript-eslint/prefer-for-of, @typescript-eslint/no-unsafe-member-access
				for (let index = 0; index < this.dataSource.protocolQTestResults.length; index++) {
					if (!this.dataSource.protocolQTestResults[index].resultExpected) {
						errs.push(this.translate.instant('protocolTest.test.result.resultExpected.error'));
					} else {
						const valueTestData = this.dataSource.protocolQTestResults[index].resultExpected?.filter(e => e === '');
						if (valueTestData != null && valueTestData?.length > 0) {
							errs.push(this.translate.instant('protocolTest.verificationType.expectedResultDesc.error'));
						}
					}
					if (!this.dataSource.protocolQTestResults[index].result || this.dataSource.protocolQTestResults[index].result?.length === 0) {
						errs.push(this.translate.instant('protocolTest.test.result.result.error'));
					}
					if (!this.dataSource.protocolQTestResults[index].verification) {
						errs.push(this.translate.instant('protocolTest.test.result.verification.error'));
					}
				}
			}
		}

		if (this.testConfig.testVerification) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (!this.dataSource.protocolQTestVerification || this.dataSource.protocolQTestVerification.length === 0) {
				errs.push(this.translate.instant('protocolTest.testVerification.add.error'));
			} else {
				// eslint-disable-next-line @typescript-eslint/prefer-for-of, @typescript-eslint/no-unsafe-member-access
				for (let index = 0; index < this.dataSource.protocolQTestVerification.length; index++) {
					if (!this.dataSource.protocolQTestVerification[index].test) {
						errs.push(this.translate.instant('protocolTest.testVerification.test.error'));
					}
					if (!this.dataSource.protocolQTestVerification[index].descriptionTest) {
						errs.push(this.translate.instant('protocolTest.testVerification.description.test.error'));
					}
					if (!this.dataSource.protocolQTestVerification[index].resultDesc || this.dataSource.protocolQTestVerification[index].resultDesc?.length === 0) {
						errs.push(this.translate.instant('protocolTest.testVerification.result.erro'));
					}
				}

			}
		}

		if (errs.length === 0) {
			if (!this.dataSource.regUser) {
				this.dataSource.regUser = this.currentUser.username;
				this.dataSource.regDate = new Date();
			}

			this.dataSource.modUser = this.currentUser.username;
			this.dataSource.modDate = new Date();

			if (this.data.idStatus === ProtocolStatus.FIRMADO) {

				this.requestReason((reason) => {
					this.dataSource.reason = reason;

					this.dataSource.reason = this.data.reason;
					this.dialogRef.close(this.dataSource);
				});
			} else {
				this.dialogRef.close(this.dataSource);
			}

		} else {
			const error = errs.join('\n');
			this.snackBarService.sendError(error);
		}
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	newItemVeritication(): void {
		const protocolQTestItem = new ProtocolQTestItem();
		protocolQTestItem.idType = this.data.testQType.id;
		this.dataSource.protocolQTestItem.push(protocolQTestItem);
	}

	newItemVerification(index: number): void {

		if (!this.dataSource.protocolQTestItem[index].protocolQTestItemVerification) {
			this.dataSource.protocolQTestItem[index].protocolQTestItemVerification = [];
		}

		const protocolQTestData = new ProtocolQTestItemVerification();
		this.dataSource.protocolQTestItem[index].protocolQTestItemVerification.push(protocolQTestData);

	}

	newDataVerification(indexItem: number, indexVerification: number): void {

		if (!this.dataSource.protocolQTestItem[indexItem].protocolQTestItemVerification[indexVerification].protocolQTestDataMultiple) {
			this.dataSource.protocolQTestItem[indexItem].protocolQTestItemVerification[indexVerification].protocolQTestDataMultiple = [];
		}

		const protocolQTestData = new ProtocolQTestData();
		protocolQTestData.resultDesc = [];
		this.dataSource.protocolQTestItem[indexItem].protocolQTestItemVerification[indexVerification].protocolQTestDataMultiple.push(protocolQTestData);

	}

	newProbeTraceability(): void {

		if (!this.dataSource.protocolQTestProbeTraceability) {
			this.dataSource.protocolQTestProbeTraceability = [];
		}
		const probe = new ProtocolQTestProbeTraceability();
		this.dataSource.protocolQTestProbeTraceability.push(probe);
	}


	deleteProbeTraceability(itemData: number): void {
		this.dataSource.protocolQTestProbeTraceability.splice(itemData, 1)
	}

	newDataResult(): void {
		const protocolResult = new ProtocolQTestResults();
		if (!this.dataSource?.protocolQTestResults) {
			this.dataSource.protocolQTestResults = [];
		}
		protocolResult.resultExpected = [];
		protocolResult.resultExpected.push('');
		this.dataSource.protocolQTestResults.push(protocolResult);
	}

	deleteRowDataResult(itemData: number): void {
		this.dataSource.protocolQTestResults.splice(itemData, 1)
	}

	newBioTraceability(): void {
		if (!this.dataSource.protocolQTestBioindicadores) {
			this.dataSource.protocolQTestBioindicadores = [];
		}
		const bio = new ProtocolQTestBioindicadores();
		bio.protocolTestId = this.data.testQType.id;
		this.dataSource.protocolQTestBioindicadores.push(bio);
	}

	deleteBioTraceability(itemData: number): void {
		this.dataSource.protocolQTestBioindicadores.splice(itemData, 1)
	}

	newTestVerification(): void {
		if (!this.dataSource.protocolQTestVerification) {
			this.dataSource.protocolQTestVerification = [];
		}
		const test = new ProtocolQTestDataOQ();
		test.idProtocol = this.data.testQType.id;
		this.dataSource.protocolQTestVerification.push(test);

	}
	deleteTestVerification(itemData: number): void {
		this.dataSource.protocolQTestVerification.splice(itemData, 1)
	}

	onChangeTacometerCheck(event: MatCheckboxChange, indexProbe) {
		if (event.checked) {
			for (let index = 0; index < this.dataSource.protocolQTestProbeTraceability.length; index++) {
				this.dataSource.protocolQTestProbeTraceability[index].tacometer = false;
			}
			this.dataSource.protocolQTestProbeTraceability[indexProbe].tacometer = true;
		} else {
			this.dataSource.protocolQTestProbeTraceability[indexProbe].tacometer = false;
		}
	}

	private requestReason(callback: (reason: string) => void) {
		const dialogRef = this.dialog.open(ReasonDialogComponent, {
			minWidth: '40%',
			maxHeight: '95vh',
			data: {}
		});

		dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
			if (result != null) {
				callback(result);
			}
		});
	}

	onImageSelected(event: Event) {
		const input = event.target as HTMLInputElement;
		if (!input.files?.length) {
			return;
		}

		const file = input.files[0];
		this.fileName = file.name;

		const reader = new FileReader();

		reader.onload = () => {
			const base64String = reader.result as string;
			// Remover la parte "data:image/jpeg;base64,"
			const base64WithoutPrefix = base64String.split(',')[1];
			this.dataSource.imageTest = base64WithoutPrefix;
			console.log('Image in base64 without prefix:', this.dataSource.imageTest);
		};

		reader.readAsDataURL(file);
	}

	openVisualizerImage(verificationData: ProtocolQTestData): void {
		if (verificationData.idImage) {
			this.attachmentService.getImageToVerification(verificationData.idVerification, verificationData.id).pipe(takeUntil(this.destroy$)).subscribe((result: FileInfo) => {
				let src: string;
				if (result) {
					src = result.contentString;
					this.dialogVisualizerImage(src, verificationData);
				} else {
					this.dialogVisualizerImage(null, verificationData);
				}
			});
		} else {
			this.dialogVisualizerImage(null, verificationData);
		}
	}

	dialogVisualizerImage(src: string, verificationData: ProtocolQTestData): void {
		const dialogRef = this.dialog.open(ResizeImageComponent, {
			minWidth: '50%',
			maxHeight: '95vh',
			data: {
				src: src
			}
		});

		dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
			if (result) {
				this.spinnerService.show();
				this.attachmentService.uploadImageToVerification(verificationData.idVerification, verificationData.id, result.file, result.width, result.height).pipe(takeUntil(this.destroy$)).subscribe((result: number) => {
					this.spinnerService.hide();
					verificationData.idImage = result;
					this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.attachments.dialog.form.upload.ok') as string);
				}, () => {
					this.spinnerService.hide();
					this.snackBarService.sendError(this.translate.instant('protocolEdit.attachments.dialog.form.upload.error.generic') as string);
				});
			}
		});
	}

	drop(event: CdkDragDrop<any[]>, indexItem: number) {
		if (event.previousIndex !== event.currentIndex) {
			const item = this.dataSource.protocolQTestItem[indexItem];
			moveItemInArray(item.protocolQTestItemVerification, event.previousIndex, event.currentIndex);

			item.protocolQTestItemVerification.forEach((element, index) => {
				element.order = index + 1;
			});
		}
	}

	showLastTacometer(index: number) {
		return this.dataSource?.protocolQTestProbeTraceability?.length === index + 1;
	}

	getEquipmentByField(field: string): string {
		const fieldMap = {
			'Equipo': 'equipment',
			'Modelo': 'model',
			'Marca': 'maker',
			'Número de serie': 'serialNumber',
			'Identificativo': 'internalId',
			'Ubicación': 'location',
			'Modelo rotor': 'rotorModel',
			'Número de serie rotor': 'rotorSerial'
		};

		const fieldKey = fieldMap[field];
		if (!fieldKey) return null;

		const equipment = this.data.equipment.find(e => e[fieldKey]);
		return equipment ? equipment[fieldKey] : null;
	}

	newEssayOpenDialog(): void {
		const dialogRef = this.dialog.open(ProtocolQEditTestEssayComponent, {
			minWidth: '90vw',
			height: '95vh',
			data: {
				essay: new ProtocolQTestEssayGroup(),
				isEdit: false,
				idProtocolTest: this.dataSource.id,
				idTestType: this.data.testQType.id,
				disabled: false
			}
		});

		dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ProtocolQTestEssayGroup) => {
			if (result != null) {
				if (!this.dataSource.protocolQTestEssayGroup) {
					this.dataSource.protocolQTestEssayGroup = [];
				}
				this.dataSource.protocolQTestEssayGroup.push(result);

				if (this.essaysTable) {
					this.essaysTable.renderRows();
				}
			}
		});
	}

	editEssayOpenDialog(idx: number): void {

		const essay = this.dataSource.protocolQTestEssayGroup[idx];

		const dialogRef = this.dialog.open(ProtocolQEditTestEssayComponent, {
			minWidth: '90vw',
			height: '95vh',
			data: {
				essay: _.cloneDeep(essay),
				isEdit: true,
				idProtocolTest: this.dataSource.id,
				idTestType: this.data.testQType.id,
				disabled: false
			}
		});

		dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ProtocolQTestEssayGroup) => {
			if (result != null) {
				this.dataSource.protocolQTestEssayGroup[idx] = result;

				if (this.essaysTable) {
					this.essaysTable.renderRows();
				}
			}
		});
	}

	getVariablesFromEssay(essay: ProtocolQTestEssayGroup): string {
		return essay.essayValues.map(i => this.translate.instant('variable.' + i.translationVariable) as string).join(', ');
	}

	getCriteriaFromEssay(essay: ProtocolQTestEssayGroup): string {
		if (!essay || !essay.essayValues) {
			return null;
		}

		return essay.essayValues.map(i => EssayUtils.getCriteriasToShowThermal(i, this.translate)).filter(f => f != null).join('; ');
	}

	getLoadFromEssay(essay: ProtocolQTestEssayGroup): string {
		if (!essay || !essay.essayValues) {
			return null;
		}

		const fields = [];
		essay.essayValues.forEach(i => i.fields?.filter(field => field.idField === FieldEnum.LOAD).forEach(field => fields.push(field.value)));

		return fields.join(', ');
	}

	deleteEssayRow(index: number): void {
		if (index > -1) {
			this.dataSource.protocolQTestEssayGroup.splice(index, 1);

			if (this.essaysTable) {
				this.essaysTable.renderRows();
			}
		}
	}

	showTestEssay(): boolean {
		const allowedTest = [TestTypeEnum.TEST_BOWIE_DICK, TestTypeEnum.TEST_TEMPERATURE_DISTRIBUTION_MOISTURE_CO2_WITH_LOAD_OPENING_DOORS, TestTypeEnum.TEST_TIGHTNESS_TEST_VACUUM_TEST_LEAK_TEST, TestTypeEnum.LEAKAGE_TEST, 
			TestTypeEnum.TEMPERATURE_PENETRATION_TEST_UNDER_LOAD_TEMPERATURE, TestTypeEnum.RELATIVE_MOISTURE_TEMPERATURE_DISTRIBUTION_TEST_CO2_IN_VACUUM];

		if (allowedTest.includes(this.dataSource.idTestType)) {
			return true;
		} else {
			return false;
		}
	}

	isCompletedTest(idTestType: number): boolean {
		const notValidationExpectedResult = [TestTypeEnum.TEST_BOWIE_DICK];
		const notValidationSensor = [TestTypeEnum.TEST_BOWIE_DICK, TestTypeEnum.TEST_TEMPERATURE_DISTRIBUTION_MOISTURE_CO2_WITH_LOAD_OPENING_DOORS, TestTypeEnum.TEST_TIGHTNESS_TEST_VACUUM_TEST_LEAK_TEST, TestTypeEnum.LEAKAGE_TEST, TestTypeEnum.RELATIVE_MOISTURE_TEMPERATURE_DISTRIBUTION_TEST_CO2_IN_VACUUM, TestTypeEnum.TEMPERATURE_PENETRATION_TEST_UNDER_LOAD_TEMPERATURE];
	
		let res = true;
		const testResult = this.data.tests?.find(e => e.idTestType === idTestType);
	 
		if (testResult) {
	
		  if (testResult.protocolQTestItem?.length > 0) {
			for (let index = 0; index < testResult.protocolQTestItem?.length; index++) {
			  const element = testResult.protocolQTestItem[index];
	  
			  if (element.protocolQTestItemVerification?.length > 0) {
				for (let indextiv= 0; indextiv < element.protocolQTestItemVerification?.length; indextiv++) {
				  const elementtiv = element.protocolQTestItemVerification[indextiv];
		
				  if (elementtiv.protocolQTestDataMultiple?.length > 0) {
					for (let indextdm= 0; indextdm < elementtiv.protocolQTestDataMultiple?.length; indextdm++) {
					  const element = elementtiv.protocolQTestDataMultiple[indextdm];
					   if (!notValidationExpectedResult.includes(idTestType)) {
						if (!element.expectedResultDesc) {
						  res = false;
						 }
					   }
					 }
				  } else {
					res = false;
				  }
				}
			  } else {
				res = false;
			  }
			} 
		  } else {
			res = false;
		  }
	
		  if (notValidationSensor.includes(idTestType)) {
			if (!testResult.protocolQTestEssayGroup || testResult.protocolQTestEssayGroup?.length === 0) {
			  res = false;
			} 
		  }
		  
		  if (!testResult.protocolQTestGeneralData) {
			res = false;
		  }
		} else {
		  res = false;
		}
	
		return res;
	  }
}