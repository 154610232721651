import * as _ from 'lodash-es';

import { ActivatedRoute, Router } from '@angular/router';
import { Client, ClientFilter } from 'src/app/model/client';
import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
  IsothermalCharacterization,
  IsothermalCharacterizationCalibration,
  IsothermalCharacterizationCalibrationValue,
  IsothermalCharacterizationStatus,
  IsothermalCharacterizationVariable
} from 'src/app/model/isothermalCharacterization';
import { Variable, VariableTypeEnum, VariableUnit } from 'src/app/model/variable';

import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { CheckWarnsErrorsComponent } from '../../shared/check-warns-errors/check-warns-errors.component';
import { ClientService } from 'src/app/services/client.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { Constants } from 'src/app/utils/constants';
import { EditDocumentCodeEditComponent } from '../../shared/edit-document-code-edit/edit-document-code-edit.component';
import { EditReferenceDocsComponent } from '../../shared/edit-reference-docs/edit-reference-docs.component';
import { Equipment } from 'src/app/model/equipment';
import { FieldUtils } from 'src/app/utils/fieldUtils';
import { GenericWarnError } from 'src/app/model/genericClass';
import { IsothermalCharacterizationEditAuditComponent } from './isothermal-characterization-edit-audit.component';
import { IsothermalCharacterizationEditConfirmSaveComponent } from './isothermal-characterization-edit-dialog-confirmSave.component';
import { IsothermalCharacterizationEditEquipmentDialogComponent } from './isothermal-characterization-edit-dialog-equipment.component';
import { IsothermalCharacterizationEditEssayComponent } from './isothermal-characterization-edit-essay.component';
import { IsothermalCharacterizationEditGenerateReportComponent } from './isothermal-characterization-edit-generate-report.component';
import { IsothermalCharacterizationEditSignReportComponent } from './isothermal-characterization-edit-sign-report.component';
import {
  IsothermalCharacterizationEditValidateIsothermalCharacterizationComponent
} from './isothermal-characterization-edit-validate-isothermal-characterization.component';
import { IsothermalCharacterizationService } from 'src/app/services/isothermalCharacterization.service';
import { IsothermalCharacterizationUtils } from 'src/app/utils/isothermalCharacterizationUtils';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatTable } from '@angular/material/table';
import { OnlineService } from 'src/app/services/online.service';
import { ReasonDialogComponent } from '../../shared/reason-dialog/reason-dialog.component';
import { ReferenceDocument } from 'src/app/model/referenceDocument';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StringUtils } from 'src/app/utils/stringUtils';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { VariableTypeService } from 'src/app/services/variableType.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-isothermal-characterization-edit',
  templateUrl: './isothermal-characterization-edit.component.html'
})
export class IsothermalCharacterizationEditComponent implements OnInit, OnDestroy {

  @ViewChildren('essaysComponent') essaysComponent: IsothermalCharacterizationEditEssayComponent;

  @ViewChild('equipmentTable', { static: true }) equipmentTable: MatTable<any>;
  @ViewChild(IsothermalCharacterizationEditAuditComponent) audit: IsothermalCharacterizationEditAuditComponent;
  @ViewChild(IsothermalCharacterizationEditEssayComponent) essayComponent: IsothermalCharacterizationEditEssayComponent;
  @ViewChild('refDocTable', { static: true }) refDocTable: MatTable<any>;

  currentUser: User;

  isothermalCharacterization: IsothermalCharacterization;

  clientFilter: ClientFilter = new ClientFilter();
  clients: Client[];
  clientsFiltered: Client[];

  variables: Variable[];
  public checked = new Map<number, boolean>();

  disableOfflineButton = false;
  displayedColsRefDoc = ['code', 'name', 'edit', 'delete'];
  equipmentCols = ['equipment', 'maker', 'model', 'serialNum', 'internalId', 'location', 'edit', 'delete'];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private isothermalCharacterizationService: IsothermalCharacterizationService,
    private clientService: ClientService,
    private variableTypeService: VariableTypeService,
    private userService: UserService,
    private onlineService: OnlineService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    this.spinnerService.show();

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const id = params[Constants.FIELD_ID] as number;

      if (id == null) {
        this.cancel();
      }

      this.isothermalCharacterization = new IsothermalCharacterization();
      if (+id === 0) {
        this.isothermalCharacterization.equipments = [];
        this.isothermalCharacterization.procediments = [];
        this.isothermalCharacterization.referenceDocs = [];
        this.isothermalCharacterization.variables = [];
        this.spinnerService.hide();
      } else {
        this.isothermalCharacterizationService.findOne(id).pipe(takeUntil(this.destroy$))
          .subscribe((result: IsothermalCharacterization) => {
            if (result !== null && result !== undefined) {
              this.isothermalCharacterization = result;

              const variableUnits = this.essayComponent.variableUnits;

              this.isothermalCharacterization.variables = this.isothermalCharacterization.variables.map(v => {
                v.calibration = v.calibrations[0];
                const variable = IsothermalCharacterizationUtils.objectToVariable(v);

                variable.calibration?.recalculateValues();

                const idVariable = variable.idVariable;

                this.checked.set(idVariable, true);

                this.variableTypeService.findUnits(idVariable).pipe(takeUntil(this.destroy$)).subscribe((res: VariableUnit[]) => {
                  variableUnits.set(idVariable, res);

                  if (!ArrayUtils.isEmpty(res)) {
                    if (variable.idVariable === idVariable && variable.idUnit == null) {
                      variable.idUnit = res[0].id;
                      variable.unitName = res[0].unit;
                    }
                  }

                });

                return variable;
              });

              this.essayComponent.variableUnits = variableUnits;

              this.essayComponent.variables = this.isothermalCharacterization.variables;
            } else {
              this.isothermalCharacterization = null;
            }
          }, () => {
            this.isothermalCharacterization = null;
          }, () => {
            if (this.isothermalCharacterization == null) {
              this.cancel();
            }

            this.spinnerService.hide();
          });
      }

    });

  }

  ngOnInit(): void {
    this.onlineService.online$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.disableOfflineButton = !res;
    });

    const allowedVars = [VariableTypeEnum.TEMPERATURE, VariableTypeEnum.HUMIDITY, VariableTypeEnum.CO2];

    this.variableTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: Variable[]) => {
      this.variables = res.filter(v => allowedVars.includes(v.id));
    });

    this.disableOfflineButton = !this.onlineService.latestOnline;

    this.currentUser = this.userService.currentProfile;

    if (this.isothermalCharacterization.id == null) {
      this.clients = [];

      this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe(data => {
        if (data && data.content) {
          this.clients = data.content as Client[];
        } else if (data) {
          this.clients = data as Client[];
        } else {
          this.clients = [];
        }
        this.clientsFiltered = this.clients.slice();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  reloadPage(idIsothermalCharacterization = this.isothermalCharacterization.id): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    let url: string;
    if (this.router.url.includes('calibrates')){
      url = `calibrates/isothermalCharacterization?id=${idIsothermalCharacterization}`
    } else if (this.router.url.includes('thermal')) {
      url = `thermal/isothermalCharacterization?id=${idIsothermalCharacterization}`
    }
    void this.router.navigateByUrl(url);
  }

  reloadPageEmit(event): void {
    this.reloadPage(event.idIsothermalCharacterization as number);
  }

  isVariableChecked(item: Variable): boolean {
    const checkedByArray = this.checked.get(item.id);
    return checkedByArray || item.required;
  }

  disableChangeVariable(): boolean {
    const allowedStatuses = [IsothermalCharacterizationStatus.EN_PREPARACION];

    return !allowedStatuses.includes(this.isothermalCharacterization.idStatus);
  }

  onChangeVariableCheck(event: MatCheckboxChange): void {
    const variable = event.source.value as unknown as Variable;

    if (this.isothermalCharacterization.variables == null) {
      this.isothermalCharacterization.variables = [];
    }

    const idVariable = variable.id;

    if (event.checked) {
      const tab = new IsothermalCharacterizationVariable();
      tab.idVariable = idVariable;
      tab.translationVariable = variable.translation;
      tab.sensors = [];
      tab.calibrations = [new IsothermalCharacterizationCalibration()];
      tab.calibrations.forEach(c => c.values = []);
      tab.copyFromTemperature = (idVariable === VariableTypeEnum.HUMIDITY);
      tab.criterias = [];
      tab.enableCalibration = true;

      let maxNumValues = Math.max(...this.isothermalCharacterization.variables.map(v => v.calibrations).reduce((a, b) => a.concat(b), [])
        .map(c => c.values.length)) || 0;

      maxNumValues = Math.max(maxNumValues, 1);

      tab.calibrations.forEach(calibration => {
        while (calibration.values.length < maxNumValues) {
          calibration.values.push(new IsothermalCharacterizationCalibrationValue());
        }
      });

      this.isothermalCharacterization.variables.push(tab);

      const variableUnits = this.essayComponent.variableUnits;

      const units = variableUnits.get(idVariable);

      if (ArrayUtils.isEmpty(units)) {
        this.variableTypeService.findUnits(idVariable).pipe(takeUntil(this.destroy$)).subscribe((res: VariableUnit[]) => {
          variableUnits.set(idVariable, res);

          this.essayComponent.variableUnits = variableUnits;

          if (!ArrayUtils.isEmpty(res)) {
            this.isothermalCharacterization.variables = this.isothermalCharacterization.variables.map(v => {

              if (v.idVariable === idVariable && v.idUnit == null) {
                v.idUnit = res[0].id;
                v.unitName = res[0].unit;
              }

              return v;
            });
          }

        });
      } else {
        if (!ArrayUtils.isEmpty(units)) {
          this.isothermalCharacterization.variables = this.isothermalCharacterization.variables.map(v => {

            if (v.idVariable === idVariable && v.idUnit == null) {
              v.idUnit = units[0].id;
              v.unitName = units[0].unit;
            }

            return v;
          });
        }
      }

    } else {
      this.isothermalCharacterization.variables = this.isothermalCharacterization.variables.filter(item => item.idVariable !== idVariable);
    }

    this.checked.set(variable.id, event.checked);
    this.essayComponent.variables = this.isothermalCharacterization.variables;
  }

  showDownloadButton(): boolean {
    if (this.isothermalCharacterization.idStatus == null) {
      return false;
    }

    const validStatuses = [IsothermalCharacterizationStatus.PENDIENTE_FIRMA, IsothermalCharacterizationStatus.FIRMADO];

    return validStatuses.includes(this.isothermalCharacterization.idStatus);
  }

  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 1) {
      this.audit.getAudit();
    }
  }

  openDialogEquipment(): void {
    const dialogRef = this.dialog.open(IsothermalCharacterizationEditEquipmentDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipment: new Equipment(),
        isEdit: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Equipment) => {
      if (result != null) {
        this.isothermalCharacterization.equipments.push(result);

        this.equipmentTable.renderRows();
      }
    });
  }

  openDialogEditEquipment(idx: number): void {
    const eq = this.isothermalCharacterization.equipments[idx];

    const dialogRef = this.dialog.open(IsothermalCharacterizationEditEquipmentDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipment: _.cloneDeep(eq),
        isEdit: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Equipment) => {
      if (result != null) {
        this.isothermalCharacterization.equipments[idx] = result;
        this.equipmentTable.renderRows();
      }
    });
  }

  deleteEquipmentRow(index: number): void {
    if (index > -1) {
      this.isothermalCharacterization.equipments.splice(index, 1);

      this.equipmentTable.renderRows();
    }
  }

  openNewRefDoc(): void {
    if (this.isothermalCharacterization.id == null) {
      return;
    }

    const dialogRef = this.dialog.open(EditReferenceDocsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        refDoc: new ReferenceDocument(),
        isEdit: false,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ReferenceDocument) => {

      if (result !== null && result !== undefined) {
        this.isothermalCharacterization.referenceDocs.push(result);

        this.refDocTable.renderRows();
      }
    });
  }

  editRefDoc(refDocIndex: number): void {
    const refDoc = this.isothermalCharacterization.referenceDocs[refDocIndex];

    const dialogRef = this.dialog.open(EditReferenceDocsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        refDoc: _.cloneDeep(refDoc),
        isEdit: true,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ReferenceDocument) => {

      if (result !== null && result !== undefined) {
        this.isothermalCharacterization.referenceDocs[refDocIndex] = result;

        this.refDocTable.renderRows();
      }
    });
  }

  removeRefDoc(refDocIndex: number): void {
    this.isothermalCharacterization.referenceDocs.splice(refDocIndex, 1);

    this.refDocTable.renderRows();
  }

  saveIsothermalCharacterization(): void {
    const errors = [];

    if (this.isothermalCharacterization.idClient == null) {
      errors.push(this.translate.instant('isothermalCharacterizationEdit.general.client.error'));
    }

    if (ArrayUtils.isEmpty(this.isothermalCharacterization.equipments)) {
      errors.push(this.translate.instant('isothermalCharacterizationEdit.general.equipments.error.empty'));
    }

    if (errors.length !== 0) {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);

      return;
    }

    if (this.essaysComponent instanceof QueryList) {
      this.essaysComponent = this.essaysComponent.first as IsothermalCharacterizationEditEssayComponent;
    }

    if (this.isothermalCharacterization.id == null) {
      const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
        minWidth: '20%',
        maxHeight: '95vh',
        data: {}
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {

        if (token != null) {
          this.spinnerService.show();

          this.isothermalCharacterizationService.create(this.isothermalCharacterization, token).pipe(takeUntil(this.destroy$))
            .subscribe((item: IsothermalCharacterization) => {
              this.snackBarService.sendSuccess(this.translate.instant('isothermalCharacterizationEdit.form.create.ok') as string);

              let url: string;
              if (this.router.url.includes('calibrates')){
                url = `calibrates/isothermalCharacterization?id=${item.id}`
              } else if (this.router.url.includes('thermal')) {
                url = `thermal/isothermalCharacterization?id=${item.id}`
              }
              void this.router.navigateByUrl(url);

              this.spinnerService.hide();
            }, () => {
              this.snackBarService.sendError(this.translate.instant('isothermalCharacterizationEdit.form.create.error') as string);

              this.spinnerService.hide();
            });

        } else {
          this.spinnerService.hide();
        }

      });

    } else if (this.isothermalCharacterization.idStatus === IsothermalCharacterizationStatus.FIRMADO
      || (+this.isothermalCharacterization.currentVersion) > 1) {

      const dialogRef = this.dialog.open(IsothermalCharacterizationEditConfirmSaveComponent, {
        minWidth: '20%',
        maxHeight: '95vh',
        data: {
          reason: this.isothermalCharacterization.reason,
          showReason: true
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(resultConfirm => {

        if (resultConfirm !== null && resultConfirm !== undefined) {
          const reason = resultConfirm.reason as string;

          const dialogRefConfirmPassword = this.dialog.open(ActionConfirmPasswordComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {}
          });

          dialogRefConfirmPassword.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(resultConfirmPassword => {

            this.isothermalCharacterization.reason = reason;

            if (resultConfirmPassword !== null && resultConfirmPassword !== undefined) {

              this.isothermalCharacterizationService.save(this.isothermalCharacterization).pipe(takeUntil(this.destroy$))
                .subscribe((item: IsothermalCharacterization) => {
                  this.snackBarService.sendSuccess(this.translate.instant('isothermalCharacterizationEdit.form.update.ok') as string);

                  this.spinnerService.hide();

                  this.reloadPage(item.id);
                }, error => {
                  if (error != null && error.error != null && typeof error.error === 'string') {
                    this.snackBarService.sendError(error.error as string);
                  } else {
                    this.snackBarService.sendError(this.translate.instant('isothermalCharacterizationEdit.form.update.error') as string);
                  }

                  this.spinnerService.hide();
                });

            } else {
              this.spinnerService.hide();
            }
          });

        } else {
          this.spinnerService.hide();
        }
      });

    } else {

      const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
        minWidth: '20%',
        maxHeight: '95vh',
        data: {}
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {

        if (result !== null && result !== undefined) {

          this.isothermalCharacterizationService.save(this.isothermalCharacterization).pipe(takeUntil(this.destroy$))
            .subscribe((item: IsothermalCharacterization) => {
              this.snackBarService.sendSuccess(this.translate.instant('isothermalCharacterizationEdit.form.update.ok') as string);

              this.spinnerService.hide();

              this.reloadPage(item.id);
            }, error => {
              if (error != null && error.error != null && typeof error.error === 'string') {
                this.snackBarService.sendError(error.error as string);
              } else {
                this.snackBarService.sendError(this.translate.instant('isothermalCharacterizationEdit.form.update.error') as string);
              }

              this.spinnerService.hide();
            });

        } else {
          this.spinnerService.hide();
        }
      });

    }
  }

  showAdvance(): boolean {
    if (this.isothermalCharacterization.idStatus == null) {
      return false;
    }

    const profile = this.userService.currentProfile;

    if (profile == null) {
      return false;
    }

    const validStatuses = [IsothermalCharacterizationStatus.EN_PREPARACION];

    return validStatuses.includes(this.isothermalCharacterization.idStatus);
  }

  calculateAdvance(): void {
    this.isothermalCharacterizationService.save(this.isothermalCharacterization).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.prepareExecute();
    }, error => {
      if (error != null && error.error != null && typeof error.error === 'string') {
        this.snackBarService.sendError(error.error as string);
      } else {
        this.snackBarService.sendError(
          this.translate.instant('isothermalCharacterizationEdit.form.status.advance.error.generic') as string);
      }
    });
  }

  finalizedIsothermalCharacterization(): boolean {
    const statusFinalized = [IsothermalCharacterizationStatus.FIRMADO];

    return statusFinalized.includes(this.isothermalCharacterization.idStatus);
  }

  showBack(): boolean {
    if (this.isothermalCharacterization.idStatus == null) {
      return false;
    }

    const profile = this.userService.currentProfile;

    if (profile == null) {
      return false;
    }

    const validStatuses = [IsothermalCharacterizationStatus.PENDIENTE_FIRMA];

    return validStatuses.includes(this.isothermalCharacterization.idStatus);
  }

  calculateBack(): void {
    if (this.isothermalCharacterization.idStatus === IsothermalCharacterizationStatus.PENDIENTE_FIRMA) {
      this.invalidate();
    } else {
      this.snackBarService.sendError(this.translate.instant('isothermalCharacterizationEdit.form.status.error.notAllowed') as string);
    }
  }

  prepareExecute(): void {
    const errors = [];

    if (this.isothermalCharacterization.idDisposal == null) {
      errors.push(this.translate.instant('isothermalCharacterizationEdit.form.status.error.emptyDisposal'));
    }

    if (this.isothermalCharacterization.idPhoto == null) {
      errors.push(this.translate.instant('isothermalCharacterizationEdit.form.status.error.emptyPhoto'));
    }

    if (errors.length !== 0) {
      let error = errors.join('\n');

      error += '\n\n' + (this.translate.instant('isothermalCharacterizationEdit.continue') as string);

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        minWidth: '20%',
        maxHeight: '95vh',
        data: {
          message: error
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {

        if (result === true) {
          this.execute();
        }
      });

      return;
    }

    this.execute();
  }

  execute(): void {
    const errors = [];

    this.spinnerService.show();

    if (StringUtils.isEmpty(this.isothermalCharacterization.workingConditions)) {
      errors.push(this.translate.instant('isothermalCharacterizationEdit.dialog.essay.workingConditions.error'));
    }

    if (StringUtils.isEmpty(this.isothermalCharacterization.rehearsalTime)) {
      errors.push(this.translate.instant('isothermalCharacterizationEdit.dialog.essay.rehearsalTime.error'));
    }
    if (StringUtils.isEmpty(this.isothermalCharacterization.frequency)) {
      errors.push(this.translate.instant('isothermalCharacterizationEdit.dialog.essay.frequency.error'));
    }

    if (ArrayUtils.isEmpty(this.isothermalCharacterization.variables)) {
      errors.push(this.translate.instant('isothermalCharacterizationEdit.essays.sensor.form.error.emptyVariables'));
    } else {
      this.isothermalCharacterization.variables.forEach((essay: IsothermalCharacterizationVariable) => {

        const dsVariable = this.translate.instant('variable.' + essay.translationVariable) as string;

        if (ArrayUtils.isEmpty(essay.sensors)) {
          errors.push(this.translate.instant('isothermalCharacterizationEdit.essays.sensor.form.error.emptySensors',
            { variable: dsVariable }));
        } else {
          essay.sensors.forEach((sensor, indexSensor) => {
            if (sensor.evaluate && sensor.idExcel == null && this.essayComponent.showExcelButton()) {
              errors.push(this.translate.instant('isothermalCharacterizationEdit.essays.sensor.form.error.excel',
                { index: indexSensor + 1, variable: dsVariable }));
            }

            if (sensor.evaluate && sensor.idPrimaryDataFile == null && this.essayComponent.showPrimaryButton()) {
              errors.push(this.translate.instant('isothermalCharacterizationEdit.essays.sensor.form.error.primaryData',
                { index: indexSensor + 1, variable: dsVariable }));
            }

            if (sensor.evaluate && (sensor.uncertainty == null || sensor.uncertainty <= 0)) {
              errors.push(this.translate.instant('isothermalCharacterizationEdit.essays.sensor.form.error.uncertainty',
                { index: indexSensor + 1, variable: dsVariable }));
            }
          });
        }

        if (essay.idUnit == null) {
          errors.push(this.translate.instant('isothermalCharacterizationEdit.dialog.essay.unit.error'));
        }

        if (StringUtils.isEmpty(essay.setpoint) || isNaN(+essay.setpoint)) {
          errors.push(this.translate.instant('isothermalCharacterizationEdit.dialog.essay.setpoint.error'));
        }

        if (essay.enableCalibration) {
          const calibrations = essay.calibrations;

          calibrations.forEach(calibration => {
            if (StringUtils.isEmpty(calibration.resolution) || isNaN(+calibration.resolution)) {
              errors.push(this.translate.instant('isothermalCharacterizationEdit.dialog.essay.resolution.error'));
            }

            if (StringUtils.isEmpty(calibration.equipmentName)) {
              errors.push(this.translate.instant('isothermalCharacterizationEdit.dialog.essay.sensors.serialNum.error'));
            }

            if (StringUtils.isEmpty(calibration.uncertainty) || isNaN(+calibration.uncertainty)) {
              errors.push(this.translate.instant('isothermalCharacterizationEdit.dialog.essay.sensors.uncertainty.error'));
            }

            if (StringUtils.isEmpty(calibration.pattern) || isNaN(+calibration.pattern)) {
              errors.push(this.translate.instant('isothermalCharacterizationEdit.dialog.essay.pattern.error'));
            }

            if (!ArrayUtils.isEmpty(calibration.values.filter(v => StringUtils.isEmpty(v.value) || isNaN(+v.value)))) {
              errors.push(this.translate.instant('isothermalCharacterizationEdit.dialog.essay.value.error'));
            }
          });

        }

      });
    }

    this.spinnerService.hide();

    if (errors.length !== 0) {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);

      return;
    }

    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {

      if (token != null) {
        this.spinnerService.show();

        this.isothermalCharacterizationService.execute(this.isothermalCharacterization, token).pipe(takeUntil(this.destroy$))
          .subscribe((item: IsothermalCharacterization) => {
            const status = this.translate.instant(`isothermalCharacterizationStatus.${item.statusTranslation}`) as string;
            this.snackBarService.sendSuccess(
              this.translate.instant('isothermalCharacterizationEdit.form.status.advance.ok', { status }) as string);

            this.reloadPage();

            this.spinnerService.hide();

          }, () => {
            this.snackBarService.sendError(
              this.translate.instant('isothermalCharacterizationEdit.form.status.advance.error.generic') as string);

            this.spinnerService.hide();
          }
          );

      } else {
        this.spinnerService.hide();
      }
    });
  }

  invalidate(): void {
    const errors = [];

    if (errors.length !== 0) {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);

      return;
    }

    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {

      if (token != null) {
        this.spinnerService.show();

        this.isothermalCharacterizationService.invalidate(this.isothermalCharacterization, token).pipe(takeUntil(this.destroy$))
          .subscribe((item: IsothermalCharacterization) => {
            const status = this.translate.instant(`isothermalCharacterizationStatus.${item.statusTranslation}`) as string;
            this.snackBarService.sendSuccess(
              this.translate.instant('isothermalCharacterizationEdit.form.status.back.ok', { status }) as string);

            this.reloadPage();

            this.spinnerService.hide();

          }, () => {
            this.snackBarService.sendError(
              this.translate.instant('isothermalCharacterizationEdit.form.status.advance.error.generic') as string);

            this.spinnerService.hide();
          });

      } else {
        this.spinnerService.hide();
      }
    });

  }

  downloadPdf(): void {
    this.dialog.open(IsothermalCharacterizationEditGenerateReportComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {
        isothermalCharacterization: this.isothermalCharacterization,
        reason: this.isothermalCharacterization.reason
      }
    });
  }

  cancel(): void {
    let url: string;
    if (this.router.url.includes('calibrates')){
      url = 'calibrates/isothermalCharacterizations';
    } else if (this.router.url.includes('thermal')) {
      url = 'thermal/isothermalCharacterizations';
    }
    void this.router.navigateByUrl(url);
  }

  onFcStartEssayEmit(event: any): void {
    if (event.essayIndex != null && event.variableIndex != null) {

      const init = _.cloneDeep(event.fcStartEssay) as Date;
      const end = _.cloneDeep(event.fcEndEssay) as Date;

      this.isothermalCharacterization.variables[event.variableIndex].fcStartEssay = init;
      this.isothermalCharacterization.variables[event.variableIndex].fcEndEssay = end;
    }
  }

  public setValue(field: string, index: number, value: string | MatCheckboxChange | MatSelectionListChange): void {

    if (value instanceof MatCheckboxChange) {
      value = value.checked.toString();
    } else if (value instanceof MatSelectionListChange) {
      value = value.source.selectedOptions.selected.map((i) => i.value as string).join(';');
    }

    if (index == null || index < 0) {
      return;
    }

    const val = this.isothermalCharacterization[field] as string;
    const arr = val.split(FieldUtils.separator);

    while (arr.length < index) {
      arr.push('');
    }

    if (!ArrayUtils.isEmpty(arr) && arr.length > 1) {

      arr[index] = value;
      this.isothermalCharacterization[field] = arr.join(FieldUtils.separator);
    } else {
      this.isothermalCharacterization[field] = value;
    }

    if (field === 'rehearsalTime') {
      this.essayComponent.setRange(this.isothermalCharacterization[field]);
    }

  }

  public getValue(field: string, index: number) {

    let val = this.isothermalCharacterization[field];

    if (val == null) {
      val = '';
    }

    val = val.split(FieldUtils.separator);

    while (val.length < index) {
      val.push('');
    }

    this.isothermalCharacterization[field] = val.join(FieldUtils.separator);

    return val[index];
  }

  setIdDisposal(data: any): void {
    this.isothermalCharacterization.idDisposal = data.id;
  }

  setIdPhoto(data: any): void {
    this.isothermalCharacterization.idPhoto = data.id;
  }

  setSensor(data: any): void {
    const variableIndex = data.variableIndex;
    const sensorIndex = data.sensorIndex;
    const sensor = data.sensor;

    this.isothermalCharacterization.variables[variableIndex].sensors[sensorIndex] = sensor;
  }

  canEditDocumentCode(): boolean {
    const allowedStatuses = [IsothermalCharacterizationStatus.EN_PREPARACION, IsothermalCharacterizationStatus.PENDIENTE_FIRMA];

    return this.isothermalCharacterization.id != null && allowedStatuses.includes(this.isothermalCharacterization.idStatus);
  }

  editDocumentCode(): void {
    const dialogRef = this.dialog.open(EditDocumentCodeEditComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        documentCode: this.isothermalCharacterization.documentCode
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {

      if (result !== null && result !== undefined) {
        this.isothermalCharacterization.documentCode = result;
      }
    });
  }

  automaticSign(): void {
    this.calculateErrors(errors => {
      if (errors.length !== 0) {
        const error = errors.join('\n');
        this.snackBarService.sendError(error);

        return;
      }

      const dialogRef = this.dialog.open(IsothermalCharacterizationEditSignReportComponent, {
        minWidth: '20%',
        maxHeight: '95vh',
        data: {
          isothermalCharacterization: this.isothermalCharacterization
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {

        if (result != null) {
          if (this.isothermalCharacterization.idClient) {
            this.clientService.findOne(this.isothermalCharacterization.idClient).subscribe((client: Client) => {

              if (client.idGroup != null) {
                void this.cloneRow(this.isothermalCharacterization.id, client.idGroup).then(() => this.reloadPage());
              } else {
                this.reloadPage();
              }
            });
          } else {
            this.reloadPage();
          }
        } else {
          this.spinnerService.hide();
        }
      });
    });
  }

  manualSign(): void {
    this.calculateErrors(errors => {

      if (errors.length !== 0) {
        const error = errors.join('\n');
        this.snackBarService.sendError(error);

        return;
      }

      const dialog = this.dialog.open(IsothermalCharacterizationEditValidateIsothermalCharacterizationComponent, {
        minWidth: '20%',
        maxHeight: '95vh',
        data: {
          isothermalCharacterization: this.isothermalCharacterization
        }
      });

      dialog.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(() => {
        if (this.isothermalCharacterization.idClient) {
          this.clientService.findOne(this.isothermalCharacterization.idClient).subscribe((client: Client) => {

            if (client.idGroup != null) {
              void this.cloneRow(this.isothermalCharacterization.id, client.idGroup).then(() => this.reloadPage());
            } else {
              this.reloadPage();
            }
          });
        } else {
          this.reloadPage();
        }
      });
    });

  }

  calculateErrors(callback: (errors: string[]) => void): void {
    const errors: string[] = [];

    this.isothermalCharacterization.variables.forEach((essay: IsothermalCharacterizationVariable) => {

      const dsVariable = this.translate.instant('variable.' + essay.translationVariable) as string;

      essay.sensors.forEach((sensor, indexSensor) => {
        if (sensor.evaluate && sensor.idExcel == null && this.essayComponent.showExcelButton()) {
          errors.push(this.translate.instant('isothermalCharacterizationEdit.essays.sensor.form.error.excel',
            { index: indexSensor + 1, variable: dsVariable }) as string);
        }

        if (sensor.evaluate && sensor.idPrimaryDataFile == null && this.essayComponent.showPrimaryButton()) {
          errors.push(this.translate.instant('isothermalCharacterizationEdit.essays.sensor.form.error.primaryData',
            { index: indexSensor + 1, variable: dsVariable }) as string);
        }
      });
    });

    callback(errors);
  }

  showAutomaticSign(): boolean {
    const current = this.currentUser;

    return this.canSign() && current.automaticSign;
  }

  showManualSign(): boolean {
    const current = this.currentUser;

    return this.canSign() && current.manualSign;
  }

  showRevertSign(): boolean {
    return this.isothermalCharacterization.idStatus === IsothermalCharacterizationStatus.FIRMADO;
  }

  revertSign(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.translate.instant('calibrateEquipmentEdit.form.revertSign.confirm') as string
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {

      if (result === true) {
        this.requestReason((reason) => {
          const dialogRefConfirmation = this.dialog.open(ActionConfirmPasswordComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {}
          });
          dialogRefConfirmation.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
            this.isothermalCharacterizationService.revertSign(this.isothermalCharacterization.id, reason, token)
              .pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.spinnerService.hide();
                this.reloadPage(this.isothermalCharacterization.id);
              });
          }, error => {
            if (error != null && error.error != null && typeof error.error === 'string') {
              this.snackBarService.sendError(error.error as string);
            } else {
              this.snackBarService.sendError(this.translate.instant('protocolEdit.form.revertSign.error') as string);
            }
          });
        });
      }
    });
  }

  private canSign(): boolean {
    const allowedStatuses = [IsothermalCharacterizationStatus.PENDIENTE_FIRMA];

    return this.isothermalCharacterization != null && allowedStatuses.includes(this.isothermalCharacterization.idStatus);
  }

  private requestReason(callback: (reason: string) => void) {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

  private cloneRow(id: number, idGroup: number): Promise<void> {

    return new Promise<void>((resolve) => {

      const dialogRefConfirm = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('generic.clone.sign') as string
        }
      });

      dialogRefConfirm.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: boolean) => {
        if (result === true) {

          this.spinnerService.show();

          this.isothermalCharacterizationService.checkCopyToGroup(id, idGroup).subscribe((res: GenericWarnError) => {
            this.spinnerService.hide();

            const dialogRef = this.dialog.open(CheckWarnsErrorsComponent, {
              minWidth: '20%',
              maxHeight: '95vh',
              data: {
                warns: res.warns,
                errors: res.errors
              }
            });

            dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((canContinue: boolean) => {
              if (canContinue === true) {
                this.spinnerService.show();
                this.isothermalCharacterizationService.copyToGroup(id, idGroup).subscribe(() => {
                  this.spinnerService.hide();

                  this.snackBarService.sendSuccess(this.translate.instant('isothermalCharacterizationEdit.form.clone.ok') as string);
                  resolve();
                }, () => {
                  this.spinnerService.hide();
                  this.snackBarService.sendError(this.translate.instant('isothermalCharacterizationEdit.form.clone.error') as string);
                  resolve();
                });
              } else {
                resolve();
              }
            });
          }, () => {
            this.spinnerService.hide();
            this.snackBarService.sendError(this.translate.instant('isothermalCharacterizationEdit.form.clone.error') as string);
            resolve();
          });
        } else {
          resolve();
        }
      });

    });
  }

}
