import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { IsothermalCharacterization, IsothermalCharacterizationStatus } from 'src/app/model/isothermalCharacterization';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { IsothermalCharacterizationService } from 'src/app/services/isothermalCharacterization.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DefrostService } from 'src/app/services/defrost.service';
import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { IsothermalCharacterizationEditGenerateReportComponent } from './isothermal-characterization-edit-generate-report.component';
import { TranslateService } from '@ngx-translate/core';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { takeUntil } from 'rxjs/operators';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';

export interface DialogDataValidateIsothermalCharacterization {
  isothermalCharacterization: IsothermalCharacterization;
  documentSigned: File;
}

@Component({
  selector: 'app-isothermal-characterization-edit-validate-isothermal-characterization',
  templateUrl: './isothermal-characterization-edit-validate-isothermal-characterization.component.html'
})
export class IsothermalCharacterizationEditValidateIsothermalCharacterizationComponent implements OnInit, OnDestroy {

  mainForm: FormGroup;

  forceAccording: boolean;
  reasonForce: string;
  reason: string;

  errors: string[];
  warns: string[];

  private destroy$ = new Subject<void>();

  constructor(
    fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<IsothermalCharacterizationEditValidateIsothermalCharacterizationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataValidateIsothermalCharacterization,
    private isothermalCharacterizationService: IsothermalCharacterizationService,
    private defrostService: DefrostService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    this.mainForm = fb.group({
      forceAccording: [this.forceAccording],
      reasonForce: [this.reasonForce],
      reason: [this.reason]
    });
  }

  ngOnInit(): void {
    this.errors = [];
    this.warns = [];

    this.spinnerService.show();

    const according = this.data.isothermalCharacterization.according;

    const force: boolean = according != null ? according.valueOf() : false;
    this.forceAccording = !force;
    this.reasonForce = this.data.isothermalCharacterization.accordingReason;
    this.reason = this.data.isothermalCharacterization.reason;

    const promises = [];
    const map = new Map();

    this.data.isothermalCharacterization.variables.forEach(essay => {
      promises.push(new Promise<void>(resolve => {
        this.defrostService.findAllFromEssay(essay.id).pipe(takeUntil(this.destroy$)).subscribe(item => {
          map.set(essay.id, item);
          resolve();
        }, () => {
          map.set(essay.id, null);
          resolve();
        });
      }));
    });

    void Promise.all(promises).then(() => {
      this.spinnerService.show();

      this.errors = [];
      this.warns = [];

      setTimeout(() => {
        this.spinnerService.hide();
      }, 100);
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isEmpty(a: any[]): boolean {
    return ArrayUtils.isEmpty(a);
  }

  canAccept(): boolean {
    let res = false;

    if (this.errors == null) {
      res = true;
    } else if (this.forceAccording) {
      res = true;
    } else if (this.reasonForce != null && this.reasonForce !== '') {
      res = true;
    } else if (this.data.isothermalCharacterization.idStatus === IsothermalCharacterizationStatus.PENDIENTE_FIRMA
      && this.data.documentSigned != null) {
      res = true;
    }

    return res;
  }

  showReason(): boolean {
    return (+this.data.isothermalCharacterization.currentVersion) > 1;
  }

  canDownloadDocument(): boolean {
    return this.data.isothermalCharacterization.idStatus === IsothermalCharacterizationStatus.PENDIENTE_FIRMA;
  }

  downloadDocument(): void {
    this.dialog.open(IsothermalCharacterizationEditGenerateReportComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {
        isothermalCharacterization: this.data.isothermalCharacterization,
        toSign: true,
        reason: this.reason
      }
    });
  }

  uploadDocumentToIsothermalCharacterization(event): void {
    this.data.documentSigned = event.target.files[0] as File;

    if (this.data.documentSigned != null) {
      this.onOkClick();
    }
  }

  onOkClick(): void {
    this.validateByDoc();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private validateByDoc() {

    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {

      if (result !== null && result !== undefined) {

        this.spinnerService.show();

        this.data.isothermalCharacterization.reason = this.reason;

        this.isothermalCharacterizationService.manualSign(this.data.isothermalCharacterization, result, this.forceAccording,
          this.reasonForce, this.data.documentSigned).pipe(takeUntil(this.destroy$)).subscribe(
            item => this.onFinishOk(item), () => {
              this.snackBarService.sendError(this.translate.instant('isothermalCharacterizationEdit.form.status.advance.error.generic'));

              this.spinnerService.hide();
            }
          );

      } else {
        this.spinnerService.hide();
      }
    });

  }

  private onFinishOk(item: IsothermalCharacterization) {
    const status = this.translate.instant('isothermalCharacterizationStatus.' + item.statusTranslation) as string;
    this.snackBarService.sendSuccess(this.translate.instant('isothermalCharacterizationEdit.form.status.advance.ok', { status }));

    this.data.isothermalCharacterization = item;

    this.spinnerService.hide();

    this.dialogRef.close(this.data.isothermalCharacterization);
  }

}
