import { Area } from './area';
import { CriticalInstrumentationExecution } from './criticalInstrumentation';
import { EquipmentExecution, EquipmentQExecution } from './equipment';
import { Essay } from './essay';
import { GenericAttachment, GenericFilter } from './genericClass';
import { Protocol, ProtocolAir } from './protocol';
import { ReferenceDocument } from './referenceDocument';
import { ValidatorSpecifics } from './validatorSpecific';

export class Execution {
    id: number;

    idProtocol: number;
    nameProtocol: string;
    originalProtocolVersion: number;
    protocol: Protocol; // Puede no venir, no usar si no se está seguro

    idProcess: number;
    processTranslation: string;

    idClient: number;
    nameClient: string;
    clientAddress: string;

    idPhase: number;
    phaseTranslation: string;

    usernameProjectManager: string;

    idInstrumentation: number; // FIXME: A desaparecer
    nameInstrumentation: string; // FIXME: A desaparecer

    regUser: string;
    modUser: string;

    regFc: Date;
    modFc: Date;

    idStatus: number;
    statusTranslation: string;

    reason: string;

    deviations: string;
    observations: string;

    according: boolean;
    accordingReason: string;

    projectNo: string;
    documentCode: string;
    clientDocumentCode: string;
    currentVersion: string;

    department: string;

    equipments: EquipmentExecution[] = [];
    essays: Essay[] = [];
    criticalInstrumentations: CriticalInstrumentationExecution[] = [];
    referenceDocs: ReferenceDocument[];

    // instrumentations: Instrumentation[]; // TODO: A implementar
    validator: ValidatorSpecifics; // FIXME: A desaparecer

    initWorkDate: Date;
    endWorkDate: Date;

    realizationDate: Date;
    conclusions: string;
}

export class ExecutionAudit {
    id: number;
    username: string;

    idAction: number;
    actionName: string;
    actionTranslation: string;

    idStatus: number;
    nameStatus: string;
    statusTranslation: string;

    date: Date;

    currentVersion: string;

    changes: string;

    canDownload: boolean;
}

export class ExecutionAttachment extends GenericAttachment {
    idExecution: number;
}

export class ExecutionQAttachment extends GenericAttachment {
    idExecution: number;
    idAttachment: number;
}

export class ExecutionFilter extends GenericFilter {
    projectNo: string;
    documentCode: string;
    clientDocumentCode: string;
    documentCodeProtocol: string;

    idProtocol: number;

    idProcess: string;
    idClient: number;
    idPhase: number;
    idInstrumentation: number;

    idStatus: number;

    regUser: string;
    modUser: string;
    projectManager: string;

    regFcStart: Date;
    regFcEnd: Date;

    modFcStart: Date;
    modFcEnd: Date;

    workStartFcStart: Date;
    workStartFcEnd: Date;

    workEndFcStart: Date;
    workEndFcEnd: Date;

    realizationFcStart: Date;
    realizationFcEnd: Date;

    equipment: string;
    equipmentMaker: string;
    equipmentModel: string;
    equipmentSerialNum: string;
    equipmentInternalId: string;
    equipmentLocation: string;

    department: string;

    areaName: string;

    cols: string[];
}

export class ExecutionAir {
    id: number;

    idProtocol: number;
    nameProtocol: string;
    originalProtocolVersion: number;
    protocol: ProtocolAir; // Puede no venir, no usar si no se está seguro

    idProcess: number;
    processTranslation: string;

    idClient: number;
    nameClient: string;
    clientAddress: string;

    usernameProjectManager: string;

    regUser: string;
    modUser: string;

    regFc: Date;
    modFc: Date;

    idStatus: number;
    statusTranslation: string;

    reason: string;

    deviations: string;
    observations: string;
    notes: string;

    customDeviations: string;
    customObservations: string;

    according: boolean;
    accordingReason: string;

    projectNo: string;
    documentCode: string;
    clientDocumentCode: string;
    currentVersion: string;

    referenceDocs: ReferenceDocument[];

    initWorkDate: Date;
    endWorkDate: Date;

    realizationDate: Date;
    conclusions: string;

    areas: Area[];
    areaNames: string;
    workOffline: boolean;
}

export class ExecutionQ{
    id: number;

    documentCode: string;
    documentCodeOriginal: string;
    clientDocumentCode: string;

    projectNo: string;
    
    idProtocol:number;
    protocolDocumentCode: string;
    originalProtocolVersion:number;

    idStatus: number;
    statusTranslation: string;

    observations: string;

    deviations: string;

    currentVersion: number;
    signedVersion: string;

    regUser: string;
    modUser: string;

    regFc: Date;
    modFc: Date;

    according: boolean;
    accordingReason: string;

    idClient: number;
    clientName: string;

    idPhase:number;
    idInstrumentation: number;
    idProcess:number;

    usernameProjectManager: string;

    reason: string;

    conclusions: string;

    departament: string;

    notes: string;

    referenceDocs: ReferenceDocument[];

    equipments: EquipmentQExecution[];

    executionQTests: ExecutionQTest[] = [];

}

export class ExecutionQFilter extends GenericFilter{

    documentCode: string;

    protocolDocumentCode: string;

    clientDocumentCode: string;
    projectNo: string;

    idProcess: string;
    idClient: number;
    idStatus: number;
    regFc: Date;

    regFcStart: Date;
    regFcEnd: Date;

    modFcStart: Date;
    modFcEnd: Date;

    projectManager: string;


}

export class ExecutionAuditFilter extends GenericFilter {
}

export class ExecutionAttachmentFilter extends GenericFilter {
}

export enum ExecutionStatus {
    EN_EJECUCION = 2,
    PENDIENTE_FIRMA = 4,
    FIRMADO = 5
}

export enum ExecutionActionEnum {
    CREATE = 1,
    SAVE = 2,
    PREPARE = 3,
    EXECUTE = 4,
    VALIDATED_BY_TECHNICIAN = 5,
    INVALIDATE_BY_TECHNICIAN = 6,
    VALIDATED_BY_DOCUMENTATION = 7,
    INVALIDATE_BY_DOCUMENTATION = 8,
    UPLOAD_PHOTO_SENSOR = 9,
    UPLOAD_EXCEL_SENSOR = 10,
    UPLOAD_PRIMARY_DATA_SENSOR = 11,
    UPLOAD_ATTACHMENT = 12,
    DELETE_ATTACHMENT = 13,
    MODIFY_CRITICAL_INSTR = 14,
    MODIFY_ESSAY = 15,
    MODIFY_SENSOR = 16,
    MODIFY_FIELD = 17,
    MODIFY_CRITERIA = 18,
    MODIFY_EQUIPMENT = 19
}

export class ExecutionQTest {
    id: number;
    idExecution: number;
    idTestType: number;
    executionQTestItem: ExecutionQTestItem[];
    executionQTestProbeTraceability: ExecutionQTestProbeTraceability[];
    executionQTestResults: ExecutionQTestResults[];
    executionQTestBioindicadores: ExecutionQTestBioindicadores[];
    executionQTestVerification: ExecutionQTestDataOQ[];
    executionQTestGeneralData: ExecutionQTestGeneralData[];
    executionQTestAttachment: ExecutionQTestAttachment[];
    deviations: ExecutionQTestDeviations[];
    idOffline: string;
    testDescription: string;

    idStatus: number;  
      
    regUser: string;
    regDate: Date;
    modUser: string;
    modDate: Date;
    reason: string;

    imageTest: string;

    according:boolean;
}

export class ExecutionQTestItem {
    id: number;
    idExecutionTest: number;
    idType: number;
    description: string;
    executionQTestItemVerification: ExecutionQTestItemVerification[];
}

export class ExecutionQTestItemVerification {
    id: number;
    idItem: number;
    verification: string;
    executionQTestDataMultiple: ExecutionQTestData[];
}

export class ExecutionQTestData {
    id: number;
	idVerification: number;
	expectedResultDesc: string;
	expectedResultRhDesc: string;
    obtainedResult:string;
    selectedResult: string;
	resultDesc: string[];
    uuid: string;
    idImage: number;
    executionQTestDeviations: ExecutionQTestDeviations;

}


export class ExecutionQTestProbeTraceability {
    id: number;
    descriptionProbe: string;
    identifier: string;
    order: number;
    idExecutionTest: number;
    location: string;
    tacometer: boolean;
    idEquipment: number;
    external: boolean; 
}

export class ExecutionQTestResults{
    id: number;
    idExecutionTest: number;
    description: string;
    verification: string;
    obtainedResult: string;
    selectedResult: string;
	resultExpected: string[];
	result: string[];
    executionQTestDeviations: ExecutionQTestDeviations;
}

export class ExecutionQTestDataOQ {
    id: number;
    idExecution: number;
    test: string;
    descriptionTest: string;
    resultDesc: string[];
    selectedResult: string;
    executionQTestDeviations: ExecutionQTestDeviations;
}

export class ExecutionQTestBioindicadores {
    id: number;
    bioDescription: string;
    identifier: string;
    order: number;
    ExecutionTestId: number;
    control: string;
}

export class ExecutionQTestGeneralData{
    id:number;
    idExecutionTest:number;
    methodology:string;
    objective:string;
    criteria:string;
}

export class ExecutionQGeneralData {
    id: number;
    idExecution: number;
    precedents: string;
    target: string;
    scope: string;
    validationTeamResponsibility: string;
    clientResponsibility: string;
    engineeringResponsibility: string;
}

export class ExecutionQTestAttachment extends GenericAttachment{
    idTest: number;
    idAttachment: number;
}


export class ExecutionQTestDeviations {
    id: number;
    codeDeviation: string;
    idDataMultiple: string;
    idExecution: number;
    idTestExecution: number;
    idTestResult: number;
    executionQTestDeviationData: ExecutionQTestDeviationData[];
    modUser: string;
    modDate: Date;
    regDate: Date;
}

export class ExecutionQTestDeviationData {
    id: number;
    idDeviation: number;
    idType: number;
    comments: string;
    closed: boolean;
    valuation: number;
    qualificationContinue: boolean;
    resolved: boolean;
    regUser: string;
    modUser: string;
    regFc: Date;
    modFc: Date;
}

export enum TestTypeDeviations {
    COMMENTS = 1,
    VALUATION = 2,
    PROPOSED_CORRECTIVE = 3,
    ACTIONS_TAKEN = 4,
    DEVIATION_RESOLUTION = 5,
    DEVIATION_CLOSED = 6
}
