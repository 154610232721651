export class FieldType {
    id: number;

    name: string;
    noValuesRequired: number;
}

export enum FieldTypeEnum {
    STRING = 1,
    NUMBER = 2,
    VALUE_TOLERANCE = 3,
    DAY_HOUR_MINUTE = 4,
    HOUR_MINUTE = 5,
    SELECT_ONE = 6,
    SELECT_MULTI = 7,
    BOOLEAN = 8,
    AREA = 9
}