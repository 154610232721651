import { HttpClient } from '@angular/common/http';

import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class EquipmentTypeService {

    constructor(private http: HttpClient) {
    }

    findAll(): Observable<any> {
        const url = environment.coreUrl + '/equipmentType/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOne(id: number): Observable<any> {
        const url = environment.coreUrl + `/equipmentType/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

}
