import { SensorData } from './sensorData';

export class Sensor {
    id: number;
    idVariable: number;

    location: string;
    locationProtocol: string;
    locationChangeReason: string;

    idPhoto: number;
    idExcel: number;
    idPrimaryDataFile: number;

    idEquipment: number;
    equipmentName: string;
    serialNum: string;

    evaluate: boolean;
    evaluateReason: string;

    outer: boolean;

    hasBioindicator: boolean;
    bioindicatorSerialNum: string;

    hasEndotoxin: boolean;
    endotoxinSerialNum: string;

    hotColdSpot: boolean;

    showDiffInDeviations: boolean;
    showDiffInObservations: boolean;

    data: SensorData[];

    fromProtocol: boolean;
    addingReason: string;

    mkt: number;

    corrected: boolean;
}
