import { Observable, from } from 'rxjs';
import { Variable, VariableUnit } from '../model/variable';

import { DexieService } from './dexieService';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { OnlineService } from './online.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class VariableTypeService {

    constructor(private http: HttpClient,
        private dexieService: DexieService,
        private onlineService: OnlineService) {
    }

    findAll(): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + '/variableType/';

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));

        } else {
            return from(this.dexieService.variableTypes.toArray());
        }
    }

    findAllCalibrates(): Observable<any> {
        const url = environment.coreUrl + '/variableType/calibrates/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOne(idVariable: number): Observable<any> {
        const url = environment.coreUrl + `/variableType/${idVariable}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findUnits(idVariable: number): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + `/variableType/${idVariable}/units`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));

        } else {
            return from(this.dexieService.variableUnits.where({ idVariable }).toArray());
        }
    }

    findOneUnit(idUnit: number): Observable<any> {
        const url = environment.coreUrl + `/variableType/unit/${idUnit}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    convertUnit(idUnitFrom: number, idUnitTo: number): Observable<any> {
        const url = environment.coreUrl + `/variableType/unit/convert/unit/${idUnitFrom}/to/${idUnitTo}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    convert(fromNum: number, idUnitFrom: number, idUnitTo: number): Observable<any> {
        const url = environment.coreUrl + `/variableType/unit/convert/${fromNum}/unit/${idUnitFrom}/to/${idUnitTo}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    convertWithRadius(fromNum: number, radius: number, idUnitFrom: number, idUnitTo: number): Observable<any> {
        const url = environment.coreUrl + `/variableType/unit/convert/${fromNum}/radius/${radius}/unit/${idUnitFrom}/to/${idUnitTo}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllUnits(): Observable<any> {
        const url = environment.coreUrl + '/variableType/units/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    saveVariableTypeToDexie(): Promise<void> {
        return new Promise<void>((resolve) => this.findAll().subscribe((res: Variable[]) => {
            if (res) {
                void this.dexieService.variableTypes.clear().then(() => {
                    res.forEach(type => {
                        void this.dexieService.variableTypes.put(type).then(() => resolve());
                    });
                });
            }
        }));
    }

    saveVariableUnitToDexie(): Promise<void> {
        return new Promise<void>((resolve) => this.findAllUnits().subscribe((res: VariableUnit[]) => {
            if (res) {
                void this.dexieService.variableUnits.clear().then(() => {
                    res.forEach(type => {
                        void this.dexieService.variableUnits.put(type, type.id).then(() => resolve());
                    });
                });
            }
        }));
    }
}
