import { GenericFilter } from './genericClass';

export class RoleUser {
  username: string;

  idRole: number;
  roleName: string;
  roleTranslation: string;

  idGroup: number;
  groupName: string;

  idApp: number;
  appName: string;
}

export class RoleUserFilter extends GenericFilter {
  idGroup: number;
}

export enum RoleEnum {
  ADMIN = 1,
  MANAGER = 2,
  RESPONSABLE = 3,
  TECNICIAN = 5
}
