import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from '../../../external-components/angular-material.module';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ProtocolEditAttachmentsComponent } from './protocol-edit-attachments.component';
import { ProtocolEditAuditComponent } from './protocol-edit-audit.component';
import { ProtocolEditBioindicatorsDetailsComponent } from './protocol-edit-bioindicators-details.component';
import { ProtocolEditComponent } from './protocol-edit.component';
import { ProtocolEditConfirmSaveComponent } from './protocol-edit-dialog-confirmSave.component';
import { ProtocolEditCriticalInstrDialogComponent } from './protocol-edit-dialog-criticalInstr.component';
import { ProtocolEditDialogAutomaticSignComponent } from './protocol-edit-dialog-automatic-sign.component';
import { ProtocolEditDialogEditSensorsComponent } from './protocol-edit-dialog-edit-sensors.component';
import { ProtocolEditDialogManualSignComponent } from './protocol-edit-dialog-manual-sign.component';
import { ProtocolEditEquipmentDialogComponent } from './protocol-edit-dialog-equipment.component';
import { ProtocolEditEssayCriteriaComponent } from './protocol-edit-essay-criteria.component';
import { ProtocolEditEssayDialogComponent } from './protocol-edit-dialog-essay.component';
import { ProtocolEditGenerateReportComponent } from './protocol-edit-generate-report.component';
import { ProtocolEditPhotoDetailsComponent } from './protocol-edit-photo-details.component';
import { SharedModule } from '../../shared/shared.module';
import { ThermalModule } from '../thermal.module';

@NgModule({
    declarations: [
        ProtocolEditComponent,
        ProtocolEditEquipmentDialogComponent, ProtocolEditCriticalInstrDialogComponent, ProtocolEditEssayDialogComponent,
        ProtocolEditConfirmSaveComponent, ProtocolEditAuditComponent, ProtocolEditAttachmentsComponent,
        ProtocolEditGenerateReportComponent, ProtocolEditEssayCriteriaComponent, ProtocolEditBioindicatorsDetailsComponent,
        ProtocolEditDialogAutomaticSignComponent, ProtocolEditDialogManualSignComponent, ProtocolEditPhotoDetailsComponent,
        ProtocolEditDialogEditSensorsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ThermalModule,
        /* Custom modules */
        AngularMaterialModule,
        SharedModule,
        PipesModule,
        /* Otros */
        FlexLayoutModule
    ],
    exports: [
        ProtocolEditComponent, ProtocolEditEquipmentDialogComponent, ProtocolEditCriticalInstrDialogComponent,
        ProtocolEditEssayDialogComponent, ProtocolEditConfirmSaveComponent, ProtocolEditGenerateReportComponent,
        ProtocolEditEssayCriteriaComponent
    ]
})
export class ProtocolEditModule { }
