import { Component, Inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Sensor } from 'src/app/model/sensor';
import { ExecutionService } from 'src/app/services/execution.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';

export interface DialogDataConfirmSave {
  idExecution: number;
  idEssay: number;
  idEssayVariable: number;
  sensors: Sensor[];
}

@Component({
  selector: 'app-execution-edit-dialog-correct-sensors',
  templateUrl: './execution-edit-dialog-correct-sensors.component.html'
})
export class ExecutionEditDialogCorrectSensorsComponent {

  checks: Map<Sensor, boolean>;

  constructor(
    public dialogRef: MatDialogRef<ExecutionEditDialogCorrectSensorsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataConfirmSave,
    private executionService: ExecutionService,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    this.checks = new Map<Sensor, boolean>();

    const sensors = this.data.sensors;
    const allUnchecked = !ArrayUtils.isEmpty(sensors) && sensors.every(s => !s.corrected);

    sensors.forEach(s => this.checks.set(s, s.corrected || allUnchecked));
  }

  onOkClick(): void {
    this.spinnerService.show();

    const data = this.data;
    const checks: number[] = [];

    this.checks.forEach((ch: boolean, sen: Sensor) => {
      if (ch) {
        checks.push(sen.id);
      }
    })

    this.executionService.correctSensors(data.idExecution, data.idEssayVariable, checks).subscribe(() => {
      this.spinnerService.hide();
      this.snackBarService.sendSuccess(this.translate.instant('executionEdit.dialog.correctSensor.form.ok') as string);

      this.dialogRef.close(this.data);
    }, () => {
      this.spinnerService.hide();
      this.snackBarService.sendError(this.translate.instant('executionEdit.dialog.correctSensor.form.error.generic') as string);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isChecked(sensor: Sensor): boolean {
    return this.checks.get(sensor);
  }

  setChecked(sensor: Sensor, check: MatCheckboxChange): void {
    this.checks.set(sensor, check.checked);
  }

  setCheckedAll(check: MatCheckboxChange): void {
    this.checks.forEach((ch: boolean, sen: Sensor) => {
      this.checks.set(sen, check.checked)
    })
  }

}
