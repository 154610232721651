import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ProtocolAttachmentFilter, ProtocolQ, ProtocolQTestAttachment, ProtocolStatus } from '../../../../model/protocol';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '../../../../services/snackBar.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { forkJoin, merge, Observable, Subject, takeUntil, tap } from 'rxjs';
import { ArrayUtils } from '../../../../utils/arrayUtils';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { GenericClass } from '../../../../model/genericClass';
import { EditAttachmentEditComponent } from '../../../shared/edit-attachment-edit/edit-attachment-edit.component';
import * as saveAs from 'file-saver';
import { ProtocolQTestAttachmentDataSource } from '../../../../model/protocolQTestAttachmentDataSource';
import { AttachmentQualificatesService } from '../../../../services/attachmentQualificates.service';
import * as _ from 'lodash';
import { ProtocolQTestsService } from '../../../../services/protocol-qtests.service';

@Component({
  selector: 'app-protocol-q-edit-test-attachments',
  templateUrl: './protocol-q-edit-test-attachments.component.html',
  styleUrls: ['./protocol-q-edit-test-attachments.component.scss']
})
export class ProtocolEditTestAttachmentsComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @Input() disableOfflineButton: boolean;

  @ViewChild('attachmentUpload') attachmentUpload: ElementRef;

  dataSource: ProtocolQTestAttachmentDataSource;
  public displayedColumns = ['name', 'filename', 'date', 'type', 'edit', 'download', 'delete'];
  filter: ProtocolAttachmentFilter = new ProtocolAttachmentFilter();

  idTest: number;
  idProtocol: number;
  idStatus: number;

  private destroy$ = new Subject<void>();

  constructor(
    private protocolQTestsService: ProtocolQTestsService,
    private attachmentService: AttachmentQualificatesService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.dataSource = new ProtocolQTestAttachmentDataSource(this.protocolQTestsService);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort?.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadAttachments())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  setIdTest(id: number): void {
    this.idTest = id;

    this.loadAttachments();
  }

  setIdProtocol(id: number): void {
    this.idProtocol = id;
  }

  setIdStatus(id: number): void 
  {
    this.idStatus = id;
  }


  uploadAttachment(event): void {
    if (this.idTest == null) {
      this.snackBarService.sendError(this.translate.instant('protocolEdit.attachments.dialog.form.error.generic') as string);
    }

    const files = Array.from(event.target.files as FileList);

    const promises: Observable<void>[] = [];

    if (!ArrayUtils.isEmpty(files)) {
      this.spinnerService.show();
      files.forEach(file => promises.push(this.attachmentService.uploadAttachmentToTest(this.idProtocol, this.idTest, file) as Observable<void>));

      forkJoin(promises).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.loadAttachments();

        this.spinnerService.hide();

        this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.attachments.dialog.form.upload.ok') as string);
      }, () => {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('protocolEdit.attachments.dialog.form.upload.error.generic') as string);
      });
    }

    this.attachmentUpload.nativeElement.value = '';
  }

  drop(event: CdkDragDrop<ProtocolQTestAttachment[]>): void {
   // this.protocolService.moveAttachment(this.idTest, event.previousIndex, event.currentIndex)
    //  .subscribe(() => this.loadAttachments(), () => this.loadAttachments());
  }

  editAttachment(att: ProtocolQTestAttachment): void {
    this.attachmentService.getAllTypesForProtocolAir().pipe(takeUntil(this.destroy$)).subscribe((types: GenericClass[]) => {

      const dialogRef = this.dialog.open(EditAttachmentEditComponent, {
        minWidth: '50%',
        maxHeight: '95vh',
        data: {
          attachment: _.cloneDeep(att),
          types
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ProtocolQTestAttachment) => {
        if (result != null) {

          this.attachmentService.updateAttachmentToTest(att.id, this.idProtocol, this.idTest, result.idAttachment, result.idType, result.name)
            .pipe(takeUntil(this.destroy$)).subscribe(() => {
              this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.attachments.dialog.form.upload.ok') as string);
              this.loadAttachments();
            }, () => {
              this.snackBarService.sendError(this.translate.instant('protocolEdit.attachments.dialog.form.upload.error.generic') as string);
              this.loadAttachments();
            });
        }
      });
    });
  }

  downloadAttachment(idAtt: number, filename: string, idProtocol: number): void {
    this.spinnerService.show();
    this.attachmentService.downloadAttachmentToTest(idProtocol, idAtt).pipe(takeUntil(this.destroy$)).subscribe((item: Blob) => {
      saveAs(item, filename);

      this.spinnerService.hide();
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.generateReport.form.error.generic') as string);
    }
    );
  }

  deleteAttachment(idAtt: number, idProtocol: number): void {
    this.spinnerService.show();
   
    this.attachmentService.deleteAttachmentToTest(idProtocol, idAtt).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.loadAttachments();

      this.spinnerService.hide();

      this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.attachments.dialog.form.delete.ok') as string);
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('protocolEdit.attachments.dialog.form.delete.error.generic') as string);
    });
  }

  showUploadButton(): boolean {
    const statusFinalized = [ProtocolStatus.FIRMADO];

    return !statusFinalized.includes(this.idStatus);
  }

  private loadAttachments(): void {
    const id = this.idTest;

    if (id == null) {
      this.dataSource = null;
      return;
    }

    if (this.sort) {
      this.filter.sortBy = this.sort.active;
      this.filter.sortDirection = this.sort.direction || 'asc';
      this.filter.pageIndex = this.paginator.pageIndex || 0;
      this.filter.pageSize = this.paginator.pageSize || 5;
    }

    this.dataSource.loadProtocolAttachment(id, this.filter);
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

}
