import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';

import { of } from 'rxjs';
import { ExecutionAttachmentFilter, ExecutionQTestAttachment } from './execution';
import { ExecutionQTestsService } from '../services/execution-q-tests.service';

export class ExecutionQTestAttachmentDataSource implements DataSource<ExecutionQTestAttachment> {

    private attachmentSubject = new BehaviorSubject<ExecutionQTestAttachment[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public loading$ = this.loadingSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public totalItems$ = this.totalSubject.asObservable();

    constructor(private service: ExecutionQTestsService) { }

    connect(_collectionViewer: CollectionViewer): Observable<ExecutionQTestAttachment[]> {
        return this.attachmentSubject.asObservable();
    }

    disconnect(_collectionViewer: CollectionViewer): void {
        this.attachmentSubject.complete();
        this.loadingSubject.complete();
        this.totalSubject.complete();
    }

    loadExecutionAttachment(id: number, filter = new ExecutionAttachmentFilter()): void {

        this.loadingSubject.next(true);
        this.service.getAttachments(id, filter).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(data => {
            if (data && data.content) {
                this.attachmentSubject.next(data.content as ExecutionQTestAttachment[]);
                this.totalSubject.next(data.totalElements as number);
            } else if (data) {
                this.attachmentSubject.next(data as ExecutionQTestAttachment[]);
                this.totalSubject.next(data.length as number);
            } else {
                this.attachmentSubject.next([]);
                this.totalSubject.next(0);
            }
        });
    }
}
