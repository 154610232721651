import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ProtocolAir, ProtocolStatus } from 'src/app/model/protocol';
import { Subject } from 'rxjs';
import { User } from 'src/app/model/user';
import { ProtocolReportFilter } from 'src/app/model/attachment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService, LangFlag } from 'src/app/services/translation.service';
import { takeUntil } from 'rxjs/operators';
import { ProtocolUtils } from 'src/app/utils/protocolUtils';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ProtocolAirService } from 'src/app/services/protocolAir.service';

export interface DialogDataGenerateReport {
  protocol: ProtocolAir;
  toSign: boolean;
}

@Component({
  selector: 'app-protocol-edit-generate-report',
  templateUrl: './protocol-edit-generate-report.component.html'
})
export class ProtocolEditGenerateReportComponent implements OnInit, OnDestroy {

  respDocs: User[];
  reportConfig: ProtocolReportFilter;
  langs: LangFlag[];

  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<ProtocolEditGenerateReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataGenerateReport,
    private protocolService: ProtocolAirService,
    private usersService: UsersService,
    private translate: TranslateService,
    private translationService: TranslationService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    if (data.toSign == null) {
      data.toSign = false;
    }

  }

  ngOnInit(): void {
    this.spinnerService.show();

    this.reportConfig = new ProtocolReportFilter();
    this.reportConfig.lang = this.translationService.currentLang;
    this.reportConfig.toSign = this.data.toSign;

    this.langs = this.translationService.configuredLangs;

    this.usersService.findAllUserToSignFromGroup().pipe(takeUntil(this.destroy$)).subscribe((item: User[]) => {
      this.spinnerService.hide();
      this.respDocs = item;
    }, () => this.spinnerService.hide());

    if (this.data.protocol.idStatus === ProtocolStatus.FIRMADO) {
      this.spinnerService.show();

      this.protocolService.downloadPdfProtocolVersion(this.data.protocol).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        const name = ProtocolUtils.getNameForProtocolAir(this.data.protocol, this.translate);
        saveAs(res, name);
        this.spinnerService.hide();

        this.dialogRef.close();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.generateReport.form.error.notFound') as string);
        this.spinnerService.hide();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onOkClick(): void {
    this.spinnerService.show();
    this.protocolService.downloadPdf(this.data.protocol.id, this.reportConfig).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name = ProtocolUtils.getNameForProtocolAir(this.data.protocol, this.translate);
      saveAs(res, name);
      this.spinnerService.hide();

      this.dialogRef.close();
    }, () => {
      this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.generateReport.form.error.generic') as string);
      this.spinnerService.hide();
    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showChangeLangIcon(): boolean {
    return this.langs.length > 1;
  }

  onOkClickHybride(): void {
    this.spinnerService.show();
    // eslint-disable-next-line max-len
    this.protocolService.downloadPdfHybride(this.data.protocol.id, this.reportConfig).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name = ProtocolUtils.getNameForProtocolAir(this.data.protocol, this.translate);
      saveAs(res, name);
      this.spinnerService.hide();

      this.dialogRef.close();
    }, () => {
      this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.generateReport.form.error.generic') as string);
      this.spinnerService.hide();
    });

  }

}
