import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileInfo } from 'src/app/model/attachment';
import { Sensor } from 'src/app/model/sensor';
import { SensorData } from 'src/app/model/sensorData';
import { AttachmentCalibratesService } from 'src/app/services/attachmentCalibrates.service';
import { AttachmentThermalService } from 'src/app/services/attachmentThermal.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ExcelUtils } from 'src/app/utils/excelUtils';

export interface DialogDataAttachmentDetails {
  idSensor: number;
  idAttachment: number;
  accept: string;
  isPhoto: boolean;
  disableUpload: boolean;
  type: string;
  sensor: Sensor;
  idVariable: number;
}

@Component({
  selector: 'app-isothermal-characterization-edit-attachment-details',
  templateUrl: './isothermal-characterization-edit-attachment-details.component.html'
})
export class IsothermalCharacterizationEditAttachmentDetailsComponent implements OnInit, OnDestroy {

  fileData: FileInfo;

  private destroy$ = new Subject<void>();

  constructor(
    private attachmentThermalService: AttachmentThermalService,
    private attachmentCalibratesService: AttachmentCalibratesService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataAttachmentDetails,
    public dialogRef: MatDialogRef<IsothermalCharacterizationEditAttachmentDetailsComponent>,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.reloadAttachment();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  reloadAttachment(): void {
    this.spinnerService.show();

    this.fileData = new FileInfo();
    this.attachmentCalibratesService.getAttachmentInfoToSensorCharacterization(this.data.idSensor, this.data.idAttachment)
      .pipe(takeUntil(this.destroy$)).subscribe((res: FileInfo) => this.fileData = res);

    this.spinnerService.hide();
  }

  downloadAttachment(): void {
    this.spinnerService.show();

    this.attachmentCalibratesService.downloadAttachmentToSensorCharacterization(this.data.idSensor, this.data.idAttachment)
      .pipe(takeUntil(this.destroy$)).subscribe(res => {
        saveAs(res, this.fileData.filename);
        this.spinnerService.hide();
      }, error => {
        console.error(error);
        this.spinnerService.hide();
      });
  }

  deleteAttachment(): void {
    this.spinnerService.show();

    this.attachmentCalibratesService.deleteAttachmentToSensorCharacterization(this.data.idSensor, this.data.idAttachment)
      .pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.snackBarService.sendSuccess(this.translate.instant('isothermalCharacterizationEdit.essays.form.deleteGeneric.ok'));
        this.spinnerService.hide();
      }, error => {
        console.error(error);
        this.snackBarService.sendError(this.translate.instant('isothermalCharacterizationEdit.essays.form.deleteGeneric.error.generic'));
        this.spinnerService.hide();
      });
  }

  uploadFile(event): void {

    const file = event.target.files[0] as File;

    if (file == null) {
      return;
    }

    this.spinnerService.show();

    if (this.data.type === 'primary') {
      this.attachmentCalibratesService.uploadPrimaryFileToSensorCharacterization(this.data.idSensor, file).pipe(takeUntil(this.destroy$))
        .subscribe((item: number) => {
          this.spinnerService.hide();

          this.data.idAttachment = item;
          this.reloadAttachment();

          this.snackBarService.sendSuccess(this.translate.instant('isothermalCharacterizationEdit.essays.form.uploadGeneric.ok'));
        }, () => {
          this.spinnerService.hide();

          this.snackBarService.sendError(this.translate.instant(
            'isothermalCharacterizationEdit.essays.form.uploadGeneric.error.generic'));
        });
    } else if (this.data.type === 'excel') {

      ExcelUtils.excelToSensorData(file, this.attachmentThermalService, this.data.idVariable, false, 1).then((data: SensorData[]) => {

        data.forEach(item => {
          item.serialNum = this.data.sensor.serialNum;
          item.equipmentName = this.data.sensor.equipmentName;
          item.idEquipment = this.data.sensor.idEquipment;
        });

        data.forEach(item => delete item.posEquipment);

        this.data.sensor.data = data;

        data = data.filter(item => item.date != null && !isNaN(item.date.getTime()));

        this.attachmentCalibratesService.uploadExcelToSensorCharacterization(this.data.idSensor, file, data).pipe(takeUntil(this.destroy$))
          .subscribe((item: number) => {
            this.data.sensor.idExcel = item;

            this.data.idAttachment = item;
            this.reloadAttachment();

            this.spinnerService.hide();

            this.snackBarService.sendSuccess(this.translate.instant('isothermalCharacterizationEdit.essays.form.uploadExcel.ok'));
          }, () => {
            this.spinnerService.hide();

            this.snackBarService.sendError(this.translate.instant(
              'isothermalCharacterizationEdit.essays.form.uploadExcel.error.generic'));
          }
          );

      }).catch(err => {
        this.snackBarService.sendError(err);
        this.spinnerService.hide();
      });
    } else {
      this.snackBarService.sendError(this.translate.instant('isothermalCharacterizationEdit.essays.form.uploadGeneric.error.generic'));
      this.spinnerService.hide();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
