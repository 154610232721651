/* eslint-disable max-len */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExecutionReportFilter } from 'src/app/model/attachment';
import { ExecutionAir } from 'src/app/model/execution';
import { PendingSignStatusEnum } from 'src/app/model/pendingSignStatus';
import { PendingSignType, PendingSignTypeEnum } from 'src/app/model/pendingSignType';
import { ProtocolAir } from 'src/app/model/protocol';
import { SignInfo } from 'src/app/model/sign';
import { User } from 'src/app/model/user';
import { ExecutionAirService } from 'src/app/services/executionAir.service';
import { PendingSignTypeService } from 'src/app/services/pendingSignType.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LangFlag, TranslationService } from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';
import { UsersService } from 'src/app/services/users.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { DateUtils } from 'src/app/utils/dateUtils';
import { ExecutionAirUtils } from 'src/app/utils/executionAirUtils';
import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { ReasonDialogComponent } from '../../shared/reason-dialog/reason-dialog.component';
import { ExecutionEditGenerateReportComponent } from './execution-edit-generate-report.component';
import { ManageUsersService } from '../../../services/manageUsers.service';
import { GroupEnum } from '../../../utils/groupUtils';

export interface DialogDataAutomaticSignExecution {
  execution: ExecutionAir;
  protocol: ProtocolAir;
  forceAccording: boolean;
  reasonForce: string;
}

@Component({
  selector: 'app-execution-edit-dialog-automatic-sign',
  templateUrl: './execution-edit-dialog-automatic-sign.component.html'
})
export class ExecutionEditDialogAutomaticSignComponent implements OnInit, OnDestroy {
  respDocs: User[];
  respDocsFiltered: User[];

  reportConfig: ExecutionReportFilter;
  langs: LangFlag[];

  signTypes: PendingSignType[];
  signTypeChecked = new Map<number, boolean>();
  disableSignType = false;

  showLang = false;
  nextSign: string;

  currentUser: User;

  canRefuse = false;

  isFirstSign: boolean;
  selectedGroup: number;

  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<ExecutionEditDialogAutomaticSignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataAutomaticSignExecution,
    private executionService: ExecutionAirService,
    private userService: UserService,
    private usersService: UsersService,
    private manageUsersService: ManageUsersService,
    private pendingSignType: PendingSignTypeService,
    private translate: TranslateService,
    private translationService: TranslationService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    this.currentUser = this.userService.currentProfile;
  }

  ngOnInit(): void {
    this.spinnerService.show();
    const currentProfile = this.userService.currentProfile;

    this.selectedGroup = currentProfile.profiles.find(p => p.idGroup === currentProfile.idActiveProfile)?.idGroup;

    this.langs = this.translationService.configuredLangs;

    this.reportConfig = new ExecutionReportFilter();
    this.reportConfig.lang = this.translationService.currentLang;

    this.signTypes = [];
    this.pendingSignType.findAll().pipe(takeUntil(this.destroy$)).subscribe((r: PendingSignType[]) => this.signTypes = r);

    this.executionService.signInfo(this.data.execution).pipe(takeUntil(this.destroy$)).subscribe((inf: SignInfo[]) => {

      this.isFirstSign = inf.length === 0;

      inf.forEach(i => {
        i.date = DateUtils.anyToDate(i.date);
      });

      const anySign = !ArrayUtils.isEmpty(inf);
      let usersToRemove = [];

      this.signTypeChecked.set(PendingSignTypeEnum.REVIEW, true);
      this.signTypeChecked.set(PendingSignTypeEnum.APPROVED, false);

      if (!anySign) {
        this.showLang = true;
      } else {
        const anyApprove = anySign && inf.map(i => i.idType).includes(PendingSignTypeEnum.APPROVED);

        if (anyApprove) {
          this.disableSignType = true;
          this.signTypeChecked.set(PendingSignTypeEnum.REVIEW, false);
          this.signTypeChecked.set(PendingSignTypeEnum.APPROVED, true);
        }

        usersToRemove = inf.map(i => i.username);

        const lastDate = Math.max(...inf.filter(s => s.idStatus === PendingSignStatusEnum.PENDING).map(s => s.date.getTime()));
        const lastSign = inf.find(i => i.date.getTime() === lastDate);
        const isLastSign = lastSign != null && (lastSign.username === this.currentUser.username);

        if (anySign && usersToRemove.includes(this.currentUser.username) && !isLastSign) {
          this.snackBarService.sendError(this.translate.instant('executionEdit.dialog.generateReport.error.alreadySign') as string);
          this.onNoClick();
          this.spinnerService.hide();
          return;
        }

        if (anySign && !isLastSign) {
          this.snackBarService.sendError(this.translate.instant('executionEdit.dialog.generateReport.error.notLastSign') as string);
          this.onNoClick();
          this.spinnerService.hide();
          return;
        }

        this.canRefuse = isLastSign;
      }

      this.usersService.findAllUserToSignFromGroup().pipe(takeUntil(this.destroy$)).subscribe((item: User[]) => {
        const sorted = item.filter(u => !usersToRemove.includes(u.username) && u.username !== this.currentUser.username)
          .sort((v1, v2) => v1.fullName.localeCompare(v2.fullName));
        this.respDocs = sorted;
        this.respDocsFiltered = sorted;

        this.spinnerService.hide();
      });

    }, () => {
      this.spinnerService.hide();
      this.onNoClick();
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onNextSignChange(event: MatSelectChange): void {
    this.nextSign = event.value as string;
  }

  isSignTypeChecked(item: PendingSignType): boolean {
    return this.signTypeChecked.get(item.id) || false;
  }

  onChangeSignTypeCheck(event: MatCheckboxChange): void {
    const signType = event.source.value as unknown as PendingSignType;

    this.signTypeChecked.set(signType.id, event.checked);
  }

  getOkLabel(): string {
    let label = 'button.sign';

    if (this.nextSign != null) {
      label = 'button.sendSign';
    }

    return this.translate.instant(label) as string;
  }

  onOkClick(): void {
    this.spinnerService.show();

    const errors = [];

    const checks: number[] = [];

    this.signTypeChecked.forEach((value, key) => {
      if (value) {
        checks.push(key);
      }
    });

    if (ArrayUtils.isEmpty(checks)) {
      errors.push(this.translate.instant('executionEdit.dialog.generateReport.form.error.anySignReason'));
    }

    this.spinnerService.hide();

    if (errors.length !== 0) {
      this.snackBarService.sendError(errors.join('\n'));
      return;
    }

    const execution = this.data.execution;

    if (this.nextSign == null) {
      const message = this.translate.instant('executionEdit.dialog.generateReport.form.isLastSign') as string;

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

      this.spinnerService.hide();

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
        if (response === true) {
          if (execution.idClient) {
            this.manageUsersService.checkIfHasUser(execution.idClient).subscribe(hasUser => {
              if(!hasUser && this.selectedGroup === GroupEnum.QUALIPHARMA){
                this.snackBarService.sendError(this.translate.instant('manageUsers.dialog.clientHasUsers.error') as string);
              } else {
                if (this.isFirstSign) {
                  this.requestConfig(config => this.requestSign(token => this.defaultActionAutomaticSign(execution, token, checks, config)));
                } else {
                  this.requestSign(token => this.defaultActionAutomaticSign(execution, token, checks, this.reportConfig));
                }
              }
            });
          } else {
            if (this.isFirstSign) {
              this.requestConfig(config => this.requestSign(token => this.defaultActionAutomaticSign(execution, token, checks, config)));
            } else {
              this.requestSign(token => this.defaultActionAutomaticSign(execution, token, checks, this.reportConfig));
            }
          }
        } else {
          this.spinnerService.hide();
        }
      });

    } else {
      if (this.isFirstSign) {
        this.requestConfig(config => this.requestSign(token => this.defaultActionAddSign(execution, token, checks, config)));
      } else {
        this.requestSign(token => this.defaultActionAddSign(execution, token, checks, this.reportConfig));
      }
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  refuseSign(): void {
    this.requestReason((reason) => {
      this.requestSign((result) => {
        this.spinnerService.show();

        this.executionService.refuseToSign(this.data.execution, result, reason).pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.snackBarService.sendSuccess(this.translate.instant('executionEdit.dialog.generateReport.form.reject.ok') as string);

          this.spinnerService.hide();

          this.dialogRef.close(this.data);
        }, () => {
          this.snackBarService.sendError(this.translate.instant('executionEdit.dialog.generateReport.form.reject.error') as string);
          this.spinnerService.hide();
        });
      });
    });

  }

  showChangeLangIcon(): boolean {
    return this.langs.length > 1;
  }

  private requestSign(callback: (token: string) => void) {
    this.spinnerService.hide();

    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

  private requestConfig(callback: (config: ExecutionReportFilter) => void) {

    const dialogRef = this.dialog.open(ExecutionEditGenerateReportComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        execution: this.data.execution,
        protocol: this.data.protocol,
        reason: this.data.execution.reason,
        toSign: true,
        config: this.reportConfig,
        isForAutomaticSign: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionReportFilter) => {
      if (result != null) {
        this.data.execution.conclusions = result.conclusions;
        callback(result);
      }
    });
  }

  private requestReason(callback: (reason: string) => void) {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        title: 'executionAuditAction.refuseSign'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

  private defaultActionAddSign(execution: ExecutionAir, token: string, checks: number[], config: ExecutionReportFilter) {
    this.executionService.addSign(execution, token, checks, this.nextSign, config).pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.snackBarService.sendSuccess(this.translate.instant('executionEdit.dialog.generateReport.form.addSign.ok') as string);

        this.spinnerService.hide();

        this.dialogRef.close(this.data);
      }, () => {
        this.snackBarService.sendError(this.translate.instant('executionEdit.dialog.generateReport.form.addSign.error') as string);
        this.spinnerService.hide();
      });
  }

  private defaultActionAutomaticSign(ex: ExecutionAir, token: string, checks: number[], config: ExecutionReportFilter) {
    const forceAccording = this.data.forceAccording;
    const reasonForce = this.data.reasonForce;

    this.spinnerService.show();

    this.executionService.automaticSign(ex, token, forceAccording, reasonForce, checks, config).pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.snackBarService.sendSuccess(this.translate.instant('executionEdit.dialog.generateReport.form.sign.ok') as string);

        this.executionService.downloadPdfVersion(ex.id, ex.currentVersion).pipe(takeUntil(this.destroy$)).subscribe((file: Blob) => {
          const name = ExecutionAirUtils.getNameForReportPdf(ex, this.translate);
          saveAs(file, name);
          this.spinnerService.hide();
          this.dialogRef.close(this.data);
        });
      }, err => {
        if (err != null && err.error != null && typeof err.error === 'string') {
          this.snackBarService.sendError(err.error as string);
        } else {
          this.snackBarService.sendError(this.translate.instant('executionEdit.dialog.generateReport.form.error.generic') as string);
        }
        this.spinnerService.hide();
      });
  }

}
