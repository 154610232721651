import { Injectable } from '@angular/core';
import { InternalEquipmentCustomSelectValue } from '../model/internalEquipmentCustomSelectValue';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpUtils } from '../utils/httpUtils';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InternalEquipmentCustomSelectValueService {

  constructor(private http: HttpClient) { }

  save(value: InternalEquipmentCustomSelectValue[]): Observable<any> {
    const url = environment.thermalUrl + '/internalEquipmentCustomSelectValue/create';

    return this.http.post(url, value).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  findByIdField(idField: number): Observable<any> {
    const url = environment.thermalUrl +`/internalEquipmentCustomSelectValue/idField/${idField}`;

    return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

}
