import { HttpClient, HttpRequest } from '@angular/common/http';
import { StoredAction, StoredCacheData, StoredListData } from '../model/storedData';

import { Constants } from '../utils/constants';
import { DatabaseService } from './database.service';
import Dexie from 'dexie';
import { GenericFilter } from '../model/genericClass';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

const ttlDays = 30;

@Injectable()
export class RequestCacheService {

    tableCache: Dexie.Table<StoredCacheData, number>;
    tableList: Dexie.Table<StoredListData, number>;
    tableAction: Dexie.Table<StoredAction, number>;

    constructor(
        private databaseService: DatabaseService,
        private http: HttpClient) {
        this.tableCache = this.databaseService.table('cache');
        this.tableList = this.databaseService.table('cacheList');
        this.tableAction = this.databaseService.table('cacheAction');
    }

    // Metodos de la tabla cache

    getCache(key: string): Promise<any> {
        const promise = this.findCache(key);

        return promise.then(data => {
            if (!data) {
                return null;
            }

            const expires = data.expires;

            // Don't observe expired keys
            const now = new Date();
            if (expires && expires.getTime() < now.getTime()) {
                void this.tableCache.delete(data.id);
                return null;
            }

            return data.data;
        });

    }

    setCache(key: string, value: any[]): void {
        const expires = this.calculateExpirationDate();

        const store = new StoredCacheData();
        store.name = key;
        store.expires = expires;
        store.data = value;

        // TODO: Encontrar el existente y actualizarlo
        const promise = this.findCache(key);

        promise.then(data => {
            if (data) {
                void this.tableCache.delete(data.id);
            }
        }).finally(() => void this.tableCache.add(store));
    }

    deleteCache(key: string): void {
        const promise = this.findCache(key);

        void promise.then(data => {
            if (data) {
                void this.tableCache.delete(data.id);
            }
        });
    }

    // Metodos de la tabla cacheList

    getUnique(key: string, id: number): Promise<any> {
        const promise = this.findList(key);

        return promise.then(data => {
            if (!data) {
                return null;
            }

            return data.filter(item => item.idData === id).map(item => item.data)[0];
        });
    }

    addToListCache(key: string, value: any): void {
        const expires = this.calculateExpirationDate();

        const promise = this.findList(key);

        promise.then(res => {
            const data = res.find(item => item.idData === value.id);
            void this.tableList.update(data.id, { data: value, expires });
        }).catch(() => {
            const data = new StoredListData();
            data.type = key;
            data.expires = expires;
            data.idData = value.id;
            data.data = value;

            void this.tableList.add(data);
        });
    }

    getFiltered(key: string, filter: GenericFilter): Promise<any> {

        filter.sortBy = filter.sortBy || Constants.FIELD_ID;
        filter.sortDirection = filter.sortDirection || 'asc';
        filter.pageIndex = filter.pageIndex || 0;
        filter.pageSize = filter.pageSize || 5;

        const promise = this.findList(key);

        return promise.then(data => {
            if (!data) {
                return null;
            }

            data = data.filter(item => this.genericFilter(item, filter));

            /*
            let arr = data.slice(filter.pageIndex * filter.pageSize, (filter.pageIndex + 1) * filter.pageSize)
                .map(item => item.data);
            */

            const arr = data.map(item => item.data);

            return {
                content: arr,
                totalLength: arr.length
            };
        });
    }

    // Metodos de la tabla cacheAction

    addActionToCache(res: HttpRequest<any>): Promise<number> {
        const data = new StoredAction();

        data.date = new Date();
        data.request = res;

        return this.tableAction.add(data);
    }

    findAllActions(): Promise<StoredAction[]> {
        return new Promise((resolve) => resolve(this.tableAction.toArray()));
    }
    countPendingActions(): Promise<number> {
        return new Promise((resolve) => resolve(this.tableAction.count()));
    }

    executeActions(callback: () => void): void {
        void this.findAllActions().then(actions => {
            if (actions.length === 0) {
                callback();
            }

            actions.forEach((action, i) => {

                const lastIteration = i === actions.length - 1;

                const req = action.request;

                const method = req.method;
                const url = req.urlWithParams;
                const body = req.body;

                // Typescript es un lenguaje fuertemente tipado........
                const heh: any = req.headers;
                const headers = heh.headers;

                if (method === 'GET') {
                    this.http.get(url, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes))).subscribe(() => {
                        void this.tableAction.delete(action.id);

                        if (lastIteration) {
                            callback();
                        }
                    }, () => {
                        if (lastIteration) {
                            callback();
                        }
                    }
                    );
                } else if (method === 'POST') {
                    this.http.post(url, body, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes))).subscribe(() => {
                        void this.tableAction.delete(action.id);

                        if (lastIteration) {
                            callback();
                        }
                    }, () => {
                        if (lastIteration) {
                            callback();
                        }
                    }
                    );
                } else if (method === 'PUT') {
                    this.http.put(url, body, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes))).subscribe(() => {
                        void this.tableAction.delete(action.id);

                        if (lastIteration) {
                            callback();
                        }
                    }, () => {
                        if (lastIteration) {
                            callback();
                        }
                    }
                    );
                } else if (method === 'DELETE') {
                    this.http.delete(url, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes))).subscribe(() => {
                        void this.tableAction.delete(action.id);

                        if (lastIteration) {
                            callback();
                        }
                    }, () => {
                        if (lastIteration) {
                            callback();
                        }
                    });
                }
            });
        }
        );
    }

    // Metodos privados

    private calculateExpirationDate() {
        const expires = new Date();
        expires.setDate(expires.getDate() + ttlDays);

        return expires;
    }

    private findCache(key: string): Promise<StoredCacheData> {
        return new Promise((resolve, reject) => void this.tableCache.where('name').equalsIgnoreCase(key).first()
            .then(data => resolve(data)).catch(err => reject(err)));
    }

    private findList(key: string): Promise<StoredListData[]> {
        return new Promise((resolve, reject) => void this.tableList.where('type').equalsIgnoreCase(key).sortBy(Constants.FIELD_ID)
            .then(data => resolve(data)).catch(err => reject(err)));
    }

    private genericFilter(item: StoredListData, filter: GenericFilter): boolean {

        const now = new Date();
        if (item.expires && item.expires.getTime() < now.getTime()) {
            return false;
        }

        return true;
    }

}
