import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProtocolAir } from 'src/app/model/protocol';
import { ProtocolAirService } from 'src/app/services/protocolAir.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { ProtocolEditGenerateReportComponent } from './protocol-edit-generate-report.component';

export interface DialogDataManualSignProtocol {
  protocol: ProtocolAir;
  showReason: boolean;
}

@Component({
  selector: 'app-protocol-edit-dialog-manual-sign',
  templateUrl: './protocol-edit-dialog-manual-sign.component.html'
})
export class ProtocolEditDialogManualSignComponent implements OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<ProtocolEditDialogManualSignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataManualSignProtocol,
    private protocolService: ProtocolAirService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    if (data.showReason == null) {
      data.showReason = false;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  downloadDocument(): void {
    this.dialog.open(ProtocolEditGenerateReportComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {
        protocol: this.data.protocol,
        toSign: true
      }
    });
  }

  onOkClick(event): void {
    const file = event.target.files[0] as File;

    const dialogRefPassword = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRefPassword.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
      if (token != null) {
        this.spinnerService.show();

        this.protocolService.manualSign(this.data.protocol, file, token).pipe(takeUntil(this.destroy$)).subscribe(() => {

          this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.sign.ok') as string);

          this.spinnerService.hide();

          this.dialogRef.close(this.data);
        }, () => {
          this.snackBarService.sendError(this.translate.instant('protocolEdit.form.sign.error') as string);

          this.spinnerService.hide();
        });
      } else {
        this.spinnerService.hide();
      }

    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
