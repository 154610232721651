import { Component, OnDestroy, OnInit } from '@angular/core';

import { AppTypeEnum } from 'src/app/model/appType';
import { OnlineService } from 'src/app/services/online.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Subject } from 'rxjs';
import { ThemeService } from 'src/app/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { RoleUser } from '../../model/roleUser';
import { GlobalStateService } from '../../services/globalState.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  timestampt: string;

  user = new User();

  selectedGroup: number;
  selectedApp: number;
  roles: RoleUser[];
  selectedComponent: string;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private onlineService: OnlineService,
    private themeService: ThemeService,
    private userService: UserService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    private globalStateService: GlobalStateService) {
      const currentProfile = userService.currentProfile;

      this.roles = currentProfile.profiles;
      this.selectedGroup = currentProfile.profiles
        .find(p => p.idGroup === currentProfile.idActiveProfile)?.idGroup;
    }

  ngOnInit(): void {
    localStorage.removeItem('filter');
    localStorage.removeItem('customFields');
    this.timestampt = environment.timeStamp;

    this.user = this.userService.currentProfile;

    this.globalStateService.selectedComponent$.subscribe((component) => {
      this.selectedComponent = component;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goToPage(page: string): void {
    void this.router.navigateByUrl(page);
  }

  downloadData(): void {
    this.spinnerService.show();

    this.onlineService.downloadCacheableData().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.snackBarService.sendSuccess(this.translate.instant('home.form.downloadDataOffline.ok') as string);

      this.spinnerService.hide();
    }, () => {
      this.snackBarService.sendError(this.translate.instant('home.form.downloadDataOffline.error') as string);

      this.spinnerService.hide();
    });
  }

  showExternalEquipments(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }

    return currentUser.showExternalEquipments;
  }

  showThermal(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }

    return currentUser.idActiveApp === AppTypeEnum.THERMAL;
  }

  showCalibrates(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }

    return currentUser.idActiveApp === AppTypeEnum.CALIBRATES;
  }

  showAir(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser === null || currentUser === undefined) {
      return false;
    }

    return currentUser.idActiveApp === AppTypeEnum.AIR;
  }

  showCharacterization(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }

    return currentUser.showCharacterization && (this.showThermal() || this.showCalibrates());
  }

  getLogo(): string {
    return 'assets/img/' + this.themeService.getLogo();
  }

  getText(): string {
    return this.themeService.getText();
  }

  getThemeIconPath(): string {
    return this.themeService.getThemeIconPath();
  }

  newProtocol(): void {
    void this.router.navigateByUrl('/protocol?id=0');
  }

  newExecutions(): void {
    void this.router.navigateByUrl('/execution?id=0');
  }

  newProtocolAir(): void {
    void this.router.navigateByUrl('/air/protocol?id=0');
  }

  newExecutionsAir(): void {
    void this.router.navigateByUrl('/air/execution?id=0');
  }

  newInternalEquipment(): void {
    void this.router.navigateByUrl('/internalEquipment?id=0');
  }

  newExternalEquipment(): void {
    void this.router.navigateByUrl('/externalEquipment?id=0');
  }

  newCalibration(): void {
    void this.router.navigateByUrl('/calibration?id=0');
  }

  newIsothermalCharacterization(): void {
    void this.router.navigateByUrl('/isothermalCharacterization?id=0');
  }
}
