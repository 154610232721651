import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentAutocompleteFilter } from 'src/app/model/autocompleteFilter';
import { InternalEquipment } from 'src/app/model/internalEquipment';

import { InternalEquipmentFolder } from 'src/app/model/internalEquipmentFolder';
import { InternalEquipmentService } from 'src/app/services/internalEquipment.service';
import { InternalEquipmentFolderService } from 'src/app/services/internalEquipmentFolder.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';

export interface DialogDataFolder {
  folder: InternalEquipmentFolder;
  isEdit: boolean;
}

@Component({
  selector: 'app-calibration-plan-edit-folder',
  templateUrl: './calibration-plan-edit-folder.component.html'
})
export class CalibrationPlanEditFolderComponent implements OnInit {

  tituloDialog: string;
  filteredEquipments: InternalEquipment[];
  filteredEquipmentFolders: InternalEquipmentFolder[];

  public checked: Map<number, boolean> = new Map();

  constructor(
    public snackBarService: SnackBarService,
    private internalEquipmentService: InternalEquipmentService,
    private internalEquipmentFolderService: InternalEquipmentFolderService,
    private spinnerService: SpinnerService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<CalibrationPlanEditFolderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataFolder) {
    if (data.isEdit) {
      this.tituloDialog = this.translate.instant('protocolEdit.dialog.area.title.edit', { name: data.folder.name }) as string;
    } else {
      this.tituloDialog = this.translate.instant('calibrationPlan.folderInternalEquipment.dialog.create.title') as string;
    }
  }

  ngOnInit(): void {
    this.spinnerService.show();
    const filter = new EquipmentAutocompleteFilter();

    // TODO: pasar a conts
    filter.pageIndex = 0;
    filter.pageSize = 20000;
    filter.sortBy = 'name';
    filter.sortDirection = 'asc';
    filter.internalEquipment = true;
    filter.forExecution = true;
    filter.allStatus = true;

    this.internalEquipmentService.findAutocomplete(filter).subscribe(res => {
      this.spinnerService.hide();
      this.filteredEquipments = res.content as InternalEquipment[];
    });

    const filter2 = new EquipmentAutocompleteFilter();
    // TODO: pasar a conts
    filter.pageIndex = 0;
    filter.pageSize = 20000;
    filter.sortBy = 'name';
    filter.sortDirection = 'asc';

    this.internalEquipmentFolderService.findAll(filter2)
      .subscribe(res => this.filteredEquipmentFolders = res.content as InternalEquipmentFolder[]);

    this.data.folder.equipments?.forEach(eq => this.checked.set(eq, true));
  }

  onOkClick(): void {
    this.spinnerService.show();
    const errs: string[] = [];

    const checks: number[] = [];

    this.checked.forEach((value, key) => {
      if (value) {
        checks.push(key);
      }
    });

    if (this.data.folder.name == null) {
      errs.push(this.translate.instant('protocolEdit.dialog.area.name.error') as string);
    }

    if (errs.length === 0) {
      this.data.folder.equipments = checks;
      if (this.data.folder.id != null) {
        this.internalEquipmentFolderService.update(this.data.folder).subscribe(() => {
          this.spinnerService.hide();
          this.dialogRef.close(this.data.folder);
        });
      } else {
        this.internalEquipmentFolderService.save(this.data.folder).subscribe(() => {
          this.spinnerService.hide();
          this.dialogRef.close(this.data.folder);
        });
      }
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  lookupEquipment($event): void {
    let results = this.filteredEquipments;

    const filter = new EquipmentAutocompleteFilter();

    filter.query = $event.target.value as string;
    filter.pageIndex = 0;
    filter.pageSize = 20000;
    filter.sortBy = 'name';
    filter.sortDirection = 'asc';
    filter.forExecution = true;
    filter.allStatus = true;
    
    this.internalEquipmentService.findAutocomplete(filter).pipe().subscribe(item => {
      if (item.content != null) {
        results = item.content as InternalEquipment[];
      } else {
        results = item as InternalEquipment[];
      }

      this.filteredEquipments = results;
    }, () => {
      results = [];
      this.filteredEquipments = results;
    });
  }

  isEquipmentChecked(item: InternalEquipment): boolean {
    return this.checked.get(item.id) || false;
  }

  onChangeEquipmentCheck(event: MatCheckboxChange): void {
    const eq = event.source.value as unknown as InternalEquipment;

    this.checked.set(eq.id, event.checked);
  }
}
