import { GenericClassTranslate } from './genericClass';

export class EssayType extends GenericClassTranslate {
    translated: string; // ESTE OBJETO ES SOLO DE FRONT. NO VIENE DE BACK.

    idVariable: number;
}

export enum EssayAirTypeEnum {
    DEF_PRESSURE = 1,
    AIR_SPEED = 2,
    HEPA_FILTER_INTEGRITY = 4,
    REN_FLOW_AND_RATE = 5,
    PARTICLE_COUNT_REPOSE = 6,
    PARTICLE_COUNT_WORKING = 7,
    WASTE_OF_LOAD = 8,
    SMOKE_ESSAY_REPOSE = 9,
    SMOKE_ESSAY_WORKING = 10,
    TEMPERATURE = 11,
    HUMIDITY = 12,
    LIGHT = 13,
    NOISE = 14,
    RECOVERY_ESSAY = 15,
    UV_INTENSITY = 16,
    INDUCTION_ESSAY = 17,
    ELECTRIC_ESSAY = 18,
    FILTER_SATURATION = 19,
    PARALLELISM_TEST = 20,
    MICROBIO_ESSAY = 21,
    FORMOLIZATIONEQUIPMENT = 22,
    HYDROCARBON_LEVEL = 23,
    DEW_POINT = 24,
    LINE_PRESSURE = 25,
    CO2_CONCENTRATION = 26,
    OTHERS = 27,
    DECONTAMINATION = 28,
    CO_CONCENTRATION = 29,
    NO_CONCENTRATION = 30,
    NO2_CONCENTRATION = 31,
    PARTICLE_COUNT = 32,
    ANEXOI_COUNT_REPOSE = 33,
    ANEXOI_COUNT_WORKING = 34,
    ANEX_COUNT = 35,
}
