<form>
  <div mat-dialog-content class="air-theme">

    <div fxLayout="row" class="flexwrap">
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'protocolEdit.dialog.equipment.type.label' | translate }}</mat-label>
          <mat-select name="idType" [(ngModel)]="data.room.idType" required>
            <mat-option *ngFor="let item of this.data.roomTypes" [value]="item.id">
              {{ 'equipmentType.' + item.translation | translate }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.type.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput name="name" [(ngModel)]="data.room.name" required
            placeholder="{{ 'protocolEdit.dialog.equipment.name.label' | translate }}">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.type.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" class="flexwrap" *ngIf="data.isExecution && data.room.fromProtocol">
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'protocolEdit.dialog.equipment.type.label' | translate }} {{ 'common.inProtocol' | translate }}
          </mat-label>
          <mat-select name="idTypeProtocol" [ngModel]="data.room.idTypeProtocol" disabled>
            <mat-option *ngFor="let item of this.data.roomTypes" [value]="item.id">
              {{ 'equipmentType.' + item.translation | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput name="nameProtocol" [ngModel]="data.room.nameProtocol" disabled
            placeholder="{{ 'protocolEdit.dialog.equipment.name.label' | translate }} {{ 'common.inProtocol' | translate }}">
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="column" class="flexwrap" *ngIf="data.isExecution && data.room.fromProtocol && showReason()">
      <div fxFlex>
        <mat-form-field class="campoFormulario">
          <textarea matInput name="reasonChange" [(ngModel)]="data.room.reasonChange" required
            placeholder="{{ 'protocolEdit.dialog.confirmSave.reason.label' | translate }}">
            <mat-error>
              {{ 'common.reasonChangeError' | translate }}
            </mat-error>
          </textarea>
        </mat-form-field>
      </div>
      <div fxFlex>
        <mat-checkbox name="showDiffInDeviations" [(ngModel)]="data.room.showDiffInDeviations">
          {{ 'executionEdit.sensorConfig.showDiffInDeviations.label' | translate }}
        </mat-checkbox>
      </div>
      <div fxFlex>
        <mat-checkbox name="showDiffInObservations" [(ngModel)]="data.room.showDiffInObservations">
          {{ 'executionEdit.sensorConfig.showDiffInObservations.label' | translate }}
        </mat-checkbox>
      </div>
    </div>

    <div fxLayout="row" class="flexwrap">

      <mat-toolbar color="secondary">
        {{ 'protocolEdit.dialog.essay.volume.label' | translate }}

        <button mat-icon-button color="accent" (click)="newValue()">
          <mat-icon>add</mat-icon>
        </button>
      </mat-toolbar>

      <div fxLayout="column">
        <div fxFlex *ngFor="let volume of data.room?.volumes; let indexValue = index">
          <div fxLayout="row">
            <div>
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'protocolEdit.dialog.room.surface.label' | translate }}"
                  name="surface{{indexValue}}" type="number" [(ngModel)]="volume.surface" required min="0">
                <mat-error>
                  {{ 'protocolEdit.dialog.room.surface.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'protocolEdit.dialog.room.height.label' | translate }}"
                  name="height{{indexValue}}" type="number" [(ngModel)]="volume.height" required min="0">
                <mat-error>
                  {{ 'protocolEdit.dialog.room.height.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'protocolEdit.dialog.room.volume.label' | translate }}"
                  name="volume{{indexValue}}" type="number" [value]="showVolume(volume) | number:'1.1-1'" disabled>
              </mat-form-field>
            </div>

            <div>
              <button mat-icon-button color="accent" (click)="removeVolume(indexValue)"
                *ngIf="data.room.volumes.length > 1">
                <mat-icon>clear</mat-icon>
              </button>
            </div>

          </div>
        </div>

      </div>

      <br />

      <mat-toolbar color="secondary">
        {{ 'protocolEdit.dialog.room.filters' | translate }}

        <button mat-icon-button color="accent" (click)="openNewFilter()">
          <mat-icon>add</mat-icon>
        </button>
      </mat-toolbar>
      <div fxLayout="row" class="flexwrap" style="width: 100%;">
        <div fxFlex>
          <table mat-table matSort #filtersTable [dataSource]="data.room.filters">

            <!-- order Column -->
            <ng-container matColumnDef="order">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'protocolEdit.dialog.room.order' | translate }}
              </th>
              <td mat-cell *matCellDef="let item; let index = index"> {{ getOrderFilter(index) }} </td>
            </ng-container>

            <!-- filter Column -->
            <ng-container matColumnDef="filter">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'protocolEdit.dialog.room.filter' | translate }}
              </th>
              <td mat-cell *matCellDef="let item; let index = index"> {{ getFilterName(index) | translate }} </td>
            </ng-container>

            <!-- size Column -->
            <ng-container matColumnDef="size">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'protocolEdit.dialog.room.size' | translate }}
              </th>
              <td mat-cell *matCellDef="let item"> {{ getSizeOfFilter(item) }} </td>
            </ng-container>

            <!-- changeFilter Column -->
            <ng-container matColumnDef="changeFilter">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'protocolEdit.dialog.filter.changeFilter' | translate }}
              </th>
              <td mat-cell *matCellDef="let item"> {{ getChangeFilter(item) }} </td>
            </ng-container>

            <!-- Button edit Column -->
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
              <td mat-cell *matCellDef="let element; let filterIndex = index">
                <button mat-icon-button (click)="editFilter(filterIndex)">
                  <mat-icon>mode_edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <!-- Button delete Column -->
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
              <td mat-cell *matCellDef="let element; let filterIndex = index">
                <button mat-icon-button (click)="removeFilter(filterIndex)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColsFilter"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColsFilter;"></tr>

          </table>

        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>