<div class="main-container small-layout">
  <form [formGroup]="userMainForm">

    <div class="toolbar-list">
      <div class="toolbar-list-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <h1 *ngIf="isEdit">{{ 'common.edit' | translate }} {{ user.username }}</h1>
          <h1 *ngIf="!isEdit">{{ 'userEdit.newUser.label' | translate }}</h1>
        </div>
        <div class="breadcrumbs">
          <a href="#">{{ 'common.start.label' | translate }}</a> /
          <a routerLink="/admin/manageUsers">{{ 'manageUsers.title' | translate }}</a> /
          <span *ngIf="isEdit">{{ user.username }}</span>
          <span *ngIf="!isEdit">{{ 'userEdit.newUser.label' | translate }}</span>
        </div>
      </div>
      <div class="toolbar-back-button">
        <a (click)="cancel()" mat-button>
          <mat-icon>undo</mat-icon> {{ 'button.back' | translate }}
        </a>
      </div>
    </div>

    <mat-card class="card">
      <mat-card-content>
        <div class="form-block">
          <div class="form-icon">
            <mat-icon>format_size</mat-icon>
          </div>
          <div class="form-content">

            <div fxFlex="50%" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'userEdit.fullName.label' | translate }}" formControlName="fullName"
                  [(ngModel)]="user.fullName" required [errorStateMatcher]="matcher">
                <mat-error>
                  {{ 'userEdit.fullName.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'userEdit.username.label' | translate }}" formControlName="username"
                  [(ngModel)]="user.username" [errorStateMatcher]="matcher">
                <mat-error *ngIf="userMainForm.get('username').hasError('required')">
                  {{ 'userEdit.username.error' | translate }}
                </mat-error>
                <mat-error *ngIf="userMainForm.get('username').hasError('pattern')">
                  {{ 'userEdit.username.pattern' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput type="email" placeholder="{{ 'userEdit.email.label' | translate }}"
                  formControlName="email" [(ngModel)]="user.email" required [errorStateMatcher]="matcher">
                <mat-error *ngIf="userMainForm.get('email').hasError('required')">
                  {{ 'userEdit.email.error' | translate }}
                </mat-error>
                <mat-error *ngIf="userMainForm.get('email').hasError('pattern')">
                  {{ 'userEdit.email.pattern' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%" *ngIf="!isEdit">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'userEdit.password.label' | translate }}" formControlName="password"
                  [(ngModel)]="user.password" [errorStateMatcher]="matcher">
                <mat-error *ngIf="userMainForm.get('password').hasError('required')">
                  {{ 'userEdit.password.error' | translate }}
                </mat-error>
                <mat-error *ngIf="userMainForm.get('password').hasError('pattern')">
                  {{ 'userEdit.password.pattern' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'userEdit.position.label' | translate }}" formControlName="position"
                  [(ngModel)]="user.position">
              </mat-form-field>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%" *ngIf="isEdit">
              <mat-slide-toggle class="example-margin" color="primary" [checked]="user.active" [(ngModel)]="user.active"
                formControlName="active">
                {{ 'userEdit.active.label' | translate }}
              </mat-slide-toggle>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%" class="top-padding-half" *ngIf="isEdit">
              <button mat-raised-button (click)="caducatePasswordUser()" class="stroked-primary">{{
                'button.resetPasswordUser' | translate }}</button>
            </div>

          </div>
          <div class="side-block"></div>
        </div>

        <div class="form-block" *ngIf="isEdit || user.idCertificate">
          <div class="form-icon">
            <mat-icon>assignment</mat-icon>
          </div>
          <div class="form-content">

            <div fxFlex="50%" fxFlex.lt-md="100%" class="top-padding-half" *ngIf="isEdit">
              <button mat-raised-button (click)="uploadCertificate.click()" class="stroked">
                <mat-icon>cloud_upload</mat-icon> {{ 'button.upload-certificate' | translate }}
              </button>
              <input accept="application/pdf" hidden type="file" (change)="uploadCertificateToUser($event)"
                #uploadCertificate>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%" class="top-padding-half" *ngIf="user.idCertificate">
              <button mat-raised-button (click)="downloadCertificatePdf()" class="stroked-primary">
                <mat-icon>cloud_download</mat-icon> {{ 'button.download-certificate' | translate }}
              </button>
            </div>

          </div>
          <div class="side-block"></div>
        </div>

        <div class="form-block">
          <div class="form-icon">
            <mat-icon>supervisor_account</mat-icon>
          </div>
          <div class="table-content">

            <h2>{{ 'userEdit.roles.title' | translate }}

              <button mat-icon-button (click)="openRolesDialog()">
                <mat-icon>add</mat-icon>
              </button>
            </h2>


            <div class="table-container">
              <table #rolesTable mat-table matSort [dataSource]="roles" *ngIf="roles.length > 0">

                <!-- groupName Column -->
                <ng-container matColumnDef="groupName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'userEdit.roles.cols.groupName' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let item"> {{ item.groupName }} </td>
                </ng-container>

                <!-- appName Column -->
                <ng-container matColumnDef="appName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'userEdit.roles.cols.appName' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let item"> {{ item.appName }} </td>
                </ng-container>

                <!-- role Column -->
                <ng-container matColumnDef="role">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'userEdit.roles.cols.role' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let item"> {{ 'role.' + item.roleTranslation | translate }}</td>
                </ng-container>

                <!-- Button delete Column -->
                <ng-container matColumnDef="delete">
                  <th mat-header-cell *matHeaderCellDef fxHide.lt-sm> {{ 'button.delete' | translate }} </th>
                  <td mat-cell *matCellDef="let element; let i = index" fxHide.lt-sm class="action">
                    <button mat-icon-button (click)="removeRole(i)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <!-- Button more Column -->
                <ng-container matColumnDef="more" stickyEnd>
                  <th mat-header-cell *matHeaderCellDef fxHide fxShow.lt-sm></th>
                  <td mat-cell *matCellDef="let element; let i = index" fxHide fxShow.lt-sm class="action">

                    <mat-menu #appMenu="matMenu" class="moreActions">
                      <ng-template matMenuContent let-name="element">
                        <button mat-icon-button (click)="removeRole(i)">
                          <mat-icon>delete</mat-icon> {{ 'button.delete' | translate }}
                        </button>
                      </ng-template>
                    </mat-menu>

                    <button mat-icon-button [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="element">
                      <mat-icon>more_horiz</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColsRoles"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColsRoles;"></tr>
              </table>
            </div>

          </div>
        </div>

        <div class="form-block" *ngIf="isEdit && user.lockeds?.length > 0">
          <div class="form-icon">
            <mat-icon>block</mat-icon>
          </div>
          <div class="form-content">

            <div fxFlex="50%" fxFlex.lt-md="100%" class="top-padding">
              Bloqueos del usuario:
              <ul>
                <li *ngFor="let lock of user.lockeds">IP: {{ lock.ip }}: Hasta: {{ lock.until | fixDateTime }}</li>
              </ul>
            </div>

            <div fxFlex="50%" fxFlex.lt-md="100%" class="top-padding-half">
              <button mat-raised-button (click)="unlockUser()">{{ 'button.unlockUser' | translate
                }}</button>
            </div>

          </div>
          <div class="side-block"></div>
        </div>

      </mat-card-content>

      <mat-card-actions>
        <div fxLayoutGap="16px" fxLayoutAlign="start center">
          <button mat-raised-button color="primary" (click)="saveUser()">{{ 'button.save' | translate }}</button>
          <button mat-raised-button color="secondary" (click)="cancel()">{{ 'button.cancel' | translate }}</button>
        </div>
      </mat-card-actions>
    </mat-card>

    <div class="table-content table-spaced" *ngIf="isEdit">

      <h2>{{ 'userEdit.loginAccess.title' | translate }}</h2>

      <table #loginAccessTable mat-table matSort #loginAccessSort="matSort" [dataSource]="loginAccessDataSource">

        <!-- date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'userEdit.loginAccess.cols.date' | translate }}
          </th>
          <td mat-cell *matCellDef="let item"> {{ item.date | fixDateTime }} </td>
        </ng-container>

        <!-- ip Column -->
        <ng-container matColumnDef="ip">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'userEdit.loginAccess.cols.ip' | translate }}
          </th>
          <td mat-cell *matCellDef="let item"> {{item.ip}}</td>
        </ng-container>

        <!-- correct Column -->
        <ng-container matColumnDef="correct">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'userEdit.loginAccess.cols.correct' | translate }}
          </th>
          <td mat-cell *matCellDef="let item">
            {{ (item.correct) ? ('common.yes' | translate) : ('common.no' | translate) }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColsLoginAccess"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColsLoginAccess;"></tr>

      </table>

      <div class="table-footer-container">
        <div class="actions">
          <button mat-raised-button (click)="downloadAuditPdf()" class="stroked-primary">
            <mat-icon fxHide.lt-sm>cloud_download</mat-icon> {{ 'button.downloadPdf' | translate }}
          </button>
          <a [routerLink]="['.']" [fragment]="'table-container'" class="back-to-top">
            {{ 'button.backToTop' | translate }} </a>
        </div>
        <div class="paginator">
          <mat-paginator #loginAccessPaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
            [length]="loginAccessDataSource.totalItems$ | async" lang="es">
          </mat-paginator>
        </div>
      </div>
    </div>

  </form>
</div>