import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentQualificates } from 'src/app/model/equipment';
import { GenericClass } from 'src/app/model/genericClass';
import { EquipmentTypeQualificatesService } from 'src/app/services/equipmentTypeQualificates.service';
import { QualificationUtils } from '../../../../utils/qualificationUtils';

export interface DialogDataEquipment {
  equipment: EquipmentQualificates;
  isEdit: boolean;
}

@Component({
  selector: 'app-protocol-q-edit-equipment-dialog',
  templateUrl: './protocol-q-edit-equipment-dialog.component.html'
})
export class ProtocolEditEquipmentDialogComponent implements OnInit {

  tituloDialog: string;
  form: FormGroup;
  
  equipmentTypes: GenericClass[];

  constructor(
    fb: FormBuilder,
    private translate: TranslateService,
    private equipmentTypeQualificatesService: EquipmentTypeQualificatesService,
    private dialogRef: MatDialogRef<ProtocolEditEquipmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataEquipment
  ) {
    if (data.isEdit) {
      // eslint-disable-next-line max-len
      this.tituloDialog = this.translate.instant('protocolEdit.dialog.equipment.title.edit', { name: data.equipment.serialNumber }) as string;
    } else {
      this.tituloDialog = this.translate.instant('protocolEdit.dialog.equipment.title.create') as string;
    }

    this.form = fb.group({
      equipment: [this.data.equipment, [Validators.required]],
      maker: [this.data.equipment.maker],
      model: [this.data.equipment.model],
      serialNum: [this.data.equipment.serialNumber],
      idType: [this.data.equipment.idType, [Validators.required]],
      rotorSerial: [this.data.equipment.rotorSerial],
      rotorModel: [this.data.equipment.rotorModel],
      location: [this.data.equipment.location, [Validators.required]],
      internalId: [this.data.equipment.internalId]
    });
    this.equipmentTypeQualificatesService.findAll().subscribe((res: GenericClass[]) => {
      this.equipmentTypes = res;
    });
  }

  ngOnInit(): void {
    this.data.equipment.idType = QualificationUtils.EQUIPMENT_TYPE;
  }

  onOkClick(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.data.equipment);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
