import { Domain } from '../model/domain';
import { Field } from '../model/field';
import { StringUtils } from './stringUtils';
import { TranslateService } from '@ngx-translate/core';

export class FieldUtils {

    static separator = Field.separator;

    static validateField(field: Field): boolean {
        let res = true;
        let validTime = false;
        if (FieldUtils.isMultifield(field)) {
            if (field.value == null) {
                res = false;
            } else if (Array.isArray(field.value)) {
                if (field.value.length < field.noValuesRequired) {
                    res = false;
                } else {
                    let anyNotZero = false;
                    for (const value of field.value) {
                        validTime = false;
                        if (StringUtils.isEmpty(value as string)) {
                            res = false;
                            break;
                        } else {
                            anyNotZero = anyNotZero || value !== '0';
                            validTime = validTime || value >= '0';
                        }
                        res = res && validTime;
                    }
                    res = res && anyNotZero;

                }
            }
        } else {
            if (StringUtils.isEmpty(field.value as string)) {
                res = false;
            }
        }

        return res;
    }

    static isMultifield(field: Field): boolean {
        return field.isMultifield();
    }

    static objectToField(item: Field | any): Field {

        if (item instanceof Field) {
            return item;
        } else {
            const f = new Field();

            for (const prop in item) {
                if (prop !== 'fieldDomain') {
                    f[prop] = item[prop];
                }
            }

            f.fieldDomain = item.fieldDomain.map((element: Domain) => {
                const d = new Domain();
                d.name = element.name;
                d.order = element.order;
                d.translation = element.translation;
                d.value = element.value;

                return d;
            }) as Domain[];

            return f;
        }
    }

    static secondsToHHMMSS(secs: number, translate: TranslateService): string {
        if (translate === null) {
            return `${secs}`;
        }

        if (secs === 0) {
            return translate.instant('executionEdit.essays.graph.time.instant') as string;
        }

        const hours = Math.floor(secs / 3600);
        secs %= 3600;
        const minutes = Math.floor(secs / 60);
        const seconds = secs % 60;

        const times = [];

        if (hours > 0) {
            let message = `${hours} ${translate.instant('executionEdit.essays.graph.time.hour') as string}`;
            if (hours > 1) {
                message = message + 's';
            }
            times.push(message);
        }
        if (minutes > 0) {
            let message = `${minutes} ${translate.instant('executionEdit.essays.graph.time.minute') as string}`;
            if (minutes > 1) {
                message = message + 's';
            }
            times.push(message);
        }
        if (seconds > 0) {
            let message = `${seconds} ${translate.instant('executionEdit.essays.graph.time.second') as string}`;
            if (seconds > 1) {
                message = message + 's';
            }
            times.push(message);
        }

        let res = times.join(', ');

        const pos = res.lastIndexOf(',');
        if (pos > -1) {
            res = res.substring(0, pos) + ' ' + (translate.instant('executionEdit.essays.graph.time.and') as string) + ' '
                + res.substring(pos + 1);
        }

        return res;
    }

    static minutesToHHMMSS(secs: number, translate: TranslateService): string {
        if (translate === null) {
            return `${secs}`;
        }

        return this.secondsToHHMMSS(secs * 60, translate);
    }

    static rehearsalToHHMMSS(values: string | number[], translate: TranslateService): string {

        if (typeof values === 'string') {
            values = values.split(';').map(Number);
        }

        const days = values[0];
        const hours = values[1];
        const minutes = values[2];

        const times = [];

        if (days > 0) {
            let message = `${days}` + ' ' + (translate.instant('executionEdit.essays.graph.time.day') as string);
            if (days > 1) {
                message = message + 's';
            }
            times.push(message);
        }
        if (hours > 0) {
            let message = `${hours}` + ' ' + (translate.instant('executionEdit.essays.graph.time.hour') as string);
            if (hours > 1) {
                message = message + 's';
            }
            times.push(message);
        }
        if (minutes > 0) {
            let message = `${minutes}` + ' ' + (translate.instant('executionEdit.essays.graph.time.minute') as string);
            if (minutes > 1) {
                message = message + 's';
            }
            times.push(message);
        }

        let res = times.join(', ');

        const pos = res.lastIndexOf(',');
        if (pos > -1) {
            res = res.substring(0, pos) + ' ' + (translate.instant('executionEdit.essays.graph.time.and') as string) + ' '
                + res.substring(pos + 1);
        }

        return res;
    }

}

export enum FieldEnum {
    NUM_CYCLES = 1,
    SET_POINT = 2,
    TEMPERATURE = 3,
    FREQUENCY = 4,
    REHEARSAL_TIME = 5,
    DS_LOAD = 6,
    LOAD = 7,
    BIOINDICATORS = 9,
    LETHALITY_F0 = 10,
    ENDOTOXINE = 11,
    LETHALITY_FD = 12,
    LETHALITY_FH = 13,
    VOLUME = 16,
    SAMPLE_PLACE = 17,
    POINTS = 18,
    CAUDAL = 19,
    APPLY_UNIFORMITY = 20,
    EXTRACTION_SURFACE = 25,
    FRONTAL_WINDOW_SURFACE = 26,
    UNIFORMITY_VALUE = 30,
    IN_VIDEO = 31,
    INFO_EVALUATE = 32,
    OTHER_INFO = 34
}
