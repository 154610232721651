<form #executionListForm="ngForm" *ngIf="!roouterPath">
    <div fxLayout="row" *ngIf="!this.disableOfflineButton">
        <div class="main-container small-layout">
    
            <div class="lead-text centerText">
                <p [innerHtml]="'landing.main' | translate | safe: 'html'"> </p>
            </div>
    
            <div fxLayout="row" class="block-option-highlights">
                <mat-card class="card" (click)="onSelectionOption(isAir)" routerLinkActive="router-link-active"  appMaterialElevation  *ngIf="showAir">
                    <mat-card-content class="pointerHand">
                        <div class="action-floating info-badge" fxHide.lt-md
                            matTooltip="{{ 'menu.protocol.info' | translate }}">
                           <!--  <mat-icon color="accent">info</mat-icon> -->
                        </div>
                        <img class="icon" src="assets/img/q_air.png"
                            alt="{{ 'menu.manage' | translate }} {{ 'menu.execution' | translate }}">
                        <span class="subtitle">{{ 'menu.air' | translate }}</span>
                    </mat-card-content>
                </mat-card>
    
                <mat-card class="card" (click)="onSelectionOption(isThermal)" routerLinkActive="router-link-active" appMaterialElevation  *ngIf="showThermal">
                    <mat-card-content class="pointerHand">
                        <div class="action-floating info-badge" fxHide.lt-md
                            matTooltip="{{ 'menu.protocol.info' | translate }}">
                            <!-- <mat-icon color="accent">info</mat-icon>-->
                        </div>
                        <img class="icon" src="assets/img/q_thermal.png"
                            alt="{{ 'menu.manage' | translate }} {{ 'menu.execution' | translate }}">
                        <span class="subtitle">{{ 'menu.thermal' | translate }}</span>
                    </mat-card-content>
                </mat-card>
    
                <mat-card class="card" (click)="onSelectionOption(isCalibrates)" routerLinkActive="router-link-active" appMaterialElevation *ngIf="showCalibrates">
                    <mat-card-content class="pointerHand">
                        <div class="action-floating info-badge" fxHide.lt-md
                            matTooltip="{{ 'menu.protocol.info' | translate }}">
                           <!--  <mat-icon color="accent">info</mat-icon>-->
                        </div>
                        <img class="icon" src="assets/img/q_calibrates.png"
                            alt="{{ 'menu.manage' | translate }} {{ 'menu.execution' | translate }}">
                        <span class="subtitle">{{ 'menu.calibrates' | translate }}</span>
                    </mat-card-content>
                </mat-card>
                <mat-card class="card" (click)="onSelectionOption(isQualificates)" routerLinkActive="router-link-active" appMaterialElevation *ngIf="showQualificates">
                    <mat-card-content class="pointerHand">
                        <div class="action-floating info-badge" fxHide.lt-md
                            matTooltip="{{ 'menu.protocol.info' | translate }}">
                           <!--  <mat-icon color="accent">info</mat-icon>-->
                        </div>
                        <img class="icon" src="assets/img/q_qualificates.png"
                            alt="{{ 'menu.manage' | translate }} {{ 'menu.execution' | translate }}">
                        <span class="subtitle">{{ 'menu.qualificates' | translate }}</span>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    
        <div *ngIf="timestampt" class="generatedDate">
            {{ 'home.appVersion' | translate: {version: timestampt} }}
        </div>
    </div>
</form>
