import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ActionConfirmPasswordComponent } from './action-confirm-password/action-confirm-password.component';
import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { ChangeProfileDialogComponent } from './change-profile-dialog/change-profile-dialog.component';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CookiesComponent } from './cookies/cookies.component';
import { CustomDatepickerHeaderComponent } from './datepicker-custom-header/datepicker-custom-header.component';
import { EditAttachmentEditComponent } from './edit-attachment-edit/edit-attachment-edit.component';
import { EditDocumentCodeEditComponent } from './edit-document-code-edit/edit-document-code-edit.component';
import { EditReferenceDocsComponent } from './edit-reference-docs/edit-reference-docs.component';
import { ExecutionEditGenerateReportComponent } from '../thermal/execution-edit/execution-edit-generate-report.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LanguageDialogComponent } from './language-dialog/language-dialog.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NgModule } from '@angular/core';
import { OldBrowserComponent } from './old-browser/old-browser.component';
import { PaginatorI18n } from 'src/app/utils/paginatorI18n';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ReasonDialogComponent } from './reason-dialog/reason-dialog.component';
import { RouterModule } from '@angular/router';
import { SelectGroupToCloneComponent } from './select-group-to-clone/select-group-to-clone.component';
import { SnackBarComponent } from './snackbar/snackbar-component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { CheckWarnsErrorsComponent } from './check-warns-errors/check-warns-errors.component';
import { EditDataProtocolComponent } from './edit-data-protocol/edit-data-protocol.component';
import { HeaderClientComponent } from './header-client/header-client.component';
import { FixedSidebarComponent } from './fixed-sidebar/fixed-sidebar.component';
import { ResizeImageComponent } from './resize-image/resize-image.component';

@NgModule({
    declarations: [
        ConfirmationDialogComponent, HeaderComponent, CustomDatepickerHeaderComponent, SnackBarComponent, OldBrowserComponent,
        CookiesComponent, ExecutionEditGenerateReportComponent, ActionConfirmPasswordComponent, ChangeProfileDialogComponent,
        FooterComponent, ReasonDialogComponent, LanguageDialogComponent, VideoPlayerComponent, EditReferenceDocsComponent,
        EditAttachmentEditComponent, EditDocumentCodeEditComponent, SelectGroupToCloneComponent, CheckWarnsErrorsComponent,
        EditDataProtocolComponent,
        HeaderClientComponent,
        FixedSidebarComponent,
        ResizeImageComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        /* Custom modules */
        AngularMaterialModule,
        PipesModule,
        /* Otros */
        FlexLayoutModule
    ],
    providers: [
        {
            provide: MatPaginatorIntl, deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService)
        }
    ],
    exports: [
        ConfirmationDialogComponent, HeaderComponent, TranslateModule, CustomDatepickerHeaderComponent, ActionConfirmPasswordComponent,
        SnackBarComponent, FooterComponent, VideoPlayerComponent, SelectGroupToCloneComponent, HeaderClientComponent,FixedSidebarComponent, ResizeImageComponent
    ]
})
export class SharedModule { }
