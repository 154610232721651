import { GenericFilter, GenericClass } from './genericClass';
import { ContactPerson } from './model';

export class Client extends GenericClass {

    address: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;

    active: boolean;
    nonPayments: boolean;
    
    showLogo: boolean;
    idLogo: number;

    fullAddress: string;
    addressLine1: string;
    addressLine2: string;

    idGroup: number;

    persons: ContactPerson[] = [];
}

export class ClientFilter extends GenericFilter {

    name: string;

    address: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;

    cols: string[];
}
