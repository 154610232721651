import { Component, Inject, OnDestroy } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IsothermalCharacterizationSensor } from 'src/app/model/isothermalCharacterization';
import { AttachmentCalibratesService } from 'src/app/services/attachmentCalibrates.service';
import { SpinnerService } from 'src/app/services/spinner.service';

export interface DialogDataMassiveUpload {
  sensors: IsothermalCharacterizationSensor[];
  type: string;
}

@Component({
  selector: 'app-isothermal-characterization-edit-massive-upload',
  templateUrl: './isothermal-characterization-edit-massive-upload.component.html'
})
export class IsothermalCharacterizationEditMassiveUploadComponent implements OnDestroy {

  checked = new Map<string, boolean>();

  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<IsothermalCharacterizationEditMassiveUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataMassiveUpload,
    private attachmentCalibratesService: AttachmentCalibratesService,
    private spinnerService: SpinnerService) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isSelected(item: IsothermalCharacterizationSensor): boolean {
    if (item == null) {
      return false;
    }

    return this.checked.get(`${item.id}`);
  }

  isAllSelected(): boolean {
    return this.data.sensors.every(item => this.isSelected(item));
  }

  onCheckAllItems(event: MatCheckboxChange): void {
    this.data.sensors.forEach(elem => {
      this.checked.set(`${elem.id}`, event.checked);
    });
  }

  onSensorCheck(event: MatCheckboxChange): void {
    this.checked.set(`${event.source.value}`, event.checked);
  }

  disableUploadButton(): boolean {
    const checks = [];

    this.checked.forEach((value, key) => {
      if (value) {
        checks.push(key);
      }
    });

    return checks.length === 0;
  }

  accept(): string {
    let res = '*';

    const type = this.data.type;

    if (type === 'photo') {
      res = 'image/*';
    } else if (type === 'excel') {
      res = '.xlsx, .xls, .csv, .pdf';
    }

    return res;
  }

  uploadMassive(event): void {
    this.spinnerService.show();

    const checks: string[] = [];

    this.checked.forEach((value, key) => {
      if (value) {
        checks.push(key);
      }
    });

    if (checks.length === 0) {
      this.spinnerService.hide();

      return;
    }

    const type = this.data.type;
    const file = event.target.files[0] as File;

    const obs = [];
    checks.forEach(id => {

      if (type === 'primary') {
        obs.push(this.attachmentCalibratesService.uploadPrimaryFileToSensorCharacterization(+id, file));
      }
    }
    );

    if (obs.length === 0) {
      // TODO: Error

      this.spinnerService.hide();

      return;
    }

    forkJoin(obs).pipe(takeUntil(this.destroy$)).subscribe(response => {
      response.forEach((res, index) => {
        const id = checks[index];

        const sensor = this.data.sensors.find(sen => sen.id === +id);

        if (type === 'primary') {
          sensor.idPrimaryDataFile = Number(res);
        } else if (type === 'excel') {
          sensor.idExcel = Number(res);
        }
      });

      this.spinnerService.hide();
      this.dialogRef.close(this.data.sensors);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
