import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { BroadcastChannelService } from '../../../services/broadcastChannel.service';
import { UserService } from '../../../services/user.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-header-client',
  templateUrl: './header-client.component.html',
  styleUrls: ['./header-client.component.scss']
})
export class HeaderClientComponent implements OnInit {

  public unreadNotifications = 0;
  private destroy$ = new Subject<void>();

  constructor(private userService: UserService,
            private loginService: LoginService,
            private broadcastChannelService: BroadcastChannelService) { }

  ngOnInit(): void {
    this.userService.unread$.pipe(takeUntil(this.destroy$)).subscribe(unread => {
      this.unreadNotifications = unread;
    });
  }

  logout(): void {
    this.loginService.logout();
    this.broadcastChannelService.sendMessageForceLogout();
  }

  public anyUnreadNotification(): boolean {
    return this.unreadNotifications != null && this.unreadNotifications !== 0;
  }

  public getUnreadNotifications(): string {
    let res = '';

    if (this.anyUnreadNotification()) {
      res = this.unreadNotifications.toString();

      if (this.unreadNotifications > 99) {
        res = '99+';
      }
    }

    return res;
  }


}
