import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Group, GroupFilter } from 'src/app/model/group';
import { Subject, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { Constants } from 'src/app/utils/constants';
import { GroupsDataSource } from 'src/app/model/groupDataSource';
import { Location } from '@angular/common';
import { ManageGroupsService } from 'src/app/services/manageGroups.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Phase } from 'src/app/model/phase';
import { PhaseService } from 'src/app/services/phase.service';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-groups-list',
  templateUrl: './groups-list.component.html'
})
export class GroupsListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: GroupsDataSource;
  filter: GroupFilter = new GroupFilter();

  currentUser: User;

  phases: Phase[];

  displayedColumns: string[] = [
    'name', 'planType', 'phasesAllowed', 'canManageUsers', 'maxUsers', 'maxExecutions', 'canGeneratePreReport', 'signs',
    'showCharacterization', 'edit'
  ];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private location: Location,
    private userService: UserService,
    private groupsService: ManageGroupsService,
    private phaseService: PhaseService,
    private translate: TranslateService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.currentUser = this.userService.currentProfile;

    if (this.currentUser.manager) {
      this.spinnerService.show();
      this.groupsService.findCurrent().pipe(takeUntil(this.destroy$)).subscribe((res: Group) => {
        this.spinnerService.hide();
        this.editRow(res.id);
      });
    } else {
      this.phaseService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Phase[]) => this.phases = data);

      this.loadGroupList();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadGroupList())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  new(): void {
    void this.router.navigateByUrl('/admin/group');
  }

  editRow(id: number): void {
    void this.router.navigateByUrl(`/admin/group?id=${id}`);
  }

  doFilter(event: GroupFilter): void {
    this.filter = event;

    this.loadGroupList();
  }

  loadGroupList(): void {
    this.dataSource = new GroupsDataSource(this.groupsService);

    this.filter.sortBy = this.sort.active || Constants.FIELD_ID;
    this.filter.sortDirection = this.sort.direction || 'asc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 5;

    this.dataSource.loadGroups(this.filter);
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  getMaxUsers(item: Group): number | string {
    return item.maxUsers === -1 ? this.translate.instant('common.unlimited') as string : item.maxUsers;
  }

  getMaxExecutions(item: Group): number | string {
    return item.maxExecutions === -1 ? this.translate.instant('common.unlimited') as string : item.maxExecutions;
  }

  getPhasesAllowed(item: Group): string {
    if (item == null || item.idsPhasesAllowed == null || this.phases == null) {
      return '';
    }

    return this.phases.filter(p => item.idsPhasesAllowed.includes(p.id))
      .map(p => this.translate.instant('phase.' + p.translation) as string).join(', ');
  }

  getSignTypes(item: Group): string {
    const types = [];

    if (item.manualSign) {
      types.push(this.translate.instant('common.manualSign'));
    }
    if (item.automaticSign) {
      types.push(this.translate.instant('common.automaticSign'));
    }

    return types.join(', ');
  }

  cancel(): void {
    this.location.back();
  }

}
