import { Observable, from } from 'rxjs';

import { CompressedGas } from '../model/compressedGasesAir';
import { DexieService } from './dexieService';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { OnlineService } from './online.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class CompressedGasesTypeService {

    constructor(private http: HttpClient,
        private dexieService: DexieService,
        private onlineService: OnlineService) {
    }

    findConfigByEssayidAndEquipmentId(idEquipment: number, idEssay: number): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + `/air/essay/config/${idEquipment}/${idEssay}`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));

        } else {
            return from(this.dexieService.essayConfig.get({ idEquipmentType: idEquipment, idEssay }).timeout(30000));
        }
    }

    findAll(): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + '/compressedGasType/';

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.compressedGasTypes.toArray().timeout(30000));
        }
    }

    findOne(id: number): Observable<any> {

        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + `/compressedGasType/${id}`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));

        } else {
            return from(this.dexieService.essayConfig.get({ id }).timeout(30000));
        }
    }

    saveCompressedGasTypeToDexie(): Promise<void> {
        return new Promise<void>((resolve) => this.findAll().subscribe((res: CompressedGas[]) => {
            if (res) {
                void this.dexieService.compressedGasTypes.clear().then(() => {
                    res.forEach(type => {
                        void this.dexieService.compressedGasTypes.put(type, type.id).then(() => resolve());
                    });
                });
            }
        }));
    }

}
