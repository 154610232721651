import { Component, OnDestroy, OnInit } from '@angular/core';

import { MaintenanceModeService } from 'src/app/services/maintenanceMode.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-maintenance-mode',
  templateUrl: './maintenance-mode.component.html'
})
export class MaintenanceModeComponent implements OnInit, OnDestroy {

  private interval: number;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private maintenanceModeService: MaintenanceModeService) { }

  ngOnInit(): void {
    // this.checkMaintenance();
    // this.interval = window.setInterval(() => this.checkMaintenance(), 60 * 1000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    /// clearInterval(this.interval);
  }

  private checkMaintenance(): void {
    // this.maintenanceModeService.isMaintenanceMode().pipe(takeUntil(this.destroy$)).subscribe(() => void this.router.navigateByUrl('/'));
  }

}
