import * as _ from 'lodash-es';
import * as moment from 'moment';

import { CalibrationPatternValue, CalibrationUncertaintiesValues } from 'src/app/model/calibration';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
  DialogDataConfigSensors,
  IsothermalCharacterizationEditConfigureSensorsComponent
} from './isothermal-characterization-edit-configure-sensors.component';
import {
  IsothermalCharacterizationCalibrationValue,
  IsothermalCharacterizationSensor,
  IsothermalCharacterizationStatus,
  IsothermalCharacterizationVariable
} from 'src/app/model/isothermalCharacterization';
import { VariableTypeEnum, VariableUnit } from 'src/app/model/variable';

import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { AttachmentCalibratesService } from 'src/app/services/attachmentCalibrates.service';
import { AttachmentThermalService } from 'src/app/services/attachmentThermal.service';
import { CalibrationService } from 'src/app/services/calibration.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { Criteria } from 'src/app/model/criteria';
import { CriteriaTypeEnum } from 'src/app/model/criteriaType';
import { EquipmentAutocompleteFilter } from 'src/app/model/autocompleteFilter';
import { EssayUtils } from 'src/app/utils/essayUtils';
import { ExcelUtils } from 'src/app/utils/excelUtils';
import { HttpStatusCode } from '@angular/common/http';
import { InternalEquipment } from 'src/app/model/internalEquipment';
import { InternalEquipmentService } from 'src/app/services/internalEquipment.service';
import { IsothermalCharacterizationEditAttachmentDetailsComponent } from './isothermal-characterization-edit-attachment-details.component';
import { IsothermalCharacterizationEditConfigSensorComponent } from './isothermal-characterization-edit-config-sensor.component';
import { IsothermalCharacterizationEditCriteriaEditComponent } from './isothermal-characterization-edit-criteria-edit.component';
import {
  IsothermalCharacterizationEditDialogCorrectSensorsComponent
} from './isothermal-characterization-edit-dialog-correct-sensors.component';
import { IsothermalCharacterizationEditDiscardTimeComponent } from './isothermal-characterization-edit-discard-time.component';
import { IsothermalCharacterizationEditGraphComponent } from './isothermal-characterization-edit-graph.component';
import { IsothermalCharacterizationEditMassiveUploadComponent } from './isothermal-characterization-edit-massive-upload.component';
import { IsothermalCharacterizationEditPhotoDetailsComponent } from './isothermal-characterization-edit-photo-details.component';
import {
  IsothermalCharacterizationEditReasonExpositionTimeComponent
} from './isothermal-characterization-edit-reason-exposition-time.component';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatTable } from '@angular/material/table';
import { SensorData } from 'src/app/model/sensorData';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-isothermal-characterization-edit-essay',
  templateUrl: './isothermal-characterization-edit-essay.component.html'
})
export class IsothermalCharacterizationEditEssayComponent implements OnDestroy {

  @ViewChild(IsothermalCharacterizationEditGraphComponent, { static: true }) graph: IsothermalCharacterizationEditGraphComponent;
  @ViewChild('uploadExcelMassive') uploadExcelMassiveInput: ElementRef;
  @ViewChild('uploadAutomaticMassiveFile') uploadAutomaticMassiveInput: ElementRef;

  @Input() idIsothermalCharacterization: number;
  @Input() idStatusIsothermalCharacterization: number;
  @Input() rehearsalTime: string;
  @Input() idDisposal: number;
  @Input() idPhoto: number;

  @Output() fcStartEssayEmitter = new EventEmitter<any>();
  @Output() updateSensorEssayEmitter = new EventEmitter<any>();
  @Output() reloadPageEmitter = new EventEmitter<any>();
  @Output() idDisposalEmitter = new EventEmitter<any>();
  @Output() idPhotoEmitter = new EventEmitter<any>();

  @ViewChildren('criteriaTable') criteriaTable: QueryList<MatTable<any>>;
  @ViewChild('uncertaintiesTable', { static: true }) uncertaintiesTable: MatTable<any>;

  variableUnits: Map<number, VariableUnit[]>;
  public equipmentAutoComplete: Map<number, InternalEquipment[]>;
  public equipmentCalibrationAutoComplete: Map<number, InternalEquipment[]>;
  _variables: IsothermalCharacterizationVariable[];

  displayedColsCriteria = ['criteria', 'edit', 'delete'];

  uncertaintiesCols = ['variable', 'point', 'uRep', 'uResol', 'uPattern', 'uEstab', 'uc', 'neff', 'k', 'U', 'measureUnit'];

  graphMap = new Map<number, SensorData[]>();
  copyGraphDateMap = new Map<number, boolean>();

  private destroy$ = new Subject<void>();

  private _variableIndex = 0;

  constructor(
    private attachmentThermalService: AttachmentThermalService,
    private attachmentCalibratesService: AttachmentCalibratesService,
    private internalEquipmentService: InternalEquipmentService,
    private calibrationService: CalibrationService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    this.variableUnits = new Map<number, VariableUnit[]>();
  }

  get reasonModifyExpositionTime(): string {
    const variableSelected = this._variables[this._variableIndex];

    if (variableSelected == null) {
      return '';
    }

    return variableSelected.reasonModifyExpositionTime;
  }

  set reasonModifyExpositionTime(reason: string) {
    const variableSelected = this._variables[this._variableIndex];

    if (variableSelected != null) {
      variableSelected.reasonModifyExpositionTime = reason;
    }
  }

  public set variables(variables: IsothermalCharacterizationVariable[]) {
    variables = variables.sort((a, b) => a.idVariable - b.idVariable);

    this._variables = variables;

    this.equipmentAutoComplete = new Map<number, InternalEquipment[]>();
    this.equipmentCalibrationAutoComplete = new Map<number, InternalEquipment[]>();

    this.onVariableTabChange(0);
    this.reloadGraphMap();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  anyVariable(): boolean {
    if (this._variables == null) {
      return false;
    }

    return this._variables.length !== 0;
  }

  anyVariableSelected(): boolean {
    if (this._variables == null) {
      return false;
    }

    return this._variables.filter(v => v.enableCalibration).length !== 0;
  }

  newValue(): void {
    for (const variable of this._variables) {
      const calibrations = variable.calibrations;
      calibrations.forEach(c => c.values.push(new IsothermalCharacterizationCalibrationValue()));
    }
  }

  removeValue(index: number): void {

    for (const variable of this._variables) {
      const calibrations = variable.calibrations;

      calibrations.forEach(c => c.values.splice(index, 1));
    }
  }

  updateTableUncertainty(indexVariable: number): void {
    if (indexVariable == null) {

      const variablesSize = this._variables.length;
      for (let i = 0; i < variablesSize; i++) {
        this.updateTableUncertainty(+i);
      }

      return;
    }

    this._variables[indexVariable].calibrations.forEach(c => c.recalculateValues());
  }

  getUncertaintiesList(): IsothermalCharacterizationVariable[] {
    if (ArrayUtils.isEmpty(this._variables)) {
      return [];
    }

    const res = this._variables.filter(v => v.enableCalibration);

    res.forEach(v => v.calibration = v.calibrations[0]);

    return res;
  }

  setUncertainty(variableIndex: number, sensorIndex: number, $event): void {
    const value = +$event;

    if (isNaN(value)) {
      return;
    }

    const sensor = this._variables[variableIndex].sensors[sensorIndex];
    sensor.uncertainty = value;

    if (!ArrayUtils.isEmpty(sensor.data)) {
      this.graph.sensors = this.graph.sensors.map(d => {

        if (sensor.idEquipment === d.idEquipment) {
          d.uncertainty = value;
        }

        return d;
      });
      this.graph.recalculateValues();
    }
  }

  reloadPage(): void {
    const data = { idIsothermalCharacterization: this.idIsothermalCharacterization };
    this.reloadPageEmitter.emit(data);
  }

  reloadGraphMap(): void {
    this.graphMap = new Map<number, SensorData[]>();

    this._variables.forEach((variable, variableIndex) => {
      let values: SensorData[] = [];
      variable.sensors.filter(sensor => sensor.data != null && sensor.data.length !== 0).forEach(sensor => {
        values = values.concat(...sensor.data);
      });

      variable.sensors.forEach(sensor => {
        this.equipmentAutoComplete.set(sensor.id, []);
      });

      if (values.length > 0) {
        this.graphMap.set(variable.idVariable, values);
      }

      if (variableIndex !== 0) {
        this.copyGraphDateMap.set(variableIndex, true);
      }

    });

    this.updateGraphByIndex(this._variableIndex);
  }

  lookupEquipment(variableIndex: number, sensorIndex: number, idSensor: number, $event): void {
    let results = this.getResultsLookup(idSensor);

    const filter = new EquipmentAutocompleteFilter();

    filter.query = $event.target.value as string;
    filter.pageIndex = 0;
    filter.pageSize = 10;
    filter.sortBy = 'name';
    filter.sortDirection = 'asc';
    filter.forExecution = true;

    this.internalEquipmentService.findAutocomplete(filter).pipe(takeUntil(this.destroy$)).subscribe(item => {

      const variableSelected = this._variables[variableIndex];

      const alreadySelected = variableSelected.sensors.filter((v, index) => index !== sensorIndex).map(v => v.serialNum);

      item.content = item.content.filter((c: InternalEquipment) => !alreadySelected.includes(c.serialNum));

      results = item.content;
      this.equipmentAutoComplete.set(idSensor, results);
    }, () => {
      results = [];

    });
  }

  getResultsLookup(idSensor: number): InternalEquipment[] {
    return this.equipmentAutoComplete.get(idSensor);
  }

  updateSensorDependency(variableIndex: number, sensorIndex: number, field: string): void {
    const currentVariable = this._variables[variableIndex];

    const isCopyToTemp = currentVariable.copyFromTemperature;
    const isCopyFromTemp = currentVariable.idVariable === VariableTypeEnum.TEMPERATURE;

    // Si NO copiamos DESDE o A temperatura, no copiamos
    if (!isCopyFromTemp && !isCopyToTemp) {
      return;
    }

    this._variables.forEach((variable, essayValIndex) => {
      // Si la variable es la misma, no copiamos
      if (variable === currentVariable) {
        return;
      }

      const idVariable = variable.idVariable;

      // Si no está marcado para ser copiado y no es temperatura, no copiamos
      if (!variable.copyFromTemperature && idVariable !== VariableTypeEnum.TEMPERATURE) {
        return;
      }

      let updateIndividualSensor = false;

      // Caso queremos copiar todos
      if (field === 'sensorsConfig') {
        const currentSensors = variable.sensors;

        const finalSensors = currentVariable.sensors.map(s => {
          let currentSensor = currentSensors.find(sen => sen.trayNum === s.trayNum && sen.sensorTray === s.sensorTray);

          if (currentSensor == null) {
            currentSensor = new IsothermalCharacterizationSensor();
            currentSensor.sensorTray = s.sensorTray;
            currentSensor.trayNum = s.trayNum;
            currentSensor.equipmentName = s.equipmentName;
            currentSensor.evaluate = s.evaluate;
            currentSensor.evaluateReason = s.evaluateReason;
            currentSensor.idEquipment = s.idEquipment;
            currentSensor.serialNum = s.serialNum;
            currentSensor.location = s.location;
          }

          return currentSensor;
        });

        variable.sensors = finalSensors;
      }

      const sensor = currentVariable.sensors[sensorIndex] || null;
      const sensorDepends = variable.sensors[sensorIndex] || null;

      if (sensor != null && sensorDepends != null) {
        if (field === 'serialNum') {
          sensorDepends.equipmentName = sensor.equipmentName;
          sensorDepends.serialNum = sensor.serialNum;
          updateIndividualSensor = true;
        } else if (field === 'uncertainty') {
          this.setUncertaintySensor(essayValIndex, sensorIndex, null, false);
        } else if (field === 'primary') {
          sensorDepends.idPrimaryDataFile = sensor.idPrimaryDataFile;
          updateIndividualSensor = true;
        } else if (field === 'config') {
          sensorDepends.evaluate = sensor.evaluate;
          sensorDepends.evaluateReason = sensor.evaluateReason;
          updateIndividualSensor = true;
        }
      }

      if (updateIndividualSensor) {
        this.setSensor(essayValIndex, sensorIndex, sensorDepends);
      }

    });
  }

  onSensorChange(variableIndex: number, sensorIndex: number, $event: any, trigger: MatAutocompleteTrigger, confirmEq = false): void {
    const equipmentSelected = $event.option.value as InternalEquipment;

    const sensor = this.getSensor(variableIndex, sensorIndex);

    const variableSelected = this._variables[variableIndex];

    if (!confirmEq) {

      variableSelected.sensors.forEach((s: IsothermalCharacterizationSensor, index: number) => {
        if (index === sensorIndex) {
          return;
        }

        if (s.serialNum === equipmentSelected.serialNum) {

          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
              message: this.translate.instant('executionEdit.essays.sensor.form.error.alreadyInUse') as string,
              canOk: false
            }
          });

          dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
            if (result === true) {
              this.onSensorChange(variableIndex, sensorIndex, $event, trigger, true);
            } else {
              sensor.equipmentName = null;
              sensor.serialNum = null;
              sensor.uncertainty = null;

              this.setSensor(variableIndex, sensorIndex, sensor);
              this.updateSensorDependency(variableIndex, sensorIndex, 'serialNum');
            }

            event.stopPropagation();
            trigger.closePanel();
          });

          return;
        }
      });
    }

    sensor.equipmentName = null;
    sensor.serialNum = null;

    if (equipmentSelected.nearToExpire || equipmentSelected.expired) {

      sensor.equipmentName = null;
      sensor.serialNum = null;
      sensor.uncertainty = null;

      this.setSensor(variableIndex, sensorIndex, sensor);
      this.updateSensorDependency(variableIndex, sensorIndex, 'serialNum');

      const expirationDate = moment(equipmentSelected.expirationDate).format('DD/MMM/YYYY').toUpperCase().replace('./', '/');

      let message = this.translate.instant('executionEdit.essays.sensor.form.error.nearToExpire', { expirationDate }) as string;

      if (equipmentSelected.expired) {
        message = this.translate.instant('executionEdit.essays.sensor.form.error.expired') as string;
      }

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
        const sen = this.getSensor(variableIndex, sensorIndex);

        if (result === true) {
          sen.equipmentName = equipmentSelected.name;
          sen.serialNum = equipmentSelected.serialNum;
          this.setSensor(variableIndex, sensorIndex, sen);
          this.updateSensorDependency(variableIndex, sensorIndex, 'serialNum');

        } else {
          sen.equipmentName = null;
          sen.serialNum = null;
          sen.uncertainty = null;

          this.setSensor(variableIndex, sensorIndex, sen);
          this.updateSensorDependency(variableIndex, sensorIndex, 'serialNum');
        }
        this.setUncertaintySensor(variableIndex, sensorIndex, equipmentSelected.id);

        event.stopPropagation();
        trigger.closePanel();
      });
    } else {
      sensor.equipmentName = equipmentSelected.name;
      sensor.serialNum = equipmentSelected.serialNum;

      this.setUncertaintySensor(variableIndex, sensorIndex, equipmentSelected.id);

      this.setSensor(variableIndex, sensorIndex, sensor);
      this.updateSensorDependency(variableIndex, sensorIndex, 'serialNum');
    }
  }

  lookupCalibrationEquipment(variableIndex: number, $event): void {
    const variableSelected = this._variables[variableIndex];

    const idVariable = variableSelected.idVariable;

    let results = this.getResultsLookup(idVariable);

    const filter = new EquipmentAutocompleteFilter();

    filter.query = $event.target.value as string;
    filter.pageIndex = 0;
    filter.pageSize = 10;
    filter.sortBy = 'name';
    filter.sortDirection = 'asc';
    filter.forExecution = true;

    this.internalEquipmentService.findAutocomplete(filter).pipe(takeUntil(this.destroy$)).subscribe(item => {
      results = item.content;
      this.equipmentCalibrationAutoComplete.set(idVariable, results);
    }, () => {
      results = [];
      this.equipmentCalibrationAutoComplete.set(idVariable, results);
    });
  }

  getResultsCalibrationLookup(idVariable: number): InternalEquipment[] {
    return this.equipmentCalibrationAutoComplete.get(idVariable);
  }

  onSensorCalibrationChange(variableIndex: number, calibrationIndex: number, $event: any, trigger: MatAutocompleteTrigger): void {

    const equipmentSelected = $event.option.value as InternalEquipment;

    const sensor = this._variables[variableIndex].calibrations[calibrationIndex];

    const variableSelected = this._variables[variableIndex];

    sensor.equipmentName = null;
    sensor.serialNum = null;
    sensor.idEquipmentCalibration = null;

    if (equipmentSelected.nearToExpire || equipmentSelected.expired) {

      sensor.equipmentName = null;
      sensor.serialNum = null;
      sensor.uncertainty = null;
      sensor.idEquipmentCalibration = null;

      this._variables[variableIndex].calibrations[calibrationIndex] = sensor;

      const expirationDate = moment(equipmentSelected.expirationDate).format('DD/MMM/YYYY').toUpperCase().replace('./', '/');

      let message = this.translate.instant('executionEdit.essays.sensor.form.error.nearToExpire', { expirationDate }) as string;

      if (equipmentSelected.expired) {
        message = this.translate.instant('executionEdit.essays.sensor.form.error.expired') as string;
      }

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
        const sen = this._variables[variableIndex].calibrations[calibrationIndex];

        if (result === true) {
          sen.equipmentName = equipmentSelected.name;
          sen.serialNum = equipmentSelected.serialNum;
          sen.idEquipmentCalibration = equipmentSelected.id;

          this.setUncertaintyCalibration(variableIndex, calibrationIndex, equipmentSelected.id, variableSelected.idVariable);
        } else {
          sen.equipmentName = null;
          sen.serialNum = null;
          sen.uncertainty = null;
          sen.idEquipmentCalibration = null;

          this._variables[variableIndex].calibrations[calibrationIndex] = sen;
        }

        event.stopPropagation();
        trigger.closePanel();
      });
    } else {
      sensor.equipmentName = equipmentSelected.name;
      sensor.serialNum = equipmentSelected.serialNum;
      sensor.idEquipmentCalibration = equipmentSelected.id;

      this.setUncertaintyCalibration(variableIndex, calibrationIndex, equipmentSelected.id, variableSelected.idVariable);

      this._variables[variableIndex].calibrations[calibrationIndex] = sensor;
    }
  }

  clearSensorCalibration(variableIndex: number, calibrationIndex: number): void {
    const sensor = this._variables[variableIndex].calibrations[calibrationIndex];

    sensor.equipmentName = null;
    sensor.serialNum = null;
    sensor.uncertainty = null;
    sensor.idEquipmentCalibration = null;

    this._variables[variableIndex].calibrations[calibrationIndex] = sensor;
  }

  downloadCertificate(variableIndex: number, calibrationIndex: number): void {
    const sensor = this._variables[variableIndex].calibrations[calibrationIndex];
    this.spinnerService.show();

    this.internalEquipmentService.downloadPdf(sensor.idEquipmentCalibration).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      saveAs(res, sensor.equipmentName + '.pdf');
      this.spinnerService.hide();
    }, err => {
      this.spinnerService.hide();
      if (err.status === HttpStatusCode.NotFound) {
        this.snackBarService.sendError(this.translate.instant('isothermalCharacterizationEdit.form.downloadPdf.error.notFound') as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('isothermalCharacterizationEdit.form.downloadPdf.error.generic') as string);
      }
    });
  }

  displayFn(eq?: InternalEquipment | string | { name: string }): string {
    if (!eq) {
      return null;
    }

    let res: string = null;

    if (typeof eq === 'string') {
      res = eq;
    } else if (eq instanceof InternalEquipment || eq.name != null) {
      res = eq.name;
    }

    return res;
  }

  showCriteria(crit: Criteria): string {
    return EssayUtils.getCriteriaToShowThermal(crit, this.translate);
  }

  onVariableTabChange(event: number | MatTabChangeEvent): void {
    if (event instanceof MatTabChangeEvent) {
      this._variableIndex = event.index;
    } else {
      this._variableIndex = event;
    }

    this.updateGraphByIndex(this._variableIndex);
  }

  getVariableUnitsByVariable(idVariable: number): VariableUnit[] {
    return this.variableUnits.get(idVariable) || [];
  }

  onUnitChange(indexVariable: number, event: MatSelectChange | VariableUnit): void {

    const variable = this._variables[indexVariable];

    let unit: VariableUnit;

    if (event instanceof MatSelectChange) {
      unit = this.variableUnits.get(variable.idVariable).find(v => v.id === event.value);
    } else {
      unit = event;
    }

    if (variable != null && unit != null) {
      variable.idUnit = unit.id;
      variable.unitName = unit.unit;

      this._variables[indexVariable] = variable;

      this.graph.unitName = variable.unitName;
    }
  }

  restartGraph(): void {
    this.graph.restartGraph();
  }

  setRange(range: string): void {
    this.graph.range = range;
  }

  onCopyTimeGraphChange(event: any): void {
    const variableIndex = event.variableIndex as number;
    const value = event.value as boolean;

    this.copyGraphDateMap.set(variableIndex, value);
  }

  onCopyFromTemperatureChange(variableIndex: number, event: MatCheckboxChange): void {
    this._variables[variableIndex].copyFromTemperature = event.checked;
  }

  disableConfigureSensorsButton(): boolean {
    const allowedStatuses = [IsothermalCharacterizationStatus.EN_PREPARACION];

    return !allowedStatuses.includes(this.idStatusIsothermalCharacterization);
  }

  disableCopySensorInfo(): boolean {
    return this.disableSerialNumLabel() || this._variables.length < 2 || this._variables[this._variableIndex].sensors.length === 0;
  }

  disableDefrostsButton(): boolean {
    const disallowedStatuses = [IsothermalCharacterizationStatus.PENDIENTE_FIRMA, IsothermalCharacterizationStatus.FIRMADO];

    return disallowedStatuses.includes(this.idStatusIsothermalCharacterization) || (this.graph != null && this.graph.disableGraph());
  }

  onExcelUploadSensor(variableIndex: number, sensorIndex: number, event): void {
    const variableSelected = this._variables[variableIndex];
    const sensor = this.getSensor(variableIndex, sensorIndex);

    const cond = sensor.idEquipment != null;

    if (cond === false) {
      this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadExcel.error.idEquipmentMissing') as string);

      return;
    }

    const file = event.target.files[0] as File;

    if (file.name.endsWith('.pdf')) {
      this.spinnerService.show();

      this.attachmentThermalService.convertPdfToCsv(file, variableSelected.idVariable).pipe(takeUntil(this.destroy$)).subscribe(res => {
        res.name = file.name;
        this.uploadDataExcel(variableIndex, sensorIndex, res as File);
      });
    } else {
      this.uploadDataExcel(variableIndex, sensorIndex, file);
    }
  }

  onExcelMassiveUploadSensor(variableIndex: number, event): void {
    const variableSelected = this._variables[variableIndex];
    const file = event.target.files[0] as File;

    this.uploadExcelMassiveInput.nativeElement.value = '';

    if (file.name.endsWith('.pdf')) {
      this.spinnerService.show();

      this.attachmentThermalService.convertPdfToCsv(file, variableSelected.idVariable).pipe(takeUntil(this.destroy$)).subscribe(res => {
        res.name = file.name;
        this.uploadMassiveDataExcel(variableIndex, res as File);
      });
    } else {
      this.uploadMassiveDataExcel(variableIndex, file);
    }
  }

  onPrimaryDataUploadSensor(variableIndex: number, sensorIndex: number, event): void {
    const sensor = this.getSensor(variableIndex, sensorIndex);

    const file = event.target.files[0] as File;

    this.spinnerService.show();
    this.attachmentCalibratesService.uploadPrimaryFileToSensorCharacterization(sensor.id, file).pipe(takeUntil(this.destroy$))
      .subscribe((item: number) => {
        sensor.idPrimaryDataFile = item;

        this.setSensor(variableIndex, sensorIndex, sensor);
        this.updateSensorDependency(variableIndex, sensorIndex, 'primary');

        this.spinnerService.hide();

        this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.uploadPrimaryData.ok') as string);
      }, () => {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadPrimaryData.error.generic') as string);
      });
  }

  onUploadDisposal(event): void {
    const file = event.target.files[0] as File;

    this.spinnerService.show();
    this.attachmentThermalService.uploadGeneric(file).pipe(takeUntil(this.destroy$)).subscribe((item: number) => {
      this.idDisposalEmitter.emit({ id: item });

      this.spinnerService.hide();

      this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.disposal.ok') as string);
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.disposal.error.generic') as string);
    });
  }

  openDialogDisposal(): void {
    const dialogRef = this.dialog.open(IsothermalCharacterizationEditPhotoDetailsComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        idIso: this.idIsothermalCharacterization,
        idAttachment: this.idDisposal,
        disableUpload: this.disableUploadExcelButton(),
        type: 'disposal'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: number) => {
      this.idDisposal = result;
      this.idDisposalEmitter.emit({ id: result });
    });
  }

  onUploadPhoto(event): void {
    const file = event.target.files[0] as File;

    this.spinnerService.show();
    this.attachmentThermalService.uploadGeneric(file).pipe(takeUntil(this.destroy$)).subscribe((item: number) => {
      this.idPhotoEmitter.emit({ id: item });

      this.spinnerService.hide();

      this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.generalPhoto.ok') as string);
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.generalPhoto.error.generic') as string);
    });
  }

  openDialogPhoto(): void {
    const dialogRef = this.dialog.open(IsothermalCharacterizationEditPhotoDetailsComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        idIso: this.idIsothermalCharacterization,
        idAttachment: this.idPhoto,
        disableUpload: this.disableUploadExcelButton(),
        type: 'photo'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: number) => {
      this.idPhoto = result;
      this.idPhotoEmitter.emit({ id: result });
    });
  }

  openMassiveUploadExcelDialog(variableIndex: number): void {
    this.openMassiveUploadGenericDialog(variableIndex, 'excel');
  }

  openMassiveUploadPrimaryDataDialog(variableIndex: number): void {
    this.openMassiveUploadGenericDialog(variableIndex, 'primary');
  }

  uploadAutomaticMassive(variableIndex: number, event): void {
    this.spinnerService.show();

    const fileList = event.target.files as FileList;
    const files: File[] = Array.from(fileList);

    if (ArrayUtils.isEmpty(files)) {
      this.uploadAutomaticMassiveInput.nativeElement.value = '';

      this.spinnerService.hide();
      return;
    }

    void this.generatePromisesMassiveAutomatic(variableIndex, files).then(() => {
      this.spinnerService.show();
      setTimeout(() => {
        this.spinnerService.hide();
        this.reloadPage();
      }, files.length * 200);
    });

    this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.uploadMassiveAutomatic.ok') as string);
    this.uploadAutomaticMassiveInput.nativeElement.value = '';
    this.spinnerService.hide();
  }

  enableReasonModifyExpositionTime(): boolean {
    const allowedStatuses = [IsothermalCharacterizationStatus.EN_PREPARACION, IsothermalCharacterizationStatus.PENDIENTE_FIRMA];

    return allowedStatuses.includes(this.idStatusIsothermalCharacterization);
  }

  openReasonModifyExpositionTime(event: MatCheckboxChange): void {

    if (event.checked === false) {
      this.reasonModifyExpositionTime = null;
      this.graph.reasonModifyExpositionTime = null;
      return;
    }

    const dialogRef = this.dialog.open(IsothermalCharacterizationEditReasonExpositionTimeComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        reason: _.cloneDeep(this.reasonModifyExpositionTime)
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {

      if (result !== null && result !== undefined) {
        this.reasonModifyExpositionTime = result;
        this.graph.reasonModifyExpositionTime = result;
      }
    });
  }

  openConfigureSensors(): void {

    const tabSelected = this._variables[this._variableIndex];

    const data: DialogDataConfigSensors = {
      locationByTrays: _.cloneDeep(tabSelected.locationByTrays),
      sensors: _.cloneDeep(tabSelected.sensors)
    };

    const dialogRef = this.dialog.open(IsothermalCharacterizationEditConfigureSensorsComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res: DialogDataConfigSensors) => {
      if (res != null) {

        tabSelected.locationByTrays = res.locationByTrays;
        tabSelected.sensors = res.sensors;
        this.updateSensorDependency(this._variableIndex, null, 'sensorsConfig');
      }
    });
  }

  showSensorName(essayIndex: number, sensor: IsothermalCharacterizationSensor): string {
    const variable = this._variables[essayIndex];

    let res: string;
    if (variable.locationByTrays) {
      res = this.translate.instant('isothermalCharacterizationEdit.dialog.essay.sensors.location.text',
        { trayNum: sensor.trayNum, sensorTray: sensor.sensorTray }) as string;
    } else {
      res = sensor.location;
    }

    return res;
  }

  enableEditCriteria(): boolean {
    const allowedStatuses = [IsothermalCharacterizationStatus.EN_PREPARACION];

    return allowedStatuses.includes(this.idStatusIsothermalCharacterization);
  }

  openCreateCriteria(variableIndex: number): void {
    const variable = this._variables[variableIndex];

    const criteriasRemove = variable.criterias.map(c => c.idType);

    const criteriasUniformity =
      [CriteriaTypeEnum.UNIFORMITY_LE, CriteriaTypeEnum.UNIFORMITY_LOWER,
      CriteriaTypeEnum.INFORMATIVE_UNIFORMITY, CriteriaTypeEnum.NOT_APPLY_UNIFORMITY
      ];
    const criteriasStability = [
      CriteriaTypeEnum.STABILITY_LE, CriteriaTypeEnum.STABILITY_LOWER,
      CriteriaTypeEnum.INFORMATIVE_STABILITY, CriteriaTypeEnum.NOT_APPLY_STABILITY
    ];

    const anyUniformity = variable.criterias.some(c => criteriasUniformity.includes(c.idType));
    const anyStability = variable.criterias.some(c => criteriasStability.includes(c.idType));

    if (anyUniformity) {
      criteriasRemove.push(...criteriasUniformity);
    }

    if (anyStability) {
      criteriasRemove.push(...criteriasStability);
    }

    const dialogRef = this.dialog.open(IsothermalCharacterizationEditCriteriaEditComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        criteria: _.cloneDeep(new Criteria()),
        criteriasRemove
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Criteria) => {

      if (result !== null && result !== undefined) {
        variable.criterias.push(result);
        this.criteriaTable.forEach(i => i.renderRows());
        this.updateGraphByIndex(variableIndex);
      }
    });
  }

  openEditCriteria(variableIndex: number, criteriaIndex: number): void {
    const variable = this._variables[variableIndex];
    const criteria = variable.criterias[criteriaIndex];

    const criteriasRemove = variable.criterias.map(c => c.idType).filter(c => c !== criteria.idType);

    const criteriasUniformity = [CriteriaTypeEnum.UNIFORMITY_LE, CriteriaTypeEnum.UNIFORMITY_LOWER,
    CriteriaTypeEnum.INFORMATIVE_UNIFORMITY, CriteriaTypeEnum.NOT_APPLY_UNIFORMITY];
    const criteriasStability = [CriteriaTypeEnum.STABILITY_LE, CriteriaTypeEnum.STABILITY_LOWER,
    CriteriaTypeEnum.INFORMATIVE_STABILITY, CriteriaTypeEnum.NOT_APPLY_STABILITY];

    const anyUniformity = variable.criterias.filter(c => c !== criteria).some(c => criteriasUniformity.includes(c.idType));
    const anyStability = variable.criterias.filter(c => c !== criteria).some(c => criteriasStability.includes(c.idType));

    if (anyUniformity) {
      criteriasRemove.push(...criteriasUniformity);
    }

    if (anyStability) {
      criteriasRemove.push(...criteriasStability);
    }

    const dialogRef = this.dialog.open(IsothermalCharacterizationEditCriteriaEditComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        criteria: _.cloneDeep(criteria),
        criteriasRemove
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Criteria) => {

      if (result !== null && result !== undefined) {
        variable.criterias[criteriaIndex] = result;
        this.criteriaTable.forEach(i => i.renderRows());
        this.updateGraphByIndex(variableIndex);
      }
    });
  }

  removeCriteria(essayIndex: number, criteriaIndex: number): void {
    const variable = this._variables[essayIndex];
    variable.criterias.splice(criteriaIndex, 1);

    this.criteriaTable.forEach(i => i.renderRows());
  }

  openMassiveUploadGenericDialog(variableIndex: number, type: string): void {
    const sensors = this._variables[variableIndex].sensors;

    const dialogRef = this.dialog.open(IsothermalCharacterizationEditMassiveUploadComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: { sensors, type }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: IsothermalCharacterizationSensor[]) => {
      if (result !== null && result !== undefined) {
        this._variables[variableIndex].sensors = result;
        this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.uploadMassive.ok') as string);
      }
    });
  }

  disableEdit(): boolean {
    const allowedStatuses = [IsothermalCharacterizationStatus.EN_PREPARACION];

    return !allowedStatuses.includes(this.idStatusIsothermalCharacterization);
  }

  openDialogCorrectSensors(indexVariable: number): void {
    const variableSelected = this._variables[indexVariable];

    const dialogRef = this.dialog.open(IsothermalCharacterizationEditDialogCorrectSensorsComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        idIso: this.idIsothermalCharacterization,
        idEssayVariable: variableSelected.id,
        sensors: variableSelected.sensors,
        locationByTrays: variableSelected.locationByTrays
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res: boolean) => {
      if (res) {
        this.reloadPage();
      }
    });
  }

  disableSerialNumLabel(): boolean {
    const allowedStatuses = [IsothermalCharacterizationStatus.EN_PREPARACION];

    return !allowedStatuses.includes(this.idStatusIsothermalCharacterization);
  }

  disableUploadExcelButton(): boolean {
    const allowedStatuses = [IsothermalCharacterizationStatus.PENDIENTE_FIRMA, IsothermalCharacterizationStatus.FIRMADO];

    return allowedStatuses.includes(this.idStatusIsothermalCharacterization);
  }

  disableUploadOriginalButton(): boolean {
    const allowedStatuses = [IsothermalCharacterizationStatus.PENDIENTE_FIRMA, IsothermalCharacterizationStatus.FIRMADO];

    return allowedStatuses.includes(this.idStatusIsothermalCharacterization);
  }

  styleUploadExcelButton(variableIndex: number, sensorIndex: number): string {
    const sensor = this.getSensor(variableIndex, sensorIndex);

    return (!this.disableUploadExcelButton() && sensor.idExcel != null) ? 'blueIcon' : 'grayIcon';
  }

  styleUploadOriginalButton(variableIndex: number, sensorIndex: number): string {
    const sensor = this.getSensor(variableIndex, sensorIndex);

    return (!this.disableUploadOriginalButton() && sensor.idPrimaryDataFile != null) ? 'blueIcon' : 'grayIcon';
  }

  showExcelButton(): boolean {
    return true;
  }

  showPrimaryButton(): boolean {
    return true;
  }

  excelAlreadyUploaded(variableIndex: number, sensorIndex: number): boolean {
    const sensor = this.getSensor(variableIndex, sensorIndex);
    return sensor.idExcel != null;
  }

  primaryAlreadyUploaded(variableIndex: number, sensorIndex: number): boolean {
    const sensor = this.getSensor(variableIndex, sensorIndex);
    return sensor.idPrimaryDataFile != null;
  }

  openDialogSensorExcel(variableIndex: number, sensorIndex: number): void {
    const variableSelected = this._variables[variableIndex];
    const sensor = this.getSensor(variableIndex, sensorIndex);

    const dialogRef = this.dialog.open(IsothermalCharacterizationEditAttachmentDetailsComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        idSensor: sensor.id,
        idAttachment: sensor.idExcel,
        accept: '.xlsx, .xls, .csv, .pdf',
        isPhoto: false,
        disableUpload: this.disableUploadExcelButton(),
        sensor,
        idVariable: variableSelected.idVariable,
        type: 'excel'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: IsothermalCharacterizationSensor) => {
      if (result !== null && result !== undefined) {
        this.setSensor(variableIndex, sensorIndex, result);

        this.reloadGraphMap();
      }
    });
  }

  openDialogSensorPrimary(variableIndex: number, sensorIndex: number): void {
    const variableSelected = this._variables[variableIndex];
    const sensor = this.getSensor(variableIndex, sensorIndex);

    const dialogRef = this.dialog.open(IsothermalCharacterizationEditAttachmentDetailsComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        idSensor: sensor.id,
        idAttachment: sensor.idPrimaryDataFile,
        accept: '*',
        isPhoto: false,
        disableUpload: this.disableUploadOriginalButton(),
        sensor,
        idVariable: variableSelected.idVariable,
        type: 'primary'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: IsothermalCharacterizationSensor) => {
      if (result !== null && result !== undefined) {
        this.setSensor(variableIndex, sensorIndex, result);
      }
    });
  }


  onFcStartEssayEmit(event: any): void {
    this.fcStartEssayEmitter.next(event);

    const init = _.cloneDeep(event.fcStartEssay) as Date;
    const end = _.cloneDeep(event.fcEndEssay) as Date;

    if (event.variableIndex != null) {
      this._variables[event.variableIndex].fcStartEssay = init;
      this._variables[event.variableIndex].fcEndEssay = end;

      if (!this.graph.showCopyDateFirstSensor()) {
        this.copyGraphDateMap.forEach((value: boolean, key: number) => {
          if (key !== event.variableIndex && value) {
            this._variables[key].fcStartEssay = init;
            this._variables[key].fcEndEssay = end;
          }
        });
      }
    }
  }

  showDialogDiscardTime(variableSelected: IsothermalCharacterizationVariable): void {
    const dats = this.graphMap.get(variableSelected.idVariable);

    const dialogRef = this.dialog.open(IsothermalCharacterizationEditDiscardTimeComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        idEssay: variableSelected.id,
        idIsothermalCharacterization: this.idIsothermalCharacterization,
        data: dats
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res.valid === true) {
        this.graphMap.set(variableSelected.idVariable, res.data as SensorData[]);
        this.graph.data = res.data;
      }
    });

  }

  showDialogConfigSensor(variableIndex: number, sensorIndex: number): void {
    const sensor = this.getSensor(variableIndex, sensorIndex);

    const isLastSensor = this._variables[variableIndex].sensors.filter(s => s.id !== sensor.id && s.evaluate).length < 1;

    const dialogRef = this.dialog.open(IsothermalCharacterizationEditConfigSensorComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        sensor: _.cloneDeep(sensor),
        isLastSensor
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: IsothermalCharacterizationSensor) => {
      if (result != null) {
        this.setSensor(variableIndex, sensorIndex, result);
        this.updateSensorDependency(variableIndex, sensorIndex, 'config');

        const variableSelected = this._variables[variableIndex];
        variableSelected.sensors[sensorIndex] = result;

        this.graph.originalSensors = variableSelected.sensors;
        this.graph.recalculateGraph();
      }
    });

  }

  isSelectedMulti(valuesStr: string | string[], valueCheck: string): boolean {
    if (valuesStr == null || valueCheck == null) {
      return false;
    }

    let values = [];
    if (typeof valuesStr === 'string') {
      values = valuesStr.split(';');
    } else {
      values = valuesStr;
    }

    return values.includes(valueCheck);
  }

  private generatePromisesMassiveAutomatic(variableIndex: number, files: File[]): Promise<any> {
    const filesPromises = new Map<File, Promise<any>>();
    files.forEach(f => filesPromises.set(f, new Promise<void>((resolve) => {
      void this.generatePromiseMassiveAutomatic(variableIndex, f);

      resolve();
    })));

    return new Promise((resolve) => {
      void Promise.all(filesPromises).then(resolve);
    });
  }

  private async generatePromiseMassiveAutomatic(variableIndex: number, file: File): Promise<any> {
    const variableSelected = this._variables[variableIndex];
    const sensors = variableSelected.sensors;

    const fileName: string = file.name;
    let eqName = fileName.substring(0, file.name.indexOf(' '));

    if (eqName === '') {
      eqName = fileName.substring(0, fileName.lastIndexOf('.'));
    }

    return new Promise<void>((resolve) => {
      const possibleIndex = +eqName;

      if (!isNaN(possibleIndex) && typeof sensors[possibleIndex - 1] !== 'undefined') {
        const indexSensor = possibleIndex;
        const sensor = sensors[indexSensor - 1];

        this.resolvePromiseMassiveAutomatic(variableIndex, indexSensor, sensor, file, resolve);
      } else {
        let checked = false;

        for (let indexSensor = 0; !checked && indexSensor < sensors.length; indexSensor++) {
          const sensor = sensors[indexSensor];

          if (eqName.toLowerCase() === sensor.equipmentName.toLowerCase() || eqName.toLowerCase() === sensor.serialNum.toLowerCase()) {
            this.resolvePromiseMassiveAutomatic(variableIndex, indexSensor, sensor, file, resolve);
            checked = true;
          }
        }
      }

    });
  }

  private resolvePromiseMassiveAutomatic(variableIndex: number, indexSensor: number, sensor: IsothermalCharacterizationSensor, file: File,
    resolve: ((value: void | PromiseLike<void>) => void)): void {
    const variableSelected = this._variables[variableIndex];

    const fileName: string = file.name;
    let eqName = fileName.substring(0, file.name.indexOf(' '));

    if (eqName === '') {
      eqName = fileName.substring(0, fileName.lastIndexOf('.'));
    }

    const excelExts = ['.xls', '.xlsx', '.csv', '.pdf'];

    const ext = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

    if (excelExts.indexOf(ext) > -1) {

      if (file.name.endsWith('.pdf')) {
        this.spinnerService.show();

        this.attachmentThermalService.convertPdfToCsv(file, variableSelected.idVariable).pipe(takeUntil(this.destroy$))
          .subscribe(res => {
            res.name = file.name;
            this.uploadPromiseMassiveDataExcel(variableIndex, indexSensor, res as File, resolve());
          });
      } else {
        this.uploadPromiseMassiveDataExcel(variableIndex, indexSensor, file, resolve());
      }

    } else {
      this.attachmentCalibratesService.uploadPrimaryFileToSensorCharacterization(sensor.id, file).pipe(takeUntil(this.destroy$))
        .subscribe((response: number) => {
          sensor.idPrimaryDataFile = response;

          this.setSensor(variableIndex, indexSensor, sensor);

          resolve();
        });
    }
  }

  private setUncertaintiesSensor(
    variableIndex: number, sensorIndex: number, sensor: IsothermalCharacterizationSensor, uncertainties: CalibrationPatternValue[],
    updateDependency = true) {

    if (!ArrayUtils.isEmpty(uncertainties)) {
      const emptyList: CalibrationPatternValue[] = [];
      const sorted = emptyList.concat(...uncertainties).sort((u1, u2) => u1.value - u2.value);
      const reversed = emptyList.concat(...uncertainties).sort((u1, u2) => u1.value - u2.value).reverse();

      const setpoint = this._variables[variableIndex].setpoint;

      if (setpoint == null || isNaN(+setpoint)) {
        this.snackBarService.sendWarn(
          this.translate.instant('isothermalCharacterizationEdit.essays.sensor.form.warn.uncertainty') as string);

        sensor.uncertainty = Math.max(...uncertainties.map(a => a.uncertainty));
      } else {
        const point = (+setpoint);
        const exact = uncertainties.find(u => u.value === point);

        if (exact != null) {
          sensor.uncertainty = exact.uncertainty;
        } else {
          const high = sorted.find(u => u.value > point);
          const low = reversed.find(u => u.value < point);

          if (high != null && low != null) {
            sensor.uncertainty = Math.max(high.uncertainty, low.uncertainty);
          } else if (high != null && low == null) {
            sensor.uncertainty = high.uncertainty;
          } else if (high == null && low != null) {
            sensor.uncertainty = low.uncertainty;
          }
        }

      }
    } else {
      sensor.uncertainty = null;
    }

    this.setSensor(variableIndex, sensorIndex, sensor);

    if (updateDependency) {
      this.updateSensorDependency(variableIndex, sensorIndex, 'uncertainty');
    }
  }

  private setUncertaintySensor(variableIndex: number, sensorIndex: number, idEquipment: number, updateDependency = true) {
    const variable = this._variables[variableIndex];
    const idVariable = variable.idVariable;
    const sensor = variable.sensors[sensorIndex];

    if (idEquipment == null) {

      this.internalEquipmentService.findOneByName(sensor.equipmentName).pipe(takeUntil(this.destroy$))
        .subscribe((res: InternalEquipment) => {
          this.setUncertaintySensor(variableIndex, sensorIndex, res.id, updateDependency);
        });

      return;
    }

    this.spinnerService.show();

    this.calibrationService.findUncertaintyFromEquipment(idEquipment, idVariable).pipe(takeUntil(this.destroy$))
      .subscribe((res: CalibrationUncertaintiesValues) => {
        this.spinnerService.hide();
        const uncertainties = res.values;
        this.setUncertaintiesSensor(variableIndex, sensorIndex, sensor, uncertainties, updateDependency);
      }, () => {
        this.spinnerService.hide();
        this.setUncertaintiesSensor(variableIndex, sensorIndex, sensor, [], updateDependency);

      });
  }

  private setUncertaintyCalibration(variableIndex: number, calibrationIndex: number, idEquipment: number, idVariable: number) {
    this.spinnerService.show();
    this.calibrationService.findUncertaintyFromEquipment(idEquipment, idVariable).pipe(takeUntil(this.destroy$))
      .subscribe((res: CalibrationUncertaintiesValues) => {
        this.spinnerService.hide();
        const uncertainties = res.values;
        this.setUncertaintiesCalibration(variableIndex, calibrationIndex, uncertainties);
      }, () => {
        this.spinnerService.hide();
        this.setUncertaintiesCalibration(variableIndex, calibrationIndex, []);

      });
  }

  private setUncertaintiesCalibration(variableIndex: number, calibrationIndex: number, uncertainties: CalibrationPatternValue[]) {
    const sensor = this._variables[variableIndex].calibrations[calibrationIndex];

    if (!ArrayUtils.isEmpty(uncertainties)) {
      const emptyList: CalibrationPatternValue[] = [];
      const sorted = emptyList.concat(...uncertainties).sort((u1, u2) => u1.value - u2.value);
      const reversed = emptyList.concat(...uncertainties).sort((u1, u2) => u1.value - u2.value).reverse();

      const setpoint = this._variables[variableIndex].setpoint;

      if (setpoint == null || isNaN(+setpoint)) {
        this.setUncertaintiesCalibration(variableIndex, calibrationIndex, []);
      }

      const point = (+setpoint);
      const exact = uncertainties.find(u => u.value === point);

      if (exact != null) {
        sensor.uncertainty = exact.uncertainty.toString();
      } else {
        const high = sorted.find(u => u.value > point);
        const low = reversed.find(u => u.value < point);

        if (high != null && low != null) {
          sensor.uncertainty = Math.max(high.uncertainty, low.uncertainty).toString();
        } else if (high != null && low == null) {
          sensor.uncertainty = high.uncertainty.toString();
        } else if (high == null && low != null) {
          sensor.uncertainty = low.uncertainty.toString();
        }

      }
    } else {
      sensor.uncertainty = null;
    }

  }

  private updateGraphByIndex(variableIndex: number) {
    if (this.graph == null) {
      return;
    }

    const variableSelected = this._variables[variableIndex];

    this.graph.variableIndex = variableIndex;
    this.graph.idStatus = this.idStatusIsothermalCharacterization;

    if (variableSelected != null) {
      this.graph.reasonModifyExpositionTime = variableSelected.reasonModifyExpositionTime;
      this.graph.idTypeVariable = variableSelected.idVariable;
      this.graph.unitName = variableSelected.unitName;

      this.graph.fcStartEssay = variableSelected.fcStartEssay as Date;
      this.graph.fcEndEssay = variableSelected.fcEndEssay as Date;

      this.graph.originalSensors = variableSelected.sensors;
      this.graph.criterias = variableSelected.criterias;
    } else {
      this.graph.idTypeVariable = null;

      this.graph.fcStartEssay = null;
      this.graph.fcEndEssay = null;

      this.graph.originalSensors = [];
    }

    this.graph.changeTimezone = false;

    this.graph.range = this.rehearsalTime;
    this.graph.fullEssay = false;

    if (variableIndex !== 0) {
      const value = this.getValueFromCopyGraphDateMap(variableIndex);

      this.graph.copyDateFirstSensor = value;
    }

    let data: SensorData[] = [];

    if (variableSelected != null) {
      data = this.graphMap.get(variableSelected.idVariable);

      if (variableSelected.fcStartEssay == null) {

        if (data != null && data.length !== 0) {
          let dates = data.map(d => d.date);

          dates = dates.filter((n, i) => dates.indexOf(n) === i).sort((d1, d2) => d1.getTime() - d2.getTime());

          const minDate = dates[0];
          variableSelected.fcStartEssay = minDate;
          this.onFcStartEssayEmit({
            variableIndex,
            fcStartEssay: variableSelected.fcStartEssay as Date,
            fcEndEssay: variableSelected.fcEndEssay as Date,
            changeTimezone: false
          });

        }
      }

      this.graph.data = data;
    }
  }

  private uploadMassiveDataExcel(variableIndex: number, file: File) {
    const variableSelected = this._variables[variableIndex];

    const allSensors = variableSelected.sensors;
    const sensors = allSensors.filter(s => s.evaluate);
    const totalSensors = allSensors.length;

    this.uploadExcelMassiveInput.nativeElement.value = '';

    this.spinnerService.show();

    const sensorsName = sensors.map(s => s.equipmentName);
    const sensorsSerialNum = sensors.map(s => s.serialNum);

    const idVariable = variableSelected.idVariable;

    ExcelUtils.excelToSensorData(file, this.attachmentThermalService, idVariable, true, totalSensors).then((sensorsData: SensorData[]) => {

      if (sensorsData == null || sensorsData.length === 0) {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadExcel.error.generic') as string);

        return;
      }

      const uniqueName = sensorsData.map(sD => sD.equipmentName).filter((v, i, a) => a.indexOf(v) === i);
      const uniqueSerialNum = sensorsData.map(sD => sD.serialNum).filter((v, i, a) => a.indexOf(v) === i);

      let promises = [];

      const anyByName = uniqueName.length != null && uniqueName.filter(name => sensorsName.includes(name)).length !== 0;
      const anyBySerialNum = uniqueSerialNum.length != null && uniqueSerialNum.filter(name => sensorsSerialNum.includes(name)).length !== 0;

      if (anyByName) {
        promises = this.generatePromisesExcelName(sensorsData, allSensors, variableIndex, file);
      } else if (anyBySerialNum) {
        promises = this.generatePromisesExcelSerialNum(sensorsData, allSensors, variableIndex, file);
      } else {
        promises = this.generatePromisesExcelPosition(sensorsData, sensors, variableIndex, file);
      }

      Promise.all(promises.filter(p => p != null)).then(() => {
        this.updateGraphByIndex(variableIndex);
        this.spinnerService.hide();
        this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.uploadExcel.ok') as string);
      }).catch(err => {
        console.error(err);
        this.snackBarService.sendError(err as string);
        this.updateGraphByIndex(variableIndex);
        this.spinnerService.hide();
      });

    }).catch(err => {
      console.error(err);
      this.snackBarService.sendError(err as string);
      this.spinnerService.hide();
    });
  }

  private generatePromisesExcelName(
    sensorsData: SensorData[], sensors: IsothermalCharacterizationSensor[], variableIndex: number, file: File) {
    const promises: Promise<void>[] = [];

    const variableSelected = this._variables[variableIndex];

    const groupByNameAux = ArrayUtils.groupBy(sensorsData, (item: SensorData) => item.equipmentName) as Map<string, SensorData[]>;

    const groupByName = new Map<string, SensorData[]>();

    const sensorsConsider = sensors.filter(s => s.evaluate).map(s => s.equipmentName);

    groupByNameAux.forEach((val, key) => {
      if (sensorsConsider.includes(key)) {
        groupByName.set(key, val);
      }
    });

    groupByName.forEach((data, name) => {
      promises.push(new Promise<void>((resolve, reject) => {
        const sensor = sensors.find(s => s.equipmentName === name);

        if (sensor == null || !sensor.evaluate) {
          return;
        }

        const sensorIndex = sensors.indexOf(sensor);

        data.forEach(item => {
          item.serialNum = sensor.serialNum;
          item.equipmentName = sensor.equipmentName;
          item.idEquipment = sensor.idEquipment;
          delete item.posEquipment;
        });

        data = data.filter(item => item.date != null && !isNaN(item.date.getTime()));

        const map = ArrayUtils.groupBy(data, (item: { idVariable: number }) => item.idVariable) as Map<number, SensorData[]>;

        if (map.size > 1) {
          data = map.get(variableSelected.idVariable);
        }

        this.uploadExcelFromMassive(variableIndex, sensorIndex, file, data, resolve, reject);
      }));
    });

    return promises;
  }

  private generatePromisesExcelSerialNum(
    sensorsData: SensorData[], sensors: IsothermalCharacterizationSensor[], variableIndex: number, file: File) {
    const promises: Promise<void>[] = [];

    const variableSelected = this._variables[variableIndex];

    const groupBySerialNumAux = ArrayUtils.groupBy(sensorsData, (item: SensorData) => item.serialNum) as Map<string, SensorData[]>;
    const groupBySerialNum = new Map<string, SensorData[]>();

    const sensorsConsider = sensors.filter(s => s.evaluate).map(s => s.serialNum);

    groupBySerialNumAux.forEach((val, key) => {
      if (sensorsConsider.includes(key)) {
        groupBySerialNum.set(key, val);
      }
    });

    sensors.filter(s => !s.evaluate).map(s => s.serialNum).forEach(s => groupBySerialNum.delete(s));

    groupBySerialNum.forEach((data, name) => {
      promises.push(new Promise<void>((resolve, reject) => {
        const sensor = sensors.find(s => s.serialNum === name);

        if (sensor == null || !sensor.evaluate) {
          return;
        }

        const sensorIndex = sensors.indexOf(sensor);

        data.forEach(item => {
          item.serialNum = sensor.serialNum;
          item.equipmentName = sensor.equipmentName;
          item.idEquipment = sensor.idEquipment;
          delete item.posEquipment;
        });

        data = data.filter(item => item.date != null && !isNaN(item.date.getTime()));

        const map = ArrayUtils.groupBy(data, (item: { idVariable: number }) => item.idVariable) as Map<number, SensorData[]>;

        if (map.size > 1) {
          data = map.get(variableSelected.idVariable);
        }

        this.uploadExcelFromMassive(variableIndex, sensorIndex, file, data, resolve, reject);
      }));
    });

    return promises;
  }

  private getValueFromCopyGraphDateMap(variableIndex: number): boolean {
    let res = false;

    this.copyGraphDateMap.forEach((value, key) => {
      if (key === variableIndex) {
        res = value;
        return;
      }
    });

    return res;
  }

  private uploadDataExcel(variableIndex: number, sensorIndex: number, file: File) {
    const variableSelected = this._variables[variableIndex];

    const sensor = this.getSensor(variableIndex, sensorIndex);

    this.spinnerService.show();

    ExcelUtils.excelToSensorData(file, this.attachmentThermalService, variableSelected.idVariable, false, 1).then((data: SensorData[]) => {

      data.forEach(item => {
        item.serialNum = sensor.serialNum;
        item.equipmentName = sensor.equipmentName;
        item.idEquipment = sensor.idEquipment;
      });

      const map = ArrayUtils.groupBy(data, (item: SensorData) => item.idVariable) as Map<number, SensorData[]>;

      if (map.size > 1) {
        data = map.get(variableSelected.idVariable);
      }

      data.forEach(item => delete item.posEquipment);

      sensor.data = data;
      this.setSensor(variableIndex, sensorIndex, sensor);

      data = data.filter(item => item.date != null && !isNaN(item.date.getTime()));

      this.attachmentCalibratesService.uploadExcelToSensorCharacterization(sensor.id, file, data).pipe(takeUntil(this.destroy$))
        .subscribe((item: number) => {
          sensor.idExcel = item;

          sensor.data = data;

          this.setSensor(variableIndex, sensorIndex, sensor);

          let mapValue: SensorData[] = [];
          variableSelected.sensors.filter(sen => sen.data != null && sen.data.length !== 0).forEach(sen => {
            mapValue = [...mapValue, ...sen.data];
          });

          this.graphMap.set(variableSelected.idVariable, mapValue);

          this.updateGraphByIndex(variableIndex);

          this.spinnerService.hide();

          this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.uploadExcel.ok') as string);
        }, () => {
          this.spinnerService.hide();

          this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadExcel.error.generic') as string);
        });

    }).catch(err => {
      if (err != null && err.error != null && typeof err.error === 'string') {
        this.snackBarService.sendError(err.error as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.uploadExcel.error.generic') as string);
      }

      this.spinnerService.hide();
    });
  }

  private generatePromisesExcelPosition(
    sensorsData: SensorData[], sensors: IsothermalCharacterizationSensor[], variableIndex: number, file: File) {
    const promises: Promise<void>[] = [];

    const variableSelected = this._variables[variableIndex];

    const groupByNum = ArrayUtils.groupBy(sensorsData, (item: SensorData) => item.posEquipment) as Map<number, SensorData[]>;

    groupByNum.forEach((data, sensorIndex) => {
      promises.push(new Promise<void>((resolve, reject) => {
        const sensor = sensors[sensorIndex];

        data.forEach(item => {
          item.serialNum = sensor.serialNum;
          item.equipmentName = sensor.equipmentName;
          item.idEquipment = sensor.idEquipment;
          delete item.posEquipment;
        });

        data = data.filter(item => item.date != null && !isNaN(item.date.getTime()));

        const map = ArrayUtils.groupBy(data, (item: SensorData) => item.idVariable) as Map<number, SensorData[]>;

        if (map.size > 1) {
          data = map.get(variableSelected.idVariable);
        }

        this.uploadExcelFromMassive(variableIndex, sensorIndex, file, data, resolve, reject);
      }));
    });

    return promises;
  }

  private uploadExcelFromMassive(
    variableIndex: number, sensorIndex: number, file: File, data: SensorData[], callback: () => void, errorCallback: () => void) {
    const variableSelected = this._variables[variableIndex];
    const sensor = this.getSensor(variableIndex, sensorIndex);

    this.attachmentCalibratesService.uploadExcelToSensorCharacterization(sensor.id, file, data).pipe(takeUntil(this.destroy$))
      .subscribe((item: number) => {
        sensor.idExcel = item;
        sensor.data = data;

        data.forEach(d => {
          d.serialNum = sensor.serialNum;
          d.equipmentName = sensor.equipmentName;
          d.idEquipment = sensor.idEquipment;
        });

        this.setSensor(variableIndex, sensorIndex, sensor);
        this.updateSensorDependency(variableIndex, sensorIndex, 'primary');

        let mapValue: SensorData[] = [];
        variableSelected.sensors.filter(sen => sen.data != null && sen.data.length !== 0).forEach(sen => {
          mapValue = [...mapValue, ...sen.data];
        });

        this.graphMap.set(variableSelected.idVariable, mapValue);

        callback();
      }, () => errorCallback());
  }

  private uploadPromiseMassiveDataExcel(variableIndex: number, indexSensor: number, file: File, callback) {
    const variableSelected = this._variables[variableIndex];
    const sensors = variableSelected.sensors;
    const sensor = sensors[indexSensor];

    void ExcelUtils.excelToSensorData(file, this.attachmentThermalService, variableSelected.idVariable, false, 1).then(res => {
      res.forEach(item => {
        item.serialNum = sensor.serialNum;
        item.equipmentName = sensor.equipmentName;
        item.idEquipment = sensor.idEquipment;
      });

      const map = ArrayUtils.groupBy(res, (item: SensorData) => item.idVariable) as Map<number, SensorData[]>;

      if (map.size > 1) {
        res = map.get(variableSelected.idVariable);
      }

      res.forEach(item => delete item.posEquipment);

      res = res.filter(item => item.date != null && !isNaN(item.date.getTime()));

      this.attachmentCalibratesService.uploadExcelToSensorCharacterization(sensor.id, file, res).pipe(takeUntil(this.destroy$))
        .subscribe((response: number) => {
          sensor.idExcel = response;
          sensor.data = res;

          this.setSensor(variableIndex, indexSensor, sensor);

          callback();
        });
    });
  }
  private getSensor(variableIndex: number, sensorIndex: number): IsothermalCharacterizationSensor {
    return this._variables[variableIndex].sensors[sensorIndex];
  }

  private setSensor(variableIndex: number, sensorIndex: number, sensor: IsothermalCharacterizationSensor): void {
    this._variables[variableIndex].sensors[sensorIndex] = sensor;

    const value = { variableIndex, sensorIndex, sensor };

    this.updateSensorEssayEmitter.emit(value);
  }

}
