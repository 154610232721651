import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TimezoneInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const clonedReq = req.clone({
            headers: req.headers.set('timeZone', Intl.DateTimeFormat().resolvedOptions().timeZone)
        });

        return next.handle(clonedReq);
    }

}
