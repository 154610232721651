<h1 mat-dialog-title>{{ 'executionEdit.dialog.correctSensor.title' | translate }}</h1>
<form>
    <div mat-dialog-content class="thermal-theme">

        <div fxLayout="row" class="flexwrap" *ngFor="let sensor of data.sensors; let indexSensor = index">
            <div fxFlex="15" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput value="{{ indexSensor + 1 }}" disabled
                        placeholder="{{ 'protocolEdit.dialog.essay.sensors.sensorNum.label' | translate }}">
                </mat-form-field>
            </div>
            <div fxFlex="45" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput value="{{ showSensorName(sensor) }}" disabled
                        placeholder="{{ 'protocolEdit.dialog.essay.sensors.location.label' | translate }}">
                </mat-form-field>
            </div>
            <div fxFlex="25" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput value="{{ sensor.equipmentName }}" disabled
                        placeholder="{{ 'protocolEdit.dialog.essay.sensors.serialNum.label' | translate }}">
                </mat-form-field>
            </div>
            <div fxFlex="15" fxFlex.lt-md="100%">
                <mat-checkbox name="correct{{indexSensor}}" color="primary" [checked]="isChecked(sensor)"
                    (change)="setChecked(sensor, $event)" style="margin-top: 1em;">
                </mat-checkbox>
            </div>

        </div>

    </div>
    <div mat-dialog-actions class="thermal-theme">
        <div fxFlex></div>

        <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>

        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>

</form>