import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericErrorComponent } from './generic/generic.component';
import { ErrorNotFoundComponent } from './not-found/not-found.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18n } from 'src/app/utils/paginatorI18n';

@NgModule({
  declarations: [
    GenericErrorComponent, ErrorNotFoundComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    TranslateModule,

    /* Custom modules */
    AngularMaterialModule,
    PipesModule,

    /* Otros */
    FlexLayoutModule
  ],
  providers: [
    {
      provide: MatPaginatorIntl, deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService)
    }
  ],
  exports: [GenericErrorComponent, ErrorNotFoundComponent]
})
export class ErrorModule { }
