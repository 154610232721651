import { Component, OnDestroy } from '@angular/core';

import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { MatDialogRef } from '@angular/material/dialog';
import { RoleUser } from 'src/app/model/roleUser';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { Subject } from 'rxjs';
import { ThemeService } from 'src/app/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
  selector: 'app-change-profile-dialog',
  templateUrl: './change-profile-dialog.component.html'
})
export class ChangeProfileDialogComponent implements OnDestroy {

  selectedRole: RoleUser;
  roles: RoleUser[];

  private destroy$ = new Subject<void>();

  constructor(
    private themeService: ThemeService,
    private userService: UserService,
    private translate: TranslateService,
    private translationService: TranslationService,
    public dialogRef: MatDialogRef<ChangeProfileDialogComponent>,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    const currentProfile = userService.currentProfile;
    const currentRolUser= userService.currentRoleUser;

    this.roles = currentRolUser;
    this.selectedRole =  currentRolUser.find(p => p.idGroup === currentProfile.idActiveProfile);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onOkClick(): void {
    this.spinnerService.show();

    const errors = [];

    if (this.selectedRole == null) {
      errors.push(this.translate.instant('changeProfile.form.role.error'));
    }

    if (ArrayUtils.isEmpty(errors)) {
      const group = this.roles.find(r => r.idGroup === this.selectedRole.idGroup).groupName;
      const app = this.roles.find(r => r.idApp === this.selectedRole.idApp).appName;

      this.userService.changeProfile(this.selectedRole.idGroup, this.selectedRole.idApp).pipe(takeUntil(this.destroy$)).subscribe(item => {
        this.translationService.updateLangs();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.userService.saveProfile(item);
        this.snackBarService.sendSuccess(this.translate.instant('changeProfile.profile.form.save.ok', { group, app }) as string);
        this.dialogRef.close(item);
        this.spinnerService.hide();
      }, () => {
        this.spinnerService.hide();
        this.snackBarService.sendError(this.translate.instant('changeProfile.profile.form.save.error', { group, app }) as string);
      });

    } else {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);
      console.error(error);
      this.spinnerService.hide();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTheme(): string {
    return this.themeService.getTheme();
  }

}
