import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../services/snackBar.service';
import { ThemeService } from '../../../services/theme.service';
import { ArrayUtils } from '../../../utils/arrayUtils';
import { Client } from 'src/app/model/client';
import { ClientService } from '../../../services/client.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { User } from '../../../model/user';
import { UsersService } from '../../../services/users.service';

export interface DialogDataProtocol {
  useExistingProtocol: boolean;
  idClient: number;
  idProtocol: number;
  projectNo: string;
  usernameProjectManager: string;
  idClientProtocol: number;
}

@Component({
  selector: 'app-edit-data-protocol',
  templateUrl: './edit-data-protocol.component.html',
  styleUrls: ['./edit-data-protocol.component.scss']
})
export class EditDataProtocolComponent implements OnInit {
  clientsFiltered: Client[];
  clients: Client[];
  respField: User[];

  private destroy$ = new Subject<void>();
  constructor(
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataProtocol,
    public dialogRef: MatDialogRef<EditDataProtocolComponent>,
    public snackBarService: SnackBarService,
    private themeService: ThemeService,
    private clientService: ClientService,
    private usersService: UsersService) { }

  ngOnInit(): void {
    this.respField = [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    this.usersService.findAllRespFromGroup().pipe(takeUntil(this.destroy$)).subscribe((data: User[]) => this.respField = data);
    this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Client[]) => this.loadClients(data));
  }

  loadClients(res: Client[]): void {
    this.clients = res;
    this.clientsFiltered = this.clients.slice();
  }

  onOkClick(): void {
    const errors = [];

    if (this.data.idClient == null) {
      errors.push(this.translate.instant('protocolEdit.general.client.error'));
    }

    if (ArrayUtils.isEmpty(errors)) {
      this.dialogRef.close(this.data);
    } else {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);
      console.error(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTheme(): string {
    return this.themeService.getTheme();
  }

}
