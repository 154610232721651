import { FieldTypeEnum } from '../model/fieldType';
import { Injectable } from '@angular/core';

@Injectable()
export class Constants {

    // https://www.regextester.com/104030
    public static USERNAME_PATTERN = '[a-z0-9_]{3,16}';

    // https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
    public static PASSWORD_PATTERN = '(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9]).{8,}';

    // https://stackoverflow.com/questions/42366649/angular2-email-validation
    public static EMAIL_PATTERN = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';

    public static FIELD_ID = 'id';

    public static FIELD_USERNAME = 'username';

    public static FIELD_PASSWORD = 'password';

    public static FIELD_TOKEN = 'token';

    public static FIELD_NO_MATCH = 'notMatch';

    public static FIELD_ID_PHASE = 'idPhase';

    public static FIELD_ID_FOLDER = 'idFolder';

    public static FIELD_ID_CLIENT = 'idClient';

    public static FIELD_ID_PROTOCOL = 'idProtocol';

    public static FIELD_ID_PAGE = 'idPage';

    public static FIELD_ID_EQUIPMENT = 'idEquipment';

    public static FIELD_RETURN = 'return';

    public static FIELD_ESSAY_TAB = 'essayTab';

    public static FIELD_VARIABLE_TAB = 'variableTab';

    public static CALIBRATION_PLAN_ALLOWED_FIELD_TYPES = [
        FieldTypeEnum.STRING, FieldTypeEnum.NUMBER, FieldTypeEnum.BOOLEAN, FieldTypeEnum.AREA, FieldTypeEnum.SELECT_ONE
    ];

    public static CALIBRATION_PLAN_FILTERABLES_FIELD_TYPES = [
        FieldTypeEnum.STRING, FieldTypeEnum.NUMBER, FieldTypeEnum.BOOLEAN, FieldTypeEnum.AREA, FieldTypeEnum.SELECT_ONE
    ];

    public static PARTICLE_COUNT_ISO_1_0_1 = 10;
    public static PARTICLE_COUNT_ISO_1_0_2 = 2;
    public static PARTICLE_COUNT_ISO_1_0_3 = 0;
    public static PARTICLE_COUNT_ISO_1_0_5 = 0;
    public static PARTICLE_COUNT_ISO_1_1_0 = 0;
    public static PARTICLE_COUNT_ISO_1_5_0 = 0;

    public static PARTICLE_COUNT_ISO_2_0_1 = 100;
    public static PARTICLE_COUNT_ISO_2_0_2 = 24;
    public static PARTICLE_COUNT_ISO_2_0_3 = 10;
    public static PARTICLE_COUNT_ISO_2_0_5 = 4;
    public static PARTICLE_COUNT_ISO_2_1_0 = 0;
    public static PARTICLE_COUNT_ISO_2_5_0 = 0;

    public static PARTICLE_COUNT_ISO_3_0_1 = 1_000;
    public static PARTICLE_COUNT_ISO_3_0_2 = 237;
    public static PARTICLE_COUNT_ISO_3_0_3 = 102;
    public static PARTICLE_COUNT_ISO_3_0_5 = 35;
    public static PARTICLE_COUNT_ISO_3_1_0 = 8;
    public static PARTICLE_COUNT_ISO_3_5_0 = 0;

    public static PARTICLE_COUNT_ISO_4_0_1 = 10_000;
    public static PARTICLE_COUNT_ISO_4_0_2 = 2_370;
    public static PARTICLE_COUNT_ISO_4_0_3 = 1_020;
    public static PARTICLE_COUNT_ISO_4_0_5 = 352;
    public static PARTICLE_COUNT_ISO_4_1_0 = 83;
    public static PARTICLE_COUNT_ISO_4_5_0 = 0;

    public static PARTICLE_COUNT_ISO_5_0_1 = 100_000;
    public static PARTICLE_COUNT_ISO_5_0_2 = 23_700;
    public static PARTICLE_COUNT_ISO_5_0_3 = 10_200;
    public static PARTICLE_COUNT_ISO_5_0_5 = 3_520;
    public static PARTICLE_COUNT_ISO_5_1_0 = 832;
    public static PARTICLE_COUNT_ISO_5_5_0 = 29;

    public static PARTICLE_COUNT_ISO_6_0_1 = 1_000_000;
    public static PARTICLE_COUNT_ISO_6_0_2 = 237_000;
    public static PARTICLE_COUNT_ISO_6_0_3 = 102_000;
    public static PARTICLE_COUNT_ISO_6_0_5 = 35_200;
    public static PARTICLE_COUNT_ISO_6_1_0 = 8_320;
    public static PARTICLE_COUNT_ISO_6_5_0 = 293;

    public static PARTICLE_COUNT_ISO_7_0_5 = 352_000;
    public static PARTICLE_COUNT_ISO_7_1_0 = 83_200;
    public static PARTICLE_COUNT_ISO_7_5_0 = 2_920;

    public static PARTICLE_COUNT_ISO_8_0_5 = 3_520_000;
    public static PARTICLE_COUNT_ISO_8_1_0 = 832_000;
    public static PARTICLE_COUNT_ISO_8_5_0 = 29_300;

    public static PARTICLE_COUNT_ISO_9_0_5 = 35_200_000;
    public static PARTICLE_COUNT_ISO_9_1_0 = 8_320_000;
    public static PARTICLE_COUNT_ISO_9_5_0 = 293_000;

    public static PARTICLE_COUNT_GRADE_A_0_5 = 3_520;
    public static PARTICLE_COUNT_GRADE_A_5_0 = 20;

    public static PARTICLE_COUNT_GRADE_B_0_5_REPOSE = 3_520;
    public static PARTICLE_COUNT_GRADE_B_0_5_WORKING = 352_000;
    public static PARTICLE_COUNT_GRADE_B_5_0_REPOSE = 29;
    public static PARTICLE_COUNT_GRADE_B_5_0_WORKING = 2_900;

    public static PARTICLE_COUNT_GRADE_C_0_5_REPOSE = 352_000;
    public static PARTICLE_COUNT_GRADE_C_0_5_WORKING = 3_520_000;
    public static PARTICLE_COUNT_GRADE_C_5_0_REPOSE = 2_900;
    public static PARTICLE_COUNT_GRADE_C_5_0_WORKING = 29_000;

    public static PARTICLE_COUNT_GRADE_D_0_5_REPOSE = 3_520_000;
    public static PARTICLE_COUNT_GRADE_D_5_0_REPOSE = 29_000;

    public static PARTICLE_COUNT_GRADE_A_ANEXOI = 20;
    public static PARTICLE_COUNT_GRADE_B_ANEXOI = 29;

    public static PARTICLE_COUNT_GRADE_A_0_5_ANEXOI_REPOSE = 3_520;
    public static PARTICLE_COUNT_GRADE_A_0_5_ANEXOI_WORKING = 3_520;

    public static PARTICLE_COUNT_GRADE_B_0_5_ANEXOI_REPOSE = 3_520;
    public static PARTICLE_COUNT_GRADE_B_0_5_ANEXOI_WORKING = 352_000;
    public static PARTICLE_COUNT_GRADE_B_5_0_ANEXOI_WORKING = 2_930;

    public static PARTICLE_COUNT_GRADE_C_0_5_ANEXOI_REPOSE = 352_000;
    public static PARTICLE_COUNT_GRADE_C_0_5_ANEXOI_WORKING = 3_520_000;
    public static PARTICLE_COUNT_GRADE_C_5_0_ANEXOI_REPOSE = 2_930;
    public static PARTICLE_COUNT_GRADE_C_5_0_ANEXOI_WORKING = 29_300;

    public static PARTICLE_COUNT_GRADE_D_0_5_ANEXOI_REPOSE = 3_520_000;
    public static PARTICLE_COUNT_GRADE_D_5_0_ANEXOI_REPOSE = 29_300;

    public static DEW_POINT_CLASS_1 = -70;
    public static DEW_POINT_CLASS_2 = -40;
    public static DEW_POINT_CLASS_3 = -20;
    public static DEW_POINT_CLASS_4 = 3;
    public static DEW_POINT_CLASS_5 = 7;
    public static DEW_POINT_CLASS_6 = 10;

    public static PARTICLE_COUNT_ISO_8573_2_0_5 = 400_000;
    public static PARTICLE_COUNT_ISO_8573_2_1_0 = 6_000;
    public static PARTICLE_COUNT_ISO_8573_2_5_0 = 100;

    public static PARTICLE_COUNT_ISO_8573_1_0_5 = 20_000;
    public static PARTICLE_COUNT_ISO_8573_1_1_0 = 400;
    public static PARTICLE_COUNT_ISO_8573_1_5_0 = 10;

    public static PARTICLE_COUNT_ISO_8573_3_1_0 = 90_000;
    public static PARTICLE_COUNT_ISO_8573_3_5_0 = 1_000;

    public static PARTICLE_COUNT_ISO_8573_4_5_0 = 10_000;

    public static PARTICLE_COUNT_ISO_8573_5_5_0 = 100_000;

    public static APP_THERMAL = 1;
    public static APP_CALIBRATES = 2;
    public static APP_AIR = 3;
    public static APP_QUALIFICATES = 4;

    public static USER_ROL_GUEST = 6;
}
