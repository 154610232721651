import {
    IsothermalCharacterization,
    IsothermalCharacterizationCalibration,
    IsothermalCharacterizationCalibrationValue,
    IsothermalCharacterizationVariable
} from '../model/isothermalCharacterization';

import { ArrayUtils } from './arrayUtils';
import { SensorUtils } from './sensorUtils';
import { StringUtils } from './stringUtils';
import { TranslateService } from '@ngx-translate/core';

export class IsothermalCharacterizationUtils {

    static objectToVariable(item): IsothermalCharacterizationVariable {

        if (item instanceof IsothermalCharacterizationVariable) {
            return item;
        } else {
            const s = new IsothermalCharacterizationVariable();

            for (const variableProp in item) {
                if (variableProp !== 'sensors' && variableProp !== 'calibration') {
                    s[variableProp] = item[variableProp];
                }
            }

            const calib = new IsothermalCharacterizationCalibration();
            calib.values = [];

            const calibrations = item.calibrations;

            const calibs = calibrations.map(calibration => {

                for (const calibrationProp in calibration) {
                    if (calibrationProp !== 'values') {
                        try {
                            calib[calibrationProp] = calibration[calibrationProp];
                        } catch (e) { }
                    }
                }

                for (const val of calibration.values) {
                    const value = new IsothermalCharacterizationCalibrationValue();

                    value.id = val.id;
                    value.value = val.value;

                    calib.values.push(value);
                }

                calib.recalculateValues();

                return calib;
            });

            s.calibrations = calibs;

            s.sensors = item.sensors.map((sensor: any) => SensorUtils.objectToIsothermalSensor(sensor, s.idVariable))
                .sort((s1, s2) => s1.id - s2.id);

            return s;
        }
    }



    public static getNameForReportZip(iso: IsothermalCharacterization, translate: TranslateService): string {
        const name = this.getNameForCharacterization(iso, translate);

        return translate.instant('executionEdit.files.report-zip', { name }) as string;
    }

    public static getNameForCharacterization(iso: IsothermalCharacterization, translate: TranslateService): string {
        const isoAny: any = iso; // Typescript es maravilloso

        const documentCode = iso.documentCode;
        const versionName = 'v.' + iso.currentVersion.toString().padStart(2, '0');
        let clientName = null;
        let equipment = null;
        let serialNum = null;
        let internalId = null;

        if (!ArrayUtils.isEmpty(iso.equipments)) {
            const eq = iso.equipments[0];

            equipment = eq.equipment;
            if (StringUtils.isNotEmpty(eq.serialNum)) {
                serialNum = '(' + eq.serialNum + ')';
            }

            if (StringUtils.isNotEmpty(eq.internalId)) {
                internalId = '(' + eq.internalId + ')';
            }
        }

        if (isoAny.equipment != null && isoAny.equipment.client != null) {
            clientName = isoAny.equipment.client.name;
        }

        const name = [documentCode, versionName, translate.instant('isothermalCharacterizationEdit.files.characterization.documentType'),
            clientName, equipment, serialNum, internalId].filter((s: string) => StringUtils.isNotEmpty(s)).join(' ');

        return translate.instant('isothermalCharacterizationEdit.files.report', { name }) as string;
    }
}
