import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Notification, NotificationFilter } from './notification';
import { catchError, finalize } from 'rxjs/operators';

import { NotificationService } from '../services/notification.service';
import { UserService } from '../services/user.service';
import { of } from 'rxjs';

export class NotificationDataSource implements DataSource<Notification> {

    private notificationsSubject = new BehaviorSubject<Notification[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public loading$ = this.loadingSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public totalItems$ = this.totalSubject.asObservable();
    private data: Notification[];

    constructor(
        private service: NotificationService,
        private userService: UserService) { }

    connect(_collectionViewer: CollectionViewer): Observable<Notification[]> {
        return this.notificationsSubject.asObservable();
    }

    disconnect(_collectionViewer: CollectionViewer): void {
        this.notificationsSubject.complete();
        this.loadingSubject.complete();
        this.totalSubject.complete();
    }

    loadNotifications(filter = new NotificationFilter()): void {

        this.loadingSubject.next(true);

        this.service.findAll(filter).pipe(catchError(() => of([])), finalize(() => {
            this.userService.profile().subscribe();
            this.loadingSubject.next(false);
        })).subscribe(data => {
            if (data.content) {
                data.content.forEach(element => {
                    element.expanded = false;
                });
                this.data = data.content;
                this.notificationsSubject.next(data.content as Notification[]);
                this.totalSubject.next(data.totalElements as number);
            } else if (data) {
                data.forEach(element => {
                    element.expanded = false;
                });

                this.data = data;
                this.notificationsSubject.next(data as Notification[]);
                this.totalSubject.next(data.length as number);
            } else {
                this.notificationsSubject.next([]);
                this.totalSubject.next(0);
            }

        });
    }

    openNotification(notif: Notification): void {

        if (notif.idStatus === 1) {
            this.service.markAsRead(notif.id).subscribe((res: Notification) => {
                this.updateOpenNotification(notif, res);
            });
        } else {
            this.updateOpenNotification(notif, null);
        }
    }

    markAllAsRead(filter = new NotificationFilter()): void {
        this.loadingSubject.next(true);
        this.service.markAllAsRead().subscribe(() => {
            this.loadingSubject.next(false);
            this.loadNotifications(filter);
        });
    }

    private updateOpenNotification(notif: Notification, newNotif: Notification) {
        const index = this.data.indexOf(notif);

        notif.expanded = notif.expanded ? false : true;

        if (index > -1) {
            if (newNotif != null) {
                newNotif.expanded = newNotif.expanded ? false : true;
                this.data[index] = newNotif;
            }

            this.notificationsSubject.next(this.data);
        }
        this.userService.profile().subscribe();

    }

}
