import { Domain } from './domain';
import { FieldTypeEnum } from './fieldType';

export class Field {

    static separator = ';';

    private static fieldUniqueValue = [
        FieldTypeEnum.STRING, FieldTypeEnum.NUMBER, FieldTypeEnum.SELECT_ONE, FieldTypeEnum.SELECT_MULTI, FieldTypeEnum.AREA
    ];

    id: number;

    idField: number;

    translationField: string;

    _value: string;
    defaultValue: string;

    valueProtocol: string;
    valueChangeReason: string;

    showDiffInDeviations: boolean;
    showDiffInObservations: boolean;

    idTypeField: number;
    order: number;

    required: boolean;
    noValuesRequired = 1;

    editableInExecution: boolean;

    fieldDomain: Domain[];

    get valueProtocolArray(): string | string[] {
        if (this.isMultifield()) {
            if (this.valueProtocol != null) {
                return this.valueProtocol.split(Field.separator);
            } else {
                return [];
            }
        }
        return this.valueProtocol;
    }

    get value() {
        return this.getValue();
    }

    set value(value: any) {
        this.setValue(null, value);
    }

    getValue(): string | string[] {
        if (this.isMultifield()) {
            if (this._value != null) {
                if (Array.isArray(this._value)) {
                    return this._value;
                } else {
                    return this._value.split(Field.separator);
                }
            }
            return [];
        }
        return this._value;
    }

    setValue(index: number, value): void {
        if (index == null || index < 0) {
            this._value = value;
            return;
        }

        const val = this.value;

        if (Array.isArray(val)) {
            while (val.length < index) {
                val.push('');
            }

            val[index] = value;
            this._value = val.join(Field.separator);
        }
    }

    isMultifield(): boolean {
        return !Field.fieldUniqueValue.includes(this.idTypeField);
    }

    toJSON(): Field {
        const res = Object.assign({}, this);

        let value = this.getValue();

        if (Array.isArray(value)) {
            value = value.join(';');
        }

        res.value = value;

        delete res._value;

        return res as Field;
    }

}
