import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Protocol, ProtocolFilter } from './protocol';
import { catchError, finalize } from 'rxjs/operators';

import { ProtocolService } from '../services/protocol.service';
import { of } from 'rxjs';

export class ProtocolsDataSource implements DataSource<Protocol> {

    private protocolsSubject = new BehaviorSubject<Protocol[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public loading$ = this.loadingSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public totalItems$ = this.totalSubject.asObservable();

    constructor(private service: ProtocolService) { }

    connect(_collectionViewer: CollectionViewer): Observable<Protocol[]> {
        return this.protocolsSubject.asObservable();
    }

    disconnect(_collectionViewer: CollectionViewer): void {
        this.protocolsSubject.complete();
        this.loadingSubject.complete();
        this.totalSubject.complete();
    }

    loadProtocols(filter = new ProtocolFilter()): void {

        this.loadingSubject.next(true);

        this.service.findAll(filter).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(data => {
            if (data && data.content) {
                this.protocolsSubject.next(data.content as Protocol[]);
                this.totalSubject.next(data.totalElements as number);
            } else if (data) {
                this.protocolsSubject.next(data as Protocol[]);
                this.totalSubject.next(data.length as number);
            } else {
                this.protocolsSubject.next([]);
                this.totalSubject.next(0);
            }
        });
    }

}
