<h1 mat-dialog-title>{{ 'isothermalCharacterizationEdit.sensorsConfig.title' | translate }}</h1>
<form>

  <div mat-dialog-content class="calibrates-theme" style="min-height: 10vh;">

    <div fxLayout="column">
      <div fxFlex>
        <span> {{ '¿Ubicación por bandejas?' | translate }} </span>
        <mat-radio-group [(ngModel)]="data.locationByTrays" name="locationByTrays">
          <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
          <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div fxLayout="column" *ngIf="!data.locationByTrays">
      <div fxFlex>
        <mat-form-field class="campoFormulario">
          <input matInput name="numSensor" [(ngModel)]="totalSensors" type="number" required min="1"
            placeholder="{{ 'protocolEdit.dialog.essay.sensors.numSensor.label' | translate }}"
            (ngModelChange)="setNumSensor($event)">
        </mat-form-field>
      </div>

      <div fxFlex>

        <div fxLayout="row" class="flexwrap" *ngFor="let sensor of data.sensors; let indexSensor = index">
          <div fxFlex="20" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput name="numSensor{{indexSensor}}" value="{{ indexSensor + 1 }}" disabled
                placeholder="{{ 'protocolEdit.dialog.essay.sensors.sensorNum.label' | translate }}">
            </mat-form-field>
          </div>
          <div fxFlex="70" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput name="numSensor{{indexSensor}}" [(ngModel)]="sensor.location"
                placeholder="{{ 'protocolEdit.dialog.essay.sensors.location.label' | translate }}"
                [disabled]="data.disabled">
              <mat-error>
                {{ 'protocolEdit.dialog.essay.sensors.location.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="column" *ngIf="data.locationByTrays">
      <div fxFlex>
        <mat-form-field class="campoFormulario">
          <input matInput type="number" required name="trayNum" [(ngModel)]="trayNum" min="1"
            placeholder="{{ 'isothermalCharacterizationEdit.sensorsConfig.trayNum.label' | translate }}"
            (ngModelChange)="onTrayNumChange($event)">
          <mat-error>
            {{ 'isothermalCharacterizationEdit.sensorsConfig.trayNum.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex *ngFor="let tray of sensorTray | keyvalue">
        <mat-form-field class="campoFormulario">
          <input matInput type="number" required name="sensorTray{{tray.key}}" min="0"
            [ngModel]="sensorTray.get(tray.key)" (ngModelChange)="sensorTray.set(tray.key, $event)"
            placeholder="{{ 'isothermalCharacterizationEdit.sensorsConfig.sensorTray.label' | translate: { num : (tray.key + 1) } }}">
          <mat-error>
            {{ 'isothermalCharacterizationEdit.sensorsConfig.sensorTray.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

  </div>

  <div mat-dialog-actions class="calibrates-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <div fxFlex="5px"></div>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>