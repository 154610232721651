import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SensorData, SensorDataGraph } from 'src/app/model/sensorData';
import { IsothermalCharacterizationService } from 'src/app/services/isothermalCharacterization.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { DateUtils } from 'src/app/utils/dateUtils';
import { EssayUtils } from 'src/app/utils/essayUtils';

export interface DialogDataDiscardTimeEdit {
  idIsothermalCharacterization: number;
  idEssay: number;
  data: SensorData[];
}

@Component({
  selector: 'app-isothermal-characterization-edit-discard-time',
  templateUrl: './isothermal-characterization-edit-discard-time.component.html'
})
export class IsothermalCharacterizationEditDiscardTimeComponent implements OnDestroy {

  startDateStr: string;
  endDateStr: string;

  startDateRange: Date;
  endDateRange: Date;

  datasource: SensorDataGraph[];
  sensors: any[];

  private destroy$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<IsothermalCharacterizationEditDiscardTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataDiscardTimeEdit,
    private isothermalCharacterizationService: IsothermalCharacterizationService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    this.startDateRange = _.cloneDeep(this.data.data.sort((a, b) => a.date.getTime() - b.date.getTime()).find(i => i.date != null).date);
    this.endDateRange = _.cloneDeep(DateUtils.anyToDate(this.startDateRange));
    this.endDateRange.setHours(this.endDateRange.getHours() + 1);

    if (this.startDateStr == null || this.startDateStr === '') {
      this.convertDateToString(this.startDateRange, this.endDateRange);
    }

    this.recalculateDatasource(this.data.data);

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onOkClick(): void {
    const errs = [];

    try {
      this.startDateRange = moment(this.startDateStr, 'DD/MM/YYYY HH:mm:ss').toDate();
    } catch (e) {
      this.startDateRange = null;
    }

    try {
      this.endDateRange = moment(this.endDateStr, 'DD/MM/YYYY HH:mm:ss').toDate();
    } catch (e) {
      this.endDateRange = null;
    }

    if (this.startDateRange == null) {
      errs.push(this.translate.instant('isothermalCharacterizationEdit.discardTime.form.error.startDate'));
    }
    if (this.endDateRange == null) {
      errs.push(this.translate.instant('isothermalCharacterizationEdit.discardTime.form.error.endDate'));
    }

    if (errs.length === 0) {
      this.spinnerService.show();

      this.isothermalCharacterizationService.discardTime(this.data.idIsothermalCharacterization, this.data.idEssay, this.startDateRange,
        this.endDateRange).pipe(takeUntil(this.destroy$)).subscribe(res => {
          this.spinnerService.hide();

          this.snackBarService.sendSuccess(this.translate.instant('isothermalCharacterizationEdit.discardTime.form.save.ok') as string);

          this.dialogRef.close({
            valid: true,
            data: res
          });
        }, () => {
          this.snackBarService.sendError(
            this.translate.instant('isothermalCharacterizationEdit.discardTime.form.save.error.generic') as string);
          this.spinnerService.hide();
        });
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  recalculateDatasource(data: SensorData[]): void {
    this.spinnerService.show();

    if (data == null || ArrayUtils.isEmpty(data)) {
      this.datasource = [];
      this.sensors = [];

      return;
    }

    const res: SensorDataGraph[] = [];
    const sensors = [];

    const groupBy = ArrayUtils.groupBy(data, (item: SensorData) => item.date);
    groupBy.forEach((values: SensorData[], key: Date) => {
      values.forEach(value => {
        const sen = new SensorDataGraph();
        sen.date = key;

        if (!value.equipmentName) {
          return;
        }

        const beautySerialNum = value.equipmentName.replace('-', '').replace(' ', '').toLowerCase();

        sen[beautySerialNum] = value.value;

        const currentSensors = sensors.map(item => item.name);

        if (!currentSensors.includes(value.equipmentName)) {

          sensors.push({
            name: value.equipmentName,
            serialNum: value.serialNum,
            value: beautySerialNum
          });
        }

        res.push(sen);
      });
    });

    this.datasource = res;
    this.sensors = sensors;

    this.data.data.forEach(item => item.date = DateUtils.anyToDate(item.date));

    this.spinnerService.hide();
  }

  onValueChanged = (e) => {
    const changedInit = e.value[0].getTime() !== e.previousValue[0].getTime();
    const changedEnd = e.value[1].getTime() !== e.previousValue[1].getTime();

    let initDate = DateUtils.anyToDate(e.value[0]);
    let endDate = DateUtils.anyToDate(e.value[1]);

    if (changedInit) {
      initDate = EssayUtils.calculateNearDate(initDate, this.data.data);
    }

    if (changedEnd) {
      endDate = EssayUtils.calculateNearDate(endDate, this.data.data);
    }

    this.startDateRange = _.cloneDeep(initDate);
    this.endDateRange = _.cloneDeep(endDate);

    this.convertDateToString(initDate, endDate);
  }

  customizeLabel = (data) => {
    return data.value.toLocaleString();
  }

  customizeSliderMarker = (data) => {
    return data.value.toLocaleString();
  }

  private convertDateToString(startDate: Date, endDate: Date): void {
    try {
      if (startDate == null) {
        this.startDateStr = null;
      } else {
        const date = startDate;
        const start = moment(date).format('DD/MM/YYYY HH:mm:ss');
        this.startDateStr = start;
      }
    } catch (e) {
      this.startDateStr = null;
    }

    try {
      if (endDate == null) {
        this.startDateStr = null;
      } else {
        const date = endDate;
        const end = moment(date).format('DD/MM/YYYY HH:mm:ss');
        this.endDateStr = end;
      }
    } catch (e) {
      this.startDateStr = null;
    }
  }

}
