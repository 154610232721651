import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoomAir } from '../model/roomAir';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class RoomService {

    constructor(private http: HttpClient) {
    }

    save(room: RoomAir, timeStamp: string, executionId: number): Observable<any> {
        const url = environment.airUrl + `/execution/room/create/${executionId}`;
        const headers = new HttpHeaders({
            timeStamp
        });
        return this.http.post(url, room, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    update(room: RoomAir, timeStamp: string, executionId: number): Observable<any> {
        const url = environment.airUrl + `/execution/room/${room.id}/${executionId}`;
        const headers = new HttpHeaders({
            timeStamp
        });
        return this.http.put(url, room, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    delete(room: RoomAir, timeStamp: string, executionId: number): Observable<any> {
        const url = environment.airUrl + `/execution/room/${room.id}/${executionId}`;
        const headers = new HttpHeaders({
            timeStamp
        });
        return this.http.delete(url, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

}
