import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BioindicatorConfig } from 'src/app/model/bioindicator';
import { Execution } from 'src/app/model/execution';
import { ExecutionEditBioindicatorsDetailsComponent } from './execution-edit-bioindicators-details.component';
import { DialogDataValidateExecution } from './execution-edit-validate-execution.component';

export interface DialogDataConfirmBios {
  execution: Execution;
}

@Component({
  selector: 'app-execution-edit-confirm-bios',
  templateUrl: './execution-edit-confirm-bios.component.html'
})
export class ExecutionEditConfirmBiosComponent {

  private destroy$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ExecutionEditConfirmBiosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataValidateExecution) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showDialogConfigBioindicator(essayIndex: number, variableIndex: number): void {

    const bio = this.data.execution.essays[essayIndex].essayValues[variableIndex].bioindicatorsConfig;

    const dialogRef = this.dialog.open(ExecutionEditBioindicatorsDetailsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        bioindicator: _.cloneDeep(bio),
        idExecution: this.data.execution.id,
        type: 'bioindicator'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: BioindicatorConfig) => {
      if (result != null) {
        this.data.execution.essays[essayIndex].essayValues[variableIndex].bioindicatorsConfig = result;
      }
    });

  }

  showDialogConfigEndotoxin(essayIndex: number, variableIndex: number): void {

    const bio = this.data.execution.essays[essayIndex].essayValues[variableIndex].endotoxinsConfig;

    const dialogRef = this.dialog.open(ExecutionEditBioindicatorsDetailsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        bioindicator: _.cloneDeep(bio),
        idExecution: this.data.execution.id,
        type: 'endotoxin'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: BioindicatorConfig) => {
      if (result != null) {
        this.data.execution.essays[essayIndex].essayValues[variableIndex].endotoxinsConfig = result;
      }
    });

  }

  onOkClick(): void {
    this.dialogRef.close(this.data.execution);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
