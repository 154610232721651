<div fxLayout="row" class="flexwrap">

  <div fxFlex="100%" fxFlex.lt-md="100%">
    <mat-checkbox (change)="onCheckAllItems($event)" [checked]="isAllSelected()">
    </mat-checkbox>
    {{ 'common.selectAll' | translate }}
  </div>

  <div fxFlex="100%" fxFlex.lt-md="100%">
    <div *ngFor="let item of data.sensors; let i = index">
      <mat-checkbox (change)="onSensorCheck($event)" [value]="item.id" [disabled]="data.disabled"
        [checked]="isSelected(item)">
        {{ 'executionEdit.essays.sensor.name' | translate }} {{ (i + 1) }}
        <span *ngIf="item.equipmentName != null"> ({{ item.equipmentName }} - {{ item.serialNum }})</span>
      </mat-checkbox>
    </div>
  </div>

  <div fxFlex="100%" fxFlex.lt-md="100%" style="margin-top: 1.5em">
    <button mat-raised-button color="primary" (click)="fileInput.click()" [disabled]="disableUploadButton()">
      {{ 'button.upload' | translate }}</button>
    <input [accept]="accept()" hidden type="file" (change)="uploadMassive($event)" #fileInput>

    <div fxFlex="5px"></div>

    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</div>