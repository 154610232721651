import { Component, OnDestroy, OnInit } from '@angular/core';

import { AppPlanType } from 'src/app/model/appPlanType';
import { MatDialog } from '@angular/material/dialog';
import { PaymentComponent } from '../payment/payment.component';
import { PaymentsService } from 'src/app/services/payments.service';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html'
})
export class SignupComponent implements OnInit, OnDestroy {
  plans: AppPlanType[];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private translate: TranslateService,
    private paymentsService: PaymentsService,
    public dialog: MatDialog,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.spinnerService.show();

    this.paymentsService.findAllPlans().pipe(takeUntil(this.destroy$)).subscribe((res: AppPlanType[]) => {
      this.plans = res;

      this.spinnerService.hide();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectPlan(plan: AppPlanType): void {
    console.log(plan);

    const dialogRef = this.dialog.open(PaymentComponent, {
      minWidth: '25%',
      maxHeight: '95vh',
      data: { idPlan: plan.id }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {

      if (result != null) {
        void this.router.navigateByUrl('/payment-complete', { state: result });
      }

    });

  }

  public contactUs(): void {
    const type = this.translate.instant('signup.contactUs.subject') as string;
    window.location.href = `mailto:sales@qloudapp.com?subject=${type}`;
  }

}
