import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { ProtocolQ, ProtocolQFilter } from './protocol';
import { catchError, finalize } from 'rxjs/operators';

import { of } from 'rxjs';
import { ProtocolQService } from '../services/protocolQ.service';

export class ProtocolsQDataSource implements DataSource<ProtocolQ> {

    private protocolsSubject = new BehaviorSubject<ProtocolQ[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public loading$ = this.loadingSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public totalItems$ = this.totalSubject.asObservable();

    constructor(private service: ProtocolQService) { }

    connect(collectionViewer: CollectionViewer): Observable<ProtocolQ[]> {
        return this.protocolsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.protocolsSubject.complete();
        this.loadingSubject.complete();
        this.totalSubject.complete();
    }

    loadProtocols(filter = new ProtocolQFilter()): void {

        this.loadingSubject.next(true);

        this.service.findAll(filter).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(data => {
            if (data && data.content) {
                this.protocolsSubject.next(data.content as ProtocolQ[]);
                this.totalSubject.next(data.totalElements as number);
            } else if (data) {
                this.protocolsSubject.next(data as ProtocolQ[]);
                this.totalSubject.next(data.length as number);
            } else {
                this.protocolsSubject.next([]);
                this.totalSubject.next(0);
            }
        });
    }

}
