import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppPlanFilter, AppPlanType } from 'src/app/model/appPlanType';
import { Subject, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { AppPlanDataSource } from 'src/app/model/appPlanDataSource';
import { AppPlanTypeService } from 'src/app/services/appPlanType.service';
import { Constants } from 'src/app/utils/constants';
import { Location } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Phase } from 'src/app/model/phase';
import { PhaseService } from 'src/app/services/phase.service';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-app-plan-list',
  templateUrl: './app-plan-list.component.html'
})
export class AppPlanListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: AppPlanDataSource;
  filter: AppPlanFilter = new AppPlanFilter();

  currentUser: User;

  phases: Phase[];

  displayedColumns: string[] = ['name', 'phasesAllowed', 'canManageUsers', 'maxUsers', 'maxExecutions', 'canGeneratePreReport', 'edit'];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private location: Location,
    private userService: UserService,
    private appPlanTypeService: AppPlanTypeService,
    private phaseService: PhaseService,
    private translate: TranslateService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.currentUser = this.userService.currentProfile;
    this.loadAppPlanList();

    this.phaseService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Phase[]) => this.phases = data);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadAppPlanList())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  new(): void {
    void this.router.navigateByUrl('/admin/appPlan');
  }

  editRow(id: string): void {
    void this.router.navigateByUrl('/admin/appPlan?id=' + id);
  }

  doFilter(event: AppPlanFilter): void {
    this.filter = event;

    this.loadAppPlanList();
  }

  loadAppPlanList(): void {
    this.dataSource = new AppPlanDataSource(this.appPlanTypeService);

    this.filter.sortBy = this.sort.active || Constants.FIELD_ID;
    this.filter.sortDirection = this.sort.direction || 'asc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 5;

    this.dataSource.loadAppPlans(this.filter);
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  getMaxUsers(item: AppPlanType): string {
    return item.maxUsers === -1 ? this.translate.instant('common.unlimited') as string : item.maxUsers.toString();
  }

  getMaxExecutions(item: AppPlanType): string {
    return item.maxExecutions === -1 ? this.translate.instant('common.unlimited') as string : item.maxExecutions.toString();
  }

  getPhasesAllowed(item: AppPlanType): string {
    if (item == null || item.idsPhasesAllowed == null || this.phases == null) {
      return '';
    }

    return this.phases.filter(p => item.idsPhasesAllowed.includes(p.id))
      .map(p => this.translate.instant('phase.' + p.translation) as string).join(', ');
  }

  cancel(): void {
    this.location.back();
  }
}
