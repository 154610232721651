import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpUtils } from '../utils/httpUtils';
import { GenericDocumentList } from '../model/genericDocumentList';
import { GenericDocumentListFilter } from '../model/genericDocumentListFilter';

@Injectable({
  providedIn: 'root'
})
export class GenericDocumentListService {

  constructor(private http: HttpClient) {
  }

  findByClient(idClient: number): Observable<GenericDocumentList[]> {
      const url = environment.thermalUrl + `/genericDocumentList/${idClient}`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  findAll(filter: GenericDocumentListFilter): Observable<any> {
    const url = environment.thermalUrl + '/genericDocumentList/';

    return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
}
}
