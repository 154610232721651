<h1 mat-dialog-title>{{'protocolEdit.dialog.equipment.title.create' | translate }}</h1>
<form>
  <div mat-dialog-content class="air-theme">

    <div fxLayout="row" class="flexwrap">

   <!--   <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'protocolEdit.dialog.equipment.type.label' | translate }}</mat-label>
          <mat-select required name="idType" [(ngModel)]="data.equipment.idType">
            <mat-option *ngFor="let item of data.equipmentTypes" [value]="item.id">
              {{ 'equipmentType.' + item.translation | translate }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.type.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    -->
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.maker.label' | translate }}" name="maker"
            [(ngModel)]="data.equipment.maker" required>
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.equipment.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.model.label' | translate }}" name="model"
            [(ngModel)]="data.equipment.model" required>
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.equipment.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.serialNum.label' | translate }}"
            name="serialNum" [(ngModel)]="data.equipment.serialNum" required>
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.serialNum.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.inventoryNum.label' | translate }}"
            name="location" [(ngModel)]="data.equipment.inventoryNum">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.inventoryNum.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.room.label' | translate }}" name="room"
            [(ngModel)]="data.equipment.room" required>
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.room.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

    </div>

  </div>
  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>
