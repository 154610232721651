import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { CommonModule } from '@angular/common';
import { DevextremeModule } from 'src/app/external-components/devextreme.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ReferenceDocProtocolListComponent } from './referenceDocProtocol-q-list.component';

import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
    ReferenceDocProtocolListComponent, 
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    /* Custom modules */
    AngularMaterialModule,
    DevextremeModule,
    SharedModule,
    PipesModule,

    /* Otros */
    FlexLayoutModule
  ]
})
export class ReferenceDocQProtocolModule { }
