export class ExecutionReportFilter {

    graphsToInclude: string[];
    dataTableInclude: string;
    reviewBy: string;
    lang: string;
    reason: string;
    toSign: boolean;
    conclusions: string;
    deviations: string;
    observations: string;

    idsEquipment: number[] = [];
    idsRooms: number[] = [];
    idsCompressedGases: number[] = [];

    hybride: boolean;

    readonly graphTypes: string[] = ['essay', 'exhibition', 'minAvgMax', 'diffExhibition', 'avgSorted'];
    readonly dataTypes: string[] = ['essay', 'exhibition', 'none'];

    constructor() {
        this.graphsToInclude = ['essay', 'exhibition'];
        this.dataTableInclude = 'essay';
    }
}

export class ProtocolReportFilter {
    reviewBy: string; // FIXME: Quitar
    toSign = false; // FIXME: Quitar
    reason: string;
    lang: string;
    hybride?: boolean;
}

export class CalibrationReportFilter {
    toSign = true;
    reason: string;
    lang: string;
}

export class IsothermalCharacterizationReportFilter {
    toSign = true;
    reason: string;
    lang: string;
    dataTableInclude: string;

    readonly graphTypes: string[] = ['essay', 'exhibition'];
    readonly dataTypes: string[] = ['essay', 'exhibition', 'none'];

    constructor() {
        this.dataTableInclude = 'none';
    }
}

export class FileInfo {
    id: number;
    filename: string;
    contentType: string;
    content: any;
    contentString: any;
}

export enum AttachmentType {
    FOTO_ESQUEMA_COLOCACION_SONDAS_PROTOCOLO = 5,
    FOTO_ESQUEMA_COLOCACION_SONDAS_EJECUCION = 6,
    CERTIFICADO_EQUIPO_USO_INTERNO = 7,
    CERTIFICADO_USUARIO = 8,
    FOTO_SENSOR_EJECUCION = 9,
    EXCEL_SENSOR_EJECUCION = 10,
    ARCHIVO_DATOS_PRIMARIOS_SENSOR_EJECUCION = 11,
    DOCUMENTO_FIRMADO_PROTOCOLO = 12,
    DOCUMENTO_FIRMADO_EJECUCION = 13,
    CERTIFICADO_BIOINDICADOR = 14,
    RESULTADO_BOWIE_DICK = 15,
    ESSAY_EXCEL = 24,

    OTHERS = 99
}
