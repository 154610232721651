import { Component, Inject, OnInit } from '@angular/core';
import { ProtocolQGeneralData } from '../../../../model/protocol';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogSectionTemplate {
  protocol: ProtocolQGeneralData;
}

@Component({
  selector: 'app-protocol-q-edit-section-template',
  templateUrl: './protocol-q-edit-section-template.component.html',
  styleUrls: ['./protocol-q-edit-section-template.component.scss']
})
export class ProtocolQEditSectionTemplateComponent implements OnInit {

  scopeTab = false;
  targetTab = false;
  precedentsTab = false;

  quillConfig = {
		toolbar: [
		  ['bold', 'italic', 'underline'],
		  ['image'],
		  [{ list: 'ordered'}, { list: 'bullet' }]
		]
	};
  constructor(private dialogRef: MatDialogRef<ProtocolQEditSectionTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogSectionTemplate) { }

  ngOnInit(): void {
  }

  onOkClick(): void {
    this.dialogRef.close(this.data.protocol);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
