<div fxLayout="row" class="flexwrap">

  <div fxFlex="100%" fxFlex.lt-md="100%">
    {{ 'executionEdit.attachments.cols.filename' | translate }}: {{ fileData.filename }}
  </div>

  <div fxFlex="100%" fxFlex.lt-md="100%" style="margin-top: 1.5em">
    <button mat-raised-button color="primary" (click)="fileInput.click()"
      *ngIf="!data.disableUpload">{{ 'button.upload' | translate }}</button>
    <input [accept]="data.accept" hidden type="file" (change)="uploadFile($event)" #fileInput>

    <div fxFlex="5px"></div>

    <button mat-raised-button color="primary"
      (click)="downloadAttachment()">{{ 'button.download' | translate }}</button>

    <div fxFlex="5px"></div>

    <button mat-raised-button color="primary" (click)="deleteAttachment()"
      *ngIf="false">{{ 'button.delete' | translate }}</button>

    <div fxFlex="5px"></div>

    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</div>