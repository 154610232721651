import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable()
export class LangInterceptor implements HttpInterceptor {

    constructor(private userService: UserService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let lang: string = null;

        if (req.headers.get('lang')) {
            lang = req.headers.get('lang');
        } else if (this.userService == null || this.userService.currentLang == null) {
            lang = 'es';
        } else {
            lang = this.userService.currentLang;
        }

        const clonedReq = req.clone({
            headers: req.headers.set('lang', lang)
        });

        return next.handle(clonedReq);
    }

}
