import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { IsothermalCharacterizationSensor } from 'src/app/model/isothermalCharacterization';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { StringUtils } from 'src/app/utils/stringUtils';

export interface DialogDataConfigSensor {
  sensor: IsothermalCharacterizationSensor;
  isLastSensor: boolean;
}

@Component({
  selector: 'app-isothermal-characterization-edit-config-sensor',
  templateUrl: './isothermal-characterization-edit-config-sensor.component.html'
})
export class IsothermalCharacterizationEditConfigSensorComponent {

  hasLocation: boolean;

  constructor(
    private translate: TranslateService,
    private dialogRef: MatDialogRef<IsothermalCharacterizationEditConfigSensorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataConfigSensor,
    public snackBarService: SnackBarService) {
    this.hasLocation = StringUtils.isNotEmpty(data.sensor.location);
  }

  onOuterChange(event: MatSlideToggleChange): void {
    this.data.sensor.outer = event.checked;
  }

  onEvaluateChange(event: MatSlideToggleChange): void {
    this.data.sensor.evaluate = event.checked;
  }

  onOkClick(): void {
    const errs = [];

    if (this.data.sensor.evaluate) {
      this.data.sensor.evaluateReason = null;
    } else if (this.data.sensor.evaluateReason == null || this.data.sensor.evaluateReason === '') {
      errs.push(this.translate.instant('isothermalCharacterizationEdit.sensorConfig.form.error.evaluateReason'));
    }

    if (this.hasLocation) {
      if (StringUtils.isEmpty(this.data.sensor.location)) {
        errs.push(this.translate.instant('executionEdit.sensorConfig.form.error.location'));
      }
    } else {
      if (this.data.sensor.trayNum < 1) {
        errs.push(this.translate.instant('isothermalCharacterizationEdit.sensorConfig.trayNum.error'));
      }
      if (this.data.sensor.sensorTray < 1) {
        errs.push(this.translate.instant('isothermalCharacterizationEdit.sensorConfig.sensorTray.error'));
      }
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.sensor);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
