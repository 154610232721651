import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExecutionAir } from 'src/app/model/execution';
import { ProtocolAir } from 'src/app/model/protocol';
import { ExecutionAirService } from 'src/app/services/executionAir.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { StringUtils } from 'src/app/utils/stringUtils';
import { ExecutionEditDialogAutomaticSignComponent } from './execution-edit-dialog-automatic-sign.component';

export interface DialogDataValidateExecutionAutomaticSign {
  execution: ExecutionAir;
  protocol: ProtocolAir;
}

@Component({
  selector: 'app-execution-edit-validate-execution-automatic-sign',
  templateUrl: './execution-edit-validate-execution-automatic-sign.component.html'
})
export class ExecutionEditValidateExecutionAutomaticSignComponent implements OnInit, OnDestroy {
  mainForm: FormGroup;

  forceAccording: boolean;
  reasonForce: string;
  reason: string;

  errors: string[];
  warns: string[];

  private destroy$ = new Subject<void>();

  constructor(
    fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ExecutionEditValidateExecutionAutomaticSignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataValidateExecutionAutomaticSign,
    private executionService: ExecutionAirService,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    this.mainForm = fb.group({
      forceAccording: [this.forceAccording],
      reasonForce: [this.reasonForce],
      reason: [this.reason]
    });
  }

  ngOnInit(): void {
    this.errors = [];
    this.warns = [];

    this.spinnerService.show();

    const force: boolean = this.data.execution.according != null ? this.data.execution.according.valueOf() : false;
    this.forceAccording = !force;
    this.reasonForce = StringUtils.isNotEmpty(this.data.execution.accordingReason) ? this.data.execution.accordingReason : null;
    this.reason = this.data.execution.reason;

    this.spinnerService.show();

    this.errors = [];
    this.warns = [];

    this.executionService.checkForDownload(this.data.execution.id).pipe(takeUntil(this.destroy$)).subscribe((errors: string[]) => {
      this.errors = errors;

      this.spinnerService.hide();

      if (this.isEmpty(this.errors) && this.isEmpty(this.warns)) {
        this.onOkClick();
      }
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showReason(): boolean {
    return (+this.data.execution.currentVersion) > 1;
  }

  isEmpty(array: any[]): boolean {
    return ArrayUtils.isEmpty(array);
  }

  canAccept(): boolean {
    let res = false;

    if (this.errors == null) {
      res = true;
    } else if (this.forceAccording) {
      res = true;
    } else if (this.reasonForce != null && this.reasonForce !== '') {
      res = true;
    }

    return res;
  }

  onOkClick(): void {
    if (StringUtils.isNotEmpty(this.reason)) {
      this.data.execution.reason = this.reason;
    }

    this.saveExecutionCallback(() => {
      const dialog = this.dialog.open(ExecutionEditDialogAutomaticSignComponent, {
        minWidth: '20%',
        maxHeight: '95vh',
        data: {
          execution: this.data.execution,
          protocol: this.data.protocol,
          forceAccording: this.forceAccording,
          reasonForce: this.reasonForce
        }
      });

      dialog.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(data => {
        if (data != null) {
          this.dialogRef.close(this.data.execution);
        } else {
          this.dialogRef.close();
        }
      });
    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private saveExecutionCallback(callback: () => void) {
    this.spinnerService.show();

    this.executionService.save(this.data.execution).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.spinnerService.hide();
      callback();
    }, err => {
      if (err != null && err.error != null && typeof err.error === 'string') {
        this.snackBarService.sendError(err.error as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('executionEdit.form.status.advance.error.generic') as string);
      }
      this.spinnerService.hide();
    });
  }

}
