<h1 mat-dialog-title>{{ 'isothermalCharacterizationEdit.discardTime.title' | translate }}</h1>
<form>

  <div mat-dialog-content class="calibrates-theme" style="min-height: 10vh;">

    <div>
      <dx-range-selector id="range-selector" [value]="[startDateRange, endDateRange]" [dataSource]="datasource"
        (onValueChanged)="onValueChanged($event)">

        <dxo-margin [top]="50"></dxo-margin>
        <dxo-size [height]="500" width="80%"></dxo-size>

        <dxo-behavior [snapToTicks]="false"></dxo-behavior>

        <dxo-scale>
          <dxo-tick-interval [hours]="1"></dxo-tick-interval>
          <dxo-minor-tick-interval [minutes]="5"></dxo-minor-tick-interval>
          <dxo-minor-tick [visible]="false"></dxo-minor-tick>
          <dxo-marker [visible]="false"></dxo-marker>
          <dxo-label [customizeText]="customizeLabel"></dxo-label>
        </dxo-scale>

        <dxo-slider-marker [customizeText]="customizeSliderMarker" invalidRangeColor="white">
        </dxo-slider-marker>

        <dxo-chart>
          <dxi-series *ngFor="let sensor of sensors" [valueField]="sensor.value" [name]="sensor.name"></dxi-series>

          <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="bottom">
          </dxo-legend>

          <dxo-common-series-settings argumentField="date" type="line"></dxo-common-series-settings>

          <dxi-value-axis valueType="numeric">
          </dxi-value-axis>

        </dxo-chart>

      </dx-range-selector>
    </div>

    <div fxLayout="row">

      <div fxFlex>
        <mat-form-field>
          <input matInput [(ngModel)]="startDateStr" name="startDate"
            placeholder="{{ 'defrost.startDate.label' | translate }}">
        </mat-form-field>
      </div>
      <div fxFlex>
        <mat-form-field>
          <input matInput [(ngModel)]="endDateStr" name="endDate"
            placeholder="{{ 'defrost.endDate.label' | translate }}">
        </mat-form-field>
      </div>

    </div>
  </div>

  <div mat-dialog-actions class="calibrates-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <div fxFlex="5px"></div>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>