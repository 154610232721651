import { BehaviorSubject, Observable, of } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { ExecutionAttachment, ExecutionAttachmentFilter } from './execution';
import { catchError, finalize } from 'rxjs/operators';

import { ExecutionAirService } from '../services/executionAir.service';

export class ExecutionAirAttachmentDataSource implements DataSource<ExecutionAttachment> {

    private executionsSubject = new BehaviorSubject<ExecutionAttachment[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public loading$ = this.loadingSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public totalItems$ = this.totalSubject.asObservable();

    constructor(private service: ExecutionAirService) { }

    connect(_collectionViewer: CollectionViewer): Observable<ExecutionAttachment[]> {
        return this.executionsSubject.asObservable();
    }

    disconnect(_collectionViewer: CollectionViewer): void {
        this.executionsSubject.complete();
        this.loadingSubject.complete();
        this.totalSubject.complete();
    }

    loadExecutionAttachment(id: number, filter = new ExecutionAttachmentFilter()): void {

        this.loadingSubject.next(true);

        this.service.getAttachments(id, filter).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(data => {
            if (data && data.content) {
                this.executionsSubject.next(data.content as ExecutionAttachment[]);
                this.totalSubject.next(data.totalElements as number);
            } else if (data) {
                this.executionsSubject.next(data as ExecutionAttachment[]);
                this.totalSubject.next(data.length as number);
            } else {
                this.executionsSubject.next([]);
                this.totalSubject.next(0);
            }
        });
    }
}
