<h1 mat-dialog-title>
    {{ (data.useExistingProtocol || data.idProtocol != null ? 'protocolEdit.dialog.executionData.title' :
    'executionEdit.dialog.executionData.title') | translate }}
</h1>

<div mat-dialog-content class="thermal-theme">

  <div fxLayout="row" class="flexwrap">
    <div fxFlex="30">
        <mat-form-field class="campoFormulario" *ngIf="!data.useExistingProtocol">
            <mat-label>{{ 'executionEdit.protocol.client.label' | translate }}</mat-label>
            <mat-select #clientSelect name="idClient" [(ngModel)]="data.idClient">
                <mat-select-filter *ngIf="clientSelect.focused" [displayMember]="'name'"
                    [array]="clients" (filteredReturn)="clientsFiltered =$event"
                    [noResultsMessage]="'common.noResults' | translate"
                    [placeholder]="'common.selectFilterPlaceholder' | translate">
                </mat-select-filter>
                <mat-option *ngFor="let client of clientsFiltered" [value]="client.id">
                    {{client.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                {{ 'executionEdit.protocol.client.error' | translate }}
            </mat-error>
        </mat-form-field>

        <div *ngIf="data?.useExistingProtocol">
            <mat-label>{{ 'executionEdit.protocol.client.label' | translate }}</mat-label>
            <mat-select name="idClientProtocol" [(ngModel)]="data.idClientProtocol">
                <mat-option *ngFor="let item of clients" [value]="item.id">
                    {{item.name}} ({{item.address}})
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div fxFlex="30">
        <mat-form-field class="campoFormulario">
          <input matInput name="documentCode" [(ngModel)]="data.projectNo" required
            placeholder="{{ 'protocolEdit.dialog.documentCode.documentCode.label' | translate }}">
          <mat-error>
            {{ 'protocolEdit.dialog.documentCode.documentCode.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="30" *ngIf="!data.useExistingProtocol && data?.idProtocol == null">
        <mat-form-field class="campoFormulario">
            <mat-label>{{ 'protocolEdit.general.projectManager.label' | translate }}</mat-label>
            <mat-select name="projectManager" [(ngModel)]="data.usernameProjectManager">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let item of respField" [value]="item.username">
                    {{ item.fullName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
      </div>
  </div>

</div>

<div mat-dialog-actions align="end" class="getTheme()">
  <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
  <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
</div>