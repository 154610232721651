import { Calibration, CalibrationAuditFilter, CalibrationFilter } from '../model/calibration';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CalibrationReportFilter } from '../model/attachment';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { QueryParam, ServiceUtil } from '../utils/serviceUtils';

@Injectable()
export class CalibrationService {

    constructor(private http: HttpClient) {
    }

    findAll(filter: CalibrationFilter): Observable<any> {
        const url = environment.calibratesUrl + '/calibration/';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getExcel(filter: CalibrationFilter): Observable<any> {
        const url = environment.calibratesUrl + '/calibration/excel';

        return this.http.post(url, filter, { responseType: 'blob' });
    }

    findOne(id: number): Observable<any> {
        const url = environment.calibratesUrl + `/calibration/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getAudit(id: number, filter: CalibrationAuditFilter): Observable<any> {
        const url = environment.calibratesUrl + `/calibration/${id}` + '/audit';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllFromEquipment(id: number): Observable<any> {
        const url = environment.calibratesUrl + `/calibration/equipment/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findLastFromEquipment(id: number): Observable<any> {
        const url = environment.calibratesUrl + `/calibration/equipment/${id}/last`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findUncertaintyFromEquipment(idEquipment: number, idVariable: number): Observable<any> {
        const url = environment.calibratesUrl + `/calibration/equipment/${idEquipment}/variable/${idVariable}/uncertainty`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    interpolateValuePattern(idPattern: number, idVariable: number, value: number): Observable<any> {
        const url = environment.calibratesUrl
            + `/calibration/equipment/${idPattern}/variable/${idVariable}/uncertainty/interpolate/${value}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    save(calibration: Calibration, token: string): Observable<any> {
        let url = environment.calibratesUrl + '/calibration/';

        const form = new FormData();
        form.append('calibration', JSON.stringify(calibration));
        form.append('token', token);

        if (calibration.id !== null && calibration.id !== undefined) {
            url += calibration.id;
            return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            url += 'create';
            return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        }
    }

    manualSign(calibration: Calibration, document: File, token: string): Observable<any> {
        const url = environment.calibratesUrl + `/calibration/${calibration.id}/manualSign`;

        const form = new FormData();
        form.append('document', document, document.name);
        form.append('calibration', JSON.stringify(calibration));
        form.append('token', token);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    automaticSign(idCalibration: number, token: string, lang?: string): Observable<any> {
        const url = environment.calibratesUrl + `/calibration/${idCalibration}/automaticSign`;

        const form = new FormData();
        form.append('token', token);

        const headers = new HttpHeaders({ lang });

        return this.http.put(url, form, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    automaticSignBulk(ids: number[], token: string, lang?: string): Observable<any> {
        const url = environment.calibratesUrl + '/calibration/bulk/automaticSign';

        const form = new FormData();
        form.append('ids', ids.join(';'));
        form.append('token', token);

        const headers = new HttpHeaders({ lang });

        return this.http.put(url, form, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadCertificates(ids: number[], lang: string): Observable<any> {
        const url = environment.calibratesUrl + '/calibration/bulk/downloadCertificates';
        let headers = new HttpHeaders({});

        const form = new FormData();
        form.append('ids', ids.join(';'));

        if (lang != null) {
            headers = new HttpHeaders({ lang });
        }

        return this.http.post(url, form, { responseType: 'blob', headers });
    }

    delete(id: number): Observable<any> {
        const url = environment.calibratesUrl + `/calibration/${id}`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadPdf(id: number, config: CalibrationReportFilter = new CalibrationReportFilter()): Observable<any> {
        const url = environment.calibratesUrl + `/report/calibration/${id}`;

        const lang: string = config.lang || 'es';
        const headers = new HttpHeaders({ lang });

        const data = {
            toSign: config.toSign,
            reason: config.reason
        };

        return this.http.post(url, data, { responseType: 'blob', headers });
    }

    downloadPdfCalibrationVersion(calibration: Calibration): Observable<any> {
        return this.downloadPdfVersion(calibration.id, calibration.signedVersion);
    }

    downloadPdfVersion(idCalibration: number, version: string): Observable<any> {
        const url = environment.calibratesUrl + `/report/calibration/${idCalibration}/version/${version}`;

        return this.http.post(url, {}, { responseType: 'blob' });
    }

    downloadPdfLastVersion(idCalibration: number): Observable<any> {
        const url = environment.calibratesUrl + `/calibration/${idCalibration}/version/latest/pdf`;

        return this.http.get(url, { responseType: 'blob' });
    }

    downloadAuditPdf(id: number): Observable<any> {
        const url = environment.calibratesUrl + `/report/calibration/${id}/audit`;

        return this.http.get(url, { responseType: 'blob' });
    }

    advance(calibration: Calibration, token: string): Observable<any> {
        const url = environment.calibratesUrl + `/calibration/${calibration.id}/advance`;

        const form = new FormData();
        form.append('token', token);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    back(calibration: Calibration, token: string): Observable<any> {
        const url = environment.calibratesUrl + `/calibration/${calibration.id}/back`;

        const form = new FormData();
        form.append('token', token);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    revertSign(calibration: Calibration, reason: string, token: string): Observable<any> {
        const url = environment.calibratesUrl + `/calibration/${calibration.id}/revertSign`;

        const form = new FormData();
        form.append('token', token);
        form.append('reason', reason);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    invalidate(id: number, token: string, reason: string): Observable<any> {
        const url = environment.calibratesUrl + `/calibration/${id}/invalidate`;

        const form = new FormData();
        form.append('token', token);
        form.append('reason', reason);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    checkCopyToGroup(id: number, idGroup: number): Observable<any> {
        const url = environment.calibratesUrl + `/calibration/clonate/${id}/toGroup/${idGroup}/check`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    copyToGroup(id: number, idGroup: number): Observable<any> {
        const url = environment.calibratesUrl + `/calibration/clonate/${id}/toGroup/${idGroup}`;

        return this.http.put(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getExcelTemplate(): Observable<any> {
        const url = environment.calibratesUrl + '/calibration/excel-template';

        return this.http.get(url, { responseType: 'blob' });
    }

    checkCopyMasiveToGroup(ids: number[]): Observable<any> {
        const url = environment.calibratesUrl + '/calibration/clonate/masive/toGroup/check';
        const params: QueryParam[] = [{key: 'ids', value: ids}]
        return this.http.get(ServiceUtil.createQueryParams(url, params)).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    copyMasiveToGroup(ids: number[]): Observable<any> {
        const url = environment.calibratesUrl + '/calibration/clonate/masive/toGroup';
        return this.http.put(url, ids).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

}
