<h1 mat-dialog-title>{{ 'protocolEdit.dialog.essay.criteria.title' | translate }}</h1>

<div mat-dialog-content class="air-theme">

    <div fxLayout="row" class="flexwrap">
        <div>
            <mat-form-field class="campoFormulario">
                <mat-select required [(ngModel)]="data.criteria.idType" [disabled]="criterias.length === 1"
                    (selectionChange)="onCriteriaChange($event)">
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let item of criterias" [value]="item.id">
                        {{ 'protocolEdit.dialog.essay.criteria.' + item.translation + '.label' | translate }}
                    </mat-option>
                </mat-select>
                <mat-error>
                    {{ 'protocolEdit.dialog.essay.criteria.error' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex *ngIf="criteriaSelected != null && criteriaSelected.variablesToShow >= 1">
            <mat-form-field class="campoFormulario">
                <input matInput [name]="criteriaValue1" [(ngModel)]="data.criteria.criteriaValue1" required
                    placeholder="{{ 'protocolEdit.dialog.essay.criteria.criteriaValue1.label' | translate }}"
                    [errorStateMatcher]="matcher" type="number" [disabled]="data.disabled">
                <mat-error>
                    {{ 'protocolEdit.dialog.essay.criteria.criteriaValue1.error' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex *ngIf="criteriaSelected != null && criteriaSelected.variablesToShow >= 2">
            <mat-form-field class="campoFormulario">
                <input matInput [name]="criteriaValue2" [(ngModel)]="data.criteria.criteriaValue2" required
                    placeholder="{{ 'protocolEdit.dialog.essay.criteria.criteriaValue2.label' | translate }}"
                    [errorStateMatcher]="matcher" type="number" [disabled]="data.disabled">
                <mat-error>
                    {{ 'protocolEdit.dialog.essay.criteria.criteriaValue2.error' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex *ngIf="showCriteriaCustomSubValue()">
            <mat-form-field class="campoFormulario">
                <mat-select required [(ngModel)]="data.criteria.customValue1" [disabled]="getTotalSubvalues() === 1"
                    (selectionChange)="onCriteriaCustomSubValueChange($event)">
                    <mat-optgroup *ngFor="let group of getCustomValues()"
                        label="{{ 'protocolEdit.dialog.essay.criteria.' + group.translation + '.label' | translate }}">
                        <mat-option *ngFor="let item of mapCustomValues.get(group)" [value]="item.value">
                            {{ 'protocolEdit.dialog.essay.criteria.' + item.translation + '.label' | translate }}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div fxLayout="row" class="flexwrap" *ngIf="showParticleSize()">
        <div fxFlex>
            <mat-form-field class="campoFormulario">
                <mat-select required [ngModel]="getParticleValue(0)" required
                    (selectionChange)="onParticleValueChange($event, 0)">
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let item of getParticleSize()" [value]="item">
                        {{ item + 'µm' }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex>
            <mat-form-field class="campoFormulario">
                <mat-select required [ngModel]="getParticleValue(1)" required
                    (selectionChange)="onParticleValueChange($event, 1)">
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let item of getParticleSize()" [value]="item">
                        {{ item + 'µm' }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex *ngIf="showThirdParticleSize()">
            <mat-form-field class="campoFormulario">
                <mat-select required [ngModel]="getParticleValue(2)" required
                    (selectionChange)="onParticleValueChange($event, 2)">
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let item of getParticleSize()" [value]="item">
                        {{ item + 'µm' }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div fxLayout="row" class="flexwrap" *ngIf="showCustomText()">
        <div fxFlex *ngIf="showCustomText1()">
            <mat-form-field class="campoFormulario">
                <input matInput name="customText1" [(ngModel)]="data.criteria.customValue1" required
                    placeholder="{{ 'protocolEdit.dialog.essay.' + getCustomText1() + '.label' | translate }}">
            </mat-form-field>
        </div>
        <div fxFlex *ngIf="showCustomText2() || isWorkAnexI">
            <mat-form-field class="campoFormulario">
                <input matInput name="customText2" [(ngModel)]="data.criteria.customValue2" required
                    placeholder="{{ 'protocolEdit.dialog.essay.' + getCustomText2() + '.label' | translate }}">
            </mat-form-field>
        </div>
    </div>

    <div fxLayout="row" *ngIf="isAnexoI">
            <div fxFlex="100" *ngIf="textOneParticleAnexI || isTextOneAnexI">
                <div fxFlex="100" *ngIf="textOneParticleAnexI" style="margin: auto;">
                    <span>{{textOneParticleAnexI}}</span>
                </div>
                <div fxFlex="100" *ngIf="isTextOneAnexI">
                    <mat-form-field class="campoFormulario">
                        <input matInput name="customText1" [(ngModel)]="valueCriteria1" required
                            placeholder="{{ (isMinParticleAnexI ? 'protocolEdit.dialog.essay.particleMinAnexI.label' : 'protocolEdit.dialog.essay.particleMaxAnexI.label') | translate }}">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="100" *ngIf="textTwoParticleAnexI || isTextTwoAnexI">
                <div fxFlex="100" *ngIf="textTwoParticleAnexI" style="margin: auto;">
                    <span>{{textTwoParticleAnexI}}</span>
                </div>
                <div fxFlex="100" *ngIf="isTextTwoAnexI">
                    <mat-form-field class="campoFormulario">
                        <input matInput name="customText2" [(ngModel)]="valueCriteria2" required
                            placeholder="{{ (isMaxParticleAnexI ? 'protocolEdit.dialog.essay.particleMaxAnexI.label' : 'protocolEdit.dialog.essay.particleMinAnexI.label') | translate }}">
                    </mat-form-field>
                </div>
            </div>
    </div>

</div>

<div mat-dialog-actions align="end" class="air-theme">
    <button mat-raised-button color="primary" (click)="onOkClick()">
        {{ 'button.save' | translate }} </button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
</div>