import { DependencyTypeEnum, EssayTypeEnum } from './essayUtils';

import { ArrayUtils } from './arrayUtils';
import { BioindicatorConfig } from '../model/bioindicator';
import { Essay } from '../model/essay';
import { EssayProtocol } from '../model/essayProtocol';
import { Execution } from '../model/execution';
import { StringUtils } from './stringUtils';
import { TranslateService } from '@ngx-translate/core';

export class ExecutionUtils {

    public static validateExecution(execution: Execution, translate: TranslateService): string[] {
        const errors: string[] = [];

        execution.essays.forEach((essay: Essay, essayIndex: number) => {
            const essayName = translate.instant('essayType.' + essay.translationType) as string;

            if (!essay.active) {

                if (StringUtils.isEmpty(essay.reasonActive)) {
                    errors.push(translate.instant('executionEdit.form.error.reasonActive', { essayType: essayName }) as string);
                }

                return;
            }

            const hasGeneralPhoto = essay.idPhoto != null;

            essay.essayValues.forEach((essayValue: EssayProtocol, essayValueIndex: number) => {
                const dsVariable = translate.instant('variable.' + essayValue.translationVariable) as string;

                if (essayValue.setpoint == null && this.showSetpoint(execution.essays, essayIndex, essayValueIndex)) {
                    errors.push(translate.instant('executionEdit.form.error.setpoint', { essayType: essayName }) as string);
                }

                if (essayValue.dsLoad == null && this.showRealLoad(execution.essays, essayIndex, essayValueIndex)) {
                    errors.push(translate.instant('executionEdit.form.error.realLoad', { essayType: essayName }) as string);
                }

                essayValue.sensors.forEach((sensor, indexSensor) => {
                    if (sensor.equipmentName == null) {
                        errors.push(translate.instant('executionEdit.essays.sensor.form.error.serialNum',
                            { index: indexSensor + 1, essayType: essayName, variable: dsVariable }) as string);
                    }

                    if (sensor.evaluate && sensor.idPhoto == null && !hasGeneralPhoto && this.showPhotoButton(execution.essays, essayIndex,
                        essayValueIndex, indexSensor)) {
                        errors.push(translate.instant('executionEdit.essays.sensor.form.error.photo',
                            { index: indexSensor + 1, essayType: essayName, variable: dsVariable }) as string);
                    }

                    if (sensor.evaluate && sensor.idExcel == null && this.showExcelButton(execution.essays, essayIndex, essayValueIndex,
                        indexSensor)) {
                        errors.push(translate.instant('executionEdit.essays.sensor.form.error.excel',
                            { index: indexSensor + 1, essayType: essayName, variable: dsVariable }) as string);
                    }

                    if (sensor.evaluate && sensor.idPrimaryDataFile == null
                        && this.showPrimaryButton(execution.essays, essayIndex, essayValueIndex, indexSensor)) {
                        errors.push(translate.instant('executionEdit.essays.sensor.form.error.primaryData',
                            { index: indexSensor + 1, essayType: essayName, variable: dsVariable }) as string);
                    }
                });
            });
        });

        return errors;
    }

    public static checkBioindicators(execution: Execution, translate: TranslateService): string[] {
        const errors: string[] = [];

        execution.essays.forEach(essay => {

            if (!essay.active) {
                return;
            }

            const essayName = translate.instant('essayType.' + essay.translationType) as string;

            essay.essayValues.forEach(essayValue => {
                const bioConfig = essayValue.bioindicatorsConfig;
                if (bioConfig != null) {
                    errors.push(...this.validateBioindicators(bioConfig, essayName, 'bioindicator', translate));
                }
                const endoConfig = essayValue.endotoxinsConfig;
                if (endoConfig != null) {
                    errors.push(...this.validateBioindicators(endoConfig, essayName, 'endotoxin', translate));
                }

            });

        });

        return errors;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public static showPhotoButton(essays: Essay[], essayIndex: number, variableIndex: number, sensorIndex: number): boolean {
        const variableSelected = essays[essayIndex].essayValues[variableIndex];

        const idsHide = [DependencyTypeEnum.MANDATORY];

        return !idsHide.includes(variableSelected.idTypeDependency);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public static showExcelButton(essays: Essay[], essayIndex: number, variableIndex: number, sensorIndex: number): boolean {
        return true;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public static showPrimaryButton(essays: Essay[], essayIndex: number, variableIndex: number, sensorIndex: number): boolean {
        return true;
    }

    public static showSetpoint(essays: Essay[], essayIndex: number, essayValueIndex: number): boolean {
        const totalSensors = essays[essayIndex].essayValues[essayValueIndex].sensors.length;

        return essayValueIndex === 0 && totalSensors !== 0;
    }

    public static showRealLoad(essays: Essay[], essayIndex: number, essayValueIndex: number): boolean {
        const idType = essays[essayIndex].idType;

        const allowedEssays = [
            EssayTypeEnum.DISTRIBUCION_CON_MEDIA_CARGA,
            EssayTypeEnum.DISTRIBUCION_CON_CARGA,
            EssayTypeEnum.DISTRIBUCION_CON_CARGA_COMPLETA,
            EssayTypeEnum.ENSAYO_APERTURA_DE_PUERTA,
            EssayTypeEnum.ENSAYO_CORTE_ELECTRICO,
            EssayTypeEnum.PENETRACION_CON_CARGA
        ];

        return this.showSetpoint(essays, essayIndex, essayValueIndex) && allowedEssays.includes(idType);
    }

    public static getNameForReportPdf(ex: Execution, translate: TranslateService): string {
        const name = this.getNameForReportGeneric(ex, translate);

        return translate.instant('executionEdit.files.report', { name }) as string;
    }

    public static getNameForPreReportPdf(ex: Execution, translate: TranslateService): string {
        const name = this.getNameForReportGeneric(ex, translate);

        return translate.instant('executionEdit.files.report-pre', { name }) as string;
    }

    public static getNameForReportZip(ex: Execution, translate: TranslateService): string {
        const name = this.getNameForReportGeneric(ex, translate);

        return translate.instant('executionEdit.files.report-zip', { name }) as string;
    }

    private static getNameForReportGeneric(ex: Execution, translate: TranslateService): string {
        const documentCode = ex.documentCode;
        const versionName = 'v.' + ex.currentVersion.toString().padStart(2, '0');
        const clientName = ex.nameClient;
        let equipment = null;
        let serialNum = null;
        let internalId = null;

        if (!ArrayUtils.isEmpty(ex.equipments)) {
            const eq = ex.equipments[0];

            equipment = eq.equipment;
            if (StringUtils.isNotEmpty(eq.serialNum)) {
                serialNum = '(' + eq.serialNum + ')';
            }

            if (StringUtils.isNotEmpty(eq.internalId)) {
                internalId = '(' + eq.internalId + ')';
            }
        }

        return [documentCode, versionName, translate.instant('executionEdit.files.execution.documentType'), clientName, equipment,
            serialNum, internalId].filter((s: string) => StringUtils.isNotEmpty(s)).join(' ');
    }

    private static validateBioindicators(
        bioConfig: BioindicatorConfig, essayName: string, type: string, translate: TranslateService): string[] {
        const errors: string[] = [];

        if (type == null) {
            type = 'bioindicator';
        }

        if (bioConfig.optionSelected === 'cert') {

            if (bioConfig.certificates == null || bioConfig.certificates.length < 1) {
                errors.push(translate.instant(`executionEdit.generateReport.form.error.${type}s.certificate`,
                 { essay: essayName }) as string);
            }

        } else if (bioConfig.optionSelected === 'batch') {

            if (bioConfig.batchs == null || bioConfig.batchs.length === 0) {
                errors.push(translate.instant(`executionEdit.generateReport.form.error.${type}s.batch.empty`,
                { essay: essayName }) as string);
            } else {
                bioConfig.batchs.forEach((batch, batchIndex) => {

                    if (StringUtils.isEmpty(batch.batchId)) {
                        errors.push(translate.instant(`executionEdit.generateReport.form.error.${type}s.batch.batchId`,
                            { num: (batchIndex + 1), essay: essayName }) as string);
                    }

                    if (batch.expiryDate == null) {
                        errors.push(translate.instant(`executionEdit.generateReport.form.error.${type}s.batch.expiryDate`,
                            { num: (batchIndex + 1), essay: essayName }) as string);
                    }

                });
            }

        } else if (bioConfig.optionSelected === 'reason') {
            bioConfig.certificates = [];
            bioConfig.batchs = [];

            if (StringUtils.isEmpty(bioConfig.reason)) {
                errors.push(translate.instant(`executionEdit.generateReport.form.error.${type}s.reason`, { essay: essayName }) as string);
            }
        }

        return errors;
    }

}
