/* eslint-disable @typescript-eslint/no-misused-promises */
import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../services/spinner.service';
import { LoginService } from '../../../services/login.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-clients',
  templateUrl: './login-clients.component.html',
  styleUrls: ['./login-clients.component.scss']
})
export class LoginClientsComponent implements OnInit {

  username: string;
  password: string;
  error: string;

  showPassword = false;

  private destroy$ = new Subject<void>();

  constructor(private router: Router,
              private spinnerService: SpinnerService,
              private loginService: LoginService,
              private translate: TranslateService) { }

  ngOnInit(): void {
  }

  login(): void {
    this.error = null;

    if (this.username == null || this.password == null) {
      return;
    }

    const username = this.username.trim();
    const password = this.password.trim();

    if (this.username === '' || this.password === '') {
      return;
    }

    this.spinnerService.show();

    this.loginService.login(username, password).pipe(takeUntil(this.destroy$)).subscribe(async () => {
      const result = await this.loginService.goHomeClient();
      if (!result) {
        this.error = this.translate.instant('login.error.generic') as string;
      }
      this.spinnerService.hide();
    }, err => {
      if (err.error && err.error.error && (typeof err.error.error) === 'string') {
        this.error = err.error.error;
      } else {
        this.error = this.translate.instant('login.error.generic') as string;
      }

      this.spinnerService.hide();
    });

  }

  forgotPassword(): void {
    void this.router.navigate(['/forgotPasswordClient'], { queryParamsHandling: 'merge' });
  }


}
