import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DexieService } from './dexieService';
import { OnlineService } from './online.service';
import { Observable, from, map } from 'rxjs';
import { HttpUtils } from '../utils/httpUtils';
import { ProtocolAttachmentFilter } from '../model/protocol';

@Injectable({
  providedIn: 'root'
})
export class ProtocolQTestsService {

  protected readonly url = `${environment.qualificatesUrl}/protocolQTest`;

    constructor(private http: HttpClient,
        private dexieService: DexieService,
        private onlineService: OnlineService) { }

    findByTestTypeId(id: number): Observable<any>{
        if (this.onlineService.latestOnline) {
            return this.http.get(`${this.url}/testType/${id}`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.roomType.toArray().timeout(30000));
        }
    }

    findAll(id: number): Observable<any>{
        if (this.onlineService.latestOnline) {
            return this.http.get(`${this.url}/testType/${id}`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.roomType.toArray().timeout(30000));
        }
    }
    getAttachments(id: number, filter: ProtocolAttachmentFilter): Observable<any> {
        const url = `${this.url}/${id}/attachment`;
        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
