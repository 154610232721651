<mat-sidenav-container>

  <mat-sidenav #sidenav mode="over" class="side-menu-radius">

    <mat-nav-list>

      <span style="padding: 10px; font-weight: 700;">Selecciona servicio</span>
      <br>
      <br>
      <button class="button-selected-app" [matMenuTriggerFor]="selectedApp" [disabled]="disableOfflineButton">
        <div>
          <img class="size-img-sidebar" *ngIf="appActive != null" [src]="'assets/img/text-'+appActive?.toLowerCase()+'.png'"/>
          <mat-icon role="img" class="mat-icon notranslate material-icons" aria-hidden="true">arrow_drop_down
          </mat-icon>
        </div>
      </button>
      <mat-menu #selectedApp="matMenu">
        <div *ngFor="let item of profils" class="container-menu-main">
          <img width="35%" [src]="'assets/img/text-'+item.appName?.toLowerCase()+'.png'" (click)="onSelectionOption(item.idApp)"/>
        </div>
      </mat-menu>

      <a class="first-level" mat-list-item (click)="redirectionPage(); sidenav.close()">
       <mat-icon class="icon-menu-item-sidebar">dashboard</mat-icon> 
       {{ 'menu.home' | translate }}
      </a>

      <a class="first-level" mat-list-item *ngIf="showAir()"
        [routerLink]="checkRouterDisabledOffline('/protocols')" (click)="sidenav.close()"
        [class.disabled]="disableOfflineButton ? null: true">
        <img class="icon-menu-item-sidebar" src="{{ getThemeIconPath() }}protocols.svg">
        {{ 'menu.manage-protocols' | translate }}
      </a>

      <a class="first-level" mat-list-item *ngIf="showAir()"
        [routerLink]="checkRouterDisabledOffline('/executions')" (click)="sidenav.close()"
        [class.disabled]="disableOfflineButton ? null: true">
        <img class="icon-menu-item-sidebar" src="{{ getThemeIconPath() }}executions.svg">
        {{ 'menu.service-executions' | translate }}
      </a>
      
      <!-- Thermal-->
      <mat-expansion-panel *ngIf="showThermal()">
        <mat-expansion-panel-header>
          <a class="first-level" fxFlex  [routerLink]="checkRouterDisabledOffline('/protocols')" (click)="sidenav.close()">
            <img class="icon-menu-item-sidebar" src="{{ getThemeIconPath() }}protocols.svg">
           <span style="margin: auto;">{{ 'menu.manage-protocols' | translate }}</span>
          </a>
        </mat-expansion-panel-header>
        <div *ngFor="let item of phases; let index = index">
          <a mat-list-item class="first-level-submenu-item" (click)="goToProtocol(item); sidenav.close()"
            *ngIf="item.enabled">{{ 'phase.'+ item.translation | translate }}</a>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="showThermal()">
        <mat-expansion-panel-header>
          <a class="first-level" fxFlex  [routerLink]="checkRouterDisabledOffline('/executions')" (click)="sidenav.close()">
            <img class="icon-menu-item-sidebar" src="{{ getThemeIconPath() }}executions.svg">
            <span style="margin: auto;"> {{ 'menu.service-executions' | translate }}</span>
          </a>
        </mat-expansion-panel-header>
        <div *ngFor="let item of phases; let index = index">
          <a mat-list-item class="first-level-submenu-item" (click)="goToExecutions(item); sidenav.close()"
            *ngIf="item.enabled">{{ 'phase.'+ item.translation | translate }}</a>
        </div>
      </mat-expansion-panel>

      <a class="first-level" mat-list-item  *ngIf="showCalibrates()"
        [routerLink]="checkRouterDisabledOffline('/calibrations')" (click)="sidenav.close()"
        [class.disabled]="disableOfflineButton ? null: true">
        <img class="icon-menu-item-sidebar" src="{{ getThemeIconPath() }}calibrates.svg">
        {{ 'menu.calibrate-equipments' | translate }}
      </a>

      <a class="first-level" mat-list-item [routerLink]="checkRouterDisabledOffline('/isothermalCharacterizations')"
        (click)="sidenav.close()" [class.disabled]="disableOfflineButton ? null: true" *ngIf="showCharacterization()">
        <img class="icon-menu-item-sidebar" src="{{ getThemeIconPath() }}isothermalCharacterization.svg">
        {{ 'menu.isothermal-characterization' | translate }}
      </a>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <a class="first-level" fxFlex [routerLink]="checkRouterDisabledOffline('/internalEquipments')"
            (click)="sidenav.close()" [class.disabled]="disableOfflineButton ? null: true">
            <img class="icon-menu-item-sidebar" src="{{ getThemeIconPath() }}internalEquipments.svg">
            <span style="margin: auto;">{{ 'menu.my-equipments' | translate }}</span>
          </a>
        </mat-expansion-panel-header>
        <div>
          <a class="second-level" mat-list-item [routerLink]="checkRouterDisabledOffline('/admin/calibrationPlan')"
            (click)="sidenav.close()" *ngIf="showCalibrationPlan()"
            [class.disabled]="disableOfflineButton ? null: true">
            {{ 'menu.manage-calibratioPlan' | translate }}
          </a>
        </div>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <!-- This is the tree node template for leaf nodes -->
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="5">
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled></button>
            <a style="cursor: pointer;" (click)="goToInternalEquipment(node); sidenav.close()">
              {{ node.name | translate }}
            </a>
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="10">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
              </mat-icon>
            </button>
            <a style="cursor: pointer;" (click)="goToInternalEquipment(node); sidenav.close()">
              {{ node.name | translate }}
            </a>
          </mat-tree-node>
        </mat-tree>

      </mat-expansion-panel>

      <a class="first-level" mat-list-item *ngIf="showCalibrates() && user.showExternalEquipments"
        [routerLink]="checkRouterDisabledOffline('/externalEquipments')" (click)="sidenav.close()"
        [class.disabled]="disableOfflineButton ? null: true">
        <img class="icon-menu-item-sidebar" src="{{ getThemeIconPath() }}externalEquipments.svg">
        {{ 'menu.manage-external-equipments' | translate }}
      </a>

       <!-- Air
       <mat-expansion-panel *ngIf="showAir()">
        <mat-expansion-panel-header>
          <a class="first-level">
            {{ 'Qloud Aire' }}
          </a>
        </mat-expansion-panel-header>
        <a class="first-level" mat-list-item
          [routerLink]="checkRouterDisabledOffline('/air/protocols')" (click)="sidenav.close()"
          [class.disabled]="disableOfflineButton ? null: true">
          {{ 'menu.manage-protocols' | translate }}
        </a>
        <a class="first-level" mat-list-item
          [routerLink]="checkRouterDisabledOffline('/air/executions')" (click)="sidenav.close()"
          [class.disabled]="disableOfflineButton ? null: true">
          {{ 'menu.service-executions' | translate }}
        </a>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="showThermal()">
        <mat-expansion-panel-header>
          <a class="first-level">
            {{ 'Qloud Thermal' }}
          </a>
        </mat-expansion-panel-header>
        <mat-expansion-panel *ngIf="showThermal()">
          <mat-expansion-panel-header>
            <a class="first-level" routerLink="/protocols" (click)="sidenav.close()">
              {{ 'menu.manage-protocols' | translate }}
            </a>
          </mat-expansion-panel-header>
          <div *ngFor="let item of phases; let index = index">
            <a mat-list-item class="first-level-submenu-item" (click)="goToProtocol(item); sidenav.close()"
              *ngIf="item.enabled">{{ 'phase.'+ item.translation | translate }}</a>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="showThermal()">
          <mat-expansion-panel-header>
            <a class="first-level" routerLink="/executions" (click)="sidenav.close()">
              {{ 'menu.service-executions' | translate }}
            </a>
          </mat-expansion-panel-header>
          <div *ngFor="let item of phases; let index = index">
            <a mat-list-item class="first-level-submenu-item" (click)="goToExecutions(item); sidenav.close()"
              *ngIf="item.enabled">{{ 'phase.'+ item.translation | translate }}</a>
          </div>
        </mat-expansion-panel>
      </mat-expansion-panel>

      Calibrates
      <mat-expansion-panel *ngIf="showCalibrates()">
        <mat-expansion-panel-header>
          <a class="first-level">
            {{ 'Qloud Calibraciones' }}
          </a>
        </mat-expansion-panel-header>
       
      </mat-expansion-panel>

      Equipment
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <a class="first-level" [routerLink]="checkRouterDisabledOffline('/internalEquipments')"
            (click)="sidenav.close()" [class.disabled]="disableOfflineButton ? null: true">
            {{ 'menu.my-equipments' | translate }}
          </a>
        </mat-expansion-panel-header>
        <div>
          <a class="second-level" mat-list-item [routerLink]="checkRouterDisabledOffline('/admin/calibrationPlan')"
            (click)="sidenav.close()" *ngIf="showCalibrationPlan()"
            [class.disabled]="disableOfflineButton ? null: true">
            {{ 'menu.manage-calibratioPlan' | translate }}
          </a>
        </div>

      </mat-expansion-panel>-->
      
      <mat-divider *ngIf="user.admin || showManageClients()"></mat-divider>

      <mat-expansion-panel *ngIf="user.admin || showManageClients()">
        <mat-expansion-panel-header>
          <a class="first-level" [class.disabled]="disableOfflineButton ? null: true">
            <mat-icon class="icon-menu-item-sidebar">settings</mat-icon> 
            <span style="margin: auto;"> {{ 'menu.manage-setting' |  translate }}</span>
          </a>
        </mat-expansion-panel-header>
        <a class="second-level" mat-list-item [routerLink]="checkRouterDisabledOffline('/clients')"
          (click)="sidenav.close()" *ngIf="showManageClients()" [class.disabled]="disableOfflineButton ? null: true">
          {{ 'menu.manage-clients' | translate }}
        </a>

        <a class="second-level" mat-list-item [routerLink]="checkRouterDisabledOffline('/admin/manageUsers')"
          (click)="sidenav.close()" *ngIf="user.admin" [class.disabled]="disableOfflineButton ? null: true">
          {{ 'menu.manage-users' | translate }}
        </a>

        <a class="second-level" mat-list-item [routerLink]="checkRouterDisabledOffline('/admin/manageGroups')"
          (click)="sidenav.close()" *ngIf="user.admin" [class.disabled]="disableOfflineButton ? null: true">
          {{ 'menu.manage-groups' | translate }}
        </a>

        <a class="second-level" mat-list-item [routerLink]="checkRouterDisabledOffline('/admin/procedure')"
          (click)="sidenav.close()" *ngIf="user.admin" [class.disabled]="disableOfflineButton ? null: true">
          {{ 'menu.manage-procedures' | translate }}
        </a>
      </mat-expansion-panel> 

      <mat-expansion-panel *ngIf="user.admin || showManageClients()">
        <mat-expansion-panel-header>
          <a class="first-level" [class.disabled]="disableOfflineButton ? null: true">
            <mat-icon class="icon-menu-item-sidebar">notifications_active</mat-icon> 
            <span style="margin: auto;"> {{ 'menu.item.manage-notifications' | translate}}</span>
          </a>
        </mat-expansion-panel-header>
        <a class="second-level" mat-list-item [routerLink]="checkRouterDisabledOffline('/admin/notifications')" 
          (click)="sidenav.close()" *ngIf="user.admin || showManageClients()" [class.disabled]="disableOfflineButton ? null: true">
          {{ 'menu.manage-notifications' | translate }}
        </a>
        <a class="second-level" mat-list-item [routerLink]="checkRouterDisabledOffline('/admin/sendNotifications')"
          (click)="sidenav.close()" *ngIf="user.admin && !user.manager"
          [class.disabled]="disableOfflineButton ? null: true">
          {{ 'menu.send-notifications' | translate }}
        </a>
      </mat-expansion-panel>

      <a class="first-level" mat-list-item [routerLink]="checkRouterDisabledOffline('/admin/manageAppPlan')"
          (click)="sidenav.close()" *ngIf="user.admin && !user.manager"
          [class.disabled]="disableOfflineButton ? null: true">
          <mat-icon class="icon-menu-item-sidebar">work_outline</mat-icon> 
          {{ 'menu.manage-appPlan' | translate }}
      </a>

      <a class="first-level" mat-list-item [matMenuTriggerFor]="lang"  *ngIf="showChangeLangIcon()">
          <mat-icon class="icon-menu-item-sidebar">language</mat-icon>
          <mat-icon *ngIf="activeFlag" svgIcon="{{activeFlag}}"></mat-icon>
          <span style="margin-left: 10px;">{{activeNameFlag}}</span>
          <mat-menu #lang="matMenu">
            <div *ngFor="let item of langs">
              <button mat-menu-item *ngIf="(translationService.lang$ | async) != item.lang"
                (click)="changeLang(item)">
                <mat-icon svgIcon="{{item.flag}}"></mat-icon>
                <span>{{item.fullLang}}</span>
              </button>
            </div>
          </mat-menu>
        </a>
    </mat-nav-list>

  </mat-sidenav>
  <mat-sidenav-content fxLayout="column" fxLayoutAlign="space-between" style="overflow-x: inherit;">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </mat-sidenav-content>

</mat-sidenav-container>