<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form [formGroup]="form">
  <div mat-dialog-content class="air-theme">

    <div fxLayout="row" class="flexwrap">

        <div fxFlex="100%">
          <mat-form-field class="campoFormulario">
            <mat-label>{{ 'executionEdit.dialog.equipment.type.label' | translate }}</mat-label>
            <mat-select required formControlName="idType" [(ngModel)]="data.equipment.idType">
                <mat-option *ngFor="let item of equipmentTypes" [value]="item.id">
                    {{ 'equipmentType.' + item.translation | translate }}
                </mat-option>
            </mat-select>
            <mat-error>
                {{ 'executionEdit.dialog.equipment.type.error' | translate }}
            </mat-error>
        </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput placeholder="{{ 'executionEdit.dialog.equipment.equipment.label' | translate }}"
              formControlName="equipment" [(ngModel)]="data.equipment.equipment" required>
            <mat-error>
              {{ 'executionEdit.dialog.equipment.equipment.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'executionEdit.dialog.equipment.maker.label' | translate }}"
            formControlName="maker" [(ngModel)]="data.equipment.maker">
          <mat-error>
            {{ 'executionEdit.dialog.equipment.maker.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'executionEdit.dialog.equipment.model.label' | translate }}"
            formControlName="model" [(ngModel)]="data.equipment.model">
          <mat-error>
            {{ 'executionEdit.dialog.equipment.model.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'executionEdit.dialog.equipment.serialNum.label' | translate }}"
            formControlName="serialNum" [(ngModel)]="data.equipment.serialNumber">
          <mat-error>
            {{ 'executionEdit.dialog.equipment.serialNum.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'executionEdit.dialog.equipment.internalId.label' | translate }}"
            formControlName="internalId" [(ngModel)]="data.equipment.internalId">
          <mat-error>
            {{ 'executionEdit.dialog.equipment.internalId.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'executionEdit.dialog.equipment.location.label' | translate }}"
            formControlName="location" [(ngModel)]="data.equipment.location" required>
          <mat-error>
            {{ 'executionEdit.dialog.equipment.location.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'executionEdit.dialog.equipment.rotorModel.label' | translate }}"
            formControlName="rotorModel" [(ngModel)]="data.equipment.rotorModel">
          <mat-error>
            {{ 'executionEdit.dialog.equipment.rotorModel.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'executionEdit.dialog.equipment.rotorSerial.label' | translate }}"
            formControlName="rotorSerial" [(ngModel)]="data.equipment.rotorSerial">
          <mat-error>
            {{ 'executionEdit.dialog.equipment.rotorSerial.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

  </div>
  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>