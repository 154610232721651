<div class="main-container">
  <form #calibrateEquipmentListForm="ngForm">

    <div class="toolbar-list">

      <div class="toolbar-list-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <img fxHide.lt-sm src="{{ getThemeIconPath() }}calibrates.svg"
            alt="{{ 'calibrateEquipmentList.title' | translate }}">
          <h1 class="accent-color">{{ 'calibrateEquipmentList.title' | translate }}</h1>
        </div>

        <div class="breadcrumbs">
          <a href="#">{{ 'common.start.label' | translate }}</a> /
          <span>{{ 'calibrateEquipmentList.title' | translate }}</span>
        </div>
      </div>

      <div class="toolbar-list-actions">
        <button mat-raised-button (click)="new()" color="accent"
          matTooltip="{{ 'button.disabled.offline' | translate }}">
          <mat-icon fxHide.lt-sm>add</mat-icon> {{ 'button.new' | translate }}
        </button>

        <button mat-raised-button (click)="automaticSignBulk()" color="accent" class="stroked"
          *ngIf="showAutomaticSign()">{{ 'button.automatic-sign-bulk' | translate }}</button>
        <button mat-raised-button (click)="downloadCertificates()" color="accent" class="stroked"
          *ngIf="showAutomaticSign()">{{ 'button.download-certificates' | translate }}</button>
      </div>

      <div class="toolbar-back-button">
        <a (click)="cancel()" mat-button color="accent">
          <mat-icon color="accent">undo</mat-icon> {{ 'button.back' | translate }}
        </a>
      </div>
    </div>

    <app-calibration-list-filter (filterEmitter)="doFilter($event)" class="filter-container">
    </app-calibration-list-filter>

    <div class="table-container">
      <table #calibrations mat-table matSort [dataSource]="dataSource">

        <!-- checkbox Column -->
        <ng-container matColumnDef="checkbox">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="onCheckAllItems($event)" *ngIf="showSelectAll()" [checked]="isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let item">
            <mat-checkbox *ngIf="showCheckbox(item)" (change)="onCheckItem($event)" [value]="item"
              [checked]="isSelected(item)"> </mat-checkbox>
          </td>
        </ng-container>

        <!-- name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'calibrateEquipmentList.cols.name' | translate }}
          </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{ item.equipment?.name }} </td>
        </ng-container>

        <!-- equipment Column -->
        <ng-container matColumnDef="equipment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'calibrateEquipmentList.cols.equipment' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)">
            <div style="white-space: pre-line;"> {{ item.equipment?.equipment }} </div>
          </td>
        </ng-container>

        <!-- maker Column -->
        <ng-container matColumnDef="maker">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'calibrateEquipmentList.cols.maker' | translate }}
          </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{item.equipment?.maker}} </td>
        </ng-container>

        <!-- model Column -->
        <ng-container matColumnDef="model">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'calibrateEquipmentList.cols.model' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{item.equipment?.model}} </td>
        </ng-container>

        <!-- serialNum Column -->
        <ng-container matColumnDef="serialNum">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'calibrateEquipmentList.cols.serialNum' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)">{{ item.equipment?.serialNum }} </td>
        </ng-container>

        <!-- certificateNum Column -->
        <ng-container matColumnDef="certificateNum">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'calibrateEquipmentList.cols.certificateNum' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)">{{ item.certificateNum }}
          </td>
        </ng-container>

        <!-- projectNo Column -->
        <ng-container matColumnDef="projectNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'calibrateEquipmentList.cols.projectNo' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)">{{ item.projectNo }}
          </td>
        </ng-container>

        <!-- status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'calibrateEquipmentList.cols.status' | translate }}
          </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{ 'calibrationStatus.' +
            item.statusTranslation | translate }}
          </td>
        </ng-container>

        <!-- calibrateDate Column -->
        <ng-container matColumnDef="calibrateDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'calibrateEquipmentList.cols.calibrateDate' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{ item.calibrationDate | fixDate }} </td>
        </ng-container>

        <!-- expirationDate Column -->
        <ng-container matColumnDef="expirationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'calibrateEquipmentList.cols.expirationDate' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{ item.expirationDate | fixDate }} </td>
        </ng-container>

        <!-- client Column -->
        <ng-container matColumnDef="client">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'calibrateEquipmentList.cols.client' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)">
            {{ item.equipment?.client != null ? item.equipment?.client?.name : ('common.internal' | translate) }}
          </td>
        </ng-container>

        <!-- realizationDate Column -->
        <ng-container matColumnDef="realizationDate">
          <th mat-header-cell *matHeaderCellDef> {{ 'calibrateEquipmentList.cols.realizationDate' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)">
            <div style="white-space: pre-line;">
              {{ item.realizationDate | fixDateTime }}
            </div>
          </td>
        </ng-container>

        <!-- Button download Column -->
        <ng-container matColumnDef="download">
          <th mat-header-cell *matHeaderCellDef fxHide.lt-lg [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action"
            matTooltip="{{ 'button.download' | translate }}">
            <button mat-icon-button (click)="downloadPdf(element)" *ngIf="canDownload(element)">
              <mat-icon>cloud_download</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button edit Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef fxHide.lt-lg [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action"
            matTooltip="{{ 'button.edit' | translate }}">
            <button mat-icon-button (click)="editRow(element.id)">
              <mat-icon>edit_mode</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button delete Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef fxHide.lt-lg [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button (click)="deleteRow(element.id)" *ngIf="canDelete(element)"
              matTooltip="{{ 'button.delete' | translate }}">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button clone Column -->
        <ng-container matColumnDef="clone">
          <th mat-header-cell *matHeaderCellDef fxHide.lt-lg [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button (click)="cloneRow(element.id)" *ngIf="canClone(element)"
              matTooltip="{{ 'button.clone' | translate }}">
              <mat-icon>folder_copy</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button more Column -->
        <ng-container matColumnDef="more" stickyEnd>
          <th mat-header-cell *matHeaderCellDef fxHide fxShow.lt-lg></th>
          <td mat-cell *matCellDef="let element" fxHide fxShow.lt-lg class="action">

            <mat-menu #appMenu="matMenu" class="moreActions">
              <ng-template matMenuContent let-name="element">
                <button mat-button (click)="downloadPdf(element)">
                  <mat-icon>cloud_download</mat-icon> {{ 'button.download' | translate }}
                </button>
                <button mat-button (click)="editRow(element.id)">
                  <mat-icon>mode_edit</mat-icon> {{ 'button.edit' | translate }}
                </button>
                <button mat-button (click)="deleteRow(element.id)" *ngIf="canDelete(element)">
                  <mat-icon>delete</mat-icon> {{ 'button.delete' | translate }}
                </button>
                <button mat-button (click)="cloneRow(element.id)" *ngIf="canClone(element)">
                  <mat-icon>folder_copy</mat-icon> {{ 'button.clone' | translate }}
                </button>
              </ng-template>
            </mat-menu>

            <button mat-icon-button [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="element">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </div>

    <div class="table-footer-container">
      <div class="actions">
        <button mat-raised-button (click)="exportTable()" class="stroked-primary">
          <mat-icon fxHide.lt-sm>cloud_download</mat-icon> {{ 'button.downloadXls' | translate }}
        </button>
        <a [routerLink]="['.']" [fragment]="'table-container'" class="back-to-top accent-color">
          {{ 'button.backToTop' | translate }} </a>
      </div>
      <div class="paginator">
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
          [length]="dataSource.totalItems$ | async" lang="es">
        </mat-paginator>
      </div>
    </div>
  </form>
</div>