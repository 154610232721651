import { Observable, of } from 'rxjs';

import { EquipmentAutocompleteFilter } from '../model/autocompleteFilter';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { InternalEquipmentFolder } from '../model/internalEquipmentFolder';
import { OnlineService } from './online.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class InternalEquipmentFolderService {

    constructor(private http: HttpClient,
        private onlineService: OnlineService) {
    }

    getAll(): Observable<any> {

        if (!this.onlineService.latestOnline) {
            return of([]);
        }

        const url = environment.equipmentUrl + '/internalEquipmentFolder/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAll(filter: EquipmentAutocompleteFilter): Observable<any> {
        const url = environment.equipmentUrl + '/internalEquipmentFolder/';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findById(id: number): Observable<any> {
        const url = environment.equipmentUrl + `/internalEquipmentFolder/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    save(folder: InternalEquipmentFolder): Observable<any> {
        const url = environment.equipmentUrl + '/internalEquipmentFolder/create';

        return this.http.post(url, folder).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    update(folder: InternalEquipmentFolder): Observable<any> {
        const url = environment.equipmentUrl + `/internalEquipmentFolder/${folder.id}`;

        return this.http.put(url, folder).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    delete(folder: InternalEquipmentFolder): Observable<any> {
        const url = environment.equipmentUrl + `/internalEquipmentFolder/${folder.id}`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

}
