import { Group } from '../model/group';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SensorData } from '../model/sensorData';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class AttachmentCalibratesService {

    constructor(private http: HttpClient) {
    }

    downloadDisposalIsothermalCharacterization(idIso: number): Observable<any> {
        const url = environment.calibratesUrl + `/attachment/isothermalCharacterization/${idIso}/disposal`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadPhotoIsothermalCharacterization(idIso: number): Observable<any> {
        const url = environment.calibratesUrl + `/attachment/isothermalCharacterization/${idIso}/photo`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    rotateDisposalIsothermalCharacterizationImageLeft(idIso: number): Observable<any> {
        const url = environment.calibratesUrl + `/attachment/isothermalCharacterization/${idIso}/disposal/rotate/left`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    rotateDisposalIsothermalCharacterizationImageRight(idIso: number): Observable<any> {
        const url = environment.calibratesUrl + `/attachment/isothermalCharacterization/${idIso}/disposal/rotate/right`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    rotatePhotoIsothermalCharacterizationImageLeft(idIso: number): Observable<any> {
        const url = environment.calibratesUrl + `/attachment/isothermalCharacterization/${idIso}/photo/rotate/left`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    rotatePhotoIsothermalCharacterizationImageRight(idIso: number): Observable<any> {
        const url = environment.calibratesUrl + `/attachment/isothermalCharacterization/${idIso}/photo/rotate/right`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadPrimaryFileToSensorCharacterization(idSensor: number, file: File): Observable<any> {
        const url = environment.calibratesUrl + `/attachment/sensor/${idSensor}/primary/`;
        const form = new FormData();
        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadExcelToSensorCharacterization(idSensor: number, file: File, data: SensorData[]): Observable<any> {

        data = data.filter(item => item.date != null && !isNaN(item.date.getTime()) && item.value != null);

        const dataCopy = [...data];
        dataCopy.forEach(d => {
            delete d.idVariable;
            delete d.equipmentName;
            delete d.serialNum;
            delete d.idEquipment;
        });

        const url = environment.calibratesUrl + `/attachment/sensor/${idSensor}/excel/`;
        const form = new FormData();
        form.append('file', file, file.name);
        form.append('data', JSON.stringify(dataCopy));

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadCertificateToUser(username: string, file: File): Observable<any> {
        const url = environment.coreUrl + `/attachment/user/${username}/certificate/`;
        const form = new FormData();
        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadCertificateUser(username: string): Observable<any> {
        const url = environment.coreUrl + `/attachment/user/${username}/certificate/`;

        return this.http.get(url, { responseType: 'blob' });
    }

    downloadCertificateCalibration(idEquipment: number): Observable<any> {
        const url = environment.calibratesUrl + `/attachment/calibration/${idEquipment}/certificate/`;

        return this.http.get(url, { responseType: 'blob' });
    }

    downloadCertificateExternalCalibration(idExternalEquipment: number): Observable<any> {
        const url = environment.calibratesUrl + `/attachment/externalCalibration/${idExternalEquipment}/certificate/`;

        return this.http.get(url, { responseType: 'blob' });
    }

    downloadAttachmentToSensorCharacterization(idSensor: number, idAttachment: number): Observable<any> {
        const url = environment.calibratesUrl + `/attachment/sensor/${idSensor}/file/${idAttachment}`;
        return this.http.get(url, { responseType: 'blob' });
    }

    deleteAttachmentToSensorCharacterization(idSensor: number, idAttachment: number): Observable<any> {
        const url = environment.calibratesUrl + `/attachment/sensor/${idSensor}/file/${idAttachment}`;
        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getAttachmentInfoToSensorCharacterization(idSensor: number, idAttachment: number): Observable<any> {
        const url = environment.calibratesUrl + `/attachment/sensor/${idSensor}/info/${idAttachment}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadLogoToGroup(idGroup: number, file: File): Observable<any> {
        const url = environment.coreUrl + `/attachment/group/${idGroup}/logo/`;
        const form = new FormData();

        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadLogoToGroup(group: Group): Observable<any> {
        const url = environment.coreUrl + `/attachment/group/${group.id}/logo/${group.idLogo}`;
        return this.http.get(url, { responseType: 'blob' });
    }

    getAllTypesForProtocol(): Observable<any> {
        const url = environment.coreUrl + '/attachment/types/protocol/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getAllTypesForExecution(): Observable<any> {
        const url = environment.coreUrl + '/attachment/types/execution/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

}
