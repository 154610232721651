<h1 mat-dialog-title>{{ 'calibrateEquipmentEdit.dialog.signReport.title' | translate }}</h1>
<form>

  <div mat-dialog-content class="calibrates-theme" style="overflow-y: hidden;">

    <mat-form-field class="campoFormulario" *ngIf="showChangeLangIcon()">
      <mat-label>{{ 'common.reportType.options.language.label' | translate }}</mat-label>
      <mat-select [(ngModel)]="reportConfig.lang" [ngModelOptions]="{standalone: true}">
        <mat-option *ngFor="let item of langs" [value]="item.lang">
          <mat-icon svgIcon="{{item.flag}}"></mat-icon>
          {{item.fullLang}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <br /><br />

  <div mat-dialog-actions class="calibrates-theme">
    <div fxFlex></div>
    <button mat-raised-button (click)="downloadDocument()" color="accent">
      {{ 'button.download-document-without-watemark' | translate }}
    </button>

    <div fxFlex="5px"></div>

    <button mat-raised-button (click)="uploadDocument.click()"
      color="primary">{{ 'button.upload-signed-document' | translate }}</button>
    <input accept="application/pdf" hidden type="file" (change)="onOkClick($event)" #uploadDocument>

    <div fxFlex="5px"></div>

    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>