import { CriticalInstrumentation } from 'src/app/model/criticalInstrumentation';
import { Component, Inject } from '@angular/core';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from 'src/app/services/snackBar.service';

export interface DialogDataCriticalInstr {
  criticalInstr: CriticalInstrumentation;
  currentCriticalInstrs: CriticalInstrumentation[];
  isEdit: boolean;
}

@Component({
  selector: 'app-protocol-edit-dialog-critical-instr',
  templateUrl: 'protocol-edit-dialog-criticalInstr.html'
})
export class ProtocolEditCriticalInstrDialogComponent {

  form: FormGroup;
  matcher = new MyErrorStateMatcher();

  tituloDialog: string;

  private indexCrit: number;

  constructor(
    fb: FormBuilder,
    private dialogRef: MatDialogRef<ProtocolEditCriticalInstrDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCriticalInstr,
    private translate: TranslateService,
    public snackBarService: SnackBarService) {
    if (data.isEdit) {
      this.tituloDialog = this.translate.instant('protocolEdit.dialog.criticalInstr.title.create',
      { name: data.criticalInstr.equipment }) as string;
    } else {
      this.tituloDialog = this.translate.instant('protocolEdit.dialog.criticalInstr.title.create') as string;
    }

    this.form = fb.group({
      equipment: [this.data.criticalInstr.equipment, [Validators.required]],
      internalId: [this.data.criticalInstr.internalId],
      procediment: [this.data.criticalInstr.procediment, [Validators.required]]
    });

    if (this.data.isEdit && this.data.criticalInstr.internalId != null && this.data.criticalInstr.internalId !== '') {
      this.indexCrit = this.data.currentCriticalInstrs.findIndex(i => i.internalId === this.data.criticalInstr.internalId);
    } else {
      this.indexCrit = null;
    }
  }

  onOkClick(): void {
    let errs = [];

    const crit = this.data.criticalInstr;
    const currs = this.data.currentCriticalInstrs;

    if (crit.equipment == null || crit.equipment === '') {
      errs.push(this.translate.instant('protocolEdit.dialog.criticalInstr.form.error.equipment'));
    }

    if (crit.procediment == null || crit.procediment === '') {
      errs.push(this.translate.instant('protocolEdit.dialog.criticalInstr.form.error.procedure'));
    }

    if (currs != null && currs.length > 0) {
      currs.forEach((curr, index) => {
        if (this.indexCrit != null && this.indexCrit === index) {
          return;
        }

        if (crit.internalId == null || crit.internalId === '') {
          return;
        }

        if (crit.internalId === curr.internalId) {
          errs.push(this.translate.instant('protocolEdit.dialog.criticalInstr.form.error.internalIdExists',
            { internalId: crit.internalId }));
        }
      });
    }

    errs = Array.from(new Set(errs));

    if (this.form.valid && errs.length === 0) {
      this.dialogRef.close(crit);
    } else if (errs.length > 0) {
      this.snackBarService.sendError(errs.join('\n'));
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
