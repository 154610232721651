import { Component, OnDestroy } from '@angular/core';

import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Subject } from 'rxjs';
import { ThemeService } from 'src/app/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-action-confirm-password',
  templateUrl: './action-confirm-password.component.html'
})
export class ActionConfirmPasswordComponent implements OnDestroy {

  password: string;
  hidePassword = true;

  private destroy$ = new Subject<void>();

  constructor(
    private themeService: ThemeService,
    private userService: UserService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ActionConfirmPasswordComponent>,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    this.spinnerService.hide();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onOkClick(): void {
    const errors = [];

    if (this.password == null) {
      errors.push(this.translate.instant('confirmPassword.error.emptyPassword'));
    }

    if (ArrayUtils.isEmpty(errors)) {
      this.spinnerService.show();

      this.userService.checkUser(this.password).pipe(takeUntil(this.destroy$)).subscribe(item => {
        this.spinnerService.hide();
        this.dialogRef.close(item);
      }, () => {
        this.snackBarService.sendError(this.translate.instant('confirmPassword.error.generic') as string);
        this.spinnerService.hide();
      });

    } else {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);
      console.error(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTheme(): string {
    return this.themeService.getTheme();
  }

}
