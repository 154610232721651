import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileInfo } from 'src/app/model/attachment';
import { BioindicatorConfig, BioindicatorConfigBatch, BioindicatorConfigEquipment } from 'src/app/model/bioindicator';
import { GenericClass } from 'src/app/model/genericClass';
import { AttachmentThermalService } from 'src/app/services/attachmentThermal.service';
import { BioindicatorResponsibleService } from 'src/app/services/bioindicatorResponsible.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { FileUtils } from 'src/app/utils/fileUtils';
import { StringUtils } from 'src/app/utils/stringUtils';
import { saveAs } from 'file-saver';

export interface DialogDataBioindicatorEdit {
  idExecution: number;
  bioindicator: BioindicatorConfig;
  type: string;
}

@Component({
  selector: 'app-execution-edit-bioindicators-details',
  templateUrl: './execution-edit-bioindicators-details.component.html'
})
export class ExecutionEditBioindicatorsDetailsComponent implements OnInit, OnDestroy {

  optionSelected: string;
  options: string[] = [];

  hasEquipmentData: boolean;

  bioindicatorResponsibles: GenericClass[];

  private destroy$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<ExecutionEditBioindicatorsDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataBioindicatorEdit,
    private attachmentThermalService: AttachmentThermalService,
    private bioindicatorResponsibleService: BioindicatorResponsibleService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    if (data.type == null) {
      data.type = 'bioindicator';
    }
  }

  ngOnInit(): void {
    this.bioindicatorResponsibleService.findAll().pipe(takeUntil(this.destroy$))
      .subscribe((res: GenericClass[]) => this.bioindicatorResponsibles = res);

    this.options.push('cert');
    this.options.push('batch');
    this.options.push('reason');

    this.optionSelected = this.data.bioindicator.optionSelected || 'cert';

    this.hasEquipmentData = !ArrayUtils.isEmpty(this.data.bioindicator.equipments) && this.data.type === 'bioindicator';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onOptionChange(event: MatRadioChange): void {
    this.optionSelected = event.value as string;

    if (this.optionSelected === 'batch' && this.data.bioindicator.batchs.length === 0) {
      this.newBatch();
    }
  }

  onHasEquipmentData(event): void {
    const value = event.value as boolean;

    this.data.bioindicator.equipments = [];
    if (value) {
      this.data.bioindicator.equipments.push(new BioindicatorConfigEquipment());
    }

    this.hasEquipmentData = value;
  }

  saveDate(field: string, event: MatDatepickerInputEvent<Date>): void {

    if (event.value != null) {
      const dateStr = moment(event.value).format('DD/MM/YYYY');
      const date = moment(dateStr + ' 12:00:00 + 0:00', 'DD/MM/YYYY HH:mm:ss ZZZ').toDate();

      while (date.getFullYear() < 1970) {
        date.setFullYear(date.getFullYear() + 100);
      }

      this.data.bioindicator.equipments[0][field] = date;
    } else {
      this.data.bioindicator.equipments[0][field] = null;
    }

  }

  uploadCertificateToEquipment(event): void {
    const equipment = this.data.bioindicator.equipments[0];
    const file = event.target.files[0] as File;

    if (file == null) {
      return;
    }

    void FileUtils.toBase64(file).then((res: string) => {
      const cert = new FileInfo();
      cert.contentString = res;
      cert.contentType = file.type;
      cert.filename = file.name;
      equipment.certificate = cert;
    });

  }

  showDownloadCertificateEquipment(): boolean {
    const equipment = this.data.bioindicator.equipments[0];

    return equipment.idCertificate != null || equipment.certificate != null;
  }

  downloadCertificateEquipment(): void {
    const equipment = this.data.bioindicator.equipments[0];

    if (equipment.idCertificate != null) {
      this.spinnerService.show();

      this.attachmentThermalService.downloadCertificateBioindicatorEquipment(equipment.idCertificate).pipe(takeUntil(this.destroy$))
        .subscribe((res: Blob) => {
          saveAs(res, 'cert.pdf');
          this.spinnerService.hide();
        }, error => {
          console.error(error);
          this.spinnerService.hide();
        });
    }

    if (equipment.certificate != null) {
      const file = equipment.certificate;

      saveAs(FileUtils.toFile(file.contentString as string, file.filename), file.filename);
    }

  }

  newBatch(): void {
    this.data.bioindicator.batchs.push(new BioindicatorConfigBatch());
  }

  removeBatch(index: number): void {
    this.data.bioindicator.batchs.splice(index, 1);
  }

  uploadCertificateBioindicator(event): void {
    const file = event.target.files[0] as File;

    if (file == null) {
      return;
    }

    void FileUtils.toBase64(file).then((res: string) => {
      const cert = new FileInfo();
      cert.contentString = res;
      cert.contentType = file.type;
      cert.filename = file.name;
      this.data.bioindicator.certificates.push(cert);
    });

  }

  downloadAttachment(file: FileInfo): void {
    this.spinnerService.show();
    this.attachmentThermalService.downloadAttachmentToExecution(this.data.idExecution, file.id).pipe(takeUntil(this.destroy$))
      .subscribe((item: Blob) => {
        saveAs(item, file.filename);

        this.spinnerService.hide();
      }, () => {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.generateReport.form.error.generic') as string);
      });
  }

  downloadAttachmentLocal(file: FileInfo): void {
    saveAs(FileUtils.toFile(file.contentString as string, file.filename), file.filename);
  }

  removeAttachment(index: number): void {
    this.data.bioindicator.certificates.splice(index, 1);
  }

  onOkClick(): void {

    const errores = [];

    if (this.data.bioindicator.responsible == null) {
      errores.push(this.translate.instant(`executionEdit.${this.data.type}s.responsible.validation.error`));
    }

    if (this.optionSelected === 'cert') {
      this.data.bioindicator.batchs = [];
      this.data.bioindicator.reason = null;

      if (this.data.bioindicator.certificates == null || this.data.bioindicator.certificates.length < 1) {
        errores.push(this.translate.instant(`executionEdit.${this.data.type}s.certificate.validation.error`));
      }

    } else if (this.optionSelected === 'batch') {
      this.data.bioindicator.certificates = [];
      this.data.bioindicator.reason = null;

      if (this.data.bioindicator.batchs == null || this.data.bioindicator.batchs.length === 0) {
        errores.push(this.translate.instant(`executionEdit.${this.data.type}s.batch.validation.error.empty`));
      } else {
        this.data.bioindicator.batchs.forEach((batch, batchIndex) => {

          if (StringUtils.isEmpty(batch.batchId)) {
            errores.push(this.translate.instant(`executionEdit.${this.data.type}s.batch.validation.error.batchId`,
              { num: (batchIndex + 1) }));
          }

          if (batch.expiryDate == null) {
            errores.push(this.translate.instant(`executionEdit.${this.data.type}s.batch.validation.error.batchId`,
              { num: (batchIndex + 1) }));
          }

        });
      }

    } else if (this.optionSelected === 'reason') {
      this.data.bioindicator.certificates = [];
      this.data.bioindicator.batchs = [];

      if (StringUtils.isEmpty(this.data.bioindicator.reason)) {
        errores.push(this.translate.instant(`executionEdit.${this.data.type}s.reason.validation.error`));
      }
    }

    if (errores.length === 0) {
      this.data.bioindicator.optionSelected = this.optionSelected;

      this.dialogRef.close(this.data.bioindicator);
    } else if (errores.length !== 0) {
      this.snackBarService.sendError(errores.join('\n'));
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
