import { EssayProtocolAir } from './essayProtocolAir';
import { FilterAir } from './filterAir';
import { Volume } from './volume';

export class RoomAir {
    id: number;

    name: string;
    idType: number;
    idArea: number;
    idOffline: string;

    order: number;

    idSubtype: number;

    numberPeopleRepose: number;
    numberStoppedMachinesRepose: number;
    numberRunningMachinesRepose: number;

    numberPeopleWorking: number;
    numberStoppedMachinesWorking: number;
    numberRunningMachinesWorking: number;

    nameProtocol: string;
    idTypeProtocol: number;

    fromProtocol: boolean;
    reasonChange: string;
    showDiffInDeviations = true;
    showDiffInObservations: boolean;

    volumes: Volume[] = [];

    essays: EssayProtocolAir[] = [];
    filters: FilterAir[] = [];
}

export enum RoomAirEnum {
    ROOM = 1, PASSBOX = 2, SAS = 3
}
