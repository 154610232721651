<div mat-dialog-content [class]="getTheme()">

    <div fxLayout="row" class="flexwrap">
        <div fxFlex>
            <mat-form-field class="campoFormulario">
                <mat-label> {{ 'generic.clone.groupSelected.label' | translate }} </mat-label>
                <mat-select #groupSelect name="groupSelected" [(ngModel)]="groupSelected" required>
                    <mat-option [value]="null"> -- </mat-option>
                    <mat-option *ngFor="let group of groups" [value]="group.id">
                        {{ group.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

</div>

<div mat-dialog-actions align="end" [class]="getTheme()">
    <button mat-raised-button color="primary" (click)="onOkClick()" [disabled]="groupSelected == null">
        {{ 'button.accept' | translate }}
    </button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
</div>