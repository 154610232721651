import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EditUserComponent } from './edit-user.component';
import { EditUserRoleDialogComponent } from './edit-user-dialog-assignRole.component';
import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { SharedModule } from '../../shared/shared.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [EditUserComponent, EditUserRoleDialogComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        /* Custom modules */
        AngularMaterialModule,
        SharedModule,
        PipesModule,
        /* Otros */
        FlexLayoutModule,
        RouterModule
    ],
    exports: [
        EditUserComponent, EditUserRoleDialogComponent
    ]
})
export class EditUserModule { }
