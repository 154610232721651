<div class="main-container">
    <div class="registro-deviacion" fxLayout="column">
        <h1 mat-dialog-title>{{'desviationList.title' | translate}}</h1>
    </div>

    <div class="table-container">
        <table #deviations mat-table matSort [dataSource]="dataSource" >
            
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'desviationList.cols.code' | translate }} </th>
                <td mat-cell *matCellDef="let item"> {{ item.codeDeviation }} </td>
            </ng-container>
            <ng-container matColumnDef="modDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'desviationList.cols.modDate' | translate }} </th>
                <td mat-cell *matCellDef="let item"> {{ item.modDate |fixDateTime }} </td>
            </ng-container>
            <ng-container matColumnDef="modUser">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'desviationList.cols.modUser' | translate }} </th>
                <td mat-cell *matCellDef="let item"> {{ item.modUser }} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" [length]="totalItems" (page)="onPageChange($event)"></mat-paginator>
    </div>

    <div mat-dialog-actions class="air-theme">
        <div fxFlex></div>
        <!--<button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>-->
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.close' | translate }}</button>
    </div>
</div>