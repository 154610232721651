import { InternalEquipmentCustomSelectValue } from './internalEquipmentCustomSelectValue';

export class InternalEquipmentCustomField {
    id: number;

    name: string;
    idType: number;
    order: number;
    value?: string;
    filtrable: boolean;
    valueSelect?: InternalEquipmentCustomSelectValue[];
}
