<h1 mat-dialog-title>{{'protocolEdit.dialog.equipment.title.create' | translate }}</h1>
<form>
  <div mat-dialog-content class="air-theme">

    <div fxLayout="row" class="flexwrap">

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'protocolEdit.dialog.equipment.type.label' | translate }}</mat-label>
          <mat-select required name="idType" [(ngModel)]="data.equipment.idType"
          [disabled]="!enableEditEquipment()">
            <mat-option *ngFor="let item of data.equipmentTypes" [value]="item.id">
              {{ 'equipmentType.' + item.translation | translate }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.type.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.maker.label' | translate }}" name="maker"
            [(ngModel)]="data.equipment.maker" [disabled]="!enableEditEquipment()" required>
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.equipment.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.model.label' | translate }}" name="model"
            [(ngModel)]="data.equipment.model" [disabled]="!enableEditEquipment()" required>
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.equipment.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.serialNum.label' | translate }}"
            name="serialNum" [(ngModel)]="data.equipment.serialNum" [disabled]="!enableEditEquipment()" required>
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.serialNum.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.inventoryNum.label' | translate }}"
            name="location" [(ngModel)]="data.equipment.inventoryNum"[disabled]="!enableEditEquipment()" >
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.inventoryNum.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.equipment.room.label' | translate }}" name="room"
            [(ngModel)]="data.equipment.room" required [disabled]="!enableEditEquipment()">
          <mat-error>
            {{ 'protocolEdit.dialog.equipment.room.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <!-- accessorios-->
      <mat-toolbar color="secondary">
        {{ 'protocolEdit.dialog.equipment.accesories' | translate }}
      </mat-toolbar>

      <div fxFlex="15" *ngFor="let item of accessoryEquipmentTypes; let i = index">
        <mat-checkbox (change)="onAccesoryChange($event)" [value]="item.id" [checked]="isAccesoryChecked(item)"
        [disabled]="!enableEditEquipment()">
          {{'accessoryType.' + item.translation | translate }}
        </mat-checkbox>
      </div>
      <mat-form-field class="campoFormulario" *ngIf="showAccessoryOthers">
        <input matInput placeholder="Otros" name="maker" [(ngModel)]="othersValue" required 
        [disabled]="!enableEditEquipment()">
        <mat-error>
          {{ 'protocolEdit.dialog.equipment.equipment.error' | translate }}
        </mat-error>
      </mat-form-field>

      <br /><br /><br />

      <!-- Filtros -->
      <mat-toolbar color="secondary">
        {{ 'protocolEdit.dialog.room.filters' | translate }}

        <button mat-icon-button color="accent" (click)="openNewFilter()" [disabled]="!enableEditEquipment()">
          <mat-icon>add</mat-icon>
        </button>
      </mat-toolbar>
      <div fxLayout="row" class="flexwrap" style="width: 100%;">
        <div fxFlex>
          <table mat-table matSort #filtersTable [dataSource]="data.equipment.filters">

            <!-- order Column -->
            <ng-container matColumnDef="order">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'protocolEdit.dialog.room.order' | translate }}
              </th>
              <td mat-cell *matCellDef="let item; let index = index"> {{ getOrderFilter(index) }} </td>
            </ng-container>

            <!-- filter Column -->
            <ng-container matColumnDef="filter">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'protocolEdit.dialog.room.filter' | translate }}
              </th>
              <td mat-cell *matCellDef="let item; let index = index"> {{ getFilterName(index) | translate }} </td>
            </ng-container>

            <!-- size Column -->
            <ng-container matColumnDef="size">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'protocolEdit.dialog.room.size' | translate }}
              </th>
              <td mat-cell *matCellDef="let item"> {{ getSizeOfFilter(item) }} </td>
            </ng-container>

            <!-- changeFilter Column -->
            <ng-container matColumnDef="changeFilter">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'protocolEdit.dialog.filter.changeFilter' | translate }}
              </th>
              <td mat-cell *matCellDef="let item"> {{ getChangeFilter(item) }} </td>
            </ng-container>

            <!-- Button edit Column -->
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
              <td mat-cell *matCellDef="let element; let filterIndex = index">
                <button mat-icon-button (click)="editFilter(filterIndex)">
                  <mat-icon>mode_edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <!-- Button delete Column -->
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
              <td mat-cell *matCellDef="let element; let filterIndex = index">
                <button mat-icon-button (click)="removeFilter( filterIndex)"
                [disabled]="!enableEditEquipment()">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColsFilter"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColsFilter;"></tr>

          </table>

        </div>
      </div>

    </div>

  </div>
  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>