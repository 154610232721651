<mat-tab-group (selectedTabChange)="onTabChange($event)">
  <mat-tab label="{{ 'protocolEdit.general.title' | translate }}">

    <form [formGroup]="form">

      <mat-card class="card">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'protocolEdit.general.title' | translate }}
          </mat-toolbar>

          <div fxLayout="row" class="flexwrap">

            <div fxFlex="50" fxFlex.lt-md="100%">
              <div fxLayout="column">

                <div fxLayout="row" class="flexwrap">

                  <div fxFlex="50" fxFlex.lt-md="100%">

                    <div fxLayout="row" class="flexwrap">
                      <div fxFlex="50" fxFlex.lt-md="100%">
                        <div fxLayout="row" class="flexwrap">
                          <div fxFlex>
                            <mat-form-field>
                              <input matInput placeholder="{{ 'protocolEdit.general.documentCode.label' | translate }}"
                                formControlName="documentCode" [(ngModel)]="protocol.documentCode" required
                                [errorStateMatcher]="matcher">
                              <mat-error>
                                {{ 'protocolEdit.general.documentCode.error' | translate }}
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div fxFlex="40px" *ngIf="canEditDocumentCode()">
                            <button mat-icon-button (click)="editDocumentCode()">
                              <mat-icon>mode_edit</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div fxFlex="50" fxFlex.lt-md="100%">
                        <mat-form-field class="campoFormulario">
                          <input matInput
                            placeholder="{{ 'protocolEdit.general.clientDocumentCode.label' | translate }}"
                            formControlName="clientDocumentCode" [(ngModel)]="protocol.clientDocumentCode"
                            [errorStateMatcher]="matcher">
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <mat-label>{{ 'protocolEdit.general.client.label' | translate }}</mat-label>
                      <mat-select #clientSelect required formControlName="idClient" [(ngModel)]="protocol.idClient"
                        [disabled]="+protocol.signedVersion > 0">
                        <mat-select-filter *ngIf="clientSelect.focused" [displayMember]="'name'" [array]="clients"
                          (filteredReturn)="clientsFiltered =$event" [noResultsMessage]="'common.noResults' | translate"
                          [placeholder]="'common.selectFilterPlaceholder' | translate"></mat-select-filter>
                        <mat-option *ngFor="let client of clientsFiltered" [value]="client.id">
                          {{ client.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error>
                        {{ 'protocolEdit.general.client.error' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <mat-label>{{ 'protocolEdit.general.process.label' | translate }}</mat-label>
                      <mat-select required formControlName="idProcess" [(ngModel)]="protocol.idProcess">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let item of processes" [value]="item.id">
                          {{ 'processType.' + item.translation | translate }}
                        </mat-option>
                      </mat-select>
                      <mat-error>
                        {{ 'protocolEdit.general.process.error' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <mat-label>{{ 'protocolEdit.general.phase.label' | translate }}</mat-label>
                      <mat-select required formControlName="phase" [ngModel]="protocol.idPhase"
                        (selectionChange)="onPhaseChange($event)" [disabled]="+protocol.signedVersion > 0">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let item of phases" [value]="item.id">
                          {{ 'phase.' + item.translation | translate }}
                        </mat-option>
                      </mat-select>
                      <mat-error>
                        {{ 'protocolEdit.general.phase.error' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <input matInput placeholder="{{ 'protocolEdit.general.projectNo.label' | translate }}"
                        formControlName="projectNo" [(ngModel)]="protocol.projectNo" required
                        [errorStateMatcher]="matcher">
                      <mat-error>
                        {{ 'protocolEdit.general.projectNo.label' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <mat-label>{{ 'protocolEdit.general.projectManager.label' | translate }}</mat-label>
                      <mat-select formControlName="projectManager" [(ngModel)]="protocol.usernameProjectManager">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let item of respField" [value]="item.username">
                          {{ item.fullName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <input matInput placeholder="{{ 'protocolEdit.general.department.label' | translate }}"
                        formControlName="department" [(ngModel)]="protocol.department" [errorStateMatcher]="matcher">
                    </mat-form-field>
                  </div>

                </div>

              </div>
            </div>

            <div fxFlex="50" fxFlex.lt-md="100%">
              <div fxLayout="column">

                <div fxLayout="row" class="flexwrap">
                  <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <input matInput placeholder="{{ 'protocolEdit.general.regUser.label' | translate }}"
                        formControlName="regUser" [(ngModel)]="protocol.regUser">
                    </mat-form-field>
                  </div>
                  <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <input matInput placeholder="{{ 'protocolEdit.general.modUser.label' | translate }}"
                        formControlName="modUser" [(ngModel)]="protocol.modUser">
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row" class="flexwrap">
                  <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <input matInput placeholder="{{ 'protocolEdit.general.regFc.label' | translate }}"
                        formControlName="regFc" [ngModel]="protocol.regFc | fixDate">
                    </mat-form-field>
                  </div>
                  <div fxFlex="50" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                      <input matInput placeholder="{{ 'protocolEdit.general.modFc.label' | translate }}"
                        formControlName="modFc" [ngModel]="protocol.modFc | fixDate">
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row" class="flexwrap">
                  <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="protocol.statusTranslation != null">
                    <mat-form-field class="campoFormulario">
                      <input matInput placeholder="{{ 'protocolEdit.general.status.label' | translate }}" name="status"
                        value="{{ 'protocolStatus.' + protocol.statusTranslation | translate }}" disabled>
                    </mat-form-field>
                  </div>
                  <div fxFlex="50" fxFlex.lt-md="100%"
                    *ngIf="protocol.currentVersion != '0' && protocol.currentVersion != null">
                    <div fxLayout="row">
                      <div fxFlex="50">
                        <mat-form-field class="campoFormulario">
                          <input matInput placeholder="{{ 'protocolEdit.general.currentVersion.label' | translate }}"
                            formControlName="currentVersion" [ngModel]="protocol.currentVersion">
                        </mat-form-field>
                      </div>
                      <div fxFlex="50">
                        <mat-form-field class="campoFormulario">
                          <input matInput placeholder="{{ 'protocolEdit.general.signedVersion.label' | translate }}"
                            formControlName="signedVersion" [ngModel]="protocol.signedVersion">
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>


        </mat-card-content>
      </mat-card>

      <mat-card class="card">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'protocolEdit.referenceDocs.title' | translate }}

            <button mat-icon-button color="accent" (click)="openNewRefDoc()">
              <mat-icon>add</mat-icon>
            </button>
          </mat-toolbar>

          <br />

          <div fxLayout="row">

            <div fxFlex fxFlex.lt-md="100%">

              <div fxLayout="row" class="flexwrap">
                <div fxFlex></div>

                <div fxFlex="50px"></div>

              </div>

              <table mat-table matSort #refDocTable [dataSource]="protocol.referenceDocs" style="width: 95%;">

                <!-- code Column -->
                <ng-container matColumnDef="code">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'protocolEdit.referenceDocs.code.label' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let item"> {{ item.code }} </td>
                </ng-container>

                <!-- name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'protocolEdit.referenceDocs.name.label' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let item" style="white-space: pre;"> {{ item.name }} </td>
                </ng-container>

                <!-- Button edit Column -->
                <ng-container matColumnDef="edit">
                  <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
                  <td mat-cell *matCellDef="let element; let refDocIndex = index">
                    <button mat-icon-button (click)="editRefDoc(refDocIndex)">
                      <mat-icon>mode_edit</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <!-- Button delete Column -->
                <ng-container matColumnDef="delete">
                  <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
                  <td mat-cell *matCellDef="let element; let refDocIndex = index">
                    <button mat-icon-button (click)="removeRefDoc(refDocIndex)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColsRefDoc"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColsRefDoc;"></tr>

              </table>

            </div>

          </div>

        </mat-card-content>

      </mat-card>

      <mat-card class="card">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'protocolEdit.equipment.title' | translate }}

            <button mat-icon-button color="accent" (click)="openDialogEquipment()" *ngIf="hasEquip()"
              [disabled]="disableOfflineButton">
              <mat-icon>add</mat-icon>
            </button>
          </mat-toolbar>

          <br />

          <table #equipmentTable mat-table [dataSource]="equipmentList" style="min-width: 95%">

            <!-- type Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.equipment.type.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ 'equipmentType.' + getEquipmentType(element) | translate }}
              </td>
            </ng-container>

            <!-- equipment Column -->
            <ng-container matColumnDef="equipment">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.equipment.equipment.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ element.equipment }} </td>
            </ng-container>

            <!-- maker Column -->
            <ng-container matColumnDef="maker">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.equipment.maker.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ element.maker }} </td>
            </ng-container>

            <!-- model Column -->
            <ng-container matColumnDef="model">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.equipment.model.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ element.model }} </td>
            </ng-container>

            <!-- serialNum Column -->
            <ng-container matColumnDef="serialNum">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.equipment.serialNo.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ element.serialNum }} </td>
            </ng-container>

            <!-- internalId Column -->
            <ng-container matColumnDef="internalId">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.equipment.internalId.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ element.internalId }} </td>
            </ng-container>

            <!-- location Column -->
            <ng-container matColumnDef="location">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.equipment.location.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ element.location }} </td>
            </ng-container>

            <!-- Button edit Column -->
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element; let i = index">
                <div matTooltip="{{ 'button.disabled.offline' | translate }}"
                  [matTooltipDisabled]="!disableOfflineButton">
                  <button mat-icon-button (click)="openDialogEditEquipment(i)" [disabled]="disableOfflineButton">
                    <mat-icon>mode_edit</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>

            <!-- Button delete Column -->
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element; let i = index">
                <div matTooltip="{{ 'button.disabled.offline' | translate }}"
                  [matTooltipDisabled]="!disableOfflineButton">
                  <button mat-icon-button (click)="deleteEquipmentRow(i)" [disabled]="disableOfflineButton">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="equipmentCols"></tr>
            <tr mat-row *matRowDef="let row; columns: equipmentCols;"></tr>
          </table>

        </mat-card-content>

      </mat-card>

      <mat-card class="card">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'protocolEdit.criticalInstr.title' | translate }}
          </mat-toolbar>

          <mat-slide-toggle class="example-margin" color="primary" [checked]="calibrateCriticalInstrumentation"
            (change)="onCriticalInstrumentationChange($event)">
            {{ 'protocolEdit.criticalInstr.calibrate.label' | translate }}

            <button mat-icon-button color="accent" (click)="openDialogCriticalInstrumentation()"
              *ngIf="calibrateCriticalInstrumentation" [disabled]="disableOfflineButton">
              <mat-icon>add</mat-icon>
            </button>
          </mat-slide-toggle>

          <div *ngIf="calibrateCriticalInstrumentation">

            <table #criticalInstrTable mat-table [dataSource]="criticalInstr" style="min-width: 95%">

              <!-- equipment Column -->
              <ng-container matColumnDef="equipment">
                <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.criticalInstr.equipment.label' | translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.equipment }} </td>
              </ng-container>

              <!-- internalId Column -->
              <ng-container matColumnDef="internalId">
                <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.criticalInstr.internalId.label' | translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.internalId }} </td>
              </ng-container>

              <!-- procediment Column -->
              <ng-container matColumnDef="procediment">
                <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.criticalInstr.procediment.label' | translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.procediment }} </td>
              </ng-container>

              <!-- Button delete Column -->
              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <div matTooltip="{{ 'button.disabled.offline' | translate }}"
                    [matTooltipDisabled]="!disableOfflineButton">
                    <button mat-icon-button (click)="openDialogEditCriticalInstrumentation(i)"
                      [disabled]="disableOfflineButton">
                      <mat-icon>mode_edit</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <!-- Button delete Column -->
              <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <div matTooltip="{{ 'button.disabled.offline' | translate }}"
                    [matTooltipDisabled]="!disableOfflineButton">
                    <button mat-icon-button (click)="deleteCriticalInstrumentationRow(i)"
                      [disabled]="disableOfflineButton">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="criticalInstrCols"></tr>
              <tr mat-row *matRowDef="let row; columns: criticalInstrCols;"></tr>
            </table>

            <br />
          </div>


        </mat-card-content>
      </mat-card>

      <mat-card class="card">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'protocolEdit.instrumentationUse.title' | translate }}
          </mat-toolbar>

          <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="protocol.idInstrumentation" name="idInstrumentation" formControlName="idInstrumentation">
            <mat-radio-button class="example-radio-button" *ngFor="let item of instrumentationUse" [value]="item.id"
              (change)="onInstrumentationChange($event)">
              {{ 'instrumentation.' + item.translation | translate }}
            </mat-radio-button>
            <div class="error" *ngIf="protocol.idInstrumentation == null">
              {{ 'protocolEdit.instrumentationUse.error' | translate }}
            </div>
          </mat-radio-group>

          <table style="width:100%;" class="tablaConBordes" *ngIf="showValidatorSpecifics">
            <tr>
              <th>{{ 'protocolEdit.instrumentationUse.validator.calibrationPoint' | translate }}</th>
              <th>{{ 'protocolEdit.instrumentationUse.validator.valueTemp' | translate }}</th>
              <th>{{ 'protocolEdit.instrumentationUse.validator.maxDeviation' | translate }}</th>
              <th>{{ 'protocolEdit.instrumentationUse.validator.minStability' | translate }}</th>
            </tr>
            <tr>
              <td>{{ 'protocolEdit.instrumentationUse.validator.lowTemp' | translate }}</td>
              <td>
                <mat-form-field class="campoFormulario" style="width: 3em">
                  <input matInput [(ngModel)]="protocol.validator.lowTemp" formControlName="lowTemp" required
                    [errorStateMatcher]="matcher" size="3">
                </mat-form-field>
              </td>
              <td>
                ≤
                <mat-form-field class="campoFormulario" style="width: 3em">
                  <input matInput [(ngModel)]="protocol.validator.lowDesv" formControlName="lowDesv" required
                    [errorStateMatcher]="matcher" size="3">
                </mat-form-field>
                {{ 'protocolEdit.instrumentationUse.validator.aboutRefTemp' | translate }}
              </td>
              <td>{{ 'protocolEdit.instrumentationUse.validator.notApplicable' | translate }}</td>
            </tr>
            <tr>
              <td>{{ 'protocolEdit.instrumentationUse.validator.highTemp' | translate }}</td>
              <td>
                <mat-form-field class="campoFormulario" style="width: 3em">
                  <input matInput [(ngModel)]="protocol.validator.highTemp" formControlName="highTemp" required
                    [errorStateMatcher]="matcher" size="3">
                </mat-form-field>
              </td>
              <td>
                ≤
                <mat-form-field class="campoFormulario" style="width: 3em">
                  <input matInput [(ngModel)]="protocol.validator.highDesv" formControlName="highDesv" required
                    [errorStateMatcher]="matcher" size="3">
                </mat-form-field>
                {{ 'protocolEdit.instrumentationUse.validator.aboutRefTemp' | translate }}
              </td>
              <td>{{ 'protocolEdit.instrumentationUse.validator.notApplicable' | translate }}</td>
            </tr>
            <tr>
              <td>{{ 'protocolEdit.instrumentationUse.validator.verifTemp' | translate }}</td>
              <td>
                <mat-form-field class="campoFormulario" style="width: 3em">
                  <input matInput [(ngModel)]="protocol.validator.verifTemp" formControlName="verifTemp" required
                    [errorStateMatcher]="matcher" size="3">
                </mat-form-field>
              </td>
              <td>
                ±
                <mat-form-field class="campoFormulario" style="width: 3em">
                  <input matInput [(ngModel)]="protocol.validator.verifDesv" formControlName="verifDesv" required
                    [errorStateMatcher]="matcher" size="3">
                </mat-form-field>
                {{ 'protocolEdit.instrumentationUse.validator.aboutRefTemp' | translate }}
              </td>

              <td>
                <mat-form-field class="campoFormulario" style="width: 3em">
                  <input matInput [(ngModel)]="protocol.validator.minStability" formControlName="minStability" required
                    [errorStateMatcher]="matcher" size="3">
                </mat-form-field>
                {{ 'protocolEdit.instrumentationUse.validator.tempIn' | translate }}
                <mat-form-field class="campoFormulario" style="width: 3em">
                  <input matInput [(ngModel)]="protocol.validator.minStabilityTime" formControlName="minStabilityTime"
                    required [errorStateMatcher]="matcher" size="3">
                </mat-form-field>
                {{ 'protocolEdit.instrumentationUse.validator.minutes' | translate }}
              </td>
            </tr>
          </table>

        </mat-card-content>
      </mat-card>

      <mat-card class="card">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'protocolEdit.essays.title.generic' | translate }}

            <button mat-icon-button color="accent" [disabled]="disableEssayButtonDialog || disableOfflineButton"
              (click)="newEssayOpenDialog()">
              <mat-icon>add</mat-icon>
            </button>
          </mat-toolbar>

          <table #essaysTable mat-table [dataSource]="essays" style="min-width: 95%">

            <!-- Type Column -->
            <ng-container matColumnDef="essayType">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.essays.essay.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ 'essayType.' + element.translationType | translate }} </td>
            </ng-container>

            <!-- Variables Column -->
            <ng-container matColumnDef="variables">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.essays.variables.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ getVariablesFromEssay(element) }} </td>
            </ng-container>

            <!-- Criteria Column -->
            <ng-container matColumnDef="criteria">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.essays.criteria.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ getCriteriaFromEssay(element) }} </td>
            </ng-container>

            <!-- Load Column -->
            <ng-container matColumnDef="load">
              <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.essays.load.label' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{ getLoadFromEssay(element) }} </td>
            </ng-container>

            <!-- Button see Column -->
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element; let i = index">
                <button mat-icon-button (click)="editEssayOpenDialog(i)">
                  <mat-icon>mode_edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <!-- Button delete Column -->
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element; let i = index">
                <div matTooltip="{{ 'button.disabled.offline' | translate }}"
                  [matTooltipDisabled]="!disableOfflineButton">
                  <button mat-icon-button (click)="deleteEssayRow(i)" [disabled]="disableOfflineButton">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="essaysCols"></tr>
            <tr mat-row *matRowDef="let row; columns: essaysCols;"></tr>
          </table>

        </mat-card-content>
      </mat-card>

      <app-protocol-edit-attachments #attachmentsComponent [disableOfflineButton]="disableOfflineButton">
      </app-protocol-edit-attachments>

      <mat-card class="card">
        <mat-card-content>
          <mat-toolbar color="secondary">
            {{ 'protocolEdit.observations.title' | translate }}
          </mat-toolbar>

          <div fxLayout="column">
            <div fxFlex="100%">
              <mat-form-field class="campoFormulario" style="width: 100%">
                <textarea rows="5" matAutosizeMinRows="5" matInput
                  placeholder="{{ 'protocolEdit.observations.label' | translate }}" [(ngModel)]="protocol.observations"
                  formControlName="observations" [errorStateMatcher]="matcher" style="resize: none;">
                    </textarea>
              </mat-form-field>
            </div>
          </div>

        </mat-card-content>
      </mat-card>

      <mat-card class="card">
        <mat-card-actions>
          <div fxFlex></div>
          <button mat-raised-button color="primary" *ngIf="showRevertSign()" (click)="revertSign()">
            {{ 'button.revertSign' | translate }} </button>
          <div matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton">
            <button mat-raised-button color="primary" (click)="saveProtocol()" [disabled]="disableOfflineButton">{{
              'button.save' | translate }}</button>
          </div>
          <div fxFlex="5px"></div>
          <div matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton">
            <button mat-raised-button color="primary" *ngIf="showManualSign()" (click)="manualSign()"
              [disabled]="disableOfflineButton">{{ 'button.sign-manual' | translate }}</button>
          </div>
          <div fxFlex="5px"></div>
          <div matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton">
            <button mat-raised-button color="primary" *ngIf="showAutomaticSign()" (click)="automaticSign()"
              [disabled]="disableOfflineButton">{{ 'button.sign-automatic' | translate }}</button>
          </div>
          <div fxFlex="5px"></div>
          <div matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton">
            <button mat-raised-button color="accent" *ngIf="protocol.documentCode != null" (click)="downloadPdf()"
              [disabled]="disableOfflineButton">{{ 'button.generateDoc' | translate }}</button>
          </div>
          <div fxFlex="5px"></div>
          <button mat-raised-button color="secondary" (click)="cancel()">{{ 'button.cancel' | translate }}</button>
        </mat-card-actions>
      </mat-card>

    </form>

  </mat-tab>
  <mat-tab label="{{ 'protocolEdit.historical.title' | translate }}" *ngIf="protocol.id != null">
    <app-protocol-edit-audit [idProtocol]="protocol.id" [documentCode]="protocol.documentCode">
    </app-protocol-edit-audit>
  </mat-tab>
  <mat-tab *ngIf="protocol.id != null">
    <ng-template mat-tab-label>
      {{ 'protocolEdit.executions.title' | translate }}
      <button mat-icon-button (click)="createNewExecution()" *ngIf="canCreateNewExecution()">
        <mat-icon>add</mat-icon>
      </button>
    </ng-template>
    <app-execution-list [isProtocolDetail]="true" [idProtocolDetail]="protocol.id"></app-execution-list>
  </mat-tab>
</mat-tab-group>