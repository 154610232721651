import { Group, GroupFilter } from '../model/group';

import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoleUserFilter } from '../model/roleUser';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ManageGroupsService {

    constructor(private http: HttpClient) { }

    findAll(filter: GroupFilter): Observable<any> {
        const url = environment.coreUrl + '/manageGroups/';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllToClone(): Observable<any> {
        const url = environment.coreUrl + '/manageGroups/toClone/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOne(id: number): Observable<any> {
        const url = environment.coreUrl + `/manageGroups/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findCurrent(): Observable<any> {
        const url = environment.coreUrl + '/manageGroups/current';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findUsersFromGroup(filter: RoleUserFilter): Observable<any> {
        const url = environment.coreUrl + `/manageGroups/${filter.idGroup}/users`;

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    update(group: Group): Observable<any> {
        const url = environment.coreUrl + `/manageGroups/${group.id}`;

        return this.http.put(url, group).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findInvoicesFromGroup(id: number): Observable<any> {
        const url = environment.coreUrl + `/manageGroups/${id}/invoices`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findInvoicesFromMyGroup(): Observable<any> {
        const url = environment.coreUrl + '/manageGroups/invoices';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findSubscriptionsFromGroup(id: number): Observable<any> {
        const url = environment.coreUrl + `/manageGroups/${id}/subscriptions`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findSubscriptionsFromMyGroup(): Observable<any> {
        const url = environment.coreUrl + '/manageGroups/subscriptions';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findPaymentsFromGroup(id: number): Observable<any> {
        const url = environment.coreUrl + `/manageGroups/${id}/payments`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findPaymentsFromMyGroup(): Observable<any> {
        const url = environment.coreUrl + '/manageGroups/payments';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadAuditPdf(id: number): Observable<any> {
        const url = environment.thermalUrl + `/report/group/${id}/audit`;

        return this.http.get(url, { responseType: 'blob' });
    }
}
