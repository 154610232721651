import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppType } from 'src/app/model/appType';
import { GenericClass, GenericClassTranslate } from 'src/app/model/genericClass';
import { RoleUser } from 'src/app/model/roleUser';
import { AppTypeService } from 'src/app/services/appType.service';
import { ManageUsersService } from 'src/app/services/manageUsers.service';
import { ThemeService } from 'src/app/services/theme.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';

export interface DialogDataRoleUser {
  roleUser: RoleUser;
  isEdit: boolean;
  currentRoles: RoleUser[];
}

@Component({
  selector: 'app-edit-user-dialog-assign-role',
  templateUrl: 'edit-user-dialog-assignRole.component.html'
})
export class EditUserRoleDialogComponent implements OnInit, OnDestroy {

  matcher = new MyErrorStateMatcher();

  form: FormGroup;

  groups: GenericClass[];
  roles: GenericClassTranslate[];
  appTypes: AppType[];

  public checked: Map<AppType, boolean> = new Map<AppType, boolean>();

  private destroy$ = new Subject<void>();

  constructor(
    fb: FormBuilder,
    private dialogRef: MatDialogRef<EditUserRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataRoleUser,
    private manageUsersService: ManageUsersService,
    private appTypeService: AppTypeService,
    private themeService: ThemeService) {

    this.form = fb.group({
      idGroup: [this.data.roleUser.idGroup, [Validators.required]],
      idRole: [this.data.roleUser.idRole, [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.groups = [];
    this.manageUsersService.getAssignableGroups().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.groups = data);

    this.roles = [];
    this.manageUsersService.getAssignableRoles().pipe(takeUntil(this.destroy$))
      .subscribe((data: GenericClassTranslate[]) => this.roles = data);

    this.appTypes = [];
    this.appTypeService.findAllManage().pipe(takeUntil(this.destroy$)).subscribe((data: AppType[]) => this.appTypes = data);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChangeAppTypeCheck(event: MatCheckboxChange): void {
    const appType = event.source.value as unknown as AppType;

    this.checked.set(appType, event.checked);
  }

  onOkClick(): void {
    const appTypeSelected: AppType[] = [];

    this.checked.forEach((value, key) => {
      if (value) {
        appTypeSelected.push(key);
      }
    });

    if (this.form.valid && !ArrayUtils.isEmpty(appTypeSelected)) {
      this.data.roleUser.roleName = this.roles.find(item => item.id === this.data.roleUser.idRole).name;
      this.data.roleUser.roleTranslation = this.roles.find(item => item.id === this.data.roleUser.idRole).translation;
      this.data.roleUser.groupName = this.groups.find(item => item.id === this.data.roleUser.idGroup).name;

      const results = [];

      appTypeSelected.forEach(r => {
        const role = _.cloneDeep(this.data.roleUser);

        role.idApp = r.id;
        role.appName = r.name;

        results.push(role);
      });

      this.dialogRef.close(results);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTheme(): string {
    return this.themeService.getTheme();
  }

}
