import { Equipment } from '../model/equipment';
import { StringUtils } from './stringUtils';

export class EquipmentUtils {

    private static space = ' ';

    static showEquipments(eqs: Equipment[]): string {
        const res = eqs.map(item => this.showEquipment(item));

        return res.join('\n');
    }

    static showEquipmentsShort(eqs: Equipment[]): string {
        const res = eqs.map(item => this.showEquipmentShort(item));

        return res.join('\n');
    }

    static showEquipmentShort(equipment: Equipment): string {
        let res = '';

        if (equipment == null) {
            return res;
        }

        if (StringUtils.isNotEmpty(equipment.equipment)) {
            res += equipment.equipment;
            res += this.space;
        }

        if (StringUtils.isNotEmpty(equipment.maker)) {
            res += equipment.maker;
            res += this.space;
        }

        if (StringUtils.isNotEmpty(equipment.model)) {
            res += equipment.model;
            res += this.space;
        }

        return res;
    }

    static showEquipment(equipment: Equipment): string {
        let res = this.showEquipmentShort(equipment);

        res += this.space;

        res += this.showEquipmentExtra(equipment);

        return res;
    }

    static showEquipmentExtra(equipment: Equipment): string {
        let res = '';

        if (equipment == null) {
            return res;
        }

        if (StringUtils.isNotEmpty(equipment.serialNum)) {
            res += '(S/N: ';
            res += equipment.serialNum;
            res += ')';
            res += this.space;
        }

        if (StringUtils.isNotEmpty(equipment.internalId)) {
            res += '(Id. ';
            res += equipment.internalId;
            res += ')';
        }

        return res;
    }
}
