import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExecutionQTestDeviationData, ExecutionQTestDeviations } from 'src/app/model/execution';
import { UserService } from '../../../../services/user.service';
import { User } from '../../../../model/user';
import { TestTypeDeviations } from '../../../../model/execution';
import { SnackBarService } from '../../../../services/snackBar.service';
import { StringUtils } from '../../../../utils/stringUtils';

export interface DialogDataDeviation {
  protocolNumber: number;
  idDataMultiple: number;
  deviation: ExecutionQTestDeviations;
}
@Component({
  selector: 'app-execution-edit-q-deviation',
  templateUrl: './execution-edit-q-deviation.component.html',
  styleUrls: ['./execution-edit-q-deviation.component.scss']
})
export class ExecutionEditQDeviationComponent implements OnInit {

  commentsDeviation: ExecutionQTestDeviationData = new ExecutionQTestDeviationData();
  evaluation: ExecutionQTestDeviationData = new ExecutionQTestDeviationData();
  proposedCorrective: ExecutionQTestDeviationData = new ExecutionQTestDeviationData();
  actionsTaken: ExecutionQTestDeviationData = new ExecutionQTestDeviationData();
  deviationResolution: ExecutionQTestDeviationData = new ExecutionQTestDeviationData();
  closedDeviation: ExecutionQTestDeviationData = new ExecutionQTestDeviationData();


  currentUser: User;
  constructor(private dialogRef: MatDialogRef<ExecutionEditQDeviationComponent>,
    private userService: UserService,
    private snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataDeviation) { }

  ngOnInit(): void {
    this.currentUser = this.userService.currentProfile;

    this.data.deviation?.executionQTestDeviationData?.forEach(eqtdd => {
      if (eqtdd.idType === TestTypeDeviations.COMMENTS) {
        this.commentsDeviation = eqtdd;
      }

      if (eqtdd.idType === TestTypeDeviations.VALUATION) {
        this.evaluation = eqtdd;
      }

      if (eqtdd.idType === TestTypeDeviations.PROPOSED_CORRECTIVE) {
        this.proposedCorrective = eqtdd;
      }

      if (eqtdd.idType === TestTypeDeviations.ACTIONS_TAKEN) {
        this.actionsTaken = eqtdd;
      }

      if (eqtdd.idType === TestTypeDeviations.DEVIATION_RESOLUTION) {
        this.deviationResolution = eqtdd;
      }

      if (eqtdd.idType === TestTypeDeviations.DEVIATION_CLOSED) {
        this.deviationResolution = eqtdd;
      }
    })
  }

  onOkClick() {

    const errores = this.getErroresDeviation();

    if (errores.length !== 0) {
      this.snackBarService.sendError(errores.join('\n'));
    } else {
      if (!this.data.deviation) {
        this.data.deviation = new ExecutionQTestDeviations();
      }

      if (!this.data.deviation.executionQTestDeviationData) {
        this.data.deviation.executionQTestDeviationData = []
      }

      if (this.commentsDeviation) {
        let res = this.data.deviation.executionQTestDeviationData.findIndex(eqtdd => eqtdd.idType === TestTypeDeviations.COMMENTS);

        if (res === -1) {
          this.commentsDeviation.idType = TestTypeDeviations.COMMENTS;
          if (!this.commentsDeviation.regFc) {
            this.commentsDeviation.regFc = new Date();
            this.commentsDeviation.regUser = this.currentUser.username;
          }

          this.commentsDeviation.modFc = new Date();
          this.commentsDeviation.modUser = this.currentUser.username;

          this.data.deviation.executionQTestDeviationData.push(this.commentsDeviation);
        } else {
          this.data.deviation.executionQTestDeviationData[res].comments = this.commentsDeviation.comments;
          this.data.deviation.executionQTestDeviationData[res].modFc = new Date();
          this.data.deviation.executionQTestDeviationData[res].modUser = this.currentUser.username;
        }
      }

      if (this.deviationResolution) {
        let res = this.data.deviation.executionQTestDeviationData.findIndex(eqtdd => eqtdd.idType === TestTypeDeviations.DEVIATION_RESOLUTION);

        if (res === -1) {
          this.deviationResolution.idType = TestTypeDeviations.DEVIATION_RESOLUTION;
          if (!this.deviationResolution.regFc) {
            this.deviationResolution.regFc = new Date();
            this.deviationResolution.regUser = this.currentUser.username;
          }

          this.deviationResolution.modFc = new Date();
          this.deviationResolution.modUser = this.currentUser.username;

          this.data.deviation.executionQTestDeviationData.push(this.deviationResolution);
        } else {
          this.data.deviation.executionQTestDeviationData[res].comments = this.deviationResolution.comments;
          this.data.deviation.executionQTestDeviationData[res].modFc = new Date();
          this.data.deviation.executionQTestDeviationData[res].modUser = this.currentUser.username;
          this.data.deviation.executionQTestDeviationData[res].resolved = this.deviationResolution.resolved;
        }
      }


      if (this.actionsTaken) {
        let res = this.data.deviation.executionQTestDeviationData.findIndex(eqtdd => eqtdd.idType === TestTypeDeviations.ACTIONS_TAKEN);

        if (!this.actionsTaken.comments) {
          this.actionsTaken.comments = 'N/A'
        }

        if (res === -1) {
          this.actionsTaken.idType = TestTypeDeviations.ACTIONS_TAKEN;
          if (!this.actionsTaken.regFc) {
            this.actionsTaken.regFc = new Date();
            this.actionsTaken.regUser = this.currentUser.username;
          }

          this.actionsTaken.modFc = new Date();
          this.actionsTaken.modUser = this.currentUser.username;
          this.data.deviation.executionQTestDeviationData.push(this.actionsTaken);
        } else {
          this.data.deviation.executionQTestDeviationData[res].comments = this.actionsTaken.comments;
          this.data.deviation.executionQTestDeviationData[res].modFc = new Date();
          this.data.deviation.executionQTestDeviationData[res].modUser = this.currentUser.username;
        }
      }

      if (this.proposedCorrective) {
        let res = this.data.deviation.executionQTestDeviationData.findIndex(eqtdd => eqtdd.idType === TestTypeDeviations.PROPOSED_CORRECTIVE);

        if (!this.proposedCorrective.comments) {
          this.proposedCorrective.comments = 'N/A'
        }

        if (res === -1) {
          this.proposedCorrective.idType = TestTypeDeviations.PROPOSED_CORRECTIVE;
          if (!this.proposedCorrective.regFc) {
            this.proposedCorrective.regFc = new Date();
            this.proposedCorrective.regUser = this.currentUser.username;
          }

          this.proposedCorrective.modFc = new Date();
          this.proposedCorrective.modUser = this.currentUser.username;

          this.data.deviation.executionQTestDeviationData.push(this.proposedCorrective);

        } else {
          this.data.deviation.executionQTestDeviationData[res].comments = this.proposedCorrective.comments;
          this.data.deviation.executionQTestDeviationData[res].modFc = new Date();
          this.data.deviation.executionQTestDeviationData[res].modUser = this.currentUser.username;
        }
      }

      if (this.evaluation) {
        let res = this.data.deviation.executionQTestDeviationData.findIndex(eqtdd => eqtdd.idType === TestTypeDeviations.VALUATION);

        if (res === -1) {
          this.evaluation.idType = TestTypeDeviations.VALUATION;
          if (!this.evaluation.regFc) {
            this.evaluation.regFc = new Date();
            this.evaluation.regUser = this.currentUser.username;
          }

          this.evaluation.modFc = new Date();
          this.evaluation.modUser = this.currentUser.username;

          this.data.deviation.executionQTestDeviationData.push(this.evaluation);
        } else {
          this.data.deviation.executionQTestDeviationData[res].qualificationContinue = this.evaluation.qualificationContinue;
          this.data.deviation.executionQTestDeviationData[res].valuation = this.evaluation.valuation;
          this.data.deviation.executionQTestDeviationData[res].comments = this.evaluation.comments;
          this.data.deviation.executionQTestDeviationData[res].modFc = new Date();
          this.data.deviation.executionQTestDeviationData[res].modUser = this.currentUser.username;
        }
      }
      this.dialogRef.close(this.data.deviation);
    }



  }

  onNoClick() {
    this.dialogRef.close(null);
  }

  getErroresDeviation(): string[] {
    let errors = [];

    if (StringUtils.isEmpty(this.commentsDeviation?.comments)) {
      errors.push('Debe ingresar la descripción de la desviación')
    }

    if (this.evaluation?.valuation === null || this.evaluation?.valuation === undefined) {
      errors.push('Debe seleccionar una valoración')
    }

    if (this.evaluation?.qualificationContinue === null || this.evaluation?.qualificationContinue === undefined) {
      errors.push('Debe indicar si prosigue o no la cualificación')
    }

    if (StringUtils.isEmpty(this.evaluation?.comments)) {
      errors.push('Debe ingresar la evaluación de la desviación')
    }

    if (this.deviationResolution?.resolved) {
      if (StringUtils.isEmpty(this.deviationResolution.comments)) {
        errors.push('La desviación esta marcada como resulta, por favor ingrese los comentarios de su resolución')
      }
    } else {
      if (StringUtils.isEmpty(this.deviationResolution.comments)) {
        this.deviationResolution.comments = 'N/A'
      }
    }

    return errors;
  }

}
