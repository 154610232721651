<form>
    <h1 mat-dialog-title class="air-theme">

        <mat-checkbox matTooltip="{{ 'button.enable' | translate }}" [checked]="data.essay.active"
            (change)="data.essay.active = $event.checked" *ngIf="!data.isNew && data.essay.fromProtocol">
        </mat-checkbox>

        {{ getEssayName(data.essay.idEssayType) | translate }}

    </h1>
    <div mat-dialog-content class="air-theme">

        <div *ngIf="!data.essay.active">
            <mat-toolbar color="secondary">
                {{ 'executionEdit.sensorConfig.evaluateReason.label' | translate }}
            </mat-toolbar>

            <textarea rows="5" matAutosizeMinRows="5" matInput name="reasonActive" required style="resize: none;"
                placeholder="{{ 'executionEdit.sensorConfig.evaluateReason.label' | translate }}"
                [(ngModel)]="data.essay.reasonActive"></textarea>

            <div fxLayout="column" class="flexwrap">
                <div fxFlex>
                    <mat-checkbox name="showDiffInDeviations" [(ngModel)]="data.essay.showDiffInDeviations">
                        {{ 'executionEdit.sensorConfig.showDiffInDeviations.label' | translate }}
                    </mat-checkbox>
                </div>
                <div fxFlex>
                    <mat-checkbox name="showDiffInObservations" [(ngModel)]="data.essay.showDiffInObservations">
                        {{ 'executionEdit.sensorConfig.showDiffInObservations.label' | translate }}
                    </mat-checkbox>
                </div>
            </div>

        </div>

        <div fxLayout="row" class="flexwrap" *ngIf="data.essay.active">

            <div fxFlex="8em">
                <mat-checkbox (change)="setForceAccording($event)" [checked]="data.essay.forceAccording"
                    style="padding-top: 1em;" [disabled]="!enableEditEssay()">
                    {{ 'executionEdit.dialog.essay.forceAccording' | translate }}
                </mat-checkbox>
            </div>

            <div fxFlex="100%" *ngIf="units?.length !== 0">
                <mat-form-field class="campoFormulario">
                    <mat-label>{{ 'calibrateEquipmentEdit.calibrationData.unit.label' | translate }}</mat-label>
                    <mat-select name="unit" [(ngModel)]="data.essay.idUnit" required [disabled]="units.length < 2">
                        <mat-option *ngFor="let item of units" [value]="item.id">
                            {{item.unit}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{ 'calibrateEquipmentEdit.calibrationData.unit.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-toolbar color="secondary">
                {{ 'protocolEdit.essays.criteria.label' | translate }}
                <button mat-icon-button color="accent" (click)="openNewCriteria()" *ngIf="!showMicrobiological"
                    matTooltip="{{ 'executionEdit.dialog.essay.add.criteria' | translate }}"
                    [disabled]="!enableEditCriteria()">
                    <mat-icon class="icon-display-plus">add</mat-icon>
                </button>
            </mat-toolbar>

            <!-- Smoke Electric Paralelism-->
            <div fxLayout="row" class="flexwrap" *ngIf="showAccording">
                <div fxFlex="40" fxFlex.lt-md="100%">
                    <span> {{ 'executionEdit.dialog.essay.result' | translate }} </span>
                    <mat-radio-group [(ngModel)]="data.essay.according" name="essayAccording">
                        <div fxLayout="row" fxFlex="100%">
                            <mat-radio-button [value]="true" [disabled]="!enableEditEssay()">
                                {{'executionEdit.dialog.essay.according' | translate}}
                            </mat-radio-button>
                            <mat-radio-button [value]="false" [disabled]="!enableEditEssay()">
                                {{'executionEdit.dialog.essay.notAccording' | translate}}
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </div>

            <!-- Saturation -->
            <div fxLayout="row" class="flexwrap" *ngIf="showSaturation">
                <div fxLayout="row" fxFlex="100%">
                    <div fxFlex="40" fxFlex.lt-md="100%">
                        <span> {{ 'executionEdit.dialog.essay.result' | translate }} </span>
                        <mat-radio-group [(ngModel)]="data.essay.according" name="essayAccording"
                            [disabled]="!enableEditEssay()">
                            <div fxLayout="row" fxFlex="100%">
                                <mat-radio-button [value]="true"> {{'executionEdit.dialog.essay.according' | translate}}
                                </mat-radio-button>
                                <mat-radio-button [value]="false">
                                    {{'executionEdit.dialog.essay.notAccording' | translate}}
                                </mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                </div>
                <div fxFlex>
                    <div fxLayout="row" *ngFor="let filter of activeCarbonFilters; let indexValue = index">
                        <div fxLayout="row" fxFlex="100%">
                            {{getIndexFilterSatu(filter)+1}}. {{ showFilterNameSatu(filter) | translate }}
                            {{getSizeFilterSatu(filter)}}
                            <button mat-icon-button (click)="editFilterActiveCoal(filter)"
                                matTooltip="{{ 'button.edit' | translate }}">
                                <mat-icon>mode_edit</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Each criteria by essay -->
            <div fxLayout="row" fxFlex="100%" class="flexwrap"
                *ngFor="let criteria of getCriteriaByEssay(); let indexCriteria = index">
                <div fxLayout="row" fxFlex="100%">
                    <span style="margin-top: 0.75em">
                        {{ 'protocolEdit.dialog.essay.criteria.cols.criteria' | translate }}:
                        <span
                            [innerHtml]="((renFlowAndRate || showAirSpeedBioII || showHeavyCabin) ? getCriteriaOfEssay() : getCriteria(criteria)) | safe: 'html'"></span>
                    </span>

                    <button mat-icon-button color="accent" (click)="editCriteria(indexCriteria)"
                        [disabled]="!enableEditCriteria()" matTooltip="{{ 'button.edit' | translate }}">
                        <mat-icon class="icon-display">mode_edit</mat-icon>
                    </button>
                    <button mat-icon-button color="accent" (click)="deleteCriteria(indexCriteria)"
                        *ngIf="data.essay.criterias?.length > 1 && !showMicrobiological"
                        [disabled]="!enableEditCriteria()">
                        <mat-icon class="icon-display">delete</mat-icon>
                    </button>
                    <button mat-icon-button color="accent" (click)="newValue(indexCriteria)"
                        *ngIf="!showParticleCount && !showMicrobiological && false" [disabled]="!enableEditEssay()">
                        <mat-icon class="icon-display-plus">add</mat-icon>
                    </button>
                </div>

                <!-- Microbiologic -->
                <div fxLayout="column" *ngIf="showMicrobiological" style="width: 100%;">

                    <div fxFlex fxLayout="row" style="width: 100%;"
                        *ngFor="let value of getValuesOfCriteria(criteria); let indexValue = index">

                        <div fxFlex fxLayout="row" style="width: 100%;">
                            <div fxFlex="10" fxFlex.lt-md="100%" style="padding-top: 1em;">
                                <span> Localización {{ indexValue + 1 }} </span>
                            </div>
                            <div fxFlex="20" fxFlex.lt-md="100%">
                                <mat-form-field>
                                    <input matInput [matDatepicker]="samplingDate" [value]="getDateFromData(value, 0)"
                                        (dateChange)="saveDate(value, 0, $event)" (blur)="saveDate(value, 0, $event)"
                                        placeholder="{{ 'executionEdit.dialog.essay.data.samplingDate' | translate }}">
                                    <mat-datepicker-toggle matSuffix [for]="samplingDate"></mat-datepicker-toggle>
                                    <mat-datepicker #samplingDate [calendarHeaderComponent]="customDatepickerHeader">
                                    </mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div fxFlex="20" fxFlex.lt-md="100%">
                                <mat-form-field>
                                    <input matInput [ngModel]="getValueFromData(value, 1)"
                                        (ngModelChange)="saveData(value, 1, $event)"
                                        [disabled]="!hasMicrobiologicalAmbient()"
                                        name="ambient{{indexCriteria}}{{indexValue}}"
                                        placeholder="{{ (data.compressedGas == null ? 'executionEdit.dialog.essay.data.ambient' : 'executionEdit.dialog.essay.data.compressedGas') | translate }}"
                                        [ngModelOptions]="{standalone: true}">
                                </mat-form-field>
                            </div>
                            <div fxFlex="20" fxFlex.lt-md="100%">
                                <mat-form-field>
                                    <input matInput [ngModel]="getValueFromData(value, 2)"
                                        (ngModelChange)="saveData(value, 2, $event)"
                                        [disabled]="!hasMicrobiologicalSurface()"
                                        name="surface{{indexCriteria}}{{indexValue}}"
                                        placeholder="{{ (data.compressedGas == null ? 'executionEdit.dialog.essay.data.surface' : 'executionEdit.dialog.essay.data.compressedGas') | translate }}"
                                        [ngModelOptions]="{standalone: true}">
                                </mat-form-field>
                            </div>

                        </div>
                    </div>

                    <div fxFlex fxLayout="row" style="width: 100%;">
                        <div fxFlex fxLayout="row" class="flexwrap">
                            <div fxFlex="30" fxFlex.lt-md="100%">
                                <span> {{ 'executionEdit.dialog.essay.result' | translate }} </span>
                                <mat-radio-group [(ngModel)]="data.essay.according" name="essayAccording"
                                    [disabled]="!enableEditEssay()">
                                    <div fxLayout="row" fxFlex="100%">
                                        <mat-radio-button [value]="true">
                                            {{'executionEdit.dialog.essay.according' | translate}}
                                        </mat-radio-button>
                                        <mat-radio-button [value]="false">
                                            {{'executionEdit.dialog.essay.notAccording' | translate}}
                                        </mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </div>
                            <div fxFlex="10" fxFlex.lt-md="100%">
                                <button mat-icon-button (click)="uploadCertificateInput.click()" color="accent"
                                    matTooltip="{{ 'button.upload-certificate' | translate }}"
                                    [disabled]="!enableEditEssay()">
                                    <mat-icon>cloud_upload</mat-icon>
                                </button>
                                <input accept="application/pdf" hidden type="file" (change)="uploadCertificate($event)"
                                    #uploadCertificateInput>
                                <button mat-icon-button color="accent" (click)="downloadCertificate()"
                                    *ngIf="data.essay.idCertificate != null"
                                    matTooltip="{{ 'button.download' | translate }}">
                                    <mat-icon>cloud_download</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Induction -->
                <div fxLayout="row" class="flexwrap" *ngIf="showIntroduction">
                    <div fxFlex="40" fxFlex.lt-md="100%">
                        <span> {{ 'executionEdit.dialog.essay.result' | translate }} </span>
                        <mat-radio-group [(ngModel)]="data.essay.according" name="essayAccording"
                            [disabled]="!enableEditEssay()">
                            <div fxLayout="row" fxFlex="100%">
                                <mat-radio-button [value]="true"> {{'executionEdit.dialog.essay.according' | translate}}
                                </mat-radio-button>
                                <mat-radio-button [value]="false">
                                    {{'executionEdit.dialog.essay.notAccording' | translate}}
                                </mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                </div>

                <!-- Noise light wasteOfLoad(eq) Def Pressure -->
                <div fxFlex *ngIf="criteriaSimple">
                    <div fxLayout="row" *ngFor="let value of getValuesOfCriteria(criteria); let indexValue = index">
                        <div fxFlex="30">
                            <mat-form-field class="campoFormulario">
                                <input matInput placeholder="{{ 'executionEdit.dialog.essay.data.field' | translate }}"
                                    [(ngModel)]="value.value" [errorStateMatcher]="matcher" type="number"
                                    [ngModelOptions]="{standalone: true}" [disabled]="!enableEditEssay()">
                                <mat-error>
                                    {{ 'executionEdit.dialog.essay.data.field.error' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="10px">
                            <button mat-icon-button color="accent" (click)="deleteValue(indexCriteria, indexValue)"
                                *ngIf="showDeleteValue(criteria)" [disabled]="!enableEditEssay()">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- WasteOfLoad(room)-->
                <div fxFlex *ngIf="wasteOfLoadRoom">
                    <div fxLayout="row" *ngFor="let value of getValuesOfCriteria(criteria); let indexValue = index">
                        <div fxFlex="30">
                            {{getIndexFilter(value.uuidFilter)+1}}. {{ showFilterName(value.uuidFilter) | translate }}
                            {{getSizeFilter(value.uuidFilter)}}
                            <button mat-icon-button (click)="editFilterByUUID(value.uuidFilter)"
                                matTooltip="{{ 'button.edit' | translate }}">
                                <mat-icon>mode_edit</mat-icon>
                            </button>
                        </div>
                        <div fxFlex="30">
                            <mat-form-field class="campoFormulario">
                                <input matInput placeholder="{{ 'executionEdit.dialog.essay.data.field' | translate }}"
                                    [(ngModel)]="value.value" [errorStateMatcher]="matcher" type="number"
                                    [ngModelOptions]="{standalone: true}" [disabled]="!enableEditEssay()">
                                <mat-error>
                                    {{ 'executionEdit.dialog.essay.data.field.error' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="10px">
                            <button mat-icon-button color="accent" (click)="deleteValue(indexCriteria, indexValue)"
                                *ngIf="showDeleteValue(criteria)" [disabled]="!enableEditEssay()">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Temperature humidity-->
                <div fxFlex *ngIf="showLocations">
                    <div fxLayout="row"
                        *ngFor="let location of getValuesOfCriteria(criteria); let indexLocation = index">
                        <div fxFlex="10" style="margin-top: 1.5em;">
                            {{ 'executionEdit.dialog.essay.data.location' | translate: {index : indexLocation + 1} }}
                        </div>
                        <div fxFlex="8"
                            *ngFor="let valueInput of location.data;let indexValueInput = index;trackBy:trackByIndex;">
                            <mat-form-field class="campoFormulario">
                                <input matInput type="number" required [value]="valueInput.value"
                                    (blur)="setData(location, indexValueInput, $event.target.value)"
                                    name="valueInputValue{{indexCriteria}}{{indexLocation}}{{indexValueInput}}"
                                    [disabled]="!enableEditEssay()">
                                <mat-error>
                                    {{ 'executionEdit.dialog.essay.data.field.error' | translate }}
                                </mat-error>
                                <button matSuffix mat-icon-button *ngIf="enableEditEssay()"
                                    (click)="deleteDataLocation(indexCriteria, indexLocation, indexValueInput)">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                        <div fxFlex="4em">
                            <mat-form-field class="campoFormulario">
                                <input matInput placeholder="Media" disabled [value]="showAverageSpeed(location)">
                            </mat-form-field>
                        </div>
                        <div fxFlex="4">
                            <button mat-icon-button color="accent" (click)="addDataValue(indexCriteria, indexLocation)"
                                [disabled]="!enableEditEssay()">
                                <mat-icon class="icon-display-plus">add</mat-icon>
                            </button>
                        </div>
                        <div fxFlex="4">
                            <button mat-icon-button color="accent" (click)="deleteValue(indexCriteria, indexLocation)"
                                *ngIf="showDeleteValue(criteria)" [disabled]="!enableEditEssay()">
                                <mat-icon class="icon-display">delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- FilterHepa-->
                <div fxFlex *ngIf="showFilterHepa">
                    <div fxLayout="row" *ngFor="let value of getValuesOfCriteria(criteria); let indexValue = index">
                        <div fxFlex="30">
                            {{getIndexFilter(value.uuidFilter)+1}}. {{ showFilterName(value.uuidFilter) | translate }}
                            {{getSizeFilter(value.uuidFilter)}}
                            <button mat-icon-button (click)="editFilterByUUID(value.uuidFilter)"
                                matTooltip="{{ 'button.edit' | translate }}">
                                <mat-icon>mode_edit</mat-icon>
                            </button>
                        </div>
                        <div fxFlex="30">
                            <mat-form-field class="campoFormulario">
                                <input matInput placeholder="{{ 'executionEdit.dialog.essay.data.field' | translate }}"
                                    [(ngModel)]="value.value" [errorStateMatcher]="matcher" type="number"
                                    [ngModelOptions]="{standalone: true}" [disabled]="!enableEditEssay()">
                                <mat-error>
                                    {{ 'executionEdit.dialog.essay.data.field.error' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="15">
                            <mat-checkbox (change)="setAerosol($event, value)" [checked]="value.aerosol"
                                style="padding-top: 1em;" [disabled]="!enableEditEssay()">
                                Aportar aerosol
                            </mat-checkbox>
                        </div>
                        <div fxFlex="15">
                            <mat-form-field class="campoFormulario">
                                <input matInput [disabled]="!value.aerosol" [(ngModel)]="value.reference"
                                    [ngModelOptions]="{standalone: true}">
                                <mat-error>
                                    {{ 'executionEdit.dialog.essay.data.field.error' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxFlex="10px">
                            <button mat-icon-button color="accent" (click)="deleteValue(indexCriteria, indexValue)"
                                *ngIf="showDeleteValue(criteria)" [disabled]="!enableEditEssay()">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Contaje de partículas -->
                <div fxFlex *ngIf="showParticleCount">

                    <button mat-icon-button color="accent" (click)="josetizeParticles()"
                        matTooltip="{{ 'button.read-josetizer' | translate }}" [disabled]="!enableEditCriteria()">
                        <mat-icon>published_with_changes</mat-icon>
                    </button>

                    <button mat-icon-button color="accent" (click)="uploadExcelParticleCountInput.click()"
                        matTooltip="{{ 'button.upload-excel' | translate }}" [disabled]="!enableEditCriteria()">
                        <mat-icon>cloud_upload</mat-icon>
                    </button>
                    <input accept=".xlsx, .xls, .csv" hidden type="file" (change)="uploadExcelParticleCount($event)"
                        #uploadExcelParticleCountInput>

                    <button mat-icon-button color="accent" (click)="downloadExcelParticleCount()"
                        matTooltip="{{ 'button.download-excel' | translate }}" *ngIf="data.essay?.idExcel != null">
                        <mat-icon>cloud_download</mat-icon>
                    </button>

                    <button mat-icon-button color="accent" (click)="downloadJosetizer()"
                        matTooltip="{{ 'button.download-josetizer' | translate }}" [disabled]="!enableEditCriteria()">
                        <mat-icon>add_task</mat-icon>
                    </button>

                    <table #valuesParticles mat-table matSort [dataSource]="data.essay.valuesParticles">

                        <!-- date Column -->
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ 'executionEdit.dialog.essay.valuesParticles.cols.date' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item"> {{ item.date | fixDateTime }} </td>
                        </ng-container>

                        <!-- location Column -->
                        <ng-container matColumnDef="location">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ 'executionEdit.dialog.essay.valuesParticles.cols.location' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item"> {{ item.location }} </td>
                        </ng-container>

                        <!-- volume Column -->
                        <ng-container matColumnDef="volume">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ 'executionEdit.dialog.essay.valuesParticles.cols.volume' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item"> {{ item.volume + ' ' + item.unit }} </td>
                        </ng-container>

                        <!-- zeroOne Column -->
                        <ng-container matColumnDef="zeroOne">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ 'executionEdit.dialog.essay.valuesParticles.cols.zeroOne' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item"
                                [class]="getClassParticleNum(criteria, '0.1', item.zeroOne)">
                                {{ showParticleNum(item.zeroOne) }}
                            </td>
                        </ng-container>

                        <!-- zeroTwo Column -->
                        <ng-container matColumnDef="zeroTwo">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ 'executionEdit.dialog.essay.valuesParticles.cols.zeroTwo' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item"
                                [class]="getClassParticleNum(criteria, '0.2', item.zeroTwo)">
                                {{ showParticleNum(item.zeroTwo) }}
                            </td>
                        </ng-container>

                        <!-- zeroThree Column -->
                        <ng-container matColumnDef="zeroThree">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ 'executionEdit.dialog.essay.valuesParticles.cols.zeroThree' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item"
                                [class]="getClassParticleNum(criteria, '0.3', item.zeroThree)">
                                {{ showParticleNum(item.zeroThree) }}
                            </td>
                        </ng-container>

                        <!-- zeroFive Column -->
                        <ng-container matColumnDef="zeroFive">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ 'executionEdit.dialog.essay.valuesParticles.cols.zeroFive' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item"
                                [class]="getClassParticleNum(criteria, '0.5', item.zeroFive)">
                                {{ showParticleNum(item.zeroFive) }}
                            </td>
                        </ng-container>

                        <!-- oneZero Column -->
                        <ng-container matColumnDef="oneZero">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ 'executionEdit.dialog.essay.valuesParticles.cols.oneZero' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item"
                                [class]="getClassParticleNum(criteria, '1.0', item.oneZero)">
                                {{ showParticleNum(item.oneZero) }}
                            </td>
                        </ng-container>

                        <!-- fiveZero Column -->
                        <ng-container matColumnDef="fiveZero">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ 'executionEdit.dialog.essay.valuesParticles.cols.fiveZero' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item"
                                [class]="getClassParticleNum(criteria, '5.0', item.fiveZero)">
                                {{ showParticleNum(item.fiveZero) }}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsParticleCount"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsParticleCount;"></tr>

                    </table>

                    <div *ngIf="data.compressedGas != null && data.essay?.valuesParticles.length > 0">
                        <br />
                        <strong>{{ 'common.average' | translate }}</strong>
                        <div *ngFor="let size of getParticleSize(criteria)"
                            [class]="getClassParticleNum(criteria, size, getValueAverageParticleCountCompressedGas(size))">
                            {{ size }}: {{ getValueAverageParticleCountCompressedGas(size) }}
                        </div>
                    </div>

                </div>

                <!-- renFlowAndRate -->
                <div fxFlex *ngIf="renFlowAndRate">
                    <div fxLayout="row">
                        <div fxFlex="10"></div>
                        <div fxFlex="20">
                            <div fxLayout="row">
                                <mat-form-field class="campoFormulario">
                                    <input matInput disabled [value]="showTotalFlow(criteria)" type="number"
                                        placeholder="Caudal total">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="20">
                            <div fxLayout="row">
                                <mat-form-field class="campoFormulario">
                                    <input matInput disabled [value]="showRenH(criteria)" type="number"
                                        placeholder="Renovaciones hora">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row"
                        *ngFor="let location of getValuesOfCriteria(criteria); let indexLocation = index">
                        <div fxFlex="20" style="margin-top: 0.5em;">
                            {{ getIndexFilter(location.uuidFilter) + 1 }}.
                            {{ showFilterName(location.uuidFilter) | translate }}
                            {{ getSizeFilter(location.uuidFilter) }}
                            <button mat-icon-button (click)="editFilterByUUID(location.uuidFilter)"
                                matTooltip="{{ 'button.edit' | translate }}">
                                <mat-icon>mode_edit</mat-icon>
                            </button>
                        </div>
                        <div fxFlex="8em">
                            <mat-checkbox (change)="justCaudal($event, location)" [checked]="checkJustCaudal(location)"
                                style="padding-top: 1em;" [disabled]="!enableEditEssay() || disabledJustCaudal(location)">
                                Solo caudal
                            </mat-checkbox>
                        </div>
                        <div fxFlex="6em" *ngIf="!checkJustCaudal(location)" style="margin-top: 0.75em;">
                            <span style="padding-top: 1em;display: block;">
                                {{ 'executionEdit.essaySpeed.speeds' | translate }}:
                            </span>
                        </div>
                        <div fxFlex="8em"
                            *ngFor="let valueInput of location.data;let indexValueInput = index;trackBy:trackByIndex;">
                            <mat-form-field class="campoFormulario" *ngIf="!checkJustCaudal(location)">
                                <input matInput type="number" class="noArrow" required [value]="valueInput.value"
                                    (blur)="setData(location, indexValueInput, $event.target.value)"
                                    name="valueInputValue{{indexCriteria}}{{indexLocation}}{{indexValueInput}}"
                                    [disabled]="!enableEditEssay()">
                                <mat-error>
                                    {{ 'executionEdit.dialog.essay.data.field.error' | translate }}
                                </mat-error>
                                <button matSuffix mat-icon-button *ngIf="enableEditEssay()"
                                    (click)="deleteDataLocation(indexCriteria, indexLocation, indexValueInput)">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                        <div fxFlex="2em" *ngIf="!checkJustCaudal(location)">
                            <button mat-icon-button color="accent" (click)="addDataValue(indexCriteria, indexLocation)"
                                [disabled]="!enableEditEssay()">
                                <mat-icon class="icon-display-plus">add</mat-icon>
                            </button>
                        </div>
                        <div fxFlex="6em" *ngIf="!checkJustCaudal(location)">
                            <mat-form-field class="campoFormulario">
                                <input matInput placeholder="V. media" disabled [value]="showAverageSpeed(location)">
                            </mat-form-field>
                        </div>
                        <div fxFlex="6em" *ngIf="checkJustCaudal(location)">
                            <mat-form-field class="campoFormulario">
                                <input matInput placeholder="Caudal" [(ngModel)]="location.value"
                                    [errorStateMatcher]="matcher" type="number" [disabled]="!enableEditEssay()"
                                    [ngModelOptions]="{standalone: true}">
                            </mat-form-field>
                        </div>
                        <div fxFlex="6em" *ngIf="!checkJustCaudal(location)">
                            <mat-form-field class="campoFormulario">
                                <input matInput placeholder="Caudal" disabled [value]="showFlow(location)"
                                    type="number">
                            </mat-form-field>
                        </div>
                        <div fxFlex="4">
                            <button mat-icon-button color="accent" (click)="deleteValue(indexCriteria, indexLocation)"
                                *ngIf="showDeleteValue(criteria)" [disabled]="!enableEditEssay()">
                                <mat-icon class="icon-display">delete</mat-icon>
                            </button>
                        </div>
                    </div>

                </div>

                <!-- Air Speed -->
                <div fxFlex *ngIf="showSpeed">
                    <div fxLayout="row">
                        <div fxFlex="20">
                            <mat-checkbox [value]="isChanneled" [checked]="isChanneled" [disabled]="data.disabled"
                                (change)="changeChanneled($event, criteria)" *ngIf="showIsChanneled">
                                {{'executionEdit.channeled.label' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>
                    <div fxLayout="row"
                        *ngFor="let location of getValuesOfCriteria(criteria); let indexLocation = index">
                        <div *ngIf="location.uuidFilter != null" fxFlex="15" style="margin-top: 1.5em;">
                            {{getIndexFilter(location.uuidFilter)+1}}. {{ showFilterName(location.uuidFilter) |
                            translate }}
                            {{getSizeFilter(location.uuidFilter)}}
                            <button mat-icon-button (click)="editFilterByUUID(location.uuidFilter)"
                                matTooltip="{{ 'button.edit' | translate }}">
                                <mat-icon>mode_edit</mat-icon>
                            </button>
                        </div>
                        <div fxFlex="10" *ngIf="!checkJustCaudal(location)">
                            {{ showSpeedType() | translate }}
                        </div>
                        <div fxFlex="6em"
                            *ngFor="let valueInput of location.data;let indexValueInput = index;trackBy:trackByIndex;">
                            <mat-form-field class="campoFormulario" *ngIf="!checkJustCaudal(location)">
                                <input matInput type="number" required class="noArrow" [value]="valueInput.value"
                                    (blur)="setData(location, indexValueInput, $event.target.value)"
                                    name="valueInputValue{{indexCriteria}}{{indexLocation}}{{indexValueInput}}"
                                    [disabled]="!enableEditEssay()">
                                <mat-error>
                                    {{ 'executionEdit.dialog.essay.data.field.error' | translate }}
                                </mat-error>
                                <button matSuffix mat-icon-button *ngIf="enableEditEssay()"
                                    (click)="deleteDataLocation(indexCriteria, indexLocation, indexValueInput)">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                        <div fxFlex="4" *ngIf="!checkJustCaudal(location)">
                            <button mat-icon-button color="accent" (click)="addDataValue(indexCriteria, indexLocation)"
                                [disabled]="!enableEditEssay()">
                                <mat-icon class="icon-display-plus">add</mat-icon>
                            </button>
                        </div>
                        <div fxFlex="6em" *ngIf="!checkJustCaudal(location)">
                            <mat-form-field class="campoFormulario">
                                <input matInput disabled [value]="showAverageSpeed(location)"
                                    placeholder="{{ 'protocolEdit.dialog.essay.criteria.averageSpeed.label' | translate }}">
                            </mat-form-field>
                        </div>
                        <div fxFlex="6em" *ngIf="location.uuidFilter != null">
                            <div *ngIf="location.showLocations && showFrontSpeedByFilter(location.uuidFilter)">
                                <mat-form-field class="campoFormulario">
                                    <input matInput disabled [value]="showFrontSpeed(location)"
                                        placeholder="{{ 'protocolEdit.dialog.essay.criteria.frontalSpeed.label' | translate }}">
                                </mat-form-field>
                            </div>
                        </div>

                        <div fxFlex="6" *ngIf="checkJustCaudal(location)">
                            {{ 'variable.notApplicable' | translate }}
                        </div>
                        <div fxFlex="4">
                            <button mat-icon-button color="accent" (click)="deleteValue(indexCriteria, indexLocation)"
                                *ngIf="showDeleteValue(criteria) && location.showLocations"
                                [disabled]="!enableEditEssay()">
                                <mat-icon class="icon-display">delete</mat-icon>
                            </button>
                        </div>
                    </div>

                </div>

                <!-- Dew Point -->
                <div fxLayout="column" *ngIf="showDewPoint" style="width: 100%;">
                    <div fxFlex fxLayout="row" style="width: 100%;"
                        *ngFor="let value of getValuesOfCriteria(criteria); let indexValue = index">

                        <div fxFlex fxLayout="row" style="width: 100%;">
                            <div fxFlex="20" fxFlex.lt-md="100%">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="value.value" type="number"
                                        name="dewPoint{{indexCriteria}}{{indexValue}}"
                                        [ngModelOptions]="{standalone: true}" [disabled]="!enableEditEssay()">
                                </mat-form-field>
                            </div>
                            <div fxFlex="20" fxFlex.lt-md="100%">
                                <mat-form-field>
                                    <mat-select [(ngModel)]="value.reference" required
                                        name="dewPointReference{{indexCriteria}}{{indexValue}}"
                                        [disabled]="!enableEditEssay()">
                                        <mat-option *ngFor="let item of getDewPointValues()" [value]="item">
                                            {{ 'common.' + item | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div fxFlex fxLayout="row" style="width: 100%;">
                        <div fxFlex fxLayout="row" class="flexwrap">
                            <div fxFlex="10" fxFlex.lt-md="100%">
                                <button mat-icon-button (click)="uploadCertificateDewPointInput.click()" color="accent"
                                    matTooltip="{{ 'button.upload-certificate' | translate }}"
                                    [disabled]="!enableEditEssay()">
                                    <mat-icon>cloud_upload</mat-icon>
                                </button>
                                <input accept="application/pdf" hidden type="file" (change)="uploadCertificate($event)"
                                    #uploadCertificateDewPointInput>
                                <button mat-icon-button color="accent" (click)="downloadCertificate()"
                                    *ngIf="data.essay.idCertificate != null"
                                    matTooltip="{{ 'button.download' | translate }}">
                                    <mat-icon>cloud_download</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
                <br />
            </div>

            <!-- Ensayo de recuperación -->
            <div fxFlex *ngIf="showRecoveryEssay">
                <div fxLayout="row" fxFlex="100%">
                    <span style="font-weight: bold; padding-top: 2em;" fxFlex="100%"
                        [innerHtml]="getValuesEssayRecovery() | safe: 'html'"> </span>
                </div>
            </div>
        </div>

        <br />

        <!-- Fields -->
        <mat-toolbar color="secondary" *ngIf="data.essay.active">
            {{ 'protocolEdit.dialog.essay.fieldsTitle' | translate }}
        </mat-toolbar>
        <div *ngIf="data.essay.idEssayType != null && data.essay.active">
            <div fxLayout="row" class="flexwrap">
                <div fxFlex="50" fxFlex.lt-md="100%"
                    *ngFor="let field of data.essay.essayValues; let indexField = index">

                    <!-- STRING -->
                    <div *ngIf="field.idTypeField == 1">
                        <mat-form-field class="campoFormulario" style="width: 90%">
                            <input matInput disabled [name]="field.translationField" [ngModel]="field.value"
                                [required]="field.required"
                                placeholder="{{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}">
                            <mat-error>
                                {{ 'protocolEdit.dialog.essay.' + field.translationField + '.error' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <button mat-icon-button *ngIf="enableEditField(field)" (click)="openEditField(indexField)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>

                    <!-- NUMÉRICO -->
                    <div *ngIf="field.idTypeField == 2">
                        <mat-form-field class="campoFormulario" style="width: 90%">
                            <input matInput disabled [name]="field.translationField" [ngModel]="field.value"
                                type="number" [required]="field.required"
                                placeholder="{{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}">
                            <mat-error>
                                {{ 'protocolEdit.dialog.essay.' + field.translationField + '.error' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <button mat-icon-button *ngIf="enableEditField(field)" (click)="openEditField(indexField)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>

                    <!-- TOLERANCIA -->
                    <div *ngIf="field.idTypeField == 3">
                        <div fxFlex="30%" fxFlex.lt-md="100%">
                            <mat-form-field class="campoFormulario">
                                <input matInput disabled [name]="field.translationField" [(ngModel)]="field.value[0]"
                                    type="number" [required]="field.required"
                                    placeholder="{{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}">
                                <mat-error>
                                    {{ 'protocolEdit.dialog.essay.' + field.translationField + '.error' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="30%" fxFlex.lt-md="100%">
                            ±
                            <mat-form-field class="campoFormulario">
                                <input matInput disabled [name]="field.translationField + 'Tolerance'"
                                    [(ngModel)]="field.value[1]" type="number" [required]="field.required"
                                    placeholder="{{ 'protocolEdit.dialog.essay.valueTolerance.label' | translate }}">
                                <mat-error>
                                    {{ 'protocolEdit.dialog.essay.valueTolerance.error' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <button mat-icon-button *ngIf="enableEditField(field)" (click)="openEditField(indexField)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>

                    <!-- DIA HORA MIN -->
                    <div *ngIf="field.idTypeField == 4">
                        <div fxLayout="row">
                            <div fxFlex="30%" fxFlex.lt-md="100%">
                                <mat-form-field class="campoFormulario">
                                    <input matInput [name]="field.translationField + 'Day'" [(ngModel)]="field.value[0]"
                                        disabled type="number" [required]="field.required" min="0"
                                        placeholder="{{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}: {{ 'common.days.label' | translate }}">
                                </mat-form-field>
                            </div>
                            <div fxFlex="30%" fxFlex.lt-md="100%">
                                <mat-form-field class="campoFormulario">
                                    <input matInput [name]="field.translationField + 'Hour'"
                                        [(ngModel)]="field.value[1]" type="number" disabled [required]="field.required"
                                        min="0" placeholder="{{ 'common.hours.label' | translate }}">
                                </mat-form-field>
                            </div>
                            <div fxFlex="30%" fxFlex.lt-md="100%">
                                <mat-form-field class="campoFormulario">
                                    <input matInput [name]="field.translationField + 'Minute'"
                                        [(ngModel)]="field.value[2]" type="number" disabled [required]="field.required"
                                        min="0" placeholder="{{ 'common.minutes.label' | translate }}">
                                </mat-form-field>
                            </div>
                            <button mat-icon-button *ngIf="enableEditField(field)" (click)="openEditField(indexField)">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                    </div>

                    <!-- SELECT_ONE -->
                    <div *ngIf="field.idTypeField == 6">
                        <span>
                            {{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}
                        </span>
                        <br />
                        <mat-radio-group class="vertical-radio-group" [required]="field.required">
                            <mat-radio-button class="vertical-radio-button" *ngFor="let doma of field.fieldDomain"
                                [value]="doma.value" disabled [checked]="field.value == doma.value">
                                {{ 'protocolEdit.dialog.essay.' + doma.translation + '.label' | translate }}
                            </mat-radio-button>
                        </mat-radio-group>
                        <button mat-icon-button *ngIf="enableEditField(field)" (click)="openEditField(indexField)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>

                    <!-- SELECT_MULTI -->
                    <div *ngIf="field.idTypeField == 7">
                        <span>
                            {{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}
                        </span>
                        <button mat-icon-button *ngIf="enableEditField(field)" (click)="openEditField(indexField)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <mat-selection-list disabled>
                            <mat-list-option *ngFor="let doma of field.fieldDomain" checkboxPosition="before" disabled
                                [value]="doma.value" [selected]="isSelectedMulti(field.value, doma.value)">
                                {{ 'protocolEdit.dialog.essay.' + doma.translation + '.label' | translate }}
                            </mat-list-option>
                        </mat-selection-list>
                    </div>

                    <!-- BOOLEAN -->
                    <div *ngIf="field.idTypeField == 8">
                        <mat-checkbox [value]="field.value" [checked]="field.value == 'true'" disabled>
                            {{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}
                        </mat-checkbox>
                        <button mat-icon-button *ngIf="enableEditField(field)" (click)="openEditField(indexField)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>

                </div>
                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="data.essay?.essayValues?.length == 0"
                    style="margin-top: 2em;margin-bottom: 2em;">
                    {{ 'protocolEdit.dialog.essay.fields.empty' | translate }}
                </div>
            </div>
        </div>
        <div fxLayout="row" class="flexwrap" *ngIf="data.essay.active">
            <!-- Titulo equipos y boton + y boton delete -->
            <mat-toolbar color="secondary">
                {{ 'calibrateEquipmentEdit.patterns.dialog.equipment.label' | translate }}
                <button mat-icon-button color="accent" (click)="addEquipment()" [disabled]="!enableEditEssay()">
                    <mat-icon class="icon-display-plus">add</mat-icon>
                </button>
                <mat-checkbox (change)="onChangeAppTypeCheck($event)" [checked]="data.essay.equipmentNA"
                    [disabled]="!enableEditEssay()">
                    {{ 'executionEdit.dialog.essay.equipment.notApplicable' | translate }}
                </mat-checkbox>
            </mat-toolbar>

            <div fxFlex *ngIf="data.essay.equipmentNA">
                <br />
                <span> {{ 'executionEdit.dialog.essay.equipment.notApplicable' | translate }} </span>
                <br />
                <br />
            </div>

            <div fxFlex *ngIf="!data.essay.equipmentNA">
                <div fxLayout="row" *ngFor="let equipment of data.essay.equipments; let indexEquipment = index">
                    <div fxFlex="30">
                        <mat-form-field class="campoFormulario">
                            <input matInput [ngModel]="equipment.equipmentName" #trigger="matAutocompleteTrigger"
                                placeholder="{{ 'calibrateEquipmentEdit.patterns.dialog.equipment.label' | translate }}"
                                [matAutocomplete]="auto" (keyup)="lookupEquipment($event)"
                                [ngModelOptions]="{standalone: true}" required [disabled]="!enableEditEssay()">
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
                                (optionSelected)="onSensorChange($event, indexEquipment)">
                                <mat-option *ngFor="let item of equipmentAutoComplete; let index = index"
                                    [value]="item">
                                    {{ item.name}} ({{item.serialNum}})
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error>
                                {{ 'calibrateEquipmentEdit.patterns.dialog.equipment.error' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="10px">
                        <button mat-icon-button color="accent" (click)="deleteEquipment(indexEquipment)"
                            *ngIf="showDeleteEquipment()" [disabled]="!enableEditEssay()">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <br />

        </div>

        <br />

        <!-- Audit -->
        <mat-toolbar color="secondary">
            {{ 'executionEdit.audit.title' | translate }}
        </mat-toolbar>
        <div fxLayout="row" style="width: 100%;">
            <div fxFlex>
                <mat-form-field class="campoFormulario">
                    <input matInput disabled [value]="data.essay.regUser != null ? data.essay.regUser : ''"
                        placeholder="{{ 'executionEdit.audit.regUser' | translate }}">
                </mat-form-field>
            </div>
            <div fxFlex>
                <mat-form-field class="campoFormulario">
                    <input matInput disabled [value]="data.essay.regFc != null ? (data.essay.regFc | fixDateTime) : ''"
                        placeholder="{{ 'executionEdit.audit.regFc' | translate }}">
                </mat-form-field>
            </div>
            <div fxFlex>
                <mat-form-field class="campoFormulario">
                    <input matInput disabled [value]="data.essay.modUser != null ? data.essay.modUser : ''"
                        placeholder="{{ 'executionEdit.audit.modUser' | translate }}">
                </mat-form-field>
            </div>
            <div fxFlex>
                <mat-form-field class="campoFormulario">
                    <input matInput disabled [value]="data.essay.modFc != null ? (data.essay.modFc | fixDateTime) : ''"
                        placeholder="{{ 'executionEdit.audit.modFc' | translate }}">
                </mat-form-field>
            </div>
        </div>

        <br />

        <!-- Observations -->
        <mat-toolbar color="secondary">
            {{ 'executionEdit.observations.title' | translate }}
        </mat-toolbar>

        <div fxLayout="row" style="width: 100%;">
            <div fxFlex>
                <mat-form-field class="campoFormulario" style="width: 100%">
                    <textarea rows="5" matAutosizeMinRows="5" matInput
                        placeholder="{{ 'protocolEdit.observations.label' | translate }}" [(ngModel)]="data.essay.notes"
                        name="observations" style="resize: none;">

                    </textarea>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="air-theme">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()"
            [disabled]="data.essay.idEssayType == null || !enableEditCriteria()">
            {{ 'button.save' | translate }}
        </button>
        <div fxFlex="1"></div>
        <!-- Contaje de partículas -->
        <button mat-raised-button color="accent" (click)="downloadCountStrip()"
            *ngIf="showParticleCount && data.essay.valuesParticles?.length > 1">
            {{ 'button.download' | translate }}</button>

        <!-- Caso hay vídeo que subir -->
        <button mat-raised-button *ngIf="showUploadVideo()" (click)="uploadVideoInput.click()" color="accent">
            {{ 'button.upload-video' | translate }}
        </button>
        <input accept="video/*" hidden type="file" (change)="uploadVideo($event)" #uploadVideoInput>
        <button mat-raised-button color="accent" (click)="downloadVideo()"
            *ngIf="showUploadVideo() && data.essay.idVideo != null">
            {{ 'button.download-video' | translate }}
        </button>
        <button mat-raised-button color="accent" (click)="openVideo()"
            *ngIf="showUploadVideo() && data.essay.idVideo != null">
            {{ 'button.open-video' | translate }}
        </button>
        <button mat-raised-button color="accent" (click)="removeVideo()"
            *ngIf="showUploadVideo() && data.essay.idVideo != null">
            {{ 'button.remove-video' | translate }}
        </button>

        <button mat-raised-button color="accent" (click)="delete()" *ngIf="!data.isNew && !data.essay.fromProtocol"
            [disabled]="!enableEditCriteria()">
            {{ 'button.delete' | translate }}</button>

        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>
</form>