import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatListOption, MatSelectionListChange } from '@angular/material/list';
import { MatSelectChange } from '@angular/material/select';
import { MatTable } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AttachmentType } from 'src/app/model/attachment';
import { BioindicatorConfig } from 'src/app/model/bioindicator';
import { Criteria } from 'src/app/model/criteria';
import { CriteriaType, CriteriaTypeEnum } from 'src/app/model/criteriaType';
import { Essay } from 'src/app/model/essay';
import { EssayProtocol } from 'src/app/model/essayProtocol';
import { EssayType } from 'src/app/model/essayType';
import { Field } from 'src/app/model/field';
import { Sensor } from 'src/app/model/sensor';
import { Variable, VariableTypeEnum, VariableUnit } from 'src/app/model/variable';
import { AttachmentThermalService } from 'src/app/services/attachmentThermal.service';
import { CriteriaTypeService } from 'src/app/services/criteriaType.service';
import { EssayService } from 'src/app/services/essay.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { VariableTypeService } from 'src/app/services/variableType.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { DependencyTypeEnum, EssayUtils } from 'src/app/utils/essayUtils';
import { FieldEnum, FieldUtils } from 'src/app/utils/fieldUtils';
import { StringUtils } from 'src/app/utils/stringUtils';
import {
  DialogDataProtocolBioIndicators, ProtocolEditBioindicatorsDetailsComponent
} from './protocol-edit-bioindicators-details.component';
import { ProtocolEditDialogEditSensorsComponent } from './protocol-edit-dialog-edit-sensors.component';
import { ProtocolEditEssayCriteriaComponent } from './protocol-edit-essay-criteria.component';
import { ProtocolEditPhotoDetailsComponent } from './protocol-edit-photo-details.component';

export interface DialogDataEssay {
  essay: Essay;
  idProtocol: number;
  idPhase: number;
  isEdit: boolean;
  disabled: boolean;
}

@Component({
  selector: 'app-protocol-edit-dialog-essay',
  templateUrl: 'protocol-edit-dialog-essay.component.html'
})
export class ProtocolEditEssayDialogComponent implements OnInit, OnDestroy {

  @ViewChildren('criteriaTable') criteriaTable: QueryList<MatTable<any>>;

  essaysPossibles: EssayType[];

  matcher = new MyErrorStateMatcher();

  tituloDialog: string;

  variablesPossibles: Variable[];
  variableUnits: VariableUnit[];
  idConfig: number;

  public checked = new Map<number, boolean>();
  public numSensor = new Map<number, number>();

  public displayedColsCriteria = ['criteria', 'edit', 'delete'];

  public checkedSameSensors = new Map<number, boolean>();
  criteriaInformative: CriteriaType;

  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<ProtocolEditEssayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataEssay,
    private essayService: EssayService,
    private attachmentThermalService: AttachmentThermalService,
    private criteriaTypeService: CriteriaTypeService,
    private variableTypeService: VariableTypeService,
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    if (data.isEdit) {
      const essayName = this.translate.instant(`essayType.${data.essay.translationType}`) as string;
      this.tituloDialog = this.translate.instant('protocolEdit.essays.title.edit', { name: essayName }) as string;

      if (this.data.essay.essayValues.length !== 0) {
        this.idConfig = this.data.essay.essayValues[0].idConfig;
      }

      this.data.essay.essayValues.forEach(element => {
        const fields = element.fields.map(i => FieldUtils.objectToField(i));

        element.fields = fields;
      });
    } else {
      this.tituloDialog = this.translate.instant('protocolEdit.essays.title.create') as string;

      data.essay.essayValues = [];
    }
  }

  ngOnInit(): void {
    this.essaysPossibles = [];
    this.essayService.findByPhaseId(this.data.idPhase).pipe(takeUntil(this.destroy$))
      .subscribe((item: EssayType[]) => this.essaysPossibles = item);

    this.variablesPossibles = [];
    if (this.data.essay.idType != null) {
      this.essayService.findVariablesById(this.data.idPhase, this.data.essay.idType).pipe(takeUntil(this.destroy$))
        .subscribe((item: Variable[]) => {
          this.variablesPossibles = item;

          this.variablesPossibles.forEach(vari => {
            const checked = vari.required || this.data.essay.essayValues.find(e => e.idVariable === vari.id) != null;

            this.checked.set(vari.id, checked);

            if (checked && !this.data.isEdit) {
              this.markVariableCheck(vari);
            }
          });

          this.variablesPossibles.forEach(vari => {
            this.numSensor.set(vari.id, 0);
          });

          if (this.data.isEdit) {
            this.data.essay.essayValues.forEach(element => {
              this.checked[element.idVariable] = true;
              this.numSensor[element.idVariable] = element.sensors.length;
            });

            this.onEssayTabChange(0);
          }

          this.spinnerService.hide();
        });
    }

    this.criteriaTypeService.findOne(CriteriaTypeEnum.INFORMATIVE).pipe(takeUntil(this.destroy$))
      .subscribe((res: CriteriaType) => this.criteriaInformative = res);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showCriteria(crit: Criteria): string {
    return EssayUtils.getCriteriaToShowThermal(crit, this.translate);
  }

  isVariableChecked(item: Variable): boolean {
    const checkedByArray = this.checked.get(item.id);
    return checkedByArray || item.required;
  }

  onChangeTypeSelect(event): void {
    this.spinnerService.show();

    const type = this.essaysPossibles.find(item => item.id === event.source.value);

    if (type !== undefined && type != null) {
      this.data.essay.dsType = type.name;
      this.data.essay.translationType = type.translation;
    }

    this.variablesPossibles = [];
    this.essayService.findVariablesById(this.data.idPhase, type.id).pipe(takeUntil(this.destroy$)).subscribe((item: Variable[]) => {
      this.variablesPossibles = item;

      this.checked = new Map<number, boolean>();
      this.variablesPossibles.forEach(vari => {
        const check = vari.required || vari.checked;

        this.checked.set(vari.id, check);

        if (check) {
          this.markVariableCheck(vari);
        }
      });

      this.numSensor = new Map<number, number>();
      this.variablesPossibles.forEach(vari => {
        this.numSensor.set(vari.id, 0);
      });

      this.spinnerService.hide();
    });

    this.data.essay.essayValues = [];

  }

  onChangeVariableCheck(event: MatCheckboxChange): void {

    const variable = event.source.value as unknown as Variable;

    if (event.checked) {
      this.markVariableCheck(variable);
    } else {
      const idDelete = variable.id;

      this.data.essay.essayValues = this.data.essay.essayValues.filter(item => item.idVariable !== idDelete);
    }

    this.checked.set(variable.id, event.checked);

    this.checkedSameSensors.set(variable.idConfig, event.checked && variable.idTypeDependency === DependencyTypeEnum.OPTIONAL);

    this.data.essay.essayValues.sort((a, b) => a.idVariable < b.idVariable ? -1 : a.idVariable > b.idVariable ? 1 : 0);
  }

  openManualEditSensors(idVariable: number): void {
    const variableSelected = this.data.essay.essayValues.find(i => i.idVariable === idVariable);
    const dialogRef = this.dialog.open(ProtocolEditDialogEditSensorsComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        sensors: variableSelected.sensors.map(s => s.location).join('\n')
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Sensor[]) => {
      if (result !== null && result !== undefined) {
        variableSelected.sensors = result;
        this.numSensor[idVariable] = result.length;
      }
    });
  }

  isPhotoUploaded(idVariable: number): boolean {
    const variableSelected = this.data.essay.essayValues.find(i => i.idVariable === idVariable);

    return variableSelected.idImage != null;
  } 

  canUploadPhoto(): boolean {
    return this.data.idProtocol != null;
  }

  openDialogPhoto(idVariable: number): void {
    const variableSelected = this.data.essay.essayValues.find(i => i.idVariable === idVariable);

    const dialogRef = this.dialog.open(ProtocolEditPhotoDetailsComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        idProtocol: this.data.idProtocol,
        idAttachment: variableSelected.idImage,
        disableUpload: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: number) => {
      if (result != null) {
        if (result !== -1) {
          variableSelected.idImage = result;
        } else {
          variableSelected.idImage = null;
        }
      }
    });
  }

  onFileUpload(idVariable: number, event): void {
    const image = event.target.files[0] as File;
    const tab = this.data.essay.essayValues.find(i => i.idVariable === idVariable);

    const attType = AttachmentType.FOTO_ESQUEMA_COLOCACION_SONDAS_PROTOCOLO;
    this.attachmentThermalService.uploadGeneric(image, attType).pipe(takeUntil(this.destroy$)).subscribe((res: number) => {
      tab.idImage = res;
    });
  }

  setNumSensor(idVariable: number, event: number): void {
    if (event == null || event < 0) {
      return;
    }

    this.numSensor[idVariable] = event;

    const tab = this.data.essay.essayValues.find(i => i.idVariable === idVariable);

    const currentSensorNum = tab.sensors.length;

    let diff = this.numSensor[idVariable] - currentSensorNum;

    if (diff < 0) {
      // Caso quitar sensores

      // Quitamos el signo negativo
      diff = diff * -1;

      tab.sensors.splice(this.numSensor[idVariable], diff);
    } else if (diff > 0) {
      // Caso añadir sensores
      for (let i = 0; i < diff; i++) {
        tab.sensors.push(new Sensor());
      }
    }
  }

  onEssayTabChange(event: MatTabChangeEvent | number): void {
    let index: number;

    if (event instanceof MatTabChangeEvent) {
      index = event.index;
    } else {
      index = event;
    }

    if (index > -1) {
      this.idConfig = this.data.essay.essayValues[index].idConfig;
    }

    const tab = this.data.essay.essayValues[index];

    this.variableUnits = [];

    if (tab != null && tab.idVariable != null) {
      this.variableTypeService.findUnits(tab.idVariable).pipe(takeUntil(this.destroy$)).subscribe((res: VariableUnit[]) => {
        this.variableUnits = res;

        if (tab.idUnit == null || !this.variableUnits.map(v => v.id).includes(tab.idUnit)) {
          this.onUnitChange(index, res[0]);
        }
      });
    }
  }

  onUnitChange(indexVariable: number, event: MatSelectChange | VariableUnit): void {

    const essay = this.data.essay.essayValues[indexVariable];

    let unit: VariableUnit;

    if (event instanceof MatSelectChange) {
      unit = this.variableUnits.find(v => v.id === event.value);
    } else {
      unit = event;
    }

    if (essay != null && unit != null) {
      essay.idUnit = unit.id;
      essay.unitName = unit.unit;

      this.data.essay.essayValues[indexVariable] = essay;
    }
  }

  showSensorsSection(variableSelected?: EssayProtocol): boolean {
    if (!this.idConfig) {
      return true;
    }

    if (variableSelected == null) {
      variableSelected = this.data.essay.essayValues.find(ev => ev.idConfig === this.idConfig);
    }

    if (variableSelected == null) {
      return true;
    }

    const variableWithoutSensors = [VariableTypeEnum.NOT_APPLICABLE];

    if (variableWithoutSensors.includes(variableSelected.idVariable)) {
      return false;
    }

    if (variableSelected.idTypeDependency == null) {
      return true;
    }

    let res = false;

    const withoutSensors = [DependencyTypeEnum.NONE];
    const withoutSensorsMandatory = [DependencyTypeEnum.MANDATORY];

    if (withoutSensors.includes(variableSelected.idTypeDependency)) {
      res = false;
    } else if (withoutSensorsMandatory.includes(variableSelected.idTypeDependency)) {
      res = true;
    } else if (variableSelected.idTypeDependency === DependencyTypeEnum.OPTIONAL) {
      const variableDepends = this.data.essay.essayValues.find(ev => ev.idConfig === variableSelected.idConfigDependsOn);

      res = variableDepends == null;

      if (!res) {
        const checkeds = [];

        this.checkedSameSensors.forEach((isChecked, idConfig) => {
          if (isChecked) {
            checkeds.push(idConfig);
          }
        });

        res = !checkeds.includes(variableSelected.idConfig);
      }

    }

    return res;
  }

  showOptionalSensorsCheck(): boolean {
    if (!this.idConfig) {
      return false;
    }

    const variableSelected = this.data.essay.essayValues.find(ev => ev.idConfig === this.idConfig);

    if (variableSelected == null || variableSelected.idTypeDependency == null) {
      return false;
    }

    const withoutSensors = [DependencyTypeEnum.OPTIONAL];

    let res = withoutSensors.includes(variableSelected.idTypeDependency);

    if (res) {
      const variableDepends = this.data.essay.essayValues.find(ev => ev.idConfig === variableSelected.idConfigDependsOn);

      res = variableDepends != null;
    }

    return res;
  }

  showOptionalSensorsSection(): boolean {
    if (!this.idConfig) {
      return true;
    }

    const variableSelected = this.data.essay.essayValues.find(ev => ev.id === this.idConfig);

    if (variableSelected == null || variableSelected.idTypeDependency == null) {
      return true;
    }

    const optionalSensors = [DependencyTypeEnum.OPTIONAL];

    let res = true;

    if (optionalSensors.includes(variableSelected.idTypeDependency)) {
      res = !this.checkedSameSensors.get(variableSelected.id);
    }

    return res;
  }

  onChangeCopySensorsCheck(event: { checked: boolean }): void {
    const variable = this.variablesPossibles.find(v => v.idConfig === this.idConfig);

    this.checkedSameSensors.set(variable.idConfig, event.checked);
  }

  isCheckedCopySensors(): boolean {
    const variable = this.variablesPossibles.find(v => v.idConfig === this.idConfig);

    return this.checkedSameSensors.get(variable.idConfig);
  }

  openNewCriteria(essayIndex: number): void {
    const essayValue = this.data.essay.essayValues[essayIndex];

    if (!essayValue.criterias) {
      essayValue.criterias = [];
    }

    let criteriaRemove = [];

    try {
      criteriaRemove = essayValue.criterias.map(crit => crit.idType);
    } catch (e) { }

    const dialogRef = this.dialog.open(ProtocolEditEssayCriteriaComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        criteria: new Criteria(),
        isEdit: false,
        idEssay: this.idConfig,
        disabled: this.data.disabled,
        criteriasRemove: criteriaRemove,
        showMkt: essayValue.idVariable === VariableTypeEnum.TEMPERATURE
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Criteria) => {

      if (result != null) {
        essayValue.criterias.push(result);

        this.updateTables();
      }
    });
  }

  editCriteria(essayIndex: number, criteriaIndex: number): void {
    const essayValue = this.data.essay.essayValues[essayIndex];

    let criteriaRemove = [];

    try {
      criteriaRemove = essayValue.criterias.map(crit => crit.idType);
    } catch (e) { }

    const criteria = essayValue.criterias[criteriaIndex];

    const dialogRef = this.dialog.open(ProtocolEditEssayCriteriaComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        criteria: _.cloneDeep(criteria),
        isEdit: true,
        idEssay: this.idConfig,
        disabled: this.data.disabled,
        criteriasRemove: criteriaRemove,
        showMkt: essayValue.idVariable === VariableTypeEnum.TEMPERATURE
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Criteria) => {

      if (result != null) {
        essayValue.criterias[criteriaIndex] = result;

        this.updateTables();
      }
    });
  }

  removeCriteria(essayIndex: number, criteriaIndex: number): void {

    this.data.essay.essayValues[essayIndex].criterias.splice(criteriaIndex, 1);

    this.updateTables();
  }

  updateTables(): void {
    this.criteriaTable.forEach(i => i.renderRows());
  }

  onOkClick(): void {
    const errors = [];

    const essay = this.data.essay;

    const withoutSensors = [DependencyTypeEnum.NONE];

    const optionalDependencies = [DependencyTypeEnum.OPTIONAL];
    const mandatoryDependencies = [DependencyTypeEnum.MANDATORY];

    essay.essayValues.forEach(essayVal => {

      if (mandatoryDependencies.includes(essayVal.idTypeDependency) ||
        (optionalDependencies.includes(essayVal.idTypeDependency) && this.checkedSameSensors.get(essayVal.idConfig))) {
        const variableDepends = essay.essayValues.find(v => v.idConfig === essayVal.idConfigDependsOn);

        if (variableDepends != null) {
          essayVal.sensors = [];
          variableDepends.sensors.forEach(sen => {
            const newSen = _.cloneDeep(sen);
            newSen.id = null;
            essayVal.sensors.push(newSen);
          });
        }
      }

      if (essayVal.idVariable !== VariableTypeEnum.TEMPERATURE) {
        essayVal.sensors.forEach(s => {
          s.hasBioindicator = false;
          s.bioindicatorSerialNum = null;
          s.hasEndotoxin = false;
          s.endotoxinSerialNum = null;
        });
      }

      if (!withoutSensors.includes(essayVal.idTypeDependency) && this.showSensorsSection(essayVal)
        && (essayVal.sensors == null || essayVal.sensors.length === 0)) {
        const essayName = this.translate.instant('variable.' + essayVal.translationVariable) as string;
        errors.push(this.translate.instant('protocolEdit.dialog.essay.form.error.anySensor', { name: essayName }));
      }

    });

    if (essay.idType == null) {
      errors.push(this.translate.instant('protocolEdit.dialog.essay.form.error.type'));
    } else {
      const values = essay.essayValues;

      if (ArrayUtils.isEmpty(values)) {
        errors.push(this.translate.instant('protocolEdit.dialog.essay.form.error.variable'));
      } else {
        values.forEach(item => {
          const valuesErr = this.checkValues(item);
          if (valuesErr != null && valuesErr.length !== 0) {
            valuesErr.forEach(i => errors.push(i));
          }
        });
      }
    }

    if (errors.length === 0) {

      const array = [];

      essay.essayValues.forEach(item => {

        if (item.criterias == null || item.criterias.length === 0) {
          this.criteriaTypeService.findByIdEssay(item.idConfig).pipe(takeUntil(this.destroy$)).subscribe((res: CriteriaType[]) => {

            if (res.map(i => i.id).includes(CriteriaTypeEnum.INFORMATIVE)) {
              const crit = new Criteria();
              crit.idType = CriteriaTypeEnum.INFORMATIVE;
              crit.criteriaTranslation = this.criteriaInformative.translation;
              item.criterias.push(crit);
            } else if (res.length === 1 && res[0].variablesToShow === 0) {
              const crit = new Criteria();
              crit.idType = res[0].id;
              crit.criteriaTranslation = res[0].translation;
              item.criterias.push(crit);
            }

            this.updateTables();
          });
        }
      });

      if (array.length === 0) {
        this.dialogRef.close(essay);

        return;
      }

      Promise.all(array.filter(p => p != null)).then(() => this.dialogRef.close(essay)).catch(() => this.dialogRef.close(essay));

    } else {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);
      console.error(error);
    }
  }

  public setValue(idVariable: number, item: Field, index: number, value: string | MatCheckboxChange | MatSelectionListChange | InputEvent)
    : void {
    const tab = this.data.essay.essayValues.find(i => i.idVariable === idVariable);

    const field = tab.fields.find(i => i.idField === item.idField);
    const fieldIndex = tab.fields.findIndex(i => i.idField === item.idField);

    if (value instanceof MatCheckboxChange) {
      value = value.checked.toString();
    } else if (value instanceof MatSelectionListChange) {
      value = value.source.selectedOptions.selected.map((i: MatListOption) => i.value as string).join(';');
    } else if (value instanceof InputEvent) {
      value = (value as any).srcElement.value as string;
    }

    field.setValue(index, value);

    tab.fields.splice(fieldIndex, 1, field);

    if (field.idField === FieldEnum.BIOINDICATORS) {
      if (value === 'true') {
        const sensors = tab.sensors;

        if (this.checkOpenBioindicatorDialog(sensors)) {
          this.openBioindicatorDialog(idVariable, sensors, 'bioindicator');
        } else {
          this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.essay.form.error.bioIndicatorSensor') as string);

          this.removeCheckBioindicators();
        }
      } else {
        tab.sensors.forEach(sen => sen.hasBioindicator = false);
      }
    }

    if (field.idField === FieldEnum.ENDOTOXINE) {
      if (value === 'true') {
        const sensors = tab.sensors;

        if (this.checkOpenBioindicatorDialog(sensors)) {
          this.openBioindicatorDialog(idVariable, sensors, 'endotoxin');
        } else {
          this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.essay.form.error.bioIndicatorSensor') as string);

          this.removeCheckBioindicators();
        }
      } else {
        tab.sensors.forEach(sen => sen.hasBioindicator = false);
      }
    }

  }

  isSelectedMulti(valuesStr: string | string[], valueCheck: string): boolean {
    if (valuesStr == null || valueCheck == null) {
      return false;
    }

    let values = [];
    if (typeof valuesStr === 'string') {
      values = valuesStr.split(';');
    } else {
      values = valuesStr;
    }

    return values.includes(valueCheck);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private checkOpenBioindicatorDialog(sensors: Sensor[]): boolean {
    let res = true;

    if (sensors == null || sensors.length === 0) {
      res = false;
    } else {
      sensors.forEach(s => {
        if (s.location == null || s.location === '') {
          res = false;
        }
      });
    }

    return res;
  }

  private openBioindicatorDialog(idVariable: number, sensors: Sensor[], type: string) {
    const tab = this.data.essay.essayValues.find(i => i.idVariable === idVariable);

    if (tab.bioindicatorsConfig == null) {
      tab.bioindicatorsConfig = new BioindicatorConfig();
      tab.bioindicatorsConfig.responsible = 1;
    }

    const dialogRef = this.dialog.open(ProtocolEditBioindicatorsDetailsComponent, {
      minWidth: '30%',
      maxHeight: '95vh',
      data: {
        sensors,
        responsible: tab.bioindicatorsConfig.responsible,
        type
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: DialogDataProtocolBioIndicators) => {

      if (result != null) {

        tab.sensors = result.sensors;

        if (type === 'bioindicator') {
          if (tab.bioindicatorsConfig == null) {
            tab.bioindicatorsConfig = new BioindicatorConfig();
          }

          tab.bioindicatorsConfig.responsible = result.responsible;
        }

        if (type === 'endotoxin') {
          if (tab.endotoxinsConfig == null) {
            tab.endotoxinsConfig = new BioindicatorConfig();
          }

          tab.endotoxinsConfig.responsible = result.responsible;
        }

      } else {
        this.removeCheckBioindicators();
      }
    });
  }

  private removeCheckBioindicators() {
    const tab = this.data.essay.essayValues.find(i => i.idVariable === VariableTypeEnum.TEMPERATURE);

    const field = tab.fields.find(i => i.idField === FieldEnum.BIOINDICATORS);

    this.setValue(tab.idVariable, field, -1, 'false');
    setTimeout(() => this.cdRef.markForCheck(), 100);
  }

  private variableHasImage(item: EssayProtocol): boolean {
    return item.idImage != null;
  }

  private checkValues(item: EssayProtocol): string[] {
    const errors: string[] = [];
    const variableName = this.translate.instant('variable.' + item.translationVariable) as string;

    item.fields.forEach(field => {
      if (field.required) {
        const valid = FieldUtils.validateField(field);

        if (!valid) {
          const fieldName = this.translate.instant('protocolEdit.dialog.essay.' + field.translationField + '.label') as string;
          errors.push(this.translate.instant('protocolEdit.dialog.essay.form.error.field',
            { fieldName, variable: variableName }) as string);
        }
      }
    });

    item.sensors.forEach((sensor, index) => {
      if (StringUtils.isEmpty(sensor.location)) {
        if (!this.variableHasImage(item)) {
          errors.push(this.translate.instant('protocolEdit.dialog.essay.form.error.sensorLocation',
            { index: (index + 1), variable: variableName }) as string);
        } else {
          sensor.location = this.translate.instant('protocolEdit.dialog.essay.sensors.defaultLocation') as string;
        }
      }
    });

    if (item.percentSensorsCanFail == null) {
      errors.push(this.translate.instant('protocolEdit.dialog.essay.form.error.percentCanFail', { variable: variableName }) as string);
    }

    return errors;
  }

  private markVariableCheck(value: Variable) {
    const essayProtocol = new EssayProtocol();
    essayProtocol.idConfig = value.idConfig;
    essayProtocol.idVariable = value.id;
    essayProtocol.translationVariable = value.translation;

    essayProtocol.sensors = [];
    essayProtocol.fields = [];
    essayProtocol.criterias = [];
    essayProtocol.percentSensorsCanFail = 0;
    essayProtocol.idConfigDependsOn = value.idConfigDependsOn;
    essayProtocol.idConfig = value.idConfig;
    essayProtocol.idTypeDependency = value.idTypeDependency;

    if (this.idConfig == null) {
      this.idConfig = value.idConfig;
    }

    this.essayService.findFieldsById(value.idConfig).pipe(takeUntil(this.destroy$))
      .subscribe((item: Field[]) => essayProtocol.fields = item.map(i => FieldUtils.objectToField(i)));

    this.data.essay.essayValues.push(essayProtocol);

    this.checked.set(value.id, true);
    this.data.essay.essayValues.sort((a, b) => a.idVariable < b.idVariable ? -1 : a.idVariable > b.idVariable ? 1 : 0);

  }

}
