<h1 mat-dialog-title>{{ 'isothermalCharacterizationEdit.dialog.confirmSave.title' | translate }}</h1>
<form [formGroup]="form">
  <div mat-dialog-content class="calibrates-theme">

    <mat-form-field class="campoFormulario" *ngIf="data.showReason">
      <textarea matInput [(ngModel)]="data.reason" formControlName="reason" [errorStateMatcher]="matcher" required
        placeholder="{{ 'isothermalCharacterizationEdit.dialog.confirmSave.reason.label' | translate }}"></textarea>
      <mat-error>
        {{ 'isothermalCharacterizationEdit.dialog.confirmSave.reason.error' | translate }}
      </mat-error>
    </mat-form-field>

  </div>
  <div mat-dialog-actions class="calibrates-theme">
    <div fxFlex></div>

    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>

    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>