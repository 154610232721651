import { Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
})
export class AutoLogOutService {

    private userActionOccuredSubject: Subject<void> = new Subject();

    constructor(private loginService: LoginService) { }

    get userActionOccured(): Observable<void> {
        return this.userActionOccuredSubject.asObservable();
    }

    notifyUserAction(): void {
        this.userActionOccuredSubject.next();
    }

    logOutUser(): void {
        this.loginService.logout();
    }

}
