export class AccessoryEquipment {
    id: number;

    idEquipment: number;

    idType: number;
    idTypeProtocol: number;

    others: string;
    othersProtocol: string;
    translation: string;

    fromProtocol: boolean;
    reasonChange: string;
    showDiffInDeviations = true;
    showDiffInObservations: boolean;
}

export enum AccessoryEquipmentEnum {
    UV_LAMP = 1,
    OTHERS = 5
}
