import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as _ from 'lodash-es';
import { SensorData, SensorDataGraph } from 'src/app/model/sensorData';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { DateUtils } from 'src/app/utils/dateUtils';
import { EssayUtils } from 'src/app/utils/essayUtils';
import { Essay } from 'src/app/model/essay';
import { MatSelectChange } from '@angular/material/select';
import { SpinnerService } from 'src/app/services/spinner.service';

export interface DialogDataCopyEssay {
  essays: Essay[];
}

export interface DialogDataCopyEssayResult {
  idEssay: number;
  dateFrom: Date;
  dateTo: Date;
}

@Component({
  selector: 'app-execution-edit-copy-essay',
  templateUrl: './execution-edit-copy-essay.component.html'
})
export class ExecutionEditCopyEssayComponent {

  essayIdToCopy: number;

  startDateStr: string;
  endDateStr: string;

  startDateRange: Date;
  endDateRange: Date;

  sensorData: SensorData[];
  datasource: SensorDataGraph[];
  sensors: any[];

  constructor(
    private translate: TranslateService,
    private dialogRef: MatDialogRef<ExecutionEditCopyEssayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCopyEssay,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    this.onEssayChange(0);
  }

  onOkClick(): void {
    const errs = [];

    if (this.startDateRange == null) {
      errs.push(this.translate.instant('executionEdit.events.form.error.startDate'));
    }
    if (this.endDateRange == null) {
      errs.push(this.translate.instant('executionEdit.events.form.error.endDate'));
    }

    if (errs.length === 0) {
      const res: DialogDataCopyEssayResult = { idEssay: this.essayIdToCopy, dateFrom: this.startDateRange, dateTo: this.endDateRange };
      this.dialogRef.close(res);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onEssayChange(event: number | MatSelectChange): void {

    let index: number = null;
    if (event instanceof MatSelectChange) {
      index = this.data.essays.findIndex(e => e.id === event.source.value);
    } else {
      index = event;
    }

    const emptyList: SensorData[] = [];
    const essay = this.data.essays[index];

    this.essayIdToCopy = essay.id;
    this.sensorData = emptyList.concat(...essay.essayValues[0].sensors.map(s => s.data));

    this.startDateRange = _.cloneDeep(this.sensorData.sort((a, b) => a.date.getTime() - b.date.getTime()).find(i => i.date != null).date);
    this.endDateRange = _.cloneDeep(DateUtils.anyToDate(this.startDateRange));
    this.endDateRange.setHours(this.endDateRange.getHours() + 1);

    if (this.startDateStr == null || this.startDateStr === '') {
      this.convertDateToString(this.startDateRange, this.endDateRange);
    }

    this.recalculateDatasource(this.sensorData);

    this.sensorData.forEach(item => item.date = DateUtils.anyToDate(item.date));
  }


  recalculateDatasource(data: SensorData[]): void {
    this.spinnerService.show();

    if (data == null || ArrayUtils.isEmpty(data)) {
      this.datasource = [];
      this.sensors = [];

      return;
    }

    const res: SensorDataGraph[] = [];
    const sensors = [];

    const groupBy = ArrayUtils.groupBy(data, (item: SensorData) => item.date);
    groupBy.forEach((values: SensorData[], key: Date) => {
      values.forEach(value => {
        const sen = new SensorDataGraph();
        sen.date = key;

        if (!value.equipmentName) {
          return;
        }

        const beautySerialNum = value.equipmentName.replace('-', '').replace(' ', '').toLowerCase();

        sen[beautySerialNum] = value.value;

        const currentSensors = sensors.map((item: { name: string }) => item.name);

        if (!currentSensors.includes(value.equipmentName)) {

          sensors.push({
            name: value.equipmentName,
            serialNum: value.serialNum,
            value: beautySerialNum
          });
        }

        res.push(sen);
      });
    });

    this.datasource = res;
    this.sensors = sensors;

    this.spinnerService.hide();
  }

  onValueChanged = (e) => {
    const changedInit = e.value[0].getTime() !== e.previousValue[0].getTime();
    const changedEnd = e.value[1].getTime() !== e.previousValue[1].getTime();

    let initDate = DateUtils.anyToDate(e.value[0]);
    let endDate = DateUtils.anyToDate(e.value[1]);

    if (changedInit) {
      initDate = EssayUtils.calculateNearDate(initDate, this.sensorData);
    }

    if (changedEnd) {
      endDate = EssayUtils.calculateNearDate(endDate, this.sensorData);
    }

    this.startDateRange = _.cloneDeep(initDate);
    this.endDateRange = _.cloneDeep(endDate);

    this.convertDateToString(initDate, endDate);
  }

  customizeLabel = (data) => {
    return data.value.toLocaleString() as string;
  }

  customizeSliderMarker = (data) => {
    return data.value.toLocaleString() as string;
  }

  private convertDateToString(startDate: Date, endDate: Date): void {
    try {
      if (startDate == null) {
        this.startDateStr = null;
      } else {
        const date = startDate;
        const start = moment(date).format('DD/MM/YYYY HH:mm:ss');
        this.startDateStr = start;
      }
    } catch (e) {
      this.startDateStr = null;
    }

    try {
      if (endDate == null) {
        this.startDateStr = null;
      } else {
        const date = endDate;
        const end = moment(date).format('DD/MM/YYYY HH:mm:ss');
        this.endDateStr = end;
      }
    } catch (e) {
      this.startDateStr = null;
    }
  }

}
