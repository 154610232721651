import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccessoryFilterType } from 'src/app/model/accessoryFilterType';
import { EquipmentType } from 'src/app/model/equipmentType';
import { FilterAir, FilterAirTypeEnum, ShapeEnum } from 'src/app/model/filterAir';
import { FilterAirType } from 'src/app/model/filterAirType';
import { RoomAir } from 'src/app/model/roomAir';
import { Volume } from 'src/app/model/volume';
import { AccessoryFilterTypeService } from 'src/app/services/accessoryFilterType.service';
import { FilterAirTypeService } from 'src/app/services/filterAirType.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { AccesoryFilterUtils } from 'src/app/utils/accesoryFilterUtils';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { StringUtils } from 'src/app/utils/stringUtils';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { ProtocolEditDialogFilterComponent } from './protocol-edit-dialog-filter.component';

export interface DialogDataEquipmentAir {
  roomTypes: EquipmentType[];
  room: RoomAir;
  isEdit: boolean;
  isExecution: boolean;
  idExecution: number;
}

@Component({
  selector: 'app-protocol-edit-dialog-room-air',
  templateUrl: './protocol-edit-dialog-room-air.component.html'
})
export class ProtocolEditDialogRoomAirComponent implements OnInit {

  @ViewChildren('filtersTable') filtersTable: QueryList<MatTable<any>>;

  filterAirTypes: FilterAirType[];
  accessoryFilterTypes: AccessoryFilterType[];

  public displayedColsFilter = ['order', 'filter', 'size', 'changeFilter', 'edit', 'delete'];

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    public dialog: MatDialog,
    private filterAirTypeService: FilterAirTypeService,
    private accessoryFilterTypeService: AccessoryFilterTypeService,
    private dialogRef: MatDialogRef<ProtocolEditDialogRoomAirComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataEquipmentAir) {

    if (this.data.isExecution == null) {
      this.data.isExecution = false;
    }

    if (!this.data.isExecution) {
      this.displayedColsFilter = this.displayedColsFilter.filter(a => a !== 'changeFilter');
    }

    if (ArrayUtils.isNotEmpty(this.data.room.filters)) {
      this.data.room.filters = this.data.room.filters.sort((f1, f2) => f1.id - f2.id);
    }

  }

  ngOnInit(): void {
    this.filterAirTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: FilterAirType[]) => this.filterAirTypes = res);
    this.accessoryFilterTypeService.findAll().pipe(takeUntil(this.destroy$))
      .subscribe((res: AccessoryFilterType[]) => {
        this.accessoryFilterTypes = res;
        this.data.room.filters.forEach(filter => {
          if (filter.filterName == null) {
            filter.filterName = this.setFilterName(filter);
          }
        });
      });

    if (ArrayUtils.isEmpty(this.data.room.volumes)) {
      this.data.room.volumes = [];

      this.newValue();
    }
  }

  onOkClick(): void {
    const errs = this.validateForm();
    if (errs.length === 0) {
      this.dialogRef.close(this.data.room);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  newValue(): void {
    this.data.room.volumes.push(new Volume());
  }

  removeVolume(index: number): void {
    this.data.room.volumes.splice(index, 1);
  }

  openNewFilter(): void {
    const filter = new FilterAir();
    filter.idType = FilterAirTypeEnum.FILTER;
    filter.shape = ShapeEnum.RECTANGULAR;

    const dialogRef = this.dialog.open(ProtocolEditDialogFilterComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        accessoryFilterTypes: this.accessoryFilterTypes,
        filterTypes: this.filterAirTypes,
        filter,
        isEdit: false,
        isExecution: this.data.isExecution,
        idExecution: this.data.idExecution
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res: FilterAir) => {

      if (res != null) {
        res.filterName = this.setFilterName(res);
        this.data.room.filters.push(res);
        this.updateTables();
      }
    });
  }

  editFilter(index: number): void {
    const filter = this.data.room.filters[index];

    const dialogRef = this.dialog.open(ProtocolEditDialogFilterComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        accessoryFilterTypes: this.accessoryFilterTypes,
        filterTypes: this.filterAirTypes,
        filter: _.cloneDeep(filter),
        isEdit: true,
        isExecution: this.data.isExecution,
        idExecution: this.data.idExecution
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res: FilterAir) => {

      if (res != null) {
        res.filterName = this.setFilterName(res);
        this.data.room.filters[index] = res;
        this.updateTables();
      }
    });
  }

  removeFilter(index: number): void {
    const message = this.translate.instant('protocolEdit.dialog.filter.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (index > -1) {
          this.data.room.filters.splice(index, 1);
        }
        this.updateTables();
      }
    });
  }

  getOrderFilter(index: number): string {
    const filters = this.data.room.filters;

    let res = 0;

    for (let i = 0; i <= index; i++) {
      const filter = filters[i];

      if (filter.totalFiltersSecondStage > 1) {
        if (i === index) {
          return (res + 1).toString().concat('-').concat((index + filter.totalFiltersSecondStage).toString());
        }
        res += filter.totalFiltersSecondStage;
      } else {
        res++;
      }
    }

    return res.toString();
  }

  getFilterName(index: number): string {
    const filter = this.data.room.filters[index];

    if (StringUtils.isEmpty(filter.filterName)) {
      filter.filterName = this.setFilterName(filter);
    }

    return filter.filterName;
  }

  validateForm(): string[] {
    const errs: string[] = [];

    this.data.room.volumes.forEach(volume => {
      if (volume.height < 0 || volume.surface < 0) {
        errs.push(this.translate.instant('common.roomAir.negativeVolume.error') as string);
      }
    });

    if (this.data.room.idType == null) {
      errs.push(this.translate.instant('common.roomAir.idType.error') as string);
    }
    if (this.data.room.name == null) {
      errs.push(this.translate.instant('common.roomAir.name.error') as string);
    }
    this.data.room.volumes.forEach(volume => {
      if (volume.height == null || volume.surface == null) {
        errs.push(this.translate.instant('common.roomAir.volume.error') as string);
      }
    })

    return errs;
  }

  updateTables(): void {
    this.filtersTable.forEach(i => i.renderRows());
  }

  getSizeOfFilter(filter: FilterAir): string {
    return AccesoryFilterUtils.getSizeOfFilter(filter);
  }

  getChangeFilter(filter: FilterAir): string {
    return StringUtils.isNotEmpty(filter?.maker) ? `${filter.maker} (${filter.serialNum})`
      : this.translate.instant('common.notApplicable') as string;
  }

  showVolume(volume: Volume): number {
    if (volume.height != null && volume.surface != null) {
      return volume.height * volume.surface;
    }
    return 0;
  }

  showReason(): boolean {
    const room = this.data.room;

    return this.data.isExecution && room.fromProtocol && (room.idType !== room.idTypeProtocol || room.name !== room.nameProtocol);
  }

  private setFilterName(filter: FilterAir): string {
    let res: string = null;

    if (filter.idTypeFilter != null) {
      const type = this.accessoryFilterTypes?.find(a => a.id === filter.idTypeFilter);

      if (type != null) {
        res = 'filterType.' + type.translation;
      }
    } else {
      const type = this.filterAirTypes.find(a => a.id === filter.idType);
      if (type != null) {
        res = 'filterType.' + type.translation;
      }
    }

    return res;
  }

}