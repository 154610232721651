<h1 mat-dialog-title>{{ 'executionEdit.dialog.alreadyExistsExecution.title' | translate }}</h1>
<form>

    <div mat-dialog-content class="thermal-theme">

        {{ 'executionEdit.dialog.alreadyExistsExecution.text'
        | translate: { documentCode: data.execution.documentCode } }}

    </div>

    <br /><br />

    <div mat-dialog-actions class="thermal-theme">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()" cdkFocusInitial>
            {{ 'button.create' | translate }}</button>
        <div fxFlex="5px"></div>
        <button mat-raised-button color="accent" (click)="redirectClick()">{{ 'button.redirect' | translate }}</button>
        <div fxFlex="5px"></div>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>

</form>