import { GenericFilter, GenericClass } from './genericClass';

export class Procedure extends GenericClass {

    description: string;
}

export class ProcedureFilter extends GenericFilter {

    name: string;
    description: string;
}
