export class CreatePayment {
    shortCompanyName: string;
    companyName: string;

    addressCompany: string;
    cifCompany: string;
    postalCodeCompany: string;
    cityCompany: string;
    stateCompany: string;
    countryCompany: string;

    idPlan: number;

    managerName: string;
    managerSurname: string;
    managerEmail: string;

    token: string;

    get fullName(): string {
        return [this.managerName, this.managerSurname].join(' ');
    }
}

export class PaymentComplete {
    shortCompanyName: string;
    companyName: string;

    companyCif: string;
    companyAddress: string;
    companyPostalCode: string;
    companyCity: string;
    companyCountry: string;

    managerUsername: string;
    managerFullname: string;
    managerEmail: string;

    planId: number;
    planName: string;

    initialPayment: number;
    monthlyPayment: number;

    nextInvoice: Date;
}

export class Subscription {
    id: string;
    name: string;
    nextInvoiceDate: Date;
    subscriptionEndDate: Date;
    amount: number;
}

export class PaymentIntent {
    id: string;
    name: string;
    amount: number;
    date: Date;
    downloadUrl: string;
}

export class Invoice {
    id: string;
    date: Date;
    total: number;
    downloadUrl: string;
}
