<h1 mat-dialog-title>{{ 'isothermalCharacterizationEdit.validation.title' | translate }}</h1>
<form [formGroup]="mainForm">

  <div mat-dialog-content class="calibrates-theme" style="min-height: 10vh;">

    <div *ngIf="showReason()">

      <p> {{ 'isothermalCharacterizationEdit.dialog.confirmSave.reason.label' | translate }} </p>

      <mat-form-field class="campoFormulario" style="width: 100%">
        <textarea rows="5" matAutosizeMinRows="5" matInput [(ngModel)]="reason" formControlName="reason" required
          [errorStateMatcher]="matcher" style="resize: none;"
          placeholder="{{ 'isothermalCharacterizationEdit.dialog.confirmSave.reason.label' | translate }}"></textarea>
        <mat-error>
          {{ 'isothermalCharacterizationEdit.dialog.confirmSave.reason.error' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="!isEmpty(warns)">
      <p> {{ 'isothermalCharacterizationEdit.validation.hasWarns' | translate }} </p>

      <ul>
        <li *ngFor="let warn of warns"> {{ warn }} </li>
      </ul>
    </div>

    <div *ngIf="!isEmpty(errors)">

      <p> {{ 'isothermalCharacterizationEdit.validation.hasErrors' | translate }} </p>

      <ul>
        <li *ngFor="let error of errors"> {{ error }} </li>
      </ul>

    </div>

    <div *ngIf="!isEmpty(warns)">
      <p> {{ 'isothermalCharacterizationEdit.validation.hasWarns' | translate }} </p>

      <ul>
        <li *ngFor="let warn of warns"> {{ warn }} </li>
      </ul>

      <div *ngIf="isEmpty(errors)">
        <p> {{ 'isothermalCharacterizationEdit.validation.invalid' | translate }} </p>

        <mat-radio-group [(ngModel)]="forceAccording" formControlName="forceAccording">
          <mat-radio-button [value]="true">{{'common.yes' | translate}}</mat-radio-button>
          <mat-radio-button [value]="false">{{'common.no' | translate}}</mat-radio-button>
        </mat-radio-group>

        <mat-form-field class="campoFormulario" style="width: 100%" *ngIf="forceAccording == false">
          <textarea rows="5" matAutosizeMinRows="5" matInput placeholder="{{ 'common.reason' | translate }}" [(ngModel)]="reasonForce"
            formControlName="reasonForce" required [errorStateMatcher]="matcher" style="resize: none;"></textarea>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="isEmpty(errors) && isEmpty(warns)">
      <p> {{ 'isothermalCharacterizationEdit.validation.valid' | translate }} </p>
    </div>

    <div *ngIf="canDownloadDocument() && isEmpty(errors)">
      <button mat-raised-button (click)="downloadDocument()" color="accent">
        {{ 'button.download-document-to-sign' | translate }}
      </button>

    </div>

  </div>

  <div mat-dialog-actions class="calibrates-theme">
    <div fxFlex></div>
    <button mat-raised-button (click)="uploadDocument.click()" *ngIf="canDownloadDocument() && isEmpty(errors)"
      color="primary">{{ 'button.upload-signed-document' | translate }}</button>
    <input accept="application/pdf" hidden type="file" (change)="uploadDocumentToIsothermalCharacterization($event)"
      #uploadDocument>

    <button mat-raised-button color="primary" (click)="onOkClick()" [disabled]="!canAccept()" cdkFocusInitial
      *ngIf="isEmpty(errors) && !canDownloadDocument()">{{ 'button.accept' | translate }}</button>

    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>