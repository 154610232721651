import { EssayExecutionAirResultParticles, EssayExecutionResultAir } from './essayExecutionResultAir';

import { Criteria } from './criteria';
import { EssayExecutionAirInternalEquipment } from './essayExecutionAirInternalEquipment';
import { Field } from './field';
import { EssayFile } from './essayFile';

export class EssayProtocolAir {
    id: number;

    idEssayType: number;
    idEquipment: number;
    idRoom: number;
    idCompressedGas: number;
    idUnit: number;
    notes: string;
    equipmentNA: boolean;
    according: boolean;
    idOffline: string;

    regUser: string;
    modUser: string;

    regFc: Date;
    modFc: Date;

    fromProtocol = false;
    reasonChange: string;

    criterias: Criteria[] = [];

    essayValues: Field[] = [];

    values: EssayExecutionResultAir[] = [];
    equipments: EssayExecutionAirInternalEquipment[] = [];

    valuesParticles: EssayExecutionAirResultParticles[] = [];

    idVideo: number;
    idCertificate: number;

    idExcel: number;
    reasonChangeExcel: string;

    active = true;
    reasonActive: string;

    showDiffInDeviations = true;
    showDiffInObservations: boolean;

    forceAccording = false;

    essayFile: EssayFile;
}
