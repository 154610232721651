<h1 mat-dialog-title>{{ data.title | translate }}</h1>

<div mat-dialog-content [class]="getTheme()">

  <div fxLayout="row" class="flexwrap">

    <div fxFlex="100">
      <textarea rows="5" matAutosizeMinRows="5" matInput placeholder="{{ data.title | translate }}"
        [(ngModel)]="data.reason" name="reason" required style="resize: none;"></textarea>
    </div>

  </div>

</div>

<div mat-dialog-actions align="end" [class]="getTheme()">
  <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
  <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
</div>