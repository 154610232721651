<form [formGroup]="form">
    <mat-accordion>
        <mat-expansion-panel [expanded]="expandedFilter" (opened)="expandedFilter = true"
            (closed)="expandedFilter = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'protocolList.filter.title' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <input matInput placeholder="{{ 'protocolList.filter.documentCode.label' | translate }}"
                            formControlName="documentCode" [(ngModel)]="filter.documentCode">
                    </mat-form-field>
                </div>

                <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <input matInput placeholder="{{ 'protocolList.filter.projectNo.label' | translate }}"
                            formControlName="projectNo" [(ngModel)]="filter.projectNo">
                    </mat-form-field>
                </div>

                <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <mat-label>{{ 'protocolList.filter.client.label' | translate }}</mat-label>
                        <mat-select #clientSelect formControlName="idClient" [(ngModel)]="filter.idClient">
                            <mat-select-filter *ngIf="clientSelect.focused" [displayMember]="'name'" [array]="clients"
                                (filteredReturn)="clientsFiltered =$event"
                                [noResultsMessage]="'common.noResults' | translate"
                                [placeholder]="'common.selectFilterPlaceholder' | translate"></mat-select-filter>
                            <mat-option *ngFor="let client of clientsFiltered" [value]="client.id">
                                {{client.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <input matInput placeholder="{{ 'protocolList.filter.equipment.label' | translate }}"
                            formControlName="equipment" [(ngModel)]="filter.equipment">
                    </mat-form-field>
                </div>
            </div>

            <mat-accordion>
                <mat-expansion-panel expanded="false" class="filter-not-border">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ 'protocolList.filter.advanced.title' | translate }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="campoFormulario">
                                <input matInput
                                    placeholder="{{ 'protocolList.filter.equipmentSerialNum.label' | translate }}"
                                    formControlName="equipmentSerialNum" [(ngModel)]="filter.equipmentSerialNum">
                            </mat-form-field>
                        </div>

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="campoFormulario">
                                <input matInput
                                    placeholder="{{ 'protocolList.filter.equipmentInternalId.label' | translate }}"
                                    formControlName="equipmentInternalId" [(ngModel)]="filter.equipmentInternalId">
                            </mat-form-field>
                        </div>

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="campoFormulario">
                                <mat-label>{{ 'protocolList.filter.projectManager.label' | translate }}</mat-label>
                                <mat-select formControlName="projectManager" [(ngModel)]="filter.projectManager">
                                    <mat-option [value]="null"> --- </mat-option>
                                    <mat-option *ngFor="let item of projectManagers" [value]="item.username">
                                        {{item.fullName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="campoFormulario">
                                <input matInput
                                    placeholder="{{ 'protocolList.filter.clientDocumentCode.label' | translate }}"
                                    formControlName="clientDocumentCode" [(ngModel)]="filter.clientDocumentCode">
                            </mat-form-field>
                        </div>

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="campoFormulario">
                                <mat-label>{{ 'protocolList.filter.process.label' | translate }}</mat-label>
                                <mat-select formControlName="idProcess" [(ngModel)]="filter.idProcess">
                                    <mat-option [value]="null"> --- </mat-option>
                                    <mat-option *ngFor="let item of processes" [value]="item.id">
                                        {{ 'processType.' + item.translation | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="campoFormulario">
                                <mat-label>{{ 'protocolList.filter.status.label' | translate }}</mat-label>
                                <mat-select formControlName="idStatus" [(ngModel)]="filter.idStatus"
                                    [disabled]="isList === false">
                                    <mat-option [value]="null"> --- </mat-option>
                                    <mat-option *ngFor="let item of statuses" [value]="item.id">
                                        {{ 'protocolStatus.' + item.translation | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="datepicker">
                                <input matInput [matDatepicker]="regFcStartPicker" formControlName="regFcStart"
                                    [ngModel]="filter.regFcStart" (blur)="saveDate('regFcStart', $event)"
                                    (dateChange)="saveDate('regFcStart', $event)"
                                    placeholder="{{ 'protocolList.filter.regFcStart.label' | translate }}">
                                <mat-datepicker-toggle matSuffix [for]="regFcStartPicker"></mat-datepicker-toggle>
                                <mat-datepicker #regFcStartPicker [calendarHeaderComponent]="customDatepickerHeader">
                                </mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="datepicker">
                                <input matInput [matDatepicker]="regFcEndPicker" formControlName="regFcEnd"
                                    [ngModel]="filter.regFcEnd" (blur)="saveDate('regFcEnd', $event)"
                                    (dateChange)="saveDate('regFcEnd', $event)"
                                    placeholder="{{ 'protocolList.filter.regFcEnd.label' | translate }}">
                                <mat-datepicker-toggle matSuffix [for]="regFcEndPicker"></mat-datepicker-toggle>
                                <mat-datepicker #regFcEndPicker [calendarHeaderComponent]="customDatepickerHeader">
                                </mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="datepicker">
                                <input matInput [matDatepicker]="modFcStartPicker" formControlName="modFcStart"
                                    [ngModel]="filter.modFcStart" (blur)="saveDate('modFcStart', $event)"
                                    (dateChange)="saveDate('modFcStart', $event)"
                                    placeholder="{{ 'protocolList.filter.modFcStart.label' | translate }}">
                                <mat-datepicker-toggle matSuffix [for]="modFcStartPicker"></mat-datepicker-toggle>
                                <mat-datepicker #modFcStartPicker [calendarHeaderComponent]="customDatepickerHeader">
                                </mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="datepicker">
                                <input matInput [matDatepicker]="modFcEndPicker" formControlName="modFcEnd"
                                    [ngModel]="filter.modFcEnd" (blur)="saveDate('modFcEnd', $event)"
                                    (dateChange)="saveDate('modFcEnd', $event)"
                                    placeholder="{{ 'protocolList.filter.modFcEnd.label' | translate }}">
                                <mat-datepicker-toggle matSuffix [for]="modFcEndPicker"></mat-datepicker-toggle>
                                <mat-datepicker #modFcEndPicker [calendarHeaderComponent]="customDatepickerHeader">
                                </mat-datepicker>
                            </mat-form-field>
                        </div>                        

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="campoFormulario">
                                <input matInput formControlName="equipmentMaker" [(ngModel)]="filter.equipmentMaker"
                                    placeholder="{{ 'protocolList.filter.equipmentMaker.label' | translate }}">
                            </mat-form-field>
                        </div>

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="campoFormulario">
                                <input matInput formControlName="equipmentModel" [(ngModel)]="filter.equipmentModel"
                                    placeholder="{{ 'protocolList.filter.equipmentModel.label' | translate }}">
                            </mat-form-field>
                        </div>

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="campoFormulario">
                                <input matInput formControlName="equipmentLocation"
                                    [(ngModel)]="filter.equipmentLocation"
                                    placeholder="{{ 'protocolList.filter.equipmentLocation.label' | translate }}">
                            </mat-form-field>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
                <button mat-raised-button (click)="doFilter()" color="primary">{{ 'button.filter' | translate
                    }}</button>
                <button mat-button (click)="cleanFilter()">{{ 'button.clean' | translate }}</button>
            </div>

        </mat-expansion-panel>
    </mat-accordion>
</form>