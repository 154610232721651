import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Criteria } from 'src/app/model/criteria';
import { CriteriaType } from 'src/app/model/criteriaType';
import { CriteriaTypeService } from 'src/app/services/criteriaType.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';

export interface DialogDataCriteriaEdit {
  idEssay: number;
  idEssayConfig: number;
  criteriasRemove: number[];
  criteria: Criteria;
  isNew: boolean;
  showMkt: boolean;
}

@Component({
  selector: 'app-execution-edit-criteria-edit',
  templateUrl: './execution-edit-criteria-edit.component.html'
})
export class ExecutionEditCriteriaEditComponent implements OnInit, OnDestroy {

  criterias: CriteriaType[];
  criteriaSelected: CriteriaType;
  criteriaSelectedProtocol: CriteriaType;

  private destroy$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<ExecutionEditCriteriaEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCriteriaEdit,
    private criteriaTypeService: CriteriaTypeService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.spinnerService.show();
    this.data.criteriasRemove.splice(this.data.criteriasRemove.indexOf(this.data.criteria.idType), 1);

    this.criterias = [];
    this.criteriaSelected = null;
    this.criteriaSelectedProtocol = null;

    this.criteriaTypeService.findByIdEssay(this.data.idEssayConfig).pipe(takeUntil(this.destroy$)).subscribe((res: CriteriaType[]) => {
      this.spinnerService.hide();

      res = res.filter(crit => this.data.criteriasRemove.indexOf(crit.id) < 0);

      this.criterias = res;

      if (ArrayUtils.isEmpty(this.criterias)) {
        this.onNoClick();
        return;
      }

      const criteria = this.criterias.find(a => a.id === this.data.criteria.idType) || this.criterias[0];
      this.criteriaSelected = criteria;

      if (this.data.criteria.fromProtocol) {
        const criteriaProtocol = this.criterias.find(a => a.id === this.data.criteria.idTypeProtocol);
        this.criteriaSelectedProtocol = criteriaProtocol;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCriteriaChange(event: MatSelectChange): void {
    const criteria = this.criterias.find(a => a.id === event.value);

    this.criteriaSelected = criteria;
    this.data.criteria.idType = event.value as number;
    this.data.criteria.criteriaTranslation = criteria.translation;
  }

  showReason(): boolean {
    return this.data.criteria.idType !== this.data.criteria.idTypeProtocol
      || this.data.criteria.criteriaValue1 !== this.data.criteria.criteriaValueProtocol1
      || this.data.criteria.criteriaValue2 !== this.data.criteria.criteriaValueProtocol2;
  }

  onOkClick(): void {
    const errs = [];

    const criteria = this.data.criteria;

    if (!this.data.isNew && this.data.criteria.fromProtocol) {
      if (criteria.idType === criteria.idTypeProtocol && criteria.criteriaValue1 === criteria.criteriaValueProtocol1
        && criteria.criteriaValue2 === criteria.criteriaValueProtocol2) {
        criteria.criteriaValueChangeReason = null;
      } else if (criteria.criteriaValueChangeReason == null || criteria.criteriaValueChangeReason === '') {
        errs.push(this.translate.instant('common.reasonChangeError'));
      }
    }

    if (errs.length === 0) {
      this.dialogRef.close(criteria);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
