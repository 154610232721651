<div fxLayout="row" class="flexwrap">

    <div fxFlex="100%" fxFlex.lt-md="100%">
      {{ 'procedure.title' | translate }}
    </div>

    <mat-form-field class="campoFormulario">
        <input matInput [(ngModel)]="data.procedure.name" name="name" required
        placeholder="{{ 'procedure.dialog.name.label' | translate }}">
      <mat-error>
        {{ 'procedureEdit.dialog.name.error' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="campoFormulario">
        <input matInput [(ngModel)]="data.procedure.description" name="description" required
        placeholder="{{ 'procedure.dialog.description.label' | translate }}">
      <mat-error>
        {{ 'procedureEdit.dialog.description.error' | translate }}
      </mat-error>
    </mat-form-field>
  
    <div fxFlex="100%" fxFlex.lt-md="100%" style="margin-top: 1.5em">
  
      <div fxFlex="5px"></div>
  
      <button mat-raised-button color="primary"
        (click)="save()">{{ 'button.save' | translate }}</button>
  
      <div fxFlex="5px"></div>
  
      <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>
  
  </div>