import { ArrayUtils } from './arrayUtils';
import { EquipmentAir } from '../model/equipment';
import { EquipmentTypeAirEnum } from '../model/equipmentType';
import { EssayAirTypeEnum } from '../model/essayType';
import { EssayProtocolAir } from '../model/essayProtocolAir';
import { ExecutionAirUtils } from './executionAirUtils';
import { FilterAir } from '../model/filterAir';
import { RoomAir } from '../model/roomAir';
import { StringUtils } from './stringUtils';
import { Volume } from '../model/volume';

export enum SpeedTypeEnum {
  AVERAGE_SPEED = 16,
  FRONTAL_SPEED = 17,
  GLOVE_SPEED = 18,
  EXTRACTION_SPEED = 19
}

export class AirUtils {

  private static mapSpeedTypeCriteria: Map<number, number[]> = null;

  private static mapOrderEssay: Map<number, number> = null;

  static showEquipment(eq: EquipmentAir): string {
    const arr: string[] = [];

    arr.push(eq.maker);
    arr.push(eq.model);
    arr.push(`(${eq.serialNum})`);

    if (StringUtils.isNotEmpty(eq.inventoryNum)) {
      arr.push(`(${eq.inventoryNum})`);
    }

    return arr.join(' ');
  }

  static checkAccordingEssay(essay: EssayProtocolAir, volume: number, filters: FilterAir[], eqType: number, isCompressedGas: boolean)
    : boolean {
    let accordingEssay = true;

    if (essay.forceAccording) {
      return true;
    }

    const criteriaSimpleEssays = [
      EssayAirTypeEnum.NOISE, EssayAirTypeEnum.LIGHT, EssayAirTypeEnum.UV_INTENSITY,
      EssayAirTypeEnum.DEF_PRESSURE, EssayAirTypeEnum.PARALLELISM_TEST, EssayAirTypeEnum.CO2_CONCENTRATION,
      EssayAirTypeEnum.CO_CONCENTRATION, EssayAirTypeEnum.NO2_CONCENTRATION, EssayAirTypeEnum.NO_CONCENTRATION,
      EssayAirTypeEnum.LINE_PRESSURE, EssayAirTypeEnum.DEW_POINT];
    const essaysParticles = [
      EssayAirTypeEnum.PARTICLE_COUNT_REPOSE, EssayAirTypeEnum.PARTICLE_COUNT_WORKING, EssayAirTypeEnum.RECOVERY_ESSAY,
      EssayAirTypeEnum.PARTICLE_COUNT, EssayAirTypeEnum.ANEXOI_COUNT_REPOSE, EssayAirTypeEnum.ANEXOI_COUNT_WORKING,
      EssayAirTypeEnum.ANEX_COUNT];
    const accordingEssays = [
      EssayAirTypeEnum.SMOKE_ESSAY_REPOSE, EssayAirTypeEnum.SMOKE_ESSAY_WORKING, EssayAirTypeEnum.ELECTRIC_ESSAY,
      EssayAirTypeEnum.FILTER_SATURATION, EssayAirTypeEnum.INDUCTION_ESSAY, EssayAirTypeEnum.HYDROCARBON_LEVEL];

    const isParticleEssay = essaysParticles.includes(essay.idEssayType);
    const isAccordingEssay = accordingEssays.includes(essay.idEssayType);
    const isCriteriaSimpleEssay = criteriaSimpleEssays.includes(essay.idEssayType);

    if (!isAccordingEssay && ((ArrayUtils.isEmpty(essay.values) && !isParticleEssay)
      || (ArrayUtils.isEmpty(essay.valuesParticles) && isParticleEssay))) {
      return null;
    }

    if (isCriteriaSimpleEssay) {
      essay.criterias.forEach(criteria => {
        if (ArrayUtils.isEmpty(criteria.values)) {
          criteria.values = essay.values.filter(v => v.idCriteria === criteria.id);
        }
        accordingEssay = ExecutionAirUtils.checkValuesCriteria(criteria) && accordingEssay;
      });
    } else if (essay.idEssayType === EssayAirTypeEnum.TEMPERATURE || essay.idEssayType === EssayAirTypeEnum.HUMIDITY) {
      essay.criterias.forEach(criteria => {
        if (ArrayUtils.isEmpty(criteria.values)) {
          criteria.values = essay.values.filter(v => v.idCriteria === criteria.id);
        }
        accordingEssay = ExecutionAirUtils.checkValuesCriteria(criteria) && accordingEssay;
      });
    } else if (essay.idEssayType === EssayAirTypeEnum.RECOVERY_ESSAY) {
      essay.criterias.forEach(criteria => {
        accordingEssay =
          ExecutionAirUtils.checkValuesCriteriaParticleRecovery(essay, criteria, essay.valuesParticles) && accordingEssay;
      });
    } else if (isParticleEssay) {
      essay.criterias.forEach(criteria => {
        accordingEssay =
          ExecutionAirUtils.checkValuesCriteriaParticleCount(essay, criteria, essay.valuesParticles, isCompressedGas) && accordingEssay;
      });
    } else if (essay.idEssayType === EssayAirTypeEnum.WASTE_OF_LOAD || essay.idEssayType === EssayAirTypeEnum.HEPA_FILTER_INTEGRITY) {
      essay.criterias.forEach(criteria => {
        if (ArrayUtils.isEmpty(criteria.values)) {
          criteria.values = essay.values.filter(v => v.idCriteria === criteria.id);
        }
        accordingEssay = ExecutionAirUtils.checkValuesCriteria(criteria) && accordingEssay;
      });
    } else if (isAccordingEssay) {
      accordingEssay = essay.according;
    } else if (essay.idEssayType === EssayAirTypeEnum.MICROBIO_ESSAY) {
      const hasMicrobiologicalAmbient = ExecutionAirUtils.hasMicrobiologicalAmbient(essay);
      const hasMicrobiologicalSurface = ExecutionAirUtils.hasMicrobiologicalSurface(essay);

      let anyError = false;

      essay.criterias.forEach(crit => crit.values.forEach(val => {
        // Si ya hay error, evitamos el bucle
        if (anyError) {
          return;
        }

        let values = val.data?.map(a => a.value);

        if (values == null) {
          values = val.value.split(';');
        }

        if (StringUtils.isEmpty(values[0]) || (hasMicrobiologicalAmbient && StringUtils.isEmpty(values[1]))
          || (hasMicrobiologicalSurface && StringUtils.isEmpty(values[2]))) {
          anyError = true;
        }
      }));

      accordingEssay = anyError ? null : essay.according;
    } else if (essay.idEssayType === EssayAirTypeEnum.REN_FLOW_AND_RATE) {
      essay.criterias.forEach(criteria => {
        if (ArrayUtils.isEmpty(criteria.values)) {
          criteria.values = essay.values.filter(v => v.idCriteria === criteria.id);
        }
        accordingEssay = ExecutionAirUtils.checkValuesCriteriaFlow(essay, volume, filters) && accordingEssay;
      });
    } else if (essay.idEssayType === EssayAirTypeEnum.AIR_SPEED) {
      essay.criterias.forEach(criteria => {
        if (ArrayUtils.isEmpty(criteria.values)) {
          criteria.values = essay.values.filter(v => v.idCriteria === criteria.id);
        }
        accordingEssay = ExecutionAirUtils.checkValuesCriteriaSpeed(essay, eqType, filters) && accordingEssay;
      });
    }
    if(essay.idEssayType === EssayAirTypeEnum.DEF_PRESSURE){
      essay.criterias.forEach(criteria => {
        if(ArrayUtils.isEmpty(criteria.values)) {
          accordingEssay = null;
        }
      });
    }

    return accordingEssay;
  }

  static getMapSpeedTypeCriteria(): Map<number, number[]> {
    if (this.mapSpeedTypeCriteria == null) {
      this.mapSpeedTypeCriteria = new Map<number, number[]>();
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.LAMINAR_CABIN_VERTICAL, [SpeedTypeEnum.AVERAGE_SPEED]);
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.LAMINAR_CABIN_HORIZONTAL, [SpeedTypeEnum.AVERAGE_SPEED]);
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.LAMINAR_CABIN_30_70, [SpeedTypeEnum.AVERAGE_SPEED]);
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_I, [SpeedTypeEnum.FRONTAL_SPEED]);
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_IIA,
        [SpeedTypeEnum.AVERAGE_SPEED, SpeedTypeEnum.FRONTAL_SPEED]);
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_IIB,
        [SpeedTypeEnum.AVERAGE_SPEED, SpeedTypeEnum.FRONTAL_SPEED]);
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_III, [SpeedTypeEnum.GLOVE_SPEED]);
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.CARVING_TABLE, [SpeedTypeEnum.FRONTAL_SPEED]);
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.EXTRACTOR_DISPLAY_CASE, [SpeedTypeEnum.FRONTAL_SPEED]);
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.EXTRACTION_CABIN, [SpeedTypeEnum.FRONTAL_SPEED]);
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.AIR_DRIVE_UNIT, [SpeedTypeEnum.AVERAGE_SPEED]);
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.AIR_EXTRACTION_UNIT, [SpeedTypeEnum.AVERAGE_SPEED]);
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.PROTECTION_HEAVY_CABIN,
        [SpeedTypeEnum.AVERAGE_SPEED, SpeedTypeEnum.EXTRACTION_SPEED]);
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.LAMINATE_FLOW_MODULE,
        [SpeedTypeEnum.AVERAGE_SPEED, SpeedTypeEnum.EXTRACTION_SPEED]);
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.AISLATOR, [SpeedTypeEnum.AVERAGE_SPEED, SpeedTypeEnum.EXTRACTION_SPEED]);
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.CELL, [SpeedTypeEnum.AVERAGE_SPEED, SpeedTypeEnum.EXTRACTION_SPEED]);
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.TRANSFER, [SpeedTypeEnum.AVERAGE_SPEED, SpeedTypeEnum.EXTRACTION_SPEED]);
      this.mapSpeedTypeCriteria.set(EquipmentTypeAirEnum.FFU, [SpeedTypeEnum.AVERAGE_SPEED]);
    }

    return this.mapSpeedTypeCriteria;
  }

  static getMapOrderEssay(): Map<number, number> {
    if (this.mapOrderEssay == null) {
      this.mapOrderEssay = new Map<number, number>();
      this.mapOrderEssay.set(EssayAirTypeEnum.ELECTRIC_ESSAY, 1);
      this.mapOrderEssay.set(EssayAirTypeEnum.HEPA_FILTER_INTEGRITY, 2);
      this.mapOrderEssay.set(EssayAirTypeEnum.WASTE_OF_LOAD, 3);
      this.mapOrderEssay.set(EssayAirTypeEnum.AIR_SPEED, 4);
      this.mapOrderEssay.set(EssayAirTypeEnum.REN_FLOW_AND_RATE, 5);
      this.mapOrderEssay.set(EssayAirTypeEnum.DEF_PRESSURE, 6);
      this.mapOrderEssay.set(EssayAirTypeEnum.SMOKE_ESSAY_REPOSE, 7);
      this.mapOrderEssay.set(EssayAirTypeEnum.SMOKE_ESSAY_WORKING, 8);
      this.mapOrderEssay.set(EssayAirTypeEnum.PARTICLE_COUNT_REPOSE, 9);
      this.mapOrderEssay.set(EssayAirTypeEnum.PARTICLE_COUNT_WORKING, 10);
      this.mapOrderEssay.set(EssayAirTypeEnum.RECOVERY_ESSAY, 11);
      this.mapOrderEssay.set(EssayAirTypeEnum.LIGHT, 12);
      this.mapOrderEssay.set(EssayAirTypeEnum.NOISE, 13);
      this.mapOrderEssay.set(EssayAirTypeEnum.TEMPERATURE, 14);
      this.mapOrderEssay.set(EssayAirTypeEnum.HUMIDITY, 15);
      this.mapOrderEssay.set(EssayAirTypeEnum.INDUCTION_ESSAY, 16);
      this.mapOrderEssay.set(EssayAirTypeEnum.UV_INTENSITY, 17);
      this.mapOrderEssay.set(EssayAirTypeEnum.FILTER_SATURATION, 18);
      this.mapOrderEssay.set(EssayAirTypeEnum.PARALLELISM_TEST, 19);
      this.mapOrderEssay.set(EssayAirTypeEnum.FORMOLIZATIONEQUIPMENT, 20);
      this.mapOrderEssay.set(EssayAirTypeEnum.PARTICLE_COUNT, 21);
      this.mapOrderEssay.set(EssayAirTypeEnum.HYDROCARBON_LEVEL, 22);
      this.mapOrderEssay.set(EssayAirTypeEnum.DEW_POINT, 23);
      this.mapOrderEssay.set(EssayAirTypeEnum.LINE_PRESSURE, 24);
      this.mapOrderEssay.set(EssayAirTypeEnum.CO2_CONCENTRATION, 25);
      this.mapOrderEssay.set(EssayAirTypeEnum.CO_CONCENTRATION, 26);
      this.mapOrderEssay.set(EssayAirTypeEnum.NO2_CONCENTRATION, 27);
      this.mapOrderEssay.set(EssayAirTypeEnum.NO_CONCENTRATION, 28);
      this.mapOrderEssay.set(EssayAirTypeEnum.MICROBIO_ESSAY, 29);
      this.mapOrderEssay.set(EssayAirTypeEnum.ANEXOI_COUNT_REPOSE, 33);
      this.mapOrderEssay.set(EssayAirTypeEnum.ANEXOI_COUNT_WORKING, 34);
      this.mapOrderEssay.set(EssayAirTypeEnum.ANEX_COUNT, 35);
      this.mapOrderEssay.set(EssayAirTypeEnum.OTHERS, 99);
    }

    return this.mapOrderEssay;
  }

  static objectToRoom(item: RoomAir | any): RoomAir {

    if (item instanceof RoomAir) {
      return item;
    } else {
      const s = new RoomAir();

      for (const prop in item) {
        if (prop !== 'volumes') {
          s[prop] = item[prop];
        }
      }

      s.volumes = (item.volumes as Volume[]).map(vol => {
        const v = new Volume();

        for (const prop in vol) {
          if (prop !== 'values') {
            v[prop] = vol[prop];
          }
        }

        return v;
      });

      return s;
    }
  }

}
