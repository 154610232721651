import { Component, Inject } from '@angular/core';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Equipment } from 'src/app/model/equipment';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentTypeService } from 'src/app/services/equipmentType.service';
import { EquipmentType } from 'src/app/model/equipmentType';

export interface DialogDataEquipment {
  equipment: Equipment;
  isEdit: boolean;
}

@Component({
  selector: 'app-protocol-edit-dialog-equipment',
  templateUrl: 'protocol-edit-dialog-equipment.component.html'
})
export class ProtocolEditEquipmentDialogComponent {

  matcher = new MyErrorStateMatcher();
  tituloDialog: string;
  form: FormGroup;

  equipmentTypes: EquipmentType[];

  constructor(
    fb: FormBuilder,
    private equipmentTypeService: EquipmentTypeService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<ProtocolEditEquipmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataEquipment) {
    if (data.isEdit) {
      this.tituloDialog = this.translate.instant('protocolEdit.dialog.equipment.title.edit', { name: data.equipment.equipment }) as string;
    } else {
      this.tituloDialog = this.translate.instant('protocolEdit.dialog.equipment.title.create') as string;
    }

    this.form = fb.group({
      idType: [this.data.equipment.idType, [Validators.required]],
      equipment: [this.data.equipment.equipment, [Validators.required]],
      maker: [this.data.equipment.maker],
      model: [this.data.equipment.model],
      serialNum: [this.data.equipment.serialNum],
      internalId: [this.data.equipment.internalId],
      location: [this.data.equipment.location, [Validators.required]]
    });

    this.equipmentTypeService.findAll().subscribe((res: EquipmentType[]) => {
      this.equipmentTypes = res;

      if (this.data.equipment.idType == null) {
        this.data.equipment.idType = 1;
      }
    });
  }

  onOkClick(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.data.equipment);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
