<div *ngIf="!disableGraph()">

  <div>
    <dx-range-selector #graph id="range-selector" [value]="[minDate, maxDate]" [dataSource]="datasource"
      (onValueChanged)="onValueChanged($event)" (onIncidentOccurred)="onError($event)" [disabled]="disableRange()"
      style="width: 98vw">

      <dxo-margin [top]="50"></dxo-margin>
      <dxo-size [height]="500" width="80%"></dxo-size>

      <dxo-behavior [snapToTicks]="false"></dxo-behavior>

      <dxo-scale argumentType="datetime">
        <dxo-tick-interval [hours]="1"></dxo-tick-interval>
        <dxo-max-range [days]="maxDaysRange()" [hours]="maxHoursRange()" [minutes]="maxMinutesRange()"></dxo-max-range>
        <dxo-min-range [days]="minDaysRange()" [hours]="minHoursRange()" [minutes]="minMinutesRange()"></dxo-min-range>
        <dxo-minor-tick [visible]="false"></dxo-minor-tick>
        <dxo-marker [visible]="false"></dxo-marker>
        <dxo-label [customizeText]="customizeLabel"></dxo-label>
      </dxo-scale>

      <dxo-slider-marker [customizeText]="customizeSliderMarker" invalidRangeColor="white">
      </dxo-slider-marker>

      <dxo-chart>
       <dxi-series *ngFor="let sensor of sensorsToShow" [valueField]="sensor.value" [name]="sensor.name"></dxi-series>

        <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="bottom">
        </dxo-legend>

        <dxo-common-series-settings argumentField="date" type="line"></dxo-common-series-settings>

      </dxo-chart>

    </dx-range-selector>
  </div>

  <br />

  <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
    <div fxFlex="50" fxFlex.lt-md="100%">

      {{ 'isothermalCharacterizationEdit.essays.graph.form.filter.label' | translate }}

      <mat-form-field>
        <input matInput [(ngModel)]="dateZoomInitStr" placeholder="{{ 'common.start.label' | translate }}">
      </mat-form-field>

      <mat-form-field>
        <input matInput [(ngModel)]="dateZoomEndStr" placeholder="{{ 'common.end.label' | translate }}">
      </mat-form-field>

      <button mat-icon-button color="accent" (click)="recalculateDateFilter()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>

    <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="showCopyDateFirstSensor()">
      <mat-checkbox class="example-margin" (change)="onCopyTimeGraphChange($event)" [value]="item"
        [checked]="copyDateFirstSensor">
        {{ 'isothermalCharacterizationEdit.essays.graph.form.copyDates.label' | translate }}
      </mat-checkbox>
    </div>
    <div fxFlex="50" fxFlex.lt-md="100%"></div>

  </div>

  <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
    <div fxFlex="50" fxFlex.lt-md="100%">
      {{ 'isothermalCharacterizationEdit.essays.graph.form.exhibitionTime.label' | translate }}
      {{ 'isothermalCharacterizationEdit.essays.graph.form.exhibitionTime.from' | translate }}
      {{ minDate | fixDateTime }}
      {{ 'isothermalCharacterizationEdit.essays.graph.form.exhibitionTime.to' | translate }} {{ maxDate | fixDateTime }}
    </div>
    <div fxFlex="50" fxFlex.lt-md="100%"></div>

    <div fxFlex="20" fxFlex.lt-md="100%" *ngFor="let data of calculatedData">
      <p style="font-weight: bold">
        {{ 'isothermalCharacterizationEdit.essays.graph.form.calculatedData.sensorName' | translate }}
        {{ data.equipmentName }} ({{ data.serialNum }})
      </p>
      <div *ngIf="data.uncertainty > 0">
        <p>
          {{ 'isothermalCharacterizationEdit.essays.graph.form.calculatedData.min' | translate }}
          {{ data.minValue | number:'1.2-2' }} {{ unitName }}
        </p>
        <p>
          {{ 'isothermalCharacterizationEdit.essays.graph.form.calculatedData.average' | translate }}
          {{ data.average | number:'1.2-2' }} {{ unitName }}
        </p>
        <p>
          {{ 'isothermalCharacterizationEdit.essays.graph.form.calculatedData.max' | translate }}
          {{ data.maxValue | number:'1.2-2' }} {{ unitName }}
        </p>
        <p>
          {{ 'isothermalCharacterizationEdit.essays.graph.form.calculatedData.minAvg' | translate }}
          {{ data.diffAvgMin | number:'1.2-2' }} {{ unitName }}
        </p>
        <p>
          {{ 'isothermalCharacterizationEdit.essays.graph.form.calculatedData.avgMax' | translate }}
          {{ data.diffMaxAvg | number:'1.2-2' }} {{ unitName }}
        </p>
        <p>
          {{ 'isothermalCharacterizationEdit.essays.graph.form.calculatedData.minMax' | translate }}
          {{ data.diffMaxMin | number:'1.2-2' }} {{ unitName }}
        </p>
        <p>
          {{ 'isothermalCharacterizationEdit.essays.graph.form.calculatedData.standardDeviation' | translate }}
          {{ data.standardDeviation | number:'1.2-2' }} {{ unitName }}
        </p>
        <p>
          {{ 'isothermalCharacterizationEdit.essays.graph.form.calculatedData.uncertaintyVariable' | translate }}
          {{ data.uncertaintyVariable | number:'1.2-2' }} {{ unitName }}
        </p>

        <p *ngIf="data.outer">
          {{ 'executionEdit.essays.graph.form.calculatedData.outer' | translate }}
        </p>

        <p *ngIf="data.isErrMax && !data.outer" class="invalidValue">
          {{ 'executionEdit.essays.graph.form.calculatedData.isErrMax' | translate }}
        </p>
        <p *ngIf="data.isErrMin && !data.outer" class="invalidValue">
          {{ 'executionEdit.essays.graph.form.calculatedData.isErrMin' | translate }}
        </p>
        <p *ngIf="data.isErrPeakMax && !data.outer" class="invalidValue">
          {{ 'executionEdit.essays.graph.form.calculatedData.isErrPeakMax' | translate }}
        </p>
        <p *ngIf="data.isErrPeakMin && !data.outer" class="invalidValue">
          {{ 'executionEdit.essays.graph.form.calculatedData.isErrPeakMin' | translate }}
        </p>
        <p *ngIf="(data.isErrAvgMin || data.isErrAvgMin) && !data.outer" class="invalidValue">
          {{ 'executionEdit.essays.graph.form.calculatedData.isErrAvgMin' | translate }}
        </p>
      </div>
      <div *ngIf="data.uncertainty <= 0">
        {{ 'isothermalCharacterizationEdit.essays.graph.form.calculatedData.emptyUncertainty' | translate }}
      </div>
    </div>
    <div fxFlex="100" *ngIf="summaryData != null">

      <mat-toolbar color="secondary">
        {{ 'isothermalCharacterizationEdit.essays.graph.form.summaryData.title' | translate }}
      </mat-toolbar>

      <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
        <div fxFlex="15em" fxFlex.lt-md="100%">
          <div fxLayout="column">
            <p fxFlex>
              {{ 'isothermalCharacterizationEdit.essays.graph.form.summaryData.average' | translate }}<sup>1</sup>:
              {{ summaryData.average | number:'1.2-2' }} ± {{ summaryData.averageExpandedUncertainty | number:'1.2-2' }}
              {{ unitName }}
            </p>
            <p fxFlex *ngIf="summaryData.uniformity != null"
              class="{{ summaryData.uniformityValid ? 'validValue' : 'invalidValue' }}">
              {{ 'isothermalCharacterizationEdit.essays.graph.form.summaryData.uniformity' | translate }}
              {{ summaryData.uniformity | number:'1.2-2' }} ±
              {{ summaryData.uniformityExpandedUncertainty | number:'1.2-2' }} {{ unitName }}
            </p>
            <p fxFlex class="{{ summaryData.stabilityValid ? '' : 'error' }}">
              {{ 'isothermalCharacterizationEdit.essays.graph.form.summaryData.stability' | translate }}
              {{ summaryData.stability | number:'1.2-2' }} ±
              {{ summaryData.stabilityExpandedUncertainty | number:'1.2-2' }} {{ unitName }}
            </p>
          </div>
        </div>

        <div fxFlex="10em" fxFlex.lt-md="0%"> </div>

        <div fxFlex="25em" fxFlex.lt-md="100%">
          <div fxLayout="column">
            <p fxFlex>
              {{ 'isothermalCharacterizationEdit.essays.graph.form.summaryData.max' | translate }}
              {{ summaryData.max | number:'1.2-2' }} {{ unitName }} ({{ summaryData.maxSensor }})
            </p>
            <p fxFlex>
              {{ 'isothermalCharacterizationEdit.essays.graph.form.summaryData.min' | translate }}
              {{ summaryData.min | number:'1.2-2' }} {{ unitName }} ({{ summaryData.minSensor }})
            </p>
          </div>
        </div>

        <div fxFlex="15em" fxFlex.lt-md="100%">
          <div fxLayout="column">
            <p fxFlex>
              {{ 'isothermalCharacterizationEdit.essays.graph.form.summaryData.avgMax' | translate }}
              {{ summaryData.avgMax | number:'1.2-2' }} {{ unitName }}
            </p>
            <p fxFlex>
              {{ 'isothermalCharacterizationEdit.essays.graph.form.summaryData.avgMin' | translate }}
              {{ summaryData.avgMin | number:'1.2-2' }} {{ unitName }}
            </p>
            <p fxFlex>
              {{ 'isothermalCharacterizationEdit.essays.graph.form.summaryData.dispersion' | translate }}<sup>2</sup>:
              {{ summaryData.dispersion | number:'1.2-2' }} {{ unitName }}
            </p>
          </div>
        </div>

      </div>

      <br />

      <strong>
        {{ 'isothermalCharacterizationEdit.essays.graph.form.summaryData.legend.title' | translate}}
      </strong>

      <div>
        <sup>1</sup>
        {{ 'isothermalCharacterizationEdit.essays.graph.form.summaryData.legend.average' | translate}}<br />
        <sup>2</sup>
        {{ 'isothermalCharacterizationEdit.essays.graph.form.summaryData.legend.dispersion' | translate}}<br />
      </div>

    </div>

  </div>

</div>