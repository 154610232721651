<h1 mat-dialog-title>{{ 'common.reportType.title' | translate }}</h1>
<form>

  <div mat-dialog-content class="calibrates-theme" style="overflow-y: hidden;">

    <mat-form-field class="campoFormulario" *ngIf="showChangeLangIcon()">
      <mat-label>{{ 'common.reportType.options.language.label' | translate }}</mat-label>
      <mat-select [(ngModel)]="reportConfig.lang" [ngModelOptions]="{standalone: true}">
        <mat-option *ngFor="let item of langs" [value]="item.lang">
          <mat-icon svgIcon="{{item.flag}}"></mat-icon>
          {{item.fullLang}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox [checked]="checkFullReport" (change)="onChangeCheck($event)">
      {{ 'common.reportType.full-report' | translate }}
    </mat-checkbox>

    <br /><br />

    <span>{{ 'common.reportType.options.dataTable' | translate }}:</span>
    <br />
    <mat-radio-group class="vertical-radio-group" [(ngModel)]="reportConfig.dataTableInclude"
      [ngModelOptions]="{standalone: true}">
      <mat-radio-button class="vertical-radio-button" *ngFor="let item of reportConfig.dataTypes" [value]="item">
        {{ 'common.reportType.options.' + item | translate }}
      </mat-radio-button>
    </mat-radio-group>

  </div>

  <br /><br />

  <div mat-dialog-actions class="calibrates-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()" cdkFocusInitial>
      {{ 'button.download' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>