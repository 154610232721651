import { CriteriaType, CriteriaTypeCustomSubValue } from '../model/criteriaType';
import Dexie, { Table } from 'dexie';

import { AccessoryEquipmentType } from '../model/accessoryEquipmenType';
import { AccessoryFilterType } from '../model/accessoryFilterType';
import { Changes } from '../model/dexie';
import { CompressedGas } from '../model/compressedGasesAir';
import { EquipmentType } from '../model/equipmentType';
import { EssayConfigAir } from '../model/essayConfigAir';
import { EssayType } from '../model/essayType';
import { ExecutionAir } from '../model/execution';
import { FilterAirType } from '../model/filterAirType';
import { GenericClassTranslate } from '../model/genericClass';
import { Injectable } from '@angular/core';
import { InternalEquipment } from '../model/internalEquipment';
import { ProtocolAir } from '../model/protocol';
import { VariableUnit } from '../model/variable';

@Injectable()
export class DexieService extends Dexie {

    execution!: Table<ExecutionAir, number>;
    executionAudit!: Table<any, number>;
    executionStatus!: Table<any, number>;
    changes!: Table<Changes<any>, number>;
    subfilterTypes!: Table<AccessoryFilterType, number>;
    filterTypes!: Table<FilterAirType, number>;
    roomType!: Table<GenericClassTranslate, number>;
    phaseType!: Table<GenericClassTranslate, number>;
    processType!: Table<GenericClassTranslate, number>;
    protocol!: Table<ProtocolAir, number>;
    roomSubtypes!: Table<GenericClassTranslate, number>;
    equipmentTypesAir!: Table<EquipmentType, number>;
    internalEquipments!: Table<InternalEquipment, number>;
    responsables!: Table<any, number>;
    responsablesGroup!: Table<any, number>;
    essayTypes!: Table<EssayType, number>;
    essayConfig!: Table<EssayConfigAir, number>;
    essayFields!: Table<any, number>;
    attached!: Table<any, number>;
    equipmentTypes!: Table<EquipmentType, number>;
    accessoryType!: Table<AccessoryEquipmentType, number>;
    clients!: Table<any, number>;
    variableTypes!: Table<any, number>;
    variableUnits!: Table<VariableUnit, number>;
    criteriaTypes!: Table<CriteriaType, number>;
    criteriaConfig!: Table<any, number>;
    customValues!: Table<any, number>;
    customSubValues!: Table<CriteriaTypeCustomSubValue, number>;
    compressedGasTypes!: Table<CompressedGas, number>;

    constructor() {
        super('myDb');
        this.version(3).stores({
            attached: '++id, blob, idExecution, idEssay, idFilter, type, timestamp, fileName',
            execution: 'id, execution',
            changes: '++id, type, changes, father_id, areaId, areaIdOffline, roomId, roomIdOffline, timestamp, filterIdOffline, essayId,' +
                'essayIdOffline, equipmentId, equipmentIdOffline, gasIdOffline, gasId, execution_id',
            subfilterTypes: 'id',
            filterTypes: 'id',
            roomType: 'id',
            roomSubtypes: 'id',
            equipmentTypesAir: 'id',
            equipmentTypes: 'id',
            essayTypes: 'id',
            essayConfig: 'id, idEquipmentType, idEssay, idRoom, idCompressedGas',
            essayFields: 'id, idEssay',
            accessoryType: 'id',
            criteriaTypes: 'id',
            compressedGasTypes: 'id',
            criteriaConfig: 'id, idEssay',
            customSubValues: 'id, idCriteriaTypeCustom',
            customValues: 'id, idEssayType, idCriteriaType',
            internalEquipments: 'id, name, equipment, serialNum',
            executionAudit: 'id',
            processType: 'id',
            phaseType: 'id',
            executionStatus: 'id',
            responsables: 'username',
            clients: 'id',
            protocol: 'id',
            responsablesGroup: 'username',
            variableTypes: 'id',
            variableUnits: 'id, idVariable'
        });

        this.open().catch(err => {
            console.error(`Open failed: ${err.stack}`);
        });
    }

    public recreate(): Promise<void> {
        return new Promise<void>((resolve) => void this.execution.clear().then(() => resolve()));
    }

}