import * as _ from 'lodash-es';

import { ExecutionAir, ExecutionAttachmentFilter, ExecutionAuditFilter, ExecutionFilter } from '../model/execution';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, firstValueFrom, from } from 'rxjs';

import { AccessoryEquipment } from '../model/accessoryEquipment';
import { Area } from '../model/area';
import { AreaService } from './areaService';
import { Changes } from '../model/dexie';
import { CompressedGas } from '../model/compressedGasesAir';
import { CompressedGasService } from './compressedGasService';
import { DateUtils } from '../utils/dateUtils';
import { DexieService } from './dexieService';
import { EquipmentAir } from '../model/equipment';
import { EssayAirService } from './essayAir.service';
import { EssayProtocolAir } from '../model/essayProtocolAir';
import { ExecutionEquipmentService } from './executionEquipmentService';
import { ExecutionReportFilter } from '../model/attachment';
import { FieldUtils } from '../utils/fieldUtils';
import { FilterAir } from '../model/filterAir';
import { FilterService } from './filterService';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { OnlineService } from './online.service';
import { RoomAir } from '../model/roomAir';
import { RoomService } from './roomService';
import { Volume } from '../model/volume';
import { VolumeService } from './volumeService';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

@Injectable()
export class ExecutionAirService {

    constructor(private http: HttpClient,
        private areaService: AreaService,
        private compressedGasService: CompressedGasService,
        private dexieService: DexieService,
        private executionEquipmentService: ExecutionEquipmentService,
        private essayService: EssayAirService,
        private filterServive: FilterService,
        private onlineService: OnlineService,
        private roomService: RoomService,
        private volumeService: VolumeService) {
    }

    findAll(filter: ExecutionFilter): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.airUrl + '/execution/';

            return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.execution.toArray().timeout(30000));
        }
    }

    getExcel(filter: ExecutionFilter): Observable<any> {
        const url = environment.airUrl + '/execution/excel';

        return this.http.post(url, filter, { responseType: 'blob' });
    }

    findOne(id: number): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.airUrl + `/execution/${id}`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.execution.get(+id).timeout(30000));
        }

    }

    checkForDownload(id: number): Observable<any> {
        const url = environment.airUrl + `/execution/${id}/check`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOneShort(id: number): Observable<any> {
        const url = environment.airUrl + `/execution/${id}/short`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOneEssay(id: number): Observable<any> {
        const url = environment.airUrl + `/execution/essayValue/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getEventValue(id: number): Observable<any> {
        const url = environment.airUrl + `/execution/essayValue/${id}/event`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    create(exe: ExecutionAir, token: string): Observable<any> {
        const url = environment.airUrl + '/execution/create';

        return this.http.post(url, {
            execution: exe,
            token
        }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    save(ex: ExecutionAir): Observable<any> {
        return from(this.checkAreaChanges(ex));
    }

    validate(exe: ExecutionAir, token: string, forceAccording: boolean, reasonForce: string): Observable<any> {
        const url = environment.airUrl + `/execution/${exe.id}/validate/`;

        const body = { forceAccording, reasonForce, token };

        return this.http.put(url, body).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    signInfo(exe: ExecutionAir): Observable<any> {
        const url = environment.airUrl + `/execution/${exe.id}/signInfo/`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    addSign(exe: ExecutionAir, token: string, signTypes: number[], user: string, config: ExecutionReportFilter): Observable<any> {
        const url = environment.airUrl + `/execution/${exe.id}/addSign`;

        const form = new FormData();
        form.append('token', token);
        form.append('user', user);
        form.append('signTypes', signTypes.join(';'));
        form.append('config', JSON.stringify(config));

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    refuseToSign(exe: ExecutionAir, token: string, reason: string): Observable<any> {
        const url = environment.airUrl + `/execution/${exe.id}/refuseToSign`;

        const form = new FormData();
        form.append('token', token);
        form.append('reason', reason);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    manualSign(exe: ExecutionAir, token: string, forceAccording: boolean, reasonForce: string, file: File): Observable<any> {
        const url = environment.airUrl + `/execution/${exe.id}/manualSign`;

        const form = new FormData();
        form.append('forceAccording', JSON.stringify(forceAccording));
        form.append('reasonForce', reasonForce);
        form.append('reason', exe.reason);
        form.append('token', token);
        form.append('file', file, file.name);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    automaticSign(
        exe: ExecutionAir, token: string, forceAccording: boolean, reasonForce: string, signTypes: number[], config: ExecutionReportFilter)
        : Observable<any> {
        const url = environment.airUrl + `/execution/${exe.id}/automaticSign`;

        const form = new FormData();
        form.append('forceAccording', JSON.stringify(forceAccording));
        form.append('reasonForce', reasonForce);
        form.append('reason', exe.reason);
        form.append('token', token);
        form.append('signTypes', signTypes.join(';'));
        form.append('config', JSON.stringify(config));

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    invalidateByDoc(exe: ExecutionAir, token: string, reason: string): Observable<any> {
        const url = environment.airUrl + `/execution/${exe.id}/invalidateByDoc/`;

        return this.http.put(url, { token, reason }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    delete(id: number): Observable<any> {
        const url = environment.airUrl + `/execution/${id}`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadPdf(id: number, config: ExecutionReportFilter = new ExecutionReportFilter()): Observable<any> {
        const url = environment.airUrl + `/report/execution/${id}`;

        const headers = new HttpHeaders({
            lang: config.lang
        });

        const data = {
            graphsToInclude: config.graphsToInclude,
            dataTableInclude: config.dataTableInclude,
            reviewBy: config.reviewBy,
            toSign: config.toSign,
            reason: config.reason,
            conclusions: config.conclusions,
            deviations: config.deviations,
            observations: config.observations,
            idsEquipment: config.idsEquipment,
            idsRooms: config.idsRooms,
            idsCompressedGases: config.idsCompressedGases,
            hybride: config.hybride
        };

        return this.http.post(url, data, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadPdfExecutionVersion(execution: ExecutionAir): Observable<any> {
        return this.downloadPdfVersion(execution.id, execution.currentVersion);
    }

    downloadPdfVersion(id: number, version: string): Observable<any> {
        const url = environment.airUrl + `/report/execution/${id}/version/${version}`;

        return this.http.post(url, {}, { responseType: 'blob' });
    }

    downloadAuditPdf(id: number): Observable<any> {
        const url = environment.airUrl + `/report/execution/${id}/audit`;

        return this.http.get(url, { responseType: 'blob' });
    }

    getAudit(id: number, filter: ExecutionAuditFilter): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.airUrl + `/execution/${id}/audit`;

            return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from([]);
        }

    }

    getAttachments(id: number, filter: ExecutionAttachmentFilter): Observable<any> {
        const url = environment.airUrl + `/execution/${id}/attachment`;

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    moveAttachment(id: number, previousIndex: number, currentIndex: number): Observable<any> {
        const url = environment.airUrl + `/execution/${id}/attachment/move`;

        return this.http.post(url, { previousIndex, currentIndex }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getConclusions(id: number, lang: string): Observable<any> {
        const url = environment.airUrl + `/execution/${id}/conclusions`;

        const headers = new HttpHeaders({
            lang
        });

        return this.http.get(url, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getDeviations(id: number, lang: string): Observable<any> {
        const url = environment.airUrl + `/execution/${id}/deviations`;

        const headers = new HttpHeaders({
            lang
        });

        return this.http.get(url, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getObservations(id: number, lang: string): Observable<any> {
        const url = environment.airUrl + `/execution/${id}/observations`;

        const headers = new HttpHeaders({
            lang
        });

        return this.http.get(url, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    revertSign(id: number, reason: string, token: string): Observable<any> {
        const url = environment.airUrl + `/execution/${id}/revertSign`;

        const form = new FormData();
        form.append('token', token);
        form.append('reason', reason);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    updateAreas(rooms: RoomAir[]): Observable<any> {
        const url = environment.airUrl + '/execution/areas';

        return this.http.put(url, { rooms }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    update(ex: ExecutionAir): Observable<any> {
        const url = environment.airUrl + `/execution/generalData/${ex.id}`;

        return this.http.put(url, ex).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadDatasheetEquipment(ex: ExecutionAir, eq: EquipmentAir): Observable<any> {
        const url = environment.airUrl + `/report/execution/${ex.id}/equipment/${eq.id}/datasheet`;

        return this.http.get(url, { responseType: 'blob' });
    }

    downloadDatasheetRoom(ex: ExecutionAir, room: RoomAir): Observable<any> {
        const url = environment.airUrl + `/report/execution/${ex.id}/room/${room.id}/datasheet`;

        return this.http.get(url, { responseType: 'blob' });
    }

    downloadDatasheetCompressedGas(ex: ExecutionAir, gas: CompressedGas): Observable<any> {
        const url = environment.airUrl + `/report/execution/${ex.id}/compressedGas/${gas.id}/datasheet`;

        return this.http.get(url, { responseType: 'blob' });
    }

    downloadCountStrip(ex: ExecutionAir, essay: EssayProtocolAir): Observable<any> {
        const url = environment.airUrl + `/report/execution/${ex.id}/essay/${essay.id}/countStrip`;

        return this.http.get(url, { responseType: 'blob' });
    }

    saveExecutionToDexie(id: number): Promise<void> {
        return new Promise<void>((resolve) => this.findOne(id).subscribe((result: ExecutionAir) => {
            void this.dexieService.execution.put(result).then(() => resolve());
        }));
    }

    saveExecutionAuditToDexie(id: number): Promise<void> {
        return new Promise<void>((resolve) => this.getAudit(id, null).subscribe((result) => {
            void this.dexieService.executionAudit.put(result).then(() => resolve());
        }));
    }

    checkCopyToGroup(id: number, idGroup: number): Observable<any> {
        const url = environment.airUrl + `/execution/clonate/${id}/toGroup/${idGroup}/check`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    copyToGroup(id: number, idGroup: number): Observable<any> {
        const url = environment.airUrl + `/execution/clonate/${id}/toGroup/${idGroup}`;

        return this.http.put(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    cloneExecution(id: number): Observable<any> {
        const url = environment.airUrl + `/execution/clonate-execution/${id}`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    // Offline method
    async checkAreaChanges(ex: ExecutionAir): Promise<void> {
        const promises = [];

        let execHasChanges = false;

        const res = await firstValueFrom(this.findOne(ex.id)) as ExecutionAir;
        // Buscar area original, este metodo se moverá al boton de trabajo offline

        if (res != null) {

            if (this.compareExecutions(ex, res)) {
                execHasChanges = true;
            }

            ex.areas.forEach(area => {
                // Check areas to edit
                if (area.id != null || area.idOffline != null) {
                    let originalArea = new Area();

                    if (area.id != null) {
                        originalArea = res.areas.find(a => a.id === area.id);
                    } else {
                        originalArea = res.areas.find(a => a.idOffline === area.idOffline);
                    }

                    // Area to edit if has changes
                    if (originalArea != null) {
                        const changes = this.getChangesArea(area, ex);

                        if (this.compareAreas(area, originalArea)) {
                            promises.push(firstValueFrom(this.areaService.update(area, this.getTimestampOffline())));
                            if (area.id != null && area.idOffline != null) {
                                void this.dexieService.changes.where('areaId').equals(area.id).modify(changes);
                            } else if (area.id == null && area.idOffline != null) {
                                void this.dexieService.changes.where('areaIdOffline').equals(area.idOffline).modify(changes);
                            } else {
                                area.idOffline = uuid();
                                void this.dexieService.changes.put(changes);
                            }
                        } else if (JSON.stringify(area) !== JSON.stringify(originalArea)) {
                            if (area.id != null && area.idOffline != null) {
                                void this.dexieService.changes.where('areaId').equals(area.id).modify(changes);
                            } else if (area.id == null && area.idOffline != null) {
                                void this.dexieService.changes.where('areaIdOffline').equals(area.idOffline).modify(changes);
                            }
                        }
                    } else {
                        const changes = this.getChangesArea(area, ex);

                        if (area.idOffline == null) {
                            area.idOffline = uuid();
                        }

                        changes.areaIdOffline = area.idOffline;
                        void this.dexieService.changes.put(changes);
                    }

                    // Check the rooms of the area to edit
                    // The areas that will be created, will create the rooms automatically
                    // The areas that will be deleted, will delete the rooms automatically
                    if (area.id != null) {
                        area.rooms.forEach(room => this.checkRoomChanges(room, area, originalArea, ex, promises));

                        // Check rooms to delete
                        originalArea?.rooms.filter(roomO => !area.rooms.some(r => r.id === roomO.id)).forEach(roomO => {
                            promises.push(firstValueFrom(this.roomService.delete(roomO, this.getTimestampOffline(), ex.id)));

                            void this.dexieService.changes.put(new Changes<RoomAir>({
                                type: 'roomDelete',
                                changes: area,
                                execution_id: ex.id,
                                timestamp: this.getTimestampOffline(),
                                dateDeleted: this.getTimestampOffline(),
                                deleted: false
                            }));
                        });

                        // Check the equipments of the area to edit
                        // The areas that will be created, will create the equipments automatically
                        // The areas that will be deleted, will delete the equipments automatically
                        area.equipments.forEach(equipment => this.checkEquipmentChanges(equipment, area, originalArea, ex, promises));

                        // Check equipments to delete
                        originalArea?.equipments.filter(eqO => !area.equipments.some(r => r.id === eqO.id)).forEach(eqO => {
                            promises.push(firstValueFrom(this.executionEquipmentService.delete(eqO, this.getTimestampOffline(), res.id)));

                            void this.dexieService.changes.put(new Changes<EquipmentAir>({
                                type: 'equipmentDelete',
                                changes: eqO,
                                father_id: area.id,
                                timestamp: this.getTimestampOffline(),
                                dateDeleted: this.getTimestampOffline(),
                                deleted: false,
                                execution_id: ex.id
                            }));
                        });

                        // Check the gas of the area to edit
                        // The areas that will be created, will create the equipments automatically
                        // The areas that will be deleted, will delete the equipments automatically
                        area.compressedGases.forEach(gas => this.checkGasChanges(gas, area, originalArea, ex, promises));

                        originalArea?.compressedGases.filter(gasO => !area.compressedGases.some(r => r.id === gasO.id)).forEach(gasO => {
                            promises.push(firstValueFrom(this.compressedGasService.delete(gasO, this.getTimestampOffline(), res.id)));

                            void this.dexieService.changes.put(new Changes<CompressedGas>({
                                type: 'gasDelete',
                                changes: area,
                                execution_id: ex.id,
                                timestamp: this.getTimestampOffline(),
                                dateDeleted: this.getTimestampOffline(),
                                deleted: false
                            }));
                        });
                    }
                } else {
                    promises.push(firstValueFrom(this.areaService.save(area, this.getTimestampOffline())));
                    area.idOffline = uuid();

                    const changes = this.getChangesArea(area, ex);

                    void this.dexieService.changes.put(changes);
                }
            });
        }

        // Check areas to delete
        res.areas.filter(areaO => !ex.areas.some(a => a.id === areaO.id)).forEach(areaO => {
            promises.push(firstValueFrom(this.areaService.delete(areaO, this.getTimestampOffline())));

            void this.dexieService.changes.put(new Changes<Area>({
                type: 'areaDelete',
                changes: areaO,
                execution_id: ex.id,
                timestamp: this.getTimestampOffline(),
                dateDeleted: this.getTimestampOffline(),
                deleted: false
            }));
        });

        if (execHasChanges) {
            promises.push(firstValueFrom(this.update(ex)));
            void this.dexieService.changes.put(new Changes<ExecutionAir>({
                type: 'execution',
                changes: ex,
                dateDeleted: this.getTimestampOffline(),
                deleted: false,
                execution_id: ex.id
            }));
        }

        return new Promise<void>((resolve, reject) => {
            void this.dexieService.execution.update(ex.id, ex);
            void this.dexieService.execution.put(ex).then();

            if (this.onlineService.latestOnline) {
                Promise.all(promises).then(() => resolve()).catch(err => {
                    console.log(err);
                    reject();
                });

                void this.dexieService.changes.clear();
            } else {
                resolve();
            }
        });
    }

    private checkEquipmentChanges(equipment: EquipmentAir, area: Area, originalArea: Area, ex: ExecutionAir, promises: any[]): void {
        if (equipment.id != null || equipment.idOffline != null) {
            let originalEquipment = new EquipmentAir();
            originalEquipment = originalArea.equipments.find(r => r.id === equipment.id);
            const changes = this.getChangesEquipment(equipment, area, ex);

            if (this.compareEquipments(equipment, originalEquipment)) {
                promises.push(firstValueFrom(this.executionEquipmentService.update(equipment, this.getTimestampOffline(), ex.id)));
                if (equipment.id != null && equipment.idOffline != null) {
                    void this.dexieService.changes.where('equipmentId').equals(equipment.id).modify(changes);
                } else if (equipment.id == null && equipment.idOffline != null) {
                    void this.dexieService.changes.where('equipmentIdOffline').equals(equipment.idOffline).modify(changes);
                } else {
                    equipment.idOffline = uuid();
                    changes.equipmentIdOffline = equipment.idOffline;
                    void this.dexieService.changes.put(changes);
                }
            } else if (JSON.stringify(equipment) !== JSON.stringify(originalEquipment)) {
                if (area.id != null && area.idOffline != null) {
                    void this.dexieService.changes.where('equipmentId').equals(equipment.id).modify(changes);
                } else if (area.id == null && area.idOffline != null) {
                    void this.dexieService.changes.where('equipmentIdOffline').equals(equipment.idOffline).modify(changes);
                }
            }

            // CheckChanges on essays equipments
            if (equipment.id) {
                equipment.essays.forEach(essay => {
                    essay.idEquipment = equipment.id;
                    const originalEssay = originalEquipment.essays.find(r => r.id === essay.id);

                    if (originalEssay == null || this.compareDatesFromEssay(essay, originalEssay)) {
                        const alreadySaved = essay.idOffline != null;

                        if (essay.idOffline == null) {
                            essay.idOffline = essay.id?.toString() || uuid();
                        }

                        const changesEssay = this.getChangesEssayEquipment(essay, equipment, ex);

                        this.saveEssayToDexie(changesEssay, alreadySaved);
                        if(essay.id != null){
                            promises.push(firstValueFrom(this.essayService.update(essay, this.getTimestampOffline(), ex.id)));
                        }else{
                            promises.push(firstValueFrom(this.essayService.save(essay, this.getTimestampOffline(), ex.id)));
                        }
                    }
                });
            }

            // Check essays to delete
            originalEquipment.essays.filter(essay => !equipment.essays.some(r => r.id === essay.id)).forEach(essayO => {
                promises.push(firstValueFrom(this.essayService.delete(essayO, this.getTimestampOffline(), ex.id)));

                void this.dexieService.changes.put(new Changes<EssayProtocolAir>({
                    type: 'essayEqDelete',
                    changes: essayO,
                    dateDeleted: this.getTimestampOffline(),
                    deleted: false,
                    execution_id: ex.id
                }));
            });

            // CheckChanges on essays equipments
            if (equipment.id != null) {
                equipment.filters.forEach(filter => {
                    filter.idEquipment = equipment.id;
                    if (filter.id != null) {
                        let originalFilter = new FilterAir();
                        originalFilter = originalEquipment.filters.find(r => r.id === filter.id);

                        if (this.compareFilters(filter, originalFilter)) {
                            promises.push(firstValueFrom(this.filterServive.update(filter, this.getTimestampOffline(), ex.id)));
                            const changesFilter = this.getChangesFilterEquipment(filter, equipment, ex);

                            if (filter.idOfflineDexie != null) {
                                void this.dexieService.changes.where('filterId').equals(filter.id).modify(changesFilter);
                            } else {
                                filter.idOfflineDexie = uuid();
                                void this.dexieService.changes.put(changesFilter);
                            }
                        }
                    } else {
                        promises.push(firstValueFrom(this.filterServive.save(filter, this.getTimestampOffline(), ex.id)));
                        const changesFilter = this.getChangesFilterEquipment(filter, equipment, ex);

                        if (filter.idOfflineDexie != null) {
                            void this.dexieService.changes.where('filterIdOffline').equals(filter.idOfflineDexie).modify(changesFilter);
                        } else {
                            filter.idOfflineDexie = uuid();
                            filter.idEquipment = equipment.id;
                            void this.dexieService.changes.put(changesFilter);
                        }
                    }
                });
            }

            // Check filters to delete
            originalEquipment.filters.filter(filtO => !equipment.filters.some(r => r.id === filtO.id)).forEach(filterO => {
                promises.push(firstValueFrom(this.filterServive.delete(filterO, this.getTimestampOffline(), ex.id)));
                void this.dexieService.changes.put(new Changes<FilterAir>({
                    type: 'filterEqDelete',
                    changes: filterO,
                    father_id: equipment.id,
                    dateDeleted: this.getTimestampOffline(),
                    deleted: false,
                    execution_id: ex.id
                }));
            });

            if (equipment.id != null) {
                if (JSON.stringify(equipment.accessories) !== JSON.stringify(originalEquipment.accessories)) {
                    void this.dexieService.changes.put(new Changes<AccessoryEquipment[]>({
                        type: 'equipmentAccesories',
                        changes: equipment.accessories,
                        execution_id: ex.id
                    }));
                }
            }
        } else {
            equipment.idOffline = uuid();
            equipment.idArea = area.id;
            promises.push(firstValueFrom(this.executionEquipmentService.save(equipment, this.getTimestampOffline(), ex.id)));

            const changes = this.getChangesEquipment(equipment, area, ex);
            void this.dexieService.changes.put(changes);
        }
    }

    private checkRoomChanges(room: RoomAir, area: Area, originalArea: Area, ex: ExecutionAir, promises: any[]): void {
        if (room.id != null) {
            let originalRoom = new RoomAir();
            if (area.id != null) {
                originalRoom = originalArea.rooms.find(r => r.id === room.id);
            } else {
                originalRoom = originalArea.rooms.find(a => a.idOffline === area.idOffline);
            }

            const changes = this.getChangesRoom(room, area, ex);

            // Room to edit if has changes
            if (originalRoom != null || room.idOffline != null) {

                if (this.compareRooms(room, originalRoom)) {
                    promises.push(firstValueFrom(this.roomService.update(room, this.getTimestampOffline(), ex.id)));
                    if (room.id != null && room.idOffline != null) {
                        void this.dexieService.changes.where('roomId').equals(room.id).modify(changes);
                    } else if (room.id == null && room.idOffline != null) {
                        void this.dexieService.changes.where('roomIdOffline').equals(room.idOffline).modify(changes);
                    } else {
                        if (room.idOffline == null) {
                            room.idOffline = uuid();
                        }
                        changes.roomIdOffline = room.idOffline;
                        void this.dexieService.changes.put(changes);
                    }
                } else if (JSON.stringify(room) !== JSON.stringify(originalRoom)) {
                    if (area.id != null && area.idOffline != null) {
                        void this.dexieService.changes.where('roomId').equals(room.id).modify(changes);
                    } else if (area.id == null && area.idOffline != null) {
                        void this.dexieService.changes.where('roomIdOffline').equals(room.idOffline).modify(changes);
                    }
                }
            }
            // Check the essays of the room to edit
            if (room.id != null) {
                room.essays.forEach(essay => {
                    essay.idRoom = room.id;
                    const originalEssay = originalRoom.essays.find(r => r.id === essay.id);

                    if (originalEssay == null || this.compareDatesFromEssay(essay, originalEssay)) {
                        const alreadySaved = essay.idOffline != null;

                        if (essay.idOffline == null) {
                            essay.idOffline = essay.id?.toString() || uuid();
                        }

                        const changesEssay = this.getChangesEssayRoom(essay, room, ex);

                        this.saveEssayToDexie(changesEssay, alreadySaved);
                        if(essay.id != null){
                            promises.push(firstValueFrom(this.essayService.update(essay, this.getTimestampOffline(), ex.id)));
                        }else{
                            promises.push(firstValueFrom(this.essayService.save(essay, this.getTimestampOffline(), ex.id)));
                        }
                    }
                });
            }

            originalRoom?.essays.filter(essayO => !room.essays.some(r => r.id === essayO.id)).forEach(essayO => {
                promises.push(firstValueFrom(this.essayService.delete(essayO, this.getTimestampOffline(), ex.id)));

                void this.dexieService.changes.put(new Changes<EssayProtocolAir>({
                    type: 'essayRoomDelete',
                    changes: essayO,
                    father_id: room.id,
                    execution_id: ex.id,
                    timestamp: this.getTimestampOffline(),
                    dateDeleted: this.getTimestampOffline(),
                    deleted: false
                }));
            });

            // Check filter changes on essayRooms
            if (room.id != null) {
                room.filters.forEach(filter => {
                    const changesFilter = this.getChangesFilterRoom(filter, room, ex);
                    if (filter.id != null) {
                        let originalFilter = new FilterAir();
                        originalFilter = originalRoom.filters.find(r => r.id === filter.id);

                        if (this.compareFilters(filter, originalFilter)) {
                            promises.push(firstValueFrom(this.filterServive.update(filter, this.getTimestampOffline(), ex.id)));

                            if (room.id != null && room.idOffline != null) {
                                void this.dexieService.changes.where('filterId').equals(filter.id).modify(changesFilter);
                            } else if (room.id == null && room.idOffline != null) {
                                void this.dexieService.changes.where('filterIdOffline').equals(filter.idOffline).modify(changesFilter);
                            } else {
                                filter.idOfflineDexie = uuid();
                                filter.idRoom = room.id;
                                void this.dexieService.changes.put(changesFilter);
                            }
                        }
                    } else {
                        filter.idRoom = room.id;
                        promises.push(firstValueFrom(this.filterServive.save(
                            filter, this.getTimestampOffline(), ex.id)));
                        if (filter.idOfflineDexie != null) {
                            void this.dexieService.changes.where('filterIdOffline').equals(filter.idOfflineDexie).modify(changesFilter);
                        } else {
                            filter.idOfflineDexie = uuid();
                            void this.dexieService.changes.put(changesFilter);
                        }
                    }
                });
            }

            // Check filters to delete
            originalRoom?.filters.filter(filterO => !room.filters.some(r => r.id === filterO.id)).forEach(filterO => {
                promises.push(firstValueFrom(this.filterServive.delete(filterO, this.getTimestampOffline(), ex.id)));

                void this.dexieService.changes.put(new Changes<FilterAir>({
                    type: 'filterRoomDelete',
                    changes: filterO,
                    father_id: room.id,
                    execution_id: ex.id,
                    dateDeleted: this.getTimestampOffline(),
                    deleted: false
                }));
            });

            if (room.id != null) {
                room.volumes.forEach(volume => {
                    if (volume.id != null) {
                        let originalVolume = new Volume();
                        originalVolume = originalRoom?.volumes.find(r => r.id === volume.id);
                        if (JSON.stringify(volume) !== JSON.stringify(originalVolume)) {
                            promises.push(firstValueFrom(this.volumeService.update(volume, this.getTimestampOffline(), ex.id)));
                            const changesVolume = this.getChangesVolume(volume, room, ex);

                            if (volume.id != null && volume.idOffline != null) {
                                void this.dexieService.changes.where('volumeId').equals(volume.id).modify(changesVolume);
                            } else if (volume.id == null && volume.idOffline != null) {
                                void this.dexieService.changes.where('volumeIdOffline').equals(volume.idOffline).modify(changesVolume);
                            } else {
                                volume.idOffline = uuid();
                                void this.dexieService.changes.put(changesVolume);
                            }
                        }
                    } else {
                        volume.idRoom = room.id;
                        promises.push(firstValueFrom(this.volumeService.save(volume, this.getTimestampOffline(), ex.id)));

                        const changesVolume = this.getChangesVolume(volume, room, ex);
                        void this.dexieService.changes.put(changesVolume);
                    }
                });
            }
            originalRoom?.volumes.filter(volumeO => !room.volumes.some(r => r.id === volumeO.id)).forEach(volumeO => {
                promises.push(firstValueFrom(this.volumeService.delete(volumeO, this.getTimestampOffline(), ex.id)));

                void this.dexieService.changes.put(new Changes<Volume>({
                    type: 'volumeDelete',
                    changes: volumeO,
                    father_id: room.id,
                    dateDeleted: this.getTimestampOffline(),
                    deleted: false,
                    execution_id: ex.id
                }));
            });
        } else {
            // Rooms to create
            room.idArea = area.id;
            promises.push(firstValueFrom(this.roomService.save(room, this.getTimestampOffline(), ex.id)));

            const changes = this.getChangesRoom(room, area, ex);

            if (room.id != null && room.idOffline != null) {
                void this.dexieService.changes.where('roomId').equals(room.id).modify(changes);
            } else if (room.id == null && room.idOffline != null) {
                void this.dexieService.changes.where('roomIdOffline').equals(room.idOffline).modify(changes);
            } else {
                room.idOffline = uuid();
                changes.roomIdOffline = room.idOffline;
                void this.dexieService.changes.put(changes);
            }
        }
    }

    private checkGasChanges(gas: CompressedGas, area: Area, originalArea: Area, ex: ExecutionAir, promises: any[]): void {

        if (gas.id != null || gas.idOffline != null) {
            let originalGas = new CompressedGas();

            if (area.id != null) {
                originalGas = originalArea.compressedGases.find(r => r.id === gas.id);
            } else {
                originalGas = originalArea.compressedGases.find(a => a.idOffline === gas.idOffline);
            }

            const changes = this.getChangesGas(gas, area, ex);

            // Gas to edit if has changes
            if (this.compareGases(gas, originalGas)) {
                promises.push(firstValueFrom(this.compressedGasService.update(gas, this.getTimestampOffline(), ex.id)));
                if (gas.id != null && gas.idOffline != null) {
                    void this.dexieService.changes.where('gasId').equals(gas.id).modify(changes);
                } else if (gas.id == null && gas.idOffline != null) {
                    void this.dexieService.changes.where('gasIdOffline').equals(gas.idOffline).modify(changes);
                } else {
                    gas.idOffline = uuid();
                    changes.gasIdOffline = gas.idOffline;
                    void this.dexieService.changes.put(changes);
                }
            } else if (JSON.stringify(gas) !== JSON.stringify(originalGas)) {
                promises.push(firstValueFrom(this.compressedGasService.update(gas, this.getTimestampOffline(), ex.id)));
                if (gas.id != null && gas.idOffline != null) {
                    void this.dexieService.changes.where('gasId').equals(gas.id).modify(changes);
                } else if (gas.id == null && gas.idOffline != null) {
                    void this.dexieService.changes.where('gasIdOffline').equals(gas.idOffline).modify(changes);
                }
            }

            // Check the essays of the room to edit
            if (gas.id != null) {
                gas.essays.forEach(essay => {
                    essay.idCompressedGas = gas.id;
                    const originalEssay = originalGas.essays.find(r => r.id === essay.id);

                    if (originalEssay == null || this.compareDatesFromEssay(essay, originalEssay)) {
                        const alreadySaved = essay.idOffline != null;

                        if (essay.idOffline == null) {
                            essay.idOffline = essay.id?.toString() || uuid();
                        }

                        const changesEssay = this.getChangesEssayGas(essay, gas, ex);

                        this.saveEssayToDexie(changesEssay, alreadySaved);
                        if(essay.id != null){
                            promises.push(firstValueFrom(this.essayService.update(essay, this.getTimestampOffline(), ex.id)));
                        }else{
                            promises.push(firstValueFrom(this.essayService.save(essay, this.getTimestampOffline(), ex.id)));
                        }
                    }
                });
            }

            originalGas.essays.filter(essayO => !gas.essays.some(r => r.id === essayO.id)).forEach(essayO => {
                promises.push(firstValueFrom(this.essayService.delete(essayO, this.getTimestampOffline(), ex.id)));

                void this.dexieService.changes.put(new Changes<EssayProtocolAir>({
                    type: 'essayGasDelete',
                    changes: essayO,
                    father_id: gas.id,
                    execution_id: ex.id,
                    timestamp: this.getTimestampOffline(),
                    dateDeleted: this.getTimestampOffline(),
                    deleted: false
                }));
            });
        } else {
            // Gas to create
            gas.idArea = area.id;
            gas.idOffline = uuid();
            promises.push(firstValueFrom(this.compressedGasService.save(gas, this.getTimestampOffline(), ex.id)));

            const changes = this.getChangesGas(gas, area, ex);
            void this.dexieService.changes.put(changes);
        }

    }

    private compareExecutions(ex: ExecutionAir, originalEx: ExecutionAir): boolean {
        return  ex.documentCode !== originalEx.documentCode || ex.clientDocumentCode !== originalEx.clientDocumentCode
            || ex.observations !== originalEx.observations ||
            ex.deviations !== originalEx.deviations || ex.idProcess !== originalEx.idProcess || ex.reason !== null
            || ex.projectNo !== originalEx.projectNo || ex.idClient !== originalEx.idClient
            || ex.usernameProjectManager !== originalEx.usernameProjectManager
            || ex.workOffline !== originalEx.workOffline
            || this.compareReferenceDocs(ex, originalEx);
    }

    private compareReferenceDocs(ex: ExecutionAir, originalEx: ExecutionAir): boolean {
        let index = 0;
        let diffs = false;
        ex.referenceDocs.forEach(refDoc => {
            if (refDoc?.code !== originalEx.referenceDocs[index]?.code ||
                refDoc.name !== originalEx.referenceDocs[index]?.name) {
                diffs = true;
            }
            index++;
        });
        return diffs;
    }

    private compareAreas(area: Area, originalArea: Area): boolean {
        return area.name !== originalArea.name;
    }

    private compareEquipments(equipment: EquipmentAir, originalEquipment: EquipmentAir): boolean {
        return equipment.idType !== originalEquipment.idType || equipment.inventoryNum !== originalEquipment.inventoryNum ||
            equipment.maker !== originalEquipment.maker || equipment.model !== originalEquipment.model ||
            equipment.serialNum !== originalEquipment.serialNum || equipment.equipmentStatus !== originalEquipment.equipmentStatus ||
            equipment.alarmType !== originalEquipment.alarmType || equipment.room !== originalEquipment.room ||
            equipment.variatorPosition !== originalEquipment.variatorPosition ||
            equipment.regFc !== originalEquipment.regFc || equipment.regUser !== originalEquipment.regUser ||
            equipment.reasonChange !== originalEquipment.reasonChange || equipment.modFc !== originalEquipment.modFc ||
            equipment.modUser !== originalEquipment.modUser || equipment.workingTime !== originalEquipment.workingTime;
    }

    private compareRooms(room: RoomAir, originalRoom: RoomAir): boolean {
        return room.idType !== originalRoom.idType || room.name !== originalRoom.name || room.idSubtype !== originalRoom.idSubtype ||
            room.numberPeopleRepose !== originalRoom.numberPeopleRepose ||
            room.numberPeopleWorking !== originalRoom.numberPeopleWorking ||
            room.numberRunningMachinesRepose !== originalRoom.numberRunningMachinesRepose ||
            room.numberRunningMachinesWorking !== originalRoom.numberRunningMachinesWorking ||
            room.numberStoppedMachinesRepose !== originalRoom.numberStoppedMachinesRepose ||
            room.numberStoppedMachinesWorking !== originalRoom.numberStoppedMachinesWorking ||
            room.showDiffInDeviations !== originalRoom.showDiffInDeviations ||
            room.showDiffInObservations !== originalRoom.showDiffInObservations;
    }

    private compareGases(gas: CompressedGas, originalGas: CompressedGas): boolean {
        return gas.idType !== originalGas.idType || gas.location !== originalGas.location || gas.code !== originalGas.code;
    }

    private compareFilters(filter: FilterAir, originalFilter: FilterAir): boolean {
        return filter.maker !== originalFilter.maker || filter.reasonChangeFilter !== originalFilter.reasonChangeFilter ||
            filter.length !== originalFilter.length || filter.diameter !== originalFilter.diameter ||
            filter.height !== originalFilter.height || filter.width !== originalFilter.width || filter.idType !== originalFilter.idType ||
            filter.idTypeFilter !== originalFilter.idTypeFilter;
    }

    private getChangesArea(area: Area, ex: ExecutionAir): Changes<Area> {
        return new Changes<Area>({
            type: 'area',
            changes: area,
            father_id: ex.id,
            areaId: area.id,
            areaIdOffline: area.idOffline,
            execution_id: ex.id,
            timestamp: this.getTimestampOffline(),
            deleted: false,
            dateDeleted: this.getTimestampOffline()
        });
    }

    private getChangesEquipment(equipment: EquipmentAir, area: Area, ex: ExecutionAir): Changes<EquipmentAir> {
        return new Changes<EquipmentAir>({
            type: 'equipment',
            changes: equipment,
            father_id: area.id,
            execution_id: ex.id,
            equipmentId: equipment.id,
            equipmentIdOffline: equipment.idOffline,
            timestamp: this.getTimestampOffline(),
            deleted: false,
            dateDeleted: this.getTimestampOffline()
        });
    }

    private getChangesRoom(room: RoomAir, area: Area, ex: ExecutionAir): Changes<RoomAir> {
        return new Changes<RoomAir>({
            type: 'room',
            changes: room,
            father_id: area.id,
            roomId: room.id,
            roomIdOffline: room.idOffline,
            execution_id: ex.id,
            timestamp: this.getTimestampOffline(),
            deleted: false,
            dateDeleted: this.getTimestampOffline()
        });
    }

    private getChangesGas(gas: CompressedGas, area: Area, ex: ExecutionAir): Changes<CompressedGas> {
        return new Changes<CompressedGas>({
            type: 'gas',
            changes: gas,
            father_id: area.id,
            gasId: gas.id,
            gasIdOffline: gas.idOffline,
            execution_id: ex.id,
            timestamp: this.getTimestampOffline(),
            deleted: false,
            dateDeleted: this.getTimestampOffline()
        });
    }

    private getChangesVolume(volume: Volume, room: RoomAir, ex: ExecutionAir): Changes<Volume> {
        return new Changes<Volume>({
            type: 'volumeRoom',
            changes: volume,
            father_id: room.id,
            execution_id: ex.id,
            volumeId: volume.id,
            volumeIdOffline: volume.idOffline,
            timestamp: this.getTimestampOffline(),
            deleted: false,
            dateDeleted: this.getTimestampOffline()
        });
    }

    private getChangesFilterEquipment(filter: FilterAir, equipment: EquipmentAir, ex: ExecutionAir): Changes<FilterAir> {
        return this.getChangesFilterGeneric(filter, 'filterEq', equipment.id, ex);
    }

    private getChangesFilterRoom(filter: FilterAir, room: RoomAir, ex: ExecutionAir): Changes<FilterAir> {
        return this.getChangesFilterGeneric(filter, 'filterRoom', room.id, ex);
    }

    private getChangesFilterGeneric(filter: FilterAir, type: string, fatherId: number, ex: ExecutionAir): Changes<FilterAir> {
        return new Changes<FilterAir>({
            type,
            changes: filter,
            father_id: fatherId,
            execution_id: ex.id,
            filterId: filter.id,
            filterIdOffline: filter.idOfflineDexie,
            timestamp: this.getTimestampOffline(),
            deleted: false,
            dateDeleted: this.getTimestampOffline()
        });
    }

    private getChangesEssayEquipment(essay: EssayProtocolAir, equipment: EquipmentAir, ex: ExecutionAir): Changes<EssayProtocolAir> {
        return this.getChangesEssayGeneric(essay, 'essayEquipment', equipment.id, ex);
    }

    private getChangesEssayRoom(essay: EssayProtocolAir, room: RoomAir, ex: ExecutionAir): Changes<EssayProtocolAir> {
        return this.getChangesEssayGeneric(essay, 'essayRoom', room.id, ex);
    }

    private getChangesEssayGas(essay: EssayProtocolAir, gas: CompressedGas, ex: ExecutionAir): Changes<EssayProtocolAir> {
        return this.getChangesEssayGeneric(essay, 'essayGas', gas.id, ex);
    }

    private getChangesEssayGeneric(essay: EssayProtocolAir, type: string, fatherId: number, ex: ExecutionAir): Changes<EssayProtocolAir> {
        return new Changes<EssayProtocolAir>({
            type,
            changes: this.prepareForChanges(essay),
            father_id: fatherId,
            execution_id: ex.id,
            essayId: essay.id,
            essayIdOffline: essay.idOffline,
            timestamp: this.getTimestampOffline(),
            deleted: false,
            dateDeleted: this.getTimestampOffline()
        });
    }

    private getTimestampOffline(): string {
        const date = new Date();
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
        return DateUtils.toDateTimeStr(date);
    }

    private prepareForChanges(essay: EssayProtocolAir): EssayProtocolAir {
        const cloned = _.cloneDeep(essay);

        cloned.essayValues = essay.essayValues.map(e => {
            const res = FieldUtils.objectToField(e).toJSON();

            res.value = e._value;

            return res;
        });

        return cloned;
    }

    private saveEssayToDexie(changesEssay: Changes<EssayProtocolAir>, alreadySaved: boolean): EssayProtocolAir {
        const essay = changesEssay.changes;

        changesEssay.changes.essayValues.forEach(field => {
            if (Array.isArray(field.value)) {
                field.value = field.value.join(';');
            }
        });

        if (alreadySaved) {
            // Caso ya tiene idoffline: Modificamos registro
            if (essay.id != null) {
                // Caso ensayo creado online, lo actualizamos según id online
                const existsEssay = void this.dexieService.changes.where('essayId').equals(essay.id);
                if (!existsEssay) {
                    void this.dexieService.changes.put(changesEssay);
                } else {
                    void this.dexieService.changes.where('essayId').equals(essay.id).modify(changesEssay);
                }
            } else {
                // Caso ensayo creado offline, lo actualizamos según id offline
                const existsEssay = void this.dexieService.changes.where('essayIdOffline').equals(essay.idOffline);
                if (!existsEssay) {
                    void this.dexieService.changes.put(changesEssay);
                } else {
                    void this.dexieService.changes.where('essayIdOffline').equals(essay.idOffline).modify(changesEssay);
                }
            }
        } else {
            // Caso no tiene idoffline, se lo seteamos y lo guardamos por primera vez.
            // Si es online, el id offline será el id online, si es offline, un uuid
            void this.dexieService.changes.put(changesEssay);
        }

        return essay;
    }

    private compareDatesFromEssay(essay: EssayProtocolAir, essayOriginal: EssayProtocolAir): boolean {
        let date1: number = null;
        let date2: number = null;

        if (essay != null && essay.modFc != null) {

            if (essay.modFc != null && typeof essay.modFc == 'string') {
                essay.modFc = DateUtils.anyToDate(essay.modFc);
            }

            date1 = essay.modFc.getTime();
        }

        if (essayOriginal != null && essayOriginal.modFc != null) {

            if (essayOriginal.modFc != null && typeof essayOriginal.modFc == 'string') {
                essayOriginal.modFc = DateUtils.anyToDate(essayOriginal.modFc);
            }

            date2 = essayOriginal.modFc.getTime();
        }

        return date1 !== date2;
    }

}
