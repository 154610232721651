import { GenericClassTranslate } from './genericClass';

export class TestQType extends GenericClassTranslate {
    checked: boolean;
    idExecutionTest: number;
    haveDeviations: boolean;
}

export enum TestTypeEnum{
    VERIFICATION_APPROVAL_PROTOCOL_QUALIFICATION = 1,
    VERIFICATION_APPROVAL_DESIGN_QUALIFICATION = 2,
    EQUIPMENT_VERIFICATION = 3,
    EQUIPMENT_DOCUMENTATION_VERIFICATION = 4,
    MATCHING_INSTALLATION_PLANS = 5,
    VERIFICATION_LOCATION_ASSOCIATED_SERVICES = 6,
    VERIFICATION_COMPONENTS_EQUIPMENT_DIMENSIONS_MATERIALS = 7,
    VERIFICATION_CALIBRATION_INSTRUMENTATION_CRITICAL_EQUIPMENT = 8,
    VERIFICATION_PERFORMANCE_TESTING_INSTALLATION_QUALIFICATION = 9,
    VERIFICATION_CONTROL_PANEL = 10,
    VERIFICATION_OF_EQUIPMENT_ALARMS_SAFEGUARDS = 11,
    VERIFICATION_OF_INTERLOCKS = 12,
    TEST_TIGHTNESS_TEST_VACUUM_TEST_LEAK_TEST = 13,
    LEAKAGE_TEST = 14,
    TEST_BOWIE_DICK = 15,
    RELATIVE_MOISTURE_TEMPERATURE_DISTRIBUTION_TEST_CO2_IN_VACUUM = 16,
    VERIFICATION_PERFORMANCE_TEST_QUALIFICATION_PERFORMANCE_TESTING = 17,
    VERIFICATION_DOCUMENTATION_OPERATION_MAINTENANCE_CLEANING = 18,
    TEST_TEMPERATURE_DISTRIBUTION_MOISTURE_CO2_WITH_LOAD_OPENING_DOORS = 19,
    TEMPERATURE_PENETRATION_TEST_UNDER_LOAD_TEMPERATURE = 20,
    VERIFICATION_APPROVAL_PROTOCOL_EXECUTION = 21
}