import { HttpStatusCode } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileInfo, IsothermalCharacterizationReportFilter } from 'src/app/model/attachment';
import { IsothermalCharacterization, IsothermalCharacterizationStatus } from 'src/app/model/isothermalCharacterization';
import { IsothermalCharacterizationService } from 'src/app/services/isothermalCharacterization.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LangFlag, TranslationService } from 'src/app/services/translation.service';
import { FileUtils } from 'src/app/utils/fileUtils';
import { IsothermalCharacterizationUtils } from 'src/app/utils/isothermalCharacterizationUtils';

export interface DialogDataGenerateReport {
  isothermalCharacterization: IsothermalCharacterization;
  hasToLoadData: boolean;
  toSign: boolean;
  reason: string;
}

@Component({
  selector: 'app-isothermal-characterization-edit-generate-report',
  templateUrl: './isothermal-characterization-edit-generate-report.component.html'
})
export class IsothermalCharacterizationEditGenerateReportComponent implements OnInit, OnDestroy {

  reportConfig: IsothermalCharacterizationReportFilter;
  checkFullReport: boolean;
  langs: LangFlag[];


  private destroy$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<IsothermalCharacterizationEditGenerateReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataGenerateReport,
    private isothermalCharacterizationService: IsothermalCharacterizationService,
    private translate: TranslateService,
    private translationService: TranslationService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    // Si es nulo, le asignamos por defecto false. En la interfaz no podemos asignarle un valor por defecto.
    data.hasToLoadData = data.hasToLoadData != null ? data.hasToLoadData : false;
    data.toSign = data.toSign != null ? data.toSign : false;
  }

  ngOnInit(): void {
    this.reportConfig = new IsothermalCharacterizationReportFilter();
    this.reportConfig.lang = this.translationService.currentLang;
    this.reportConfig.toSign = this.data.toSign;
    this.reportConfig.reason = this.data.reason;

    this.langs = this.translationService.configuredLangs;

    if (this.data.isothermalCharacterization.idStatus === IsothermalCharacterizationStatus.FIRMADO) {
      this.spinnerService.show();

      this.isothermalCharacterizationService.downloadPdfIsothermalCharacterizationVersion(this.data.isothermalCharacterization)
        .pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
          const name = IsothermalCharacterizationUtils.getNameForCharacterization(this.data.isothermalCharacterization, this.translate);
          saveAs(res, name);
          this.spinnerService.hide();

          this.dialogRef.close();
        }, () => {
          this.snackBarService.sendError(
            this.translate.instant('isothermalCharacterizationEdit.form.downloadPdf.error.notFound') as string);
          this.spinnerService.hide();
        });
    }

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showChangeLangIcon(): boolean {
    return this.langs.length > 1;
  }

  onOkClick(): void {
    this.spinnerService.show();

    if (this.data.hasToLoadData) {

      this.spinnerService.show();

      this.isothermalCharacterizationService.findOne(this.data.isothermalCharacterization.id).pipe(takeUntil(this.destroy$))
        .subscribe((res: IsothermalCharacterization) => {
          this.data.isothermalCharacterization = res;

          this.data.hasToLoadData = false;
          this.spinnerService.hide();

          this.onOkClick();
        });

      return;
    }
    if (this.checkFullReport) {
      this.defaultActionFullReport();
    } else {
      this.isothermalCharacterizationService.downloadPdf(this.data.isothermalCharacterization.id, this.reportConfig)
        .pipe(takeUntil(this.destroy$)).subscribe((res: { errors: string[], file: FileInfo }) => {

          if (res.errors) {
            const error = res.errors.join('\n');
            this.snackBarService.sendError(error);
          } else if (res.file) {
            const name = IsothermalCharacterizationUtils.getNameForCharacterization(this.data.isothermalCharacterization, this.translate);
            saveAs(FileUtils.base64toBlob(res.file.contentString, res.file.contentType), name);
          } else if (res instanceof Blob) {
            const name = IsothermalCharacterizationUtils.getNameForCharacterization(this.data.isothermalCharacterization, this.translate);
            saveAs(res, name);
          }

          this.spinnerService.hide();

          this.dialogRef.close();
        }, error => {
          this.spinnerService.hide();

          if (error != null && error.error != null) {
            error.error.text().then(err => {
              if (err != null && typeof err === 'string') {
                this.snackBarService.sendError(err);
              } else if (error.status === HttpStatusCode.NotFound) {
                this.snackBarService.sendError(
                  this.translate.instant('isothermalCharacterizationEdit.form.downloadPdf.error.notFound') as string);
              } else {
                this.snackBarService.sendError(
                  this.translate.instant('isothermalCharacterizationEdit.form.downloadPdf.error.generic') as string);
              }
            });
          } else {
            if (error.status === HttpStatusCode.NotFound) {
              this.snackBarService.sendError(
                this.translate.instant('isothermalCharacterizationEdit.form.downloadPdf.error.notFound') as string);
            } else {
              this.snackBarService.sendError(
                this.translate.instant('isothermalCharacterizationEdit.form.downloadPdf.error.generic') as string);
            }
          }
        });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onChangeCheck(event: MatCheckboxChange): void {
    this.checkFullReport = event.checked;
  }

  isFullReportChecked(): boolean {
    return this.checkFullReport;
  }

  defaultActionFullReport(): void {
    this.isothermalCharacterizationService.downloadReportFullPdf(this.data.isothermalCharacterization.id, this.reportConfig)
      .pipe(takeUntil(this.destroy$)).subscribe(res => {
        if (res.errors) {
          const error = res.errors.join('\n') as string;
          this.snackBarService.sendError(error);
        } else if (res.file) {
          const name = IsothermalCharacterizationUtils.getNameForReportZip(this.data.isothermalCharacterization, this.translate);
          saveAs(FileUtils.base64toBlob(res.file.contentString, res.file.contentType as string), name);
        }

        this.spinnerService.hide();

        this.dialogRef.close(this.reportConfig);
      }, err => {
        console.error(err);

        if (err != null && err.error != null && typeof err.error === 'string') {
          this.snackBarService.sendError(err.error as string);
        } else {
          this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.generic') as string);
        }

        this.spinnerService.hide();
      });
  }

}
