import { Component, Inject } from '@angular/core';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Equipment } from 'src/app/model/equipment';
import { TranslateService } from '@ngx-translate/core';

export interface DialogDataEquipment {
  equipment: Equipment;
  isEdit: boolean;
}

@Component({
  selector: 'app-isothermal-characterization-edit-dialog-equipment',
  templateUrl: 'isothermal-characterization-edit-dialog-equipment.html'
})
export class IsothermalCharacterizationEditEquipmentDialogComponent {

  matcher = new MyErrorStateMatcher();
  tituloDialog: string;
  form: FormGroup;

  constructor(
    fb: FormBuilder,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<IsothermalCharacterizationEditEquipmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataEquipment) {
    if (data.isEdit) {
      this.tituloDialog = this.translate.instant('protocolEdit.dialog.equipment.title.edit', { name: data.equipment.equipment });
    } else {
      this.tituloDialog = this.translate.instant('protocolEdit.dialog.equipment.title.create');
    }

    this.form = fb.group({
      equipment: [this.data.equipment.equipment, [Validators.required]],
      maker: [this.data.equipment.maker],
      model: [this.data.equipment.model],
      serialNum: [this.data.equipment.serialNum],
      internalId: [this.data.equipment.internalId],
      location: [this.data.equipment.location, [Validators.required]]
    });
  }

  onOkClick(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.data.equipment);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
