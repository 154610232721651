<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form>
  <div mat-dialog-content class="air-theme">

    <div fxLayout="row" class="flexwrap">
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput [(ngModel)]="data.person.name" name="name" required
            placeholder="{{ 'clientEdit.dialog.person.name.label' | translate }}">
          <mat-error>
            {{ 'clientEdit.dialog.person.name.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput [(ngModel)]="data.person.email" name="email" required
            placeholder="{{ 'clientEdit.dialog.person.email.label' | translate }}">
          <mat-error>
            {{ 'clientEdit.dialog.area.email.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="column" class="flexwrap">
      <div fxFlex>
        <mat-checkbox name="showDiffInDeviations" [(ngModel)]="data.person.preferential">
          {{ 'clientEdit.dialog.person.preferential.label' | translate }}
        </mat-checkbox>
      </div>
    </div>

  </div>
  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>