import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExecutionStatus } from 'src/app/model/execution';
import { FilterAir, FilterAirEnum, FilterAirTypeEnum, ShapeEnum } from 'src/app/model/filterAir';
import { FilterAirType } from 'src/app/model/filterAirType';
import { AttachmentAirService } from 'src/app/services/attachmentAir.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StringUtils } from 'src/app/utils/stringUtils';
import { v4 as uuid } from 'uuid';

export interface DialogDataFiltertAir {
  filterTypes: FilterAirType[];
  filter: FilterAir;
  isEdit: boolean;
  idExecution: number;
  isExecution: boolean;
  executionStatus: number;
}

@Component({
  selector: 'app-protocol-edit-dialog-filter',
  templateUrl: './protocol-edit-dialog-filter.component.html'
})

export class ProtocolEditDialogFilterComponent implements OnInit, OnDestroy {

  showFiltersType = false;

  showDiameter = false;
  showRectangular = false;

  changeFilter = false;

  private destroy$ = new Subject<void>();

  constructor(
    private attachmentAirService: AttachmentAirService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private dialogRef: MatDialogRef<ProtocolEditDialogFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataFiltertAir) {

    if (this.data.isExecution == null || this.data.idExecution == null) {
      this.data.isExecution = false;
    }

    this.changeFilter = this.data.isExecution && StringUtils.isNotEmpty(this.data.filter.maker);

    if (this.data.filter.idType != null) {
      this.onChangeTypeFilter(this.data.filter.idType);
      const shape = this.data.filter.diameter != null ? ShapeEnum.CIRCULAR : ShapeEnum.RECTANGULAR;
      this.onShapeChange(shape);
    }
  }

  ngOnInit(): void {
    if (this.data.filter.diameter != null || this.data.filter.length != null) {
      if (this.data.filter.diameter != null) {
        this.data.filter.shape = ShapeEnum.CIRCULAR;
      } else {
        this.data.filter.shape = ShapeEnum.RECTANGULAR;
      }
    }
    if (this.data.filter.idOffline == null) {
      this.data.filter.idOffline = uuid();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  uploadCertificate(event): void {
    const file = event.target.files[0] as File;

    this.spinnerService.show();

    this.attachmentAirService.uploadCertificateToFilter(this.data.idExecution, this.data.filter, file).subscribe((item: number) => {
      if (!isNaN(item)) {
        this.data.filter.idCertificate = item;
      } else {
        this.data.filter.idCertificate = 0;
      }

      this.spinnerService.hide();

      this.snackBarService.sendSuccess(this.translate.instant('userEdit.dialog.form.uploadCertificate.ok') as string);
    }, () => {
      this.spinnerService.hide();

      this.snackBarService.sendError(this.translate.instant('userEdit.dialog.form.uploadCertificate.error') as string);
    });
  }

  downloadCertificate(): void {
    this.spinnerService.show();

    this.attachmentAirService.downloadCertificateToFilter(this.data.idExecution, this.data.filter.id).pipe(takeUntil(this.destroy$))
      .subscribe((res: Blob) => {
        saveAs(res, 'cert.pdf');
        this.spinnerService.hide();
      }, error => {
        console.error(error);
        this.spinnerService.hide();
      });
  }

  onOkClick(): void {
    const errs = this.validateForm();

    if (!this.changeFilter) {
      this.data.filter.maker = null;
      this.data.filter.serialNum = null;
      this.data.filter.idCertificate = null;
    }

    if (this.data.filter.shape === ShapeEnum.CIRCULAR) {
      this.data.filter.height = null;
      this.data.filter.length = null;
      this.data.filter.width = null;
    } else {
      this.data.filter.diameter = null;
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.filter);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onChangeTypeFilter(event: number | MatRadioChange): void {
    let idType: number;

    if (typeof event === 'number') {
      idType = event;
    } else {
      idType = +event.source.value;
    }

    if (idType === FilterAirTypeEnum.FILTER || idType === FilterAirTypeEnum.FILTER_WITH_DIFFUSER) {
      this.showFiltersType = true;
    } else {
      this.showFiltersType = false;
      this.data.filter.idTypeFilter = null;
    }
  }

  onShapeChange(event: number | MatRadioChange): void {
    let idType: number;

    if (typeof event === 'number') {
      idType = event;
    } else {
      idType = +event.source.value;
    }

    if (idType === ShapeEnum.RECTANGULAR) {
      this.showRectangular = true;
      this.showDiameter = false;
    } else {
      this.showRectangular = false;
      this.showDiameter = true;
    }
  }

  showTotalFiltersSecondStage(): boolean {
    return this.data.filter.idType === FilterAirTypeEnum.FILTER && this.data.filter.idTypeFilter === FilterAirEnum.HEPA_EXTRACTION_2;
  }

  validateForm(): string[] {
    const errs: string[] = [];

    if (!this.showTotalFiltersSecondStage()) {
      this.data.filter.totalFiltersSecondStage = null;
    } else if (this.data.filter.totalFiltersSecondStage == null) {
      errs.push(this.translate.instant('protocolEdit.dialog.equipment.totalFiltersSecondStage.error') as string);
    }

    if (this.data.filter.idType == null) {
      errs.push(this.translate.instant('filter.equipment.idType.error') as string);
    }
    if((this.data.filter.idType === FilterAirTypeEnum.FILTER ||
      this.data.filter.idType === FilterAirTypeEnum.FILTER_WITH_DIFFUSER) &&
      this.data.filter.idTypeFilter == null) {
        errs.push(this.translate.instant('filter.equipment.idSubType.error') as string);
    }
    if (this.data.filter.shape == null) {
      errs.push(this.translate.instant('filter.equipment.shape.error') as string);
    } else {
      if (this.data.filter.shape === ShapeEnum.RECTANGULAR) {
        if (this.data.filter.length == null) {
          errs.push(this.translate.instant('filter.equipment.length.error') as string);
        }
        if (this.data.filter.width == null) {
          errs.push(this.translate.instant('filter.equipment.width.error') as string);
        }
      } else if (this.data.filter.shape === ShapeEnum.CIRCULAR) {
        if (this.data.filter.diameter == null) {
          errs.push(this.translate.instant('filter.equipment.diameter.error') as string);
        }
      }
    }

    return errs;
  }

  enableEditEssay(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];
    if (this.data.executionStatus != null) {
      return allowedStatuses.includes(this.data.executionStatus)
    }
    return true;
  }
}
