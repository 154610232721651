import { EssayProtocolAir } from './essayProtocolAir';

export class CompressedGas {
    id: number;

    code: string;
    location: string;

    codeProtocol: string;
    locationProtocol: string;

    idArea: number;

    idType: number;
    others: string;

    idTypeProtocol: number;
    othersProtocol: string;

    order: number;

    idOffline: string;

    essays: EssayProtocolAir[] = [];
}

export enum CompressedGasEnum {
    COMPRESSED_AIR = 1, N2 = 2, CO2 = 3, OTHERS = 4
}
