import { AttachedOffline, ChangeOffline } from '../model/storedData';

import { Area } from '../model/area';
import { AreaService } from './areaService';
import { AttachmentAirService } from './attachmentAir.service';
import { AttachmentType } from '../model/attachment';
import { CompressedGas } from '../model/compressedGasesAir';
import { CompressedGasService } from './compressedGasService';
import { DexieService } from './dexieService';
import { EquipmentAir } from '../model/equipment';
import { EssayAirService } from './essayAir.service';
import { EssayProtocolAir } from '../model/essayProtocolAir';
import { ExecutionAir } from '../model/execution';
import { ExecutionAirService } from './executionAir.service';
import { ExecutionEquipmentService } from './executionEquipmentService';
import { FileUtils } from '../utils/fileUtils';
import { FilterAir } from '../model/filterAir';
import { FilterService } from './filterService';
import { Injectable } from '@angular/core';
import { RoomAir } from '../model/roomAir';
import { RoomService } from './roomService';
import { Volume } from '../model/volume';
import { VolumeService } from './volumeService';
import { DateUtils } from '../utils/dateUtils';

@Injectable()
export class SaveExecutionOfflineService {

  constructor(
    private areaService: AreaService,
    private attachmentAirService: AttachmentAirService,
    private compressedGasService: CompressedGasService,
    private dexieService: DexieService,
    private essayAirService: EssayAirService,
    private executionEquipmentService: ExecutionEquipmentService,
    private filterService: FilterService,
    private roomService: RoomService,
    private volumeService: VolumeService,
    private executionService: ExecutionAirService) {
  }

  saveOfflineChanges(): Promise<void[]> {
    const promises: Promise<void>[] = [];

    void this.dexieService.changes.toArray().then((changes: ChangeOffline[]) => {
      promises.push(new Promise<void>((resolve) => changes.forEach(change => {
        const timeStamp = change.timestamp;
        /*
        const timeStampDate = DateUtils.strTimeToDate(timeStamp);

        const now = new Date();
        now.setSeconds(now.getSeconds() - 7);

        if (timeStampDate.getTime() < now.getTime()) {
          void this.dexieService.changes.delete(change.id).then(() => resolve());
          return;
        }
        */

        const executionId = change.execution_id;
        // Check if the type of change is area
        /* if (change.type === 'execution') {
          void this.executionService.update(change.changes as ExecutionAir).subscribe(() => {
            change.deleted = true;
            change.dateDeleted = DateUtils.getTimestampOffline();
            void this.dexieService.changes.update(change.id, change).then(() => resolve());
            // void this.dexieService.changes.delete(change.id).then(() => resolve());
          });
        } */

        if (change.deleted) {
          return;
        }

        if (!executionId) {
          return;
        }

        if (change.type === 'area') {
          const area = change.changes as Area;
          if (area.id != null) {
            this.areaService.update(area, timeStamp).subscribe(() => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          } else {
            this.areaService.save(area, timeStamp).subscribe(() => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          }
        } else if (change.type === 'areaDelete') {
          this.areaService.delete(change.changes as Area, timeStamp).subscribe(() => {
            change.deleted = true;
            change.dateDeleted = DateUtils.getTimestampOffline();
            void this.dexieService.changes.update(change.id, change).then(() => resolve());
            // void this.dexieService.changes.delete(change.id).then(() => resolve());
          });
        } else if (change.type === 'room') {
          const room = change.changes as RoomAir;
          if (room.id != null) {
            this.roomService.update(room, timeStamp, executionId).subscribe(() => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          } else {
            this.roomService.save(room, timeStamp, executionId).subscribe((res: RoomAir) => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
            });
          }
        } else if (change.type === 'roomDelete') {
          this.roomService.delete(change.changes as RoomAir, timeStamp, executionId).subscribe(() => {
            change.deleted = true;
            change.dateDeleted = DateUtils.getTimestampOffline();
            void this.dexieService.changes.update(change.id, change).then(() => resolve());
            // void this.dexieService.changes.delete(change.id).then(() => resolve());
          });
        } else if (change.type === 'gas') {
          const gas = change.changes as CompressedGas;
          if (gas.id != null) {
            this.compressedGasService.update(gas, timeStamp, executionId).subscribe(() => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          } else {
            this.compressedGasService.save(gas, timeStamp, executionId).subscribe((res: CompressedGas) => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          }
        } else if (change.type === 'gasDelete') {
          this.compressedGasService.delete(change.changes as CompressedGas, timeStamp, executionId).subscribe(() => {
            change.deleted = true;
            change.dateDeleted = DateUtils.getTimestampOffline();
            void this.dexieService.changes.update(change.id, change).then(() => resolve());
            // void this.dexieService.changes.delete(change.id).then(() => resolve());
          });
         } else if (change.type === 'execution') {
          this.executionService.update(change.changes as ExecutionAir).subscribe(() => {
            change.deleted = true;
            change.dateDeleted = DateUtils.getTimestampOffline();
            void this.dexieService.changes.update(change.id, change).then(() => resolve());
            // void this.dexieService.changes.delete(change.id).then(() => resolve());
          });
        } else if (change.type === 'equipment') {
          const eq = change.changes as EquipmentAir;
          if (eq.id != null) {
            this.executionEquipmentService.update(eq, timeStamp, executionId).subscribe(() => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          } else {
            this.executionEquipmentService.save(eq, timeStamp, executionId).subscribe((res: EquipmentAir) => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          }
        } else if (change.type === 'equipmentDelete') {
          this.executionEquipmentService.delete(change.changes as EquipmentAir, timeStamp, executionId).subscribe(() => {
            change.deleted = true;
            change.dateDeleted = DateUtils.getTimestampOffline();
            void this.dexieService.changes.update(change.id, change).then(() => resolve());
            // void this.dexieService.changes.delete(change.id).then(() => resolve());
          });
        } else if (change.type === 'essayRoom') {
          const essay = change.changes as EssayProtocolAir;
          if (essay.id != null) {
            this.essayAirService.update(essay, timeStamp, executionId).subscribe(() => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          } else {
            this.essayAirService.save(essay, timeStamp, executionId).subscribe((res: EssayProtocolAir) => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          }
        } else if (change.type === 'essayRoomDelete') {
          this.essayAirService.delete(change.changes as EssayProtocolAir, timeStamp, executionId).subscribe(() => {
            change.deleted = true;
            change.dateDeleted = DateUtils.getTimestampOffline();
            void this.dexieService.changes.update(change.id, change).then(() => resolve());
            // void this.dexieService.changes.delete(change.id).then(() => resolve());
          });
        } else if (change.type === 'essayGas') {
          const essay = change.changes as EssayProtocolAir;
          if (essay.id != null) {
            this.essayAirService.update(essay, timeStamp, executionId).subscribe(() => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          } else {
            this.essayAirService.save(essay, timeStamp, executionId).subscribe((res: EssayProtocolAir) => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          }
        } else if (change.type === 'essayGasDelete') {
          this.essayAirService.delete(change.changes as EssayProtocolAir, timeStamp, executionId).subscribe(() => {
            change.deleted = true;
            change.dateDeleted = DateUtils.getTimestampOffline();
            void this.dexieService.changes.update(change.id, change).then(() => resolve());
            // void this.dexieService.changes.delete(change.id).then(() => resolve());
          });
        } else if (change.type === 'essayEquipment') {
          const essay = change.changes as EssayProtocolAir;
          if (essay.id != null) {
            this.essayAirService.update(essay, timeStamp, executionId).subscribe(() => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          } else {
            this.essayAirService.save(essay, timeStamp, executionId).subscribe((res: EssayProtocolAir) => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          }
        } else if (change.type === 'essayEquipmentDelete') {
          this.essayAirService.delete(change.changes as EssayProtocolAir, timeStamp, executionId).subscribe(() => {
            change.deleted = true;
            change.dateDeleted = DateUtils.getTimestampOffline();
            void this.dexieService.changes.update(change.id, change).then(() => resolve());
            // void this.dexieService.changes.delete(change.id).then(() => resolve());
          });
        } else if (change.type === 'volumeRoom') {
          const volume = change.changes as Volume;
          if (volume.id != null) {
            this.volumeService.update(volume, timeStamp, executionId).subscribe(() => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          } else {
            this.volumeService.save(volume, timeStamp, executionId).subscribe(() => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          }
        } else if (change.type === 'volumeDelete') {
          this.volumeService.delete(change.changes as Volume, timeStamp, executionId).subscribe(() => {
            change.deleted = true;
            change.dateDeleted = DateUtils.getTimestampOffline();
            void this.dexieService.changes.update(change.id, change).then(() => resolve());
            // void this.dexieService.changes.delete(change.id).then(() => resolve());
          });
        } else if (change.type === 'filterRoom') {
          const filter = change.changes as FilterAir;
          if (filter.id != null) {
            this.filterService.update(filter, timeStamp, executionId).subscribe(() => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          } else {
            this.filterService.save(filter, timeStamp, executionId).subscribe((res: FilterAir) => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          }
        } else if (change.type === 'filterRoomDelete') {
          this.filterService.delete(change.changes as FilterAir, timeStamp, executionId).subscribe(() => {
            change.deleted = true;
            change.dateDeleted = DateUtils.getTimestampOffline();
            void this.dexieService.changes.update(change.id, change).then(() => resolve());
            // void this.dexieService.changes.delete(change.id).then(() => resolve());
          });
        } else if (change.type === 'filterEq') {
          const filter = change.changes as FilterAir;
          if (filter.id != null) {
            this.filterService.update(filter, timeStamp, executionId).subscribe(() => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          } else {
            this.filterService.save(filter, timeStamp, executionId).subscribe((res: FilterAir) => {
              change.deleted = true;
              change.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.changes.update(change.id, change).then(() => resolve());
              // void this.dexieService.changes.delete(change.id).then(() => resolve());
            });
          }
        } else if (change.type === 'filterEqDelete') {
          this.filterService.delete(change.changes as FilterAir, timeStamp, executionId).subscribe(() => {
            change.deleted = true;
            change.dateDeleted = DateUtils.getTimestampOffline();
            void this.dexieService.changes.update(change.id, change).then(() => resolve());
            // void this.dexieService.changes.delete(change.id).then(() => resolve());
          });
        }
      })));

      promises.push(new Promise<void>((resolve) => void this.dexieService.attached
        .filter((x: AttachedOffline) => x.type === 'file').toArray((exAtt: AttachedOffline[]) => {
          exAtt.forEach(att => {
            const blob = FileUtils.toFile(att.blob, att.fileName);
            this.attachmentAirService.uploadAttachmentToExecution(att.idExecution, blob, AttachmentType.OTHERS).subscribe(() => {
              att.deleted = true;
              att.dateDeleted = DateUtils.getTimestampOffline();
              void this.dexieService.attached.delete(att.id).then(() => resolve());
            });
          });
        })));

    });

    return Promise.all(promises);
  }

  deleteCacheDixie(): void  {
    void this.dexieService.changes.toArray().then((changes: ChangeOffline[]) => {
      changes.forEach(change => {
        const timeStamp = change.dateDeleted;

        const timeStampDate = DateUtils.strTimeToDate(timeStamp);
        const now = new Date();
        timeStampDate.setDate(timeStampDate.getDate() + 7);
        if (now.getTime() > timeStampDate.getTime()) {
          void this.dexieService.changes.delete(change.id).then(() => {});
          return;
        }
      });
    });

    void this.dexieService.attached.toArray().then((changes: AttachedOffline[]) => {
      changes.forEach(change => {
        const timeStamp = change.dateDeleted ? change.dateDeleted : change.timestamp;
        const timeStampDate = DateUtils.strTimeToDate(timeStamp);
        const now = new Date();
        timeStampDate.setDate(timeStampDate.getDate() + 7);
        if (now.getTime() > timeStampDate.getTime()) {
          void this.dexieService.attached.delete(change.id).then(() => {});
          return;
        }
      });
    });
  }
}
