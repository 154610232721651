import { GenericClassTranslate } from './genericClass';

export class CriteriaType {
    id: number;
    translation: string;
    variablesToShow: number;

    defaultValue1: number;
    defaultValue2: number;

    hasCustomValues: boolean;
}

export class CriteriaTypeCustomValue extends GenericClassTranslate {
    value: string;
}

export class CriteriaTypeCustomSubValue extends GenericClassTranslate {
    value: string;

   idCriteriaTypeCustom: number;
}

export enum CriteriaTypeEnum {
    NO_COLD_NO_HOT = 1,
    NO_COLD = 2,
    NO_HOT = 3,
    LETHALITY_GREAT_EQ = 4,
    GREATER_THAN = 5,
    LOWER_THAN = 6,
    GREATER_EQUAL = 7,
    LOWER_EQUAL = 8,
    TOLERANCE = 9,
    RANGE = 10,
    INFORMATIVE = 11,
    DIFF_VALUE = 12,
    DEVIATION_VALUE_AVG = 13,
    LETHALITY_GREAT = 14,
    DIFF_AVG_VALUE = 15,
    HIGH_PEAK = 16,
    LOW_PEAK = 17,
    CHANGE_COLOR = 18,
    UNIFORMITY_LE = 19,
    UNIFORMITY_LOWER = 20,
    STABILITY_LE = 21,
    STABILITY_LOWER = 22,
    NOT_APPLY_STABILITY = 23,
    NOT_APPLY_UNIFORMITY = 24,
    INFORMATIVE_STABILITY = 25,
    INFORMATIVE_UNIFORMITY = 26,
    DELTA_GREATER_THAN = 27,
    DELTA_LOWER_THAN = 28,
    DELTA_GREATER_EQUAL = 29,
    DELTA_LOWER_EQUAL = 30,
    STANDARD_DEVIATION_LE = 31,
    STANDARD_DEVIATION_LOWER = 32,
    GRADE_CLASS = 33,
    CUSTOM = 34,
    NO_LEAKS = 35,
    GRADE = 36,
    DEW_POINT = 38,
    GRADE_ANEXOI = 44,
    CLASS_GRADE_GAS= 37,
    CLASS_HYDROCARBURE = 39
}

export enum CriteriaCustomValueEnum {
    GRADE = 1,
    CLASS = 2
}

export enum ParticleSizeEnum {
    ZERO_DOT_ONE = '0.1',
    ZERO_DOT_TWO = '0.2',
    ZERO_DOT_THREE = '0.3',
    ZERO_DOT_FIVE = '0.5',
    ONE_DOT_ZERO = '1.0',
    FIVE_DOT_ZERO = '5.0',
}

export enum SpeedTypeCriteria {
    FRONTAL_SPEED = 'frontalSpeed',
    AVERAGE_SPEED = 'averageSpeed',
    EXTRACTION_SPEED = 'extractionSpeed',
    GLOVE_SPEED = 'gloveSpeed',
    IMPULSION_SPEED = 'ImpulsionSpeed',
}
