import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Client } from 'src/app/model/client';
import { GenericClass } from 'src/app/model/genericClass';
import { Phase } from 'src/app/model/phase';
import { User } from 'src/app/model/user';
import { ClientService } from 'src/app/services/client.service';
import { PhaseService } from 'src/app/services/phase.service';
import { ProcessService } from 'src/app/services/process.service';

import { UsersService } from 'src/app/services/users.service';
import { CustomDatepickerHeaderComponent } from '../../shared/datepicker-custom-header/datepicker-custom-header.component';
import { ExecutionQFilter, ExecutionStatus} from 'src/app/model/execution';
import { ExecutionStatusService } from 'src/app/services/executionStatus.service';


@Component({
  selector: 'app-execution-list-q-filter',
  templateUrl: './execution-list-q-filter.component.html'
})
export class ExecutionListFilterComponent implements OnInit {

  @Output() filterEmitter = new EventEmitter<ExecutionQFilter>();
  
  filter: ExecutionQFilter;
  form: FormGroup;
  isList: boolean;
  clients: Client[];
  clientsFiltered: Client[];
  private destroy$ = new Subject<void>();
  expandedFilter = true;
  statuses: GenericClass[];
  processes: GenericClass[];
  projectManagers: User[];
  users: User[];
  customDatepickerHeader = CustomDatepickerHeaderComponent;

  constructor(
    fb: FormBuilder,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private executionStatus: ExecutionStatusService,
    private processService: ProcessService,
    private usersService: UsersService) {
    this.cleanFilter();
  }

  ngOnInit(): void {
    this.clients = [];
    this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Client[]) => {
      const empty = new Client();
      empty.id = null;
      empty.name = '---';

      data = [empty].concat(data);

      this.clients = data;
      this.clientsFiltered = this.clients.slice();
    });
    this.statuses = [];
    this.executionStatus.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.statuses = data);

    this.processes = [];
    this.processService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.processes = data);
    
    this.projectManagers = [];
    this.usersService.findAllRespFromGroup().pipe(takeUntil(this.destroy$)).subscribe((data: User[]) => this.projectManagers = data);    
  }

  cleanFilter(): void {
    this.filter = new ExecutionQFilter();

    if (this.isList === false) {
      this.filter.idStatus = ExecutionStatus.FIRMADO;
    }
  }

  doFilter(): void {
    this.expandedFilter = false;

    this.filterEmitter.emit(this.filter);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  saveDate(field: string, event: MatDatepickerInputEvent<Date>): void {

    let dateValue = event.value;
    if (dateValue != null) {   
      this.filter[field] = new Date(dateValue);
    } else {
      this.filter[field] = null;
    }
  }
}
