import * as _ from 'lodash';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';

import { DexieService } from './dexieService';
import { EssayProtocolAir } from '../model/essayProtocolAir';
import { EssayType } from '../model/essayType';
import { Field } from '../model/field';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { OnlineService } from './online.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class EssayAirService {

    constructor(private http: HttpClient,
        private dexieService: DexieService,
        private onlineService: OnlineService) {
    }

    save(essay: EssayProtocolAir, timeStamp: string, executionId: number): Observable<any> {
        const url = environment.airUrl + `/execution/essay/create/${executionId}`;
        const headers = new HttpHeaders({
            timeStamp
        });
        return this.http.post(url, essay, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    update(e: EssayProtocolAir, timeStamp: string, executionId: number): Observable<any> {

        const essay = _.cloneDeep(e);

        const url = environment.airUrl + `/execution/essay/${essay.id}/${executionId}`;
        const headers = new HttpHeaders({
            timeStamp
        });

        delete essay.idOffline;

        return this.http.put(url, essay, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    delete(essay: EssayProtocolAir, timeStamp: string, executionId: number): Observable<any> {
        const url = environment.airUrl + `/execution/essay/${essay.id}/${executionId}`;
        const headers = new HttpHeaders({
            timeStamp
        });

        delete essay.idOffline;

        return this.http.delete(url, { headers }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findByEquipmentId(idEquipment: number): Observable<any> {

        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + `/air/essay/equipment/${idEquipment}`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));

        } else {
            return from(this.findByEquipmentIdOffline(idEquipment, 'idEquipmentType'));
        }
    }

    findByRoomId(idEquipment: number): Observable<any> {

        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + `/air/essay/room/${idEquipment}`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.findByEquipmentIdOffline(idEquipment, 'idRoom'));
        }
    }

    findByCompressedGasId(idEquipment: number): Observable<any> {

        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + `/air/essay/compressedGas/${idEquipment}`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));

        } else {
            return from(this.findByEquipmentIdOffline(idEquipment, 'idCompressedGas'));
        }
    }

    findFieldsById(idEssay: number): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + `/air/essay/${idEssay}/fields`;

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.essayFields.where({ idEssay }).toArray());
        }
    }

    findAllFields(): Observable<any> {
        const url = environment.coreUrl + '/air/essay/fields';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllAir(): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + '/air/essay/';

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.essayTypes.toArray().timeout(30000));
        }
    }

    saveEssayTypeToDexie(): Promise<void> {
        return new Promise<void>((resolve) => this.findAllAir().subscribe((res: EssayType[]) => {
            if (res) {
                void this.dexieService.essayTypes.clear().then(() => {
                    res.forEach(type => {
                        void this.dexieService.essayTypes.put(type).then(() => resolve());
                    });
                });
            }
        }));
    }

    saveEssayFieldsToDexie(): Promise<void> {
        return new Promise<void>((resolve) => this.findAllFields().subscribe((res: Field[]) => {
            if (res) {
                void this.dexieService.essayFields.clear().then(() => {
                    res.forEach(type => {
                        void this.dexieService.essayFields.put(type, type.id).then(() => resolve());
                    });
                });
            }
        }));
    }

    private async findByEquipmentIdOffline(idEquipment: number, type: string): Promise<any> {
        const essaysId = [];
        const search = {};
        search[type] = idEquipment
        const result = await this.dexieService.essayConfig.where(search).toArray();

        result.forEach(res => essaysId.push(res.idEssay));
        return this.dexieService.essayTypes.where('id').anyOf(essaysId).toArray();
    }
}
