<h1 mat-dialog-title>{{ 'common.reportType.title' | translate }}</h1>
<form>

  <mat-accordion class="air-theme">
    <mat-expansion-panel expanded *ngIf="!data.isForAutomaticSign">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'common.reportType.options.reviewBy.label' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="campoFormulario">
        <mat-label>{{ 'common.reportType.options.reviewBy.label' | translate }}</mat-label>
        <mat-select [(ngModel)]="reportConfig.reviewBy" [ngModelOptions]="{standalone: true}">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let item of respDocs" [value]="item.username">
            {{item.fullName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'common.reportType.options.dataToInclude.label' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div *ngFor="let area of data.execution.areas; let indexArea = index">
        <mat-checkbox (change)="onAreaSelectChange(area, $event)" [checked]="isAreaSelected(area)">
          {{ area.name }}
        </mat-checkbox>

        <div *ngFor="let equipment of area.equipments; let indexEquipment = index">
          <mat-checkbox (change)="onEquipmentSelectChange(equipment, $event)" [checked]="isEquipmentSelected(equipment)"
            style="margin-left: 1em;">
            {{ showEquipment(equipment) }}
          </mat-checkbox>
        </div>

        <div *ngFor="let room of area.rooms; let indexRoom = index">
          <mat-checkbox (change)="onRoomSelectChange(room, $event)" [checked]="isRoomSelected(room)"
            style="margin-left: 1em;">
            {{ room.name }}
          </mat-checkbox>
        </div>

        <div *ngFor="let gas of area.compressedGases; let indexGas = index">
          <mat-checkbox (change)="onCompressedGasSelectChange(gas, $event)" [checked]="isCompressedGasSelected(gas)"
            style="margin-left: 1em;">
            {{ gas.code }}
          </mat-checkbox>
        </div>

      </div>

    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'common.reportType.options.language.label' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="campoFormulario" *ngIf="showChangeLangIcon() && !data.isForAutomaticSign">
        <mat-label>{{ 'common.reportType.options.language.label' | translate }}</mat-label>
        <mat-select [(ngModel)]="reportConfig.lang" [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let item of langs" [value]="item.lang">
            <mat-icon svgIcon="{{item.flag}}"></mat-icon>
            {{item.fullLang}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'common.reportType.options.observations' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-checkbox name="changeObservations" [ngModel]="changeObservations"
        (change)="onChangeObservationsCheck($event)">
        {{'common.reportType.options.observations.label' | translate }}
      </mat-checkbox>

      <button mat-icon-button (click)="reloadObservations()" matTooltip="{{ 'button.reload' | translate }}"
        *ngIf="changeObservations">
        <mat-icon>refresh</mat-icon>
      </button>

      <div *ngIf="changeObservations">
        <br />
        <textarea matInput name="observations" style="resize: none;" [(ngModel)]="reportConfig.observations"
          placeholder="{{ 'common.reportType.options.observations.field' | translate }}" rows="10"
          matAutosizeMinRows="10" style="resize: none;" required> </textarea>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'common.reportType.options.deviations' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-checkbox name="changeDeviations" [ngModel]="changeDeviations" (change)="onChangeDeviationsCheck($event)">
        {{'common.reportType.options.deviations.label' | translate }}
      </mat-checkbox>

      <button mat-icon-button (click)="reloadDeviations()" matTooltip="{{ 'button.reload' | translate }}"
        *ngIf="changeDeviations">
        <mat-icon>refresh</mat-icon>
      </button>

      <div *ngIf="changeDeviations">
        <br />
        <textarea matInput name="deviations" style="resize: none;" [(ngModel)]="reportConfig.deviations"
          placeholder="{{ 'common.reportType.options.deviations.field' | translate }}" rows="10" matAutosizeMinRows="10"
          style="resize: none;" required> </textarea>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'common.reportType.options.conclusions' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-checkbox name="changeConclusions" [ngModel]="changeConclusions" (change)="onChangeConclusionsCheck($event)">
        {{'common.reportType.options.conclusions.label' | translate }}
      </mat-checkbox>

      <button mat-icon-button (click)="reloadConclusions()" matTooltip="{{ 'button.reload' | translate }}"
        *ngIf="changeConclusions">
        <mat-icon>refresh</mat-icon>
      </button>

      <div *ngIf="changeConclusions">
        <br />
        <textarea matInput name="conclusions" style="resize: none;" [(ngModel)]="reportConfig.conclusions"
          placeholder="{{ 'common.reportType.options.conclusions.field' | translate }}" rows="10"
          matAutosizeMinRows="10" style="resize: none;" required> </textarea>
      </div>
    </mat-expansion-panel>

  </mat-accordion>

  <br /><br />

  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick(false)" cdkFocusInitial>
      {{ 'button.download' | translate }}</button>
    <button mat-raised-button color="primary" (click)="onOkClick(true)"
      cdkFocusInitial>{{ 'button.download.hybride' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>