/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AfterViewInit, Component, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TestQConfig } from '../../../../model/testQConfig';
import { TestQType, TestTypeEnum } from '../../../../model/testQType';
import { TestQTypeService } from '../../../../services/testQType.service';
import { Subject, takeUntil } from 'rxjs';
import { TestQElementTypeService } from '../../../../services/testQElementType.service';
import { TestQElementType } from '../../../../model/testQElementType';
import { VerificationQTypeService } from '../../../../services/verificationQType.service';
import { VerificationQType } from '../../../../model/verificationQType';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ExpectedResultTypeService } from '../../../../services/expectedResultType.service';
import { ExpectedResultType } from 'src/app/model/expectedResultType';
import { ResultQTypeService } from '../../../../services/resultQType.service';
import { ResultQType } from '../../../../model/resultQType';
import { SnackBarService } from '../../../../services/snackBar.service';
import { TestQConfigService } from '../../../../services/testQConfig.service';
import { TestQDataVerificationService } from '../../../../services/testQDataVerification.service';
import { CustomDatepickerHeaderComponent } from '../../../shared/datepicker-custom-header/datepicker-custom-header.component';
import { TestQItemService } from '../../../../services/testQItem.service';
import { TestQItem } from '../../../../model/testQItem';
import { TranslateService } from '@ngx-translate/core';

import { TestQResultsService } from '../../../../services/testQResults.service';
import { TestQResults } from '../../../../model/testQResults';

import { TestQDataOQService } from '../../../../services/testQDataOQ.service';
import { TestQDataOQ } from '../../../../model/testQDataOQ';
import { TestGeneralDataConfig } from 'src/app/model/testGeneralDataConfig';
import { TestGeneralDataConfigService } from 'src/app/services/testGeneralDataConfig.service';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { ReasonDialogComponent } from 'src/app/components/shared/reason-dialog/reason-dialog.component';
import { MatSelectChange } from '@angular/material/select';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as _ from 'lodash';
import { ResizeImageComponent } from '../../../shared/resize-image/resize-image.component';
import { AttachmentQualificatesService } from '../../../../services/attachmentQualificates.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { FileInfo } from '../../../../model/attachment';
import { ExecutionQTest, ExecutionQTestData, ExecutionQTestDataOQ, ExecutionQTestDeviationData, ExecutionQTestGeneralData, ExecutionQTestItem, ExecutionQTestItemVerification, ExecutionQTestProbeTraceability, ExecutionQTestResults, ExecutionStatus } from 'src/app/model/execution';
import { ExecutionEditTestAttachmentsComponent } from '../execution-edit-test-attachments/execution-q-edit-test-attachments.component';
import { EquipmentAutocompleteFilter } from '../../../../model/autocompleteFilter';
import { InternalEquipmentService } from '../../../../services/internalEquipment.service';
import { InternalEquipment } from '../../../../model/internalEquipment';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import * as moment from 'moment';
import { ExecutionEditQDeviationComponent } from '../execution-edit-q-deviation/execution-edit-q-deviation.component';
import { ExecutionQTestDeviations } from '../../../../model/execution';

export class Group {
	level = 0;
	expanded = false;
	totalCounts = 0;
}

export interface DialogQEditTest {
	idItem: number;
	testQType: TestQType;
	reason: string;
	isEdit: boolean;
	isEditTest: boolean;
	data: ExecutionQTest;
	idStatus: number;
	numberTest: number;
	executionNumber: string;
}
export interface DialogDataDeviationList {
	data: ExecutionQTestDeviationData;
}
@Component({
	selector: 'app-execution-q-edit-test',
	templateUrl: './execution-q-edit-test.component.html',
	styleUrls: ['./execution-q-edit-test.component.scss']
})
export class ExecutionQEditTestComponent implements OnInit, AfterViewInit {

	@ViewChild(MatSort) sort: MatSort;
	@ViewChild('tableTest', { static: true }) tableTest: MatTable<any>;
	@ViewChildren('attachmentsTestComponent') attachmentsComponent: ExecutionEditTestAttachmentsComponent;

	allData: any[];
	_allGroup: any[];
	dataSource: ExecutionQTest = new ExecutionQTest();

	displayedColumns: string[] = ['verificationTypeDesc'];
	columnsToDisplay: string[] = this.displayedColumns.slice();
	groupByColumns: string[] = [];

	testConfig: TestQConfig = new TestQConfig();
	testQTypes: TestQType[];
	testQElementTypes: TestQElementType[];
	verificationQTypes: VerificationQType[];
	expectedResultTypes: ExpectedResultType[];
	resultQTypes: ResultQType[];
	columns: any[] = [];
	expandedCar: any[] = [];
	fileName: string | null = null;

	currentUser: User;

	public equipmentName: string;

	public equipmentAutoComplete: InternalEquipment[];

	customDatepickerHeader = CustomDatepickerHeaderComponent;

	quillConfig = {
		toolbar: [
			['bold', 'italic', 'underline'],
			[{ list: 'ordered' }, { list: 'bullet' }]
		]
	};
	selectedOption: string [] = [];
	isTacometer: boolean;
	tacometer: string;

	private destroy$ = new Subject<void>();

	constructor(private testQTypeService: TestQTypeService,
		private testQElementTypeService: TestQElementTypeService,
		private verificationQTypeService: VerificationQTypeService,
		private expectedResultTypeService: ExpectedResultTypeService,
		private resultQTypeService: ResultQTypeService,
		private snackBarService: SnackBarService,
		private testQConfigService: TestQConfigService,
		private testQResultsService: TestQResultsService,
		private testQDataVerificationService: TestQDataVerificationService,
		private testGeneralDataConfigService: TestGeneralDataConfigService,
		private testQDataOQService: TestQDataOQService,
		private testQItemService: TestQItemService,
		private dialogRef: MatDialogRef<ExecutionQEditTestComponent>,
		private translate: TranslateService,
		private userService: UserService,
		private attachmentService: AttachmentQualificatesService,
		private spinnerService: SpinnerService,
		private internalEquipmentService: InternalEquipmentService,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: DialogQEditTest,
		@Inject(MAT_DIALOG_DATA) public dataList: DialogDataDeviationList) {
		this.currentUser = this.userService.currentProfile;
	}
	ngAfterViewInit(): void {
		if (this.attachmentsComponent instanceof QueryList) {
			this.attachmentsComponent = this.attachmentsComponent.first as ExecutionEditTestAttachmentsComponent;
		}

		if (this.attachmentsComponent) {
			this.attachmentsComponent.setIdExecution(this.data.data.idExecution);
			this.attachmentsComponent.setIdStatus(this.data.idStatus);
			this.attachmentsComponent.setIdTest(this.data.data.id);
		}
	}

	ngOnInit(): void {
		if (!this.data.data) {
			this.data.data = new ExecutionQTest();
		} else {
			this.dataSource = this.data.data;
		}
		this.data.data.testDescription = this.translate.instant('testQualification.' + this.data.testQType.translation) as string;

		this.testQTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((result: TestQType[]) => {
			this.testQTypes = result;
		});

		this.testQElementTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((result: TestQElementType[]) => {
			this.testQElementTypes = result;
		});

		this.verificationQTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((result: VerificationQType[]) => {
			this.verificationQTypes = result;
		});

		this.expectedResultTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((result: ExpectedResultType[]) => {
			this.expectedResultTypes = result;
		});

		this.resultQTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((result: ResultQType[]) => {
			this.resultQTypes = result;
		});

		if (this.data.testQType) {
			this.testQConfigService.findByIdTest(this.data.testQType.id).pipe(takeUntil(this.destroy$)).subscribe((result: TestQConfig) => {
				this.testConfig = result;
			});

			if (!this.data.data.executionQTestItem) {
				// eslint-disable-next-line max-len
				this.testQItemService.findAllByIdTest(this.data.testQType.id).pipe(takeUntil(this.destroy$)).subscribe((result: TestQItem[]) => {
					result?.forEach(r => {
						const executionQTestItem = new ExecutionQTestItem();
						executionQTestItem.description = r.description;
						executionQTestItem.idType = r.idType;
						executionQTestItem.executionQTestItemVerification = [];
						r.testQItemVerifications?.forEach(rt => {
							const executionQTestItemVerification = new ExecutionQTestItemVerification();
							executionQTestItemVerification.verification = rt.verification;
							rt.testQDataMultiple.forEach(pte => {
								const executionQTestData = new ExecutionQTestData();
								executionQTestData.expectedResultRhDesc = pte.expectedResultRhDesc;
								executionQTestData.expectedResultDesc = pte.expectedResultDesc;
								executionQTestData.resultDesc = pte.resultDesc;
								if (!executionQTestItemVerification.executionQTestDataMultiple) {
									executionQTestItemVerification.executionQTestDataMultiple = [];
								}
								executionQTestItemVerification.executionQTestDataMultiple.push(executionQTestData);
							});

							executionQTestItem.executionQTestItemVerification.push(executionQTestItemVerification);
						});

						if (!this.dataSource?.executionQTestItem) {
							this.dataSource.executionQTestItem = [];
						}
						this.dataSource?.executionQTestItem.push(executionQTestItem);
					})
				});

			} else {
				this.dataSource.executionQTestItem = this.data.data.executionQTestItem;
			}

			if (!this.data.data.executionQTestVerification) {
				// eslint-disable-next-line max-len
				this.testQDataOQService.findTestQDataOQByIdTest(this.data.testQType.id).pipe(takeUntil(this.destroy$)).subscribe((result: TestQDataOQ[]) => {
					result?.forEach(r => {
						const executionQTestOQ = new ExecutionQTestDataOQ();
						executionQTestOQ.descriptionTest = r.descriptionTest;
						executionQTestOQ.test = r.test;
						executionQTestOQ.resultDesc = r.resultDesc;

						if (!this.dataSource.executionQTestVerification) {
							this.dataSource.executionQTestVerification = [];
						}
						this.dataSource?.executionQTestVerification.push(executionQTestOQ);
					});
				})
			} else {
				this.dataSource.executionQTestVerification = this.data.data.executionQTestVerification;
			}

			if (!this.data.data.executionQTestGeneralData) {
				this.testGeneralDataConfigService.getTestGeneralDataConfigById(this.data.testQType.id).pipe(takeUntil(this.destroy$)).subscribe((result: TestGeneralDataConfig[]) => {
					result?.forEach(r => {
						const executionGeneral = new ExecutionQTestGeneralData();
						executionGeneral.methodology = r.methodology;
						executionGeneral.objective = r.objective;
						executionGeneral.criteria = r.criteria;

						if (!this.dataSource.executionQTestGeneralData) {
							this.dataSource.executionQTestGeneralData = [];
						}
						this.dataSource?.executionQTestGeneralData.push(executionGeneral)
					});

				})
			} else {
				this.dataSource.executionQTestGeneralData = this.data.data.executionQTestGeneralData;
			}

			if (this.data.data.executionQTestProbeTraceability) {
				this.dataSource.executionQTestProbeTraceability = this.data.data.executionQTestProbeTraceability;
			}

			if (this.data.data.executionQTestBioindicadores) {
				this.dataSource.executionQTestBioindicadores = this.data.data.executionQTestBioindicadores;
			}

			if (!this.data.data.executionQTestResults) {
				// eslint-disable-next-line max-len
				this.testQResultsService.findByIdTest(this.data.testQType.id).pipe(takeUntil(this.destroy$)).subscribe((result: TestQResults[]) => {
					result.forEach(rt => {
						const executionQTestResult = new ExecutionQTestResults();
						executionQTestResult.verification = rt.verification;
						executionQTestResult.resultExpected = rt.resultExpected;
						executionQTestResult.result = rt.result;
						if (!this.dataSource?.executionQTestResults) {
							this.dataSource.executionQTestResults = [];
						}
						this.dataSource?.executionQTestResults.push(executionQTestResult);
					})
				});
			} else {
				this.dataSource.executionQTestResults = this.data.data.executionQTestResults;

			}
		}

		this.onChangeSelectionOption();
	}

	onChangeSelectionOption(): void {
		this.displayedColumns = [];
		this.displayedColumns.push('verificationTypeDesc');
		if (this.testConfig.expectedResult) {
			this.displayedColumns.push('expectedResult')
		}

		if (this.testConfig.obtainedResult) {
			this.displayedColumns.push('obtainedResult')
		}

		if (this.testConfig.testResult) {
			this.displayedColumns.push('testResult')
		}

		this.columnsToDisplay = this.displayedColumns.slice();
	}

	deleteRowItemVerification(indexItem: number, itemData: number): void {
		if (this.dataSource.executionQTestItem[indexItem]) {
			this.dataSource.executionQTestItem[indexItem].executionQTestItemVerification.splice(itemData, 1)
		}
	}

	deleteRowData(indexItem: number, itemValue: number, itemData: number): void {
		if (this.dataSource.executionQTestItem[indexItem]) {
			this.dataSource.executionQTestItem[indexItem].executionQTestItemVerification[itemValue].executionQTestDataMultiple.splice(itemData, 1)
		}
	}

	deleteRowItem(indexItem: number): void {
		this.dataSource.executionQTestItem.splice(indexItem, 1)
	}

	onOkClick(): void {
		const errs = [];

		if (this.testConfig.expectedResult) {
			if (!this.dataSource.executionQTestItem) {
				errs.push(this.translate.instant('executionTest.probe.add.error'));
			} else {
				// eslint-disable-next-line @typescript-eslint/prefer-for-of, @typescript-eslint/no-unsafe-member-access
				for (let index = 0; index < this.dataSource.executionQTestItem.length; index++) {
					if (!this.dataSource.executionQTestItem[index] && this.testConfig.item) {
						errs.push(this.translate.instant('executionTest.verificationType.item.description.error'));
					} else {
						if (!this.dataSource.executionQTestItem[index].description) {
							errs.push(this.translate.instant('executionTest.verificationType.item.error'));
						}
						// eslint-disable-next-line @typescript-eslint/prefer-for-of, max-len
						for (let indexItemValue = 0; indexItemValue < this.dataSource.executionQTestItem[index].executionQTestItemVerification.length; indexItemValue++) {
							if (!this.dataSource.executionQTestItem[index].executionQTestItemVerification[indexItemValue]) {
								errs.push(this.translate.instant('executionTest.verificationType.add.error'));
							} else {
								if (!this.dataSource.executionQTestItem[index].executionQTestItemVerification[indexItemValue].verification) {
									errs.push(this.translate.instant('executionTest.verificationType.descripction.error'));
								}

								for (let indexData = 0; indexData < this.dataSource.executionQTestItem[index].executionQTestItemVerification[indexItemValue].executionQTestDataMultiple.length; indexData++) {
									if (!this.dataSource.executionQTestItem[index].executionQTestItemVerification[indexItemValue].executionQTestDataMultiple) {
										errs.push(this.translate.instant('executionTest.verificationType.add.error'));
									} else {
										
										if (!this.dataSource.executionQTestItem[index].executionQTestItemVerification[indexItemValue].executionQTestDataMultiple[indexData].resultDesc || this.dataSource.executionQTestItem[index].executionQTestItemVerification[indexItemValue].executionQTestDataMultiple[indexData].resultDesc?.length === 0) {
											errs.push(this.translate.instant('executionTest.verificationType.resultDesc.error'));
										} else {
											if (this.dataSource.executionQTestItem[index].executionQTestItemVerification[indexItemValue].executionQTestDataMultiple[indexData].selectedResult === 'not_according') {
												if (!this.dataSource.executionQTestItem[index].executionQTestItemVerification[indexItemValue].executionQTestDataMultiple[indexData].executionQTestDeviations) {
													errs.push(this.translate.instant('executionTest.deviation.resultDesc.empty'));
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		if (this.testConfig.probeTraceability) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (!this.dataSource.executionQTestProbeTraceability || this.dataSource.executionQTestProbeTraceability?.length === 0) {
				errs.push(this.translate.instant('executionTest.probe.add.error'));
			} else {
				// eslint-disable-next-line @typescript-eslint/prefer-for-of, @typescript-eslint/no-unsafe-member-access
				for (let index = 0; index < this.dataSource.executionQTestProbeTraceability.length; index++) {
					if (!this.dataSource.executionQTestProbeTraceability[index].descriptionProbe) {
						errs.push(this.translate.instant('executionTest.probe.descripction.error'));
					}
				}
			}
		}

		if (this.testConfig.testResult) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (!this.dataSource.executionQTestResults || this.dataSource.executionQTestResults.length === 0) {
				errs.push(this.translate.instant('executionTest.probe.add.error'));
			} else {
				// eslint-disable-next-line @typescript-eslint/prefer-for-of, @typescript-eslint/no-unsafe-member-access
				for (let index = 0; index < this.dataSource.executionQTestResults.length; index++) {
					if (!this.dataSource.executionQTestResults[index].resultExpected) {
						errs.push(this.translate.instant('executionTest.test.result.resultExpected.error'));
					} else {
						const valueTestData = this.dataSource.executionQTestResults[index].resultExpected?.filter(e => e === '');
						if (valueTestData != null && valueTestData?.length > 0) {
							errs.push(this.translate.instant('executionTest.verificationType.expectedResultDesc.error'));
						}
					}
					if (!this.dataSource.executionQTestResults[index].result || this.dataSource.executionQTestResults[index].result?.length === 0) {
						errs.push(this.translate.instant('executionTest.test.result.result.error'));
					}
					if (!this.dataSource.executionQTestResults[index].verification) {
						errs.push(this.translate.instant('executionTest.test.result.verification.error'));
					}
				}
			}
		}

		if (this.testConfig.bioTraceability) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (!this.dataSource.executionQTestBioindicadores || this.dataSource.executionQTestBioindicadores.length === 0) {
				errs.push(this.translate.instant('executionTest.bioindicador.add.error'));
			} else {
				// eslint-disable-next-line @typescript-eslint/prefer-for-of, @typescript-eslint/no-unsafe-member-access
				for (let index = 0; index < this.dataSource.executionQTestBioindicadores.length; index++) {
					if (!this.dataSource.executionQTestBioindicadores[index].bioDescription) {
						errs.push(this.translate.instant('executionTest.bioindicador.description.error'));
					}
				}
			}
		}

		if (this.testConfig.testVerification) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (!this.dataSource.executionQTestVerification || this.dataSource.executionQTestVerification.length === 0) {
				errs.push(this.translate.instant('executionTest.testVerification.add.error'));
			} else {
				// eslint-disable-next-line @typescript-eslint/prefer-for-of, @typescript-eslint/no-unsafe-member-access
				for (let index = 0; index < this.dataSource.executionQTestVerification.length; index++) {
					if (!this.dataSource.executionQTestVerification[index].test) {
						errs.push(this.translate.instant('executionTest.testVerification.test.error'));
					}
					if (!this.dataSource.executionQTestVerification[index].descriptionTest) {
						errs.push(this.translate.instant('executionTest.testVerification.description.test.error'));
					}
					if (!this.dataSource.executionQTestVerification[index].resultDesc || this.dataSource.executionQTestVerification[index].resultDesc?.length === 0) {
						errs.push(this.translate.instant('executionTest.testVerification.result.erro'));
					}
				}

			}
		}

		if (errs.length === 0) {
			if (!this.dataSource.regUser) {
				this.dataSource.regUser = this.currentUser.username;
				this.dataSource.regDate = new Date();
			}

			this.dataSource.modUser = this.currentUser.username;
			this.dataSource.modDate = new Date();

			if (this.data.idStatus === ExecutionStatus.FIRMADO) {

				this.requestReason((reason) => {
					this.dataSource.reason = reason;

					this.dataSource.reason = this.data.reason;
					this.dialogRef.close(this.dataSource);
				});
			} else {
				this.dialogRef.close(this.dataSource);
			}

		} else {
			const error = errs.join('\n');
			this.snackBarService.sendError(error);
		}
	}

	onNoClick(): void {
		this.dialogRef.close();
	}


	private requestReason(callback: (reason: string) => void) {
		const dialogRef = this.dialog.open(ReasonDialogComponent, {
			minWidth: '40%',
			maxHeight: '95vh',
			data: {}
		});

		dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
			if (result != null) {
				callback(result);
			}
		});
	}

	onImageSelected(event: Event) {
		const input = event.target as HTMLInputElement;
		if (!input.files?.length) {
			return;
		}

		const file = input.files[0];
		this.fileName = file.name;

		const reader = new FileReader();

		reader.onload = () => {
			const base64String = reader.result as string;
			// Remover la parte "data:image/jpeg;base64,"
			const base64WithoutPrefix = base64String.split(',')[1];
			this.dataSource.imageTest = base64WithoutPrefix;
			console.log('Image in base64 without prefix:', this.dataSource.imageTest);
		};

		reader.readAsDataURL(file);
	}

	showLastTacometer(index: number) {
		return this.dataSource?.executionQTestProbeTraceability?.length === index + 1;
	}

	lookupEquipment($event): void {
		let results = this.equipmentAutoComplete;
	
		const filter = new EquipmentAutocompleteFilter();
	
		filter.query = $event.target.value as string;
		filter.pageIndex = 0;
		filter.pageSize = 10;
		filter.sortBy = 'name';
		filter.sortDirection = 'asc';
		filter.forExecution = true;
	
		this.internalEquipmentService.findAutocomplete(filter).pipe(takeUntil(this.destroy$)).subscribe(item => {
		  if (item.content != null) {
			results = item.content as InternalEquipment[];
		  } else {
			results = item as InternalEquipment[];
		  }
	
		  this.equipmentAutoComplete = results;
		}, () => {
		  results = [];
		  this.equipmentAutoComplete = results;
		});
	}

	onEquipmentSelected($event: any, trigger: MatAutocompleteTrigger, indexProbe: number): void {
		const equipmentSelected = $event.option.value as InternalEquipment;
	
		if (equipmentSelected.nearToExpire || equipmentSelected.expired) {
	
		  const expirationDate = moment(equipmentSelected.expirationDate).format('DD/MMM/YYYY').replace('./', '/').toUpperCase();
	
		  let message = this.translate.instant('executionEditQ.test.probe.form.error.nearToExpire', { expirationDate }) as string;
	
		  if (equipmentSelected.expired) {
			message = this.translate.instant('executionEditQ.test.probe.form.error.expired') as string;
		  }
	
		  const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });
	
		  dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
			if (result === true) {
			  this.dataSource.executionQTestProbeTraceability[indexProbe].idEquipment = equipmentSelected.id;
			  this.dataSource.executionQTestProbeTraceability[indexProbe].identifier = equipmentSelected.name;
			  this.equipmentName = equipmentSelected.name;
			} else {
			  this.dataSource.executionQTestProbeTraceability[indexProbe].idEquipment = null;
			  this.dataSource.executionQTestProbeTraceability[indexProbe].identifier = null;
			  this.equipmentName = null;
			}
		  });
		} else {
			this.dataSource.executionQTestProbeTraceability[indexProbe].idEquipment = equipmentSelected.id;
			this.dataSource.executionQTestProbeTraceability[indexProbe].identifier = equipmentSelected.name;
		  	this.equipmentName = equipmentSelected.name;
		}
	}

	displayFn(eq?: InternalEquipment | string | { name: string }): string {
		if (!eq) {
		  return null;
		}
	
		let res: string = null;
	
		if (typeof eq === 'string') {
		  res = eq;
		} else if (eq instanceof InternalEquipment || eq.name != null) {
		  res = eq.name;
		}
	
		return res;
	}


	onChangeTacometerCheck(event: MatCheckboxChange, indexProbe) {
		if (event.checked) {
			for (let index = 0; index < this.dataSource.executionQTestProbeTraceability.length; index++) {
				this.dataSource.executionQTestProbeTraceability[index].tacometer = false;
			}
			this.dataSource.executionQTestProbeTraceability[indexProbe].tacometer = true;
		} else {
			this.dataSource.executionQTestProbeTraceability[indexProbe].tacometer = false;
		}
	}

	onChangeExternalCheck(event: MatCheckboxChange, indexProbe) {
		this.dataSource.executionQTestProbeTraceability[indexProbe].external = event.checked;
		this.dataSource.executionQTestProbeTraceability[indexProbe].idEquipment = null;
		this.dataSource.executionQTestProbeTraceability[indexProbe].identifier = null;
	}
	
	onSelectedVerificationResult(event: MatSelectChange, indexItem: number, idxVerification: number, idxMultuple: number) {
		
		const resSelected = this.dataSource.executionQTestItem[indexItem].executionQTestItemVerification[idxVerification].executionQTestDataMultiple[idxMultuple];
		if (event?.value === 'not_according') {
			const dialogRef = this.dialog.open(ExecutionEditQDeviationComponent, {
				minWidth: '70%',
				maxHeight: '95vh',
				data: {
					executionNumber: this.data.executionNumber,
					idDataMultiple: resSelected.id, 
					deviation: _.cloneDeep(resSelected.executionQTestDeviations)

				}
			});
	
			dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionQTestDeviations) => {
				if (result) {
					this.dataSource.executionQTestItem[indexItem].executionQTestItemVerification[idxVerification].executionQTestDataMultiple[idxMultuple].executionQTestDeviations = result;
				}
			});
		} else {
			if (this.dataSource.executionQTestItem[indexItem].executionQTestItemVerification[idxVerification].executionQTestDataMultiple[idxMultuple].executionQTestDeviations) {
				this.dataSource.executionQTestItem[indexItem].executionQTestItemVerification[idxVerification].executionQTestDataMultiple[idxMultuple].executionQTestDeviations = null;
			}
		}
	}

	onSelectedVerificationTestResult(event: MatSelectChange, indexItem: number) {
		const resSelected = this.dataSource.executionQTestResults[indexItem];
		if (event?.value === 'not_according') {
			const dialogRef = this.dialog.open(ExecutionEditQDeviationComponent, {
				minWidth: '70%',
				maxHeight: '95vh',
				data: {
					executionNumber: this.data.executionNumber,
					idDataMultiple: resSelected.id, 
					deviation: _.cloneDeep(resSelected.executionQTestDeviations)

				}
			});
	
			dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionQTestDeviations) => {
				if (result) {
					this.dataSource.executionQTestResults[indexItem].executionQTestDeviations = result;
				}
			});
		} else {
			if (this.dataSource.executionQTestResults[indexItem].executionQTestDeviations) {
				this.dataSource.executionQTestResults[indexItem].executionQTestDeviations = null;
			}
		}
	}

	showDeviationTestResultSelected(indexItem: number) {
		const resSelected = this.dataSource.executionQTestResults[indexItem];
		if (resSelected.executionQTestDeviations) {
			const dialogRef = this.dialog.open(ExecutionEditQDeviationComponent, {
				minWidth: '70%',
				maxHeight: '95vh',
				data: {
					executionNumber: this.data.executionNumber,
					idDataMultiple: resSelected.id, 
					deviation: _.cloneDeep(resSelected.executionQTestDeviations)
				}
			});
	
			dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionQTestDeviations) => {
				if (result) {
					this.dataSource.executionQTestResults[indexItem].executionQTestDeviations = result;
				}
			});
		}
	}

	showDeviationSelected(indexItem: number, idxVerification: number, idxMultuple: number) {
		const resSelected = this.dataSource.executionQTestItem[indexItem].executionQTestItemVerification[idxVerification].executionQTestDataMultiple[idxMultuple];

		if (resSelected.executionQTestDeviations) {
			const dialogRef = this.dialog.open(ExecutionEditQDeviationComponent, {
				minWidth: '70%',
				maxHeight: '95vh',
				data: {
					executionNumber: this.data.executionNumber,
					idDataMultiple: resSelected.id, 
					deviation: _.cloneDeep(resSelected.executionQTestDeviations)
				}
			});
	
			dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionQTestDeviations) => {
				if (result) {
					this.dataSource.executionQTestItem[indexItem].executionQTestItemVerification[idxVerification].executionQTestDataMultiple[idxMultuple].executionQTestDeviations = result;
				}
			});
		}
	}
	
}