<div *ngIf="!anyVariable()">
  {{ 'isothermalCharacterizationEdit.essays.empty' | translate }}
</div>

<mat-toolbar color="secondary" *ngIf="anyVariable()" style="padding-left: 3em;">
  {{ 'isothermalCharacterizationEdit.calibration.title' | translate }}
</mat-toolbar>

<br />

<div fxLayout="row" fxLayout.xs="column" class="flexwrap">

  <div fxFlex *ngFor="let tab of _variables; let indexVariable = index">

    <div fxLayout="column">
      <div fxFlex *ngFor="let calibration of tab.calibrations; let indexCalibration = index">

        <div fxFlex>
          <b style="margin: 0 auto; display: table; font-size: large;">
            {{ 'variable.' + tab.translationVariable | translate }}

            <mat-checkbox [(ngModel)]="tab.enableCalibration" *ngIf="!disableConfigureSensorsButton()"> </mat-checkbox>
          </b>
        </div>

        <div fxFlex *ngIf="tab.enableCalibration">

          <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
            <div fxFlex="80">
              <mat-form-field class="campoFormulario">
                <input matInput name="calibrationName{{tab.idVariable}}" [(ngModel)]="calibration.calibrationName"
                  placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.calibrationName.label' | translate }}"
                  [disabled]="disableEdit()">
              </mat-form-field>
            </div>
          </div>
        </div>

        <div fxFlex>
          <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
            <div fxFlex="30">
              <mat-form-field class="campoFormulario">
                <input matInput name="setpoint{{tab.idVariable}}" [(ngModel)]="tab.setpoint" required
                  placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.setpoint.label' | translate }}"
                  pattern="(-?)[0-9]+([\.,][0-9]+)?" (blur)="updateTableUncertainty(indexVariable)"
                  [disabled]="disableEdit()">
                <mat-error>
                  {{ 'isothermalCharacterizationEdit.dialog.essay.setpoint.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="20"> </div>

            <div fxFlex="30">
              <mat-form-field class="campoFormulario">
                <mat-label>{{ 'isothermalCharacterizationEdit.dialog.essay.unit.label' | translate }}</mat-label>
                <mat-select name="unit{{tab.idVariable}}" [(ngModel)]="tab.idUnit" required
                  [disabled]="!enableEditCriteria() || getVariableUnitsByVariable(tab.idVariable).length < 2"
                  (selectionChange)="onUnitChange(indexVariable, $event)">
                  <mat-option *ngFor="let item of getVariableUnitsByVariable(tab.idVariable)" [value]="item.id">
                    {{item.unit}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{ 'isothermalCharacterizationEdit.dialog.essay.unit.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="20"> </div>
          </div>

        </div>

        <div fxFlex *ngIf="tab.enableCalibration">
          <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
            <div fxFlex="40">
              <div fxLayout="row">
                <div fxFlex="60">
                  <mat-form-field class="campoFormulario">
                    <input matInput [ngModel]="calibration.equipmentName" [disabled]="disableSerialNumLabel()"
                      placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.equipment.label' | translate }}"
                      #trigger="matAutocompleteTrigger" [matAutocomplete]="auto" required
                      (keyup)="lookupCalibrationEquipment(indexVariable, $event)" [disabled]="disableEdit()">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
                      (optionSelected)='onSensorCalibrationChange(indexVariable, indexCalibration, $event, trigger)'>
                      <mat-option *ngFor="let item of getResultsCalibrationLookup(tab.idVariable); let index = index"
                        [value]="item">
                        {{ item.name}} ({{item.serialNum}})
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error>
                      {{ 'isothermalCharacterizationEdit.dialog.essay.sensors.serialNum.error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="10" fxFlex.lt-md="50%" *ngIf="calibration.equipmentName">
                  <button mat-icon-button color="primary" (click)="downloadCertificate(indexVariable, 0)">
                    <mat-icon>cloud_download</mat-icon>
                  </button>
                </div>
                <div fxFlex="10" fxFlex.lt-md="50%" *ngIf="calibration.equipmentName && !disableEdit()">
                  <button mat-icon-button color="primary" (click)="clearSensorCalibration(indexVariable, 0)">
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <div fxFlex="10"> </div>

            <div fxFlex="30">
              <mat-form-field class="campoFormulario">
                <input matInput name="uncertaintyCalibration{{tab.idVariable}}" [(ngModel)]="calibration.uncertainty"
                  placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.uncertainty.label' | translate }}"
                  (blur)="updateTableUncertainty(indexVariable)" pattern="[0-9]+([\.,][0-9]+)?" required
                  [disabled]="disableEdit()">
                <mat-error>
                  {{ 'isothermalCharacterizationEdit.dialog.essay.sensors.uncertainty.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="20"> </div>
          </div>

        </div>

        <div fxFlex *ngIf="tab.enableCalibration">
          <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
            <div fxFlex="26">
              <mat-form-field class="campoFormulario">
                <input matInput name="resolution{{tab.idVariable}}" [(ngModel)]="calibration.resolution" required
                  placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.resolution.label' | translate }}"
                  pattern="[0-9]+([\.,][0-9]+)?" (blur)="updateTableUncertainty(indexVariable)"
                  [disabled]="disableEdit()">
                <mat-error>
                  {{ 'isothermalCharacterizationEdit.dialog.essay.resolution.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="27">
              <mat-form-field class="campoFormulario">
                <input matInput name="tolerance{{tab.idVariable}}" [(ngModel)]="calibration.tolerance"
                  placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.tolerance.label' | translate }}"
                  pattern="[0-9]+([\.,][0-9]+)?" (blur)="updateTableUncertainty(indexVariable)"
                  [disabled]="disableEdit()">
              </mat-form-field>
            </div>

            <div fxFlex="26">
              <mat-form-field class="campoFormulario">
                <input matInput name="pattern{{tab.idVariable}}" [(ngModel)]="calibration.pattern"
                  placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.pattern.label' | translate }}"
                  (blur)="updateTableUncertainty(indexVariable)" type="number" required [disabled]="disableEdit()">
                <mat-error>
                  {{ 'isothermalCharacterizationEdit.dialog.essay.pattern.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div fxFlex *ngFor="let val of calibration.values; let indexValue = index">
          <div fxLayout="row" *ngIf="tab.enableCalibration">
            <div fxFlex="30">
              <mat-form-field class="campoFormulario">
                <input matInput name="calibrationValue{{tab.idVariable}}{{indexValue}}" [(ngModel)]="val.value" required
                  placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.value.label' | translate }} {{ indexValue + 1 }}"
                  (blur)="updateTableUncertainty(indexVariable)" type="number" [disabled]="disableEdit()">
                <mat-error>
                  {{ 'isothermalCharacterizationEdit.dialog.essay.value.error' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="15" fxFlex.lt-md="100%" *ngIf="(indexVariable + 1) == _variables.length && !disableEdit()">
              <button mat-icon-button color="primary" (click)="removeValue(indexValue)">
                <mat-icon>clear</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div fxFlex [fxShow]="indexVariable == 0" fxShow.lt-md="true" *ngIf="tab.enableCalibration">
          <button mat-raised-button color="primary" (click)="newValue(indexVariable)" *ngIf="!disableEdit()">
            {{ 'button.new' | translate }}</button>
          <br />
        </div>

        <div fxFlex [fxShow]="indexVariable != 0" fxShow.lt-md="false" *ngIf="tab.enableCalibration">
          <br /><br />
        </div>

        <div fxFlex *ngIf="tab.enableCalibration">
          <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
            <div fxFlex="33">
              <mat-form-field class="campoFormulario">
                <input matInput name="average{{tab.idVariable}}" [ngModel]="(calibration.average | number:'1.2-2')"
                  disabled placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.average.label' | translate }}">
              </mat-form-field>
            </div>

            <div fxFlex="33">
              <mat-form-field class="campoFormulario">
                <input matInput name="correction{{tab.idVariable}}"
                  [ngModel]="(calibration.correction | number:'1.2-2')" disabled
                  placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.correction.label' | translate }}">
              </mat-form-field>
            </div>

            <div fxFlex="33">
              <mat-form-field class="campoFormulario">
                <input matInput name="uncertaintyExpanded{{tab.idVariable}}"
                  [ngModel]="(calibration.cPlusU | number:'1.2-2')" disabled
                  placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.uncertaintyExpanded.label' | translate }}">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>

<table #uncertaintiesTable mat-table [dataSource]="getUncertaintiesList()" *ngIf="anyVariableSelected()"
  style="width: 100%;">

  <!-- Variable Column -->
  <ng-container matColumnDef="variable">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let element">
      {{'variable.' + element.translationVariable | translate }}
    </td>
  </ng-container>

  <!-- Point Column -->
  <ng-container matColumnDef="point">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'calibrateEquipmentEdit.uncertainties.cols.average.label' | translate }} </th>
    <td mat-cell *matCellDef="let element; let i = index">
      <span [matTooltip]="element.calibration?.average">
        <strong> {{ element.calibration?.average | number:'1.2-2' }} </strong>
      </span>
    </td>
  </ng-container>

  <!-- uRep Column -->
  <ng-container matColumnDef="uRep">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'calibrateEquipmentEdit.uncertainties.cols.uRep.label' | translate }} </th>
    <td mat-cell *matCellDef="let element">
      <span [matTooltip]="element.calibration?.uRep">
        {{ element.calibration?.uRep | number:'1.2-2' }}
      </span>
    </td>
  </ng-container>

  <!-- uResol Column -->
  <ng-container matColumnDef="uResol">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'calibrateEquipmentEdit.uncertainties.cols.uResol.label' | translate }} </th>
    <td mat-cell *matCellDef="let element">
      <span [matTooltip]="element.calibration?.uResol">
        {{ element.calibration?.uResol | number:'1.2-2' }}
      </span>
    </td>
  </ng-container>

  <!-- uPattern Column -->
  <ng-container matColumnDef="uPattern">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'calibrateEquipmentEdit.uncertainties.cols.uPattern.label' | translate }} </th>
    <td mat-cell *matCellDef="let element">
      {{ element?.calibration?.uncertainty ? (element.calibration.uncertainty / 2) : null | number:'1.2-2' }}
    </td>
  </ng-container>

  <!-- uUnif Column -->
  <ng-container matColumnDef="uUnif">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'calibrateEquipmentEdit.uncertainties.cols.uUnif.label' | translate }} </th>
    <td mat-cell *matCellDef="let element">
      <span [matTooltip]="element.calibration?.uUnif">
        {{ element.calibration?.uUnif != null ? element.calibration.uUnif : 'common.notApplicable' | translate }}
      </span>
    </td>
  </ng-container>

  <!-- uEstab Column -->
  <ng-container matColumnDef="uEstab">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'calibrateEquipmentEdit.uncertainties.cols.uEstab.label' | translate }} </th>
    <td mat-cell *matCellDef="let element">
      <span [matTooltip]="element.calibration?.uEstab">
        {{ element.calibration?.uEstab | number:'1.2-2' }}
      </span>
    </td>
  </ng-container>

  <!-- uc Column -->
  <ng-container matColumnDef="uc">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'calibrateEquipmentEdit.uncertainties.cols.uc.label' | translate }} </th>
    <td mat-cell *matCellDef="let element">
      <span [matTooltip]="element.calibration?.uC">
        {{ element.calibration?.uC | number:'1.2-2' }}
      </span>
    </td>
  </ng-container>

  <!-- neff Column -->
  <ng-container matColumnDef="neff">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'calibrateEquipmentEdit.uncertainties.cols.neff.label' | translate }} </th>
    <td mat-cell *matCellDef="let element">
      <span [matTooltip]="element.calibration?.neff">
        {{ element.calibration?.neff | number:'1.0-0' }}
      </span>
    </td>
  </ng-container>

  <!-- k Column -->
  <ng-container matColumnDef="k">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'calibrateEquipmentEdit.uncertainties.cols.k.label' | translate }} </th>
    <td mat-cell *matCellDef="let element">
      <span [matTooltip]="element.calibration?.k">
        {{ element.calibration?.k }}
      </span>
    </td>
  </ng-container>

  <!-- U Column -->
  <ng-container matColumnDef="U">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'calibrateEquipmentEdit.uncertainties.cols.U.label' | translate }} </th>
    <td mat-cell *matCellDef="let element">
      <span [matTooltip]="element.calibration?.U">
        <strong> {{ element.calibration.U | number:'1.2-2' }} </strong>
      </span>
    </td>
  </ng-container>

  <!-- measureUnit Column -->
  <ng-container matColumnDef="measureUnit">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'calibrateEquipmentEdit.uncertainties.cols.measureUnit.label' | translate }} </th>
    <td mat-cell *matCellDef="let element"> {{ element.unitName }} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="uncertaintiesCols"></tr>
  <tr mat-row *matRowDef="let row; columns: uncertaintiesCols;"></tr>
</table>

<mat-toolbar color="secondary" *ngIf="anyVariableSelected()" style="padding-left: 3em;">
  {{ 'isothermalCharacterizationEdit.result.title' | translate }}
</mat-toolbar>


<mat-tab-group style="width: 100%;" (selectedTabChange)="onVariableTabChange($event)">

  <mat-tab *ngFor="let tab of _variables; let indexVariable = index"
    label="{{'variable.' + tab.translationVariable | translate }}" style="min-height: 50vh;">

    <mat-toolbar color="secondary">
      {{ 'isothermalCharacterizationEdit.essays.criteriaTitle' | translate }}

      <button mat-icon-button color="accent" [disabled]="!enableEditCriteria()"
        (click)="openCreateCriteria(indexVariable)">
        <mat-icon>add</mat-icon>
      </button>
    </mat-toolbar>

    <div fxLayout="row" class="flexwrap">
      <div fxFlex>
        <table mat-table matSort #criteriaTable [dataSource]="tab.criterias" style="width: 95%;">

          <!-- criteria Column -->
          <ng-container matColumnDef="criteria">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'isothermalCharacterizationEdit.dialog.essay.criteria.cols.criteria' | translate }}
            </th>
            <td mat-cell *matCellDef="let item"> {{ showCriteria(item) }} </td>
          </ng-container>

          <!-- Button edit Column -->
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
            <td mat-cell *matCellDef="let element; let criteriaIndex = index">
              <button mat-icon-button *ngIf="enableEditCriteria()"
                (click)="openEditCriteria(indexVariable, criteriaIndex)">
                <mat-icon>mode_edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Button delete Column -->
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
            <td mat-cell *matCellDef="let element; let criteriaIndex = index">
              <button mat-icon-button *ngIf="enableEditCriteria()"
                (click)="removeCriteria(indexVariable, criteriaIndex)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColsCriteria"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColsCriteria;"></tr>

        </table>

        <br />
      </div>
    </div>

    <mat-toolbar color="secondary">
      {{ 'isothermalCharacterizationEdit.essays.sensorsTitle' | translate }}

      <button mat-icon-button color="accent" (click)="openDialogCorrectSensors(indexVariable)"
        *ngIf="tab.id != null && !disableSerialNumLabel()"
        matTooltip="{{ 'executionEdit.dialog.correctSensor.title' | translate }}">
        <mat-icon class="mat-icon material-icons correctedSensorIcon">done_all</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="uploadDisposalFile.click()"
        *ngIf="idDisposal == null && !disableSerialNumLabel()" matTooltip="{{ 'button.uploadDisposal' | translate }}">
        <mat-icon class="opacity50">auto_awesome_mosaic</mat-icon>
      </button>
      <input accept="image/*" hidden type="file" (change)="onUploadDisposal($event)" #uploadDisposalFile>

      <button mat-icon-button color="accent" (click)="openDialogDisposal()"
        *ngIf="idDisposal != null && !disableSerialNumLabel()" matTooltip="{{ 'button.uploadDisposal' | translate }}">
        <mat-icon>auto_awesome_mosaic</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="uploadPhotoFile.click()"
        *ngIf="idPhoto == null && !disableSerialNumLabel()" matTooltip="{{ 'button.uploadPhoto' | translate }}">
        <mat-icon class="opacity50">image</mat-icon>
      </button>
      <input accept="image/*" hidden type="file" (change)="onUploadPhoto($event)" #uploadPhotoFile>

      <button mat-icon-button color="accent" (click)="openDialogPhoto()"
        *ngIf="idPhoto != null && !disableSerialNumLabel()" matTooltip="{{ 'button.uploadPhoto' | translate }}">
        <mat-icon>image</mat-icon>
      </button>
    </mat-toolbar>

    <div fxLayout="row" class="flexwrap">
      <div fxFlex="100%">
        <div fxLayout="row" class="flexwrap" *ngFor="let sensor of tab.sensors; let indexSensor = index">
          <div fxFlex="15" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput name="numSensor{{tab.idVariable}}{{indexSensor}}" value="{{ indexSensor + 1 }}" disabled
                placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.sensors.sensorNum.label' | translate }}">
            </mat-form-field>
          </div>
          <div fxFlex="30" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput name="location{{tab.idVariable}}{{indexSensor}}" disabled
                value="{{ showSensorName(indexVariable, sensor) }}"
                placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.sensors.location.label' | translate }}">
            </mat-form-field>
          </div>
          <div fxFlex="25" fxFlex.lt-md="100%">
            <div fxLayout="row" class="flexwrap">
              <div fxFlex="20px" *ngIf="sensor.corrected" style="margin-top: 1em;margin-right: 1em;">
                <mat-icon class="correctedSensorIcon"
                  matTooltip="{{ 'executionEdit.dialog.correctSensor.sensor.tooltip' | translate }}">done_all
                </mat-icon>
              </div>
              <div fxFlex="20px" *ngIf="!sensor.evaluate" style="margin-top: 1em;">
                <span>❌</span>
              </div>
              <div fxFlex="20px" *ngIf="sensor.outer" style="margin-top: 1em;">
                <span>️☀️</span>
              </div>
              <div fxFlex>
                <mat-form-field class="campoFormulario">
                  <input matInput name="equipmentName{{tab.idVariable}}{{indexSensor}}" [ngModel]="sensor.equipmentName"
                    [disabled]="disableSerialNumLabel()" #trigger="matAutocompleteTrigger" [matAutocomplete]="auto"
                    placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.sensors.serialNum.label' | translate }}"
                    (keyup)="lookupEquipment(indexVariable, indexSensor, sensor.id, $event)">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
                    (optionSelected)='onSensorChange(indexVariable, indexSensor, $event, trigger)'>
                    <mat-option *ngFor="let item of getResultsLookup(sensor.id); let index = index" [value]="item">
                      {{ item.name}} ({{item.serialNum}})
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error>
                    {{ 'isothermalCharacterizationEdit.dialog.essay.sensors.serialNum.error' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxFlex="15" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput name="uncertainty{{tab.idVariable}}{{indexSensor}}" [ngModel]="sensor.uncertainty"
                placeholder="{{ 'isothermalCharacterizationEdit.dialog.essay.sensors.uncertainty.label' | translate }}"
                (blur)="setUncertainty(indexVariable, indexSensor, $event.target.value)"
                [disabled]="disableSerialNumLabel()" required>
            </mat-form-field>
          </div>

          <button mat-icon-button [disabled]="disableUploadExcelButton() || sensor.id == null"
            (click)="uploadExcel.click()"
            *ngIf="!excelAlreadyUploaded(indexVariable, indexSensor) && showExcelButton(indexVariable, indexSensor)">
            <mat-icon style="max-height: 20px; vertical-align: text-bottom;"
              class="mat-icon material-icons {{ styleUploadOriginalButton(indexVariable, indexSensor) }}">
              insert_chart
            </mat-icon>
          </button>
          <button mat-icon-button (click)="openDialogSensorExcel(indexVariable, indexSensor)"
            *ngIf="excelAlreadyUploaded(indexVariable, indexSensor) && showExcelButton(indexVariable, indexSensor)">
            <mat-icon class="mat-icon material-icons blueIcon">
              insert_chart
            </mat-icon>
          </button>

          <button mat-icon-button [disabled]="disableUploadOriginalButton() || sensor.id == null"
            (click)="uploadPrimaryFile.click()"
            *ngIf="!primaryAlreadyUploaded(indexVariable, indexSensor) && showPrimaryButton(indexVariable, indexSensor)">
            <mat-icon style="max-height: 20px; vertical-align: text-bottom;" svgIcon="doc_lock"
              class="mat-icon material-icons {{ styleUploadOriginalButton(indexVariable, indexSensor) }}">
            </mat-icon>
          </button>
          <button mat-icon-button (click)="openDialogSensorPrimary(indexVariable, indexSensor)"
            *ngIf="primaryAlreadyUploaded(indexVariable, indexSensor) && showPrimaryButton(indexVariable, indexSensor)">
            <mat-icon style="max-height: 20px; vertical-align: text-bottom;" svgIcon="doc_lock"
              class="mat-icon material-icons blueIcon"> </mat-icon>
          </button>

          <button mat-icon-button (click)="showDialogConfigSensor(indexVariable, indexSensor)">
            <mat-icon style="max-height: 20px; vertical-align: text-bottom;" class="mat-icon material-icons">
              build
            </mat-icon>
          </button>

          <input accept=".xlsx, .xls, .csv, .pdf" hidden type="file"
            (change)="onExcelUploadSensor(indexVariable, indexSensor, $event)" #uploadExcel>

          <input accept="*" hidden type="file" (change)="onPrimaryDataUploadSensor(indexVariable, indexSensor, $event)"
            #uploadPrimaryFile>
        </div>
      </div>

      <br />

      <div fxLayout="row" class="flexwrap" *ngIf="tab.sensors?.length != 0">

        <div fxFlex="30" fxFlex.lt-md="100%" *ngIf="tab.idVariable != 1">
          <mat-checkbox (change)="onCopyFromTemperatureChange(indexVariable, $event)"
            [checked]="tab.copyFromTemperature">
            {{ 'isothermalCharacterizationEdit.dialog.essay.copyFromTemperature.label' | translate }}
          </mat-checkbox>
        </div>

        <div fxFlex="25" fxFlex.lt-md="100%">
          <mat-checkbox [value]="reasonModifyExpositionTime != null" [checked]="reasonModifyExpositionTime != null"
            [disabled]="!enableReasonModifyExpositionTime()" (change)="openReasonModifyExpositionTime($event)">
            {{ 'isothermalCharacterizationEdit.essays.allowEditGraph' | translate }}</mat-checkbox>
        </div>

        <div fxFlex="5px" *ngIf="tab.idVariable != 1"></div>

        <div fxFlex></div>

        <button mat-raised-button color="accent" *ngIf="!disableUploadExcelButton()"
          (click)="uploadExcelMassive.click()" [disabled]="disableUploadExcelButton()">
          <mat-icon>insert_chart</mat-icon>
          {{ 'button.massive-upload-excel' | translate }}
        </button>
        <input accept=".xlsx, .xls, .csv, .pdf" hidden type="file"
          (change)="onExcelMassiveUploadSensor(indexVariable, $event)" #uploadExcelMassive>

        <div fxFlex="5px"></div>

        <button mat-raised-button color="accent" *ngIf="!disableUploadOriginalButton()"
          (click)="openMassiveUploadPrimaryDataDialog(indexVariable)" [disabled]="disableUploadOriginalButton()">
          <mat-icon style="max-height: 20px; vertical-align: text-bottom;" svgIcon="doc_lock">
          </mat-icon>
          {{ 'button.massive-upload-primary' | translate }}
        </button>

        <div fxFlex="5px"></div>

        <button mat-raised-button color="accent" *ngIf="!disableUploadExcelButton()"
          (click)="uploadAutomaticMassiveFile.click()" [disabled]="disableUploadExcelButton()">
          {{ 'button.massive-upload-automatic' | translate }}
        </button>
        <input accept="*" hidden type="file" multiple (change)="uploadAutomaticMassive(indexVariable, $event)"
          #uploadAutomaticMassiveFile>

      </div>
      <div fxFlex="5px"></div>

      <button mat-raised-button (click)="openConfigureSensors()" *ngIf="!disableConfigureSensorsButton()"
        color="accent">{{ 'button.configureSensors' | translate }}</button>

      <div fxFlex="5px"></div>

      <button mat-raised-button (click)="showDialogDiscardTime(tab)" *ngIf="!disableDefrostsButton()" color="accent">
        {{ 'button.discardTimes' | translate }}</button>

      <div fxFlex="5px"></div>
      <button mat-raised-button (click)="restartGraph(tab)" color="accent">
        {{ 'button.restartGraph' | translate }}</button>
    </div>

  </mat-tab>

</mat-tab-group>

<app-isothermal-characterization-edit-graph (fcStartEssayEmitter)="onFcStartEssayEmit($event)"
  (copyDateFirstSensorEmitter)="onCopyTimeGraphChange($event)">
</app-isothermal-characterization-edit-graph>