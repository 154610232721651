import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Execution, ExecutionStatus } from 'src/app/model/execution';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ExecutionService } from 'src/app/services/execution.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { ExecutionEditGenerateReportComponent } from './execution-edit-generate-report.component';
import { TranslateService } from '@ngx-translate/core';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { takeUntil } from 'rxjs/operators';
import { Protocol } from 'src/app/model/protocol';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ExecutionReportFilter } from 'src/app/model/attachment';
import { StringUtils } from 'src/app/utils/stringUtils';
import { SpinnerService } from 'src/app/services/spinner.service';

export interface DialogDataValidateExecution {
  execution: Execution;
  protocol: Protocol;
  documentSigned: File;
}

@Component({
  selector: 'app-execution-edit-validate-execution',
  templateUrl: './execution-edit-validate-execution.component.html'
})
export class ExecutionEditValidateExecutionComponent implements OnInit, OnDestroy {
  mainForm: FormGroup;

  forceAccording: boolean;
  reasonForce: string;
  reason: string;

  errors: string[];
  warns: string[];

  private destroy$ = new Subject<void>();

  constructor(
    fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ExecutionEditValidateExecutionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataValidateExecution,
    private executionService: ExecutionService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    this.mainForm = fb.group({
      forceAccording: [this.forceAccording],
      reasonForce: [this.reasonForce],
      reason: [this.reason]
    });
  }

  ngOnInit(): void {
    this.errors = [];
    this.warns = [];

    this.spinnerService.show();

    const force: boolean = this.data.execution.according != null ? this.data.execution.according.valueOf() : false;
    this.forceAccording = !force;
    this.reasonForce = StringUtils.isNotEmpty(this.data.execution.accordingReason) ? this.data.execution.accordingReason : null;
    this.reason = this.data.execution.reason;

    this.spinnerService.show();

    this.errors = [];
    this.warns = [];

    this.executionService.checkValidation(this.data.execution.id).subscribe((warns: string[]) => {
      this.warns = warns;

      this.executionService.checkForDownload(this.data.execution.id).pipe(takeUntil(this.destroy$)).subscribe((errors: string[]) => {
        this.errors = errors;

        this.spinnerService.hide();
      });
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showReason(): boolean {
    return (+this.data.execution.currentVersion) > 1;
  }

  isEmpty(array: any[]): boolean {
    return ArrayUtils.isEmpty(array);
  }

  canAccept(): boolean {
    let res = false;

    if (this.errors == null) {
      res = true;
    } else if (this.forceAccording) {
      res = true;
    } else if (this.reasonForce != null && this.reasonForce !== '') {
      res = true;
    } else if (this.data.execution.idStatus === ExecutionStatus.PENDIENTE_FIRMA) {
      res = true;
    }

    return res;
  }

  canDownloadDocument(): boolean {
    return this.data.execution.idStatus === ExecutionStatus.PENDIENTE_FIRMA;
  }

  downloadDocument(): void {

    const dialogRef = this.dialog.open(ExecutionEditGenerateReportComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        execution: this.data.execution,
        protocol: this.data.protocol,
        reason: this.reason,
        toSign: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionReportFilter) => {
      if (result != null) {
        this.data.execution.conclusions = result.conclusions;
      }
    });
  }

  uploadDocumentToExecution(event): void {
    this.data.documentSigned = event.target.files[0] as File;

    if (this.data.documentSigned != null) {
      this.onOkClick();
    }
  }


  onOkClick(): void {
    if (this.data.execution.idStatus === ExecutionStatus.EN_EJECUCION) {
      this.validateByTec();
    } else if (this.data.execution.idStatus === ExecutionStatus.PENDIENTE_FIRMA) {
      this.sign();
    } else {
      this.snackBarService.sendError(this.translate.instant('executionEdit.form.status.error.notAllowed') as string);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private validateByTec() {

    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {

      if (result != null) {

        this.spinnerService.show();

        this.data.execution.reason = this.reason;

        this.executionService.validate(this.data.execution, result, this.forceAccording, this.reasonForce).pipe(takeUntil(this.destroy$))
          .subscribe(() => this.onFinishOk('validated'), () => {
            this.snackBarService.sendError(this.translate.instant('executionEdit.form.status.advance.error.generic') as string);

            this.spinnerService.hide();
          }
          );

      } else {
        this.spinnerService.hide();
      }
    });

  }

  private sign() {
    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    const reason = this.reason;

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {

      if (result != null) {

        this.spinnerService.show();

        this.data.execution.reason = reason;

        this.executionService.manualSign(this.data.execution, result, this.forceAccording, this.reasonForce, this.data.documentSigned)
          .pipe(takeUntil(this.destroy$)).subscribe(() => this.onFinishOk('finished'), () => {
            this.snackBarService.sendError(this.translate.instant('executionEdit.form.status.advance.error.generic') as string);

            this.spinnerService.hide();
          }
          );

      } else {
        this.spinnerService.hide();
      }
    });

  }

  private onFinishOk(statusTranslation: string) {
    const status = this.translate.instant('executionStatus.' + statusTranslation) as string;
    this.snackBarService.sendSuccess(this.translate.instant('executionEdit.form.status.advance.ok', { status }) as string);

    this.spinnerService.hide();

    this.dialogRef.close(this.data.execution);
  }

}
