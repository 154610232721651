import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { IsothermalCharacterizationAudit, IsothermalCharacterizationAuditFilter } from './isothermalCharacterization';
import { catchError, finalize } from 'rxjs/operators';

import { IsothermalCharacterizationService } from '../services/isothermalCharacterization.service';
import { of } from 'rxjs';

export class IsothermalCharacterizationAuditDataSource implements DataSource<IsothermalCharacterizationAudit> {

    private isothermalCharacterizationsSubject = new BehaviorSubject<IsothermalCharacterizationAudit[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public loading$ = this.loadingSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public totalItems$ = this.totalSubject.asObservable();

    constructor(private service: IsothermalCharacterizationService) { }

    connect(_collectionViewer: CollectionViewer): Observable<IsothermalCharacterizationAudit[]> {
        return this.isothermalCharacterizationsSubject.asObservable();
    }

    disconnect(_collectionViewer: CollectionViewer): void {
        this.isothermalCharacterizationsSubject.complete();
        this.loadingSubject.complete();
        this.totalSubject.complete();
    }

    loadIsothermalCharacterizationAudit(id: number, filter = new IsothermalCharacterizationAuditFilter()): void {

        this.loadingSubject.next(true);

        this.service.getAudit(id, filter).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(data => {
            if (data && data.content) {
                this.isothermalCharacterizationsSubject.next(data.content as IsothermalCharacterizationAudit[]);
                this.totalSubject.next(data.totalElements as number);
            } else if (data) {
                this.isothermalCharacterizationsSubject.next(data as IsothermalCharacterizationAudit[]);
                this.totalSubject.next(data.length as number);
            } else {
                this.isothermalCharacterizationsSubject.next([]);
                this.totalSubject.next(0);
            }
        });
    }
}
