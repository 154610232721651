import { FileInfo } from './attachment';

export class BioindicatorConfig {
    valid: boolean;

    controlSerialNum: string;
    optionSelected: string;
    responsible: number;

    certificates: FileInfo[];
    batchs: BioindicatorConfigBatch[];
    equipments: BioindicatorConfigEquipment[];
    reason: string;
}

export class BioindicatorConfigBatch {
    batchId: string;
    expiryDate: Date;
}

export class BioindicatorConfigEquipment {

    id: number;
    equipment: string;
    serialNum: string;
    internalId: string;
    certificateNum: string;
    calibrateDate: Date;

    idCertificate: number;
    certificate: FileInfo;
}
