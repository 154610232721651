import { AppPlanFilter, AppPlanType } from '../model/appPlanType';
import { HttpClient } from '@angular/common/http';

import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class AppPlanTypeService {

    constructor(private http: HttpClient) {
    }

    findAll(): Observable<any> {
        const url = environment.coreUrl + '/appPlanType/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllFilter(filter: AppPlanFilter): Observable<any> {
        const url = environment.coreUrl + '/appPlanType/';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOne(id: number): Observable<any> {
        const url = environment.coreUrl + `/appPlanType/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    create(group: AppPlanType): Observable<any> {
        const url = environment.coreUrl + '/appPlanType/create';

        return this.http.post(url, group).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    update(group: AppPlanType): Observable<any> {
        if (group.id == null) {
            return this.create(group);
        }

        const url = environment.coreUrl + `/appPlanType/${group.id}`;

        return this.http.put(url, group).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
