import { DateUtils } from './dateUtils';
import { IsothermalCharacterizationSensor } from '../model/isothermalCharacterization';
import { Sensor } from '../model/sensor';
import { SensorData } from '../model/sensorData';

export class SensorUtils {

    static objectToSensor(item: any): Sensor {

        if (item instanceof Sensor) {
            return item;
        } else {
            const s = new Sensor();

            for (const prop in item) {
                if (prop !== 'data') {
                    s[prop] = item[prop];
                }
            }

            const dats = [];

            item.data.sort((a: SensorData, b: SensorData) => DateUtils.compare(a.date, b.date));
            item.data.forEach((dato: SensorData) => {
                const d = new SensorData();
                d.idVariable = s.idVariable;
                d.idEquipment = s.idEquipment;
                d.equipmentName = s.equipmentName;
                d.serialNum = s.serialNum;

                d.id = dato.id;
                d.value = dato.value;
                d.date = DateUtils.anyToDate(dato.date);

                dats.push(d);
            });

            s.data = dats;

            return s;
        }
    }

    static objectToIsothermalSensor(item: any, idVariable: number): IsothermalCharacterizationSensor {

        if (item instanceof IsothermalCharacterizationSensor) {
            return item;
        } else {
            const s = new IsothermalCharacterizationSensor();

            for (const prop in item) {
                if (prop !== 'data') {
                    s[prop] = item[prop];
                }
            }

            const dats: SensorData[] = [];

            item.data.sort((a: SensorData, b: SensorData) => DateUtils.compare(a.date, b.date));
            item.data.forEach((dato: SensorData) => {
                const d = new SensorData();

                d.id = dato.id;
                d.idVariable = idVariable;
                d.idEquipment = s.idEquipment;
                d.equipmentName = s.equipmentName;
                d.serialNum = s.serialNum;
                d.date = DateUtils.anyToDate(dato.date);
                d.value = dato.value;

                dats.push(d);
            });

            s.data = dats;

            return s;
        }
    }
}
