import { GenericClassTranslate } from './genericClass';

export class VerificationQType extends GenericClassTranslate {

}

export enum QualificationTypeEnum{ 
    IQ = 1,
    OQ= 2, 
    PQ = 3,
    IQ_DESCRIPTION = "iq",
    OQ_DESCRIPTION = "oq", 
    PQ_DESCRIPTION ="pq"
}