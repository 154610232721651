<form [formGroup]="form">
  <mat-accordion>
    <mat-expansion-panel [expanded]="expandedFilter" (opened)="expandedFilter = true" (closed)="expandedFilter = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'manageUsers.filter.title' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
          <mat-form-field class="campoFormulario">
            <input matInput placeholder="{{ 'manageUsers.filter.username.label' | translate }}"
              formControlName="username" [(ngModel)]="filter.username">
          </mat-form-field>
        </div>

        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
          <mat-form-field class="campoFormulario">
            <input matInput placeholder="{{ 'manageUsers.filter.email.label' | translate }}" formControlName="email"
              [(ngModel)]="filter.email">
          </mat-form-field>
        </div>

        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
          <mat-form-field class="campoFormulario">
            <input matInput placeholder="{{ 'manageUsers.filter.fullName.label' | translate }}"
              formControlName="fullName" [(ngModel)]="filter.fullName">
          </mat-form-field>
        </div>

        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
          <mat-form-field class="campoFormulario">
            <mat-label>{{ 'manageUsers.filter.fullName.label' | translate }}</mat-label>
            <mat-select formControlName="idGroup" [(ngModel)]="filter.idGroup">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let item of groups" [value]="item.id">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>

      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <button mat-raised-button (click)="doFilter()"
                color="primary">{{ 'button.filter' | translate }}</button>
        <button mat-button (click)="cleanFilter()">{{ 'button.clean' | translate }}</button>
      </div>

    </mat-expansion-panel>
  </mat-accordion>
</form>
