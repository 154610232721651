import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ThemeService } from 'src/app/services/theme.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

export interface DialogDataVideoPlayer {
  observable: Observable<any>;
}

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html'
})
export class VideoPlayerComponent {

  @ViewChild('videoPlayer') videoElement: ElementRef;

  constructor(
    private themeService: ThemeService,
    private spinnerService: SpinnerService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataVideoPlayer) {

    this.spinnerService.show();

    this.data.observable.subscribe((res) => {
      this.spinnerService.hide();

      if (res.url) {
        res = res.url as string;
      }

      const videoPlayer = this.videoElement.nativeElement as HTMLVideoElement;
      if (typeof res === 'string') {
        videoPlayer.src = res;
      } else {
        videoPlayer.src = URL.createObjectURL(res);
      }
    }, error => {
      console.error(error);

      // TODO: Mostrar error

      this.spinnerService.hide();

      this.dialogRef.close();
    });
  }

  toggleVideo(): void {
    const videoPlayer = this.videoElement.nativeElement as HTMLVideoElement;
    void videoPlayer.play();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTheme(): string {
    return this.themeService.getTheme();
  }

}
