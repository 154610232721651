import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';

import { of } from 'rxjs';
import { Procedure, ProcedureFilter } from './procedure';
import { ProcedureService } from '../services/procedure.service';

export class ProcedureDataSource implements DataSource<Procedure> {

    private procedureSubject = new BehaviorSubject<Procedure[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public loading$ = this.loadingSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public totalItems$ = this.totalSubject.asObservable();

    constructor(private service: ProcedureService) { }

    connect(_collectionViewer: CollectionViewer): Observable<Procedure[]> {
        return this.procedureSubject.asObservable();
    }

    disconnect(_collectionViewer: CollectionViewer): void {
        this.procedureSubject.complete();
        this.loadingSubject.complete();
        this.totalSubject.complete();
    }

    loadProcedure(filter = new ProcedureFilter()): void {

        this.loadingSubject.next(true);

        this.service.filter(filter).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(data => {
            if (data && data.content) {
                this.procedureSubject.next(data.content as Procedure[]);
                this.totalSubject.next(data.totalElements as number);
            } else if (data) {
                this.procedureSubject.next(data as Procedure[]);
                this.totalSubject.next(data.length as number);
            } else {
                this.procedureSubject.next([]);
                this.totalSubject.next(0);
            }
        });
    }

}
