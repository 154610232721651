<h1 mat-dialog-title>{{ 'executionEdit.sensorConfig.title' | translate }}</h1>
<form>

  <div mat-dialog-content class="thermal-theme" style="min-height: 10vh;">

    <div fxLayout="column">
      <div fxFlex>
        {{ 'executionEdit.sensorConfig.location.label' | translate }}:
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'executionEdit.sensorConfig.location.label' | translate }}" name="location"
            [(ngModel)]="data.sensor.location">
          <mat-error>
            {{ 'executionEdit.sensorConfig.location.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex *ngIf="data.sensor.location != data.sensor.locationProtocol && data.sensor.fromProtocol">
        <textarea rows="5" matAutosizeMinRows="5" matInput [(ngModel)]="data.sensor.locationChangeReason"
          name="locationChangeReason" required style="resize: none;"
          placeholder="{{ 'executionEdit.sensorConfig.locationChangeReason.label' | translate }}"></textarea>
      </div>
      <div fxFlex *ngIf="data.sensor.fromProtocol">
        {{ 'executionEdit.sensorConfig.location.label' | translate }} {{ 'common.inProtocol' | translate }}:
        {{ data.sensor.locationProtocol }}
      </div>
      <div fxFlex *ngIf="!data.sensor.fromProtocol">
        <textarea rows="5" matAutosizeMinRows="5" matInput
          placeholder="{{ 'executionEdit.sensorConfig.addingReason.label' | translate }}"
          [(ngModel)]="data.sensor.addingReason" name="addingReason" required style="resize: none;"></textarea>
      </div>

      <div fxFlex *ngIf="data.sensor.fromProtocol">
        <mat-slide-toggle class="example-margin" color="primary" name="evaluate" [(ngModel)]="data.sensor.evaluate"
          [checked]="data.sensor.evaluate" (change)="onEvaluateChange($event)" [disabled]="data.isLastSensor">
          {{ 'executionEdit.sensorConfig.evaluate.label' | translate }}
        </mat-slide-toggle>
      </div>
      <div fxFlex *ngIf="!data.sensor.evaluate && data.sensor.fromProtocol">
        <textarea rows="5" matAutosizeMinRows="5" matInput name="evaluateReason" required style="resize: none;"
          placeholder="{{ 'executionEdit.sensorConfig.evaluateReason.label' | translate }}"
          [(ngModel)]="data.sensor.evaluateReason"></textarea>
      </div>
      <div fxFlex>
        <mat-slide-toggle class="example-margin" color="primary" name="outer" [(ngModel)]="data.sensor.outer"
          [checked]="data.sensor.outer" (change)="onOuterChange($event)">
          {{ 'executionEdit.sensorConfig.outer.label' | translate }}
        </mat-slide-toggle>
      </div>
      <div fxFlex>
        <mat-checkbox name="showDiffInDeviations" [(ngModel)]="data.sensor.showDiffInDeviations">
          {{ 'executionEdit.sensorConfig.showDiffInDeviations.label' | translate }}
        </mat-checkbox>
      </div>
      <div fxFlex>
        <mat-checkbox name="showDiffInObservations" [(ngModel)]="data.sensor.showDiffInObservations">
          {{ 'executionEdit.sensorConfig.showDiffInObservations.label' | translate }}
        </mat-checkbox>
      </div>
    </div>

  </div>

  <div mat-dialog-actions class="thermal-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <div fxFlex="5px"></div>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>