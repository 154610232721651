import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DexieService } from './dexieService';
import { OnlineService } from './online.service';
import { Observable, from, map } from 'rxjs';
import { HttpUtils } from '../utils/httpUtils';

@Injectable({
  providedIn: 'root'
})
export class TestQItemService {

  protected readonly url = environment.qualificatesUrl + '/testQItem';

  constructor(private http: HttpClient,
    private dexieService: DexieService,
    private onlineService: OnlineService) {
  }

  findAllByIdTest(idTest: number): Observable<any> {
    if (this.onlineService.latestOnline) {
      return this.http.get(`${this.url}/test/${idTest}`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    } else {
        return from(this.dexieService.roomType.toArray().timeout(30000));
    }
  }
}
