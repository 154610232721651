import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Criteria } from '../../../../model/criteria';
import { CriteriaType } from '../../../../model/criteriaType';
import { Subject, takeUntil } from 'rxjs';
import { CriteriaTypeService } from '../../../../services/criteriaType.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '../../../../services/snackBar.service';
import { ArrayUtils } from '../../../../utils/arrayUtils';

export interface DialogDataCriteriaEssay {
  criteria: Criteria;
  idEssay: number;
  isEdit: boolean;
  disabled: boolean;
  criteriasRemove: number[];
  showMkt: boolean;
}

@Component({
  selector: 'app-protocol-q-edit-test-essay-criteria',
  templateUrl: './protocol-q-edit-test-essay-criteria.component.html',
  styleUrls: ['./protocol-q-edit-test-essay-criteria.component.scss']
})
export class ProtocolQEditTestEssayCriteriaComponent implements OnInit, OnDestroy {

  criterias: CriteriaType[];
  criteriaSelected: CriteriaType;

  private destroy$ = new Subject<void>();

  constructor(
    private criteriaTypeService: CriteriaTypeService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCriteriaEssay,
    public dialogRef: MatDialogRef<ProtocolQEditTestEssayCriteriaComponent>,
    public snackBarService: SnackBarService) { }

  ngOnInit(): void {

    if (this.data.isEdit) {
      this.data.criteriasRemove.splice(this.data.criteriasRemove.indexOf(this.data.criteria.idType), 1);
    }

    this.criterias = [];
    this.criteriaTypeService.findByIdEssay(this.data.idEssay).pipe(takeUntil(this.destroy$)).subscribe((res: CriteriaType[]) => {
      res = res.filter(crit => !this.data.criteriasRemove.includes(crit.id));

      this.criterias = res;
    });

    let idCriteriaType: number = null;
    try {
      idCriteriaType = this.data.criteria.idType;
    } catch (e) { }

    if (idCriteriaType) {
      this.criteriaTypeService.findOne(idCriteriaType).pipe(takeUntil(this.destroy$))
        .subscribe((res: CriteriaType) => this.criteriaSelected = res, () => this.criteriaSelected = null);
    }

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCriteriaChange(event): void {
    const idCriteria = event.source.value as number;

    if (idCriteria == null) {
      this.criteriaSelected = null;
      this.data.criteria.criteriaValue1 = null;
      this.data.criteria.criteriaValue2 = null;
      this.data.criteria.criteriaTranslation = null;

      return;
    }

    const crit = this.criterias.find(cr => cr.id === idCriteria);

    if (crit !== null) {
      this.criteriaSelected = crit;
      this.data.criteria.criteriaValue1 = crit.defaultValue1;
      this.data.criteria.criteriaValue2 = crit.defaultValue2;
      this.data.criteria.criteriaTranslation = crit.translation;
    } else {
      this.criteriaSelected = null;
      this.data.criteria.criteriaValue1 = null;
      this.data.criteria.criteriaValue2 = null;
      this.data.criteria.criteriaTranslation = null;
    }

  }

  onOkClick(): void {
    const errors = [];

    const item = this.data.criteria;

    if (item.idType == null) {
      errors.push(this.translate.instant('protocolEdit.dialog.criteria.form.error.type'));
    } else {
      this.criteriaTypeService.findOne(item.idType).pipe(takeUntil(this.destroy$)).subscribe((res: CriteriaType) => {
        const crit = res;

        if (crit.variablesToShow >= 1 && item.criteriaValue1 == null) {
          errors.push(this.translate.instant('protocolEdit.dialog.criteria.form.error.value1'));
        } else if (crit.variablesToShow >= 2 && item.criteriaValue2 == null) {
          errors.push(this.translate.instant('protocolEdit.dialog.criteria.form.error.value2'));
        }
      }, () => {
        errors.push(this.translate.instant('protocolEdit.dialog.criteria.form.error.type'));
      });
    }

    if (ArrayUtils.isEmpty(errors)) {
      this.dialogRef.close(item);
    } else {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);
      console.error(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
