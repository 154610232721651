import { GenericClassTranslate } from './genericClass';

export class PeakEventType extends GenericClassTranslate {
}

export enum PeakEventTypeEnum {
    HIGH = 1,
    LOW = 2,
    BOTH = 3
}
