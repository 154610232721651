import { CreatePayment } from '../model/payment';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class PaymentsService {

  constructor(private http: HttpClient) { }

  findAllPlans(): Observable<any> {
    const url = environment.coreUrl + '/payments/plans';

    return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  findOnePlan(id: number): Observable<any> {
    const url = environment.coreUrl + `/payments/plan/${id}`;

    return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

  createUser(payment: CreatePayment): Observable<any> {
    const url = environment.coreUrl + '/payments/create';

    return this.http.post(url, payment).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
  }

}
