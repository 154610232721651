import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, map } from 'rxjs';
import { DexieService } from './dexieService';
import { OnlineService } from './online.service';
import { environment } from 'src/environments/environment';
import { HttpUtils } from '../utils/httpUtils';

@Injectable({
  providedIn: 'root'
})
export class TestQualificationTypeService {

  constructor(private http: HttpClient,
    private dexieService: DexieService,
    private onlineService: OnlineService) { }

  findAll(): Observable<any> {
    if (this.onlineService.latestOnline) {
        const url = environment.coreUrl + '/testQualificationType/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));

    } else {
        return from(this.dexieService.variableTypes.toArray());
    }
}
}
