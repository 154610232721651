import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExecutionReportFilter } from 'src/app/model/attachment';
import { Execution, ExecutionStatus } from 'src/app/model/execution';
import { Protocol } from 'src/app/model/protocol';
import { User } from 'src/app/model/user';
import { ExecutionService } from 'src/app/services/execution.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LangFlag, TranslationService } from 'src/app/services/translation.service';
import { UsersService } from 'src/app/services/users.service';
import { ExecutionUtils } from 'src/app/utils/executionUtils';
import { FileUtils } from 'src/app/utils/fileUtils';
import { StringUtils } from 'src/app/utils/stringUtils';

export interface DialogDataGenerateReport {
  execution: Execution;
  protocol: Protocol;
  reason: string;
  toSign: boolean;
  config: ExecutionReportFilter;
  isForAutomaticSign: boolean;
}

@Component({
  selector: 'app-execution-edit-generate-report',
  templateUrl: './execution-edit-generate-report.component.html'
})
export class ExecutionEditGenerateReportComponent implements OnInit, OnDestroy {

  optionsToDownload: string[] = [];
  optionsConfigReport: string[] = [];
  optionSelected: string;

  respDocs: User[];

  reportConfig: ExecutionReportFilter;

  langs: LangFlag[];

  changeConclusions: boolean;

  private destroy$ = new Subject<void>();
  constructor(
    public dialog: MatDialog, public dialogRef: MatDialogRef<ExecutionEditGenerateReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataGenerateReport, private executionService: ExecutionService,
    private usersService: UsersService, private translate: TranslateService, private translationService: TranslationService,
    public snackBarService: SnackBarService, private spinnerService: SpinnerService) {
  }

  ngOnInit(): void {
    if (this.data.config == null) {
      this.reportConfig = new ExecutionReportFilter();
      this.reportConfig.lang = this.translationService.currentLang;
    } else {
      this.reportConfig = this.data.config;
    }

    this.reportConfig.conclusions = this.data.execution.conclusions;
    this.changeConclusions = StringUtils.isNotEmpty(this.data.execution.conclusions);

    this.langs = this.translationService.configuredLangs;

    this.optionsToDownload.push('pre');
    this.optionsToDownload.push('report');
    this.optionsToDownload.push('full-report');

    this.optionsConfigReport.push('report');
    this.optionsConfigReport.push('full-report');

    this.optionSelected = 'report';

    this.usersService.findAllRespFromGroup().pipe(takeUntil(this.destroy$)).subscribe((item: User[]) => this.respDocs = item);

    if (this.data.execution.idStatus === ExecutionStatus.FIRMADO) {
      this.spinnerService.show();

      this.executionService.downloadPdfExecutionVersion(this.data.execution).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        const name = ExecutionUtils.getNameForReportPdf(this.data.execution, this.translate);
        saveAs(res, name);
        this.spinnerService.hide();

        this.dialogRef.close();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.notFound') as string);
        this.spinnerService.hide();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showConfigReport(): boolean {
    return this.optionsConfigReport.includes(this.optionSelected);
  }

  showChangeLangIcon(): boolean {
    return this.langs.length > 1;
  }

  onOptionChange(event: MatRadioChange): void {
    this.optionSelected = event.value as string;
  }

  onChangeConclusionsCheck(event: MatCheckboxChange): void {
    this.changeConclusions = event.checked;

    if (event.checked && StringUtils.isEmpty(this.reportConfig.conclusions)) {
      this.reloadConclusions();
    }
  }

  reloadConclusions(): void {
    this.spinnerService.show();

    this.executionService.getConclusions(this.data.execution.id, this.reportConfig.lang).pipe(takeUntil(this.destroy$)).subscribe(con => {
      this.reportConfig.conclusions = con.conclusions as string;
      this.spinnerService.hide();
    });
  }

  onOkClick(hybride: boolean): void {
    this.spinnerService.show();
    this.reportConfig.hybride = hybride;
    this.reportConfig.toSign = this.data.toSign;

    if (!this.changeConclusions) {
      this.reportConfig.conclusions = null;
    }

    if (this.optionSelected === 'pre') {
      this.defaultActionPrereport();
    } else if (this.optionSelected === 'report') {
      this.executionService.checkForDownload(this.data.execution.id).pipe(takeUntil(this.destroy$)).subscribe((errors: string[]) => {

        if (errors != null && errors.length > 0) {
          this.spinnerService.hide();

          this.snackBarService.sendError(errors.join('\n'));

          return;
        }

        if (this.data.reason) {
          this.reportConfig.reason = this.data.reason;
        }

        if (this.data.isForAutomaticSign) {
          this.dialogRef.close(this.reportConfig);
        } else {
          this.defaultActionReport();
        }
      }, err => {
        if (err != null && err.error != null && typeof err.error === 'string') {
          this.snackBarService.sendError(err.error as string);
        } else {
          this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.generic') as string);
        }

        this.spinnerService.hide();
      });

    } else if (this.optionSelected === 'full-report') {

      this.executionService.checkForDownload(this.data.execution.id).pipe(takeUntil(this.destroy$)).subscribe((errors: string[]) => {
        if (errors != null && errors.length > 0) {
          this.spinnerService.hide();

          this.snackBarService.sendError(errors.join('\n'));

          return;
        }

        this.defaultActionFullReport();

      }, err => {
        if (err != null && err.error != null && typeof err.error === 'string') {
          this.snackBarService.sendError(err.error as string);
        } else {
          this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.generic') as string);
        }

        this.spinnerService.hide();
      });
    } else {
      this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.generic') as string);
      this.spinnerService.hide();
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  defaultActionPrereport(): void {
    this.executionService.downloadPrereportPdf(this.data.execution.id, this.reportConfig).pipe(takeUntil(this.destroy$))
      .subscribe((res: Blob) => {
        const name = ExecutionUtils.getNameForPreReportPdf(this.data.execution, this.translate);
        saveAs(res, name);

        this.spinnerService.hide();

        this.dialogRef.close(this.reportConfig);
      }, error => {
        console.error(error);
        this.spinnerService.hide();
      });
  }

  defaultActionReport(): void {
    this.executionService.downloadPdf(this.data.execution.id, this.reportConfig).pipe(takeUntil(this.destroy$)).subscribe(err => {

      if (err != null && err.error != null && typeof err.error === 'string') {
        this.snackBarService.sendError(err.error as string);
      } else if (err.errors) {
        const error = err.errors.join('\n') as string;
        this.snackBarService.sendError(error);
      } else if (err.file) {
        const name = ExecutionUtils.getNameForReportPdf(this.data.execution, this.translate);
        saveAs(FileUtils.base64toBlob(err.file.contentString, err.file.contentType as string), name);
      }

      this.spinnerService.hide();

      this.dialogRef.close(this.reportConfig);
    }, err => {
      console.error(err);

      if (err != null && err.error != null && typeof err.error === 'string') {
        this.snackBarService.sendError(err.error as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.generic') as string);
      }

      this.spinnerService.hide();
    });
  }

  defaultActionFullReport(): void {
    this.executionService.downloadReportFullPdf(this.data.execution.id, this.reportConfig).pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res.errors) {
          const error = res.errors.join('\n') as string;
          this.snackBarService.sendError(error);
        } else if (res.file) {
          const name = ExecutionUtils.getNameForReportZip(this.data.execution, this.translate);
          saveAs(FileUtils.base64toBlob(res.file.contentString, res.file.contentType as string), name);
        }

        this.spinnerService.hide();

        this.dialogRef.close(this.reportConfig);
      }, err => {
        console.error(err);

        if (err != null && err.error != null && typeof err.error === 'string') {
          this.snackBarService.sendError(err.error as string);
        } else {
          this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.generic') as string);
        }

        this.spinnerService.hide();
      });
  }
}
