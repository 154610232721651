import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Execution, ExecutionFilter, ExecutionStatus } from 'src/app/model/execution';
import { Location, ViewportScroller } from '@angular/common';
import { Router, Scroll } from '@angular/router';
import { Subject, merge } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { CheckWarnsErrorsComponent } from '../../shared/check-warns-errors/check-warns-errors.component';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { Equipment } from 'src/app/model/equipment';
import { EquipmentUtils } from 'src/app/utils/equipmentUtils';
import { ExecutionDataSource } from 'src/app/model/executionDataSource';
import { ExecutionEditGenerateReportComponent } from '../execution-edit/execution-edit-generate-report.component';
import { ExecutionReportFilter } from 'src/app/model/attachment';
import { ExecutionService } from '../../../services/execution.service';
import { GenericWarnError } from 'src/app/model/genericClass';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { OnlineService } from 'src/app/services/online.service';
import { SelectGroupToCloneComponent } from '../../shared/select-group-to-clone/select-group-to-clone.component';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ThemeService } from 'src/app/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-execution-list',
  templateUrl: './execution-list.component.html'
})
export class ExecutionListComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() isProtocolDetail: boolean;
  @Input() idProtocolDetail: number;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: ExecutionDataSource;
  filter: ExecutionFilter = new ExecutionFilter();

  currentUser: User;

  disableOfflineButton = false;

  hasFiltered = false;

  displayedColumns: string[] = [
    'documentCode', 'projectNo', 'client', 'protocol', 'equipment', 'serialNum', 'internalId', 'status', 'department', 'endWorkDate',
    'realizationDate', 'download', 'edit', 'delete', 'clone', 'more'
  ];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private location: Location,
    private executionService: ExecutionService,
    private onlineService: OnlineService,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
    private themeService: ThemeService,
    private userService: UserService,
    public dialog: MatDialog,
    private viewportScroller: ViewportScroller,
    private spinnerService: SpinnerService) {
    this.router.events.pipe(filter(e => e instanceof Scroll)).pipe(takeUntil(this.destroy$)).subscribe((e: Scroll) => {
      setTimeout(() => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
    });
  }

  get pageTitle(): string {
    return this.translate.instant('executionList.title') as string;
  }

  ngOnInit(): void {
    this.currentUser = this.userService.currentProfile;

    if (this.isProtocolDetail == null) {
      this.isProtocolDetail = false;
    }

    if (this.isProtocolDetail && this.idProtocolDetail != null) {
      const columnsRemove = ['protocol', 'delete'];

      columnsRemove.forEach(col => this.displayedColumns.splice(this.displayedColumns.indexOf(col), 1));

      this.filter.idProtocol = this.idProtocolDetail;
      this.doFilter(this.filter);
    }

    this.onlineService.online$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.disableOfflineButton = !res;

      this.loadExecutionList();
    });

    this.disableOfflineButton = !this.onlineService.latestOnline;

    const excludedCols: string[] = [];

    if (!this.currentUser.showClone) {
      excludedCols.push('clone');
    }

    this.displayedColumns = this.displayedColumns.filter(c => !excludedCols.includes(c));

    const filterResult = localStorage.getItem('filter');

    if (filterResult) {
      this.hasFiltered = true;
      this.filter = JSON.parse(filterResult) as ExecutionFilter;
    }
    this.loadExecutionList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadExecutionList())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  new(): void {
    void this.router.navigateByUrl('thermal/execution?id=0');
  }

  editRow(id: number): void {
    void this.router.navigateByUrl(`thermal/execution?id=${id}`);
  }

  deleteRow(id: number): void {

    if (id > 0) {

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('executionList.dialog.delete.confirmation') as string
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
        if (result === true) {

          this.spinnerService.show();

          this.executionService.delete(id).pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.spinnerService.hide();

            this.loadExecutionList();

            this.snackBarService.sendSuccess(this.translate.instant('executionList.dialog.delete.ok') as string);
          }, () => {
            this.spinnerService.hide();

            this.snackBarService.sendError(this.translate.instant('executionList.dialog.delete.error.generic') as string);
          });
        }
      });
    }
  }

  canClone(ex: Execution): boolean {
    return ex?.idStatus === ExecutionStatus.FIRMADO;
  }

  cloneRow(id: number): void {
    const dialogRefSelectGroupToClone = this.dialog.open(SelectGroupToCloneComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRefSelectGroupToClone.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((idGroup: number) => {
      if (idGroup != null) {

        this.spinnerService.show();

        this.executionService.checkCopyToGroup(id, idGroup).subscribe((res: GenericWarnError) => {
          this.spinnerService.hide();

          const dialogRef = this.dialog.open(CheckWarnsErrorsComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {
              warns: res.warns,
              errors: res.errors
            }
          });

          dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((canContinue: boolean) => {
            if (canContinue === true) {
              this.spinnerService.show();
              this.executionService.copyToGroup(id, idGroup).subscribe(() => {
                this.spinnerService.hide();

                this.snackBarService.sendSuccess(this.translate.instant('executionEdit.form.clone.ok') as string);
              }, () => {
                this.spinnerService.hide();
                this.snackBarService.sendError(this.translate.instant('executionEdit.form.clone.error') as string);
              });
            }
          });
        }, () => {
          this.spinnerService.hide();
          this.snackBarService.sendError(this.translate.instant('executionEdit.form.clone.error') as string);
        });
      }
    });
  }

  getThemeIconPath(): string {
    const url = this.router.url;
    return this.themeService.getThemeIconPathSelection(url);
  }

  doFilter(event: ExecutionFilter): void {
    this.filter = event;

    this.hasFiltered = true;

    this.loadExecutionList();
  }

  loadExecutionList(): void {
    this.dataSource = new ExecutionDataSource(this.executionService);

    this.filter.sortBy = this.sort.active || 'documentCode';
    this.filter.sortDirection = this.sort.direction || 'desc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 10;

    if (this.hasFiltered) {
      this.saveSearchFilter(this.filter);
      this.dataSource.loadExecutions(this.filter);
    }

    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  showDownloadButton(execution: Execution): boolean {
    const validStatuses = [ExecutionStatus.PENDIENTE_FIRMA, ExecutionStatus.FIRMADO];

    return validStatuses.includes(execution.idStatus);
  }

  showDeleteButton(execution: Execution): boolean {
    const validStatuses = [ExecutionStatus.EN_EJECUCION];

    return validStatuses.includes(execution.idStatus) && !this.isProtocolDetail;
  }

  downloadPdf(execution: Execution): void {
    const dialogRef = this.dialog.open(ExecutionEditGenerateReportComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        execution,
        protocol: execution.protocol,
        reason: execution.reason
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionReportFilter) => {
      if (result != null) {
        this.loadExecutionList();
      }
    });
  }

  showProtocol(item: Execution): string {
    let res = '';

    if (item.protocol != null) {
      res = item.protocol.documentCode + 'v.' + item.originalProtocolVersion.toString().padStart(2, '0');
    } else {
      res = this.translate.instant('common.notApplicable') as string;
    }

    return res;
  }

  showEquipment(eqs: Equipment[]): string {
    return EquipmentUtils.showEquipmentsShort(eqs);
  }

  showSerialNumEquipment(eqs: Equipment[]): string {
    const res = [];
    eqs.forEach(item => {
      res.push(item.serialNum);
    });

    return res.join('\n');
  }

  showInternalIdEquipment(eqs: Equipment[]): string {
    const res = [];
    eqs.forEach(item => {
      res.push(item.internalId);
    });

    return res.join('\n');
  }

  exportTable(): void {
    this.filter.cols = this.displayedColumns;

    this.spinnerService.show();
    this.executionService.getExcel(this.filter).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name = this.pageTitle + '.xlsx';
      saveAs(res, name);
      this.spinnerService.hide();
    }, error => {
      console.error(error);
      this.spinnerService.hide();
    });
  }

  cancel(): void {
    this.location.back();
  }

  saveSearchFilter(execution: ExecutionFilter): void {
    localStorage.setItem('filter', JSON.stringify(execution));
  }
}
