import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class JosetAirService {

    private josetAirUrl = 'http://localhost:12345/';

    // Lighthouse
    private lighthouseIp = '192.168.20.9';
    private lighthousePort = 502;

    constructor(private http: HttpClient) {
    }

    downloadJosetizer(): Observable<any> {
        const url = environment.coreUrl + '/attachment/josetizer';

        return this.http.get(url, { responseType: 'blob' });
    }

    hello(): Observable<any> {
        const url = this.josetAirUrl;

        return this.http.get(url);
    }

    climet(): Observable<any> {
        const url = this.josetAirUrl + 'climet/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    lighthouse(locationNumber: number): Observable<any> {
        const url = this.josetAirUrl + `lighthouse/ip/${this.lighthouseIp}/port/${this.lighthousePort}/locationNum/${locationNumber}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
