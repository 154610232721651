import { Component, Inject, OnInit } from '@angular/core';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Protocol, ProtocolAir, ProtocolStatus } from 'src/app/model/protocol';
import { ProtocolEditGenerateReportComponent } from './protocol-edit-generate-report.component';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from 'src/app/services/snackBar.service';

export interface DialogDataConfirmSave {
  protocol: Protocol | ProtocolAir;
  reason: string;
}

@Component({
  selector: 'app-protocol-edit-dialog-confirm-save',
  templateUrl: 'protocol-edit-dialog-confirmSave.html'
})
export class ProtocolEditConfirmSaveComponent implements OnInit {

  matcher = new MyErrorStateMatcher();
  form: FormGroup;
  inSignState: boolean;

  constructor(
    fb: FormBuilder,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ProtocolEditConfirmSaveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataConfirmSave) {
    this.form = fb.group({
      reason: [this.data.reason, [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.inSignState = this.data.protocol.idStatus === ProtocolStatus.FIRMADO;
  }

  downloadDocument(): void {
    this.dialog.open(ProtocolEditGenerateReportComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {
        protocol: this.data.protocol
      }
    });
  }

  onOkClick(): void {

    if (!this.form.valid) {
      this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.confirmSave.form.error.reason') as string);
      return;
    }

    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
