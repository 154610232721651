<h1 mat-dialog-title></h1>
<form>
    <div mat-dialog-content class="air-theme">

        <div fxLayout="row" class="flexwrap">

            <div fxFlex>

                <div fxFlex="100%">
                    <mat-form-field class="campoFormulario">
                        <mat-label>{{ 'protocolEdit.dialog.equipment.type.label' | translate }}</mat-label>
                        <mat-select required name="idType" [(ngModel)]="data.essay.idEssayType"
                            (selectionChange)="onTypeChange($event)" [disabled]="data.isEdit">
                            <mat-select-filter [displayMember]="'translated'" [array]="essays"
                                (filteredReturn)="essaysFiltered =$event"
                                [noResultsMessage]="'common.noResults' | translate"
                                [placeholder]="'common.selectFilterPlaceholder' | translate"> </mat-select-filter>
                            <mat-option *ngFor="let item of essaysFiltered" [value]="item.id">
                                {{ item.translated }}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            {{ 'protocolEdit.dialog.equipment.type.error' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxFlex="100%" *ngIf="units?.length !== 0">
                    <mat-form-field class="campoFormulario">
                        <mat-label>{{ 'calibrateEquipmentEdit.calibrationData.unit.label' | translate }}</mat-label>
                        <mat-select name="unit" [(ngModel)]="data.essay.idUnit" required [disabled]="units.length < 2">
                            <mat-option *ngFor="let item of units" [value]="item.id">
                                {{item.unit}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            {{ 'calibrateEquipmentEdit.calibrationData.unit.error' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>

            <mat-toolbar color="secondary" *ngIf="data.essay.idEssayType != null">
                {{ 'protocolEdit.dialog.essay.fieldsTitle' | translate }}
            </mat-toolbar>

            <div fxFlex *ngIf="data.essay.idEssayType != null">
                <div fxLayout="row" class="flexwrap">
                    <div fxFlex="50" fxFlex.lt-md="100%"
                        *ngFor="let field of data.essay.essayValues; let indexField = index">

                        <!-- STRING -->
                        <div *ngIf="field.idTypeField == 1">
                            <mat-form-field class="campoFormulario">
                                <input matInput
                                    placeholder="{{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}"
                                    [name]="field.translationField" [(ngModel)]="field.value"
                                    [errorStateMatcher]="matcher" [disabled]="data.disabled" [required]="field.required"
                                    [ngModelOptions]="{standalone: true}">
                                <mat-error>
                                    {{ 'protocolEdit.dialog.essay.' + field.translationField + '.error' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- NUMÉRICO -->
                        <div *ngIf="field.idTypeField == 2">
                            <mat-form-field class="campoFormulario">
                                <input matInput
                                    placeholder="{{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}"
                                    [name]="field.translationField" [(ngModel)]="field.value"
                                    [ngModelOptions]="{standalone: true}" [errorStateMatcher]="matcher"
                                    (ngModelChange)="setValue(field, -1, $event)" type="number"
                                    [disabled]="data.disabled" [required]="field.required">
                                <mat-error>
                                    {{ 'protocolEdit.dialog.essay.' + field.translationField + '.error' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- TOLERANCIA -->
                        <div *ngIf="field.idTypeField == 3">
                            <div fxFlex="40%" fxFlex.lt-md="100%">
                                <mat-form-field class="campoFormulario">
                                    <input matInput
                                        placeholder="{{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}"
                                        [name]="field.translationField" [(ngModel)]="field.value[0]"
                                        [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="setValue(field, 0, $event)" [errorStateMatcher]="matcher"
                                        type="number" [disabled]="data.disabled" [required]="field.required">
                                    <mat-error>
                                        {{ 'protocolEdit.dialog.essay.' + field.translationField + '.error' | translate
                                        }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="40%" fxFlex.lt-md="100%">
                                ±
                                <mat-form-field class="campoFormulario">
                                    <input matInput
                                        placeholder="{{ 'protocolEdit.dialog.essay.valueTolerance.label' | translate }}"
                                        [name]="field.translationField + 'Tolerance'" [(ngModel)]="field.value[1]"
                                        (ngModelChange)="setValue(field, 1, $event)" [errorStateMatcher]="matcher"
                                        type="number" [disabled]="data.disabled" [required]="field.required"
                                        [ngModelOptions]="{standalone: true}">
                                    <mat-error>
                                        {{ 'protocolEdit.dialog.essay.valueTolerance.error' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- DIA HORA MIN -->
                        <div *ngIf="field.idTypeField == 4">
                            <div fxLayout="row">
                                <div fxFlex="34%" fxFlex.lt-md="100%">
                                    <mat-form-field class="campoFormulario">
                                        <input matInput [name]="field.translationField + 'Day'"
                                            [(ngModel)]="field.value[0]" (ngModelChange)="setValue(field, 0, $event)"
                                            placeholder="{{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}: {{ 'common.days.label' | translate }}"
                                            [errorStateMatcher]="matcher" type="number" [disabled]="data.disabled"
                                            [required]="field.required" min="0" min="0"
                                            [ngModelOptions]="{standalone: true}">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="33%" fxFlex.lt-md="100%">
                                    <mat-form-field class="campoFormulario">
                                        <input matInput [name]="field.translationField + 'Hour'"
                                            [(ngModel)]="field.value[1]" (ngModelChange)="setValue(field, 1, $event)"
                                            placeholder="{{ 'common.hours.label' | translate }}"
                                            [errorStateMatcher]="matcher" type="number" [disabled]="data.disabled"
                                            [required]="field.required" min="0" min="0"
                                            [ngModelOptions]="{standalone: true}">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="33%" fxFlex.lt-md="100%">
                                    <mat-form-field class="campoFormulario">
                                        <input matInput [name]="field.translationField + 'Minute'"
                                            [(ngModel)]="field.value[2]" (ngModelChange)="setValue(field, 2, $event)"
                                            placeholder="{{ 'common.minutes.label' | translate }}"
                                            [errorStateMatcher]="matcher" type="number" [disabled]="data.disabled"
                                            [required]="field.required" min="0" [ngModelOptions]="{standalone: true}">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <!-- SELECT_ONE -->
                        <div *ngIf="field.idTypeField == 6">
                            <span>
                                {{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}
                            </span>
                            <br />
                            <mat-radio-group class="vertical-radio-group" [required]="field.required">
                                <mat-radio-button class="vertical-radio-button" *ngFor="let doma of field.fieldDomain"
                                    [value]="doma.value" (change)="setValue(field, -1, $event)"
                                    [checked]="field.value == doma.value">
                                    {{ 'protocolEdit.dialog.essay.' + doma.translation + '.label' | translate }}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <!-- SELECT_MULTI -->
                        <div *ngIf="field.idTypeField == 7">
                            <span>
                                {{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}
                                <span *ngIf="field.required">*</span>
                            </span>
                            <mat-selection-list [disabled]="data.disabled"
                                (selectionChange)="setValue(field, -1, $event)">
                                <mat-list-option *ngFor="let doma of field.fieldDomain" checkboxPosition="before"
                                    [disabled]="data.disabled" [value]="doma.value"
                                    [selected]="isSelectedMulti(field.value, doma.value)">
                                    {{ 'protocolEdit.dialog.essay.' + doma.translation + '.label' | translate }}
                                </mat-list-option>
                            </mat-selection-list>
                        </div>

                        <!-- BOOLEAN -->
                        <div *ngIf="field.idTypeField == 8">
                            <mat-checkbox [value]="field.value" [checked]="field.value == 'true'"
                                [disabled]="data.disabled" (change)="setValue(field, -1, $event)">
                                {{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}
                            </mat-checkbox>
                        </div>

                    </div>
                    <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="data.essay?.essayValues?.length == 0"
                        style="margin-top: 2em;margin-bottom: 2em;">
                        {{ 'protocolEdit.dialog.essay.fields.empty' | translate }}
                    </div>
                </div>
            </div>

            <mat-toolbar color="secondary" *ngIf="data.essay.idEssayType != null">
                {{ 'protocolEdit.dialog.essay.criteriaTitle' | translate }}

                <button mat-icon-button color="accent" (click)="openNewCriteria()" *ngIf="showNewCriteria()">
                    <mat-icon>add</mat-icon>
                </button>
            </mat-toolbar>

            <div fxLayout="row" class="flexwrap" style="width: 100%;"
                *ngIf="data.essay.idEssayType != null && showNewCriteria()">
                <div fxFlex>
                    <table mat-table matSort #criteriaTable [dataSource]="data.essay.criterias">

                        <!-- criteria Column -->
                        <ng-container matColumnDef="criteria">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'protocolEdit.dialog.essay.criteria.cols.criteria' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item"> {{ showCriteria(item) }} </td>
                        </ng-container>

                        <!-- Button edit Column -->
                        <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
                            <td mat-cell *matCellDef="let element; let criteriaIndex = index">
                                <button mat-icon-button (click)="editCriteria(criteriaIndex)">
                                    <mat-icon>mode_edit</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <!-- Button delete Column -->
                        <ng-container matColumnDef="delete">
                            <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
                            <td mat-cell *matCellDef="let element; let criteriaIndex = index">
                                <button mat-icon-button (click)="removeCriteria(criteriaIndex)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColsCriteria"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColsCriteria;"></tr>

                    </table>

                </div>
            </div>
            <div fxFlex *ngIf="data.essay.idEssayType != null && !showNewCriteria()"
                style="margin-top: 2em;margin-bottom: 2em;">
                {{ 'protocolEdit.dialog.essay.fields.empty' | translate}}
            </div>
        </div>
        <div fxLayout="row" class="flexwrap">
            <mat-toolbar color="secondary">
                {{ 'protocolEdit.observations.title' | translate }}
            </mat-toolbar>

            <div fxLayout="row" style="width: 100%;">
                <div fxFlex>
                    <mat-form-field class="campoFormulario" style="width: 100%">
                        <textarea rows="5" matAutosizeMinRows="5" matInput
                            placeholder="{{ 'protocolEdit.observations.label' | translate }}"
                            [(ngModel)]="data.essay.notes" name="observations" style="resize: none;">

                        </textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="air-theme">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()" cdkFocusInitial
            [disabled]="data.essay.idEssayType == null">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="accent" (click)="delete()" *ngIf="data.isEdit">
            {{ 'button.delete' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>

</form>