import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThemeService } from 'src/app/services/theme.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';

export interface DialogDataCheckWarnsErrors {
  errors: string[];
  warns: string[];
}

@Component({
  selector: 'app-check-warns-errors',
  templateUrl: './check-warns-errors.component.html'
})
export class CheckWarnsErrorsComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCheckWarnsErrors,
    private themeService: ThemeService,
    public dialogRef: MatDialogRef<CheckWarnsErrorsComponent>) {
  }

  ngOnInit(): void {
    if (this.isEmpty(this.data.warns) && this.isEmpty(this.data.errors)) {
      this.onOkClick();
    }
  }

  isEmpty(array: any[]): boolean {
    return ArrayUtils.isEmpty(array);
  }

  onOkClick(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  getTheme(): string {
    return this.themeService.getTheme();
  }

}
