import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ValidatorSpecifics } from 'src/app/model/validatorSpecific';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { StringUtils } from 'src/app/utils/stringUtils';

export interface DialogDataValidatorEdit {
  validator: ValidatorSpecifics;
}

@Component({
  selector: 'app-execution-edit-validator-edit',
  templateUrl: './execution-edit-validator-edit.component.html'
})
export class ExecutionEditValidatorEditComponent implements OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<ExecutionEditValidatorEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataValidatorEdit,
    private translate: TranslateService,
    public snackBarService: SnackBarService) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showReason(): boolean {
    const vali = this.data.validator;
    const lowTemp = vali.lowTemp;
    const lowTempProtocol = vali.lowTempProtocol;
    const highTemp = vali.highTemp;
    const highTempProtocol = vali.highTempProtocol;
    const verifTemp = vali.verifTemp;
    const verifTempProtocol = vali.verifTempProtocol;
    const lowDesv = vali.lowDesv;
    const lowDesvProtocol = vali.lowDesvProtocol;
    const highDesv = vali.highDesv;
    const highDesvProtocol = vali.highDesvProtocol;
    const verifDesv = vali.verifDesv;
    const verifDesvProtocol = vali.verifDesvProtocol;
    const minStability = vali.minStability;
    const minStabilityProtocol = vali.minStabilityProtocol;
    const minStabilityTime = vali.minStabilityTime;
    const minStabilityTimeProtocol = vali.minStabilityTimeProtocol;

    return vali.fromProtocol && (lowTemp !== lowTempProtocol || highTemp !== highTempProtocol || verifTemp !== verifTempProtocol
      || lowDesv !== lowDesvProtocol || highDesv !== highDesvProtocol || verifDesv !== verifDesvProtocol
      || minStability !== minStabilityProtocol || minStabilityTime !== minStabilityTimeProtocol);
  }

  onOkClick(): void {
    const vali = this.data.validator;
    const reason = vali.valueChangeReason;
    const lowTemp = vali.lowTemp;
    const highTemp = vali.highTemp;
    const verifTemp = vali.verifTemp;
    const lowDesv = vali.lowDesv;
    const highDesv = vali.highDesv;
    const verifDesv = vali.verifDesv;
    const minStability = vali.minStability;
    const minStabilityTime = vali.minStabilityTime;

    if (lowTemp == null || highTemp == null || verifTemp == null || lowDesv == null || highDesv == null || verifDesv == null
      || minStability == null || minStabilityTime == null) {
      this.snackBarService.sendError(this.translate.instant('protocolEdit.instrumentationUse.validator.form.fieldRequired') as string);
      return;
    }

    if (this.showReason() && StringUtils.isEmpty(reason)) {
      this.snackBarService.sendError(this.translate.instant('protocolEdit.instrumentationUse.validator.reason.error') as string);
      return;
    }

    if (!this.showReason()) {
      vali.valueChangeReason = null;
    }

    this.dialogRef.close(vali);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
