<div [class]="getTheme()">
    <form *ngIf="!roouterPath">
        <div fxLayout="row">
            <div fxFlex="20" fxHide.xs="true" fxHide="false" fxHide.sm="true" fxHide.md="true">
                <div style="width: 350px;"></div>

                <app-fixed-sidebar></app-fixed-sidebar>
            </div>
            <div class="main-container small-layout" fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100">

                <div class="lead-text centerText">
                    <p [innerHtml]="getText() | translate | safe: 'html'"> </p>
                </div>

                <div fxLayout="column">
                    <div fxFlex="80" class="content-card"  fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100">
                        <div fxLayout="row" class="block-highlights">
                            <mat-card class="card" (click)="navigateToCalibratesOptions('calibrations')"
                                appMaterialElevation>
                                <mat-card-content class="pointerHand">
                                    <div class="action-floating new-badge" (click)="newCalibration()">
                                        <mat-icon>add_circle</mat-icon>
                                        <span>{{ 'button.new' | translate }}</span>
                                    </div>
                                    <div class="action-floating info-badge" fxHide.lt-md
                                        matTooltip="{{ 'menu.calibrate-equipments.info' | translate }}">
                                        <mat-icon color="accent">info</mat-icon>
                                    </div>
                                    <img class="icon" src="{{ getThemeIconPath() }}calibrates.svg"
                                        alt="{{ 'menu.manage' | translate }} {{ 'menu.calibrate-equipments' | translate }}">
                                    <span class="title accent-color">{{ 'menu.calibrate-equipments' | translate }}</span>
                                </mat-card-content>
                            </mat-card>
    
                            <mat-card class="card" (click)="navigateToCalibratesOptions('isothermalCharacterizations')"
                                appMaterialElevation>
                                <mat-card-content class="pointerHand">
                                    <div class="action-floating new-badge" (click)="newIsothermalCharacterization()">
                                        <mat-icon>add_circle</mat-icon>
                                        <span>{{ 'button.new' | translate }}</span>
                                    </div>
                                    <div class="action-floating info-badge" fxHide.lt-md
                                        matTooltip="{{ 'menu.isothermal-characterization.info' | translate }}">
                                        <mat-icon color="accent">info</mat-icon>
                                    </div>
                                    <img class="icon" src="{{ getThemeIconPath() }}isothermalCharacterization.svg"
                                        alt="{{ 'menu.manage' | translate }} {{ 'menu.isothermal-characterization' | translate }}">
                                    <span class="title accent-color">{{ 'menu.isothermal-characterization' | translate
                                        }}</span>
                                </mat-card-content>
                            </mat-card>
    
                            <mat-card class="card" (click)="navigateToCalibratesOptions('internalEquipments')"
                                appMaterialElevation>
                                <mat-card-content class="pointerHand">
                                    <div class="action-floating new-badge" (click)="newInternalEquipment()">
                                        <mat-icon>add_circle</mat-icon>
                                        <span>{{ 'button.new' | translate }}</span>
                                    </div>
                                    <div class="action-floating info-badge" fxHide.lt-md
                                        matTooltip="{{ 'menu.my-equipments.info' | translate }}">
                                        <mat-icon color="accent">info</mat-icon>
                                    </div>
                                    <img class="icon" src="{{ getThemeIconPath() }}internalEquipments.svg"
                                        alt="{{ 'menu.manage' | translate }} {{ 'menu.my-equipments' | translate }}">
                                    <span class="title accent-color">
                                        {{ 'menu.my-equipments' | translate }}
                                    </span>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div fxLayout="row" class="block-highlights" style="margin: 0; justify-content: center;">
                            <div fxFlex="100">
                                <mat-card class="card card-sub" (click)="navigateToCalibratesOptions('externalEquipments')"
                                    appMaterialElevation *ngIf="showExternalEquipments()">
                                    <mat-card-content class="pointerHand">
                                        <div class="action-floating new-badge" (click)="newExternalEquipment()">
                                            <mat-icon>add_circle</mat-icon>
                                            <span>{{ 'button.new' | translate }}</span>
                                        </div>
                                        <div class="action-floating info-badge" fxHide.lt-md
                                            matTooltip="{{ 'menu.external-equipment.info' | translate }}">
                                            <mat-icon color="accent">info</mat-icon>
                                        </div>
                                        <img class="icon" src="{{ getThemeIconPath() }}externalEquipments.svg"
                                            alt="{{ 'menu.manage' | translate }} {{ 'menu.external-equipment' | translate }}">
                                        <span class="title accent-color">{{ 'menu.external-equipment' | translate }}</span>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </form>

    <router-outlet *ngIf="roouterPath"></router-outlet>
</div>