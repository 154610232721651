import { ManageNotificationFilter, NotificationManage, SendNotification } from '../model/notification';

import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ManageNotificationsService {

    constructor(private http: HttpClient) { }

    findAll(filter: ManageNotificationFilter): Observable<any> {
        const url = environment.coreUrl + '/manageNotifications/';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOne(id: number): Observable<any> {
        const url = environment.coreUrl + `/manageNotifications/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    update(notif: NotificationManage): Observable<any> {
        const url = environment.coreUrl + `/manageNotifications/${notif.id}`;

        return this.http.put(url, notif).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    sendNotification(notif: SendNotification): Observable<any> {
        const url = environment.thermalUrl + '/sendNotifications/';

        return this.http.post(url, notif).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

}
