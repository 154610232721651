import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ContactPerson } from 'src/app/model/model';
import { SnackBarService } from 'src/app/services/snackBar.service';

export interface DialogDataContactPerson {
  person: ContactPerson;
  isEdit: boolean;
}

@Component({
  selector: 'app-client-contat-dialog-contact-person',
  templateUrl: './client-contat-dialog-contact-person.component.html'
})

export class ClientContatDialogContactPersonComponent{

  tituloDialog: string;

  constructor(public snackBarService: SnackBarService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<ClientContatDialogContactPersonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataContactPerson) { }

    onOkClick(): void {
      const errs: string[] = [];
  
      if (this.data.person.name == null) {
        errs.push(this.translate.instant('protocolEdit.dialog.person.name.error') as string);
      }

      if (this.data.person.email == null) {
        errs.push(this.translate.instant('protocolEdit.dialog.person.email.error') as string);
      }

      if (errs.length === 0) {
        this.dialogRef.close(this.data.person);
      } else {
        const error = errs.join('\n');
        this.snackBarService.sendError(error);
      }
  
    }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
}
