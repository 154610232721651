import { Protocol, ProtocolAir, ProtocolQ } from '../model/protocol';

import { ArrayUtils } from './arrayUtils';
import { StringUtils } from './stringUtils';
import { TranslateService } from '@ngx-translate/core';

export class ProtocolUtils {

    public static getNameForProtocol(protocol: Protocol, translate: TranslateService): string {
        const documentCode = protocol.documentCode;
        const versionName = 'v.' + protocol.currentVersion.toString().padStart(2, '0');
        const clientName = protocol.nameClient;
        let equipment = null;
        let serialNum = null;
        let internalId = null;

        if (!ArrayUtils.isEmpty(protocol.equipments)) {
            const eq = protocol.equipments[0];

            equipment = eq.equipment;
            if (StringUtils.isNotEmpty(eq.serialNum)) {
                serialNum = '(' + eq.serialNum + ')';
            }

            if (StringUtils.isNotEmpty(eq.internalId)) {
                internalId = '(' + eq.internalId + ')';
            }
        }

        const name = [documentCode, versionName, translate.instant('protocolEdit.files.protocol.documentType'), clientName, equipment,
            serialNum, internalId].filter((s: string) => StringUtils.isNotEmpty(s)).join(' ');

        const res: string = translate.instant('protocolEdit.files.protocol', { name }) as string;

        return res;
    }

    public static getNameForProtocolAir(protocol: ProtocolAir, translate: TranslateService): string {
        const documentCode = protocol.documentCode;
        const versionName = 'v.' + protocol.currentVersion.toString().padStart(2, '0');
        const clientName = protocol.nameClient;

        let areas = null;

        if (!ArrayUtils.isEmpty(protocol?.areas)) {
            areas = protocol.areas.map(a => a.name).join(', ');
        }
        const name = [documentCode, versionName, translate.instant('protocolEdit.files.protocol.documentType'), clientName, areas]
            .filter((s: string) => StringUtils.isNotEmpty(s)).join(' ');

        const res: string = translate.instant('protocolEdit.files.protocol', { name }) as string;

        return res;
    }

    public static getNameForProtocolQualificates(protocol: ProtocolQ, translate: TranslateService): string {
        const documentCode = protocol.documentCode;
        const versionName = 'v.' + protocol.currentVersion.toString().padStart(2, '0');
        const clientName = protocol.nameClient;
        let equipment = null;
        let serialNum = null;
        let internalId = null;

        if (!ArrayUtils.isEmpty(protocol.equipments)) {
            const eq = protocol.equipments[0];

            equipment = eq.equipment;
            if (StringUtils.isNotEmpty(eq.serialNumber)) {
                serialNum = '(' + eq.serialNumber + ')';
            }

            if (StringUtils.isNotEmpty(eq.internalId)) {
                internalId = '(' + eq.internalId + ')';
            }
        }

        const name = [documentCode, versionName, translate.instant('protocolEdit.files.protocol.documentType'), clientName, equipment,
            serialNum, internalId].filter((s: string) => StringUtils.isNotEmpty(s)).join(' ');

        const res: string = translate.instant('protocolEdit.files.protocol', { name }) as string;

        return res;
    }

}
