import { BehaviorSubject, Observable } from 'rxjs';
import { Calibration, CalibrationFilter } from './calibration';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';

import { CalibrationService } from '../services/calibration.service';
import { of } from 'rxjs';

export class CalibrationDataSource implements DataSource<Calibration> {

    public data: Calibration[];

    private calibrationsSubject = new BehaviorSubject<Calibration[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalSubject = new BehaviorSubject<number>(0);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public loading$ = this.loadingSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public totalItems$ = this.totalSubject.asObservable();

    constructor(private service: CalibrationService) {
        this.calibrationsSubject.subscribe(data => this.data = data);
    }

    connect(_collectionViewer: CollectionViewer): Observable<Calibration[]> {
        return this.calibrationsSubject.asObservable();
    }

    disconnect(_collectionViewer: CollectionViewer): void {
        this.calibrationsSubject.complete();
        this.loadingSubject.complete();
        this.totalSubject.complete();
    }

    loadCalibration(filter = new CalibrationFilter()): void {

        this.loadingSubject.next(true);

        this.service.findAll(filter).pipe(catchError(() => of([])), finalize(() => this.loadingSubject.next(false))
        ).subscribe(data => {
            if (data && data.content) {
                this.calibrationsSubject.next(data.content as Calibration[]);
                this.totalSubject.next(data.totalElements as number);
            } else if (data) {
                this.calibrationsSubject.next(data as Calibration[]);
                this.totalSubject.next(data.length as number);
            } else {
                this.calibrationsSubject.next([]);
                this.totalSubject.next(0);
            }
        });
    }

}
