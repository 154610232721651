import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CompressedGas } from 'src/app/model/compressedGasesAir';
import { EquipmentType } from 'src/app/model/equipmentType';
import { SnackBarService } from 'src/app/services/snackBar.service';

export interface DialogDataCompressedGasAir {
  compressedGasTypes: EquipmentType[];
  compressedGas: CompressedGas;
  isEdit: boolean;
  isExecution: boolean;
  idExecution: number;
}

@Component({
  selector: 'app-protocol-edit-dialog-compressed-gas-air',
  templateUrl: './protocol-edit-dialog-compressed-gas-air.component.html'
})
export class ProtocolEditDialogCompressedGasAirComponent {

  public displayedColsFilter = ['order', 'filter', 'size', 'changeFilter', 'edit', 'delete'];

  constructor(
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ProtocolEditDialogCompressedGasAirComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCompressedGasAir) {

    if (this.data.isExecution == null) {
      this.data.isExecution = false;
    }

    if (!this.data.isExecution) {
      this.displayedColsFilter = this.displayedColsFilter.filter(a => a !== 'changeFilter');
    }

  }

  onOkClick(): void {
    const errs = this.validateForm();
    if (errs.length === 0) {
      this.dialogRef.close(this.data.compressedGas);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  validateForm(): string[] {
    const errs: string[] = [];

    if (this.data.compressedGas.idType == null) {
      errs.push(this.translate.instant('common.roomAir.idType.error') as string);
    }
    if (this.data.compressedGas.code == null) {
      errs.push(this.translate.instant('common.roomAir.name.error') as string);
    }

    return errs;
  }

}