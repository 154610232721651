import { Observable, from } from 'rxjs';

import { AccessoryFilterType } from '../model/accessoryFilterType';
import { DexieService } from './dexieService';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { OnlineService } from './online.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class AccessoryFilterTypeService {

    constructor(
        private http: HttpClient,
        private dexieService: DexieService,
        private onlineService: OnlineService) {
    }

    findAll(): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + '/accessoryFilterType/';

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.subfilterTypes.toArray().timeout(30000));
        }
    }

    saveSubFilterTypeToDexie(): Promise<void> {
        return new Promise<void>((resolve) => this.findAll().subscribe((res: AccessoryFilterType[]) => {
            if (res) {
                void this.dexieService.subfilterTypes.clear().then(() => {
                    res.forEach(type => {
                        void this.dexieService.subfilterTypes.put(type, type.id).then(() => resolve());
                    });
                });
            }
        }));
    }
}
