<h1 mat-dialog-title>{{ 'changeProfile.title' | translate }}</h1>

<div mat-dialog-content [class]="getTheme()">

  <div fxLayout="row" class="flexwrap">
    <div fxFlex>
      <mat-form-field class="campoFormulario">
        <mat-label>{{ 'changeProfile.text' | translate }}</mat-label>
        <mat-select name="userRole" [(ngModel)]="selectedRole">
          <mat-option *ngFor="let role of roles" [value]="role">
            {{role.groupName}} ({{ 'role.' + role.roleTranslation | translate }})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

</div>

<div mat-dialog-actions align="end" [class]="getTheme()">
  <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
  <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
</div>