import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from 'src/app/external-components/angular-material.module';
import { CommonModule } from '@angular/common';
import { DevextremeModule } from 'src/app/external-components/devextreme.module';
import { ExecutionListComponent } from './execution-list.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SharedModule } from '../../shared/shared.module';
import { ExecutionListFilterComponent } from './execution-list-filter.component';

@NgModule({
  declarations: [
    ExecutionListComponent,
    ExecutionListFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    /* Custom modules */
    AngularMaterialModule,
    DevextremeModule,
    SharedModule,
    PipesModule,

    /* Otros */
    FlexLayoutModule
  ]
})
export class ExecutionAirListModule { }
