<div class="main-container small-layout">

    <div class="toolbar-list">

        <div class="toolbar-list-title">
            <div fxLayout="row" fxLayoutAlign="start center">
                <h1>{{ 'profile.title' | translate }}</h1>
            </div>
            <div class="breadcrumbs">
                <a (click)="redirectionPage()"><u>{{ 'common.start.label' | translate }}</u></a> / <span>{{ 'profile.title' | translate }}</span>
            </div>
        </div>

        <div class="toolbar-list-actions">
          <button mat-raised-button (click)="save()"
            matTooltip="{{ 'button.disabled.offline' | translate }}">
            <mat-icon fxHide.lt-sm>save</mat-icon> {{ 'button.save' | translate }}
          </button>
        </div>

        <div class="toolbar-back-button">
            <a (click)="cancel()" mat-button>
                <mat-icon>undo</mat-icon> {{ 'button.back' | translate }}</a>
        </div>
    </div>

    <div class="user-info-container" fxLayout="row" fxLayoutAlign="left center">
        <div class="user-pic">
            <span class="circle mat-elevation-z2" fxLayout="row" fxLayoutAlign="center center">
              <span class="initialLetters">{{ getInitials() }}</span>
            <a mat-raised-button color="primary" *ngIf="false">
                <mat-icon>create</mat-icon>
            </a>
            </span>
        </div>

        <div class="user-info">
            <h2>{{ user.fullName }}</h2>
            <p>{{ user.activeProfile }}</p>
            <p>{{ 'role.' + user.activeRoleTranslation | translate }}</p>
        </div>
    </div>

    <mat-card class="card" *ngIf="!isGuest">
        <mat-card-content>
            <h2>{{ 'profile.personal.title' | translate }}</h2>
            <div class="profile">
                <mat-slide-toggle [(ngModel)]="user.receiveEmails" name="receiveEmails" [checked]="true" [disabled]="false">
                    {{ 'profile.input.messages' | translate }}
                </mat-slide-toggle>
            </div>
        </mat-card-content>
    </mat-card>

    <form [formGroup]="changePasswordForm">
        <mat-card class="card">
            <mat-card-content>
                <h2>{{ 'profile.changePassword.title' | translate }}</h2>
                <div class="profile">
                    <mat-form-field class="full-width">
                        <input matInput type="password" placeholder="{{ 'profile.changePassword.currentPassword.label' | translate }}" formControlName="currentPassword" [(ngModel)]="currentPassword" autocomplete="current-password" required [errorStateMatcher]="matcher">
                        <mat-error>
                            {{ 'profile.changePassword.currentPassword.error' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <div class="form-separator"></div>
                    <mat-form-field class="full-width">
                        <input id="newPassword" matInput type="password" placeholder="{{ 'profile.changePassword.newPassword.label' | translate }}" formControlName="newPassword" [(ngModel)]="newPassword" autocomplete="new-password" required [errorStateMatcher]="matcher">
                        <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('required')">
                            {{ 'profile.changePassword.newPassword.error.required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('pattern')">
                            {{ 'profile.changePassword.newPassword.error.pattern' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="full-width">
                        <input id="confirmPassword" matInput type="password" placeholder="{{ 'profile.changePassword.confirmPassword.label' | translate }}" formControlName="confirmPassword" [(ngModel)]="confirmPassword" autocomplete="new-password" required [errorStateMatcher]="matcher">
                        <mat-error *ngIf="changePasswordForm.get('confirmPassword').hasError('required')">
                            {{ 'profile.changePassword.confirmPassword.error.required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="changePasswordForm.get('confirmPassword').hasError('pattern')">
                            {{ 'profile.changePassword.confirmPassword.error.pattern' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <div fxLayoutAlign="start center">
                    <button mat-raised-button color="primary" (click)="changePassword()">{{ 'button.changePassword' | translate }}</button>
                </div>
            </mat-card-actions>
        </mat-card>

    </form>

</div>