import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Defrost } from 'src/app/model/defrost';
import { SensorData } from 'src/app/model/sensorData';
import { DefrostService } from 'src/app/services/defrost.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { ExecutionEditDefrostEditComponent } from './execution-edit-defrost-edit.component';

export interface DialogDataDefrostList {
  idEssay: number;
  data: SensorData[];
}

@Component({
  selector: 'app-execution-edit-defrost-list',
  templateUrl: './execution-edit-defrost-list.component.html'
})
export class ExecutionEditDefrostListComponent implements OnInit, OnDestroy {

  displayedColsUsers = ['index', 'startDate', 'endDate', 'type', 'edit', 'delete'];

  defrosts: Defrost[];

  private destroy$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<ExecutionEditDefrostListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataDefrostList,
    private defrostService: DefrostService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.reloadTable();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  reloadTable(): void {
    this.spinnerService.show();
    this.defrostService.findAllFromEssay(this.data.idEssay).pipe(takeUntil(this.destroy$)).subscribe((item: Defrost[]) => {
      this.defrosts = item;
      this.spinnerService.hide();
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  newDefrost(): void {
    this.openDialogEdit(new Defrost());
  }

  editRow(id: number): void {
    this.defrostService.findOne(id).pipe(takeUntil(this.destroy$)).subscribe((item: Defrost) => this.openDialogEdit(item));
  }

  deleteRow(id: number): void {
    const message = this.translate.instant('executionEdit.events.form.save.confirm') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result === true) {
        this.spinnerService.show();

        this.defrostService.delete(id).pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.reloadTable();

          this.spinnerService.hide();
        });
      }
    });

  }

  private openDialogEdit(defrost: Defrost) {
    const dialogRef = this.dialog.open(ExecutionEditDefrostEditComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        defrost,
        data: this.data.data
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Defrost) => {
      if (result != null) {
        result.idEssay = this.data.idEssay;

        this.spinnerService.show();

        this.defrostService.save(result).pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.snackBarService.sendSuccess(this.translate.instant('executionEdit.events.form.save.ok') as string);

          this.spinnerService.hide();

          this.reloadTable();
        }, () => {
          this.snackBarService.sendError(this.translate.instant('executionEdit.events.form.save.error.generic') as string);

          this.spinnerService.hide();

          this.reloadTable();
        });
      }
    });
  }

}
