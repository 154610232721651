<form [formGroup]="form">
  <mat-accordion>
    <mat-expansion-panel [expanded]="expandedFilter" (opened)="expandedFilter = true" (closed)="expandedFilter = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'isothermalCharacterizationList.filter.title' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

        <div fxFlex="25" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput formControlName="documentCode" [(ngModel)]="filter.documentCode"
              placeholder="{{ 'isothermalCharacterizationList.filter.documentCode.label' | translate }}">
          </mat-form-field>
        </div>

        <div fxFlex="25" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <mat-label>{{ 'isothermalCharacterizationList.filter.client.label' | translate }}</mat-label>
            <mat-select #clientSelect formControlName="idClient" [(ngModel)]="filter.idClient">
              <mat-select-filter *ngIf="clientSelect.focused" [displayMember]="'name'" [array]="clients"
                (filteredReturn)="clientsFiltered =$event" [noResultsMessage]="'common.noResults' | translate"
                [placeholder]="'common.selectFilterPlaceholder' | translate"></mat-select-filter>
              <mat-option *ngFor="let client of clientsFiltered" [value]="client.id">
                {{ client.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="25" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput formControlName="equipment" [(ngModel)]="filter.equipment"
              placeholder="{{ 'isothermalCharacterizationList.filter.equipment.label' | translate }}">
          </mat-form-field>
        </div>

        <div fxFlex="25" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput formControlName="equipmentSerialNum" [(ngModel)]="filter.equipmentSerialNum"
              placeholder="{{ 'isothermalCharacterizationList.filter.equipmentSerialNum.label' | translate }}">
          </mat-form-field>
        </div>

      </div>

      <mat-accordion>
        <mat-expansion-panel expanded="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'isothermalCharacterizationList.filter.advanced.title' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <mat-label>{{ 'isothermalCharacterizationList.filter.status.label' | translate }}</mat-label>
                <mat-select formControlName="idStatus" [(ngModel)]="filter.idStatus">
                  <mat-option [value]="null"> --- </mat-option>
                  <mat-option *ngFor="let item of statuses" [value]="item.id">
                    {{ 'isothermalCharacterizationStatus.' + item.translation | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <mat-label>{{ 'isothermalCharacterizationList.filter.regUser.label' | translate }}</mat-label>
                <mat-select formControlName="regUser" [(ngModel)]="filter.regUser">
                  <mat-option [value]="null"> --- </mat-option>
                  <mat-option *ngFor="let item of users" [value]="item.username">
                    {{ item.fullName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput formControlName="equipmentMaker" [(ngModel)]="filter.equipmentMaker"
                  placeholder="{{ 'isothermalCharacterizationList.filter.equipmentMaker.label' | translate }}">
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput formControlName="equipmentModel" [(ngModel)]="filter.equipmentModel"
                  placeholder="{{ 'isothermalCharacterizationList.filter.equipmentModel.label' | translate }}">
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="datepicker">
                <input matInput [matDatepicker]="regFcStartPicker" formControlName="regFcStart"
                  [ngModel]="filter.regFcStart" (blur)="saveDate('regFcStart', $event)"
                  (dateChange)="saveDate('regFcStart', $event)"
                  placeholder="{{ 'isothermalCharacterizationList.filter.regFcStart.label' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="regFcStartPicker"></mat-datepicker-toggle>
                <mat-datepicker #regFcStartPicker [calendarHeaderComponent]="customDatepickerHeader">
                </mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="datepicker">
                <input matInput [matDatepicker]="regFcEndPicker" formControlName="regFcEnd" [ngModel]="filter.regFcEnd"
                  (blur)="saveDate('regFcEnd', $event)" (dateChange)="saveDate('regFcEnd', $event)"
                  placeholder="{{ 'isothermalCharacterizationList.filter.regFcEnd.label' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="regFcEndPicker"></mat-datepicker-toggle>
                <mat-datepicker #regFcEndPicker [calendarHeaderComponent]="customDatepickerHeader"></mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="datepicker">
                <input matInput [matDatepicker]="modFcStartPicker" formControlName="modFcStart"
                  [ngModel]="filter.modFcStart" (blur)="saveDate('modFcStart', $event)"
                  (dateChange)="saveDate('modFcStart', $event)"
                  placeholder="{{ 'isothermalCharacterizationList.filter.modFcStart.label' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="modFcStartPicker"></mat-datepicker-toggle>
                <mat-datepicker #modFcStartPicker [calendarHeaderComponent]="customDatepickerHeader">
                </mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="datepicker">
                <input matInput [matDatepicker]="modFcEndPicker" formControlName="modFcEnd" [ngModel]="filter.modFcEnd"
                  (blur)="saveDate('modFcEnd', $event)" (dateChange)="saveDate('modFcEnd', $event)"
                  placeholder="{{ 'isothermalCharacterizationList.filter.modFcEnd.label' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="modFcEndPicker"></mat-datepicker-toggle>
                <mat-datepicker #modFcEndPicker [calendarHeaderComponent]="customDatepickerHeader"></mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
              <mat-form-field class="datepicker">
                <input matInput [matDatepicker]="workEndFcStartPicker" formControlName="workEndFcStart"
                  [ngModel]="filter.workEndFcStart" (blur)="saveDate('workEndFcStart', $event)"
                  (dateChange)="saveDate('workEndFcStart', $event)"
                  placeholder="{{ 'isothermalCharacterizationList.filter.workEndFcStart.label' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="workEndFcStartPicker"></mat-datepicker-toggle>
                <mat-datepicker #workEndFcStartPicker [calendarHeaderComponent]="customDatepickerHeader">
                </mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
              <mat-form-field class="datepicker">
                <input matInput [matDatepicker]="workEndFcEndPicker" formControlName="workEndFcEnd"
                  [ngModel]="filter.workEndFcEnd" (blur)="saveDate('workEndFcEnd', $event)"
                  (dateChange)="saveDate('workEndFcEnd', $event)"
                  placeholder="{{ 'isothermalCharacterizationList.filter.workEndFcEnd.label' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="workEndFcEndPicker"></mat-datepicker-toggle>
                <mat-datepicker #workEndFcEndPicker [calendarHeaderComponent]="customDatepickerHeader">
                </mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
              <mat-form-field class="datepicker">
                <input matInput [matDatepicker]="realizationFcStartPicker" formControlName="realizationFcStart"
                  [ngModel]="filter.realizationFcStart" (blur)="saveDate('realizationFcStart', $event)"
                  (dateChange)="saveDate('realizationFcStart', $event)"
                  placeholder="{{ 'isothermalCharacterizationList.filter.realizationFcStart.label' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="realizationFcStartPicker"></mat-datepicker-toggle>
                <mat-datepicker #realizationFcStartPicker [calendarHeaderComponent]="customDatepickerHeader">
                </mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
              <mat-form-field class="datepicker">
                <input matInput [matDatepicker]="realizationFcEndPicker" formControlName="realizationFcEnd"
                  [ngModel]="filter.realizationFcEnd" (blur)="saveDate('realizationFcEnd', $event)"
                  (dateChange)="saveDate('realizationFcEnd', $event)"
                  placeholder="{{ 'isothermalCharacterizationList.filter.realizationFcEnd.label' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="realizationFcEndPicker"></mat-datepicker-toggle>
                <mat-datepicker #realizationFcEndPicker [calendarHeaderComponent]="customDatepickerHeader">
                </mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput formControlName="clientDocumentCode" [(ngModel)]="filter.clientDocumentCode"
                  placeholder="{{ 'isothermalCharacterizationList.filter.clientDocumentCode.label' | translate }}">
              </mat-form-field>
            </div>


            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput formControlName="projectNo" [(ngModel)]="filter.projectNo"
                  placeholder="{{ 'isothermalCharacterizationList.filter.projectNo.label' | translate }}">
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput formControlName="equipmentIdInternal" [(ngModel)]="filter.equipmentIdInternal"
                  placeholder="{{ 'isothermalCharacterizationList.filter.equipmentInternalId.label' | translate }}">
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
              <mat-form-field class="campoFormulario">
                <input matInput formControlName="department" [(ngModel)]="filter.department"
                  placeholder="{{ 'isothermalCharacterizationList.filter.department.label' | translate }}">
              </mat-form-field>
            </div>

          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <button mat-raised-button (click)="doFilter()" color="primary">{{ 'button.filter' | translate }}</button>
        <button mat-button (click)="cleanFilter()" color="secondary">{{ 'button.clean' | translate }}</button>
      </div>

    </mat-expansion-panel>
  </mat-accordion>
</form>