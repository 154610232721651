<div mat-dialog-content class="thermal-theme">

  <div fxLayout="column" class="flexwrap" *ngIf="criteriaSelected != null">
    <div fxFlex>
      <div fxLayout="row">
        <div fxFlex>
          <mat-form-field class="campoFormulario">
            <mat-label>{{ 'protocolEdit.dialog.essay.criteria.cols.criteria' | translate }}</mat-label>
            <mat-select required [ngModel]="data.criteria.idType" (selectionChange)="onCriteriaChange($event)">
              <mat-option *ngFor="let item of criterias" [value]="item.id">
                {{ 'protocolEdit.dialog.essay.criteria.' + item.translation + '.label' | translate }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{ 'protocolEdit.dialog.essay.criteria.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex [hidden]="criteriaSelected.variablesToShow >= 1"></div>
        <div fxFlex [hidden]="criteriaSelected.variablesToShow < 1">
          <mat-form-field class="campoFormulario">
            <input matInput [name]="criteriaValue1" [(ngModel)]="data.criteria.criteriaValue1" required
              placeholder="{{ 'protocolEdit.dialog.essay.criteria.criteriaValue1.label' | translate }}"
              [errorStateMatcher]="matcher" type="number" [disabled]="data.disabled">
            <mat-error>
              {{ 'protocolEdit.dialog.essay.criteria.criteriaValue1.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex [hidden]="criteriaSelected.variablesToShow >= 2"></div>
        <div fxFlex [hidden]="criteriaSelected.variablesToShow < 2">
          <mat-form-field class="campoFormulario">
            <input matInput [name]="criteriaValue2" [(ngModel)]="data.criteria.criteriaValue2" required
              placeholder="{{ 'protocolEdit.dialog.essay.criteria.criteriaValue2.label' | translate }}"
              [errorStateMatcher]="matcher" type="number" [disabled]="data.disabled">
            <mat-error>
              {{ 'protocolEdit.dialog.essay.criteria.criteriaValue2.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div fxFlex *ngIf="!isNew && data.criteria.fromProtocol">
      <div fxLayout="row">
        <div fxFlex>
          <mat-form-field class="campoFormulario">
            <mat-label>{{ 'protocolEdit.dialog.essay.criteria.cols.criteria' | translate }} {{ 'common.inProtocol' |
              translate }}</mat-label>
            <mat-select required [ngModel]="data.criteria.idTypeProtocol" disabled>
              <mat-option *ngFor="let item of criterias" [value]="item.id">
                {{ 'protocolEdit.dialog.essay.criteria.' + item.translation + '.label' | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex [hidden]="criteriaSelectedProtocol.variablesToShow >= 1"></div>
        <div fxFlex [hidden]="criteriaSelectedProtocol.variablesToShow < 1">
          <mat-form-field class="campoFormulario">
            <input matInput [name]="criteriaValue1" [(ngModel)]="data.criteria.criteriaValueProtocol1" required
              placeholder="{{ 'protocolEdit.dialog.essay.criteria.criteriaValue1.label' | translate }} {{ 'common.inProtocol' | translate }}"
              [errorStateMatcher]="matcher" type="number" disabled>
          </mat-form-field>
        </div>

        <div fxFlex [hidden]="criteriaSelectedProtocol.variablesToShow >= 2"></div>
        <div fxFlex [hidden]="criteriaSelectedProtocol.variablesToShow < 2">
          <mat-form-field class="campoFormulario">
            <input matInput [name]="criteriaValue2" [(ngModel)]="data.criteria.criteriaValueProtocol2" required
              placeholder="{{ 'protocolEdit.dialog.essay.criteria.criteriaValue2.label' | translate }} {{ 'common.inProtocol' | translate }}"
              [errorStateMatcher]="matcher" type="number" disabled>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div fxFlex="100"
      *ngIf="!isNew && data.criteria.fromProtocol && showReason()">
      <textarea rows="5" matAutosizeMinRows="5" matInput placeholder="{{ 'common.reasonChange' | translate }}"
        [(ngModel)]="data.criteria.criteriaValueChangeReason" name="criteriaValueChangeReason" required
        style="resize: none;"></textarea>
    </div>
    <div fxFlex="100" *ngIf="!isNew && data.criteria.fromProtocol">
      <mat-checkbox name="showDiffInDeviations" [(ngModel)]="data.criteria.showDiffInDeviations">
        {{ 'protocolEdit.dialog.essay.criteria.showDiffInDeviations.label' | translate }}
      </mat-checkbox>
    </div>
    <div fxFlex="100" *ngIf="!isNew && data.criteria.fromProtocol">
      <mat-checkbox name="showDiffInObservations" [(ngModel)]="data.criteria.showDiffInObservations">
        {{ 'protocolEdit.dialog.essay.criteria.showDiffInObservations.label' | translate }}
      </mat-checkbox>
    </div>
  </div>

  <div *ngIf="data.showMkt">
    <mat-checkbox name="showMkt" [(ngModel)]="data.criteria.showMkt">
      {{'protocolEdit.dialog.essay.criteria.showMkt.label' | translate }}
    </mat-checkbox>
  </div>

</div>

<div mat-dialog-actions align="end" class="thermal-theme">
  <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
  <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
</div>