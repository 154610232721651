<div class="main-container">
  <form #manageUsersForm="ngForm">

    <div class="toolbar-list">

      <div class="toolbar-list-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <h1>{{ 'manageUsers.title' | translate }}</h1>
        </div>

        <div class="breadcrumbs">
          <a href="#">{{ 'common.start.label' | translate }}</a> / <span>{{ 'manageUsers.title' | translate }}</span>
        </div>
      </div>

      <div class="toolbar-list-actions">
        <button mat-raised-button (click)="new()">
          <mat-icon fxHide.lt-sm>add</mat-icon> {{ 'button.new' | translate }}</button>
      </div>

      <div class="toolbar-back-button">
        <a (click)="cancel()"  mat-button><mat-icon>undo</mat-icon> {{ 'button.back' | translate }}</a>
      </div>
    </div>

    <app-manage-users-filter (filterEmitter)="doFilter($event)"  class="filter-container">
    </app-manage-users-filter>

    <div class="table-container">
      <table #users mat-table matSort [dataSource]="dataSource">

        <!-- Username Column -->
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'manageUsers.cols.username' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{item.username}} </td>
        </ng-container>

        <!-- email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'manageUsers.cols.email' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{item.email}}</td>
        </ng-container>

        <!-- fullName Column -->
        <ng-container matColumnDef="fullName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'manageUsers.cols.fullName' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{item.fullName}} </td>
        </ng-container>

        <!-- Button edit Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef colspan="2" fxHide.lt-lg>
            {{ 'manageUsers.cols.actions' | translate }}  </th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button *ngIf="canEdit(element)" (click)="editRow(element.username)" matTooltip="{{ 'button.edit' | translate }}">
              <mat-icon>mode_edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button activate Column -->
        <ng-container matColumnDef="activate">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button *ngIf="!element.active && canEdit(element)" (click)="activate(element.username)" matTooltip="{{ 'clientList.cols.activate' | translate }}">
              <mat-icon class="deactivate">clear</mat-icon>
            </button>
            <button mat-icon-button *ngIf="element.active && canEdit(element)" (click)="deactivate(element.username)" matTooltip="{{ 'clientList.cols.deactivate' | translate }}">
              <mat-icon class="activate">done</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button more Column -->
        <ng-container matColumnDef="more" stickyEnd>
          <th mat-header-cell *matHeaderCellDef fxHide fxShow.lt-lg></th>
          <td mat-cell *matCellDef="let element" fxHide fxShow.lt-lg class="action">

            <mat-menu #appMenu="matMenu" class="moreActions">
              <ng-template matMenuContent let-name="element">
                <button mat-button *ngIf="canEdit(element)" (click)="editRow(element.username)">
                  <mat-icon>mode_edit</mat-icon> {{ 'button.edit' | translate }}
                </button>
                <button mat-button *ngIf="!element.active && canEdit(element)" (click)="activate(element.username)">
                  <mat-icon class="deactivate">clear</mat-icon> {{ 'manageUsers.cols.activate' | translate }}
                </button>
                <button mat-button *ngIf="element.active && canEdit(element)" (click)="deactivate(element.username)">
                  <mat-icon class="activate">done</mat-icon> {{ 'manageUsers.cols.deactivate' | translate }}
                </button>
              </ng-template>
            </mat-menu>

            <button mat-icon-button [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="element">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </div>

    <div class="table-footer-container">
      <div class="actions">
        <a [routerLink]="['.']" [fragment]="'table-container'" class="back-to-top">
          {{ 'button.backToTop' | translate }} </a>
      </div>
      <div class="paginator">
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons [length]="dataSource.totalItems$ | async"
                       lang="es">
        </mat-paginator>
      </div>
    </div>
  </form>
</div>
