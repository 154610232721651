/* eslint-disable max-len */
import * as _ from 'lodash-es';

import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { CriticalInstrumentationCommon, CriticalInstrumentationExecution } from 'src/app/model/criticalInstrumentation';
import { Execution, ExecutionStatus } from 'src/app/model/execution';
import { FieldEnum, FieldUtils } from 'src/app/utils/fieldUtils';
import { GenericClass, GenericWarnError } from 'src/app/model/genericClass';
import { Protocol, ProtocolAssignable, ProtocolFilter } from 'src/app/model/protocol';

import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { AttachmentThermalService } from 'src/app/services/attachmentThermal.service';
import { CheckWarnsErrorsComponent } from '../../shared/check-warns-errors/check-warns-errors.component';
import { Client } from 'src/app/model/client';
import { ClientService } from 'src/app/services/client.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { Constants } from 'src/app/utils/constants';
import { DialogDataConfirmSave } from '../../calibrations/calibration-edit/calibration-edit-dialog-confirmSave.component';
import { EditDocumentCodeEditComponent } from '../../shared/edit-document-code-edit/edit-document-code-edit.component';
import { EditReferenceDocsComponent } from '../../shared/edit-reference-docs/edit-reference-docs.component';
import { EquipmentExecution } from 'src/app/model/equipment';
import { EquipmentType } from 'src/app/model/equipmentType';
import { EquipmentTypeService } from 'src/app/services/equipmentType.service';
import { Essay } from 'src/app/model/essay';
import { EssayProtocol } from 'src/app/model/essayProtocol';
import { EssayUtils } from 'src/app/utils/essayUtils';
import { ExecutionEditAttachmentsComponent } from './execution-edit-attachments.component';
import { ExecutionEditAuditComponent } from './execution-edit-audit.component';
import { ExecutionEditConfirmSaveComponent } from './execution-edit-dialog-confirmSave.component';
import { ExecutionEditCriticalInstrumentationEditComponent } from './execution-edit-critical-instrumentation-edit.component';
import { ExecutionEditDialogAlreadyExistsExecutionComponent } from './execution-edit-dialog-already-exists-execution.component';
import { ExecutionEditDialogAutomaticSignComponent } from './execution-edit-dialog-automatic-sign.component';
import { ExecutionEditEquipmentEditComponent } from './execution-edit-equipment-edit.component';
import { ExecutionEditEssayComponent } from './execution-edit-essay.component';
import { ExecutionEditGenerateReportComponent } from './execution-edit-generate-report.component';
import { ExecutionEditValidateExecutionAutomaticSignComponent } from './execution-edit-validate-execution.component-automatic-sign';
import { ExecutionEditValidateExecutionComponent } from './execution-edit-validate-execution.component';
import { ExecutionEditValidatorEditComponent } from './execution-edit-validator-edit.component';
import { ExecutionReportFilter } from 'src/app/model/attachment';
import { ExecutionService } from 'src/app/services/execution.service';
import { ExecutionUtils } from 'src/app/utils/executionUtils';
import { InstrumentationService } from 'src/app/services/instrumentation.service';
import { InstrumentationUse } from 'src/app/model/instrumentationUse';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatTable } from '@angular/material/table';
import { OnlineService } from 'src/app/services/online.service';
import { Phase } from 'src/app/model/phase';
import { PhaseService } from 'src/app/services/phase.service';
import { ProcessService } from 'src/app/services/process.service';
import { ProtocolEditEssayDialogComponent } from '../protocol-edit/protocol-edit-dialog-essay.component';
import { ProtocolListComponent } from '../protocol-list/protocol-list.component';
import { ProtocolService } from 'src/app/services/protocol.service';
import { ReasonDialogComponent } from '../../shared/reason-dialog/reason-dialog.component';
import { ReferenceDocument } from 'src/app/model/referenceDocument';
import { Sensor } from 'src/app/model/sensor';
import { SensorUtils } from 'src/app/utils/sensorUtils';
import { SignInfo } from 'src/app/model/sign';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { UsersService } from 'src/app/services/users.service';
import { ValidatorSpecifics } from 'src/app/model/validatorSpecific';
import { saveAs } from 'file-saver';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-execution-edit',
  templateUrl: './execution-edit.component.html',
  styleUrls: ['./execution-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExecutionEditComponent implements OnInit, OnDestroy {

  @ViewChildren('essaysComponent') essaysComponent: ExecutionEditEssayComponent;

  @ViewChildren('attachmentsComponent') attachmentsComponent: ExecutionEditAttachmentsComponent;

  @ViewChildren('criticalInstrTable') criticalInstrTable: MatTable<any>;
  @ViewChildren('equipmentTable') equipmentTable: QueryList<MatTable<any>>;

  @ViewChild(ExecutionEditAuditComponent) audit: ExecutionEditAuditComponent;
  @ViewChild(ExecutionEditEssayComponent, { static: true }) essayComponent: ExecutionEditEssayComponent;
  @ViewChild('refDocTable', { static: true }) refDocTable: MatTable<any>;

  @ViewChild('protocolAutocomplete') protocolAutocompleteInput: ElementRef;

  currentUser: User;

  execution: Execution;
  protocol: Protocol = new Protocol();

  useExistingProtocol: boolean;

  pendingSigns: SignInfo[];

  protocolFilter: ProtocolFilter = new ProtocolFilter();
  protocols: Protocol[];
  protocolsFiltered: Protocol[];

  clients: Client[];
  clientsFiltered: Client[];
  instrumentationUse: InstrumentationUse[] = [];
  processes: GenericClass[];

  phases: Phase[];
  respField: User[];

  equipmentCols = ['type', 'equipment', 'maker', 'model', 'serialNum', 'internalId', 'location'];
  equipmentColsEx = [...this.equipmentCols, 'edit'];
  criticalInstrColsProt = ['equipment', 'internalId', 'procediment'];

  equipmentTypes: EquipmentType[];

  essaysCols = ['essayType', 'variables', 'criteria', 'load', 'edit'];

  disableOfflineButton = false;
  showValidatorSpecifics = false;

  displayedColsRefDoc = ['code', 'name', 'edit', 'delete'];

  private destroy$ = new Subject<void>();
  private hasLoaded$ = new Subject<void>();

  private prevProtocolAutocomplete: Subscription;
  private loadingAutocompleteSubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public loadingAutocomplete$ = this.loadingAutocompleteSubject.asObservable();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private executionService: ExecutionService,
    private protocolService: ProtocolService,
    private clientService: ClientService,
    private instrumentationService: InstrumentationService,
    private processService: ProcessService,
    private onlineService: OnlineService,
    private userService: UserService,
    private attachmentThermalService: AttachmentThermalService,
    private equipmentTypeService: EquipmentTypeService,
    private phaseService: PhaseService,
    private usersService: UsersService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    this.spinnerService.show();

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const id = params[Constants.FIELD_ID] as number;
      const essayIndexParam = +params[Constants.FIELD_ESSAY_TAB];
      const variableIndexParam = +params[Constants.FIELD_VARIABLE_TAB];
      const idProtocolParam = +params[Constants.FIELD_ID_PROTOCOL];

      if (id == null) {
        this.cancel();
      }

      this.protocols = [];
      this.clients = [];

      this.execution = new Execution();
      if (+id === 0) {
        this.spinnerService.hide();

        this.clearProtocolFilter();
        this.protocolService.findAllAssignable(this.protocolFilter).pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data && data.content) {
            this.protocols = data.content as Protocol[];
          } else if (data) {
            this.protocols = data as Protocol[];
          } else {
            this.protocols = [];
          }
          this.protocolsFiltered = this.protocols.slice();
        });

        this.useExistingProtocol = true;
        if (idProtocolParam != null) {
          this.onProtocolChange(idProtocolParam);
        }

        this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Client[]) => this.loadClients(data));

      } else {
        this.executionService.findOneShort(id).pipe(takeUntil(this.hasLoaded$)).subscribe((result: Execution) => {
          if (result != null) {
            this.execution = result;
            this.execution.referenceDocs?.sort((eq1, eq2) => {
              let order = eq1.id - eq2.id;

              if (order === 0) {
                order = eq1.id - eq2.id;
              }

              return order;
            });
            this.useExistingProtocol = this.execution.idProtocol != null;

            if (this.useExistingProtocol) {
              this.protocol = new Protocol();
            }

            this.executionService.signInfo(this.execution).subscribe((res: SignInfo[]) => this.pendingSigns = res);

            const promises = [];

            const instr = this.instrumentationUse.find(i => i.id === this.execution.idInstrumentation);
            if (instr != null) {
              this.configureInstrumentation(instr);
            } else if (this.execution.idInstrumentation != null) {
              this.instrumentationService.findOne(this.execution.idInstrumentation).pipe(takeUntil(this.hasLoaded$))
                .subscribe((ins: InstrumentationUse) => this.configureInstrumentation(ins));
            }

            this.execution.essays.forEach((essay, essayIndex) => {

              essay.essayValues.forEach((essayValue, essayValueIndex) => {

                const promise = new Promise<void>((resolve) => {
                  this.spinnerService.show();
                  this.executionService.findOneEssay(essayValue.id).pipe(takeUntil(this.hasLoaded$)).subscribe((eF: EssayProtocol) => {

                    const fields = eF.fields.map(i => FieldUtils.objectToField(i)).sort((v1, v2) => v1.id - v2.id);
                    eF.fields = fields;

                    const sensors = eF.sensors.map(i => {
                      i.idVariable = eF.idVariable;

                      return SensorUtils.objectToSensor(i);
                    });
                    eF.sensors = sensors;

                    this.execution.essays[essayIndex].essayValues[essayValueIndex] = eF;

                    resolve();
                  });
                });

                promises.push(promise);
              });
            });

            void Promise.all(promises).then(() => {
              this.essayComponent.essays = this.execution.essays;

              this.updateAttachmentList();

              if (result.idProtocol) {
                this.protocolService.findOne(result.idProtocol).pipe(takeUntil(this.hasLoaded$)).subscribe((prot: Protocol) => {
                  this.protocol = prot;
                  this.spinnerService.hide();
                  this.clientService.findOne(result.idClient).pipe(takeUntil(this.destroy$)).subscribe((data: Client) => {
                    this.loadClients([data]);
                    this.hasLoaded$.next();
                  });
                });
              } else {
                this.spinnerService.hide();
                this.clientService.findOne(result.idClient).pipe(takeUntil(this.destroy$)).subscribe((data: Client) => {
                  this.loadClients([data]);
                  this.hasLoaded$.next();
                  this.spinnerService.hide();
                });
              }

              if (!isNaN(essayIndexParam) && !isNaN(variableIndexParam)) {
                const essay = this.execution.essays[essayIndexParam];

                if (essay != null) {
                  const variable = essay.essayValues[variableIndexParam];

                  if (variable != null) {
                    this.essayComponent._essayIndex = essayIndexParam;
                    this.essayComponent._variableIndex = variableIndexParam;

                    this.essayComponent.onVariableTabChange(variableIndexParam);
                  }
                }

              }

            });

          } else {
            this.execution = null;
          }
        }, () => {
          this.execution = null;

          this.spinnerService.hide();
        }, () => {
          if (this.execution == null) {
            this.cancel();
          }
        });
      }

    });

  }

  get criticalInstrCols(): string[] {
    const res = [...this.criticalInstrColsProt, 'maker', 'model', 'serialNum', 'certificateNum'];

    if (this.enableEditCriticalInstrumentation()) {
      res.push('edit', 'delete');
    }

    const validStatuses = [ExecutionStatus.EN_EJECUCION, ExecutionStatus.PENDIENTE_FIRMA];

    if (this.execution && validStatuses.includes(this.execution.idStatus)) {
      res.push('uploadCert');
    }

    return res;
  }

  ngOnInit(): void {
    this.onlineService.online$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.disableOfflineButton = !res;
    });

    this.disableOfflineButton = !this.onlineService.latestOnline;

    this.currentUser = this.userService.currentProfile;

    this.instrumentationUse = [];
    this.instrumentationService.findAllExecution().pipe(takeUntil(this.destroy$))
      .subscribe((data: InstrumentationUse[]) => this.instrumentationUse = data);

    this.processes = [];
    this.processService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.processes = data);

    this.equipmentTypes = [];
    this.equipmentTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: EquipmentType[]) => this.equipmentTypes = res);

    this.phases = [];
    this.phaseService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Phase[]) => {
      data = data.filter(d => d.enabled);
      this.phases = data;
    });

    this.respField = [];
    this.usersService.findAllRespFromGroup().pipe(takeUntil(this.destroy$)).subscribe((data: User[]) => this.respField = data);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.hasLoaded$.next();
    this.hasLoaded$.complete();
  }

  onUseExistingProtocolChange(event: MatRadioChange): void {
    this.useExistingProtocol = event.value as boolean;

    if (this.useExistingProtocol) {
      this.execution.idClient = null;
      this.execution.idProcess = null;
      this.execution.projectNo = null;
      this.execution.idPhase = null;
      this.execution.usernameProjectManager = null;
    } else {
      this.protocol = null;
      this.execution.idProtocol = null;
    }
  }

  configureInstrumentation(instr: InstrumentationUse): void {
    this.showValidatorSpecifics = instr?.hasValidatorSpecifics;

    if (this.execution.validator == null) {
      this.execution.validator = new ValidatorSpecifics();
      this.execution.validator.lowDesv = 1;
      this.execution.validator.highDesv = 1;
      this.execution.validator.verifDesv = 0.5;
      this.execution.validator.minStability = 0.2;
    }
  }

  loadClients(res: Client[]): void {
    this.clients = res;
    this.clientsFiltered = this.clients.slice();
  }

  reloadPage(idExecution = this.execution.id): void {
    if (idExecution == null) {
      idExecution = this.execution.id;
    }

    const essayIndex = this.essaysComponent._essayIndex || 0;
    const variableIndex = this.essaysComponent._variableIndex || 0;

    const url = `thermal/execution?id=${idExecution}&${Constants.FIELD_ESSAY_TAB}=${essayIndex}&${Constants.FIELD_VARIABLE_TAB}=${variableIndex}`;

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    void this.router.navigateByUrl(url);
  }

  reloadPageEmit(event: { idExecution: number }): void {
    this.reloadPage(event.idExecution);
  }

  essayActiveEmit(event): void {
    this.execution.essays.find(e => e.id === event.essayId).active = event.active;
  }

  essayActiveReasonEmit(event): void {
    this.execution.essays.find(e => e.id === event.essayId).reasonActive = event.reason;
  }

  showDownloadButton(): boolean {
    if (this.execution.idStatus == null) {
      return false;
    }

    const validStatuses = [ExecutionStatus.PENDIENTE_FIRMA, ExecutionStatus.FIRMADO];

    return validStatuses.includes(this.execution.idStatus);
  }

  enableEditEquipment(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return allowedStatuses.includes(this.execution.idStatus);
  }

  enableEditInstrumentationToUse(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return allowedStatuses.includes(this.execution.idStatus);
  }

  onInstrumentationChange(event: MatRadioChange): void {
    const instr = this.instrumentationUse.find(i => i.id === event.value);

    this.showValidatorSpecifics = instr.hasValidatorSpecifics;

    if (this.showValidatorSpecifics) {
      if (this.execution.validator == null) {
        const validator = new ValidatorSpecifics();
        validator.lowDesv = 1;
        validator.highDesv = 1;
        validator.verifDesv = 0.5;
        validator.minStability = 0.2;
        this.execution.validator = validator;
      }

    }
  }

  canEditValidator(item: InstrumentationUse): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return this.showValidator() && item.hasValidatorSpecifics && allowedStatuses.includes(this.execution.idStatus);
  }

  showValidator(): boolean {
    return this.showValidatorSpecifics;
  }

  editValidator(): void {
    const dialogRef = this.dialog.open(ExecutionEditValidatorEditComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        validator: _.clone(this.execution.validator)
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ValidatorSpecifics) => {
      if (result != null) {
        this.execution.validator = result;
      }
    });

  }

  enableEditCriticalInstrumentation(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];

    return allowedStatuses.includes(this.execution.idStatus);
  }

  showCriticalInstrumentation(): boolean {
    let res = false;

    const arrayToCheck = this.datasourceCriticalInstrumentation();

    res = arrayToCheck != null && arrayToCheck.length !== 0;

    return res;
  }

  datasourceCriticalInstrumentation(): CriticalInstrumentationCommon[] {
    let res: CriticalInstrumentationCommon[] = [];

    if (this.execution.id != null) {
      res = this.execution.criticalInstrumentations;
    } else if (this.protocol != null && this.protocol.id != null) {
      res = this.protocol.criticalInstrumentations;
    }

    return res;
  }

  openNewEquipment(): void {

    const dialogRef = this.dialog.open(ExecutionEditEquipmentEditComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        equipment: new EquipmentExecution()
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EquipmentExecution) => {
      if (result != null) {
        this.execution.equipments.push(result);
        this.equipmentTable.forEach(i => i.renderRows());
      }
    });
  }

  openEditEquipment(equipmentIndex: number): void {
    const eq = this.execution.equipments[equipmentIndex];

    const dialogRef = this.dialog.open(ExecutionEditEquipmentEditComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        equipment: _.cloneDeep(eq)
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EquipmentExecution) => {

      if (result != null) {
        this.execution.equipments[equipmentIndex] = result;
        this.equipmentTable.forEach(i => i.renderRows());
      }
    });
  }

  getEquipmentType(eq: EquipmentExecution): string {
    const eqType = this.equipmentTypes.find(e => e.id === eq.idType);

    return eqType?.translation;
  }

  openNewCriticalInstrumentation(): void {
    const dialogRef = this.dialog.open(ExecutionEditCriticalInstrumentationEditComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        criticalInstr: new CriticalInstrumentationExecution()
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CriticalInstrumentationExecution) => {

      if (result != null) {
        this.execution.criticalInstrumentations.push(result);
        this.renderCriticalInstrTable();
      }
    });
  }

  openEditCriticalInstrumentation(criticalInstrIndex: number): void {
    const crit = this.execution.criticalInstrumentations[criticalInstrIndex];

    const dialogRef = this.dialog.open(ExecutionEditCriticalInstrumentationEditComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        criticalInstr: _.cloneDeep(crit)
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: CriticalInstrumentationExecution) => {

      if (result != null) {
        this.execution.criticalInstrumentations[criticalInstrIndex] = result;
        this.renderCriticalInstrTable();
      }
    });
  }

  removeCriticalInstrumentation(criticalInstrIndex: number): void {
    this.execution.criticalInstrumentations.splice(criticalInstrIndex, 1);
    this.renderCriticalInstrTable();
  }

  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 1) {
      this.audit.getAudit();
    }
  }

  displayProtocol(prot: ProtocolAssignable): string {
    if (!prot) {
      return '';
    }

    const documentCode = prot?.documentCode != null ? prot.documentCode : '';
    const client = prot?.nameClient != null ? prot.nameClient : '';
    const equipment = prot?.equipment != null ? prot.equipment : '';
    const serialNum = prot?.serialNumber != null ? prot.serialNumber : '';
    const internalId = prot?.internalId != null ? prot.internalId : '';

    return documentCode.concat(' ').concat(client).concat(' ').concat(equipment).concat(' ').concat(serialNum).concat(' ')
      .concat(internalId);
  }

  lookupProtocol($event): void {
    if (this.prevProtocolAutocomplete) {
      this.prevProtocolAutocomplete.unsubscribe();
    }

    this.loadingAutocompleteSubject.next(true);

    const filter = new ProtocolFilter();

    filter.autocomplete = $event.target.value as string;
    filter.pageIndex = 0;
    filter.pageSize = 10;
    filter.sortBy = 'name';
    filter.sortDirection = 'asc';

    this.prevProtocolAutocomplete = this.protocolService.findAllAssignable(filter).pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data && data.content) {
        this.protocolsFiltered = data.content;
      } else if (data) {
        this.protocolsFiltered = data;
      } else {
        this.protocolsFiltered = [];
      }

      this.loadingAutocompleteSubject.next(false);
    });
  }

  onProtocolChange(evento: MatAutocompleteSelectedEvent | number): void {
    this.spinnerService.show();

    let idProtocol: number = null;

    if (evento instanceof MatAutocompleteSelectedEvent) {
      idProtocol = (evento.option.value as ProtocolAssignable)?.id;
    } else {
      idProtocol = evento;
    }

    if (idProtocol == null || isNaN(idProtocol)) {
      this.spinnerService.hide();

      return;
    }

    this.execution.idProtocol = idProtocol;

    this.protocolService.findOne(idProtocol).pipe(takeUntil(this.destroy$)).subscribe((prot: Protocol) => {

      this.executionService.findCurrentByIdProtocol(prot.id).subscribe((possibleEx: Execution) => {
        this.spinnerService.hide();
        event.stopPropagation();

        if (possibleEx == null || possibleEx.id == null) {
          this.protocol = prot;

          this.protocolAutocompleteInput.nativeElement.value = prot.documentCode;
          this.renderCriticalInstrTable();
        } else {

          const dialogRef = this.dialog.open(ExecutionEditDialogAlreadyExistsExecutionComponent, {
            minWidth: '50%',
            maxHeight: '95vh',
            data: {
              execution: possibleEx
            }
          });

          dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: boolean) => {
            if (result === true) {
              this.protocol = prot;

              this.protocolAutocompleteInput.nativeElement.value = prot.documentCode;
              this.renderCriticalInstrTable();
            } else {
              this.protocolAutocompleteInput.nativeElement.value = '';
            }
          });
        }

      }, () => {
        this.spinnerService.hide();
      });

    });
  }

  openSearchProtocol(): void {
    event.stopPropagation();

    this.loadingAutocompleteSubject.next(true);

    const dialogRef = this.dialog.open(ProtocolListComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        isList: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: number) => {
      this.loadingAutocompleteSubject.next(false);
      if (result != null) {
        this.onProtocolChange(result);
      }
    });
  }

  openNewRefDoc(): void {
    const dialogRef = this.dialog.open(EditReferenceDocsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        refDoc: new ReferenceDocument(),
        isEdit: false,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ReferenceDocument) => {

      if (result != null) {
        this.execution.referenceDocs.push(result);

        this.refDocTable.renderRows();
      }
    });
  }

  editRefDoc(refDocIndex: number): void {
    const refDoc = this.execution.referenceDocs[refDocIndex];

    const dialogRef = this.dialog.open(EditReferenceDocsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        refDoc: _.cloneDeep(refDoc),
        isEdit: true,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ReferenceDocument) => {

      if (result != null) {
        this.execution.referenceDocs[refDocIndex] = result;

        this.refDocTable.renderRows();
      }
    });
  }

  removeRefDoc(refDocIndex: number): void {
    this.execution.referenceDocs.splice(refDocIndex, 1);

    this.refDocTable.renderRows();
  }

  saveExecution(): void {
    if (this.essaysComponent instanceof QueryList) {
      this.essaysComponent = this.essaysComponent.first as ExecutionEditEssayComponent;
    }

    const errors: string[] = [];
    const isCreation = this.execution.id == null;

    if (!this.useExistingProtocol) {
      if (isCreation) {
        if (this.execution.idClient == null) {
          errors.push(this.translate.instant('executionEdit.protocol.client.error') as string);
        }
        if (this.execution.idProcess == null) {
          errors.push(this.translate.instant('executionEdit.protocol.process.error') as string);
        }
        if (this.execution.projectNo == null) {
          errors.push(this.translate.instant('executionEdit.protocol.projectNo.error') as string);
        }
        if (this.execution.idPhase == null) {
          errors.push(this.translate.instant('executionEdit.protocol.phase.error') as string);
        }
      } else {
        if (ArrayUtils.isEmpty(this.execution.equipments)) {
          errors.push(this.translate.instant('protocolEdit.form.error.anyEquipment') as string);
        }
        if (ArrayUtils.isEmpty(this.execution.essays)) {
          errors.push(this.translate.instant('protocolEdit.form.error.anyEssay') as string);
        }
      }
    }

    if (errors.length !== 0) {
      this.snackBarService.sendError(errors.join('\n'));
      this.spinnerService.hide();

      return;
    }

    if (isCreation) {

      const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
        minWidth: '20%',
        maxHeight: '95vh',
        data: {}
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {

        if (token != null) {
          this.spinnerService.show();

          this.executionService.create(this.execution, token).pipe(takeUntil(this.destroy$)).subscribe((item: Execution) => {
            this.snackBarService.sendSuccess(this.translate.instant('executionEdit.form.create.ok') as string);

            void this.router.navigateByUrl(`thermal/execution?id=${item.id}`);

            this.spinnerService.hide();
          }, err => {
            let message = err.error as string;
            if (message == null) {
              message = this.translate.instant('executionEdit.form.create.error.generic') as string;
            }
            this.snackBarService.sendError(message);
            this.spinnerService.hide();
          });

        } else {
          this.spinnerService.hide();
        }

      });

    } else if (this.execution.idStatus === ExecutionStatus.FIRMADO || (+this.execution.currentVersion) > 1) {

      const dialogRef = this.dialog.open(ExecutionEditConfirmSaveComponent, {
        minWidth: '20%',
        maxHeight: '95vh',
        data: {
          reason: this.execution.reason,
          showReason: true
        }
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: DialogDataConfirmSave) => {

        if (result != null) {

          const reason = result.reason;

          const dialogRefConfirmPassword = this.dialog.open(ActionConfirmPasswordComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {}
          });

          dialogRefConfirmPassword.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(resultConfirm => {

            this.execution.reason = reason;

            if (resultConfirm != null) {
              this.spinnerService.show();

              this.executionService.save(this.execution).pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.snackBarService.sendSuccess(this.translate.instant('executionEdit.form.save.ok') as string);

                this.spinnerService.hide();

                this.reloadPage(this.execution.id);
              }, err => {
                if (err != null && err.error != null && typeof err.error === 'string') {
                  this.snackBarService.sendError(err.error as string);
                } else {
                  this.snackBarService.sendError(this.translate.instant('executionEdit.form.save.error.generic') as string);
                }

                this.spinnerService.hide();
              });

            } else {
              this.spinnerService.hide();
            }
          });
        }

      });

    } else {

      const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
        minWidth: '20%',
        maxHeight: '95vh',
        data: {}
      });

      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {

        if (result != null) {

          if (!this.useExistingProtocol) {
            this.execution.idProtocol = null;
          }

          this.executionService.save(this.execution).pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.snackBarService.sendSuccess(this.translate.instant('executionEdit.form.save.ok') as string);

            this.spinnerService.hide();

            this.reloadPage(this.execution.id);
          }, err => {
            if (err != null && err.error != null && typeof err.error === 'string') {
              this.snackBarService.sendError(err.error as string);
            } else {
              this.snackBarService.sendError(this.translate.instant('executionEdit.form.save.error.generic') as string);
            }

            this.spinnerService.hide();
          });

        } else {
          this.spinnerService.hide();
        }
      });

    }
  }

  showExecute(): boolean {
    if (this.execution.idStatus == null) {
      return false;
    }

    const validStatuses = [ExecutionStatus.EN_EJECUCION];

    return validStatuses.includes(this.execution.idStatus);
  }

  showAutomaticSign(): boolean {
    const current = this.currentUser;

    return this.canSign() && current.automaticSign;
  }

  showManualSign(): boolean {
    const current = this.currentUser;

    return this.canSign() && current.manualSign && ArrayUtils.isEmpty(this.pendingSigns);
  }

  execute(): void {
    this.saveExecutionCallback(() => {
      this.recalculateFcInit();
      this.showDialogValidate();
    });
  }

  manualSign(): void {
    this.saveExecutionCallback(() => {
      this.recalculateFcInit();
      this.showDialogValidate();
    });
  }

  automaticSign(): void {
    this.spinnerService.show();

    this.executionService.signInfo(this.execution).pipe(takeUntil(this.destroy$)).subscribe((res: SignInfo[]) => {
      this.spinnerService.hide();
      this.recalculateFcInit();

      if (res == null || res.length === 0) {
        this.saveExecutionCallback(() => {
          this.showDialogValidateAutomatic();
        });
      } else {
        this.showDialogSignAutomatic();
      }

    }, () => {
      this.spinnerService.hide();
      this.saveExecutionCallback(() => {
        this.showDialogValidateAutomatic();
      });
    }
    );

  }

  updateAttachmentList(): void {
    if (this.attachmentsComponent instanceof QueryList) {
      this.attachmentsComponent = this.attachmentsComponent.first as ExecutionEditAttachmentsComponent;
    }

    this.attachmentsComponent.setExecution(this.execution);
  }

  showBack(): boolean {
    if (this.execution.idStatus == null) {
      return false;
    }

    if (this.execution.idStatus == null) {
      return false;
    }

    const profile = this.userService.currentProfile;

    if (profile == null) {
      return false;
    }

    const validStatuses = [ExecutionStatus.PENDIENTE_FIRMA];

    return validStatuses.includes(this.execution.idStatus);
  }

  calculateBack(): void {

    if (this.execution.idStatus === ExecutionStatus.PENDIENTE_FIRMA) {
      this.invalidateByDoc();
    } else {
      this.snackBarService.sendError(this.translate.instant('executionEdit.form.status.error.notAllowed') as string);
    }

  }

  invalidateByDoc(): void {
    const errors = [];

    if (errors.length !== 0) {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);

      return;
    }

    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {

      if (token != null) {
        this.spinnerService.show();

        this.executionService.invalidateByDoc(this.execution, token).pipe(takeUntil(this.destroy$)).subscribe((item: Execution) => {
          const status = this.translate.instant(`executionStatus.${item.statusTranslation}`) as string;
          this.snackBarService.sendSuccess(this.translate.instant('executionEdit.form.status.back.ok', { status }) as string);

          this.reloadPage(this.execution.id);

          this.spinnerService.hide();

        }, () => {
          this.snackBarService.sendError(this.translate.instant('executionEdit.form.status.advance.error.generic') as string);

          this.spinnerService.hide();
        });

      } else {
        this.spinnerService.hide();
      }
    });

  }

  downloadProtocolPdf(): void {
    this.spinnerService.show();

    this.protocolService.downloadPdfVersion(this.execution.idProtocol, this.execution.originalProtocolVersion.toString())
      .pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        const name = this.protocol.documentCode + ' v.' + this.execution.originalProtocolVersion.toString().padStart(2, '0');

        saveAs(res, this.translate.instant('executionEdit.files.report', { name }) as string);
        this.spinnerService.hide();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.notFound') as string);
        this.spinnerService.hide();
      });
  }

  downloadPdf(): void {
    const dialogRef = this.dialog.open(ExecutionEditGenerateReportComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        execution: this.execution,
        protocol: this.protocol,
        reason: this.execution.reason
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionReportFilter) => {
      if (result != null) {
        this.execution.conclusions = result.conclusions;
      }
    });
  }

  cancel(): void {
    void this.router.navigateByUrl('thermal/executions');
  }

  onFcStartEssayEmit(event): void {
    if (event.essayIndex != null && event.variableIndex != null) {

      const init = _.cloneDeep(event.fcStartEssay) as Date;
      const end = _.cloneDeep(event.fcEndEssay) as Date;

      this.execution.essays[event.essayIndex].essayValues[event.variableIndex].fcStartEssay = init;
      this.execution.essays[event.essayIndex].essayValues[event.variableIndex].fcEndEssay = end;
    }
  }

  onIdPeakChange(event): void {
    if (event.essayIndex != null) {
      this.execution.essays[event.essayIndex].idPeakEventType = event.value;
    }
  }

  setSensor(data): void {
    const essayIndex = data.essayIndex as number;
    const variableIndex = data.variableIndex as number;
    const sensorIndex = data.sensorIndex as number;
    const sensor = data.sensor as Sensor;

    this.execution.essays[essayIndex].essayValues[variableIndex].sensors[sensorIndex] = sensor;
  }

  getVariablesFromEssay(essay: Essay): string {
    if (!essay || !essay.essayValues) {
      return null;
    }

    return essay.essayValues.map(i => this.translate.instant('variable.' + i.translationVariable) as string).join(', ');
  }

  getCriteriaFromEssay(essay: Essay): string {
    if (!essay || !essay.essayValues) {
      return null;
    }

    return essay.essayValues.map(i => EssayUtils.getCriteriasToShowThermal(i, this.translate)).filter(f => f != null).join('; ');
  }

  getLoadFromEssay(essay: Essay): string {
    if (!essay || !essay.essayValues) {
      return null;
    }

    const fields = [];
    essay.essayValues.forEach(i => i.fields.filter(field => field.idField === FieldEnum.LOAD).forEach(field => fields.push(field.value)));

    return fields.join(', ');
  }

  seeEssayRow(index: number): void {
    const essay = this.protocol.essays[index];

    this.dialog.open(ProtocolEditEssayDialogComponent, {
      minWidth: '90vw',
      height: '95vh',
      data: {
        essay,
        isEdit: true,
        idPhase: this.protocol.idPhase,
        disabled: true
      }
    });
  }

  openNewEssay(): void {
    const dialogRef = this.dialog.open(ProtocolEditEssayDialogComponent, {
      minWidth: '90vw',
      height: '95vh',
      data: {
        essay: new Essay(),
        isEdit: false,
        idPhase: this.execution.idPhase,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: Essay) => {
      if (result != null) {
        result.active = true;

        result.essayValues.forEach(essayVal => {
          essayVal.sensors.forEach(sensor => {
            sensor.evaluate = true;
          });
        });

        this.execution.essays.push(result);
      }
    });
  }

  disableUploadCriticalInstrumentationButton(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION, ExecutionStatus.PENDIENTE_FIRMA];

    return !allowedStatuses.includes(this.execution.idStatus);
  }

  styleUploadCriticalInstrumentationButton(item: CriticalInstrumentationExecution): string {
    return (!this.disableUploadCriticalInstrumentationButton() && item.idCertificate != null) ? 'blueIcon' : '';

  }

  uploadCertificateCriticalInstrumentation(crit: CriticalInstrumentationExecution, event): void {
    const file = event.target.files[0] as File;

    this.spinnerService.show();
    this.attachmentThermalService.uploadCertificateToCriticalInstrumentation(crit.id, file).pipe(takeUntil(this.destroy$))
      .subscribe((res: number) => {
        this.execution.criticalInstrumentations.filter(item => item.id === crit.id).forEach(item => item.idCertificate = res);

        this.spinnerService.hide();

        this.snackBarService.sendSuccess(this.translate.instant('executionEdit.form.uploadCertificate.ok') as string);
      }, () => {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('executionEdit.form.uploadCertificate.error') as string);
      });
  }

  canEditDocumentCode(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION, ExecutionStatus.PENDIENTE_FIRMA];

    return this.execution.id != null && allowedStatuses.includes(this.execution.idStatus);
  }

  editDocumentCode(): void {
    const dialogRef = this.dialog.open(EditDocumentCodeEditComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        documentCode: this.execution.documentCode
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {

      if (result != null) {
        this.execution.documentCode = result;
      }
    });
  }

  showRevertSign(): boolean {
    return this.execution.idStatus === ExecutionStatus.FIRMADO;
  }

  revertSign(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.translate.instant('calibrateEquipmentEdit.form.revertSign.confirm') as string
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {

      if (result === true) {
        this.requestReason((reason) => {
          const dialogRefConfirmation = this.dialog.open(ActionConfirmPasswordComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {}
          });
          dialogRefConfirmation.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
            this.executionService.revertSign(this.execution.id, reason, token).pipe(takeUntil(this.destroy$)).subscribe(() => {
              this.spinnerService.hide();
              this.reloadPage(this.execution.id);
            });
          }, err => {
            if (err != null && err.error != null && typeof err.error === 'string') {
              this.snackBarService.sendError(err.error as string);
            } else {
              this.snackBarService.sendError(this.translate.instant('protocolEdit.form.revertSign.error') as string);
            }
          });
        });
      }
    });
  }

  private renderCriticalInstrTable() {
    if (this.criticalInstrTable instanceof QueryList) {
      this.criticalInstrTable = this.criticalInstrTable.first as MatTable<any>;
    }

    this.criticalInstrTable.dataSource = this.datasourceCriticalInstrumentation();
    this.criticalInstrTable.renderRows();
  }

  private canSign(): boolean {
    if (this.execution.idStatus == null) {
      return false;
    }

    const profile = this.userService.currentProfile;

    if (profile == null) {
      return false;
    }

    const validStatuses = [ExecutionStatus.PENDIENTE_FIRMA];

    return validStatuses.includes(this.execution.idStatus);
  }

  private saveExecutionCallback(callback: () => void) {
    this.spinnerService.show();

    this.executionService.save(this.execution).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.spinnerService.hide();
      callback();
    }, err => {
      if (err != null && err.error != null && typeof err.error === 'string') {
        this.snackBarService.sendError(err.error as string);
      } else {
        this.snackBarService.sendError(this.translate.instant('executionEdit.form.status.advance.error.generic') as string);
      }
      this.spinnerService.hide();
    });
  }

  private recalculateFcInit() {
    if (this.essaysComponent instanceof QueryList) {
      this.essaysComponent = this.essaysComponent.first as ExecutionEditEssayComponent;
    }

    const copyGraphDateMap = this.essaysComponent.copyGraphDateMap;
    if (copyGraphDateMap) {
      copyGraphDateMap.forEach((value, key) => {
        if (value === true) {
          const essayIndex = key.essayIndex;
          const variableIndex = key.variableIndex;

          const essayValue = this.execution.essays[essayIndex].essayValues[variableIndex];

          essayValue.fcStartEssay = this.execution.essays[essayIndex].essayValues[0].fcStartEssay as Date;
          essayValue.fcEndEssay = this.execution.essays[essayIndex].essayValues[0].fcEndEssay as Date;
        }
      });
    }
  }

  private showDialogValidate() {
    const errors = ExecutionUtils.validateExecution(this.execution, this.translate);

    if (errors.length !== 0) {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);

      return;
    }

    const dialog = this.dialog.open(ExecutionEditValidateExecutionComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {
        execution: this.execution,
        protocol: this.protocol
      }
    });

    dialog.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data != null) {
        this.reloadPage(this.execution.id);
      }
    });
  }

  private showDialogValidateAutomatic() {
    const errors = ExecutionUtils.validateExecution(this.execution, this.translate);

    if (errors.length !== 0) {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);

      return;
    }

    const dialog = this.dialog.open(ExecutionEditValidateExecutionAutomaticSignComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {
        execution: this.execution,
        protocol: this.protocol
      }
    });

    dialog.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data != null) {
        this.clientService.findOne(this.execution.idClient).subscribe((client: Client) => {

          if (client.idGroup != null) {
            void this.cloneRow(this.execution.id, client.idGroup).then(() => this.reloadPage());
          } else {
            this.reloadPage();
          }

        });
      }
    });
  }

  private showDialogSignAutomatic() {
    const errors = ExecutionUtils.validateExecution(this.execution, this.translate);

    if (errors.length !== 0) {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);

      return;
    }

    const dialog = this.dialog.open(ExecutionEditDialogAutomaticSignComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {
        execution: this.execution,
        protocol: this.protocol
      }
    });

    dialog.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data != null) {
        this.clientService.findOne(this.execution.idClient).subscribe((client: Client) => {

          if (client.idGroup != null) {
            void this.cloneRow(this.execution.id, client.idGroup).then(() => this.reloadPage());
          } else {
            this.reloadPage();
          }

        });
      }
    });
  }

  private clearProtocolFilter() {
    this.protocolFilter = new ProtocolFilter();

    this.protocolFilter.pageSize = 10000;
  }

  private requestReason(callback: (reason: string) => void) {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

  private cloneRow(id: number, idGroup: number): Promise<void> {

    return new Promise<void>((resolve) => {

      const dialogRefConfirm = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('generic.clone.sign') as string
        }
      });

      dialogRefConfirm.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: boolean) => {
        if (result === true) {

          this.spinnerService.show();

          this.executionService.checkCopyToGroup(id, idGroup).subscribe((res: GenericWarnError) => {
            this.spinnerService.hide();

            const dialogRef = this.dialog.open(CheckWarnsErrorsComponent, {
              minWidth: '20%',
              maxHeight: '95vh',
              data: {
                warns: res.warns,
                errors: res.errors
              }
            });

            dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((canContinue: boolean) => {
              if (canContinue === true) {
                this.spinnerService.show();
                this.executionService.copyToGroup(id, idGroup).subscribe(() => {
                  this.spinnerService.hide();

                  this.snackBarService.sendSuccess(this.translate.instant('executionEdit.form.clone.ok') as string);
                  resolve();
                }, () => {
                  this.spinnerService.hide();
                  this.snackBarService.sendError(this.translate.instant('executionEdit.form.clone.error') as string);
                  resolve();
                });
              } else {
                resolve();
              }
            });
          }, () => {
            this.spinnerService.hide();
            this.snackBarService.sendError(this.translate.instant('executionEdit.form.clone.error') as string);
            resolve();
          });
        } else {
          resolve();
        }
      });

    });
  }

}
