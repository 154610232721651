import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Criteria } from 'src/app/model/criteria';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CriteriaType } from 'src/app/model/criteriaType';
import { CriteriaTypeService } from 'src/app/services/criteriaType.service';
import { MatSelectChange } from '@angular/material/select';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SnackBarService } from 'src/app/services/snackBar.service';

export interface DialogDataCriteriaEdit {
  criteriasRemove: number[];
  criteria: Criteria;
}

@Component({
  selector: 'app-isothermal-characterization-edit-criteria-edit',
  templateUrl: './isothermal-characterization-edit-criteria-edit.component.html'
})
export class IsothermalCharacterizationEditCriteriaEditComponent implements OnInit, OnDestroy {

  criterias: CriteriaType[];
  criteriaSelected: CriteriaType;

  private destroy$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<IsothermalCharacterizationEditCriteriaEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCriteriaEdit,
    private criteriaTypeService: CriteriaTypeService,
    private translate: TranslateService,
    public snackBarService: SnackBarService) {
  }

  ngOnInit(): void {

    this.criterias = [];
    this.criteriaTypeService.findAllCalibrates().pipe(takeUntil(this.destroy$)).subscribe((res: CriteriaType[]) => {
      res = res.filter(crit => !this.data.criteriasRemove.includes(crit.id));

      this.criterias = res;

      let idCriteriaType = null;
      try {
        idCriteriaType = this.data.criteria.idType;
      } catch (e) { }

      if (idCriteriaType) {
        this.criteriaSelected = this.criterias.find(c => c.id === idCriteriaType);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onTypeChange(event: MatSelectChange): void {
    const criteria = this.criterias.find(c => c.id === event.value);
    this.criteriaSelected = criteria;
  }

  onOkClick(): void {
    const errs = [];

    const criteria = this.criterias.find(c => c.id === this.data.criteria.idType);

    if (criteria == null) {
      errs.push(this.translate.instant('protocolEdit.dialog.essay.criteria.error'));
    } else {
      const variablesToShow = criteria.variablesToShow;
      const value1 = this.data.criteria.criteriaValue1 || -1;
      const value2 = this.data.criteria.criteriaValue2 || -1;

      if (variablesToShow >= 1 && value1 == null) {
        errs.push(this.translate.instant('protocolEdit.dialog.essay.criteria.criteriaValue1.error'));
      }
      if (variablesToShow >= 2 && value2 == null) {
        errs.push(this.translate.instant('protocolEdit.dialog.essay.criteria.criteriaValue2.error'));
      }
    }

    if (errs.length === 0) {
      this.data.criteria.criteriaTranslation = criteria.translation;
      this.dialogRef.close(this.data.criteria);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
