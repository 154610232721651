<br />

<h1 class="errorTitle">{{ 'errorPage.notFound.title' | translate }}</h1>

<div class="main-container">
    <div class="centerText">
        <img src="assets/img/404.gif" class="errorImage">
    </div>

    <br /><br />

    <h2 class="centerText">{{ 'errorPage.notFound.text1' | translate }}</h2>
    <h3 class="centerText">
        {{ 'errorPage.generic.returnToHome.prefix' | translate }}
        <a routerLink="/">{{ 'errorPage.generic.returnToHome.link' | translate }}</a>
        {{ 'errorPage.generic.returnToHome.suffix' | translate }}
    </h3>
</div>