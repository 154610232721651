import { CriteriaTypeEnum, SpeedTypeCriteria } from '../model/criteriaType';
import { EssayExecutionAirResultParticles, EssayExecutionResultAir } from '../model/essayExecutionResultAir';
import { FilterAir, FilterAirEnum, FilterAirTypeEnum } from '../model/filterAir';

import { ArrayUtils } from './arrayUtils';
import { Constants } from './constants';
import { Criteria } from '../model/criteria';
import { DateUtils } from './dateUtils';
import { EquipmentTypeAirEnum } from '../model/equipmentType';
import { EssayAirTypeEnum } from '../model/essayType';
import { EssayProtocolAir } from '../model/essayProtocolAir';
import { ExecutionAir } from '../model/execution';
import { FieldEnum } from './fieldUtils';
import { NumberUtils } from './numberUtils';
import { RoomAir } from '../model/roomAir';
import { StringUtils } from './stringUtils';
import { TranslateService } from '@ngx-translate/core';
import { Volume } from '../model/volume';

export class ExecutionAirUtils {

    public static validateExecution(execution: ExecutionAir, translate: TranslateService): string[] {
        const errors: string[] = [];
        let defPre = false;
        execution.areas.forEach(area => {
            area.equipments.forEach(equipment =>
                equipment.essays.filter(a => a.active).forEach(essay => {
                    if(essay.idEssayType === EssayAirTypeEnum.DEF_PRESSURE){
                        essay.criterias.forEach(crit => {
                            if(ArrayUtils.isEmpty(crit.values) && !defPre) {
                                errors.push(translate.instant('pressure.essay.empty.value.error') as string);
                                defPre = true;
                            }
                        })
                    }
                })
            );
            area.rooms.forEach(room =>
                room.essays.filter(a => a.active).forEach(essay => {
                    if(essay.idEssayType === EssayAirTypeEnum.DEF_PRESSURE){
                        essay.criterias.forEach(crit => {
                            if(ArrayUtils.isEmpty(crit.values) && !defPre) {
                                errors.push(translate.instant('pressure.essay.empty.value.error') as string);
                                defPre = true;
                            }
                        })
                    }
                })
            );
            area.compressedGases.forEach(gas =>
                gas.essays.filter(a => a.active).forEach(essay => {
                    if(essay.idEssayType === EssayAirTypeEnum.DEF_PRESSURE){
                        essay.criterias.forEach(crit => {
                            if(ArrayUtils.isEmpty(crit.values) && !defPre) {
                                errors.push(translate.instant('pressure.essay.empty.value.error') as string);
                                defPre = true;
                            }
                        })
                    }
                })
            );
        })

        return errors;
    }

    public static getNameForReportPdf(ex: ExecutionAir, translate: TranslateService): string {
        const name = this.getNameForReportGeneric(ex, translate);

        return translate.instant('executionEdit.files.report', { name }) as string;
    }

    static checkValuesCriteria(criteria: Criteria): boolean {
        let according = true;

        criteria.values.forEach(valor => {
            const arrayData = valor?.value?.toString().split(';');

            arrayData?.forEach(data => according = this.checkValueCriteriaGeneric(criteria, Number(data)) && according);

        });

        return according;
    }

    static checkValuesCriteriaUniformity(criteria: Criteria, uniformity: number): boolean {
        let according = true;
        let avgValue: number;

        criteria.values.forEach(valor => {
            if (valor.value != null) {
                const arrayData = valor?.value?.toString().split(';');

                avgValue = ArrayUtils.calcAvg(arrayData.map(value => Number(value)));

                arrayData?.forEach(data => according = this.checkValueCriteriaGeneric(criteria, Number(data)));

                arrayData?.forEach(data => {
                    const dataNumber = Number(data);
                    according = according && this.checkUniformity(dataNumber, uniformity, avgValue);
                });
            }
        });

        return according;
    }

    static checkValuesCriteriaFlow(essay: EssayProtocolAir, volume: number, filters: FilterAir[]): boolean {

        // Cogemos siempre el volumen del ensayo
        const fieldVolume = essay.essayValues.find(f => f.idField === FieldEnum.VOLUME);
        volume = +fieldVolume?.value;

        // Si no tiene volumen ni la entidad ni el ensayo, no calculamos nada
        if (volume == null || volume.toString() === '' || +volume === 0) {
            return null;
        }

        const hasFlow = essay.criterias.some(c => c.customValue1 === 'caudalMeassurement');
        const hasRenRate = essay.criterias.some(c => c.customValue1 === 'renovationPerHour');
        let averageVel: number;
        let filter = new FilterAir();
        let accordingRen = true;
        let accordingFlow = true;
        let renH = 0;
        let flow = 0;

        essay.criterias.forEach(criteria => {
            const idTypeCriteria = criteria.idType;
            const criteriaValue1 = criteria.criteriaValue1;
            const criteriaValue2 = criteria.criteriaValue2;

            criteria.values.forEach(valor => {
                const arrayData = valor?.value?.toString().split(';');

                if (valor.uuidFilter != null) {
                    if (ExecutionAirUtils.checkIdFilter(valor.uuidFilter)) {
                        filter = filters.find(f => f.id.toString() === valor.uuidFilter);
                    } else {
                        filter = filters.find(f => f.idOffline === valor.uuidFilter);
                    }
                } else {
                    filter = filters.find(f => f.id === valor.idFilter);
                }

                const filterSup = this.getFilterSurface(filter);

                if (arrayData != null) {
                    if (arrayData.length > 1) {
                        averageVel = ArrayUtils.calcAvg(arrayData.map(d => Number(d)));

                        if (averageVel == null) {
                            return 0;
                        }

                        flow = NumberUtils.round((averageVel * filterSup) * 3600, 2);
                    } else {
                        flow = Number(arrayData[0]);
                    }
                }

                renH += NumberUtils.round(flow / volume, 2);
            });

            if (hasFlow) {
                accordingFlow = this.checkValueCriteriaGeneric(criteria, flow);
            }

            if (hasRenRate) {
                accordingRen = this.checkValueCriteriaGeneric(criteria, renH);
            }
        });

        return accordingRen && accordingFlow;
    }

    static checkValuesCriteriaSpeed(essay: EssayProtocolAir, eqType: number, filters: FilterAir[]): boolean {

        let according = true;
        let applyUniformmity = false;
        let uniformity: number;
        let exSurface: number;
        let frontSurface: number;
        let isChanneled: boolean;

        essay.essayValues.forEach(field => {
            if (field.idField === FieldEnum.APPLY_UNIFORMITY && field.value === 'true') {
                applyUniformmity = true;
            } else if (field.idField === FieldEnum.UNIFORMITY_VALUE) {
                uniformity = +field.value;
            } else if (field.idField === FieldEnum.EXTRACTION_SURFACE) {
                exSurface = +field.value;
            } else if (field.idField === FieldEnum.FRONTAL_WINDOW_SURFACE) {
                frontSurface = +field.value;
            }
        });

        if (eqType === 0) {
            essay.criterias.forEach(criteria => {
                if (applyUniformmity) {
                    according = this.checkValuesCriteriaUniformity(criteria, uniformity) && according;
                } else {
                    according = this.checkValuesCriteria(criteria) && according;
                }
            });
        } else if (eqType === EquipmentTypeAirEnum.PROTECTION_HEAVY_CABIN) {
            essay.criterias.forEach(criteria => {
                criteria.values.forEach(value => {
                    // recupero el filtro
                    const filter = this.findFilterByValue(value, filters);

                    // comparar filtro para diferenciar calculos
                    if (filter?.idType === FilterAirTypeEnum.FILTER && filter?.idTypeFilter === FilterAirEnum.HEPA_IMPULSION &&
                        criteria.customValue1 === SpeedTypeCriteria.AVERAGE_SPEED) {
                        // filtro impulsion solo se compueba la velocidad media
                        according = this.checkValueImpulsionBiological(criteria, value, applyUniformmity, uniformity) && according;
                    }
                })
            });
        } else if (eqType === EquipmentTypeAirEnum.AISLATOR || eqType === EquipmentTypeAirEnum.CELL
            || eqType === EquipmentTypeAirEnum.TRANSFER) {

            essay.criterias.forEach(criteria => {

                criteria.values.forEach(value => {

                    // recupero el filtro

                    const filter = this.findFilterByValue(value, filters);


                    // comparar filtro para diferenciar calculos

                    if (filter?.idType === FilterAirTypeEnum.FILTER && filter?.idTypeFilter === FilterAirEnum.HEPA_IMPULSION &&

                        criteria.customValue1 === SpeedTypeCriteria.AVERAGE_SPEED) {

                        // filtro impulsion solo se compueba la velocidad media

                        const arrayData = value?.value?.toString().split(';');

                        const avgValue = arrayData != null ? ArrayUtils.calcAvg(arrayData.map(valuet => Number(valuet))) : 0;

                        according = this.checkValueCriteriaGeneric(criteria, avgValue) && according;

                    } else if (this.isExtraction(filter) && criteria.customValue1 === SpeedTypeCriteria.EXTRACTION_SPEED) {

                        // filtro extraccion solo se compueba la velocidad de extraccion

                        if (value.data != null) {

                            value?.data.forEach(data => {

                                according = this.checkValueCriteriaGeneric(criteria, +data.value) && according;

                            });

                        }

                    }

                })

            });

        } else if (eqType === EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_IIB || eqType === EquipmentTypeAirEnum.BIOLOGICAL_SAFETY_BOX_IIA) {
            essay.criterias.forEach(criteria => {
                criteria.values.forEach(value => {
                    if (value.reference === 'canalizada') {
                        isChanneled = true;
                    }

                    // recupero el filtro
                    const filter = this.findFilterByValue(value, filters);

                    // comparar filtro para diferenciar calculos
                    if (filter?.idType === FilterAirTypeEnum.FILTER) {
                        if (filter?.idTypeFilter === FilterAirEnum.HEPA_IMPULSION
                            && criteria.customValue1 === SpeedTypeCriteria.AVERAGE_SPEED) {
                            // filtro impulsion solo se compueba la velocidad media
                            according = this.checkValueImpulsionBiological(criteria, value, applyUniformmity, uniformity) && according;
                        // eslint-disable-next-line max-len
                        } else if (filter?.idTypeFilter === FilterAirEnum.HEPA_EXTRACTION_1
                            && criteria.customValue1 === SpeedTypeCriteria.FRONTAL_SPEED) {

                            // filtro de extraccion: Se comrpueba si es canalizada o no
                            if (isChanneled) {
                                // frontal
                                according = this.checkValueImpulsionBiological(criteria, value, applyUniformmity, uniformity) && according;
                            } else {
                                const frontSpeed = this.calculateFrontSpeed(value, exSurface, frontSurface);
                                according = this.checkValueCriteriaGeneric(criteria, frontSpeed) && according; // frontal
                            }
                        }
                    }
                })
            });
        } else {
            essay.criterias.forEach(criteria => {
                if (applyUniformmity) {
                    according = this.checkValuesCriteriaUniformity(criteria, uniformity) && according;
                } else {
                    according = this.checkValuesCriteria(criteria) && according;
                }
            });
        }

        return according;
    }

    static getParticleSize(criteria: Criteria): string[] {
        const defaultParticleSize = ['0.5', '5.0'];

        if (criteria.idType === CriteriaTypeEnum.INFORMATIVE) {
            return defaultParticleSize;
        } else if (!criteria.customValue1) {
            return [];
        }

        const gradeClassType = criteria.customValue1.toLowerCase();
        // eslint-disable-next-line max-len
        return gradeClassType.startsWith('iso') || gradeClassType.startsWith('class') ? (criteria.customValue2 ? criteria.customValue2.split(';'): defaultParticleSize) : defaultParticleSize;
    }

    static getTotalParticlesBySize(size: string, gradeClassType: string, isRepose: boolean): number {
        let res = Number.MAX_VALUE;

        if (gradeClassType === 'iso1') {
            res = this.getCountParticlesIso1(size);
        } else if (gradeClassType === 'iso2') {
            res = this.getCountParticlesIso2(size);
        } else if (gradeClassType === 'iso3') {
            res = this.getCountParticlesIso3(size);
        } else if (gradeClassType === 'iso4') {
            res = this.getCountParticlesIso4(size);
        } else if (gradeClassType === 'iso5') {
            res = this.getCountParticlesIso5(size);
        } else if (gradeClassType === 'iso6') {
            res = this.getCountParticlesIso6(size);
        } else if (gradeClassType === 'iso7') {
            res = this.getCountParticlesIso7(size);
        } else if (gradeClassType === 'iso8') {
            res = this.getCountParticlesIso8(size);
        } else if (gradeClassType === 'iso9') {
            res = this.getCountParticlesIso9(size);
        } else {
            if (gradeClassType === 'gradea') {
                if (size === '0.5') {
                    res = Constants.PARTICLE_COUNT_GRADE_A_0_5;
                } else {
                    res = Constants.PARTICLE_COUNT_GRADE_A_5_0;
                }
            } else if (gradeClassType === 'gradeb') {
                if (isRepose) {
                    if (size === '0.5') {
                        res = Constants.PARTICLE_COUNT_GRADE_B_0_5_REPOSE;
                    } else {
                        res = Constants.PARTICLE_COUNT_GRADE_B_5_0_REPOSE;
                    }
                } else {
                    if (size === '0.5') {
                        res = Constants.PARTICLE_COUNT_GRADE_B_0_5_WORKING;
                    } else {
                        res = Constants.PARTICLE_COUNT_GRADE_B_5_0_WORKING;
                    }
                }
            } else if (gradeClassType === 'gradec') {
                if (isRepose) {
                    if (size === '0.5') {
                        res = Constants.PARTICLE_COUNT_GRADE_C_0_5_REPOSE;
                    } else {
                        res = Constants.PARTICLE_COUNT_GRADE_C_5_0_REPOSE;
                    }
                } else {
                    if (size === '0.5') {
                        res = Constants.PARTICLE_COUNT_GRADE_C_0_5_WORKING;
                    } else {
                        res = Constants.PARTICLE_COUNT_GRADE_C_5_0_WORKING;
                    }
                }
            } else if (gradeClassType === 'graded') {
                if (isRepose) {
                    if (size === '0.5') {
                        res = Constants.PARTICLE_COUNT_GRADE_D_0_5_REPOSE;
                    } else {
                        res = Constants.PARTICLE_COUNT_GRADE_D_5_0_REPOSE;
                    }
                }
            }
        }

        return res;
    }

    static checkValuesCriteriaParticleCount(essay: EssayProtocolAir, criteria: Criteria, results: EssayExecutionAirResultParticles[],
        isCompressedGas: boolean): boolean {

        let according = true;

        if (criteria.idType === CriteriaTypeEnum.INFORMATIVE) {
            return true;
        }

        const idEssayType = essay.idEssayType;

        const totalPoints = this.getTotalCountingPoints(essay);

        const gradeClassType = criteria.customValue1?.toLowerCase();
        // eslint-disable-next-line max-len
        const isRepose = (idEssayType === EssayAirTypeEnum.PARTICLE_COUNT_REPOSE) || (idEssayType === EssayAirTypeEnum.ANEXOI_COUNT_REPOSE || idEssayType === EssayAirTypeEnum.ANEX_COUNT);
        const isAnexo = idEssayType === EssayAirTypeEnum.ANEXOI_COUNT_WORKING || idEssayType === EssayAirTypeEnum.ANEXOI_COUNT_REPOSE
                        || idEssayType === EssayAirTypeEnum.ANEX_COUNT;

        let criteriaV1: number = null;
        let criteriaV2: number = null;
        if (isAnexo) {
            const arrayCustom2 = criteria.customValue2?.split(';').map(Number);
            if (arrayCustom2) {
                criteriaV1 = arrayCustom2[0];
                criteriaV2 = arrayCustom2[1];
            }
        }
        const particleSize = this.getParticleSize(criteria);

        const smallSize = particleSize[0] || '0.5';
        const largeSize = particleSize[1] || '5.0';
        const extraSize = particleSize[2];

        let smallSizeCount = 0;
        let largeSizeCount = 0;
        let extraSizeCount = 0;
        if (criteria.idType  === CriteriaTypeEnum.CLASS_GRADE_GAS && gradeClassType?.includes('class')) {
            smallSizeCount = this.getTotalParticlesGasBySize(smallSize, gradeClassType);
            largeSizeCount = this.getTotalParticlesGasBySize(largeSize, gradeClassType);
            extraSizeCount = extraSize != null ? this.getTotalParticlesGasBySize(extraSize, gradeClassType) : null;
        } else {
            // eslint-disable-next-line max-len
            smallSizeCount = this.getTotalParticlesBySizeWithCriteriaV(smallSize, gradeClassType, isRepose, isAnexo, criteriaV1, criteriaV2);
            // eslint-disable-next-line max-len
            largeSizeCount = this.getTotalParticlesBySizeWithCriteriaV(largeSize, gradeClassType, isRepose, isAnexo, criteriaV1, criteriaV2);
            // eslint-disable-next-line max-len
            extraSizeCount = extraSize != null ? this.getTotalParticlesBySizeWithCriteriaV(extraSize, gradeClassType, isRepose, isAnexo, criteriaV1, criteriaV2) : null;
        }

        if (isCompressedGas) {
            const valuesParticles = essay.valuesParticles;
            const last3Particles = valuesParticles.slice(-3);

            const smallSizeResult = this.getAverageValueByParticleSize(last3Particles, smallSize);
            const largeSizeResult = this.getAverageValueByParticleSize(last3Particles, largeSize);
            const extraSizeResult = extraSize != null ? this.getAverageValueByParticleSize(last3Particles, extraSize) : null;

            if ((smallSizeResult > smallSizeCount) || (largeSizeResult > largeSizeCount)) {
                according = false;
            } else if (extraSizeCount != null && extraSizeResult != null && (extraSizeResult > extraSizeCount)) {
                according = false;
            }
        } else {
            const countingPoints = results.map(a => a.location).filter(ArrayUtils.unique).length;

            if (countingPoints < totalPoints) {
                return null;
            }

            results.forEach(result => {
                if (!result) {
                    return;
                }

                const smallSizeResult = this.calculateResultSize(result, smallSize);
                const largeSizeResult = this.calculateResultSize(result, largeSize);
                const extraSizeResult = extraSize != null ? this.calculateResultSize(result, extraSize) : null;

                if ((smallSizeResult > smallSizeCount) || (largeSizeResult > largeSizeCount)) {
                    according = false;
                } else if (extraSizeCount != null && extraSizeResult != null && (extraSizeResult > extraSizeCount)) {
                    according = false;
                }
            });
        }

        return according;
    }

    static checkValuesCriteriaParticleRecovery(essay: EssayProtocolAir, criteria: Criteria, results: EssayExecutionAirResultParticles[])
        : boolean {
        let according = true;

        const criteriaValue = criteria.criteriaValue1;
        const canBeEqual = criteria.idType === CriteriaTypeEnum.LOWER_EQUAL;

        const totalPoints = this.getTotalCountingPoints(essay);

        if (!criteriaValue || criteriaValue <= 0) {
            return according;
        }

        const resultsRecovery = this.getValuesEssayRecovery(results);

        if (resultsRecovery.size < totalPoints) {
            return null;
        }

        resultsRecovery.forEach(diff => {
            if (diff === 0 || (canBeEqual && diff > criteriaValue) || (!canBeEqual && diff >= criteriaValue)) {
                according = false;
            }
        });

        return according;
    }

    static getValuesEssayRecoveryShow(results: EssayExecutionAirResultParticles[]): Map<string, string> {
        const res = new Map<string, string>();

        const resultsGroupBy = ArrayUtils.groupBy(results, (item: EssayExecutionAirResultParticles) => item.location) as
            Map<string, EssayExecutionAirResultParticles[]>;

        resultsGroupBy.forEach((result, location) => {
            const minDate = DateUtils.anyToDate(Math.min(...result.map(a => a.date).filter(a => a != null).map(a => DateUtils.anyToDate(a))
                .map(a => a.getTime())));
            const maxDate = DateUtils.anyToDate(Math.max(...result.map(a => a.date).filter(a => a != null).map(a => DateUtils.anyToDate(a))
                .map(a => a.getTime())));

            const diff = DateUtils.timeDiffCalc(minDate, maxDate);

            if (!isNaN(+location)) {
                location = 'Loc.' + location;
            }

            res.set(location, diff);
        });

        return res;
    }

    static getValuesEssayRecovery(results: EssayExecutionAirResultParticles[]): Map<string, number> {
        const res = new Map<string, number>();

        const resultsGroupBy = ArrayUtils.groupBy(results, (item: EssayExecutionAirResultParticles) => item.location) as
            Map<string, EssayExecutionAirResultParticles[]>;

        resultsGroupBy.forEach((result, location) => {
            const minDate = DateUtils.anyToDate(Math.min(...result.map(a => a.date).filter(a => a != null).map(a => DateUtils.anyToDate(a))
                .map(a => a.getTime())));
            const maxDate = DateUtils.anyToDate(Math.max(...result.map(a => a.date).filter(a => a != null).map(a => DateUtils.anyToDate(a))
                .map(a => a.getTime())));

            const diffMs = maxDate.getTime() - minDate.getTime();
            const diff = Math.round(((diffMs % 86400000) % 3600000) / 60000);

            if (!isNaN(+location)) {
                location = 'Loc.' + location;
            }

            res.set(location, diff);
        });

        return res;
    }

    // TODO: metodo para devolver % uniformidad
    static showUniformity(data: number, average: number): number {
        return (average - data) / average * 100;
    }

    static getTotalVolume(room: RoomAir): number {
        return NumberUtils.sum(room.volumes.map(v => v.surface * v.height));
    }

    static calculateFrontSpeed(result: EssayExecutionResultAir, exSurface: number, exFrontSurface: number): number {
        const flow = this.calculateExFlow(result, exSurface);

        return flow / (exFrontSurface * 3600);
    }

    static getFilterSurface(filter: FilterAir): number {
        let surface = 0;

        if (filter == null) {
            surface = 0;
        } else if (filter.length != null) {
            surface = NumberUtils.round(filter.length / 1000 * NumberUtils.round(filter.width / 1000, 2));
        } else {
            const radio = filter.diameter / 2000;
            surface = NumberUtils.round(Math.PI * Math.pow(radio, 2), 2);
            if (surface <= 0.01) {
                surface = Math.PI * Math.pow(radio, 2);
            }
        }

        return surface;
    }

    static getTotalCountingPoints(essay: EssayProtocolAir): number {
        const fieldPoints = essay.essayValues?.find(f => f.idField === FieldEnum.POINTS);

        return Math.max(+fieldPoints?.value || 0, 0);
    }

    static hasMicrobiologicalAmbient(essay: EssayProtocolAir): boolean {
        return this.hasMicrobiologicalByField(essay, 'environment');
    }

    static hasMicrobiologicalSurface(essay: EssayProtocolAir): boolean {
        return this.hasMicrobiologicalByField(essay, 'surface');
    }

    static hasMicrobiologicalByField(essay: EssayProtocolAir, field: string): boolean {
        if (essay.idCompressedGas != null) {
            return true;
        }

        const fieldMicrobiological = essay.essayValues?.find(f => f.idField === FieldEnum.SAMPLE_PLACE);

        if (fieldMicrobiological?.value == null) {
            return false;
        }

        return (fieldMicrobiological?.value as string[]).includes(field);
    }

    static objectToVolume(item: Volume | any): Volume {

        if (item instanceof Volume) {
            return item;
        } else {
            const f = new Volume();

            for (const prop in item) {
                // JS Nos obliga a hacer esto para nada...
                if (prop !== '') {
                    f[prop] = item[prop];
                }
            }

            return f;
        }
    }

    static checkIdFilter(idFilter: string): boolean {
        return !/[a-zA-Z]/.test(idFilter);
    }

    // eslint-disable-next-line max-len
    static getTotalParticlesBySizeWithCriteriaV(size: string, gradeClassType: string, isRepose: boolean, isAnexo: boolean, criteriav1: number, criteriav2: number): number {
        let res = Number.MAX_VALUE;

        if (gradeClassType === 'iso1') {
            res = this.getCountParticlesIso1(size);
        } else if (gradeClassType === 'iso2') {
            res = this.getCountParticlesIso2(size);
        } else if (gradeClassType === 'iso3') {
            res = this.getCountParticlesIso3(size);
        } else if (gradeClassType === 'iso4') {
            res = this.getCountParticlesIso4(size);
        } else if (gradeClassType === 'iso5') {
            res = this.getCountParticlesIso5(size);
        } else if (gradeClassType === 'iso6') {
            res = this.getCountParticlesIso6(size);
        } else if (gradeClassType === 'iso7') {
            res = this.getCountParticlesIso7(size);
        } else if (gradeClassType === 'iso8') {
            res = this.getCountParticlesIso8(size);
        } else if (gradeClassType === 'iso9') {
            res = this.getCountParticlesIso9(size);
        } else {
            if (isAnexo) {
                if (gradeClassType === 'gradea') {
                    if (isRepose) {
                        if (size === '0.5') {
                            res = Constants.PARTICLE_COUNT_GRADE_A_0_5_ANEXOI_REPOSE;
                        } else {
                            res = criteriav1;
                        }
                    } else {
                        if (size === '0.5') {
                            res = Constants.PARTICLE_COUNT_GRADE_A_0_5_ANEXOI_WORKING;
                        } else {
                            res = criteriav1;
                        }
                    }
                } else if (gradeClassType === 'gradeb') {
                    if (isRepose) {
                        if (size === '0.5') {
                            res = Constants.PARTICLE_COUNT_GRADE_B_0_5_ANEXOI_REPOSE;
                        } else {
                            res = criteriav1;
                        }
                    } else {
                        if (size === '0.5') {
                            res = Constants.PARTICLE_COUNT_GRADE_B_0_5_ANEXOI_WORKING;
                        } else {
                            res = Constants.PARTICLE_COUNT_GRADE_B_5_0_ANEXOI_WORKING;
                        }
                    }
                } else if (gradeClassType === 'gradec') {
                    if (isRepose) {
                        if (size === '0.5') {
                            res = Constants.PARTICLE_COUNT_GRADE_C_0_5_ANEXOI_REPOSE;
                        } else {
                            res = Constants.PARTICLE_COUNT_GRADE_C_5_0_ANEXOI_REPOSE;
                        }
                    } else {
                        if (size === '0.5') {
                            res = Constants.PARTICLE_COUNT_GRADE_C_0_5_ANEXOI_WORKING;
                        } else {
                            res = Constants.PARTICLE_COUNT_GRADE_C_5_0_ANEXOI_WORKING;
                        }
                    }
                } else if (gradeClassType === 'graded') {
                    if (isRepose) {
                        if (size === '0.5') {
                            res = Constants.PARTICLE_COUNT_GRADE_D_0_5_ANEXOI_REPOSE;
                        } else {
                            res = Constants.PARTICLE_COUNT_GRADE_D_5_0_ANEXOI_REPOSE;
                        }
                    } else {
                        if (size === '0.5') {
                            res = criteriav1;
                        }else {
                            res = criteriav2;
                        }
                    }
                }
            } else {
                if (gradeClassType === 'gradea') {
                    if (size === '0.5') {
                        res = Constants.PARTICLE_COUNT_GRADE_A_0_5;
                    } else {
                        res = Constants.PARTICLE_COUNT_GRADE_A_5_0;
                    }
                } else if (gradeClassType === 'gradeb') {
                    if (isRepose) {
                        if (size === '0.5') {
                            res = Constants.PARTICLE_COUNT_GRADE_B_0_5_REPOSE;
                        } else {
                            res = Constants.PARTICLE_COUNT_GRADE_B_5_0_REPOSE;
                        }
                    } else {
                        if (size === '0.5') {
                            res = Constants.PARTICLE_COUNT_GRADE_B_0_5_WORKING;
                        } else {
                            res = Constants.PARTICLE_COUNT_GRADE_B_5_0_WORKING;
                        }
                    }
                } else if (gradeClassType === 'gradec') {
                    if (isRepose) {
                        if (size === '0.5') {
                            res = Constants.PARTICLE_COUNT_GRADE_C_0_5_REPOSE;
                        } else {
                            res = Constants.PARTICLE_COUNT_GRADE_C_5_0_REPOSE;
                        }
                    } else {
                        if (size === '0.5') {
                            res = Constants.PARTICLE_COUNT_GRADE_C_0_5_WORKING;
                        } else {
                            res = Constants.PARTICLE_COUNT_GRADE_C_5_0_WORKING;
                        }
                    }
                } else if (gradeClassType === 'graded') {
                    if (isRepose) {
                        if (size === '0.5') {
                            res = Constants.PARTICLE_COUNT_GRADE_D_0_5_REPOSE;
                        } else {
                            res = Constants.PARTICLE_COUNT_GRADE_D_5_0_REPOSE;
                        }
                    }
                }
            }
        }
        return res;
    }

    static getTotalParticlesGasBySize(size: string, gradeClassType: string): number {
        let res = Number.MAX_VALUE;

        if (gradeClassType === 'class1') {
            if (size === '0.5') {
                res = Constants.PARTICLE_COUNT_ISO_8573_1_0_5;
            } else if (size === '1.0') {
                res = Constants.PARTICLE_COUNT_ISO_8573_1_1_0;
            } else if (size === '5.0') {
                res = Constants.PARTICLE_COUNT_ISO_8573_1_5_0;
            }
        } else if (gradeClassType === 'class2') {
            if (size === '0.5') {
                res = Constants.PARTICLE_COUNT_ISO_8573_2_0_5;
            } else if (size === '1.0') {
                res = Constants.PARTICLE_COUNT_ISO_8573_2_1_0;
            } else if (size === '5.0') {
                res = Constants.PARTICLE_COUNT_ISO_8573_2_5_0;
            }
       } else if (gradeClassType === 'class3') {
            if (size === '1.0') {
                res = Constants.PARTICLE_COUNT_ISO_8573_3_1_0;
            } else if (size === '5.0') {
                res = Constants.PARTICLE_COUNT_ISO_8573_3_5_0;
            }
       } else if (gradeClassType === 'class4') {
            if (size === '5.0') {
                res = Constants.PARTICLE_COUNT_ISO_8573_4_5_0;
            }
       }  else if (gradeClassType === 'class5') {
            if (size === '5.0') {
                res = Constants.PARTICLE_COUNT_ISO_8573_5_5_0;
            }
        }

        return res;
    }

    public static getNameForReportPdfVersion(docCode: string, version: string, areas: string, translate: TranslateService): string {
        const name = this.getNameForReportGenericVersion(docCode, version, areas, translate);

        return translate.instant('executionEdit.files.report', { name }) as string;
    }

    private static getCountParticlesIso1(particleSize: string): number {
        let res = 0;

        if (particleSize === '0.1') {
            res = Constants.PARTICLE_COUNT_ISO_1_0_1;
        } else if (particleSize === '0.2') {
            res = Constants.PARTICLE_COUNT_ISO_1_0_2;
        } else if (particleSize === '0.3') {
            res = Constants.PARTICLE_COUNT_ISO_1_0_3;
        } else if (particleSize === '0.5') {
            res = Constants.PARTICLE_COUNT_ISO_1_0_5;
        } else if (particleSize === '1.0') {
            res = Constants.PARTICLE_COUNT_ISO_1_1_0;
        } else if (particleSize === '5.0') {
            res = Constants.PARTICLE_COUNT_ISO_1_5_0;
        }

        return res;
    }

    private static getCountParticlesIso2(particleSize: string): number {
        let res = 0;

        if (particleSize === '0.1') {
            res = Constants.PARTICLE_COUNT_ISO_2_0_1;
        } else if (particleSize === '0.2') {
            res = Constants.PARTICLE_COUNT_ISO_2_0_2;
        } else if (particleSize === '0.3') {
            res = Constants.PARTICLE_COUNT_ISO_2_0_3;
        } else if (particleSize === '0.5') {
            res = Constants.PARTICLE_COUNT_ISO_2_0_5;
        } else if (particleSize === '1.0') {
            res = Constants.PARTICLE_COUNT_ISO_2_1_0;
        } else if (particleSize === '5.0') {
            res = Constants.PARTICLE_COUNT_ISO_2_5_0;
        }

        return res;
    }

    private static getCountParticlesIso3(particleSize: string): number {
        let res = 0;

        if (particleSize === '0.1') {
            res = Constants.PARTICLE_COUNT_ISO_3_0_1;
        } else if (particleSize === '0.2') {
            res = Constants.PARTICLE_COUNT_ISO_3_0_2;
        } else if (particleSize === '0.3') {
            res = Constants.PARTICLE_COUNT_ISO_3_0_3;
        } else if (particleSize === '0.5') {
            res = Constants.PARTICLE_COUNT_ISO_3_0_5;
        } else if (particleSize === '1.0') {
            res = Constants.PARTICLE_COUNT_ISO_3_1_0;
        } else if (particleSize === '5.0') {
            res = Constants.PARTICLE_COUNT_ISO_3_5_0;
        }

        return res;
    }

    private static getCountParticlesIso4(particleSize: string): number {
        let res = 0;

        if (particleSize === '0.1') {
            res = Constants.PARTICLE_COUNT_ISO_4_0_1;
        } else if (particleSize === '0.2') {
            res = Constants.PARTICLE_COUNT_ISO_4_0_2;
        } else if (particleSize === '0.3') {
            res = Constants.PARTICLE_COUNT_ISO_4_0_3;
        } else if (particleSize === '0.5') {
            res = Constants.PARTICLE_COUNT_ISO_4_0_5;
        } else if (particleSize === '1.0') {
            res = Constants.PARTICLE_COUNT_ISO_4_1_0;
        } else if (particleSize === '5.0') {
            res = Constants.PARTICLE_COUNT_ISO_4_5_0;
        }

        return res;
    }

    private static getCountParticlesIso5(particleSize: string): number {
        let res = 0;

        if (particleSize === '0.1') {
            res = Constants.PARTICLE_COUNT_ISO_5_0_1;
        } else if (particleSize === '0.2') {
            res = Constants.PARTICLE_COUNT_ISO_5_0_2;
        } else if (particleSize === '0.3') {
            res = Constants.PARTICLE_COUNT_ISO_5_0_3;
        } else if (particleSize === '0.5') {
            res = Constants.PARTICLE_COUNT_ISO_5_0_5;
        } else if (particleSize === '1.0') {
            res = Constants.PARTICLE_COUNT_ISO_5_1_0;
        } else if (particleSize === '5.0') {
            res = Constants.PARTICLE_COUNT_ISO_5_5_0;
        }

        return res;
    }

    private static getCountParticlesIso6(particleSize: string): number {
        let res = 0;

        if (particleSize === '0.1') {
            res = Constants.PARTICLE_COUNT_ISO_6_0_1;
        } else if (particleSize === '0.2') {
            res = Constants.PARTICLE_COUNT_ISO_6_0_2;
        } else if (particleSize === '0.3') {
            res = Constants.PARTICLE_COUNT_ISO_6_0_3;
        } else if (particleSize === '0.5') {
            res = Constants.PARTICLE_COUNT_ISO_6_0_5;
        } else if (particleSize === '1.0') {
            res = Constants.PARTICLE_COUNT_ISO_6_1_0;
        } else if (particleSize === '5.0') {
            res = Constants.PARTICLE_COUNT_ISO_6_5_0;
        }

        return res;
    }

    private static getCountParticlesIso7(particleSize: string): number {
        let res = 0;

        if (particleSize === '0.5') {
            res = Constants.PARTICLE_COUNT_ISO_7_0_5;
        } else if (particleSize === '1.0') {
            res = Constants.PARTICLE_COUNT_ISO_7_1_0;
        } else if (particleSize === '5.0') {
            res = Constants.PARTICLE_COUNT_ISO_7_5_0;
        }

        return res;
    }

    private static getCountParticlesIso8(particleSize: string): number {
        let res = 0;

        if (particleSize === '0.5') {
            res = Constants.PARTICLE_COUNT_ISO_8_0_5;
        } else if (particleSize === '1.0') {
            res = Constants.PARTICLE_COUNT_ISO_8_1_0;
        } else if (particleSize === '5.0') {
            res = Constants.PARTICLE_COUNT_ISO_8_5_0;
        }

        return res;
    }

    private static getCountParticlesIso9(particleSize: string): number {
        let res = 0;

        if (particleSize === '0.5') {
            res = Constants.PARTICLE_COUNT_ISO_9_0_5;
        } else if (particleSize === '1.0') {
            res = Constants.PARTICLE_COUNT_ISO_9_1_0;
        } else if (particleSize === '5.0') {
            res = Constants.PARTICLE_COUNT_ISO_9_5_0;
        }

        return res;
    }

    private static calculateResultSize(result: EssayExecutionAirResultParticles, particleSize: string): number {
        let res = 0;

        if (particleSize === '0.1') {
            res = result.zeroOne;
        } else if (particleSize === '0.2') {
            res = result.zeroTwo;
        } else if (particleSize === '0.3') {
            res = result.zeroThree;
        } else if (particleSize === '0.5') {
            res = result.zeroFive;
        } else if (particleSize === '1.0') {
            res = result.oneZero;
        } else if (particleSize === '5.0') {
            res = result.fiveZero;
        }

        return res;
    }

    private static getNameForReportGeneric(ex: ExecutionAir, translate: TranslateService): string {
        const documentCode = ex.documentCode;
        const versionName = 'v.' + ex.currentVersion.toString().padStart(2, '0');
        const clientName = ex.nameClient;
        let areas = null;

        if (!ArrayUtils.isEmpty(ex.areas)) {
            areas = ex.areas.map(a => a.name).join(', ');
        }

        return [documentCode, versionName, translate.instant('executionEdit.files.execution.documentType'), clientName, areas]
            .filter((s: string) => StringUtils.isNotEmpty(s)).join(' ');
    }

    private static checkUniformity(data: number, uniformity: number, average: number): boolean {
        const desviation = (uniformity / 100) * average;
        let according = true;
        if (data > average + desviation || data < average - desviation) {
            according = false;
        }

        return according;
    }

    private static calculateExFlow(result: EssayExecutionResultAir, exSurface: number): number {
        const arrayData = result?.value?.toString().split(';');
        let speed = 0;

        if (arrayData != null) {
            speed = ArrayUtils.calcAvg(arrayData.map(value => Number(value)));
        }

        return speed * exSurface * 3600;
    }

    private static checkValueImpulsionBiological(criteria: Criteria, value: EssayExecutionResultAir, applyUniformity: boolean,
        uniformity: number): boolean {
        const arrayData = value?.value?.toString().split(';');
        let according = true;
        const avgValue = arrayData != null ? ArrayUtils.calcAvg(arrayData.map(valuet => Number(valuet))) : 0;

        arrayData?.forEach(data => according = this.checkValueCriteriaGeneric(criteria, Number(data)));

        if (applyUniformity && uniformity) {
            arrayData?.forEach(data => according = according && this.checkUniformity(Number(data), uniformity, avgValue));
        }

        return according;
    }

    private static checkValueCriteriaGeneric(criteria: Criteria, value: number): boolean {
        const idTypeCriteria = criteria.idType;
        const critValue1 = criteria.criteriaValue1;
        const critValue2 = criteria.criteriaValue2;
        let according = true;

        if (idTypeCriteria === CriteriaTypeEnum.LOWER_EQUAL && value > critValue1) {
            according = false;
        } else if (idTypeCriteria === CriteriaTypeEnum.LOWER_THAN && value >= critValue1) {
            according = false;
        } else if (idTypeCriteria === CriteriaTypeEnum.GREATER_THAN && value <= critValue1) {
            according = false;
        } else if (idTypeCriteria === CriteriaTypeEnum.GREATER_EQUAL && value < critValue1) {
            according = false;
        } else if (idTypeCriteria === CriteriaTypeEnum.TOLERANCE && (value < critValue1 - critValue2 || value > critValue1 + critValue2)) {
            according = false;
        } else if (idTypeCriteria === CriteriaTypeEnum.RANGE && (value < critValue1 || value > critValue2)) {
            according = false;
        } else if (idTypeCriteria === CriteriaTypeEnum.DEW_POINT) {
            according = this.checkValuesCriteriaDewPoint(criteria, value);
        }

        return according;
    }

    private static checkValuesCriteriaDewPoint(criteria: Criteria, value: number): boolean {
        let according = true;
        const criteriaType = criteria.customValue1;

        if (value == null) {
            according = false;
        } else if (criteriaType === 'tdClass1' && value > Constants.DEW_POINT_CLASS_1) {
            according = false;
        } else if (criteriaType === 'tdClass2' && value > Constants.DEW_POINT_CLASS_2) {
            according = false;
        } else if (criteriaType === 'tdClass3' && value > Constants.DEW_POINT_CLASS_3) {
            according = false;
        } else if (criteriaType === 'tdClass4' && value > Constants.DEW_POINT_CLASS_4) {
            according = false;
        } else if (criteriaType === 'tdClass5' && value > Constants.DEW_POINT_CLASS_5) {
            according = false;
        } else if (criteriaType === 'tdClass6' && value > Constants.DEW_POINT_CLASS_6) {
            according = false;
        } else if (criteria.customValue2 != null && value > (+criteria.customValue2)) {
            according = false;
        }

        return according;
    }

    private static getAverageValueByParticleSize(particles: EssayExecutionAirResultParticles[], particleSize: string): number {
        let res: number[] = [];

        if (particleSize === '0.1') {
            res = particles.map(a => a.zeroOne);
        } else if (particleSize === '0.2') {
            res = particles.map(a => a.zeroTwo);
        } else if (particleSize === '0.3') {
            res = particles.map(a => a.zeroThree);
        } else if (particleSize === '0.5') {
            res = particles.map(a => a.zeroFive);
        } else if (particleSize === '1.0') {
            res = particles.map(a => a.oneZero);
        } else if (particleSize === '5.0') {
            res = particles.map(a => a.fiveZero);
        }

        return NumberUtils.round(NumberUtils.average(res), 0);
    }

    private static findFilterByValue(value: EssayExecutionResultAir, filters: FilterAir[]): FilterAir {
        let filter: FilterAir;

        if (value.uuidFilter != null) {
            if (ExecutionAirUtils.checkIdFilter(value.uuidFilter)) {
                filter = filters.find(f => f.id.toString() === value.uuidFilter);
            } else {
                filter = filters.find(f => f.idOffline === value.uuidFilter);
            }
        } else {
            filter = filters.find(f => f.id === value.idFilter);
        }

        return filter;
    }

    private static isExtraction(filter: FilterAir): boolean {
        let isExtraction = false;
        if (filter?.idType === FilterAirTypeEnum.EXTRACTION_RACK) {
            isExtraction = true;
        } else if (filter?.idType === FilterAirTypeEnum.FILTER || filter?.idType === FilterAirTypeEnum.FILTER_WITH_DIFFUSER) {
            if (filter?.idTypeFilter === FilterAirEnum.HEPA_EXTRACTION_1 || filter?.idTypeFilter === FilterAirEnum.HEPA_EXTRACTION_2) {
                isExtraction = true;
            }
        }
        return isExtraction;
    }

    private static getNameForReportGenericVersion(docCode: string, version: string, areas: string, translate: TranslateService): string {
        const documentCode = docCode;
        const versionName = 'v.' + version.padStart(2, '0');

        return [documentCode, versionName, translate.instant('executionEdit.files.execution.documentType'), areas]
            .filter((s: string) => StringUtils.isNotEmpty(s)).join(' ');
    }

}
