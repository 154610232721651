<form [formGroup]="form">
  <mat-accordion>
    <mat-expansion-panel [expanded]="expandedFilter" (opened)="expandedFilter = true" (closed)="expandedFilter = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'calibrateEquipmentList.filter.title' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

        <div fxFlex="25" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput placeholder="{{ 'calibrateEquipmentList.filter.certificateNum.label' | translate }}"
              formControlName="certificateNum" [(ngModel)]="filter.certificateNum">
          </mat-form-field>
        </div>

        <div fxFlex="25" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput placeholder="{{ 'calibrateEquipmentList.filter.name.label' | translate }}"
              formControlName="name" [(ngModel)]="filter.name">
          </mat-form-field>
        </div>

        
      
        <div fxFlex="25" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <mat-label>{{ 'calibrateEquipmentList.filter.client.label' | translate }}</mat-label>
            <mat-select #clientSelect formControlName="idClient" [(ngModel)]="filter.idClient">
              <mat-select-filter *ngIf="clientSelect.focused" [displayMember]="'name'" [array]="clients"
                (filteredReturn)="clientsFiltered =$event" [noResultsMessage]="'common.noResults' | translate"
                [placeholder]="'common.selectFilterPlaceholder' | translate"></mat-select-filter>
              <mat-option value="-1"> -- {{ 'calibrationResponsibleType.internal' | translate | uppercase }} --
              </mat-option>
              <mat-option *ngFor="let client of clientsFiltered" [value]="client.id">
                {{client.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="25" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput placeholder="{{ 'calibrateEquipmentList.filter.serialNum.label' | translate }}"
              formControlName="serialNum" [(ngModel)]="filter.serialNum">
          </mat-form-field>
        </div>

      </div>

      <mat-accordion>
        <mat-expansion-panel expanded="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'calibrateEquipmentList.filter.advanced.title' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'calibrateEquipmentList.filter.type.label' | translate }}"
                  formControlName="type" [(ngModel)]="filter.type">
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'calibrateEquipmentList.filter.maker.label' | translate }}"
                  formControlName="maker" [(ngModel)]="filter.maker">
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'calibrateEquipmentList.filter.model.label' | translate }}"
                  formControlName="model" [(ngModel)]="filter.model">
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <mat-label>{{ 'calibrateEquipmentList.filter.status.label' | translate }}</mat-label>
                <mat-select formControlName="idStatus" [(ngModel)]="filter.idStatus">
                  <mat-option [value]="null"> --- </mat-option>
                  <mat-option *ngFor="let item of statuses" [value]="item.id">
                    {{ 'calibrationStatus.' + item.translation | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="datepicker">
                <input matInput [matDatepicker]="calibrateDateStartPicker" formControlName="calibrateDateStart"
                  [ngModel]="filter.calibrateDateStart" (blur)="saveDate('calibrateDateStart', $event)"
                  (dateChange)="saveDate('calibrateDateStart', $event)"
                  placeholder="{{ 'calibrateEquipmentList.filter.calibrateDateStart.label' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="calibrateDateStartPicker"></mat-datepicker-toggle>
                <mat-datepicker #calibrateDateStartPicker [calendarHeaderComponent]="customDatepickerHeader">
                </mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="datepicker">
                <input matInput [matDatepicker]="calibrateDateEndPicker" formControlName="calibrateDateEnd"
                  [ngModel]="filter.calibrateDateEnd" (blur)="saveDate('calibrateDateEnd', $event)"
                  (dateChange)="saveDate('calibrateDateEnd', $event)"
                  placeholder="{{ 'calibrateEquipmentList.filter.calibrateDateEnd.label' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="calibrateDateEndPicker"></mat-datepicker-toggle>
                <mat-datepicker #calibrateDateEndPicker [calendarHeaderComponent]="customDatepickerHeader">
                </mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="datepicker">
                <input matInput [matDatepicker]="expirationDateStartPicker" formControlName="expirationDateStart"
                  [ngModel]="filter.expirationDateStart" (blur)="saveDate('expirationDateStart', $event)"
                  (dateChange)="saveDate('expirationDateStart', $event)"
                  placeholder="{{ 'calibrateEquipmentList.filter.expirationDateStart.label' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="expirationDateStartPicker"></mat-datepicker-toggle>
                <mat-datepicker #expirationDateStartPicker [calendarHeaderComponent]="customDatepickerHeader">
                </mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="datepicker">
                <input matInput [matDatepicker]="expirationDateEndPicker" formControlName="expirationDateEnd"
                  [ngModel]="filter.expirationDateEnd" (blur)="saveDate('expirationDateEnd', $event)"
                  (dateChange)="saveDate('expirationDateEnd', $event)"
                  placeholder="{{ 'calibrateEquipmentList.filter.expirationDateEnd.label' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="expirationDateEndPicker"></mat-datepicker-toggle>
                <mat-datepicker #expirationDateEndPicker [calendarHeaderComponent]="customDatepickerHeader">
                </mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
              <mat-form-field class="datepicker">
                <input matInput [matDatepicker]="realizationFcStartPicker" formControlName="realizationFcStart"
                  [ngModel]="filter.realizationFcStart" (blur)="saveDate('realizationFcStart', $event)"
                  (dateChange)="saveDate('realizationFcStart', $event)"
                  placeholder="{{ 'calibrateEquipmentList.filter.realizationFcStart.label' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="realizationFcStartPicker"></mat-datepicker-toggle>
                <mat-datepicker #realizationFcStartPicker [calendarHeaderComponent]="customDatepickerHeader">
                </mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
              <mat-form-field class="datepicker">
                <input matInput [matDatepicker]="realizationFcEndPicker" formControlName="realizationFcEnd"
                  [ngModel]="filter.realizationFcEnd" (blur)="saveDate('realizationFcEnd', $event)"
                  (dateChange)="saveDate('realizationFcEnd', $event)"
                  placeholder="{{ 'calibrateEquipmentList.filter.realizationFcEnd.label' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="realizationFcEndPicker"></mat-datepicker-toggle>
                <mat-datepicker #realizationFcEndPicker [calendarHeaderComponent]="customDatepickerHeader">
                </mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <mat-form-field class="campoFormulario">
                <input matInput placeholder="{{ 'calibrateEquipmentList.filter.projectNo.label' | translate }}"
                  formControlName="projectNo" [(ngModel)]="filter.projectNo">
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <span> {{ 'internalEquipmentList.filter.variable.label' | translate }} </span>
              <mat-select multiple [(ngModel)]="filter.idsVariable" name="idsVariable"
                [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let item of variables" checkboxPosition="before" [value]="item.id">
                  {{ 'variable.' + item.translation | translate }}
                </mat-option>
              </mat-select>
            </div>
            
            <div fxFlex="25" fxFlex.xs="100%">
              <mat-form-field class="campoFormulario">
                  <mat-label>{{ 'calibrateEquipmentList.filter.madeby.label' | translate }}</mat-label>
                  <mat-select formControlName="regUser" [(ngModel)]="filter.regUser">
                      <mat-option [value]="null"> --- </mat-option>
                      <mat-option *ngFor="let item of users" [value]="item.username">
                          {{item.fullName}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
            </div>

            <div fxFlex="25" fxFlex.lt-md="100%">
              <span> {{ 'internalEquipmentList.filter.appType.label' | translate }} </span>
              <mat-selection-list [(ngModel)]="filter.idsApp" name="idsApp" [ngModelOptions]="{standalone: true}">
                <mat-list-option *ngFor="let item of appTypes" checkboxPosition="before" [value]="item.id">
                  {{ item.name }}
                </mat-list-option>
              </mat-selection-list>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <button mat-raised-button (click)="doFilter()" color="primary">{{ 'button.filter' | translate }}</button>
        <button mat-button (click)="cleanFilter()">{{ 'button.clean' | translate }}</button>
      </div>

    </mat-expansion-panel>
  </mat-accordion>
</form>