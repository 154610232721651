<h1 mat-dialog-title></h1>
<form>

  <div mat-dialog-content class="calibrates-theme">

    <div fxLayout="column" class="flexwrap">

      <div fxFlex>
        <mat-form-field class="campoFormulario">
          <input matInput [ngModel]="equipmentName" #trigger="matAutocompleteTrigger" [matAutocomplete]="auto"
            placeholder="{{ 'calibrateEquipmentEdit.patterns.dialog.equipment.label' | translate }}"
            (keyup)="lookupEquipment($event)" [ngModelOptions]="{standalone: true}" required>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
            (optionSelected)="onSensorChange($event, trigger)">
            <mat-option *ngFor="let item of equipmentAutoComplete; let index = index" [value]="item">
              {{ item.name}} ({{item.serialNum}})
            </mat-option>
          </mat-autocomplete>
          <mat-error>
            {{ 'calibrateEquipmentEdit.patterns.dialog.equipment.error' | translate }}
          </mat-error>
        </mat-form-field>

        <button mat-icon-button *ngIf="data.item.idEquipment != null" (click)="downloadPdf()">
          <mat-icon>cloud_download</mat-icon>
        </button>
      </div>

      <div fxFlex>
        {{ 'calibrateEquipmentEdit.patterns.dialog.values.label' | translate }}
      </div>

      <div fxFlex>

        <div fxLayout="row" fxLayout.xs="column" class="flexwrap"
          *ngFor="let value of data.item.values; let indexValue = index">

          <div fxFlex="5" style="padding-top: 1em;">
            <mat-checkbox name="include{{indexValue}}" color="primary" [checked]="value.include"
              (change)="setValue(indexValue, $event, 'include')" [disabled]="equipmentName == null">
            </mat-checkbox>
          </div>

          <div fxFlex="10" fxFlex.lt-md="95%">
            <mat-form-field class="campoFormulario">
              <input matInput name="num{{indexValue}}" [ngModel]="indexValue + 1" disabled
                placeholder="{{ 'calibrateEquipmentEdit.patterns.dialog.values.number.label' | translate }}">
            </mat-form-field>
          </div>

          <div fxFlex="15" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput type="number" name="value{{indexValue}}" [ngModel]="value.value" disabled
                placeholder="{{ 'calibrateEquipmentEdit.patterns.dialog.values.value.label' | translate }}">
              <mat-error>
                {{ 'calibrateEquipmentEdit.patterns.dialog.values.value.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="20" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput type="number" name="uncertainty{{indexValue}}" [(ngModel)]="value.uncertainty"
                [disabled]="equipmentName == null || !value.include" required
                (blur)="setValue(indexValue, $event.target.value, 'uncertainty')"
                placeholder="{{ 'calibrateEquipmentEdit.patterns.dialog.values.uncertainty.label' | translate }}">
              <mat-error>
                {{ 'calibrateEquipmentEdit.patterns.dialog.values.uncertainty.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

      </div>

    </div>

  </div>
  <div mat-dialog-actions class="calibrates-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()" cdkFocusInitial>{{ 'button.save' | translate
      }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>