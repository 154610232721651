import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';

import { PaymentComplete } from 'src/app/model/payment';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-payment-complete',
  templateUrl: './payment-complete.component.html'
})
export class PaymentCompleteComponent implements OnInit, OnDestroy {

  paymentInfo: PaymentComplete;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.spinnerService.show();
    this.activatedRoute.paramMap.pipe(map(() => window.history.state), takeUntil(this.destroy$)).subscribe((res: PaymentComplete) => {
      this.paymentInfo = res;
      this.spinnerService.hide();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  backToLogin(): void {
    void this.router.navigate(['/login'], { queryParamsHandling: 'merge' });
  }

}
