<div mat-dialog-content class="calibrates-theme">

  <div fxLayout="row" class="flexwrap">
    <div fxFlex="33">
      <mat-form-field class="campoFormulario">
        <mat-select required [(ngModel)]="data.criteria.idType" (selectionChange)="onTypeChange($event)">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let item of criterias" [value]="item.id">
            {{ 'protocolEdit.dialog.essay.criteria.' + item.translation + '.label' | translate }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ 'protocolEdit.dialog.essay.criteria.error' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="33" *ngIf="criteriaSelected != null && criteriaSelected.variablesToShow >= 1">
      <mat-form-field class="campoFormulario">
        <input matInput name="criteriaValue1" [(ngModel)]="data.criteria.criteriaValue1" required type="number"
          placeholder="{{ 'protocolEdit.dialog.essay.criteria.criteriaValue1.label' | translate }}"
          [disabled]="data.disabled" min="-1000">
        <mat-error>
          {{ 'protocolEdit.dialog.essay.criteria.criteriaValue1.error' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="33" *ngIf="criteriaSelected != null && criteriaSelected.variablesToShow >= 2">
      <mat-form-field class="campoFormulario">
        <input matInput name="criteriaValue2" [(ngModel)]="data.criteria.criteriaValue2" required type="number" 
          placeholder="{{ 'protocolEdit.dialog.essay.criteria.criteriaValue2.label' | translate }}"
          [disabled]="data.disabled" >
        <mat-error>
          {{ 'protocolEdit.dialog.essay.criteria.criteriaValue2.error' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

</div>

<div mat-dialog-actions align="end" class="calibrates-theme">
  <button mat-raised-button color="primary" (click)="onOkClick()" [disabled]="data.criteria.idType == null">
    {{ 'button.save' | translate }}</button>
  <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>