import { Component, Inject, OnInit } from '@angular/core';
import { Procedure } from '../../../../model/procedure';
import { ProcedureService } from '../../../../services/procedure.service';
import { Subject, takeUntil } from 'rxjs';
import { ActionConfirmPasswordComponent } from '../../../shared/action-confirm-password/action-confirm-password.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '../../../../services/spinner.service';
import { SnackBarService } from '../../../../services/snackBar.service';

export interface DialogData {
  procedure: Procedure;
  isEdit: boolean;
}
@Component({
  selector: 'app-procedure-edit',
  templateUrl: './procedure-edit-dialog.component.html',
  styleUrls: ['./procedure-edit-dialog.component.scss']
})
export class ProcedureEditDialogComponent implements OnInit {

  procedures: Procedure[] = [];

  private destroy$ = new Subject<void>();

  constructor(private procedureService: ProcedureService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private spinnerService: SpinnerService,
    private snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<ProcedureEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.procedureService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Procedure[]) => this.procedures = data);
  }

  save(): void {
    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
      if (token != null) {
        this.spinnerService.show();
        this.procedureService.existsByName(this.data.procedure.name).pipe(takeUntil(this.destroy$))
        .subscribe((exist: boolean) => {
          if (!exist) {
            this.procedureService.save(this.data.procedure).pipe(takeUntil(this.destroy$))
            .subscribe((item: Procedure) => {
              this.snackBarService.sendSuccess(this.translate.instant('procedureEdit.form.create.ok') as string);
              this.dialogRef.close(item);

            }, () => {
              this.snackBarService.sendError(this.translate.instant('procedureEdit.form.create.error') as string);
              this.spinnerService.hide();
            });
          } else {
            this.snackBarService.sendError(this.translate.instant('procedureEdit.form.exists.error') as string);
              this.spinnerService.hide();
          }
        }, () => {
          this.snackBarService.sendError(this.translate.instant('procedureEdit.form.create.error') as string);
          this.spinnerService.hide();
        });
      } else {
        this.spinnerService.hide();
      }

    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
