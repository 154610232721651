<div class="main-container">
    <form #notificationListForm="ngForm">

        <div class="toolbar-list">

            <div class="toolbar-list-title">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <h1>{{ 'notificationList.title' | translate }}</h1>
                </div>
                <div class="breadcrumbs">
                    <a href="#">{{ 'common.start.label' | translate }}</a> /
                    <span>{{ 'notificationList.title' | translate }}</span>
                </div>
            </div>

            <div class="toolbar-back-button">
                <a (click)="cancel()" mat-button>
                    <mat-icon>undo</mat-icon> {{ 'button.back' | translate }}
                </a>
            </div>
        </div>

        <div class="table-container">
            <table #users mat-table matSort [dataSource]="dataSource">

                <!-- name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'notificationList.cols.name' | translate
                        }} </th>
                    <td mat-cell *matCellDef="let item"> {{ item.name }} </td>
                </ng-container>

                <!-- users Column -->
                <ng-container matColumnDef="users">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'notificationList.cols.users' | translate }} </th>
                    <td mat-cell *matCellDef="let item"> {{ getUsers(item) }} </td>
                </ng-container>

                <!-- Button edit Column -->
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="action">
                        <button mat-icon-button (click)="editRow(element.id)"
                            matTooltip="{{ 'button.edit' | translate }}">
                            <mat-icon>mode_edit</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </div>

        <div class="table-footer-container">
            <div class="actions">
                <a [routerLink]="['.']" [fragment]="'table-container'" class="back-to-top">
                    {{ 'button.backToTop' | translate }} </a>
            </div>
            <div class="paginator">
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                    [length]="dataSource.totalItems$ | async" lang="es">
                </mat-paginator>
            </div>
        </div>
    </form>
</div>