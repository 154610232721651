<div class="main-container">
  <form #executionListForm="ngForm">

    <div class="toolbar-list" *ngIf="!isProtocolDetail">

      <div class="toolbar-list-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Resource-/-Icon-/-Ejecucion-Servicios" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                 <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                 <g id="calendar" transform="translate(6.000000, 4.000000)" stroke="#00ACC7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                     <rect id="Rectangle" x="0" y="4" width="36" height="36" rx="4"></rect>
                     <line x1="26" y1="0" x2="26" y2="8" id="Path"></line>
                     <line x1="10" y1="0" x2="10" y2="8" id="Path"></line>
                     <line x1="0" y1="16" x2="36" y2="16" id="Path"></line>
                 </g>
             </g>
         </svg>
          <h1 class="accent-color">{{ 'executionList.title' | translate }}</h1>
        </div>

        <div class="breadcrumbs">
          <a href="#">{{ 'common.start.label' | translate }}</a> / <span>{{ 'executionList.title' | translate }}</span>
        </div>
      </div>

      <div class="toolbar-list-actions">
        <button mat-raised-button (click)="new()" color="accent"
          matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton"
          [disabled]="disableOfflineButton">
          <mat-icon fxHide.lt-sm>add</mat-icon> {{ 'button.new' | translate }}
        </button>
      </div>

      <div class="toolbar-back-button">
        <a (click)="cancel()" mat-button color="accent">
          <mat-icon color="accent">undo</mat-icon> {{ 'button.back' | translate }}
        </a>
      </div>
    </div>

    <app-execution-list-filter (filterEmitter)="doFilter($event)" class="filter-container" *ngIf="!isProtocolDetail">
    </app-execution-list-filter>

    <div class="table-container">
      <table #executions mat-table matSort [dataSource]="dataSource">

        <!-- client Column -->
        <ng-container matColumnDef="client">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'executionList.cols.client' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.nameClient }} </td>
        </ng-container>

        <!-- process Column -->
        <ng-container matColumnDef="process">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'executionList.cols.process' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.nameProcess }} </td>
        </ng-container>

        <!-- documentCode Column -->
        <ng-container matColumnDef="documentCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'executionList.cols.documentCode' | translate }}
          </th>
          <td mat-cell *matCellDef="let item"> {{ item.documentCode }} v.{{item.currentVersion | number:'2.0' }}</td>
        </ng-container>

        <!-- protocol Column -->
        <ng-container matColumnDef="protocol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'executionList.cols.protocol' | translate }} </th>
          <td mat-cell *matCellDef="let item">
            {{ item.protocol ? (item.protocol.documentCode + ' v. ' + (item.originalProtocolVersion | number:'2.0')) :
            ('common.notApplicable' | translate) }}
          </td>
        </ng-container>

        <!-- projectNo Column -->
        <ng-container matColumnDef="projectNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'executionList.cols.projectNum' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.projectNo }} </td>
        </ng-container>

        <!-- area Column -->
        <ng-container matColumnDef="area">
          <th mat-header-cell *matHeaderCellDef> {{ 'executionList.cols.area' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.areaNames }} </td>
        </ng-container>

        <!-- status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'executionList.cols.status' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ 'executionStatus.' + item.statusTranslation | translate }} </td>
        </ng-container>

        <!-- initWorkDate Column -->
        <ng-container matColumnDef="initWorkDate">
          <th mat-header-cell *matHeaderCellDef> {{ 'executionList.cols.initWorkDate' | translate }} </th>
          <td mat-cell *matCellDef="let item">
            <div style="white-space: pre-line;">
              {{ item.initWorkDate | fixDateTime }}
            </div>
          </td>
        </ng-container>

        <!-- endWorkDate Column -->
        <ng-container matColumnDef="endWorkDate">
          <th mat-header-cell *matHeaderCellDef> {{ 'executionList.cols.endWorkDate' | translate }} </th>
          <td mat-cell *matCellDef="let item">
            <div style="white-space: pre-line;">
              {{ item.endWorkDate | fixDateTime }}
            </div>
          </td>
        </ng-container>

        <!-- realizationDate Column -->
        <ng-container matColumnDef="realizationDate">
          <th mat-header-cell *matHeaderCellDef> {{ 'executionList.cols.realizationDate' | translate }} </th>
          <td mat-cell *matCellDef="let item">
            <div style="white-space: pre-line;">
              {{ item.realizationDate | fixDateTime }}
            </div>
          </td>
        </ng-container>

        <!-- Button download Column -->
        <ng-container matColumnDef="download">
          <th mat-header-cell *matHeaderCellDef colspan="3" fxHide.lt-lg> {{ 'executionList.cols.actions' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <div matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton">
              <button mat-icon-button (click)="downloadPdf(element)" *ngIf="showDownloadButton(element)"
                [disabled]="disableOfflineButton" matTooltip="{{ 'button.download' | translate }}">
                <mat-icon>cloud_download</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <!-- Button edit Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"> {{ 'button.edit' | translate }} </th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button (click)="editRow(element.id)" matTooltip="{{ 'button.edit' | translate }}">
              <mat-icon>mode_edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button delete Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <div matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton">
              <button mat-icon-button (click)="deleteRow(element.id)" *ngIf="showDeleteButton(element)"
                [disabled]="disableOfflineButton" matTooltip="{{ 'button.delete' | translate }}">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <!-- Button offline Column -->
        <ng-container matColumnDef="offline">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"> {{ 'button.edit' | translate }} </th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button (click)="downloadOffline(element)"
              matTooltip="{{ 'button.download-offline' | translate }}" *ngIf="showDownloadOfflineButton(element)">
              <mat-icon>signal_wifi_off</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button clone Column -->
        <ng-container matColumnDef="clone">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button (click)="cloneRow(element.id)" *ngIf="canClone(element)"
              matTooltip="{{ 'button.clone' | translate }}">
              <mat-icon>folder_copy</mat-icon>
            </button>
          </td>
        </ng-container>

         <!-- Button clone Column -->
         <ng-container matColumnDef="clone-exec">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button (click)="cloneExecution(element.id)" [disabled]="showCanCloneExecution"
              matTooltip="{{ 'button.clone-execution' | translate }}">
              <mat-icon>file_copy</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button more Column -->
        <ng-container matColumnDef="more" stickyEnd>
          <th mat-header-cell *matHeaderCellDef fxHide fxShow.lt-lg></th>
          <td mat-cell *matCellDef="let element" fxHide fxShow.lt-lg class="action">

            <mat-menu #appMenu="matMenu" class="moreActions">
              <ng-template matMenuContent let-name="element">
                <button mat-button (click)="downloadPdf(element)" *ngIf="showDownloadButton(element)"
                  [disabled]="disableOfflineButton">
                  <mat-icon>cloud_download</mat-icon> {{ 'button.download' | translate }}
                </button>
                <button mat-button (click)="editRow(element.id)">
                  <mat-icon>mode_edit</mat-icon> {{ 'button.edit' | translate }}
                </button>
                <button mat-button (click)="deleteRow(element.id)" *ngIf="showDeleteButton(element)"
                  [disabled]="disableOfflineButton">
                  <mat-icon>delete</mat-icon> {{ 'button.delete' | translate }}
                </button>
                <button mat-icon-button (click)="downloadOffline(element)" *ngIf="showDownloadOfflineButton(element)">
                  <mat-icon>signal_wifi_off</mat-icon>
                </button>
                <button mat-button (click)="cloneRow(element.id)" *ngIf="canClone(element)">
                  <mat-icon>folder_copy</mat-icon> {{ 'button.clone' | translate }}
                </button>
                <button mat-icon-button (click)="cloneExecution(element.id)"  [disabled]="!showCanCloneExecution"
                    matTooltip="{{ 'button.clone-execution' | translate }}">
                <mat-icon>file_copy</mat-icon>
              </button>
              </ng-template>
            </mat-menu>

            <button mat-icon-button [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="element">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

      <span *ngIf="!hasFiltered" class="prefilterMessage">
        <p [innerHtml]="'common.listPlaceholder' | translate | safe: 'html'"> </p>
      </span>
    </div>

    <div class="table-footer-container">
      <div class="actions">
        <button mat-raised-button (click)="exportTable()" class="stroked-primary">
          <mat-icon fxHide.lt-sm>cloud_download</mat-icon> {{ 'button.downloadXls' | translate }}
        </button>
        <a [routerLink]="['.']" [fragment]="'table-container'" class="back-to-top accent-color"
          *ngIf="!isProtocolDetail">
          {{ 'button.backToTop' | translate }} </a>
      </div>
      <div class="paginator">
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
          [length]="dataSource.totalItems$ | async" lang="es">
        </mat-paginator>
      </div>
    </div>
  </form>
</div>