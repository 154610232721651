import { GenericFilter } from './genericClass';
import { RoleUser } from './roleUser';

export class User {
    username: string;
    email: string;
    fullName: string;
    position: string;

    active: boolean;

    activeProfile: string;
    activeApp: string;
    activeRole: string;

    activeRoleTranslation: string;

    idActiveProfile: number;
    idActiveApp: number;
    idActiveRole: number;

    admin: boolean;
    manager: boolean;
    hasToChangePass: boolean;

    unreadNotifications: number;

    idCertificate: number;

    profiles: RoleUser[];

    receiveEmails: boolean;

    manualSign: boolean;
    automaticSign: boolean;

    showCharacterization: boolean;
    showExternalEquipments: boolean;

    showCalibrationPlan: boolean;

    showClone: boolean;
    requireProcessApproval: boolean;
    showCanCloneExecutions: boolean;
    showCanCloneCharacterization: boolean;

    clientId: number;
}

export class CreateUser extends User {
    password: string;

    idCertificate: number;

    lockeds: UserLock[];
}

export class UserFilter extends GenericFilter {
    username: string;
    email: string;
    fullName: string;
    idGroup: number;
}

export class UserLock {
    ip: string;
    until: Date;
}

export class UserOfGroup {
    username: string;

    idsApp: number[];
    appNames: string[];

    idsRole: number[];
    roleTranslates: string[];
}