<div mat-dialog-content class="thermal-theme" style="overflow-y: hidden;">

  <div *ngFor="let essay of data.execution.essays; let indexEssay = index">

    <div *ngFor="let essayVal of essay.essayValues; let indexVariable = index">

      <div *ngIf="essayVal.bioindicatorsConfig != null" fxLayout="row" fxLayout.xs="column" class="flexwrap"
        style="min-height: 3em;">
        <div fxFlex="33" fxFlex.lt-md="100%">
          {{'essayType.' + essay.translationType | translate }}
        </div>

        <div fxFlex="33" fxFlex.lt-md="100%">
          <span> {{ 'executionEdit.bioindicators.valid' | translate }} </span>
          <mat-radio-group [(ngModel)]="essayVal.bioindicatorsConfig.valid" name="validBioindicators{{essayVal.id}}">
            <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
            <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
          </mat-radio-group>
        </div>

        <div fxFlex="33" fxFlex.lt-md="100%">
          <button mat-raised-button color="accent" (click)="showDialogConfigBioindicator(indexEssay, indexVariable)">
            {{ 'button.configureBioindicators' | translate }}
          </button>
        </div>

        <br />
      </div>

      <div *ngIf="essayVal.endotoxinsConfig != null" fxLayout="row" fxLayout.xs="column" class="flexwrap"
        style="min-height: 3em;">
        <div fxFlex="33" fxFlex.lt-md="100%">
          {{'essayType.' + essay.translationType | translate }}
        </div>

        <div fxFlex="33" fxFlex.lt-md="100%">
          <span> {{ 'executionEdit.endotoxins.valid' | translate }} </span>
          <mat-radio-group [(ngModel)]="essayVal.endotoxinsConfig.valid" name="validEndotoxins{{essayVal.id}}">
            <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
            <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
          </mat-radio-group>
        </div>

        <div fxFlex="33" fxFlex.lt-md="100%">
          <button mat-raised-button color="accent" (click)="showDialogConfigEndotoxin(indexEssay, indexVariable)">
            {{ 'button.configureEndotoxins' | translate }}
          </button>
        </div>

        <br />
      </div>

    </div>
  </div>

</div>

<div mat-dialog-actions align="end" class="thermal-theme">
  <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
  <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
</div>