<h1 mat-dialog-title>{{ 'isothermalCharacterizationEdit.sensorConfig.title' | translate }}</h1>
<form>

  <div mat-dialog-content class="calibrates-theme" style="min-height: 10vh;">

    <div fxLayout="column">
      <div fxFlex>
        <mat-slide-toggle class="example-margin" color="primary" name="outer" [(ngModel)]="data.sensor.outer"
          [checked]="data.sensor.outer" (change)="onOuterChange($event)">
          {{ 'executionEdit.sensorConfig.outer.label' | translate }}
        </mat-slide-toggle>
      </div>
      <div fxFlex>
        <mat-slide-toggle class="example-margin" color="primary" name="evaluate" [(ngModel)]="data.sensor.evaluate"
          [checked]="data.sensor.evaluate" (change)="onEvaluateChange($event)" [disabled]="data.isLastSensor">
          {{ 'isothermalCharacterizationEdit.sensorConfig.evaluate.label' | translate }}
        </mat-slide-toggle>
      </div>
      <div fxFlex *ngIf="!data.sensor.evaluate">
        <textarea rows="5" matAutosizeMinRows="5" matInput
          placeholder="{{ 'isothermalCharacterizationEdit.sensorConfig.evaluateReason.label' | translate }}"
          [(ngModel)]="data.sensor.evaluateReason" name="evaluateReason" required style="resize: none;"></textarea>
      </div>

      <div fxFlex *ngIf="hasLocation">
        {{ 'executionEdit.sensorConfig.location.label' | translate }}:
        <mat-form-field class="campoFormulario">
          <input matInput name="location" [(ngModel)]="data.sensor.location" required
            placeholder="{{ 'executionEdit.sensorConfig.location.label' | translate }}">
          <mat-error>
            {{ 'executionEdit.sensorConfig.location.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex *ngIf="!hasLocation">
        <mat-form-field class="campoFormulario">
          <input matInput type="number" required name="trayNum" [(ngModel)]="data.sensor.trayNum" min="1"
            placeholder="{{ 'isothermalCharacterizationEdit.sensorConfig.trayNum.label' | translate }}">
          <mat-error>
            {{ 'isothermalCharacterizationEdit.sensorConfig.trayNum.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex *ngIf="!hasLocation">
        <mat-form-field class="campoFormulario">
          <input matInput type="number" required name="sensorTray" [(ngModel)]="data.sensor.sensorTray" min="1"
            placeholder="{{ 'isothermalCharacterizationEdit.sensorConfig.sensorTray.label' | translate }}">
          <mat-error>
            {{ 'isothermalCharacterizationEdit.sensorConfig.sensorTray.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

  </div>

  <div mat-dialog-actions class="calibrates-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <div fxFlex="5px"></div>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>