import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalibrationReportFilter } from 'src/app/model/attachment';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ThemeService } from 'src/app/services/theme.service';
import { LangFlag, TranslationService } from 'src/app/services/translation.service';

export interface DialogDataLang {
  title: string;
}

@Component({
  selector: 'app-language-dialog',
  templateUrl: './language-dialog.component.html'
})
export class LanguageDialogComponent implements OnInit {

  reportConfig: CalibrationReportFilter;
  langs: LangFlag[];
  lang: string;

  constructor(
    private themeService: ThemeService,
    private translationService: TranslationService,
    public dialogRef: MatDialogRef<LanguageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataLang,
    public snackBarService: SnackBarService) { }

  ngOnInit(): void {

    this.lang = this.translationService.currentLang;
    this.langs = this.translationService.configuredLangs;
  }

  onOkClick(): void {
    const errs = [];

    if (errs.length === 0) {
      this.dialogRef.close(this.lang);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showChangeLangIcon(): boolean {
    return this.langs.length > 1;
  }

  getTheme(): string {
    return this.themeService.getTheme();
  }

}
