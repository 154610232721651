<div class="main-container">
    <form #procedureListForm="ngForm">
      <div class="toolbar-list">
  
        <div class="toolbar-list-title">
          <div fxLayout="row" fxLayoutAlign="start center">
            <h1>{{ 'procedure.title' | translate }}</h1>
          </div>
  
          <div class="breadcrumbs">
            <a href="#">{{ 'common.start.label' | translate }}</a> /
            <span>{{ 'procedure.title' | translate }}</span>
          </div>
        </div>
  
        <div class="toolbar-list-actions">
          <button mat-raised-button (click)="openDialogProcedure()"
            matTooltip="{{ 'button.disabled.offline' | translate }}">
            <mat-icon fxHide.lt-sm>add</mat-icon> {{ 'button.new' | translate }}
          </button>
        </div>
  
        <div class="toolbar-back-button">
          <a (click)="cancel()" mat-button>
            <mat-icon>undo</mat-icon> {{ 'button.back' | translate }}
          </a>
        </div>
      </div>
  
      <app-procedure-list-filter (filterEmitter)="doFilter($event)" class="filter-container">
      </app-procedure-list-filter>
  
      <div class="table-container">
        <table #procedures mat-table matSort [dataSource]="dataSource">
  
          <!-- client Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'procedure.cols.name' | translate }} </th>
            <td mat-cell *matCellDef="let item"> {{item?.name}} </td>
          </ng-container>
  
          <!-- documentCode Column -->
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'procedure.cols.description' | translate }} </th>
            <td mat-cell *matCellDef="let item"> {{item?.description}} </td>
          </ng-container>
  
          <!-- Button edit Column -->
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
              <button mat-icon-button (click)="editDialogProcedure(element)" matTooltip="{{ 'button.edit' | translate }}">
                <mat-icon>mode_edit</mat-icon>
              </button>
            </td>
          </ng-container>
  
          <!-- Button delete Column -->
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
              <div matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton">
                <button mat-icon-button (click)="deleteRow(element.id)"
                  [disabled]="disableOfflineButton" matTooltip="{{ 'button.delete' | translate }}">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
        </table>
  
        <span *ngIf="!hasFiltered" class="prefilterMessage">
          <p [innerHtml]="'common.listPlaceholder' | translate | safe: 'html'"> </p>
        </span>
      </div>
  
      <div class="table-footer-container">
        <div class="paginator">
          <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
            [length]="dataSource.totalItems$ | async" lang="es">
          </mat-paginator>
        </div>
      </div>
    </form>
  </div>