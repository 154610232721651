<h1 mat-dialog-title>{{ 'protocolEdit.dialog.generateSign.title' | translate }}</h1>
<form>

    <div mat-dialog-content class="thermal-theme" style="overflow-y: hidden;">

        <div fxLayout="column">
            <div fxFlex>
                {{ 'protocolEdit.dialog.generateSign.signType.label' | translate }}:
                <div *ngFor="let item of signTypes">
                    <mat-checkbox (change)="onChangeSignTypeCheck($event)" [value]="item"
                        [checked]="isSignTypeChecked(item)" [disabled]="disableSignType">
                        {{ 'signType.' + item.translation | translate }}
                    </mat-checkbox>
                </div>
            </div>

            <div fxFlex>
                <mat-form-field class="campoFormulario">
                    <mat-label>{{ 'common.reportType.options.reviewBy.label' | translate }}</mat-label>
                    <mat-select name="respDocs" [(ngModel)]="nextSign" (selectionChange)="onNextSignChange($event)">
                        <mat-select-filter [displayMember]="'fullName'" [array]="respDocs"
                            (filteredReturn)="respDocsFiltered =$event"
                            [noResultsMessage]="'common.noResults' | translate"
                            [placeholder]="'common.selectFilterPlaceholder' | translate"> </mat-select-filter>
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let item of respDocsFiltered" [value]="item.username">
                            {{item.fullName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex *ngIf="showLang">
                <mat-form-field class="campoFormulario" *ngIf="showChangeLangIcon()">
                    <mat-label>{{ 'common.reportType.options.language.label' | translate }}</mat-label>
                    <mat-select name="lang" [(ngModel)]="reportConfig.lang">
                        <mat-option *ngFor="let item of langs" [value]="item.lang">
                            <mat-icon svgIcon="{{item.flag}}"></mat-icon>
                            {{item.fullLang}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

    </div>

    <br /><br />

    <div mat-dialog-actions class="thermal-theme">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick(false)" cdkFocusInitial>{{ getOkLabel() }}</button>
        <div fxFlex="5px" *ngIf="!this.nextSign"></div>
        <button mat-raised-button *ngIf="!this.nextSign" color="primary" (click)="onOkClick(true)" cdkFocusInitial>{{'button.hybride' | translate}}</button>
        <div fxFlex="5px"></div>
        <button mat-raised-button color="accent" *ngIf="canRefuse"
            (click)="refuseSign()">{{ 'button.refuse' | translate }}</button>
        <div fxFlex="5px"></div>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>

</form>