<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form>
  <div mat-dialog-content class="air-theme">

    <div fxLayout="row" class="flexwrap">
      <div fxFlex="45" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput [(ngModel)]="data.folder.name" name="name" required
            placeholder="{{ 'protocolEdit.dialog.area.name.label' | translate }}">
          <mat-error>
            {{ 'protocolEdit.dialog.area.name.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="45" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'calibrationPlan.folderInternalEquipment.dialog.father' | translate }}</mat-label>
          <mat-select name="unit" [(ngModel)]="data.folder.idFather">
              <mat-option *ngFor="let item of filteredEquipmentFolders" [value]="item.id">
                  {{item.name}}
              </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <mat-toolbar color="secondary">
      {{ 'calibrationPlan.folderInternalEquipment.dialog.linkedTeams' | translate }}
    </mat-toolbar>
    <mat-form-field class="campoFormulario">
      <input matInput
          placeholder="{{ 'calibrateEquipmentEdit.patterns.dialog.equipment.label' | translate }}"
          (keyup)="lookupEquipment($event)">
      <mat-error>
          {{ 'calibrateEquipmentEdit.patterns.dialog.equipment.error' | translate }}
      </mat-error>
  </mat-form-field>
      <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
        <div *ngFor="let eq of filteredEquipments" fxFlex="25">
          <mat-checkbox style="margin-left: 1em;" (change)="onChangeEquipmentCheck($event)" [value]="eq"
          [checked]="isEquipmentChecked(eq)">
            <span style="white-space: pre-line;">
              {{ eq.name}} ({{eq.serialNum}})
            </span>
          </mat-checkbox>
        </div>
      </div>

  </div>
  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>