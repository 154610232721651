import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppPlanType } from 'src/app/model/appPlanType';
import { PaymentsService } from 'src/app/services/payments.service';
import { Subject } from 'rxjs';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions, CreateTokenCardData } from '@stripe/stripe-js';
import { TranslationService } from 'src/app/services/translation.service';
import { CreatePayment } from 'src/app/model/payment';
import { Country } from 'src/app/model/country';
import { CountryService } from 'src/app/services/country.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';

export interface DialogDataPayment {
  idPlan: number;
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html'
})
export class PaymentComponent implements OnInit, OnDestroy {

  @ViewChild(StripeCardComponent, { static: true }) card: StripeCardComponent;

  plan: AppPlanType;

  payment: CreatePayment;

  countries: Country[];
  countriesFiltered: Country[];

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"IBM Plex Sans", "Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'es'
  };

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private paymentsService: PaymentsService,
    private stripeService: StripeService,
    private translationService: TranslationService,
    private countryService: CountryService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataPayment,
    public dialogRef: MatDialogRef<PaymentComponent>,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    this.payment = new CreatePayment();
  }

  ngOnInit(): void {
    this.spinnerService.show();

    const currentLang: any = this.translationService.currentLang;
    this.elementsOptions.locale = currentLang;

    this.translationService.lang$.pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.elementsOptions.locale = res;
      this.reloadCountries(this.countries, res as string);
    });

    this.countries = [];
    this.countriesFiltered = [];
    this.countryService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: Country[]) => this.reloadCountries(res, null));

    this.paymentsService.findOnePlan(this.data.idPlan).pipe(takeUntil(this.destroy$)).subscribe((res: AppPlanType) => {
      this.plan = res;

      this.spinnerService.hide();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  reloadCountries(res: Country[], lang: string):void {
    res = res.map(c => {
      c.name = this.translateCountry(c.id, lang);
      return c;
    }).filter(c => c.name != null);

    res = ArrayUtils.sortByField(res, 'name');

    this.countries = res;
    this.countriesFiltered = res;
  }

  onOkClick(): void {

    const errs = [];
    const fields = ['shortCompanyName', 'companyName', 'addressCompany', 'cifCompany',
      'postalCodeCompany', 'cityCompany', 'stateCompany', 'countryCompany', 'managerName',
      'managerSurname', 'managerEmail'];

    const payment = this.payment;

    fields.forEach(f => {
      if (payment[f] == null) {
        errs.push(this.translate.instant(`payment.${f}.error`));
      }
    });

    if (errs.length !== 0) {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);

      return;
    }

    const clientData: CreateTokenCardData = {
      name: payment.fullName,
      address_line1: payment.addressCompany,
      address_city: payment.cityCompany,
      address_country: this.translateCountry(payment.countryCompany, 'en'),
      address_state: payment.stateCompany,
      address_zip: payment.postalCodeCompany
    };

    this.spinnerService.show();

    this.stripeService.createToken(this.card.element, clientData).pipe(takeUntil(this.destroy$)).subscribe((result) => {
      if (result.token) {
        payment.token = result.token.id;
        payment.idPlan = this.data.idPlan;

        this.paymentsService.createUser(payment).pipe(takeUntil(this.destroy$)).subscribe(res => {
          this.snackBarService.sendSuccess(this.translate.instant('payment.complete.ok') as string);

          this.spinnerService.hide();
          this.dialogRef.close(res);
        }, () => {
          this.snackBarService.sendError(this.translate.instant('payment.complete.error') as string);
          this.spinnerService.hide();
        });

      } else if (result.error) {
        // Error creating the token
        console.log(result.error.message);
        this.spinnerService.hide();
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private translateCountry(iso: string, lang: string): string {
    return this.translationService.translateCountry(iso, lang);
  }

}
