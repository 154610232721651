import { BehaviorSubject, Observable, forkJoin, from, fromEvent, merge, of } from 'rxjs';

import { AccessoryFilterType } from '../model/accessoryFilterType';
import { EquipmentType } from '../model/equipmentType';
import { EssayType } from '../model/essayType';
import { FilterAirType } from '../model/filterAirType';
import { GenericClassTranslate } from '../model/genericClass';
import { Injectable } from '@angular/core';
import { InternalEquipmentStatusService } from './internalEquipmentStatus.service';
import { ProtocolStatusService } from './protocolStatus.service';
import { RequestCacheService } from './requestCache.service';
import { map } from 'rxjs/operators';

@Injectable()
export class OnlineService {

    essayTypes: EssayType[];
    equipmentTypesAir: EquipmentType[];
    roomTypes: GenericClassTranslate[];
    roomSubtypes: GenericClassTranslate[];
    filterAirTypes: FilterAirType[];
    accessoryFilterTypes: AccessoryFilterType[];

    public latestOnline: boolean;

    private onlineSubject = new BehaviorSubject<boolean>(true);
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public online$ = this.onlineSubject.asObservable();

    constructor(
        private protocolStatusService: ProtocolStatusService,
        private internalEquipmentStatusService: InternalEquipmentStatusService,
        private requestCacheService: RequestCacheService

    ) {
        this.online$ = merge(
            of(navigator.onLine),
            fromEvent(window, 'online').pipe(map(() => true)),
            fromEvent(window, 'offline').pipe(map(() => false))
        );
        this.online$.subscribe(res => this.latestOnline = res);
    }

    downloadCacheableData(): Observable<unknown[]> {
        const obs = [];

        obs.push(this.protocolStatusService.findAll());
        obs.push(this.internalEquipmentStatusService.findAll());

        return forkJoin(obs);
    }

    executeActions(callback: () => void): void {
        this.requestCacheService.executeActions(callback);
    }

    countPendingActions(): Observable<number> {
        return from(this.requestCacheService.countPendingActions());
    }

}
