import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalibrationReportFilter } from 'src/app/model/attachment';
import { Calibration } from 'src/app/model/calibration';
import { InternalEquipment } from 'src/app/model/internalEquipment';
import { CalibrationService } from 'src/app/services/calibration.service';
import { InternalEquipmentService } from 'src/app/services/internalEquipment.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LangFlag, TranslationService } from 'src/app/services/translation.service';
import { CalibrationUtils } from 'src/app/utils/calibratesUtils';
import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';

export interface DialogDataManualSignCalibration {
  calibration: Calibration;
}

@Component({
  selector: 'app-calibration-edit-manual-sign-report',
  templateUrl: './calibration-edit-manual-sign-report.component.html'
})
export class CalibrationEditManualSignReportComponent implements OnInit, OnDestroy {

  reportConfig: CalibrationReportFilter;
  langs: LangFlag[];

  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CalibrationEditManualSignReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataManualSignCalibration,
    private calibrationService: CalibrationService,
    private internalEquipmentService: InternalEquipmentService,
    private translate: TranslateService,
    private translationService: TranslationService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.reportConfig = new CalibrationReportFilter();
    this.reportConfig.lang = this.translationService.currentLang;

    this.langs = this.translationService.configuredLangs;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  downloadDocument(): void {
    this.spinnerService.show();

    this.reportConfig.reason = this.data.calibration.reason;

    this.calibrationService.downloadPdf(this.data.calibration.id, this.reportConfig).pipe(takeUntil(this.destroy$))
      .subscribe((res: File) => {
        this.downloadFile(this.data.calibration, res);
      }, () => {
        this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.dialog.generateReport.form.error.generic') as string);
        this.spinnerService.hide();
      });
  }

  onOkClick(event): void {
    const file = event.target.files[0] as File;

    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {

      if (token != null) {
        this.spinnerService.show();

        this.calibrationService.manualSign(this.data.calibration, file, token).pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.snackBarService.sendSuccess(this.translate.instant('calibrateEquipmentEdit.form.sign.ok') as string);

          this.spinnerService.hide();

          this.dialogRef.close(token);
        }, () => {
          this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.form.sign.error') as string);

          this.spinnerService.hide();
        });
      } else {
        this.spinnerService.hide();
      }
    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showChangeLangIcon(): boolean {
    return this.langs.length > 1;
  }

  private downloadFile(calibration: Calibration, file: File) {
    this.spinnerService.show();

    this.internalEquipmentService.findOne(calibration.idEquipment).pipe(takeUntil(this.destroy$))
      .subscribe((equipment: InternalEquipment) => {
        const name = CalibrationUtils.getNameForCalibration(this.data.calibration, equipment, this.translate);
        saveAs(file, name);
        this.spinnerService.hide();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.dialog.generateReport.form.error.generic') as string);
        this.spinnerService.hide();
      });
  }

}
