<form [formGroup]="changePasswordForm">

  <mat-card class="card">
    <mat-card-header>
      <mat-card-title>
        <mat-toolbar color="secondary" style="width: 95vw;">
          {{ 'changePassword.title' | translate }}
        </mat-toolbar>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column" style="max-width: 500px; margin: 0 auto;">
        <div fxFlex="100">
          <mat-form-field class="campoFormulario">
            <input matInput type="password" placeholder="{{ 'changePassword.currentPassword.label' | translate }}"
              formControlName="currentPassword" [(ngModel)]="currentPassword" autocomplete="current-password" required
              [errorStateMatcher]="matcher">
            <mat-error>
              {{ 'changePassword.currentPassword.error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="100">
          <mat-form-field class="campoFormulario">
            <input matInput type="password" placeholder="{{ 'changePassword.newPassword.label' | translate }}"
              formControlName="newPassword" [(ngModel)]="newPassword" autocomplete="new-password" required
              [errorStateMatcher]="matcher">
            <mat-error *ngIf="changePasswordForm.get('confirmPassword').hasError('required')">
              {{ 'changePassword.newPassword.error.required' | translate }}
            </mat-error>
            <mat-error *ngIf="changePasswordForm.get('confirmPassword').hasError('pattern')">
              {{ 'changePassword.newPassword.error.pattern' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="100">
          <mat-form-field class="campoFormulario">
            <input matInput type="password" placeholder="{{ 'changePassword.confirmPassword.label' | translate }}"
              formControlName="confirmPassword" [(ngModel)]="confirmPassword" autocomplete="new-password" required
              [errorStateMatcher]="matcher">
            <mat-error *ngIf="changePasswordForm.get('confirmPassword').hasError('required')">
              {{ 'changePassword.confirmPassword.error.required' | translate }}
            </mat-error>
            <mat-error *ngIf="changePasswordForm.get('confirmPassword').hasError('pattern')">
              {{ 'changePassword.confirmPassword.error.pattern' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div fxLayout="column" fxLayoutAlign="center center">
        <div fxFlex></div>
        <button mat-raised-button color="primary"
          (click)="changePassword()">{{ 'button.changePassword' | translate }}</button>
      </div>
    </mat-card-actions>
  </mat-card>

</form>