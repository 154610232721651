import { HttpClient } from '@angular/common/http';

import { Defrost } from '../model/defrost';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class DefrostService {

    constructor(private http: HttpClient) {
    }

    findAllFromEssay(idEssay: number): Observable<any> {
        const url = environment.thermalUrl + `/defrost/essay/${idEssay}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOne(id: number): Observable<any> {
        const url = environment.thermalUrl + `/defrost/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    save(defrost: Defrost): Observable<any> {
        let url = environment.thermalUrl + '/defrost/';

        if (defrost.id != null) {
            url += defrost.id;

            return this.http.put(url, defrost).pipe(map(httpRes => HttpUtils.extractData(httpRes)))
        } else {
            url += 'create';

            return this.http.post(url, defrost).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        }
    }

    delete(id: number): Observable<any> {
        const url = environment.thermalUrl + `/defrost/${id}`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
