import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ExecutionQTestDeviations } from 'src/app/model/execution';
import { UserService } from '../../../../services/user.service';
import { User } from '../../../../model/user';
import { SnackBarService } from '../../../../services/snackBar.service';
import { ExecutionEditQDeviationComponent } from '../execution-edit-q-deviation/execution-edit-q-deviation.component';
import { Subject, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface DialogDataDeviationList {
  
  
  deviations: ExecutionQTestDeviations[];
}
@Component({
  selector: 'app-execution-edit-q-deviation-list',
  templateUrl: './execution-edit-q-deviation-list.component.html',
  styleUrls: ['./execution-edit-q-deviation-list.component.scss']
})
export class ExecutionEditQDeviationListComponent implements OnInit {

  deviation: ExecutionQTestDeviations;
  displayedColumns = ['code','modDate','modUser'];
  private destroy$ = new Subject<void>();
  currentUser: User;
  totalItems: number;
  dataSource : MatTableDataSource<ExecutionQTestDeviations>;
 
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private dialogRef: MatDialogRef<ExecutionEditQDeviationListComponent>,
    private userService: UserService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataDeviationList) { }

    ngOnInit(): void {
      this.currentUser = this.userService.currentProfile;
      this.dataSource = new MatTableDataSource(this.data.deviations);
      this.totalItems = this.data.deviations.length;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.paginator.length = this.totalItems;
    }
  
    
    ngAfterViewInit(): void {
    
      this.loadPageData();
    }
  
    loadPageData(): void {
      const start = this.paginator.pageIndex * this.paginator.pageSize;
      const end = start + this.paginator.pageSize;

      this.dataSource.data = this.data.deviations.slice(start, end);
    }
  
    onPageChange(event: PageEvent): void {
      const start = event.pageIndex * event.pageSize;
      const end = start + event.pageSize;
  
      this.dataSource.data = this.data.deviations.slice(start, end);
    }



//MOSTRARIA LA DESVIACION SELECCIONADA
  showDeviationSelected(indexItem: number) {
 
		const resSelected = this.data.deviations[indexItem];

		if (resSelected) {
			const dialogRef = this.dialog.open(ExecutionEditQDeviationComponent, {
				minWidth: '70%',
				maxHeight: '95vh',
				data: {
					
					deviation: _.cloneDeep(resSelected)
				}
			});
	
			dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ExecutionQTestDeviations) => {
				if (result) {
					this.data.deviations[indexItem] = result;
				}
			});
		}
	}

  onOkClick(){
    this.dialogRef.close(this.data.deviations);
  }
  onNoClick() {
    this.dialogRef.close(null);
  }

}
