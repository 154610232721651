import { Component, OnInit, ViewChild } from '@angular/core';
import { ManageNotificationFilter, NotificationManage } from 'src/app/model/notification';
import { Subject, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { Constants } from 'src/app/utils/constants';
import { Location } from '@angular/common';
import { ManageNotificationDataSource } from 'src/app/model/manageNotificationDataSource';
import { ManageNotificationsService } from 'src/app/services/manageNotifications.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner.service';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html'
})
export class NotificationListComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: ManageNotificationDataSource;
  filter: ManageNotificationFilter = new ManageNotificationFilter();

  currentUser: User;

  displayedColumns: string[] = ['name', 'users', 'edit'];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private location: Location,
    private userService: UserService,
    private notificationsService: ManageNotificationsService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.currentUser = this.userService.currentProfile;

    this.loadNotificationList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadNotificationList())).pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  new(): void {
    void this.router.navigateByUrl('/admin/notification');
  }

  getUsers(item: NotificationManage): string {
    if (item == null || item.notifiedUsers == null) {
      return '';
    }

    return item.notifiedUsers.join(', ');
  }

  editRow(id: number): void {
    void this.router.navigateByUrl(`/admin/notification?id=${id}`);
  }

  loadNotificationList(): void {
    this.dataSource = new ManageNotificationDataSource(this.notificationsService);

    this.filter.sortBy = this.sort.active || Constants.FIELD_ID;
    this.filter.sortDirection = this.sort.direction || 'asc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 5;

    this.dataSource.loadNotifications(this.filter);
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  cancel(): void {
    this.location.back();
  }

}
