<div mat-dialog-content [class]="getTheme()" style="white-space: pre-line;">

    <video class="videoPlayer" controls (click)="toggleVideo()" #videoPlayer autoplay>
        <source type="video/mp4" />
        Browser not supported
    </video>

</div>
<div mat-dialog-actions [class]="getTheme()">
    <div fxFlex></div>
    <button mat-raised-button color="secondary" (click)="onNoClick()">
        {{ 'button.back' | translate }}
    </button>
</div>