import { CommonModule } from '@angular/common';
import { FixDate } from './fixDate.pipe';
import { FixDateTime } from './fixDateTime.pipe';
import { NgModule } from '@angular/core';
import { Safe } from './safe.pipe';
import { FixDateUTC } from './fixDateUTC.pipe';

@NgModule({
  declarations: [Safe, FixDate, FixDateTime, FixDateUTC],
  imports: [
    CommonModule
  ],
  providers: [Safe, FixDate, FixDateTime, FixDateUTC],
  exports: [Safe, FixDate, FixDateTime, FixDateUTC]
})
export class PipesModule { }
