import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { User, UserFilter } from 'src/app/model/user';

import { Component } from '@angular/core';
import { Constants } from 'src/app/utils/constants';
import { ManageNotificationsService } from 'src/app/services/manageNotifications.service';
import { ManageUsersService } from 'src/app/services/manageUsers.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationManage } from 'src/app/model/notification';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notification-edit',
  templateUrl: './notification-edit.component.html'
})
export class NotificationEditComponent {

  notification: NotificationManage;
  currentUser: User;

  allUsers: string[] = [];
  notifiedUsers: string[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationsService: ManageNotificationsService,
    private manageUsersService: ManageUsersService,
    private userService: UserService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    this.currentUser = this.userService.currentProfile;

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const id = params[Constants.FIELD_ID] as number;

      if (id != null) {
        this.loadGroup(id);
      }
    });
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  save(): void {
    this.notification.notifiedUsers = this.notifiedUsers;

    this.spinnerService.show();

    this.notificationsService.update(this.notification).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.snackBarService.sendSuccess(this.translate.instant('notificationEdit.form.update.ok') as string);

      this.spinnerService.hide();

      this.reloadPage(this.notification.id);
    }, () => {
      this.snackBarService.sendError(this.translate.instant('notificationEdit.form.update.error.generic') as string);

      this.spinnerService.hide();
    });
  }

  cancel(): void {
    void this.router.navigateByUrl('/admin/notifications');
  }

  private reloadPage(id: number): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    void this.router.navigateByUrl(`/admin/notification?id=${id}`);
  }

  private loadGroup(id: number): void {
    this.spinnerService.show();

    this.notificationsService.findOne(id).pipe(takeUntil(this.destroy$)).subscribe((result: NotificationManage) => {
      if (result !== null && result !== undefined) {
        this.notification = result;

        if (this.notification == null) {
          this.cancel();
        }

        this.allUsers = [];
        this.notifiedUsers = [];

        const filter = new UserFilter();
        filter.idGroup = this.currentUser.idActiveProfile;

        this.manageUsersService.findAll(filter).subscribe(users => {
          (users.content as User[]).filter(u => u.active).map(u => u.username)
            .forEach(u => (this.notification.notifiedUsers.includes(u) ? this.notifiedUsers : this.allUsers).push(u));

          this.notification.notifiedUsers.filter(u => !this.notifiedUsers.includes(u)).forEach(u => this.notifiedUsers.push(u));

          this.spinnerService.hide();
        });

      } else {
        this.notification = null;
      }
    }, () => this.cancel());
  }

}
