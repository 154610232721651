import * as moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';

import { TranslationService } from '../services/translation.service';

@Pipe({
  name: 'fixDateUTC'
})
export class FixDateUTC implements PipeTransform {

  constructor(private translateService: TranslationService) {

  }

  transform(value: Date | string | any, optionFormat?: number): any {

    if (value == null) {
      return value;
    }

    if (value instanceof Date && isNaN(value.getTime())) {
      return null;
    }

    let formatDate = 'DD/MMM/YYYY';
    if (optionFormat === 2) {
      formatDate = 'DD/MM/YYYY';
    }

    moment.locale(this.translateService.currentLang);

    return moment.utc((value as Date)).local().format(formatDate).replace('./', '/').toUpperCase();
  }

}
