<div class="main-container">
  <form #protocolListForm="ngForm">
    <div class="toolbar-list" *ngIf="data.isList">

      <div class="toolbar-list-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Resource-/-Icon-/-Protocolos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <g id="clipboard" transform="translate(8.000000, 4.000000)" stroke="#00ACC7" stroke-width="2">
                    <path d="M24,4 L28,4 C30.209139,4 32,5.790861 32,8 L32,36 C32,38.209139 30.209139,40 28,40 L4,40 C1.790861,40 0,38.209139 0,36 L0,8 C0,5.790861 1.790861,4 4,4 L8,4" id="Path"></path>
                    <rect id="Rectangle" x="8" y="0" width="16" height="8" rx="2"></rect>
                </g>
            </g>
        </svg>
          <h1 class="accent-color">{{ 'protocolreferenceQList.title' | translate }}</h1>
        </div>

        <div class="breadcrumbs">
          <a href="#">{{ 'common.start.label' | translate }}</a> / <span>{{ 'protocolreferenceQList.title' | translate }}</span>
        </div>
      </div>

      <div class="toolbar-list-actions">
        <button mat-raised-button (click)="new()" color="accent"
          matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton"
          [disabled]="disableOfflineButton">
          <mat-icon fxHide.lt-sm>add</mat-icon> {{ 'button.new' | translate }}
        </button>
      </div>

      <div class="toolbar-back-button">
        <a (click)="cancel()" mat-button color="accent">
          <mat-icon color="accent">undo</mat-icon> {{ 'button.back' | translate }}
        </a>
      </div>
    </div>

    <div class="table-container">
      <table #protocols mat-table matSort [dataSource]="dataSource">

        <!-- code Column -->
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'protocolList.cols.code' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.code}} </td>
        </ng-container>

        <!-- name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'protocolList.cols.name' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.name }} </td>
        </ng-container>

        <!-- Button edit Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button (click)="editRow(element.id)" matTooltip="{{ 'button.edit' | translate }}">
              <mat-icon>mode_edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

    </div>

    <div class="table-footer-container">
      <div class="actions">
        <a [routerLink]="['.']" [fragment]="'table-container'" class="back-to-top accent-color" *ngIf="data.isList">
          {{ 'button.backToTop' | translate }} </a>
      </div>

    </div>
  </form>
</div>
