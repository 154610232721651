<h1 mat-dialog-title></h1>
<form>
  <div mat-dialog-content [class]="getTheme()">

    <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'calibrateEquipmentEdit.asLeft.cols.point.label' | translate }}" name="point"
            [(ngModel)]="data.item.point">
          <mat-error>
            {{ 'calibrateEquipmentEdit.asLeft.cols.point.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'calibrateEquipmentEdit.asLeft.cols.average.label' | translate }}"
            name="average" [(ngModel)]="data.item.average">
          <mat-error>
            {{ 'calibrateEquipmentEdit.asLeft.cols.average.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput name="correction" disabled [ngModel]="data.item.correction"
            placeholder="{{ 'calibrateEquipmentEdit.asLeft.cols.correction.label' | translate }}">
          <mat-error>
            {{ 'calibrateEquipmentEdit.asLeft.cols.correction.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'calibrateEquipmentEdit.uncertainties.cols.U.label' | translate }}"
            name="uncertainty" [(ngModel)]="data.item.uncertainty">
          <mat-error>
            {{ 'calibrateEquipmentEdit.uncertainties.cols.U.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

    </div>

  </div>
  <div mat-dialog-actions class="calibrates-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()"
      cdkFocusInitial>{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>