<div fxLayout="row" class="flexwrap">

    <div fxFlex="50" fxFlex.lt-md="100%">

        <!-- STRING -->
        <div *ngIf="data.field.idTypeField == 1">
            <mat-form-field class="campoFormulario">
                <input matInput disabled="true" (ngModelChange)="setValue(tab.idVariable, field, -1, $event)"
                    [required]="data.field.required" [name]="data.field.translationField"
                    [ngModel]="data.field.valueProtocol" [errorStateMatcher]="matcher"
                    placeholder="{{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.label' | translate }} {{ 'common.inProtocol' | translate }}">
                <mat-error>
                    {{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.error' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <!-- NUMÉRICO -->
        <div *ngIf="data.field.idTypeField == 2">
            <mat-form-field class="campoFormulario">
                <input matInput disabled="true" [name]="data.field.translationField"
                    [ngModel]="data.field.valueProtocol" [errorStateMatcher]="matcher"
                    (ngModelChange)="setValue(tab.idVariable, field, -1, $event)" type="number"
                    [required]="data.field.required"
                    placeholder="{{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.label' | translate }} {{ 'common.inProtocol' | translate }}">
                <mat-error>
                    {{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.error' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <!-- TOLERANCIA -->
        <div *ngIf="data.field.idTypeField == 3">
            <div fxFlex="35%" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput disabled="true" [name]="data.field.translationField" type="number"
                        [required]="data.field.required" [ngModel]="data.field.valueProtocolArray[0]"
                        placeholder="{{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.label' | translate }} {{ 'common.inProtocol' | translate }}">
                    <mat-error>
                        {{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="35%" fxFlex.lt-md="100%">
                ±
                <mat-form-field class="campoFormulario">
                    <input matInput disabled="true" [name]="data.field.translationField + 'Tolerance'" type="number"
                        [required]="data.field.required" [ngModel]="data.field.valueProtocolArray[1]"
                        placeholder="{{ 'protocolEdit.dialog.essay.valueTolerance.label' | translate }} {{ 'common.inProtocol' | translate }}">
                    <mat-error>
                        {{ 'protocolEdit.dialog.essay.valueTolerance.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <!-- DIA HORA MIN -->
        <div *ngIf="data.field.idTypeField == 4">
            <div fxLayout="row">
                <div fxFlex="32%" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                        <input matInput [name]="data.field.translationField + 'DayProt'" disabled="true" type="number"
                            [required]="data.field.required" [ngModel]="data.field.valueProtocolArray[0]"
                            placeholder="{{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.label' | translate }}: {{ 'common.days.label' | translate }} {{ 'common.inProtocol' | translate }}">
                    </mat-form-field>
                </div>
                <div fxFlex="32%" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                        <input matInput [name]="data.field.translationField + 'HourProt'" type="number" disabled="true"
                            [required]="data.field.required" [ngModel]="data.field.valueProtocolArray[1]"
                            placeholder="{{ 'common.hours.label' | translate }}">
                    </mat-form-field>
                </div>
                <div fxFlex="32%" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                        <input matInput [name]="data.field.translationField + 'MinProt'" type="number" disabled="true"
                            [required]="data.field.required" [ngModel]="data.field.valueProtocolArray[2]"
                            placeholder="{{ 'common.minutes.label' | translate }}">
                    </mat-form-field>
                </div>
            </div>
        </div>

        <!-- SELECT_ONE -->
        <div *ngIf="data.field.idTypeField == 6">
            <span>
                {{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.label' | translate }}
                {{ 'common.inProtocol' | translate }}
            </span>
            <br />
            <mat-radio-group class="vertical-radio-group" [required]="data.field.required">
                <mat-radio-button class="vertical-radio-button" *ngFor="let doma of data.field.fieldDomain"
                    [value]="doma.value" disabled="true">
                    {{ 'protocolEdit.dialog.essay.' + doma.translation + '.label' | translate }}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <!-- SELECT_MULTI -->
        <div *ngIf="data.field.idTypeField == 7">
            <span>
                {{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.label' | translate }}
                {{ 'common.inProtocol' | translate }}
            </span>
            <mat-selection-list disabled="true">
                <mat-list-option *ngFor="let doma of data.field.fieldDomain" checkboxPosition="before" disabled="true"
                    [value]="doma.value" [selected]="isSelectedMulti(data.field.valueProtocol, doma.value)">
                    {{ 'protocolEdit.dialog.essay.' + doma.translation + '.label' | translate }}
                </mat-list-option>
            </mat-selection-list>
        </div>

        <!-- BOOLEAN -->
        <div *ngIf="data.field.idTypeField == 8">
            <mat-checkbox [value]="data.field.valueProtocol" disabled="true">
                {{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.label' | translate }}
                {{ 'common.inProtocol' | translate }}
            </mat-checkbox>
        </div>

    </div>

    <div fxFlex="50" fxFlex.lt-md="100%">

        <!-- STRING -->
        <div *ngIf="data.field.idTypeField == 1">
            <mat-form-field class="campoFormulario">
                <input matInput [name]="data.field.translationField" [(ngModel)]="data.field.value"
                    placeholder="{{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.label' | translate }}"
                    [errorStateMatcher]="matcher" (ngModelChange)="setValue(-1, $event)" [disabled]="data.disabled"
                    [required]="data.field.required">
                <mat-error>
                    {{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.error' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <!-- NUMÉRICO -->
        <div *ngIf="data.field.idTypeField == 2">
            <mat-form-field class="campoFormulario">
                <input matInput [name]="data.field.translationField" [(ngModel)]="data.field.value"
                    placeholder="{{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.label' | translate }}"
                    (ngModelChange)="setValue(-1, $event)" type="number" [disabled]="data.disabled"
                    [errorStateMatcher]="matcher" [required]="data.field.required">
                <mat-error>
                    {{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.error' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <!-- TOLERANCIA -->
        <div *ngIf="data.field.idTypeField == 3">
            <div fxFlex="40%" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput [name]="data.field.translationField" [(ngModel)]="data.field.value[0]" type="number"
                        placeholder="{{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.label' | translate }}"
                        (ngModelChange)="setValue(0, $event)" [errorStateMatcher]="matcher" [disabled]="data.disabled"
                        [required]="data.field.required">
                    <mat-error>
                        {{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="40%" fxFlex.lt-md="100%">
                ±
                <mat-form-field class="campoFormulario">
                    <input matInput placeholder="{{ 'protocolEdit.dialog.essay.valueTolerance.label' | translate }}"
                        [name]="data.field.translationField + 'Tolerance'" [(ngModel)]="data.field.value[1]"
                        (ngModelChange)="setValue(1, $event)" [errorStateMatcher]="matcher" type="number"
                        [disabled]="data.disabled" [required]="data.field.required">
                    <mat-error>
                        {{ 'protocolEdit.dialog.essay.valueTolerance.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <!-- DIA HORA MIN -->
        <div *ngIf="data.field.idTypeField == 4">
            <div fxLayout="row">
                <div fxFlex="34%" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                        <input matInput [name]="data.field.translationField + 'Day'" [(ngModel)]="data.field.value[0]"
                            (ngModelChange)="setValue(0, $event)" [errorStateMatcher]="matcher" type="number"
                            [disabled]="data.disabled" [required]="data.field.required" min="0"
                            placeholder="{{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.label' | translate }}: {{ 'common.days.label' | translate }}">
                    </mat-form-field>
                </div>
                <div fxFlex="33%" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                        <input matInput [name]="data.field.translationField + 'Hour'" [(ngModel)]="data.field.value[1]"
                            (ngModelChange)="setValue(1, $event)" [errorStateMatcher]="matcher" type="number"
                            [disabled]="data.disabled" [required]="data.field.required" min="0"
                            placeholder="{{ 'common.hours.label' | translate }}">
                    </mat-form-field>
                </div>
                <div fxFlex="33%" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                        <input matInput [name]="data.field.translationField + 'Minute'"
                            [(ngModel)]="data.field.value[2]" (ngModelChange)="setValue(2, $event)"
                            [errorStateMatcher]="matcher" type="number" [disabled]="data.disabled"
                            [required]="data.field.required" min="0"
                            placeholder="{{ 'common.minutes.label' | translate }}">
                    </mat-form-field>
                </div>
            </div>
        </div>

        <!-- SELECT_ONE -->
        <div *ngIf="data.field.idTypeField == 6">
            <span>
                {{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.label' | translate }}
            </span>
            <br />
            <mat-radio-group class="vertical-radio-group" [required]="data.field.required">
                <mat-radio-button class="vertical-radio-button" *ngFor="let doma of data.field.fieldDomain"
                    [value]="doma.value">
                    {{ 'protocolEdit.dialog.essay.' + doma.translation + '.label' | translate }}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <!-- SELECT_MULTI -->
        <div *ngIf="data.field.idTypeField == 7">
            <span>
                {{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.label' | translate }}
                <span *ngIf="data.field.required">*</span>
            </span>
            <mat-selection-list [disabled]="data.disabled" (selectionChange)="setValue(-1, $event)">
                <mat-list-option *ngFor="let doma of data.field.fieldDomain" checkboxPosition="before"
                    [disabled]="data.disabled" [value]="doma.value"
                    [selected]="isSelectedMulti(data.field.value, doma.value)">
                    {{ 'protocolEdit.dialog.essay.' + doma.translation + '.label' | translate }}
                </mat-list-option>
            </mat-selection-list>
        </div>

        <!-- BOOLEAN -->
        <div *ngIf="data.field.idTypeField == 8">
            <mat-checkbox [value]="data.field.value" [checked]="data.field.value == 'true'" [disabled]="data.disabled"
                (change)="setValue(-1, $event)">
                {{ 'protocolEdit.dialog.essay.' + data.field.translationField + '.label' | translate }}
            </mat-checkbox>
        </div>

    </div>

    <div fxFlex="100" *ngIf="showReason()">
        <textarea rows="5" matAutosizeMinRows="5" matInput placeholder="{{ 'common.reasonChange' | translate }}"
            [(ngModel)]="data.field.valueChangeReason" name="locationChangeReason" required
            style="resize: none;"></textarea>
    </div>

    <div fxLayout="column" class="flexwrap">
        <div fxFlex>
            <mat-checkbox name="showDiffInDeviations" [(ngModel)]="data.field.showDiffInDeviations">
                {{ 'protocolEdit.dialog.essay.criteria.showDiffInDeviations.label' | translate }}
            </mat-checkbox>
        </div>
        <div fxFlex>
            <mat-checkbox name="showDiffInObservations" [(ngModel)]="data.field.showDiffInObservations">
                {{ 'protocolEdit.dialog.essay.criteria.showDiffInObservations.label' | translate }}
            </mat-checkbox>
        </div>
    </div>

</div>

<div mat-dialog-actions class="thermal-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
</div>