import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileInfo } from 'src/app/model/attachment';
import { AttachmentThermalService } from 'src/app/services/attachmentThermal.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { FileUtils } from 'src/app/utils/fileUtils';
import { PhotoUtils } from 'src/app/utils/photoUtils';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';

export interface DialogDataAttachmentDetails {
  idProtocol: number;
  idAttachment: number;
  disableUpload: boolean;
}

@Component({
  selector: 'app-protocol-edit-photo-details',
  templateUrl: './protocol-edit-photo-details.component.html'
})
export class ProtocolEditPhotoDetailsComponent implements OnInit, OnDestroy {

  fileData: FileInfo;
  image: any;
  base64: ArrayBuffer | string;

  private destroy$ = new Subject<void>();

  constructor(
    private sanitizer: DomSanitizer,
    private attachmentThermalService: AttachmentThermalService,
    private translate: TranslateService,
    private imageCompress: NgxImageCompressService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataAttachmentDetails,
    public dialogRef: MatDialogRef<ProtocolEditPhotoDetailsComponent>,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.reloadAttachment();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  reloadAttachment(): void {
    this.fileData = new FileInfo();

    if (this.data.idAttachment == null || this.data.idAttachment === -1) {
      this.base64 = null;
      this.image = null;

      return;
    }

    this.spinnerService.show();

    this.attachmentThermalService.downloadPhotoEssayProtocol(this.data.idProtocol, this.data.idAttachment).pipe(takeUntil(this.destroy$))
      .subscribe((res: FileInfo) => this.loadPhoto(res), () => {
        this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.downloadImage.error.generic') as string);

        this.spinnerService.hide();
      });

  }

  downloadAttachment(): void {
    const blob = FileUtils.base64toBlob(this.fileData.contentString, this.fileData.contentType);
    saveAs(blob, this.fileData.filename);
  }

  deleteAttachment(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {
        message: this.translate.instant('protocolEdit.dialog.essay.photo.delete.confirm') as string
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: boolean) => {
      if (result === true) {
        this.data.idAttachment = -1;
        this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.dialog.essay.photo.delete.ok') as string);

        this.onNoClick();
      }
    });
  }

  uploadFile(event): void {

    const file = event.target.files[0] as File;

    if (file == null) {
      return;
    }

    this.spinnerService.show();

    PhotoUtils.fixImage(file, this.imageCompress, (res: File) => {

      this.attachmentThermalService.uploadGeneric(res).pipe(takeUntil(this.destroy$)).subscribe((item: number) => {
        this.data.idAttachment = item;

        this.reloadAttachment();

        this.spinnerService.hide();

        this.snackBarService.sendSuccess(this.translate.instant('executionEdit.essays.form.generalPhoto.ok') as string);
      }, () => {
        this.spinnerService.hide();

        this.snackBarService.sendError(this.translate.instant('executionEdit.essays.form.generalPhoto.error.generic') as string);
      });

    });
  }

  public base64ToImage(): SafeResourceUrl {
    if (this.base64 == null) {
      return '';
    }

    if (typeof this.base64 === 'string') {
      if (!this.base64.startsWith('data:image')) {
        this.base64 = 'data:image/jpg;base64,' + this.base64;
      }
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(this.base64 as string);
  }

  public rotateImageLeft(): void {
    this.spinnerService.show();

    this.attachmentThermalService.rotatePhotoEssayProtocolImageLeft(this.data.idProtocol, this.data.idAttachment)
      .pipe(takeUntil(this.destroy$)).subscribe((res: number) => {
        this.data.idAttachment = res;
        this.spinnerService.hide();
        this.reloadAttachment();
      });
  }

  public rotateImageRight(): void {
    this.spinnerService.show();

    this.attachmentThermalService.rotatePhotoEssayProtocolImageRight(this.data.idProtocol, this.data.idAttachment)
      .pipe(takeUntil(this.destroy$)).subscribe((res: number) => {
        this.data.idAttachment = res;
        this.spinnerService.hide();
        this.reloadAttachment();
      });
  }

  onNoClick(): void {
    this.dialogRef.close(this.data.idAttachment);
  }

  private loadPhoto(res: FileInfo) {
    this.fileData = res;
    this.image = res;

    const cthis = this;
    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result;

      let base64: ArrayBuffer | string;
      if (typeof dataUrl !== 'string') {
        base64 = dataUrl;
      } else {
        base64 = dataUrl.split(',')[1];
      }

      cthis.base64 = base64;

      cthis.spinnerService.hide();
    };

    const blob = FileUtils.base64toBlob(res.contentString, res.contentType);
    reader.readAsDataURL(blob);
  }

}
