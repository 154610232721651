<h1 mat-dialog-title>{{ 'protocolEdit.dialog.manualSign.title' | translate }}</h1>
<form>
    <div mat-dialog-content class="air-theme">

        <mat-form-field class="campoFormulario" *ngIf="data.showReason">
            <textarea matInput placeholder="{{ 'protocolEdit.dialog.confirmSave.reason.label' | translate }}"
                [(ngModel)]="data.reason" name="reason" required></textarea>
            <mat-error>
                {{ 'protocolEdit.dialog.confirmSave.reason.error' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div mat-dialog-actions class="air-theme">
        <div fxFlex></div>

        <button mat-raised-button (click)="downloadDocument()" color="accent">
            {{ 'button.download-document-without-watemark' | translate }}
        </button>

        <div fxFlex="5px"></div>

        <button mat-raised-button (click)="uploadDocument.click()"
            color="primary">{{ 'button.upload-signed-document' | translate }}</button>
        <input accept="application/pdf" hidden type="file" (change)="onOkClick($event)" #uploadDocument>

        <div fxFlex="5px"></div>

        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>

</form>