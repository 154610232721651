<div class="main-container">
  <form #internalEquipmentListForm="ngForm">

    <div class="toolbar-list">

      <div class="toolbar-list-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <img *ngIf="isOnline" fxHide.lt-sm src="{{ getThemeIconPath() }}internalEquipments.svg" alt="{{ pageTitle }}">
          <h1 class="accent-color">
            {{ pageTitle }}</h1>
        </div>
        <div class="breadcrumbs">
          <a href="#">{{ 'common.start.label' | translate }}</a> /
          <span>{{ pageTitle }}</span>
        </div>
      </div>

      <div class="toolbar-list-actions">
        <button mat-raised-button *ngIf="canSave()" (click)="new()" color="accent">
          <mat-icon fxHide.lt-sm>add</mat-icon> {{ 'button.new' | translate }}
        </button>

        <button mat-raised-button (click)="massiveUpload.click()" *ngIf="isInternal && canSave()" color="accent"
          class="stroked">
          <mat-icon fxHide.lt-sm>add</mat-icon> {{ 'button.massive-create' | translate }}
        </button>
        <input accept=".xlsx, .xls, .csv" hidden type="file" (change)="uploadMassive($event)" #massiveUpload>

        <button mat-raised-button (click)="massiveCertificateUpload.click()" *ngIf="isInternal && canSave()"
          color="accent" class="stroked">
          <mat-icon fxHide.lt-sm>cloud_upload</mat-icon> {{ 'button.massive-upload-cert' | translate }}
        </button>

        <input accept="application/pdf" hidden type="file" multiple (change)="uploadCertMassive($event)"
          #massiveCertificateUpload>
      </div>
      <div class="toolbar-back-button">
        <a (click)="cancel()" mat-button color="accent">
          <mat-icon color="accent">undo</mat-icon> {{ 'button.back' | translate }}
        </a>
      </div>
    </div>

    <app-internal-equipment-list-filter (filterEmitter)="doFilter($event)" class="filter-container">
    </app-internal-equipment-list-filter>

    <div class="table-container">
      <table #protocols mat-table matSort [dataSource]="dataSource" id="table-container">

        <!-- name Column -->
        <ng-container matColumnDef="name">
          <th fxShow mat-header-cell *matHeaderCellDef> {{ 'internalEquipmentList.cols.name' | translate }} </th>
          <td fxShow mat-cell *matCellDef="let item" [ngClass]="highlight(item)">
            {{ item.name }}
          </td>
        </ng-container>

        <!-- equipment Column -->
        <ng-container matColumnDef="equipment">
          <th mat-header-cell *matHeaderCellDef> {{ 'internalEquipmentList.cols.equipment' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)">
            <div style="white-space: pre-line;"> {{ item.equipment }} </div>
          </td>
        </ng-container>

        <!-- maker Column
        <ng-container matColumnDef="maker">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'internalEquipmentList.cols.maker' | translate }}
          </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{item.maker}} </td>
        </ng-container> -->

        <!-- model Column 
        <ng-container matColumnDef="model">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'internalEquipmentList.cols.model' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{item.model}} </td>
        </ng-container>-->

        <!-- serialNum Column -->
        <ng-container matColumnDef="serialNum">
          <th mat-header-cell *matHeaderCellDef> {{ 'internalEquipmentList.cols.serialNum' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)">{{ item.serialNum }} </td>
        </ng-container>

        <!-- certificateNum Column -->
        <ng-container matColumnDef="certificateNum">
          <th mat-header-cell *matHeaderCellDef> {{ 'internalEquipmentList.cols.certificateNum' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)" matTooltip="{{ item.certificateNum }}"
            matTooltipDisabled="disableTooltip(item)">{{ item.certificateNum }}
          </td>
        </ng-container>

        <!-- group Column -->
        <ng-container matColumnDef="group">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'internalEquipmentList.cols.group' | translate }}
          </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{ item.group }} </td>
        </ng-container>

        <!-- status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'internalEquipmentList.cols.status' | translate }}
          </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)">
            {{ 'internalEquipmentStatus.' + item.statusTranslation | translate }}
          </td>
        </ng-container>

        <!-- client Column -->
        <ng-container matColumnDef="client">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'internalEquipmentList.cols.client' | translate }}
          </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{ item.client?.name | translate }}
          </td>
        </ng-container>

        <!-- calibrateDate Column -->
        <ng-container matColumnDef="calibrateDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'internalEquipmentList.cols.calibrateDate' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{item.calibrateDate | fixDateUTC }} </td>
        </ng-container>

        <!-- expirationDate Column -->
        <ng-container matColumnDef="expirationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'internalEquipmentList.cols.expirationDate' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{item.expirationDate | fixDateUTC }} </td>
        </ng-container>

        <!-- supplier Column -->
        <ng-container matColumnDef="supplier">
          <th mat-header-cell *matHeaderCellDef> {{ 'internalEquipmentList.cols.supplier' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)" matTooltip="{{ item.supplier }}"
            matTooltipDisabled="disableTooltip(item)">{{ item.supplier }}
          </td>
        </ng-container>

        <!-- calibrateDate Column -->
        <ng-container matColumnDef="extendedDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'internalEquipmentList.cols.extendedDateCalibration' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)"> {{item.extendedExpirationDate | fixDate }} </td>
        </ng-container>

        <!-- Button download Column -->
        <ng-container matColumnDef="download">
          <th mat-header-cell *matHeaderCellDef colspan="3" fxHide.lt-lg>
            {{ 'internalEquipmentList.cols.actions' | translate }} </th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button *ngIf="element.idCertificate != null" (click)="downloadPdf(element)"
              matTooltip="{{ 'button.download' | translate }}">
              <mat-icon>cloud_download</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button edit Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button (click)="editRow(element.id)" matTooltip="{{ 'button.edit' | translate }}">
              <mat-icon>mode_edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button activate Column -->
        <ng-container matColumnDef="activate">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button *ngIf="!isDeactivate(element) && canSave()" (click)="deactivate(element)"
              class="active" matTooltip="{{ 'internalEquipmentList.cols.deactivate' | translate }}">
              <mat-icon class="activate">done</mat-icon>
            </button>
            <button mat-icon-button *ngIf="isDeactivate(element) && canSave()" (click)="activate(element)"
              matTooltip="{{ 'internalEquipmentList.cols.activate' | translate }}">
              <mat-icon class="deactivate">clear</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button clone Column -->
        <ng-container matColumnDef="clone">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button (click)="cloneRow(element.id)" matTooltip="{{ 'button.clone' | translate }}">
              <mat-icon>folder_copy</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button more Column -->
        <ng-container matColumnDef="more" stickyEnd>
          <th mat-header-cell *matHeaderCellDef fxHide fxShow.lt-lg></th>
          <td mat-cell *matCellDef="let element" fxHide fxShow.lt-lg class="action">

            <mat-menu #appMenu="matMenu" class="moreActions">
              <ng-template matMenuContent let-name="element">
                <button mat-button *ngIf="element.idCertificate != null" (click)="downloadPdf(element)">
                  <mat-icon>cloud_download</mat-icon> {{ 'button.download' | translate }}
                </button>
                <button mat-button (click)="editRow(element.id)">
                  <mat-icon>mode_edit</mat-icon> {{ 'button.edit' | translate }}
                </button>
                <button mat-button *ngIf="!isDeactivate(element) && canSave()" (click)="activate(element)"
                  class="active">
                  <mat-icon class="deactivate">clear</mat-icon> {{ 'internalEquipmentList.cols.activate' | translate }}
                </button>
                <button mat-button *ngIf="isDeactivate(element)  && canSave()" (click)="deactivate(element)">
                  <mat-icon class="activate">done</mat-icon> {{ 'internalEquipmentList.cols.deactivate' | translate }}
                </button>
                <button mat-button (click)="cloneRow(element.id)">
                  <mat-icon>folder_copy</mat-icon> {{ 'button.clone' | translate }}
                </button>
              </ng-template>
            </mat-menu>

            <button mat-icon-button [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="element">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

      <span *ngIf="!hasFiltered" class="prefilterMessage">
        <p [innerHtml]="'common.listPlaceholder' | translate | safe: 'html'"></p>
      </span>

    </div>

    <div class="table-footer-container">
      <div class="actions">
        <button mat-raised-button (click)="exportTable()" class="stroked-primary">
          <mat-icon fxHide.lt-sm>cloud_download</mat-icon> {{ 'button.downloadXls' | translate }}
        </button>
        <a [routerLink]="['.']" [fragment]="'table-container'" class="back-to-top accent-color">
          {{ 'button.backToTop' | translate }} </a>
      </div>
      <div class="paginator">
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
          [length]="dataSource.totalItems$ | async" lang="es">
        </mat-paginator>
      </div>
    </div>
  </form>
</div>