<h1 mat-dialog-title>{{'executionEdit.referenceDocs.title' | translate}}</h1>

<div mat-dialog-content class="thermal-theme">

  <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
    <div>
      <mat-form-field class="campoFormulario">
        <input matInput [(ngModel)]="data.refDoc.code" required [disabled]="data.disabled"
          placeholder="{{ 'executionEdit.referenceDocs.code.label' | translate }}"
          [ngModelOptions]="{standalone: true}">
        <mat-error>
          {{ 'executionEdit.referenceDocs.code.error' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex>
      <mat-form-field class="campoFormulario">
        <input matInput [(ngModel)]="data.refDoc.name" [disabled]="data.disabled" [ngModelOptions]="{standalone: true}"
          placeholder="{{ 'executionEdit.referenceDocs.name.label' | translate }}">
      </mat-form-field>
    </div>
  </div>

</div>

<div mat-dialog-actions align="end" class="getTheme()">
  <button mat-raised-button color="primary" (click)="onOkClick()" *ngIf="!data.disabled">
    {{ 'button.save' | translate }}</button>
  <button mat-raised-button color="secondary" (click)="onNoClick()">
    {{ 'button.cancel' | translate }}</button>
  </div>