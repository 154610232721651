<form [formGroup]="form">
    <mat-accordion>
        <mat-expansion-panel [expanded]="expandedFilter" (opened)="expandedFilter = true"
            (closed)="expandedFilter = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'protocolList.filter.title' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="campoFormulario">
                        <input matInput placeholder="{{ 'protocolList.filter.documentCode.label' | translate }}"
                            formControlName="documentCode" [(ngModel)]="filter.documentCode">
                    </mat-form-field>
                </div>
                <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="datepicker">
                        <input matInput [matDatepicker]="regFcEndPicker" formControlName="regFcEnd"
                            [ngModel]="filter.realizationDate" (blur)="saveDate('regFcEnd', $event)"
                            (dateChange)="saveDate('regFcEnd', $event)"
                            placeholder="{{ 'protocolList.filter.regFcEnd.label' | translate }}">
                        <mat-datepicker-toggle matSuffix [for]="regFcEndPicker"></mat-datepicker-toggle>
                        <mat-datepicker #regFcEndPicker [calendarHeaderComponent]="customDatepickerHeader">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
                <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                    <mat-form-field class="datepicker">
                        <input matInput [matDatepicker]="regFcEndPicker" formControlName="regFcEnd"
                            [ngModel]="filter.nextInterventionDate" (blur)="saveDate('regFcEnd', $event)"
                            (dateChange)="saveDate('regFcEnd', $event)"
                            placeholder="{{ 'protocolList.filter.regFcEnd.label' | translate }}">
                        <mat-datepicker-toggle matSuffix [for]="regFcEndPicker"></mat-datepicker-toggle>
                        <mat-datepicker #regFcEndPicker [calendarHeaderComponent]="customDatepickerHeader">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <mat-accordion>
                <mat-expansion-panel expanded="false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ 'protocolList.filter.advanced.title' | translate }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div fxLayout="row" fxLayout.xs="column" class="flexwrap">

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="campoFormulario">
                                <input matInput placeholder="{{ 'protocolList.filter.area.label' | translate }}"
                                    formControlName="areaName" [(ngModel)]="filter.areaName">
                            </mat-form-field>
                        </div>

                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="campoFormulario">
                                <mat-label>{{ 'protocolList.filter.equipment.label' | translate }}</mat-label>
                                <mat-select formControlName="equipment" [(ngModel)]="filter.equipment">
                                    <mat-option *ngFor="let item of equipmentTypes" [value]="item.id">
                                        {{ 'equipmentType.' + item.translation | translate }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    {{ 'protocolEdit.dialog.equipment.type.error' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="campoFormulario">
                                <input matInput formControlName="equipmentSerialNum"
                                    [(ngModel)]="filter.equipmentSerialNum"
                                    placeholder="{{ 'protocolList.filter.equipmentSerialNum.label' | translate }}">
                            </mat-form-field>
                        </div>
                        <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="50%">
                            <mat-form-field class="campoFormulario">
                                <input matInput placeholder="{{ 'protocolList.filter.identificative.label' | translate }}"
                                    formControlName="identificative" [(ngModel)]="filter.identificative">
                            </mat-form-field>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
                <button mat-raised-button (click)="doFilter()" color="primary">{{ 'button.filter' | translate
                    }}</button>
                <button mat-button (click)="cleanFilter()">{{ 'button.clean' | translate }}</button>
            </div>

        </mat-expansion-panel>
    </mat-accordion>
</form>