import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExternalCalibrationValue } from 'src/app/model/calibration';
import { ThemeService } from 'src/app/services/theme.service';

export interface DialogDataExternalCalibrationValue {
  item: ExternalCalibrationValue;
}

@Component({
  selector: 'app-internal-equipment-edit-create-calibration-value',
  templateUrl: './internal-equipment-edit-create-calibration-value.component.html'
})
export class InternalEquipmentEditCreateCalibrationValueComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogDataExternalCalibrationValue,
    public dialogRef: MatDialogRef<InternalEquipmentEditCreateCalibrationValueComponent>,
    public dialog: MatDialog,
    private themeService: ThemeService) { }

  onOkClick(): void {
    this.dialogRef.close(this.data.item);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTheme(): string {
    return this.themeService.getTheme();
  }

}
