<h1 mat-dialog-title>
  {{ 'executionEdit.events.title' | translate }}

  <button mat-icon-button color="accent" (click)="newDefrost()">
    <mat-icon>add</mat-icon>
  </button>
</h1>
<form>

  <div mat-dialog-content class="thermal-theme" style="min-height: 10vh;">
    <table #defrostTable mat-table matSort [dataSource]="defrosts" style="width: 100%;">

      <!-- index Column -->
      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'defrost.cols.index' | translate }}
        </th>
        <td mat-cell *matCellDef="let item; let index = index"> {{ index + 1 }} </td>
      </ng-container>

      <!-- startDate Column -->
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'defrost.cols.startDate' | translate }}
        </th>
        <td mat-cell *matCellDef="let item"> {{ item.startDate | fixDateTime }} </td>
      </ng-container>

      <!-- endDate Column -->
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'defrost.cols.endDate' | translate }}
        </th>
        <td mat-cell *matCellDef="let item"> {{ item.endDate | fixDateTime }}</td>
      </ng-container>

      <!-- type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'defrost.cols.type' | translate }}
        </th>
        <td mat-cell *matCellDef="let item"> {{ 'defrostType.' + item.translationType | translate }}</td>
      </ng-container>

      <!-- Button edit Column -->
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="editRow(element.id)">
            <mat-icon>mode_edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Button delete Column -->
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="deleteRow(element.id)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColsUsers"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColsUsers;"></tr>

    </table>
  </div>

  <div mat-dialog-actions class="thermal-theme">
    <div fxFlex></div>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.back' | translate }}</button>
  </div>

</form>