import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../model/user';
import { UserService } from '../services/user.service';

@Injectable()
export class HasToChangePasswordInterceptor implements CanActivate {

    constructor(private userService: UserService, private router: Router) { }

    canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {

        return this.userService.user$.pipe(take(1), map((user: User) => {
            if (user == null || user.username == null || (user.username != null && !user.hasToChangePass)) {
                return true;
            } else {
                void this.router.navigate(['/profile/changePassword']);
                return false;
            }
        }));
    }
}
