import { Location, ViewportScroller } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router, Scroll } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, filter, merge, takeUntil, tap } from 'rxjs';
import { GenericDocumentDataSource } from 'src/app/model/genericDocumentDataSource';
import { GenericDocumentListFilter } from 'src/app/model/genericDocumentListFilter';
import { User } from 'src/app/model/user';
import { GenericDocumentListService } from 'src/app/services/genericDocumentList.service';
import { OnlineService } from 'src/app/services/online.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ThemeService } from 'src/app/services/theme.service';
import { UserService } from 'src/app/services/user.service';
import { CustomerPortalGenericDocumentListFilterComponent } from './customer-portal-generic-document-list-filter/customer-portal-generic-document-list-filter.component';
import * as saveAs from 'file-saver';
import { DocumentListEnum } from 'src/app/utils/documentListUtils';
import { ExecutionAirUtils } from 'src/app/utils/executionAirUtils';
import { GenericDocumentList } from 'src/app/model/genericDocumentList';
import { CalibrationService } from 'src/app/services/calibration.service';
import { ExecutionService } from 'src/app/services/execution.service';
import { ExecutionAirService } from 'src/app/services/executionAir.service';
import { IsothermalCharacterizationService } from 'src/app/services/isothermalCharacterization.service';
import { ProtocolService } from 'src/app/services/protocol.service';
import { ProtocolAirService } from 'src/app/services/protocolAir.service';

export interface DialogDataProtocolListConfig {
  isList: boolean;
}

@Component({
  selector: 'app-customer-portal-generic-document-list',
  templateUrl: './customer-portal-generic-document-list.component.html'
})
export class CustomerPortalGenericDocumentListComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild('filterComponent', { static: true }) filterComponent: CustomerPortalGenericDocumentListFilterComponent;

  dataSource: GenericDocumentDataSource;
  filter: GenericDocumentListFilter = new GenericDocumentListFilter();

  currentUser: User;

  disableOfflineButton = false;

  hasFiltered = false;

  displayedColumns = ['documentCode', 'equipment', 'serialNum', 'area', 'realizationDate'];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private location: Location,
    private genericDocumentListService: GenericDocumentListService,
    private onlineService: OnlineService,
    private userService: UserService,
    private translate: TranslateService,
    private themeService: ThemeService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private executionAirService:ExecutionAirService,
              private protocolAirService: ProtocolAirService,
              private thermalService: ExecutionService,
              private protThermalService: ProtocolService,
              private characterizationService: IsothermalCharacterizationService,
              private calibrationService: CalibrationService,
    private viewportScroller: ViewportScroller,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataProtocolListConfig) {

    if (data.isList == null) {
      data.isList = true;
    }

    this.router.events.pipe(filter(e => e instanceof Scroll)).pipe(takeUntil(this.destroy$)).subscribe((e: Scroll) => {
      setTimeout(() => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
    });
  }

  get pageTitle(): string {
    return this.translate.instant('customer-portal-generic-document.title') as string;
  }

  ngOnInit(): void {
    this.currentUser = this.userService.currentProfile;

    if (this.data.isList) {
      this.displayedColumns.push('download');
    } else {
      this.displayedColumns.push('select');
      this.filterComponent.isList = this.data.isList;
    }

    this.onlineService.online$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.disableOfflineButton = !res;

      this.loadDocumentList();
    });

    this.disableOfflineButton = !this.onlineService.latestOnline;

    const excludedCols: string[] = [];

    if (!this.currentUser.showClone) {
      excludedCols.push('clone');
    }

    this.displayedColumns = this.displayedColumns.filter(c => !excludedCols.includes(c));
    const filterResult = localStorage.getItem('filter');

    if (filterResult) {
      this.hasFiltered = true;
      this.filter = JSON.parse(filterResult) as GenericDocumentListFilter;
    }
    this.loadDocumentList();
  }

  downloadDocumentByIdAndVersion(doc: GenericDocumentList): void {
    if (doc.dtype === DocumentListEnum.EXECUTION_AIR) {
      this.executionAirService.downloadPdfVersion(doc.id, doc.version).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        const name = ExecutionAirUtils.getNameForReportPdfVersion(doc.documentCode, doc.version.toString(), doc.area, this.translate);
        saveAs(res, name);
        this.spinnerService.hide();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.notFound') as string);
        this.spinnerService.hide();
      });
    } else if (doc.dtype === DocumentListEnum.PROTOCOL_AIR) {
      this.protocolAirService.downloadPdfVersion(doc.id, doc.version).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        const name = ExecutionAirUtils.getNameForReportPdfVersion(doc.documentCode, doc.version.toString(), doc.area, this.translate);
        saveAs(res, name);
        this.spinnerService.hide();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.notFound') as string);
        this.spinnerService.hide();
      });
    } else if (doc.dtype === DocumentListEnum.EXECUTION_THERMAL) {
        this.thermalService.downloadPdfVersion(doc.id, doc.version).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
          const name = ExecutionAirUtils.getNameForReportPdfVersion(doc.documentCode, doc.version.toString(), doc.area, this.translate);
          saveAs(res, name);
          this.spinnerService.hide();
        }, () => {
          this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.notFound') as string);
          this.spinnerService.hide();
        });
    }  else if (doc.dtype === DocumentListEnum.PROTOCOL_THERMAL) {
      this.protThermalService.downloadPdfVersion(doc.id, doc.version).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        const name = ExecutionAirUtils.getNameForReportPdfVersion(doc.documentCode, doc.version.toString(), doc.area, this.translate);
        saveAs(res, name);
        this.spinnerService.hide();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.notFound') as string);
        this.spinnerService.hide();
      });
    } else if (doc.dtype === DocumentListEnum.CHARACTERIZATION) {
      this.characterizationService.downloadPdfVersion(doc.id, doc.version).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        const name = ExecutionAirUtils.getNameForReportPdfVersion(doc.documentCode, doc.version.toString(), doc.area, this.translate);
        saveAs(res, name);
        this.spinnerService.hide();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.notFound') as string);
        this.spinnerService.hide();
      });
    } else if (doc.dtype === DocumentListEnum.CALIBRATES) {
      this.calibrationService.downloadPdfVersion(doc.id, doc.version).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
        const name = ExecutionAirUtils.getNameForReportPdfVersion(doc.documentCode, doc.version.toString(), doc.area, this.translate);
        saveAs(res, name);
        this.spinnerService.hide();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.notFound') as string);
        this.spinnerService.hide();
      });
    }
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadDocumentList())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  getThemeIconPath(): string {
    return this.themeService.getThemeIconPath();
  }

  doFilter(event: GenericDocumentListFilter): void {
    this.filter = event;

    this.hasFiltered = true;

    this.loadDocumentList();
  }

  loadDocumentList(): void {
    this.dataSource = new GenericDocumentDataSource(this.genericDocumentListService);

    this.filter.sortBy = this.sort.active || 'documentCode';
    this.filter.sortDirection = this.sort.direction || 'desc';
    this.filter.pageIndex = this.paginator?.pageIndex || 0;
    this.filter.pageSize = this.paginator?.pageSize || 10;
    this.filter.idClient = this.currentUser.clientId;
    
    if (this.hasFiltered) {
      this.saveSearchFilter(this.filter);
      this.dataSource.loadDocuments(this.filter);
    }
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  /* downloadPdf(protocol: ProtocolAir): void {
    this.dialog.open(ProtocolEditGenerateReportComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: { protocol }
    });
  } */

  cancel(): void {
    this.location.back();
  }

  saveSearchFilter(execution: GenericDocumentListFilter): void {
    localStorage.setItem('filter', JSON.stringify(execution));
  }

}
