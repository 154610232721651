import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IsothermalCharacterizationSensor } from 'src/app/model/isothermalCharacterization';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { StringUtils } from 'src/app/utils/stringUtils';

export interface DialogDataConfigSensors {
  locationByTrays: boolean;
  sensors: IsothermalCharacterizationSensor[];
}

@Component({
  selector: 'app-isothermal-characterization-edit-configure-sensors',
  templateUrl: './isothermal-characterization-edit-configure-sensors.component.html'
})
export class IsothermalCharacterizationEditConfigureSensorsComponent {

  totalSensors: number;

  trayNum: number;
  sensorTray: Map<number, number>;

  originalSensors: IsothermalCharacterizationSensor[];

  constructor(
    private dialogRef: MatDialogRef<IsothermalCharacterizationEditConfigureSensorsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataConfigSensors,
    private translate: TranslateService,
    private snackBarService: SnackBarService) {

    this.totalSensors = this.data.sensors.length;
    this.trayNum = Math.max(1, ...this.data.sensors.map(s => s.trayNum));
    this.originalSensors = [... this.data.sensors];

    this.sensorTray = new Map<number, number>();
    for (let i = 0; i < this.trayNum; i++) {
      const maxSensorNum = Math.max(0, ...this.data.sensors.filter(s => (s.trayNum - 1) === i).map(s => s.sensorTray));

      this.sensorTray.set(i, maxSensorNum);
    }
  }

  onTrayNumChange(value: number | string): void {
    this.sensorTray.forEach((val, i) => {
      if (i >= (+value)) {
        this.sensorTray.delete(i);
      }
    });

    for (let j = 0; j < value; j++) {
      if (!this.sensorTray.get(j)) {
        this.sensorTray.set(j, 0);
      }
    }
  }

  setNumSensor(event: number): void {
    if (event == null || isNaN(event) || event < 0) {
      return;
    }

    this.totalSensors = event;

    const currentSensorNum = this.data.sensors.length;

    let diff = this.totalSensors - currentSensorNum;

    if (diff < 0) {
      // Caso quitar sensores

      // Quitamos el signo negativo
      diff = diff * -1;

      this.data.sensors.splice(this.totalSensors, diff);
    } else if (diff > 0) {
      // Caso añadir sensores
      for (let i = 0; i < diff; i++) {
        this.data.sensors.push(new IsothermalCharacterizationSensor());
      }
    }
  }

  onOkClick(): void {
    const sensors: IsothermalCharacterizationSensor[] = [];

    const errs: string[] = [];

    if (this.data.locationByTrays) {
      this.sensorTray.forEach((val, i) => {

        for (let j = 0; j < val; j++) {
          const sensor = this.originalSensors.find(s => (s.trayNum - 1) === i && (s.sensorTray - 1) === j)
            || new IsothermalCharacterizationSensor();

          sensor.trayNum = (i + 1);
          sensor.sensorTray = (j + 1);
          sensor.location = null;

          sensors.push(sensor);
        }
      });

      this.data.sensors = sensors;
    } else {
      this.data.sensors.forEach(s => {
        s.trayNum = null;
        s.sensorTray = null;

        if (StringUtils.isEmpty(s.location)) {
          errs.push(this.translate.instant('executionEdit.sensorConfig.location.error') as string);
        }
      });
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data);
    } else {
      const error = errs.filter(ArrayUtils.unique).join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
