/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';

import { DexieService } from './dexieService';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { OnlineService } from './online.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ExecutionAttachmentFilter, ExecutionAuditFilter, ExecutionQ, ExecutionQFilter } from '../model/execution';

@Injectable()
export class ExecutionQService {

    constructor(private http: HttpClient,
        private dexieService: DexieService,
        private onlineService: OnlineService) {
    }

    findAll(filter: ExecutionQFilter): Observable<any> {
        const url = environment.qualificatesUrl + '/execution/';
        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getExcel(filter: ExecutionQFilter): Observable<any> {
        const url = environment.qualificatesUrl + '/execution/excel';

        return this.http.post(url, filter, { responseType: 'blob' });
    }

    findAllAssignable(filter: ExecutionQFilter): Observable<any> {
        const url = environment.qualificatesUrl + '/execution/assignable';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOne(id: number): Observable<any> {
        const url = environment.qualificatesUrl + `/execution/${id}`;
        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    save(execution: ExecutionQ, token: string): Observable<any> {

        return this.genericSave(execution, token, null);
    }
    
    delete(id: number): Observable<any> {

        const url = environment.qualificatesUrl + `/execution/${id}`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    private genericSave(execution: ExecutionQ, token: string, action?: string): Observable<any> {
        let url = environment.qualificatesUrl + '/execution/';

        if (execution.id != null && execution.id != 0) {
            url += execution.id;
            
            if (action != null) {
                url += action;
            }
            
            
            return this.http.put(url, execution).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            url += 'create';

            return this.http.post(url, execution).pipe(map(httpRes =>
                 HttpUtils.extractData(httpRes)));
        }
    }

    getAudit(id: number, filter: ExecutionAuditFilter): Observable<any> {
        
        if (this.onlineService.latestOnline) {
            const url = environment.qualificatesUrl + `/execution/${id}/audit`;
            return this.http.post(url, filter).pipe(map(httpRes =>  HttpUtils.extractData(httpRes)));
        } else {
            return from([]);
        }

    }

    getAttachments(id: number, filter: ExecutionAttachmentFilter): Observable<any> {
        const url = environment.qualificatesUrl + `/execution/${id}/attachment`;

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
    
    validate(idExecution: number, token: string, forceAccording: boolean, reasonForce: string): Observable<any> {
        const url = environment.airUrl + `/execution/${idExecution}/validate/`;

        const body = { forceAccording, reasonForce, token };

        return this.http.put(url, body).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }


}
