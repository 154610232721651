<h1 mat-dialog-title></h1>
<form>
  <div mat-dialog-content class="calibrates-theme">

    <div fxLayout="column" class="flexwrap">

      <div fxFlex>
        <mat-form-field class="campoFormulario">
          <input matInput type="number" name="point" [(ngModel)]="data.item.point" disabled
            placeholder="{{ 'calibrateEquipmentEdit.asLeft.dialog.point.label' | translate }}">
          <mat-error>
            {{ 'calibrateEquipmentEdit.asLeft.dialog.point.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex>
        <mat-form-field class="campoFormulario">
          <input matInput name="pattern" [(ngModel)]="data.item.pattern" required pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="{{ 'calibrateEquipmentEdit.asLeft.dialog.pattern.label' | translate }}">
          <mat-error>
            {{ 'calibrateEquipmentEdit.asLeft.dialog.pattern.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex>

        <span> {{ 'calibrateEquipmentEdit.asLeft.dialog.values.label' | translate }} </span>

        <div fxLayout="row" fxLayout.xs="column" class="flexwrap"
          *ngFor="let value of data.item.values; let indexValue = index">

          <div fxFlex="45" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
              <input matInput type="number" name="value{{indexValue}}" [(ngModel)]="value.value" required
                (blur)="setValue(indexValue, $event.target.value)"
                placeholder="{{ 'calibrateEquipmentEdit.asLeft.dialog.values.value.label' | translate }}">
              <mat-error>
                {{ 'calibrateEquipmentEdit.asLeft.dialog.values.value.error' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="5" fxFlex.lt-md="100%">
            <button mat-icon-button color="accent" (click)="removeValue(indexValue)"
              *ngIf="data.item.values.length > 1">
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </div>

        <div>
          <button mat-raised-button color="primary" (click)="newValue()">
            {{ 'button.new' | translate }}
          </button>
        </div>

      </div>

    </div>

  </div>
  <div mat-dialog-actions class="calibrates-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()"
      cdkFocusInitial>{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>