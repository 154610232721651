export class CriticalInstrumentationCommon {
    id: number;
    equipment: string;
    internalId: string;
    procediment: string;
}

export class CriticalInstrumentation extends CriticalInstrumentationCommon {
}

export class CriticalInstrumentationExecution extends CriticalInstrumentationCommon {
    maker: string;
    model: string;
    serialNum: string;
    certificateNum: string;
    idCertificate: number;

    equipmentProtocol: string;
    procedimentProtocol: string;

    reasonChange: string;
    showDiffInDeviations: boolean;
    showDiffInObservations: boolean;

    fromProtocol: boolean;
}
