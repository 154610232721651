import { HttpRequest } from '@angular/common/http';

export class StoredCacheData {
    id: number;
    name: string;
    expires: Date;
    data: any[];
}

export class StoredListData {
    id: number;
    type: string;
    expires: Date;
    idData: number;
    data: any;
}

export class StoredAction {
    id: number;
    date: Date;
    request: HttpRequest<any>;
}

export class ChangeOffline {
    id: number;
    type: string;
    changes: any;
    father_id: number | string;
    areaId: number;
    areaIdOffline: string;
    roomId: number;
    roomIdOffline: string;
    timestamp: string;
    filterIdOffline: string;
    execution_id: number;
    essayId: number;
    essayIdOffline: string;
    dateDeleted: string;
    deleted: boolean;
}

export class AttachedOffline {
    id: number;
    blob: string;
    idExecution: number;
    idEssay: number | string;
    idFilter: number | string;
    type: string;
    timestamp: string;
    fileName: string;
    dateDeleted: string;
    deleted: boolean;
}
