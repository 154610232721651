<form>
    <div mat-dialog-content class="qualificates-theme">
        <div fxFlex fxLayout="column">

            <div fxFlex="100" fxLayout="column">
                <div fxFlex="100" fxLayout="row" class="table-title">
                    <div fxFlex="100">
                        <div fxFlex="3">
                            <span>{{data.testQType.id}}</span>
                        </div>
                        <div fxFlex="97">
                            <input matInput name="title-essay" [(ngModel)]="data.data.testDescription">
                        </div>
                    </div>
                </div>

                <div *ngIf="testConfig?.testGeneralData">
                    <div fxLayout="column">
                        <div fxFlex="100" class="title-table-test">
                            <span>Objetivo</span>
                        </div>
                        <div fxLayout="row"
                            *ngFor="let value of dataSource.protocolQTestGeneralData; let indexTest = index">
                            <div fxFlex="100" class="table-item" style="margin-bottom: 80px">
                                <quill-editor [(ngModel)]="value.objective" [name]="'objective' + indexTest"
                                    [id]="'objective' + indexTest" [modules]="{ toolbar: quillConfig.toolbar }"
                                    style="height: 150px; font-weight: normal;"></quill-editor>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column">
                        <div fxFlex="100" class="title-table-test">
                            <span>Metodología</span>
                        </div>
                        <div fxLayout="row"
                            *ngFor="let value of dataSource.protocolQTestGeneralData; let indexTest = index">
                            <div fxFlex="100" class="table-item" style="margin-bottom: 80px">
                                <quill-editor [(ngModel)]="value.methodology" [name]="'methodology' + indexTest"
                                    [id]="'methodology' + indexTest" [modules]="{ toolbar: quillConfig.toolbar }"
                                    style="height: 150px; font-weight: normal;"></quill-editor>
                            </div>
                        </div>
                        <!--<div fxFlex="15" *ngIf="data.data.idTestType == 10 ">
                            <button class="btn-add" mat-raised-button color="primary" (click)="fileInput.click()">
                                {{ 'protocolTest.test.image' | translate }}
                                <mat-icon fxHide.lt-sm>add</mat-icon>
                            </button>
                            <input type="file" #fileInput (change)="onImageSelected($event)" style="display: none;">
                            <span class="file-name" *ngIf="fileName">{{ fileName }}</span>
                        </div>-->
                    </div>
                    <div fxLayout="column">
                        <div fxFlex="100" class="title-table-test">
                            <span>{{'protocolTest.test.criteria' | translate }}</span>
                        </div>
                        <div fxLayout="row"
                            *ngFor="let value of dataSource.protocolQTestGeneralData; let indexTest = index">
                            <div fxFlex="100" class="table-item" style="margin-bottom: 80px">
                                <quill-editor [(ngModel)]="value.criteria" [name]="'criteria' + indexTest"
                                    [id]="'criteria' + indexTest" [modules]="{ toolbar: quillConfig.toolbar }"
                                    style="height: 150px; font-weight: normal;"></quill-editor>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="testConfig?.testVerification">
                    <div fxLayout="column">
                        <div fxLayout="row"  class="table-item title-table-test">
                            <div fxFlex="100">
                                <div fxFlex="95" class="center-text">
                                    <span style="margin-right: 5px;">{{'protocolTest.test.verification.test.tittle' |
                                        translate}}</span>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="column" class="container-verification-data">
                            <div fxLayout="row"
                                *ngFor="let value of dataSource.protocolQTestVerification; let indexTest = index">
                                <div fxFlex="35" class="table-item " class="input-dinamyc-item">
                                    <mat-form-field class="campoFormulario">
                                        <mat-label>{{ 'protocolTest.test.verification.test.item.test' | translate
                                            }}</mat-label>
                                        <input matInput [name]="'test' + indexTest" [(ngModel)]="value.test">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="30" class="table-item">
                                    <mat-form-field class="campoFormulario">
                                        <mat-label>{{ 'protocolTest.test.verification.test.item.description' | translate
                                            }}</mat-label>
                                        <textarea matInput [name]="'descriptionTest' + indexTest"
                                            [(ngModel)]="value.descriptionTest" rows="5"></textarea>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="30" class="table-item" class="input-dinamyc-item">
                                    <mat-form-field class="campoFormulario">
                                        <mat-label>{{ 'protocolTest.test.verification.test.item.result' | translate
                                            }}</mat-label>
                                        <mat-select multiple [name]="'vResultVerification'+indexTest"
                                            [(ngModel)]="value.resultDesc">
                                            <mat-option *ngFor="let sel of resultQTypes" checkboxPosition="before"
                                                [value]="sel.translation">
                                                {{'protocolTest.result.'+sel.translation | translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="5">
                                    <button class="btn-delete-verification" mat-icon-button
                                        (click)="deleteTestVerification(indexTest)"
                                        matTooltip="{{ 'button.delete' | translate }}">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="margin-top: 20px;">
                    <div fxLayout="column">
                        <div fxLayout="row" style="padding: 20px 10px;" class="table-item title-table-test">
                            <div fxFlex="100">
                                <div fxFlex="100" class="center-text">
                                    <span>{{'protocolTest.test.verification.title' | translate }}</span>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="100" fxLayout="column" class="container-verification-data">
                            <div fxFlex="100" *ngFor="let item of dataSource?.protocolQTestItem; let indexItem = index"
                                fxLayout="column">
                                <div fxFlex="100">
                                    <div fxFlex="15" *ngIf="testConfig.item">
                                        <button class="btn-add" mat-raised-button (click)="newItemVeritication()">
                                            {{ 'Nuevo item' | translate }}
                                            <mat-icon fxHide.lt-sm>add</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div fxFlex="100" fxLayout="row" class="table-item">
                                    <div fxFlex="80" *ngIf="testConfig?.item">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'Item' | translate }}</mat-label>
                                            <input matInput [name]="'item'+indexItem" [(ngModel)]="item.description">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="5" *ngIf="testConfig?.item" [fxHide]="indexItem === 0">
                                        <button class="btn-delete-verification" mat-icon-button
                                            (click)="deleteRowItem(indexItem)"
                                            matTooltip="{{ 'button.delete' | translate }}">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                    <div fxFlex="20">
                                        <button class="btn-add" mat-raised-button
                                            (click)="newItemVerification(indexItem)">
                                            {{ 'Nueva verificación' | translate }}
                                            <mat-icon fxHide.lt-sm>add</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div cdkDropList [cdkDropListData]="item.protocolQTestDatas"
                                    class="verification-drop-list" (cdkDropListDropped)="drop($event, indexItem)">
                                    <div *ngFor="let valueItemV of item.protocolQTestItemVerification; let indexItemV = index"
                                        cdkDrag class="verification-drop-box">

                                        <div fxFlex="100" fxLayout="column" class="table-item">
                                            <div fxFlex="100" fxLayout="row">
                                                <div fxFlex="88">
                                                    <!-- Icono de arrastre (handler) -->
                                                    <div fxFlex="5" cdkDragHandle>
                                                        <span>
                                                            <mat-icon class="move-drag-cursorr">reorder</mat-icon>
                                                        </span>
                                                    </div>
                                                    <div fxFlex="95">
                                                        <mat-form-field class="campoFormulario">
                                                            <mat-label>{{ 'protocolTest.test.item.verification.item' |
                                                                translate }}</mat-label>
                                                            <textarea matInput
                                                                [name]="'verificationTypeDesc'+indexItem+indexItemV"
                                                                [(ngModel)]="valueItemV.verification"
                                                                rows="1"></textarea>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div fxFlex="7">
                                                    <button class="btn-add" mat-raised-button
                                                        (click)="newDataVerification(indexItem, indexItemV)">
                                                        <mat-icon fxHide.lt-sm>add</mat-icon>
                                                    </button>
                                                </div>
                                                <div fxFlex="5">
                                                    <button class="btn-delete-verification" mat-icon-button
                                                        (click)="deleteRowItemVerification(indexItem,indexItemV)"
                                                        matTooltip="{{ 'button.delete' | translate }}">
                                                        <mat-icon fxHide.lt-sm>delete</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                                *ngFor="let value of valueItemV.protocolQTestDataMultiple; let indexValue = index">
                                                <div fxFlex="100" fxLayout="row" class="table-item">
                                                    <div fxFlex="40" class="input-dinamyc-item"
                                                        *ngIf="testConfig?.expectedResult">
                                                        <mat-form-field class="campoFormulario">
                                                            <mat-label *ngIf="!value.expectedResultRhDesc">{{
                                                                'protocolTest.test.result.expected.multiple.item' |
                                                                translate }}</mat-label>
                                                            <mat-label *ngIf="value.expectedResultRhDesc">{{
                                                                value.expectedResultRhDesc
                                                                }}</mat-label>

                                                            <input matInput
                                                                [name]="'expectedResultDesc' +indexItem+indexItemV+indexValue"
                                                                [(ngModel)]="value.expectedResultDesc">
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="valueItemV.id" fxFlex="10">
                                                        <button mat-raised-button (click)="openVisualizerImage(value)"
                                                            class="stroked">
                                                            <mat-icon fxHide.lt-sm>add_photo_alternate</mat-icon>
                                                        </button>
                                                    </div>

                                                    <div fxFlex="40" class="input-dinamyc-item">
                                                        <mat-form-field class="campoFormulario">
                                                            <mat-label>{{ 'protocolTest.test.multiple.result.item' |
                                                                translate }}</mat-label>
                                                            <mat-select multiple
                                                                [name]="'sResultDesc'+indexItem+indexItemV+indexValue"
                                                                [(ngModel)]="value.resultDesc">
                                                                <mat-option *ngFor="let sel of resultQTypes"
                                                                    checkboxPosition="before" [value]="sel.translation">
                                                                    {{'protocolTest.result.'+sel.translation |
                                                                    translate}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <!--<div fxFlex="5" *ngIf="indexValue == 0">
                                                        <button class="btn-add without-border" mat-icon-button
                                                            (click)="addRowData(indexItem, indexItemV)"
                                                            matTooltip="{{ 'button.add' | translate }}">
                                                            <mat-icon>add</mat-icon>
                                                        </button>
                                                    </div>-->
                                                    <div fxFlex="5">
                                                        <button class="btn-delete-verification" mat-icon-button
                                                            (click)="deleteRowData(indexItem, indexItemV, indexValue)"
                                                            matTooltip="{{ 'button.delete' | translate }}">
                                                            <mat-icon>delete</mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div fxFlex="100" fxLayout="column" *ngIf="testConfig.testResult">
                <div fxLayout="column">

                    <div fxLayout="row" class="table-item title-table-test">
                        <div fxFlex="100">
                            <div fxFlex="95" class="center-text">
                                <span>{{'protocolTest.test.result.test.tittle' |
                                    translate}}</span>
                            </div>
                            <div fxFlex="5">
                                <button class="btn-add without-border" mat-icon-button (click)="newDataResult()">
                                    <mat-icon fxHide.lt-sm>add</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="100" fxLayout="column" class="container-verification-data">


                        <div *ngFor="let value of dataSource.protocolQTestResults; let indexValue = index">
                            <div fxFlex="100" fxLayout="row" class="table-item">
                                <div fxFlex="30">
                                    <mat-form-field class="campoFormulario">
                                        <mat-label>{{ 'protocolTest.test.item.verification.item' | translate
                                            }}</mat-label>
                                        <textarea matInput [name]="'verificationTypeDesc'+indexValue"
                                            [(ngModel)]="value.verification" rows="5"></textarea>
                                    </mat-form-field>
                                </div>

                                <div fxFlex="30" class="input-dinamyc-item">
                                    <mat-form-field class="campoFormulario"
                                        *ngFor="let itemResult of value.resultExpected; let indexEx = index">
                                        <mat-label>{{ 'protocolTest.test.result.expected.multiple.item' | translate
                                            }}</mat-label>
                                        <input matInput [name]="'resultExpected'+indexValue+indexEx"
                                            [ngModel]="value.resultExpected[indexEx]"
                                            (blur)="value.resultExpected[indexEx] = $event?.target?.value">
                                    </mat-form-field>
                                </div>

                                <div fxFlex="30" class="input-dinamyc-item">
                                    <mat-form-field class="campoFormulario">
                                        <mat-label>{{ 'protocolTest.test.multiple.result.item' | translate
                                            }}</mat-label>
                                        <mat-select multiple [name]="'sResult'+indexItemV+indexValue"
                                            [(ngModel)]="value.result">
                                            <mat-option *ngFor="let sel of resultQTypes" checkboxPosition="before"
                                                [value]="sel.translation">
                                                {{'protocolTest.result.'+sel.translation | translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="5">
                                    <button class="btn-delete-verification" mat-icon-button
                                        (click)="deleteRowDataResult(indexValue)"
                                        matTooltip="{{ 'button.delete' | translate }}">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="row" class="table-item title-table-test" *ngIf="showTestEssay()">
                <div fxFlex="100">
                    <div fxFlex="95" class="center-text">
                        <span> {{ 'protocolEdit.essays.title.generic' | translate }}</span>
                    </div>
                    <div fxFlex="5">
                        <button mat-icon-button class="btn-add without-border" (click)="newEssayOpenDialog()">
                            <mat-icon fxHide.lt-sm>add</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div fxFlex="100" fxLayout="column" class="container-verification-data"
                *ngIf="dataSource.protocolQTestEssayGroup?.length > 0">
                <table mat-table [dataSource]="dataSource.protocolQTestEssayGroup" style="min-width: 95%"  #essaysTable >

                    <!-- Type Column -->
                    <ng-container matColumnDef="essayType">
                        <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.essays.essay.label' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{ 'essayType.' + element.translationType |
                            translate }} </td>
                    </ng-container>

                    <!-- Variables Column -->
                    <ng-container matColumnDef="variables">
                        <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.essays.variables.label' | translate
                            }} </th>
                        <td mat-cell *matCellDef="let element"> {{ getVariablesFromEssay(element) }} </td>
                    </ng-container>

                    <!-- Criteria Column -->
                    <ng-container matColumnDef="criteria">
                        <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.essays.criteria.label' | translate
                            }} </th>
                        <td mat-cell *matCellDef="let element"> {{ getCriteriaFromEssay(element) }} </td>
                    </ng-container>

                    <!-- Load Column -->
                    <ng-container matColumnDef="load">
                        <th mat-header-cell *matHeaderCellDef> {{ 'protocolEdit.essays.load.label' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{ getLoadFromEssay(element) }} </td>
                    </ng-container>

                    <!-- Button see Column -->
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <button mat-icon-button (click)="editEssayOpenDialog(i)">
                                <mat-icon>mode_edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <!-- Button delete Column -->
                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <div matTooltip="{{ 'button.disabled.offline' | translate }}"
                                [matTooltipDisabled]="!disableOfflineButton">
                                <button mat-icon-button (click)="deleteEssayRow(i)" [disabled]="disableOfflineButton">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="essaysCols"></tr>
                    <tr mat-row *matRowDef="let row; columns: essaysCols;"></tr>
                </table>

            </div>

            <div fxFlex="15" *ngIf="testConfig?.probePlacement">
                <button class="btn-add" mat-raised-button color="primary" (click)="fileInput.click()">
                    {{ 'protocolTest.test.location.probe' | translate }}
                    <mat-icon fxHide.lt-sm>add_photo_alternate</mat-icon>
                </button>
                <input type="file" #fileInput (change)="onImageSelected($event)" style="display: none;">
                <span class="file-name" *ngIf="fileName">{{ fileName }}</span>
            </div>


            <div fxFlex>
                <app-protocol-q-edit-test-attachments #attachmentsTestComponent *ngIf="data.data.id !=null">
                </app-protocol-q-edit-test-attachments>
            </div>
            

            <div mat-dialog-actions class="qualificates-theme">
                <div fxFlex></div>
                <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
                <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate
                    }}</button>
            </div>

        </div>
    </div>
</form>