<mat-card class="card">
  <mat-card-content>

    <br />

    <table #executions mat-table matSort [dataSource]="dataSource" style="width: 100%;">

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'executionEdit.historical.cols.date' | translate }}
        </th>
        <td mat-cell *matCellDef="let item"> {{ item.date | fixDateTime }} </td>
      </ng-container>

      <!-- username Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'executionEdit.historical.cols.username' | translate }} </th>
        <td mat-cell *matCellDef="let item"> {{ item.username }} </td>
      </ng-container>

      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.historical.cols.status' | translate }} </th>
        <td mat-cell *matCellDef="let item"> {{ 'executionStatus.' + item.statusTranslation | translate }} </td>
      </ng-container>

      <!-- action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.historical.cols.action' | translate }} </th>
        <td mat-cell *matCellDef="let item"> {{ 'executionAuditAction.' + item.actionTranslation | translate }} </td>
      </ng-container>

      <!-- currentVersion Column -->
      <ng-container matColumnDef="currentVersion">
        <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.historical.cols.currentVersion' | translate }} </th>
        <td mat-cell *matCellDef="let item"> {{ item.currentVersion }} </td>
      </ng-container>

      <!-- changes Column -->
      <ng-container matColumnDef="changes">
        <th mat-header-cell *matHeaderCellDef> {{ 'executionEdit.historical.cols.changes' | translate }} </th>
        <td mat-cell *matCellDef="let item">
          <div style="white-space: pre-line;"> {{ item.changes }} </div>
        </td>
      </ng-container>

      <!-- Button download Column -->
      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef> {{ 'button.download' | translate }} </th>
        <td mat-cell *matCellDef="let item">
          <button mat-icon-button *ngIf="showDownloadSignedPdf(item)" (click)="downloadSignedPdf(item)">
            <mat-icon>cloud_download</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <div fxLayout="row" class="flexwrap">
      <div fxFlex>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" showFirstLastButtons
          [length]="dataSource.totalItems$ | async" lang="es">
        </mat-paginator>
      </div>
      <div fxFlex="45px">
        <button mat-icon-button (click)="downloadAuditPdf()" class="downloadAudit">
          <mat-icon>picture_as_pdf</mat-icon>
        </button>
      </div>
    </div>

  </mat-card-content>
  <br />
</mat-card>