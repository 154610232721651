<h1 mat-dialog-title>{{ 'userEdit.dialog.assignRole.title' | translate }}</h1>
<form [formGroup]="form">
    <div mat-dialog-content [class]="getTheme()">

        <div fxLayout="column">

            <div fxFlex>
                <mat-form-field class="campoFormulario">
                    <mat-label>{{ 'userEdit.dialog.assignRole.group.label' | translate }}</mat-label>
                    <mat-select required formControlName="idGroup" [(ngModel)]="data.roleUser.idGroup">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let item of groups" [value]="item.id">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{ 'userEdit.dialog.assignRole.group.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxFlex>
                <mat-form-field class="campoFormulario">
                    <mat-label>{{ 'userEdit.dialog.assignRole.role.label' | translate }}</mat-label>
                    <mat-select required formControlName="idRole" [(ngModel)]="data.roleUser.idRole">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let item of roles" [value]="item.id">
                            {{'role.' + item.translation | translate}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{ 'userEdit.dialog.assignRole.role.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxFlex>
                {{ 'userEdit.dialog.assignRole.appType.label' | translate }}:
                <div *ngFor="let item of appTypes">
                    <mat-checkbox (change)="onChangeAppTypeCheck($event)" [value]="item">
                        {{ item.name | translate }}
                    </mat-checkbox>
                </div>
            </div>

        </div>

    </div>
    <div mat-dialog-actions [class]="getTheme()">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>

</form>