import { Component, Inject, QueryList, ViewChildren } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccessoryEquipment } from 'src/app/model/accessoryEquipment';
import { AccessoryEquipmentType, AccessoryEquipmentTypeEnum } from 'src/app/model/accessoryEquipmenType';
import { AccessoryFilterType } from 'src/app/model/accessoryFilterType';
import { EquipmentAir } from 'src/app/model/equipment';
import { EquipmentType } from 'src/app/model/equipmentType';
import { ExecutionStatus } from 'src/app/model/execution';
import { FilterAir, FilterAirTypeEnum, ShapeEnum } from 'src/app/model/filterAir';
import { FilterAirType } from 'src/app/model/filterAirType';
import { AccessoryEquipmentTypeService } from 'src/app/services/accessoryEquipmentType.service';
import { AccessoryFilterTypeService } from 'src/app/services/accessoryFilterType.service';
import { FilterAirTypeService } from 'src/app/services/filterAirType.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { AccesoryFilterUtils } from 'src/app/utils/accesoryFilterUtils';
import { ArrayUtils } from 'src/app/utils/arrayUtils';
import { StringUtils } from 'src/app/utils/stringUtils';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { ProtocolEditDialogFilterComponent } from './protocol-edit-dialog-filter.component';
import { ReasonDialogComponent } from '../../shared/reason-dialog/reason-dialog.component';
import { User } from '../../../model/user';
import { UserService } from '../../../services/user.service';


export interface DialogDataEquipmentAir {
  equipmentTypes: EquipmentType[];
  equipment: EquipmentAir;
  isEdit: boolean;
  isExecution: boolean;
  idExecution: number;
  executionStatus: number;
}

@Component({
  selector: 'app-protocol-edit-dialog-equipment-air',
  templateUrl: './protocol-edit-dialog-equipment-air.component.html'
})
export class ProtocolEditDialogEquipmentAirComponent {

  @ViewChildren('filtersTable') filtersTable: QueryList<MatTable<any>>;

  filterAirTypes: FilterAirType[];
  accessoryFilterTypes: AccessoryFilterType[];
  accessoryEquipmentTypes: AccessoryEquipmentType[];
  showAccessoryOthers = false;
  speedVariatorType: string;
  othersValue: string;

  currentUser: User;

  public checked = new Map<number, boolean>();

  public displayedColsFilter = ['order', 'filter', 'size', 'changeFilter', 'edit', 'delete'];

  private destroy$ = new Subject<void>();
  private askForReason: boolean;

  constructor(
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    public dialog: MatDialog,
    private filterAirTypeService: FilterAirTypeService,
    private accessoryFilterTypeService: AccessoryFilterTypeService,
    private accessoryEquipmentTypeService: AccessoryEquipmentTypeService,
    private dialogRef: MatDialogRef<ProtocolEditDialogEquipmentAirComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataEquipmentAir) {

    this.currentUser = this.userService.currentProfile;

    if (this.data.isExecution == null) {
      this.data.isExecution = false;
    }

    if (!this.data.isExecution) {
      this.displayedColsFilter = this.displayedColsFilter.filter(a => a !== 'changeFilter');
    }

    if (ArrayUtils.isNotEmpty(this.data.equipment.filters)) {
      this.data.equipment.filters = this.data.equipment.filters.sort((f1, f2) => f1.id - f2.id);
    }
  }

  ngOnInit(): void {
    this.askForReason = this.data.equipment.modUser != null;

    this.filterAirTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: FilterAirType[]) => this.filterAirTypes = res);
    this.accessoryFilterTypeService.findAll().pipe(takeUntil(this.destroy$))
      .subscribe((res: AccessoryFilterType[]) => {
        this.accessoryFilterTypes = res;
        this.data.equipment.filters.forEach(filter => {
          if (filter.filterName == null) {
            filter.filterName = this.setFilterName(filter);
          }
        });
      });
    this.accessoryEquipmentTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((res: AccessoryEquipmentType[]) => {
      this.accessoryEquipmentTypes = res.sort((s1, s2) => s1.id - s2.id);
    });

    this.data.equipment.accessories?.forEach(accessory => {
      if (accessory.idType === AccessoryEquipmentTypeEnum.OTHERS) {
        this.showAccessoryOthers = true;
        this.othersValue = accessory.others;
      }
    });

  }

  onOkClick(): void {
    const accessoryEquipmentTypesIds = this.accessoryEquipmentTypes?.map(a => a.id);
    this.data.equipment.accessories = this.data.equipment.accessories?.filter(a => accessoryEquipmentTypesIds.includes(a.idType));

    const others = this.data.equipment.accessories.find(a => a.idType === AccessoryEquipmentTypeEnum.OTHERS);
    if (others != null) {
      others.others = this.othersValue;
    }

    const errs = this.validateForm();

    if (errs.length === 0) {
      if (!this.data.equipment.regFc) {
        this.data.equipment.regFc = new Date();
        this.data.equipment.regUser = this.currentUser.username;
      }

      this.data.equipment.modFc = new Date();
      this.data.equipment.modUser = this.currentUser.username;

      if (this.askForReason) {
        this.requestReason((reason) => {
          this.data.equipment.reasonChange = reason;
          this.dialogRef.close(this.data.equipment);
        });
      } else if (this.data.equipment.id != null && !this.askForReason) {
        this.requestReason((reason) => {
          this.data.equipment.reasonChange = reason;
          this.dialogRef.close(this.data.equipment);
        });
      } else {
        this.dialogRef.close(this.data.equipment);
      }
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openNewFilter(): void {
    const filter = new FilterAir();
    filter.idType = FilterAirTypeEnum.FILTER;
    filter.shape = ShapeEnum.RECTANGULAR;

    const dialogRef = this.dialog.open(ProtocolEditDialogFilterComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        accessoryFilterTypes: this.accessoryFilterTypes,
        filterTypes: this.filterAirTypes,
        filter,
        isEdit: false,
        isExecution: this.data.isExecution,
        idExecution: this.data.idExecution
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res: FilterAir) => {

      if (res != null) {
        res.filterName = this.setFilterName(res);
        this.data.equipment.filters.push(res);
        this.updateTables();
      }
    });
  }

  editFilter(index: number): void {
    const filter = this.data.equipment.filters[index];

    const dialogRef = this.dialog.open(ProtocolEditDialogFilterComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        accessoryFilterTypes: this.accessoryFilterTypes,
        filterTypes: this.filterAirTypes,
        filter: _.cloneDeep(filter),
        isEdit: true,
        isExecution: this.data.isExecution,
        idExecution: this.data.idExecution,
        executionStatus: this.data.executionStatus
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res: FilterAir) => {

      if (res != null) {
        res.filterName = this.setFilterName(res);
        this.data.equipment.filters[index] = res;
        this.updateTables();
      }
    });
  }

  removeFilter(index: number): void {
    const message = this.translate.instant('protocolEdit.dialog.filter.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (index > -1) {
          this.data.equipment.filters.splice(index, 1);
        }
        this.updateTables();
      }
    });
  }

  getOrderFilter(index: number): string {
    const filters = this.data.equipment.filters;

    let res = 0;

    for (let i = 0; i <= index; i++) {
      const filter = filters[i];

      if (filter.totalFiltersSecondStage > 1) {
        if (i === index) {
          return (res + 1).toString().concat('-').concat((index + filter.totalFiltersSecondStage).toString());
        }
        res += filter.totalFiltersSecondStage;
      } else {
        res++;
      }
    }

    return res.toString();
  }

  getFilterName(index: number): string {
    const filter = this.data.equipment.filters[index];

    if (StringUtils.isEmpty(filter.filterName)) {
      filter.filterName = this.setFilterName(filter);
    }

    return filter.filterName;
  }

  updateTables(): void {
    this.filtersTable.forEach(i => i.renderRows());
  }

  validateForm(): string[] {
    const errs: string[] = [];

    if (this.data.equipment.idType == null) {
      errs.push(this.translate.instant('common.equipmentAir.idType.error') as string);
    }
    if (this.data.equipment.maker == null || this.data.equipment.maker === '') {
      errs.push(this.translate.instant('common.equipmentAir.maker.error') as string);
    }
    if (this.data.equipment.model == null || this.data.equipment.model === '') {
      errs.push(this.translate.instant('common.equipmentAir.model.error') as string);
    }
    if (this.data.equipment.serialNum == null || this.data.equipment.serialNum === '') {
      errs.push(this.translate.instant('common.equipmentAir.serialNum.error') as string);
    }

    return errs;
  }

  getSizeOfFilter(filter: FilterAir): string {
    return AccesoryFilterUtils.getSizeOfFilter(filter);
  }

  getChangeFilter(filter: FilterAir): string {
    return StringUtils.isNotEmpty(filter?.maker) ? `${filter.maker} (${filter.serialNum})`
      : this.translate.instant('common.notApplicable') as string;
  }

  onAccesoryChange(event: MatCheckboxChange): void {
    const idSelected = +event.source.value;
    if (event.checked) {
      const selected = this.accessoryEquipmentTypes.find(a => a.id === idSelected);

      if (selected != null) {
        const a = new AccessoryEquipment();
        a.idEquipment = this.data.equipment.id;
        a.idType = selected.id;
        this.data.equipment.accessories.push(a);
      }
    } else {
      this.data.equipment.accessories = this.data.equipment.accessories.filter(a => a.idType !== idSelected);
    }

    this.showAccessoryOthers = this.data.equipment.accessories.find(a => a.idType === AccessoryEquipmentTypeEnum.OTHERS) != null;
  }

  isAccesoryChecked(item: AccessoryEquipment): boolean {
    return this.data.equipment.accessories?.map(a => a.idType).includes(item.id);
  }

  enableEditEquipment(): boolean {
    const allowedStatuses = [ExecutionStatus.EN_EJECUCION];
    if (this.data.executionStatus != null) {
      return allowedStatuses.includes(this.data.executionStatus)
    }
    return true;
  }

  private requestReason(callback: (reason: string) => void) {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

  private setFilterName(filter: FilterAir): string {
    let res: string = null;

    if (filter.idTypeFilter != null) {
      const type = this.accessoryFilterTypes?.find(a => a.id === filter.idTypeFilter);

      if (type != null) {
        res = 'filterType.' + type.translation;
      }
    } else {
      const type = this.filterAirTypes.find(a => a.id === filter.idType);
      if (type != null) {
        res = 'filterType.' + type.translation;
      }
    }

    return res;
  }

}
