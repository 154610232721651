import { DataValueEssay } from './dataValueEssay';

export class EssayExecutionResultAir {
    id: number;

    idCriteria: number;
    idEssay: number;
    value: string;
    reference: string;
    idFilter: number;

    informative: boolean;
    aerosol = false;
    data: DataValueEssay[] = [];
    fromRoom = false;
    showLocations: boolean;
    uuidFilter: string;
    isChanneled: boolean;
    dataReset?: DataValueEssay[] = [];

}

export class EssayExecutionAirResultParticles {
    id: number;

    date: Date;
    location: string;
    samplingTime: number;
    volume: number;

    zeroOne: number;
    zeroTwo: number;
    zeroThree: number;
    zeroFive: number;
    oneZero: number;
    fiveZero: number;

    alarm: string;
    flow: string;
    laser: string;
    service: string;

    unit: string;

}

export class ResultExcelParticles {
    particles: EssayExecutionAirResultParticles[] = [];
    serialNum: string;
}
