import { CreateUser, UserFilter } from '../model/user';
import { Observable, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { LoginAccessFilter } from '../model/loginAccess';
import { RoleUser } from '../model/roleUser';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ManageUsersService {

    constructor(private http: HttpClient) {
    }

    findAll(filter: UserFilter): Observable<any> {
        const url = environment.coreUrl + '/manageUsers/';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findOne(id: string): Observable<any> {
        const url = environment.coreUrl + '/manageUsers/' + id;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getLogins(filter: LoginAccessFilter): Observable<any> {
        const url = environment.coreUrl + '/manageUsers/' + filter.username + '/logins';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getRoles(id: string): Observable<any> {
        const url = environment.coreUrl + '/manageUsers/' + id + '/roles';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getAssignableRoles(): Observable<any> {
        const url = environment.coreUrl + '/manageUsers/roles';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getAssignableGroups(): Observable<any> {
        const url = environment.coreUrl + '/manageUsers/groups';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    save(user: CreateUser): Observable<any> {
        const url = environment.coreUrl + `/manageUsers/${user.username.toLowerCase()}`;

        return this.http.put(url, user).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    unlockUser(username: string): Observable<any> {
        const url = environment.coreUrl + '/manageUsers/' + username + '/unlock';

        return this.http.put(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    caducatePasswordUser(username: string): Observable<any> {
        const url = environment.coreUrl + '/manageUsers/' + username + '/caducatePassword';

        return this.http.put(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    create(user: CreateUser): Observable<any> {
        const url = environment.coreUrl + '/manageUsers/create';

        return this.http.post(url, user).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    assignRoles(username: string, roles: RoleUser[]): Observable<any> {
        const url = environment.coreUrl + '/manageUsers/' + username + '/roles';

        return this.http.post(url, roles).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    activate(id: string, activate: boolean): Observable<any> {
        return this.findOne(id).pipe(map((item: CreateUser) => {
            const user = item;
            user.active = activate;
            return this.save(user);
        }, err => {
            return of(err);
        }));
    }

    downloadAuditPdf(username: string): Observable<any> {
        const url = environment.thermalUrl + '/report/user/' + username + '/audit';

        return this.http.get(url, { responseType: 'blob' });
    }

    checkIfHasUser(idClient: number): Observable<any> {
        const url = environment.coreUrl + '/manageUsers/checkIfHasUser/' + idClient;

        return this.http.get(url, { }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
