import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ReferenceDocument } from 'src/app/model/referenceDocument';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ThemeService } from 'src/app/services/theme.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';

export interface DialogDataRefDoc {
  refDoc: ReferenceDocument;
  isEdit: boolean;
  disabled: boolean;
}

@Component({
  selector: 'app-edit-reference-docs',
  templateUrl: './edit-reference-docs.component.html'
})
export class EditReferenceDocsComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogDataRefDoc,
    public dialogRef: MatDialogRef<EditReferenceDocsComponent>,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private themeService: ThemeService) { }

  onOkClick(): void {
    const errors = [];

    const item = this.data.refDoc;

    if (item.code == null) {
      errors.push(this.translate.instant('protocolEdit.referenceDocs.form.error.code'));
    }

    if (ArrayUtils.isEmpty(errors)) {
      this.dialogRef.close(item);
    } else {
      const error = errors.join('\n');
      this.snackBarService.sendError(error);
      console.error(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTheme(): string {
    return this.themeService.getTheme();
  }

}
