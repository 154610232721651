<div fxLayout="row" class="flexwrap">

    <div fxFlex="50" fxFlex.lt-md="100%">
      <mat-form-field class="campoFormulario">
        <mat-label>{{ 'protocolEdit.dialog.essay.' + data.type + '.responsible' | translate }}</mat-label>
        <mat-select name="bioindicatorsResponsible" [(ngModel)]="data.responsible">
          <mat-option *ngFor="let responsible of bioindicatorResponsibles" [value]="responsible.id">
            {{ 'bioindicatorResponsibleType.' + responsible.translation | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  
    <div fxFlex="50" fxFlex.lt-md="100%">
      <mat-form-field class="campoFormulario">
        <input matInput type="number" required [(ngModel)]="bioCount" (change)="resetChecks()" [max]="data.sensors.length"
          [min]="1" placeholder="{{ 'protocolEdit.dialog.essay.' + data.type + '.number' | translate }}" name="bioCount">
        <mat-error>
          {{ 'protocolEdit.dialog.essay.' + data.type + '.error' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  
    <div fxFlex="100%" fxFlex.lt-md="100%" *ngIf="data.sensors.length != bioCount">
      <div fxLayout="row" class="flexwrap" *ngFor="let sensor of data.sensors; let indexSensor = index">
        <div fxFlex="20" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput name="numSensor{{indexSensor}}" value="{{ indexSensor + 1 }}"
              placeholder="{{ 'protocolEdit.dialog.essay.sensors.sensorNum.label' | translate }}" disabled>
          </mat-form-field>
        </div>
        <div fxFlex="70" fxFlex.lt-md="100%">
          <mat-form-field class="campoFormulario">
            <input matInput name="numSensor{{indexSensor}}" [(ngModel)]="sensor.location"
              placeholder="{{ 'protocolEdit.dialog.essay.sensors.location.label' | translate }}" disabled>
          </mat-form-field>
        </div>
        <div fxFlex="10" fxFlex.lt-md="100%">
          <mat-checkbox class="example-margin" (change)="onChangeCheck($event)" [value]="sensor"
            [checked]="checked[sensor.location]" [disabled]="disableChecks(sensor)"></mat-checkbox>
        </div>
      </div>
    </div>
  
    <div fxFlex="100%" fxFlex.lt-md="100%" style="margin-top: 1.5em">
  
      <div fxFlex></div>
  
      <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.accept' | translate }}</button>
  
      <div fxFlex="5px"></div>
  
      <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>
  
  </div>