import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Client, ClientFilter } from 'src/app/model/client';
import { Subject, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { ClientDataSource } from 'src/app/model/clientDataSource';
import { ClientService } from 'src/app/services/client.service';
import { ExcelUtils } from 'src/app/utils/excelUtils';
import { GenericUpload } from 'src/app/model/genericClass';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html'
})
export class ClientListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: ClientDataSource;
  filter: ClientFilter = new ClientFilter();

  displayedColumns: string[] = ['name', 'address', 'postalCode', 'city', 'state', 'country', 'edit', 'activate', 'more'];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private location: Location,
    private clientService: ClientService,
    public dialog: MatDialog,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) { }

  get pageTitle(): string {
    return this.translate.instant('clientList.title') as string;
  }

  ngOnInit(): void {
    this.loadClientList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => this.loadClientList()), takeUntil(this.destroy$)).subscribe();
  }

  new(): void {
    void this.router.navigateByUrl('/client?id=0');
  }

  editRow(id: number): void {
    void this.router.navigateByUrl(`/client?id=${id}`);
  }

  activate(client: Client): void {
    this.clientService.activate(client.id, true).pipe(takeUntil(this.destroy$)).subscribe(obs => {
      obs.subscribe(() => {
        this.snackBarService.sendSuccess(this.translate.instant('clientList.form.activateClient.ok', { name: client.name }) as string);

        this.loadClientList();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('clientList.form.activateClient.error', { name: client.name }) as string);
      });
    }, () => {
      this.snackBarService.sendError(this.translate.instant('clientList.form.activateClient.error', { name: client.name }) as string);
    });
  }

  deactivate(client: Client): void {
    this.clientService.activate(client.id, false).pipe(takeUntil(this.destroy$)).subscribe(obs => {
      obs.subscribe(() => {
        this.snackBarService.sendSuccess(this.translate.instant('clientList.form.deactivateClient.ok', { name: client.name }) as string);

        this.loadClientList();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('clientList.form.deactivateClient.error', { name: client.name }) as string);
      });
    }, () => {
      this.snackBarService.sendError(this.translate.instant('clientList.form.deactivateClient.error', { name: client.name }) as string);
    });
  }

  highlight(client: Client): string {
    let res = '';

    if (!client.active) {
      res = 'deactivate';
    }
    return res;
  }

  doFilter(event: ClientFilter): void {
    this.filter = event;

    this.loadClientList();
  }

  loadClientList(): void {
    this.dataSource = new ClientDataSource(this.clientService);

    this.filter.sortBy = this.sort.active || 'name';
    this.filter.sortDirection = this.sort.direction || 'asc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 5;

    this.dataSource.loadClient(this.filter);
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  uploadMassive(event): void {
    this.spinnerService.show();

    const file = event.target.files[0] as File;

    void ExcelUtils.excelToClient(file).then(data => {
      this.clientService.uploadMassive(data).pipe(takeUntil(this.destroy$)).subscribe((item: GenericUpload) => {

        if (item.totalOk > 0) {
          this.snackBarService.sendSuccess(this.translate.instant('clientList.form.massiveUpload.ok', { total: item.totalOk }) as string);
        }

        if (item.totalKo > 0) {
          this.snackBarService.sendWarn(this.translate.instant('clientList.form.massiveUpload.warn', { total: item.totalKo }) as string);
        }

        this.spinnerService.hide();

        this.loadClientList();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('clientList.form.massiveUpload.error') as string);
        this.spinnerService.hide();
      });
    });
  }

  exportTable(): void {
    this.filter.cols = this.displayedColumns;

    this.spinnerService.show();
    this.clientService.getExcel(this.filter).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name = this.pageTitle + '.xlsx';
      saveAs(res, name);
      this.spinnerService.hide();
    }, error => {
      console.error(error);
      this.spinnerService.hide();
    });
  }

  cancel(): void {
    this.location.back();
  }
}
