<div class="registro-deviacion" fxLayout="column">
    <h1 mat-dialog-title>{{'Registro/modificación de desviación'}}</h1>
    <div fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field fxFlex="45%" *ngIf="data?.protocolNumber">
            <mat-label>{{'executionEditQ.deviation.protocol.label' | translate}}</mat-label>
            <input matInput [(ngModel)]="data.protocolNumber" [disabled]="true">
        </mat-form-field>
        <mat-form-field fxFlex="45%" *ngIf="data.deviation?.codeDeviation">
            <mat-label>{{'executionEditQ.deviation.nDesviation.label' | translate}}</mat-label>
            <input matInput [(ngModel)]="data.deviation.codeDeviation" [disabled]="true">
        </mat-form-field>
    </div>

    <div fxLayout="column">
        <mat-form-field fxFlex>
            <mat-label>{{'executionEditQ.deviation.description.label' | translate}}</mat-label>
            <textarea matInput rows="5" [(ngModel)]="commentsDeviation.comments" [placeholder]="'executionEditQ.deviation.description.placeholder' | translate"></textarea>
        </mat-form-field>
    </div>

    <div class="evaluacion" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                <span>{{'executionEditQ.deviation.valuation.label' | translate}}:</span>
                <mat-radio-group [(ngModel)]="evaluation.valuation" name="valuation">
                    <mat-radio-button class="mat-deviation-radio-label-content" [value]="1"> {{'Crítica' | translate}} </mat-radio-button>
                    <mat-radio-button class="mat-deviation-radio-label-content" [value]="2"> {{'Mayor' | translate}} </mat-radio-button>
                    <mat-radio-button class="mat-deviation-radio-label-content" [value]="3"> {{'Menor' | translate}} </mat-radio-button>
                </mat-radio-group>
            </div>
            <div>
                <span>{{'executionEditQ.deviation.continue.label' | translate}}:</span>
                <mat-radio-group [(ngModel)]="evaluation.qualificationContinue" name="qualificationContinue">
                    <mat-radio-button class="mat-deviation-radio-label-content" [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
                    <mat-radio-button class="mat-deviation-radio-label-content" [value]="false"> {{'common.no' | translate}} </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <mat-form-field fxFlex>
            <textarea matInput rows="3" [(ngModel)]="evaluation.comments" [placeholder]="'executionEditQ.deviation.valuation.placeholder' | translate"></textarea>
        </mat-form-field>
    </div>

    <div fxLayout="column">
        <mat-form-field fxFlex>
            <mat-label>{{'executionEditQ.deviation.corrective.actions.label'|translate}}</mat-label>
            <textarea matInput rows="5" [(ngModel)]="proposedCorrective.comments" [placeholder]="'executionEditQ.deviation.corrective.actions.placeholder' | translate"></textarea>
        </mat-form-field>
    </div>

    <div fxLayout="column">
        <mat-form-field fxFlex>
            <mat-label>{{'executionEditQ.deviation.actions.taken.label' | translate}}</mat-label>
            <textarea matInput rows="5" [(ngModel)]="actionsTaken.comments" [placeholder]="'executionEditQ.deviation.actions.taken.placeholder' | translate"></textarea>
        </mat-form-field>
    </div>


    <div class="evaluacion" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                <span>{{'executionEditQ.deviation.result.deviation.label' | translate}}:</span>
                <mat-radio-group [(ngModel)]="deviationResolution.resolved" name="resolvedDesviation">
                    <mat-radio-button class="mat-deviation-radio-label-content" [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
                    <mat-radio-button class="mat-deviation-radio-label-content" [value]="false"> {{'common.no' | translate}} </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <mat-form-field fxFlex>
            <textarea matInput rows="3" [(ngModel)]="deviationResolution.comments" [placeholder]="'executionEditQ.deviation.resolution.comments.placeholder' | translate"></textarea>
        </mat-form-field>
    </div>

    <div class="evaluacion" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                <span>{{'executionEditQ.deviation.closed.label' | translate}}:</span>
                <mat-radio-group [(ngModel)]="closedDeviation.closed" name="closeDesviation">
                    <mat-radio-button class="mat-deviation-radio-label-content" [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
                    <mat-radio-button class="mat-deviation-radio-label-content" [value]="false"> {{'common.no' | translate}} </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>
</div>

<div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>