import { FilterAir } from '../model/filterAir';

export class AccesoryFilterUtils {

    public static getSizeOfFilter(filter: FilterAir): string {
        let arr: any[];

        if(filter.diameter != null) {
            arr = [`Ø${filter.diameter}`];
        } else {
            arr = [filter.length, filter.width, filter.height];
        }

        return arr.filter(x => !!x).join('x');
    }

}
