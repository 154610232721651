import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentExecution } from 'src/app/model/equipment';
import { EquipmentType } from 'src/app/model/equipmentType';
import { EquipmentTypeService } from 'src/app/services/equipmentType.service';
import { SnackBarService } from 'src/app/services/snackBar.service';

export interface DialogDataEquipmentEdit {
  equipment: EquipmentExecution;
}

@Component({
  selector: 'app-execution-edit-equipment-edit',
  templateUrl: './execution-edit-equipment-edit.component.html'
})
export class ExecutionEditEquipmentEditComponent implements OnInit {

  equipmentTypes: EquipmentType[];

  constructor(
    private dialogRef: MatDialogRef<ExecutionEditEquipmentEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataEquipmentEdit,
    private translate: TranslateService,
    private equipmentTypeService: EquipmentTypeService,
    public snackBarService: SnackBarService) {
  }

  ngOnInit(): void {
    this.equipmentTypeService.findAll().subscribe((res: EquipmentType[]) => this.equipmentTypes = res);
  }

  onOkClick(): void {
    const errs = [];

    if (!this.requiredReason()) {
      this.data.equipment.reasonChange = null;
    } else if (this.data.equipment.reasonChange == null || this.data.equipment.reasonChange === '') {
      errs.push(this.translate.instant('common.reasonChangeError'));
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.equipment);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }
  requiredReason(): boolean {
    let res = false;

    if (this.data.equipment.fromProtocol) {
      res = res || this.data.equipment.equipment !== this.data.equipment.equipmentProtocol;
      res = res || this.data.equipment.internalId !== this.data.equipment.internalIdProtocol;
      res = res || this.data.equipment.location !== this.data.equipment.locationProtocol;
      res = res || this.data.equipment.maker !== this.data.equipment.makerProtocol;
      res = res || this.data.equipment.model !== this.data.equipment.modelProtocol;
      res = res || this.data.equipment.serialNum !== this.data.equipment.serialNumProtocol;
      res = res || this.data.equipment.idType !== this.data.equipment.idTypeProtocol;
    }

    return res;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
