import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Calibration } from 'src/app/model/calibration';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';

export interface DialogDataConfirmSave {
  calibration: Calibration;
  reason: string;
  showReason: boolean;
}

@Component({
  selector: 'app-calibration-edit-dialog-confirm-save',
  templateUrl: 'calibration-edit-dialog-confirmSave.component.html'
})
export class CalibrationEditConfirmSaveComponent {

  matcher = new MyErrorStateMatcher();
  form: FormGroup;

  constructor(
    fb: FormBuilder,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CalibrationEditConfirmSaveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataConfirmSave) {
    this.form = fb.group({
      reason: [this.data.reason, this.data.showReason ? [Validators.required] : []]
    });
  }

  onOkClick(): void {
    if (!this.form.valid) {
      this.snackBarService.sendError(this.translate.instant('calibrateEquipmentEdit.dialog.confirmSave.form.error.reason') as string);
      return;
    }

    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
