<form>

    <div mat-dialog-content class="getTheme()" style="min-height: 10vh;">
        <div *ngIf="!isEmpty(data.errors)">
            <p> {{ 'executionEdit.validation.hasErrors' | translate }} </p>

            <ul>
                <li *ngFor="let error of data.errors"> {{ error }} </li>
            </ul>
        </div>

        <div *ngIf="!isEmpty(data.warns)">
            <p> {{ 'executionEdit.validation.hasWarns' | translate }} </p>

            <ul>
                <li *ngFor="let warn of data.warns"> {{ warn }} </li>
            </ul>

            <p *ngIf="isEmpty(data.errors)"> {{ 'generic.clone.hasWarns.noErrors' | translate }} </p>
        </div>

    </div>

    <div mat-dialog-actions class="getTheme()">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()" cdkFocusInitial *ngIf="isEmpty(data.errors)">
            {{ 'button.accept' | translate }}
        </button>

        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>

</form>