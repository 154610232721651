<mat-card class="card">
    <mat-card-header>
        <mat-card-title>
            <mat-toolbar color="secondary" style="width: 95vw;">
                {{ notification.name }}
            </mat-toolbar>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="centerText" style="width: 95%;">
            <div cdkDropListGroup fxLayout="row">
                <div fxFlex="40">
                    <h2>{{ 'notificationEdit.allUsers' | translate }}</h2>

                    <div cdkDropList [cdkDropListData]="allUsers" class="draganddrop-list"
                        (cdkDropListDropped)="drop($event)" fxLayout="row wrap">
                        <div fxFlex="33" fxFlex.lt-md="100" class="draganddrop-box" *ngFor="let item of allUsers"
                            cdkDrag> {{item}} </div>
                    </div>
                </div>

                <div fxFlex="10"></div>

                <div fxFlex="40">
                    <h2>{{ 'notificationEdit.notifiedUsers' | translate }}</h2>

                    <div cdkDropList [cdkDropListData]="notifiedUsers" class="draganddrop-list"
                        (cdkDropListDropped)="drop($event)" fxLayout="row wrap">
                        <div fxFlex="50" fxFlex.lt-md="100" class="draganddrop-box" *ngFor="let item of notifiedUsers"
                            cdkDrag> {{item}} </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <div fxFlex></div>
        <button mat-raised-button (click)="save()" color="primary">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="cancel()">{{ 'button.cancel' | translate }}</button>
    </mat-card-actions>
</mat-card>