import { Client } from './client';
import { GenericFilter } from './genericClass';
import { InternalEquipmentCustomFieldValue } from './internalEquipmentCustomFieldValue';

export class InternalEquipment {

    id: number;
    group: string;

    name: string;
    equipment: string;

    maker: string;
    model: string;

    serialNum: string;
    certificateNum: string;

    idStatus: number;
    nameStatus: string;
    statusTranslation: string;

    calibrateDate: Date;
    expirationDate: Date;

    idCertificate: number;
    certificate: any;

    observations: string;

    nearToExpire: boolean;
    expired: boolean;

    idClient: number;
    client: Client;

    responsible: number;

    idsVariables: number[];
    idsApps: number[];
    idsFolder: number[];

    internal: boolean;

    supplier: string;
    fee: number;

    equipmentRange: string;
    useRange: string;
    resolution: string;
    tolerance: string;
    calibrationFrequency: string;
    acquisitionDate: Date;
    extendedExpirationDate: Date;
    expansionDay: number;

    responsibleEquipment: string;
    notificationResposibleEq: boolean;
    customFields: InternalEquipmentCustomFieldValue[];
}

export class InternalEquipmentFilter extends GenericFilter {
    idStatus: number;
    idClient: number;
    group: string;

    name: string;
    type: string;

    maker: string;
    model: string;

    serialNum: string;
    certificateNum: string;

    calibrateDateStart: Date;
    calibrateDateEnd: Date;

    expirationDateStart: Date;
    expirationDateEnd: Date;

    internal = false;
    external = false;

    idsVariable: number[];
    idsApp: number[];

    supplier: string;

    equipmentRange: string;
    useRange: string;
    resolution: string;
    tolerance: string;
    calibrationFrequency: string;
    acquisitionDateStart: Date;
    acquisitionDateEnd: Date;

    idFolder: number;

    cols: string[];

    customFields: Map<number, string> | any = new Map();

    extendedExpirationDate: Date;
    expansionDay: number;
    idResposible: number;
    responsibleEquipment: string;

}
