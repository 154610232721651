<mat-card class="card">
  <mat-card-content>
    <form #notificationsForm="ngForm">

      <mat-toolbar color="secondary">
        {{ 'notifications.title' | translate }}
      </mat-toolbar>

      <br />

      <table mat-table matSort multiTemplateDataRows [dataSource]="dataSource" style="width: 100%;">

        <!-- date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'notifications.cols.date' | translate }} </th>
          <td mat-cell *matCellDef="let item">
            <div class="{{ item.idStatus == 1 ? 'unread' : '' }}">
              {{ item.date | fixDateTime }}
            </div>
          </td>
        </ng-container>

        <!-- subject Column -->
        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef> {{ 'notifications.cols.subject' | translate }} </th>
          <td mat-cell *matCellDef="let item">
            <div class="{{ item.idStatus == 1 ? 'unread' : '' }}">
              {{item.subject}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="expand-detail-element" [@detailExpand]="element.expanded ? 'expanded' : 'collapsed'">
              <p>
                {{ 'notifications.date' | translate }}: {{ element.date | fixDateTime }}
              </p>
              <p>
                <span> {{ 'notifications.subject' | translate }}: </span>
                <span [innerHtml]="element.subject | safe: 'html'" class="linkApp"> </span>
              </p>
              <p style="white-space: pre-line;">
                <span> {{ 'notifications.message' | translate }}: </span>
                <span [innerHtml]="element.message | safe: 'html'" class="linkApp"> </span>
              </p>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" (click)="openNotification(element);">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expand-detail-row">
        </tr>

      </table>

      <div fxLayout="row" class="flexwrap">
        <div fxFlex style="margin-top: 0.5em;">
          <button mat-button (click)="markAllAsRead()">
            <mat-icon> done_all</mat-icon>
            {{ 'button.markAllAsRead' | translate }}
          </button>
        </div>
        <div fxFlex>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons [length]="dataSource.totalItems$ | async"
            lang="es">
          </mat-paginator>
        </div>
      </div>

    </form>
  </mat-card-content>

</mat-card>