import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Execution } from 'src/app/model/execution';
import { SpinnerService } from 'src/app/services/spinner.service';

export interface ExecutionEditDialogAlreadyExistsExecutionData {
  execution: Execution;
}

@Component({
  selector: 'app-execution-edit-dialog-already-exists-execution',
  templateUrl: './execution-edit-dialog-already-exists-execution.component.html'
})
export class ExecutionEditDialogAlreadyExistsExecutionComponent {

  constructor(
    public dialogRef: MatDialogRef<ExecutionEditDialogAlreadyExistsExecutionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExecutionEditDialogAlreadyExistsExecutionData,
    private router: Router,
    private spinnerService: SpinnerService) { }

  onOkClick(): void {
    this.dialogRef.close(true);
  }

  redirectClick(): void {
    this.spinnerService.show();

    void this.router.navigateByUrl(`/execution?id=${this.data.execution.id}`);

    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
