import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Severity, SnackbarMessage } from './model/snackbar';
import { Subject, Subscription, timer } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { AutoLogOutService } from './services/autoLogOut.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HeaderComponent } from './components/shared/header/header.component';
import { MaintenanceModeService } from './services/maintenanceMode.service';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { NgxSpinnerService } from 'ngx-spinner';
import { OnlineService } from './services/online.service';
import { Router } from '@angular/router';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { SnackBarService } from './services/snackBar.service';
import { SpinnerService } from './services/spinner.service';
import { StringUtils } from './utils/stringUtils';
import { ThemeService } from './services/theme.service';
import { ToastrService } from 'ngx-toastr';
import { VersionCheckService } from './services/version-check.service';
import { environment } from 'src/environments/environment';
import { LoadingService } from './services/loading.service';
import { HeaderClientComponent } from './components/shared/header-client/header-client.component';
import { CookieService } from 'ngx-cookie';
import { AppTypeEnum } from './model/appType';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild(HeaderComponent, { static: true }) header: HeaderComponent;
  @ViewChild(SidebarComponent, { static: true }) sidebar: SidebarComponent;
  @ViewChild(HeaderComponent, { static: true }) headerClient: HeaderClientComponent;


  defaultElevation = 1;
  raisedElevation = 6;

  isOnline: boolean;
  endTime = 30; // Minutos

  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;
  isLoginClient = false;
  loading$ = this.loader.loading$;

  private destroy$ = new Subject<void>();

  private iconsToUse = ['excel', 'doc_lock', 'bioindicator'];
  private flagsToUse = ['es', 'gb', 'fr', 'be', 'cat', 'cn', 'de', 'es', 'it', 'jp', 'pt', 'ru', 'vn'];
  constructor(
    private router: Router,
    private themeService: ThemeService,
    private versionCheckService: VersionCheckService,
    private autoLogOutService: AutoLogOutService,
    private snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private maintenanceModeService: MaintenanceModeService,
    private onlineService: OnlineService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private dialogRef: MatDialog,
    public loader: LoadingService,
    private cookieService: CookieService) {

    this.iconsToUse.forEach(item => {
      this.matIconRegistry.addSvgIcon(item, this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/' + item + '.svg'));
    });

    this.flagsToUse.forEach(item => {
      this.matIconRegistry.addSvgIcon(item, this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/flags/' + item + '.svg'));
    });
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.autoLogOutService.logOutUser();
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimerListener(): void {
    this.autoLogOutService.notifyUserAction();
  }

  updateUnreadNotifications(total: number): void {
    const rol = this.cookieService.get('rol');

    if (rol === 'guest') {
      this.headerClient.unreadNotifications = total;
    } else {
      if (this.header) {
        this.header.unreadNotifications = total;
      }

    }
  }

  ngOnInit(): void {
    this.isLoginClient = false;
    const env: any = environment;
    const versionCheckURL = env.versionCheckURL as string;

    this.versionCheckService.initVersionCheck(versionCheckURL);

    this.resetTimer();
    this.autoLogOutService.userActionOccured.pipe(takeUntil(this.unsubscribe$), takeUntil(this.destroy$)).subscribe(() => {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this.resetTimer();
    });

    this.snackBarService.message$.pipe(takeUntil(this.destroy$)).subscribe((msg: SnackbarMessage) => {
      if (msg != null && StringUtils.isNotEmpty(msg.title)) {
        this.showSnack(msg);
      }
    });

    this.spinnerService.message$.pipe(takeUntil(this.destroy$)).subscribe((show: boolean) => {
      if (show === true) {
        void this.spinner.show();
      } else {
        void this.spinner.hide();
      }
    });

    this.onlineService.online$.subscribe(online => this.isOnline = online);

    setInterval(() => {
      this.maintenanceModeService.isMaintenanceMode().subscribe(() => {
        // No hay que hacer nada, pero si no se mete esto, se queja Sonar
      }, () => {
        if (this.isOnline && !this.isDev()) {
          this.dialogRef.closeAll();
          void this.router.navigateByUrl('/maintenance');
        }
      });
    }, 5 * 60 * 1000);

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      if (data.url) {
       // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
       const rol = this.cookieService.get('rol');
       if (rol === 'guest') {
          if (!data.url?.startsWith('/login-client')) {
            this.isLoginClient = true;
          } else {
            this.isLoginClient = false;
          }
        } else {
          this.isLoginClient = false;
        }
      }
    });
  }

  resetTimer(): void {
    const interval = 1000;
    const duration = this.endTime * 60;
    this.timerSubscription = timer(0, interval).pipe(take(duration), takeUntil(this.destroy$))
      .subscribe(() => {
        // No hay que hacer nada, pero si no se mete esto, se queja Sonar
      }, () => {
        // No hay que hacer nada, pero si no se mete esto, se queja Sonar
      }, () => this.autoLogOutService.logOutUser());
  }

  getTheme(): string {
    const url = this.router.url;
    if (url?.includes('air')) {
      return this.themeService.getThemeHome(AppTypeEnum.AIR);
    } else if (url?.includes('calibrates')) {
      return this.themeService.getThemeHome(AppTypeEnum.CALIBRATES);
    } else if (url?.includes('thermal')) {
      return this.themeService.getThemeHome(AppTypeEnum.THERMAL);
    }  else if (url?.includes('qualificates')) {
      return this.themeService.getThemeHome(AppTypeEnum.QUALIFICATES);
    }
  }

  showSnack(message: SnackbarMessage): void {

    switch (message.severity) {
      case Severity.SUCCESS:
        this.toastr.success(message.title, message.msg);
        break;
      case Severity.ERROR:
        console.error(message.title);
        this.toastr.error(message.title, message.msg);
        break;
      case Severity.WARNING:
        this.toastr.warning(message.title, message.msg);
        break;
      case Severity.INFO:
        this.toastr.info(message.title, message.msg);
        break;
    }

  }

  isDev(): boolean {
    return environment.development;
  }

  getThemeIconPath(): string {
    const url = this.router.url;
    return this.themeService.getThemeIconPathSelection(url);
  }

}
