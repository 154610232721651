import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProtocolQ, ProtocolStatus } from 'src/app/model/protocol';
import { User } from 'src/app/model/user';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { GenericClass } from 'src/app/model/genericClass';
import { Client } from 'src/app/model/client';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/utils/constants';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProtocolQService } from 'src/app/services/protocolQ.service';
import { ClientService } from 'src/app/services/client.service';
import { UsersService } from 'src/app/services/users.service';
import { ProcessService } from 'src/app/services/process.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ActionConfirmPasswordComponent } from '../../shared/action-confirm-password/action-confirm-password.component';
import { ProtocolQEditAuditComponent } from './protocol-q-edit-audit/protocol-q-edit-audit.component';
import { TestQualificationTypeService } from '../../../services/testQualificationType.service';
import { TestQualificationTypeShort } from '../../../model/testQualificationType';
import { TestQType, TestTypeEnum } from '../../../model/testQType';
import { GroupTestQDataVerification } from 'src/app/model/groupTestQDataVerification';
import { ProtocolQTest, ProtocolQGeneralData } from '../../../model/protocol';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatTable } from '@angular/material/table';
import { EditReferenceDocsComponent } from '../../shared/edit-reference-docs/edit-reference-docs.component';
import * as _ from 'lodash';
import { ReferenceDocument } from 'src/app/model/referenceDocument';
import { EquipmentQualificates } from 'src/app/model/equipment';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { ProtocolQEditTestComponent } from './protocol-q-edit-test/protocol-q-edit-test.component';
import { ProtocolQTestsService } from '../../../services/protocol-qtests.service';
import { ProtocolQEditSectionTemplateComponent } from './protocol-q-edit-section-template/protocol-q-edit-section-template.component';
import { ProtocolQEditGenerateReportComponent } from './protocol-q-edit-generate-report/protocol-q-edit-generate-report.component';
import { ReasonDialogComponent } from '../../shared/reason-dialog/reason-dialog.component';
import { EditDocumentCodeEditComponent } from '../../shared/edit-document-code-edit/edit-document-code-edit.component';
import { DialogDataConfirmSave, ProtocolEditConfirmSaveComponent } from './protocol-edit-confirm-save/protocol-q-edit-confirm-save.component';
import { ProtocolEditEquipmentDialogComponent } from './protocol-edit-equipment-dialog/protocol-q-edit-equipment-dialog.component';
import { UserService } from '../../../services/user.service';
import { ArrayUtils } from '../../../utils/arrayUtils';
import { SignInfo } from '../../../model/sign';
import { ProtocolQEditDialogAutomaticSignComponent } from './protocol-q-edit-dialog-automatic-sign/protocol-q-edit-dialog-automatic-sign.component';
import { CheckWarnsErrorsComponent } from '../../shared/check-warns-errors/check-warns-errors.component';
import { GenericWarnError } from '../../../model/genericClass';
import { ProtocolQEditDialogManualSignComponent } from './protocol-q-edit-dialog-manual-sign/protocol-q-edit-dialog-manual-sign.component';
import { ProtocolQEditAttachmentsComponent } from './protocol-edit-attachments/protocol-q-edit-attachments.component';
import { QualificationTypeEnum } from 'src/app/model/verificationQType';

@Component({
  selector: 'app-protocol-q-edit',
  templateUrl: './protocol-q-edit.component.html',
})
export class ProtocolQEditComponent implements OnInit {

  @ViewChild(ProtocolQEditAuditComponent) auditTab: ProtocolQEditAuditComponent; // Crear Audit propio
  @ViewChild('refDocTable', { static: true }) refDocTable: MatTable<any>;
  @ViewChildren('attachmentsTestComponent') attachmentsComponent: ProtocolQEditAttachmentsComponent;
  @ViewChild('checkTest', { static: true }) checkboxTest: MatCheckbox;


  form: FormGroup;
  matcher = new MyErrorStateMatcher();
  protocol: ProtocolQ;

  clients: Client[];
  clientsFiltered: Client[];
  processes: GenericClass[];
  respField: User[];
  groupSelected: GroupTestQDataVerification[] = [];
  testQualificationTypeList: TestQualificationTypeShort[] = [];
  currentUser: User;
  pendingSigns: SignInfo[];
  private destroy$ = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  displayedColsRefDoc = ['code', 'name', 'edit', 'delete'];
	allowedDataIQ: number[] = [TestTypeEnum.VERIFICATION_CALIBRATION_INSTRUMENTATION_CRITICAL_EQUIPMENT, TestTypeEnum.VERIFICATION_COMPONENTS_EQUIPMENT_DIMENSIONS_MATERIALS, 
		TestTypeEnum.VERIFICATION_LOCATION_ASSOCIATED_SERVICES, TestTypeEnum.MATCHING_INSTALLATION_PLANS,  TestTypeEnum.EQUIPMENT_DOCUMENTATION_VERIFICATION,  TestTypeEnum.EQUIPMENT_VERIFICATION, 
		TestTypeEnum.VERIFICATION_APPROVAL_DESIGN_QUALIFICATION, TestTypeEnum.VERIFICATION_APPROVAL_PROTOCOL_QUALIFICATION];

  testsPerformed = [QualificationTypeEnum.IQ_DESCRIPTION, QualificationTypeEnum.OQ_DESCRIPTION, QualificationTypeEnum.PQ_DESCRIPTION];
  constructor(
    fb: FormBuilder,
    private clientService: ClientService,
    public dialog: MatDialog,
    private processService: ProcessService,
    private protocolService: ProtocolQService,
    private route: ActivatedRoute,
    private router: Router,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private translate: TranslateService,
    private usersService: UsersService,
    private testQualificationTypeService: TestQualificationTypeService,
    private protocolQTestsService: ProtocolQTestsService,
    private userService: UserService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {
    this.spinnerService.show();
    this.currentUser = this.userService.currentProfile;
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const id = params[Constants.FIELD_ID] as number;

      if (id == null || isNaN(+id)) {
        this.cancel();
      }

      this.protocol = new ProtocolQ();
      if (+id === 0) {
        this.testQualificationTypeService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: TestQualificationTypeShort[]) => {
          this.testQualificationTypeList = data;
        });

        let doc = new ReferenceDocument();
        doc.name = this.translate.instant('protocolEdit.referenceDocs.name.default') as string;
        doc.code = this.translate.instant('protocolEdit.referenceDocs.code.default') as string;

        if (!this.protocol.referenceDocs) {
          this.protocol.referenceDocs = [];
        }
        this.protocol.referenceDocs.push(doc);
        this.spinnerService.hide();
      } else {
        this.protocolService.findOne(id).pipe(takeUntil(this.destroy$)).subscribe((result: ProtocolQ) => {
          if (result != null) {
            this.protocol = result;

            if (this.protocol == null) {
              this.cancel();
              return;
            } else {
              this.form = fb.group({
                idClient: [this.protocol.idClient, [Validators.required]],
                idProcess: [this.protocol.idProcess, [Validators.required]],
                projectNo: [this.protocol.projectNo, [Validators.required]],
                documentCode: [{ value: this.protocol.documentCode, disabled: true }],
                clientDocumentCode: [this.protocol.clientDocumentCode],
                projectManager: [this.protocol.usernameProjectManager],
                regUser: [{ value: this.protocol.regUser, disabled: true }],
                modUser: [{ value: this.protocol.modUser, disabled: true }],
                regFc: [{ value: this.protocol.regFc, disabled: true }],
                modFc: [{ value: this.protocol.modFc, disabled: true }],
                currentVersion: [{ value: this.protocol.currentVersion, disabled: true }],
                signedVersion: [{ value: this.protocol.signedVersion, disabled: true }],
                observations: [{ value: this.protocol.observations }],
                notes: [{ value: this.protocol.notes }]
              });

              this.initializeAttachmentsComponent();
              this.spinnerService.show();
              this.protocolQTestsService.findByTestTypeId(this.protocol.id).pipe(takeUntil(this.destroy$))
              .subscribe((data: TestQualificationTypeShort[]) => {
                this.testQualificationTypeList = data;
                this.spinnerService.hide();
              });

            }

          } else {
            this.protocol = null;
          }
        }, () => {
          this.protocol = null;
          this.cancel();
        });
      }
    });

    this.form = fb.group({
      idClient: [this.protocol.idClient, [Validators.required]],
      idProcess: [this.protocol.idProcess, [Validators.required]],
      projectNo: [this.protocol.projectNo, [Validators.required]],
      documentCode: [{ value: this.protocol.documentCode, disabled: true }],
      clientDocumentCode: [this.protocol.clientDocumentCode],

      projectManager: [this.protocol.usernameProjectManager],

      regUser: [{ value: this.protocol.regUser, disabled: true }],
      modUser: [{ value: this.protocol.modUser, disabled: true }],
      regFc: [{ value: this.protocol.regFc, disabled: true }],
      modFc: [{ value: this.protocol.modFc, disabled: true }],
      currentVersion: [{ value: this.protocol.currentVersion, disabled: true }],
      signedVersion: [{ value: this.protocol.signedVersion, disabled: true }],
      observations: [{ value: this.protocol.observations }],
      notes: [{ value: this.protocol.notes }]
    });
  }


  initializeAttachmentsComponent(): void {
  
    if (this.attachmentsComponent instanceof QueryList) {
			this.attachmentsComponent = this.attachmentsComponent.first as ProtocolQEditAttachmentsComponent;
		}
		
		if (this.attachmentsComponent) {
			this.attachmentsComponent.setIdProtocol(this.protocol.id);
			this.attachmentsComponent.setIdStatus(this.protocol.idStatus);
		}
}
  ngOnInit(): void {

    this.clients = [];
    this.processes = [];
    this.processService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: GenericClass[]) => this.processes = data);

    this.respField = [];
    this.usersService.findAllRespFromGroup().pipe(takeUntil(this.destroy$)).subscribe((data: User[]) => this.respField = data);

    this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Client[]) => {
      this.clients = data;
      this.clientsFiltered = this.clients.slice();
    });

    
  }

  cancel(): void {
    void this.router.navigateByUrl('/qualificates/protocols');
  }

  canEditDocumentCode(): boolean {
    const allowedStatuses = [ProtocolStatus.PENDIENTE_FIRMA];

    return this.protocol.id != null && allowedStatuses.includes(this.protocol.idStatus);
  }

  editDocumentCode(): void {
    const dialogRef = this.dialog.open(EditDocumentCodeEditComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        documentCode: _.cloneDeep(this.protocol.documentCode)
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        this.protocol.documentCode = result;
      }
    });
  }

  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 1) {
      this.auditTab.getAudit();
    }
  }

  showAutomaticSign(): boolean {
    const current = this.currentUser;

    return this.canSign() && current.automaticSign;
  }

  

  showManualSign(): boolean {
    const current = this.currentUser;

    return this.canSign() && current.manualSign && ArrayUtils.isEmpty(this.pendingSigns);
  }

  showRevertSign(): boolean {
    return this.protocol.idStatus === ProtocolStatus.FIRMADO;
  }

  saveProtocol(): void {

    const errores: string[] = [];

    if (!this.form.valid) {
      errores.push(this.translate.instant('protocolEdit.form.error.notValid') as string);
    }

    if (!this.protocol.equipments || this.protocol.equipments?.length === 0) {
      errores.push(this.translate.instant('protocolEdit.equipment.error.empty') as string);

    }

    if (errores.length === 0) {
      if (this.protocol.id == null) {
        if (!this.protocol?.testPerformed || this.protocol?.testPerformed?.length === 0) {
          this.snackBarService.sendError(this.translate.instant('protocolEdit.test.error.empty') as string);   
        } else {
          this.saveAll();
        }
      } else {

        const showReason = (+this.protocol.signedVersion) !== 0;

        if (showReason) {

          const dialogRef = this.dialog.open(ProtocolEditConfirmSaveComponent, {
            minWidth: '30vw',
            data: {
              protocol: this.protocol,
              reason: this.protocol.reason
            }
          });

          dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: DialogDataConfirmSave) => {
            if (result != null) {
              this.protocol.reason = result.reason;
              this.saveAll();
            }
          });

        } else {
          this.saveAll();
        }

      }

    } else if (errores.length !== 0) {
      this.snackBarService.sendError(errores.join('\n'));
    }

  }

  createTestsSelected(test: TestQType): void {
    let isEdit = false;
    let isEditTest = false;
    let idStatus = this.protocol.idStatus;
    // eslint-disable-next-line max-len
    const result = this.protocol?.protocolQTest?.find(e => e.idTestType === test?.id);
    if (result) {
      isEdit = true;
      if (result.protocolQTestItem) {
        isEditTest = true;
      }
    }
    const dialogRef = this.dialog.open(ProtocolQEditTestComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        idStatus,
        idItem: test.id,
        testQType: test,
        isEdit,
        isEditTest,
        data: _.cloneDeep(result),
        equipment: this.protocol.equipments,
        tests: this.protocol?.protocolQTest,
        nProtocol: this.protocol.documentCode
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((res: ProtocolQTest) => {
      if (res) {
        this.protocol?.protocolQTest?.filter(e=> e.idTestType === test.id).forEach(d => {
          d.testDescription = res.testDescription;
          d.regUser = res.regUser;
          d.regDate = res.regDate;
          d.modUser = res.modUser;
          d.modDate = res.modDate;
          d.reason = res.reason;
          d.imageTest = res.imageTest;
          d.protocolQTestItem = res.protocolQTestItem;
          if (res.protocolQTestProbeTraceability) {
            d.protocolQTestProbeTraceability = res.protocolQTestProbeTraceability;
          }
          if (res.protocolQTestResults) {
            d.protocolQTestResults = res.protocolQTestResults;}

          if (res.protocolQTestVerification) {
            d.protocolQTestVerification = res.protocolQTestVerification;
          }
          if (res.protocolQTestBioindicadores) {
            d.protocolQTestBioindicadores = res.protocolQTestBioindicadores;
          }
          if (res.protocolQTestGeneralData) {
            d.protocolQTestGeneralData = res.protocolQTestGeneralData;
          }

          if (res.protocolQTestEssayGroup) {
            d.protocolQTestEssayGroup = res.protocolQTestEssayGroup;
          }
        })
      }
      // this.groupSelected = [...res];
    });
  }

  onSelectedItem(test: TestQType, qualificationType: string, event: MatCheckboxChange): void {
   if (event.checked) {
      if (!this.protocol?.protocolQTest) {
        this.protocol.protocolQTest = [];
      }  
      if (qualificationType === QualificationTypeEnum.IQ_DESCRIPTION) {
        if (test.id !== TestTypeEnum.VERIFICATION_APPROVAL_PROTOCOL_QUALIFICATION) {
          const resVal =  this.protocol?.protocolQTest.find(pqt => pqt.idTestType === TestTypeEnum.VERIFICATION_APPROVAL_PROTOCOL_QUALIFICATION);

          if (resVal == null) {
            const testValide = this.translate.instant('testQualification.verification_approval_protocol_qualification') as string;
            const message = this.translate.instant('protocolEdit.test.selected.confirmContinue', { testValide }) as string;

            const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

            dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {

              if (response) {
                test.checked = true;

                const protocolQTest = new ProtocolQTest();
                protocolQTest.idTestType = test.id;
                this.protocol?.protocolQTest.push(protocolQTest);
              } else {
                test.checked = false;
                this.cdr.detectChanges();
              }
            });
          } else {
            test.checked = true;

            const protocolQTest = new ProtocolQTest();
            protocolQTest.idTestType = test.id;
            this.protocol?.protocolQTest.push(protocolQTest);
          }
        } else {
          const protocolQTest = new ProtocolQTest();
          protocolQTest.idTestType = test.id;
          this.protocol?.protocolQTest.push(protocolQTest);
        }
      }

      if (qualificationType === QualificationTypeEnum.OQ_DESCRIPTION) {
        if (test.id !== TestTypeEnum.VERIFICATION_PERFORMANCE_TESTING_INSTALLATION_QUALIFICATION) {
          const resVal =  this.protocol?.protocolQTest.find(pqt => pqt.idTestType === TestTypeEnum.VERIFICATION_PERFORMANCE_TESTING_INSTALLATION_QUALIFICATION);
          if (resVal == null) {
            const testValide = this.translate.instant('testQualification.verification_performance_testing_installation_qualification') as string;
            const message = this.translate.instant('protocolEdit.test.selected.confirmContinue', { testValide }) as string;
      
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });
      
            dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      
              if (response) {
                test.checked = true;
      
                const protocolQTest = new ProtocolQTest();
                protocolQTest.idTestType = test.id;
                this.protocol?.protocolQTest.push(protocolQTest);
              } else {
                test.checked = false;
                this.cdr.detectChanges();
              }
            });
          } else {
            test.checked = true;
            const protocolQTest = new ProtocolQTest();
            protocolQTest.idTestType = test.id;
            this.protocol?.protocolQTest.push(protocolQTest);
          }
        } else {
          const resIQ = this.protocol.protocolQTest.filter(e => this.allowedDataIQ.includes(e.idTestType) && !this.isCompletedTest(e.idTestType));
          if (resIQ?.length > 0) {
            const message = this.translate.instant('protocolEdit.test.unselected.principal.oq') as string;

            const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { canCancel: false, messageOk: 'Ok', message } });

            dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
              test.checked = false;
              this.cdr.detectChanges();
            });
          } else {
            test.checked = true;
            const protocolQTest = new ProtocolQTest();
            protocolQTest.idTestType = test.id;
            this.protocol?.protocolQTest.push(protocolQTest);
          }
        }
      }

      if (qualificationType === QualificationTypeEnum.PQ_DESCRIPTION) {
        if (test.id !== TestTypeEnum.VERIFICATION_PERFORMANCE_TEST_QUALIFICATION_PERFORMANCE_TESTING) {
          const resVal =  this.protocol?.protocolQTest.find(pqt => pqt.idTestType === TestTypeEnum.VERIFICATION_PERFORMANCE_TEST_QUALIFICATION_PERFORMANCE_TESTING);
    
          if (resVal == null) {
            const testValide = this.translate.instant('testQualification.verification_performance_tests_qualification_performance_testing') as string;
            const message = this.translate.instant('protocolEdit.test.selected.confirmContinue', { testValide }) as string;
      
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });
      
            dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      
              if (response) {
                this.checkboxTest;
                test.checked = true;
      
                const protocolQTest = new ProtocolQTest();
                protocolQTest.idTestType = test.id;
                this.protocol?.protocolQTest.push(protocolQTest);
              } else {
                test.checked = false;
                //(document.getElementById(`checkboxId${indexTest}${indexItem}`) as HTMLInputElement).checked = false;
                this.cdr.detectChanges();
              }
            });
          } else {
            test.checked = true;
            const protocolQTest = new ProtocolQTest();
            protocolQTest.idTestType = test.id;
            this.protocol?.protocolQTest.push(protocolQTest);
          }
        } else {
          test.checked = true;
          const protocolQTest = new ProtocolQTest();
          protocolQTest.idTestType = test.id;
          this.protocol?.protocolQTest.push(protocolQTest);
        }
      }
    } else {
      test.checked = false;
      this.protocol.protocolQTest = this.protocol.protocolQTest.filter(e => e.idTestType !== test.id);
    }
  }

  openNewRefDoc(): void {
    const dialogRef = this.dialog.open(EditReferenceDocsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        refDoc: new ReferenceDocument(),
        isEdit: false,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ReferenceDocument) => {

      if (result != null) {
        this.protocol.referenceDocs.push(result);

        this.refDocTable.renderRows();
      }
    });
  }

  editRefDoc(refDocIndex: number): void {

    const refDoc = this.protocol.referenceDocs[refDocIndex];

    const dialogRef = this.dialog.open(EditReferenceDocsComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: {
        refDoc: _.cloneDeep(refDoc),
        isEdit: true,
        disabled: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ReferenceDocument) => {

      if (result != null) {
        this.protocol.referenceDocs[refDocIndex] = result;

        this.refDocTable.renderRows();
      }
    });
  }

  removeRefDoc(refDocIndex: number): void {
    this.protocol.referenceDocs.splice(refDocIndex, 1);

    this.refDocTable.renderRows();
  }

  openDialogEquipment(): void {
    const dialogRef = this.dialog.open(ProtocolEditEquipmentDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipment: new EquipmentQualificates(),
        isEdit: false
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EquipmentQualificates) => {
      if (result != null) {
        if (this.protocol.equipments == null) {
          this.protocol.equipments = [];
        }
        this.protocol.equipments.push(result);
      }
    });
  }

  openDialogEditEquipment(idx: number): void {
    event.stopPropagation();

    const eq = this.protocol.equipments[idx];

    const dialogRef = this.dialog.open(ProtocolEditEquipmentDialogComponent, {
      minWidth: '80%',
      maxHeight: '95vh',
      data: {
        equipment: _.cloneDeep(eq),
        isEdit: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: EquipmentQualificates) => {
      if (result != null) {
        this.protocol.equipments[idx] = result;
      }
    });
  }


  deleteEquipment( index: number): void {
    event.stopPropagation();

    const message = this.translate.instant('protocolEdit.dialog.equipment.form.confirmDelete') as string;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: { message } });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response === true) {
        if (index > -1) {
          this.protocol.equipments.splice(index, 1);
        }
      }
    });

  }

  showAdjustTemplate(): void {
    const dialogRef = this.dialog.open(ProtocolQEditSectionTemplateComponent, {
      minWidth: '70%',
      maxHeight: '95vh',
      data: {
        protocol: !this.protocol.protocolQGeneralData ? new ProtocolQGeneralData(): this.protocol.protocolQGeneralData
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((protocol: ProtocolQGeneralData
    ) => {
      if (protocol != null) {
        this.protocol.protocolQGeneralData = protocol;
      }
  });
}

  downloadPdf(): void {
    this.dialog.open(ProtocolQEditGenerateReportComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: { protocol: this.protocol }
    });
  }

  isCompletedTest(idTestType: number): boolean {
    const notValidationExpectedResult = [TestTypeEnum.TEST_BOWIE_DICK];
    const notValidationSensor = [TestTypeEnum.TEST_BOWIE_DICK, TestTypeEnum.TEST_TEMPERATURE_DISTRIBUTION_MOISTURE_CO2_WITH_LOAD_OPENING_DOORS, TestTypeEnum.TEST_TIGHTNESS_TEST_VACUUM_TEST_LEAK_TEST, TestTypeEnum.LEAKAGE_TEST, TestTypeEnum.RELATIVE_MOISTURE_TEMPERATURE_DISTRIBUTION_TEST_CO2_IN_VACUUM, TestTypeEnum.TEMPERATURE_PENETRATION_TEST_UNDER_LOAD_TEMPERATURE];

    let res = true;
    const testResult = this.protocol.protocolQTest?.find(e => e.idTestType === idTestType);
 
    if (testResult) {

      if (testResult.protocolQTestItem?.length > 0) {
        for (let index = 0; index < testResult.protocolQTestItem?.length; index++) {
          const element = testResult.protocolQTestItem[index];
  
          if (element.protocolQTestItemVerification?.length > 0) {
            for (let indextiv= 0; indextiv < element.protocolQTestItemVerification?.length; indextiv++) {
              const elementtiv = element.protocolQTestItemVerification[indextiv];
    
              if (elementtiv.protocolQTestDataMultiple?.length > 0) {
                for (let indextdm= 0; indextdm < elementtiv.protocolQTestDataMultiple?.length; indextdm++) {
                  const element = elementtiv.protocolQTestDataMultiple[indextdm];
                   if (!notValidationExpectedResult.includes(idTestType)) {
                    if (!element.expectedResultDesc) {
                      res = false;
                     }
                   }
                 }
              } else {
                res = false;
              }
            }
          }
        } 
      } else {
        res = false;
      }

      if (notValidationSensor.includes(idTestType)) {
        if (!testResult.protocolQTestEssayGroup || testResult.protocolQTestEssayGroup?.length === 0) {
          res = false;
        } 
      }
      
      if (!testResult.protocolQTestGeneralData) {
        res = false;
      }
    } else {
      res = false;
    }

    return res;
  }

  automaticSign(): void {
    const dialogRef = this.dialog.open(ProtocolQEditDialogAutomaticSignComponent, {
      minWidth: '50%',
      maxHeight: '95vh',
      data: { protocol: this.protocol }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {

      if (result != null) {
        if (result != null) {
          this.clientService.findOne(this.protocol.idClient).subscribe((client: Client) => {

            if (client.idGroup != null) {
              void this.cloneRow(this.protocol.id, client.idGroup).then(() => this.reloadPage());
            } else {
              this.reloadPage();
            }

          });
        }
      } else {
        this.spinnerService.hide();
      }
    });

  }

  manualSign(): void {
    const dialogRef = this.dialog.open(ProtocolQEditDialogManualSignComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: { protocol: this.protocol }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.spinnerService.hide();

      if (result != null) {
        this.clientService.findOne(this.protocol.idClient).subscribe((client: Client) => {

          if (client.idGroup != null) {
            void this.cloneRow(this.protocol.id, client.idGroup).then(() => this.reloadPage());
          } else {
            this.reloadPage();
          }

        });
      }
    });

  }


  private cloneRow(id: number, idGroup: number): Promise<void> {

    return new Promise<void>((resolve) => {

      const dialogRefConfirm = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('generic.clone.sign') as string
        }
      });

      dialogRefConfirm.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: boolean) => {
        if (result === true) {

          this.spinnerService.show();

          this.protocolService.checkCopyToGroup(id, idGroup).subscribe((res: GenericWarnError) => {
            this.spinnerService.hide();

            const dialogRef = this.dialog.open(CheckWarnsErrorsComponent, {
              minWidth: '20%',
              maxHeight: '95vh',
              data: {
                warns: res.warns,
                errors: res.errors
              }
            });

            dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((canContinue: boolean) => {
              if (canContinue === true) {
                this.spinnerService.show();
                this.protocolService.copyToGroup(id, idGroup).subscribe(() => {
                  this.spinnerService.hide();

                  this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.clone.ok') as string);
                  resolve();
                }, () => {
                  this.spinnerService.hide();
                  this.snackBarService.sendError(this.translate.instant('protocolEdit.form.clone.error') as string);
                  resolve();
                });
              } else {
                resolve();
              }
            });
          }, () => {
            this.spinnerService.hide();
            this.snackBarService.sendError(this.translate.instant('protocolEdit.form.clone.error') as string);
            resolve();
          });
        } else {
          resolve();
        }
      });

    });
  }


  private saveAll() {
    const isNew = this.protocol.id == null;
        
    const dialogRef = this.dialog.open(ActionConfirmPasswordComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
      if (token != null) {

        this.spinnerService.show();

        this.protocolService.save(this.protocol, token).pipe(takeUntil(this.destroy$)).subscribe((res: ProtocolQ) => {
          this.protocol = res;
          if (isNew) {
            this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.create.ok') as string);
            this.reloadPage();
          } else {
            this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.update.ok') as string);
            this.reloadPage();
          }

          this.spinnerService.hide();
        }, err => {
          if (err != null && err.error != null && typeof err.error === 'string') {
            this.snackBarService.sendError(err.error as string);
          } else if (isNew) {
            this.snackBarService.sendError(this.translate.instant('protocolEdit.form.create.error') as string);
          } else {
            this.snackBarService.sendError(this.translate.instant('protocolEdit.form.update.error') as string);
          }

          this.spinnerService.hide();
        });

      } else {
        this.spinnerService.hide();
      }
    });
  }

  private reloadPage(idProtocol = this.protocol.id) {
    if (idProtocol == null) {
      idProtocol = this.protocol.id;
    }

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    void this.router.navigateByUrl(`qualificates/protocol?id=${idProtocol}`);
  }
  
  revertSign(): void {

    if (this.protocol.currentlyUsed) {
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('protocolEdit.form.revertSign.isInUse') as string,
          canCancel: false,
          messageOk: this.translate.instant('button.accept') as string
        }
      });

      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.translate.instant('calibrateEquipmentEdit.form.revertSign.confirm') as string
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result === true) {        
        this.requestReason((reason) => {
          const dialogRefConfirmation = this.dialog.open(ActionConfirmPasswordComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {}
          });

          dialogRefConfirmation.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((token: string) => {
            this.protocolService.revertSign(this.protocol.id, reason, token).pipe(takeUntil(this.destroy$)).subscribe(() => {
              this.spinnerService.hide();
              this.reloadPage(this.protocol.id);
            });
          }, err => {
            if (err != null && err.error != null && typeof err.error === 'string') {
              this.snackBarService.sendError(err.error as string);
            } else {
              this.snackBarService.sendError(this.translate.instant('protocolEdit.form.revertSign.error') as string);
            }
          });
        });
      }
    });

  }

  prepareToSign(): void {
    const errores = this.getErrorsSign();

    if (errores.length !== 0) {
      this.snackBarService.sendError(errores.join('\n'));
    } else {
      this.automaticSign();
    }
  }


  private requestReason(callback: (reason: string) => void) {
    const dialogRef = this.dialog.open(ReasonDialogComponent, {
      minWidth: '40%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: string) => {
      if (result != null) {
        callback(result);
      }
    });
  }

  private getErrorsSign(): string[] {
    const errores: string[] = [];
    if (ArrayUtils.isEmpty(this.protocol.protocolQTest)) {
      errores.push(this.translate.instant('protocolEditQ.test.empty') as string);
    }

    this.protocol.protocolQTest.forEach(pe => {
      if (ArrayUtils.isEmpty(pe.protocolQTestGeneralData)) {
        errores.push(this.translate.instant('protocolEditQ.test.dataGeneral.empty') as string);
      }
      
      /*for (let index = 0; index < pe.protocolQTestItem.length; index++) {
        const element = pe.protocolQTestItem[index];

        for (let indexItemV = 0; indexItemV < element.protocolQTestItemVerification?.length; indexItemV++) {
          const elementItemV = element.protocolQTestItemVerification[indexItemV];
          
          for (let indexTdm= 0; indexTdm < elementItemV.protocolQTestDataMultiple?.length; indexTdm++) {
            const elementTmv = elementItemV.protocolQTestDataMultiple[indexTdm];

            if (!elementTmv?.expectedResultDesc) {
              errores.push(this.translate.instant('protocolEditQ.test.verification.resultExpected.empty', pe.testDescription) as string);
            }
          }
        }
        
      }*/
    })

    return errores;
  }

  private canSign(): boolean {
    if (this.protocol.idStatus == null) {
      return false;
    }

    const profile = this.userService.currentProfile;

    if (profile == null) {
      return false;
    }

    const validStatuses = [ProtocolStatus.PENDIENTE_FIRMA];

    return validStatuses.includes(this.protocol.idStatus);
  }
}
