import { EssayProtocol } from './essayProtocol';

export class Essay {
    id: number;

    idType: number;
    dsType: string;
    translationType: string;

    idPeakEventType: number;

    essayValues: EssayProtocol[];

    active: boolean;
    reasonActive: string;

    title: string;
    additionalInfo: string;
    idPhoto: number;
}

export class EventEssayVariableKeys {
    essayIndex: number;
    variableIndex: number;

    toString(): string {
        return `${this.essayIndex};${this.variableIndex}`;
    }
}