import { RoomAir } from "../model/roomAir";
import { NumberUtils } from "./numberUtils";


export class RoomUtils {


    static getTotalVolume(room: RoomAir): number {
    return NumberUtils.sum(room.volumes.map(v => v.surface * v.height));
  }

}