import { Component, Inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IsothermalCharacterizationSensor } from 'src/app/model/isothermalCharacterization';
import { IsothermalCharacterizationService } from 'src/app/services/isothermalCharacterization.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ArrayUtils } from 'src/app/utils/arrayUtils';

export interface DialogDataConfirmSave {
  idIso: number;
  idEssayVariable: number;
  sensors: IsothermalCharacterizationSensor[];
  locationByTrays: boolean;
}

@Component({
  selector: 'app-isothermal-characterization-edit-dialog-correct-sensors',
  templateUrl: './isothermal-characterization-edit-dialog-correct-sensors.component.html'
})
export class IsothermalCharacterizationEditDialogCorrectSensorsComponent {

  checks: Map<IsothermalCharacterizationSensor, boolean>;

  constructor(
    public dialogRef: MatDialogRef<IsothermalCharacterizationEditDialogCorrectSensorsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataConfirmSave,
    private isothermalCharacterizationService: IsothermalCharacterizationService,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {

    this.checks = new Map<IsothermalCharacterizationSensor, boolean>();

    const sensors = this.data.sensors;
    const allUnchecked = !ArrayUtils.isEmpty(sensors) && sensors.every(s => !s.corrected);

    sensors.forEach(s => this.checks.set(s, s.corrected || allUnchecked));
  }

  onOkClick(): void {
    this.spinnerService.show();

    const data = this.data;
    const checks: number[] = [];

    this.checks.forEach((ch: boolean, sen: IsothermalCharacterizationSensor) => {
      if (ch) {
        checks.push(sen.id);
      }
    })

    this.isothermalCharacterizationService.correctSensors(data.idIso, data.idEssayVariable, checks).subscribe(() => {
      this.spinnerService.hide();
      this.snackBarService.sendSuccess(this.translate.instant('executionEdit.dialog.correctSensor.form.ok'));

      this.dialogRef.close(this.data);
    }, () => {
      this.spinnerService.hide();
      this.snackBarService.sendError(this.translate.instant('executionEdit.dialog.correctSensor.form.error.generic'));
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isChecked(sensor: IsothermalCharacterizationSensor): boolean {
    return this.checks.get(sensor);
  }

  setChecked(sensor: IsothermalCharacterizationSensor, check: MatCheckboxChange): void {
    this.checks.set(sensor, check.checked);
  }

  setCheckedAll(check: MatCheckboxChange): void {
    this.checks.forEach((ch: boolean, sen: IsothermalCharacterizationSensor) => {
      this.checks.set(sen, check.checked)
    })
  }

  showSensorName(sensor: IsothermalCharacterizationSensor): string {

    let res: string;
    if (this.data.locationByTrays) {
      res = this.translate.instant('isothermalCharacterizationEdit.dialog.essay.sensors.location.text',
        { trayNum: sensor.trayNum, sensorTray: sensor.sensorTray }) as string;
    } else {
      res = sensor.location;
    }

    return res;
  }

}
