<div class="main-container">
  <form #protocolListForm="ngForm">
    <div class="toolbar-list" *ngIf="data.isList">

      <div class="toolbar-list-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Resource-/-Icon-/-Protocolos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <g id="clipboard" transform="translate(8.000000, 4.000000)" stroke="#00ACC7" stroke-width="2">
                    <path d="M24,4 L28,4 C30.209139,4 32,5.790861 32,8 L32,36 C32,38.209139 30.209139,40 28,40 L4,40 C1.790861,40 0,38.209139 0,36 L0,8 C0,5.790861 1.790861,4 4,4 L8,4" id="Path"></path>
                    <rect id="Rectangle" x="8" y="0" width="16" height="8" rx="2"></rect>
                </g>
            </g>
        </svg>
          <h1 class="accent-color">{{ 'protocolList.title' | translate }}</h1>
        </div>

        <div class="breadcrumbs">
          <a href="#">{{ 'common.start.label' | translate }}</a> / <span>{{ 'protocolList.title' | translate }}</span>
        </div>
      </div>

      <div class="toolbar-list-actions">
        <button mat-raised-button (click)="new()" color="accent"
          matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton"
          [disabled]="disableOfflineButton">
          <mat-icon fxHide.lt-sm>add</mat-icon> {{ 'button.new' | translate }}
        </button>
      </div>

      <div class="toolbar-back-button">
        <a (click)="cancel()" mat-button color="accent">
          <mat-icon color="accent">undo</mat-icon> {{ 'button.back' | translate }}
        </a>
      </div>
    </div>

    <app-protocol-list-filter #filterComponent (filterEmitter)="doFilter($event)" class="filter-container">
    </app-protocol-list-filter>

    <div class="table-container">
      <table #protocols mat-table matSort [dataSource]="dataSource">

        <!-- client Column -->
        <ng-container matColumnDef="client">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'protocolList.cols.client' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.nameClient }} </td>
        </ng-container>

        <!-- process Column -->
        <ng-container matColumnDef="process">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'protocolList.cols.process' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.nameProcess }} </td>
        </ng-container>

        <!-- projectNo Column -->
        <ng-container matColumnDef="projectNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'protocolList.cols.projectNum' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.projectNo }} </td>
        </ng-container>

        <!-- area Column -->
        <ng-container matColumnDef="area">
          <th mat-header-cell *matHeaderCellDef> {{ 'protocolList.cols.area' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ getAreas(item) }} </td>
        </ng-container>

        <!-- documentCode Column -->
        <ng-container matColumnDef="documentCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'protocolList.cols.documentCode' | translate }}
          </th>
          <td mat-cell *matCellDef="let item"> {{ item.documentCode }} v.{{ item.signedVersion | number:'2.0' }} </td>
        </ng-container>

        <!-- status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'protocolList.cols.status' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ 'protocolStatus.' + item.statusTranslation | translate }} </td>
        </ng-container>

        <!-- regUser Column -->
        <ng-container matColumnDef="regUser">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'protocolList.cols.regUser' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.regUser }} </td>
        </ng-container>

        <!-- modFc Column -->
        <ng-container matColumnDef="modFc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'protocolList.cols.modFc' | translate }} </th>
          <td mat-cell *matCellDef="let item"> {{ item.modFc | fixDateTime }} </td>
        </ng-container>

        <!-- realizationDate Column -->
        <ng-container matColumnDef="realizationDate">
          <th mat-header-cell *matHeaderCellDef> {{ 'protocolList.cols.realizationDate' | translate }} </th>
          <td mat-cell *matCellDef="let item">
            <div style="white-space: pre-line;">
              {{ item.realizationDate | fixDateTime }}
            </div>
          </td>
        </ng-container>

        <!-- Button download Column -->
        <ng-container matColumnDef="download">
          <th mat-header-cell *matHeaderCellDef colspan="3" fxHide.lt-lg> {{ 'protocolList.cols.actions' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <div matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton">
              <button mat-icon-button (click)="downloadPdf(element)" [disabled]="disableOfflineButton"
                matTooltip="{{ 'button.download' | translate }}">
                <mat-icon>cloud_download</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <!-- Button edit Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button (click)="editRow(element.id)" matTooltip="{{ 'button.edit' | translate }}">
              <mat-icon>mode_edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button delete Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <div matTooltip="{{ 'button.disabled.offline' | translate }}" [matTooltipDisabled]="!disableOfflineButton">
              <button mat-icon-button (click)="deleteRow(element.id)" [disabled]="disableOfflineButton"
                *ngIf="canDelete(element)" matTooltip="{{ 'button.delete' | translate }}">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <!-- Button clone Column -->
        <ng-container matColumnDef="clone">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button (click)="cloneRow(element.id)" *ngIf="canClone(element)"
              matTooltip="{{ 'button.clone' | translate }}">
              <mat-icon>folder_copy</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button more Column -->
        <ng-container matColumnDef="more" stickyEnd>
          <th mat-header-cell *matHeaderCellDef fxHide fxShow.lt-lg></th>
          <td mat-cell *matCellDef="let element" fxHide fxShow.lt-lg class="action">

            <mat-menu #appMenu="matMenu" class="moreActions">
              <ng-template matMenuContent let-name="element">
                <button mat-button (click)="downloadPdf(element)" [disabled]="disableOfflineButton">
                  <mat-icon>cloud_download</mat-icon> {{ 'button.download' | translate }}
                </button>
                <button mat-button (click)="editRow(element.id)">
                  <mat-icon>mode_edit</mat-icon> {{ 'button.edit' | translate }}
                </button>
                <button mat-button (click)="deleteRow(element.id)" [disabled]="disableOfflineButton"
                  *ngIf="canDelete(element)">
                  <mat-icon>delete</mat-icon> {{ 'button.delete' | translate }}
                </button>
                <button mat-button (click)="cloneRow(element.id)" *ngIf="canClone(element)">
                  <mat-icon>folder_copy</mat-icon> {{ 'button.clone' | translate }}
                </button>
              </ng-template>
            </mat-menu>

            <button mat-icon-button [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="element">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button select Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button (click)="onProtocolSelect(element.id)" *ngIf="canSelect(element)"
              matTooltip="{{ 'button.select' | translate }}">
              <mat-icon>check_circle</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

      <span *ngIf="!hasFiltered" class="prefilterMessage">
        <p [innerHtml]="'common.listPlaceholder' | translate | safe: 'html'"> </p>
      </span>

    </div>

    <div class="table-footer-container">
      <div class="actions">
        <button mat-raised-button (click)="exportTable()" class="stroked-primary" *ngIf="data.isList">
          <mat-icon fxHide.lt-sm>cloud_download</mat-icon> {{ 'button.downloadXls' | translate }}
        </button>
        <a [routerLink]="['.']" [fragment]="'table-container'" class="back-to-top accent-color" *ngIf="data.isList">
          {{ 'button.backToTop' | translate }} </a>
      </div>

      <div class="paginator">
        <mat-paginator [pageSize]="data.isList ? 10 : 5" [pageSizeOptions]="data.isList ? [5, 10, 20] : [5]"
          showFirstLastButtons [length]="dataSource.totalItems$ | async" lang="es">
        </mat-paginator>
      </div>
    </div>
  </form>
</div>