import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ExecutionQ, ExecutionQFilter } from 'src/app/model/execution';
import { ExecutionQDataSource } from 'src/app/model/executionQDataSource';
import { ExecutionQService } from 'src/app/services/executionQ.service';
import { SpinnerService } from 'src/app/services/spinner.service';


export interface DialogDataExecutionListConfig {
  isList: boolean;
}

@Component({
  selector: 'app-execution-q-list',
  templateUrl: './execution-q-list.component.html'
})
export class ExecutionQListComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  filter: ExecutionQFilter = new ExecutionQFilter();
  hasFiltered = false;
  dataSource: ExecutionQDataSource;

  displayedColumns = ['documentCode', 'projectNo', 'client', 'status', 'realizationDate', 'download', 'edit', 'delete'];

  private destroy$ = new Subject<void>();

  constructor(
    private executionService: ExecutionQService,
    private router: Router,
    private spinnerService: SpinnerService,
    
    @Inject(MAT_DIALOG_DATA) public data: DialogDataExecutionListConfig
  ) {
    if (data.isList == null) {
      data.isList = true;
    }
  }

  ngOnInit(): void {
    this.loadExecutionList();
  }

  doFilter(event: ExecutionQFilter): void {
    this.filter = event;

    this.hasFiltered = true;

    this.loadExecutionList();
  }

  loadExecutionList(): void {
    this.dataSource = new ExecutionQDataSource(this.executionService);

    this.filter.sortBy = this.sort.active || 'documentCode';
    this.filter.sortDirection = this.sort.direction || 'desc';
    this.filter.pageIndex = this.paginator?.pageIndex || 0;
    this.filter.pageSize = this.paginator?.pageSize || 10;
    if (this.hasFiltered) {
      this.saveSearchFilter(this.filter);
      this.dataSource.loadExecution(this.filter);
    }
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  saveSearchFilter(execution: ExecutionQFilter): void {
    localStorage.setItem('filter', JSON.stringify(execution));
  }

  canClone(ex: ExecutionQ): boolean {
    return false;
  }

  deleteRow(id: number): void {
    if (id < 0) {
      return;
    }

    this.executionService.delete(id).pipe(takeUntil(this.destroy$)).subscribe(() => {
     this.loadExecutionList();
    });

  }

  editRow(id: number): void {
    void this.router.navigateByUrl(`/qualificates/execution?id=${id}`);
  }

  new(): void {
    void this.router.navigateByUrl('/qualificates/execution?id=0');
  }
}
