import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProtocolActionEnum, ProtocolAudit, ProtocolAuditFilter } from 'src/app/model/protocol';
import { Subject, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ProtocolAirAuditDataSource } from 'src/app/model/protocolAirAuditDataSource';
import { ProtocolAirService } from 'src/app/services/protocolAir.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-protocol-edit-audit',
  templateUrl: './protocol-edit-audit.component.html'
})
export class ProtocolEditAuditComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() idProtocol: number;
  @Input() documentCode: string;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public filter: ProtocolAuditFilter;

  public displayedColumns = ['date', 'username', 'action', 'status', 'currentVersion', 'signedVersion', 'changes', 'download'];
  public dataSource: ProtocolAirAuditDataSource;

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private protocolService: ProtocolAirService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    this.spinnerService.show();
  }

  ngOnInit(): void {
    this.filter = new ProtocolAuditFilter();
    this.getAudit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.getAudit())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  getAudit(): void {

    this.dataSource = new ProtocolAirAuditDataSource(this.protocolService);

    this.filter.sortBy = this.sort.active || 'date';
    this.filter.sortDirection = this.sort.direction || 'asc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 10;

    this.dataSource.loadProtocolAudit(this.idProtocol, this.filter);
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));

  }

  downloadAuditPdf():void {
    this.spinnerService.show();

    this.protocolService.downloadAuditPdf(this.idProtocol).pipe(takeUntil(this.destroy$)).subscribe(
      (res: Blob) => saveAs(res, this.getNameForProtocolAudit(this.documentCode)),
      error => console.error(error),
      () => this.spinnerService.hide());
  }

  showDownloadSignedPdf(prot: ProtocolAudit): boolean {
    return prot.idAction === ProtocolActionEnum.SIGN;
  }

  downloadSignedPdf(prot: ProtocolAudit):void {
    this.spinnerService.show();

    this.protocolService.downloadPdfVersion(this.idProtocol, prot.signedVersion).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name = this.getNameForProtocolAudit(this.documentCode);
      saveAs(res, name);
      this.spinnerService.hide();
    }, () => {
      this.snackBarService.sendError(this.translate.instant('protocolEdit.dialog.generateReport.form.error.notFound') as string);
      this.spinnerService.hide();
    });
  }

  private getNameForProtocolAudit(documentCode: string): string {
    return this.translate.instant('protocolEdit.files.audit', { documentCode }) as string;
  }

}
