import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExecutionAudit, ExecutionAuditFilter } from 'src/app/model/execution';
import { Subject, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { ExecutionAuditDataSource } from 'src/app/model/executionAuditDataSource';
import { ExecutionService } from 'src/app/services/execution.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-execution-edit-audit',
  templateUrl: './execution-edit-audit.component.html'
})
export class ExecutionEditAuditComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() idExecution: number;
  @Input() documentCode: string;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public filter: ExecutionAuditFilter;

  public displayedColumns = ['date', 'username', 'action', 'status', 'currentVersion', 'changes', 'download'];
  public dataSource: ExecutionAuditDataSource;

  private destroy$ = new Subject<void>();

  constructor(
    private executionService: ExecutionService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    this.spinnerService.show();
  }

  ngOnInit(): void {
    this.filter = new ExecutionAuditFilter();
    this.getAudit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.getAudit())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  getAudit(): void {

    this.dataSource = new ExecutionAuditDataSource(this.executionService);

    this.filter.sortBy = this.sort.active || 'date';
    this.filter.sortDirection = this.sort.direction || 'asc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 10;

    this.dataSource.loadExecutionAudit(this.idExecution, this.filter);
  }

  downloadAuditPdf(): void {
    this.spinnerService.show();

    this.executionService.downloadAuditPdf(this.idExecution).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      saveAs(res, this.translate.instant('executionEdit.files.audit') as string);
    }, error => {
      console.error(error);
    }, () => {
      this.spinnerService.hide();
    });
  }

  showDownloadSignedPdf(prot: ExecutionAudit): boolean {
    return prot.canDownload;
  }

  downloadSignedPdf(ex: ExecutionAudit): void {

    this.spinnerService.show();

    this.executionService.downloadPdfVersion(this.idExecution, ex.currentVersion).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name = this.getNameForExecution(this.documentCode, ex.currentVersion);
      saveAs(res, name);
      this.spinnerService.hide();
    }, () => {
      this.snackBarService.sendError(this.translate.instant('executionEdit.form.downloadPdf.error.notFound') as string);
      this.spinnerService.hide();
    });
  }

  private getNameForExecution(documentCode: string, version: string): string {
    let name = documentCode + ' v.' + version.toString().padStart(2, '0');
    name = this.translate.instant('executionEdit.files.report', { name }) as string;

    return name;
  }

}
