import { HttpClient } from "@angular/common/http";
import { OnlineService } from "./online.service";
import { DexieService } from "./dexieService";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { Observable, from, map } from "rxjs";
import { HttpUtils } from "../utils/httpUtils";

@Injectable({
    providedIn: 'root'
  })
  export class TestGeneralDataConfigService{

    protected readonly url = environment.qualificatesUrl + '/testMethodologyConfig';

  constructor(private http: HttpClient,
    private dexieService: DexieService,
    private onlineService: OnlineService) {
  }

  getTestGeneralDataConfigById(id:number): Observable<any> {
    if (this.onlineService.latestOnline) {
        return this.http.get(`${this.url}/test/${id}`).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    } else {
        return from(this.dexieService.roomType.toArray().timeout(30000));
    }
  }
  }