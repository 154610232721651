<h1 mat-dialog-title>{{ 'common.reportType.title' | translate }}</h1>
<form>

  <mat-accordion>
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'common.reportType.options.reportType' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-radio-group class="vertical-radio-group" [ngModel]="optionSelected" [ngModelOptions]="{standalone: true}"
        *ngIf="!data.isForAutomaticSign">
        <mat-radio-button class="vertical-radio-button" *ngFor="let item of optionsToDownload" [value]="item"
          (change)="onOptionChange($event)">
          {{ 'common.reportType.' + item | translate }}
        </mat-radio-button>
      </mat-radio-group>

    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="showConfigReport()">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'common.reportType.options.graphsToInclude' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <span>{{ 'common.reportType.options.graphsToInclude' | translate }}:</span>

      <mat-selection-list [(ngModel)]="reportConfig.graphsToInclude" [ngModelOptions]="{standalone: true}">
        <mat-list-option *ngFor="let item of reportConfig.graphTypes" checkboxPosition="before" [value]="item">
          {{ 'common.reportType.options.' + item | translate }}
        </mat-list-option>
      </mat-selection-list>

    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="showConfigReport()">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'common.reportType.options.dataTable' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <span>{{ 'common.reportType.options.dataTable' | translate }}:</span>
      <br />
      <mat-radio-group class="vertical-radio-group" [(ngModel)]="reportConfig.dataTableInclude"
        [ngModelOptions]="{standalone: true}">
        <mat-radio-button class="vertical-radio-button" *ngFor="let item of reportConfig.dataTypes" [value]="item">
          {{ 'common.reportType.options.' + item | translate }}
        </mat-radio-button>
      </mat-radio-group>

    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="showConfigReport()">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'common.reportType.options.reviewBy.label' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="campoFormulario" *ngIf="!data.isForAutomaticSign">
        <mat-label>{{ 'common.reportType.options.reviewBy.label' | translate }}</mat-label>
        <mat-select [(ngModel)]="reportConfig.reviewBy" [ngModelOptions]="{standalone: true}">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let item of respDocs" [value]="item.username">
            {{item.fullName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'common.reportType.options.language.label' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="campoFormulario" *ngIf="showChangeLangIcon() && !data.isForAutomaticSign">
        <mat-label>{{ 'common.reportType.options.language.label' | translate }}</mat-label>
        <mat-select [(ngModel)]="reportConfig.lang" [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let item of langs" [value]="item.lang">
            <mat-icon svgIcon="{{item.flag}}"></mat-icon>
            {{item.fullLang}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="showConfigReport()">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'common.reportType.options.conclusions' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-checkbox name="changeConclusions" [ngModel]="changeConclusions" (change)="onChangeConclusionsCheck($event)">
        {{'common.reportType.options.conclusions.label' | translate }}
      </mat-checkbox>

      <button mat-icon-button (click)="reloadConclusions()" matTooltip="{{ 'button.reload' | translate }}"
        *ngIf="changeConclusions">
        <mat-icon>refresh</mat-icon>
      </button>

      <div *ngIf="changeConclusions">
        <br />
        <textarea matInput name="conclusions" style="resize: none;" [(ngModel)]="reportConfig.conclusions"
          placeholder="{{ 'common.reportType.options.conclusions.field' | translate }}" rows="10"
          matAutosizeMinRows="10" style="resize: none;" required> </textarea>
      </div>


    </mat-expansion-panel>

  </mat-accordion>

  <br /><br />

  <div mat-dialog-actions class="thermal-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick(false)" [disabled]="optionSelected == null"
      cdkFocusInitial>{{ 'button.download' | translate }}</button>
    <button mat-raised-button color="primary" (click)="onOkClick(true)"
      cdkFocusInitial>{{ 'button.download.hybride' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>