/* eslint-disable @typescript-eslint/member-ordering */

import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { AppTypeEnum } from 'src/app/model/appType';
import { Constants } from 'src/app/utils/constants';
import { DrawerService } from 'src/app/services/drawer.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { FooterComponent } from 'src/app/components/shared/footer/footer.component';
import { InternalEquipmentFolder } from 'src/app/model/internalEquipmentFolder';
import { InternalEquipmentFolderService } from 'src/app/services/internalEquipmentFolder.service';
import { LoginService } from 'src/app/services/login.service';
import { MatSidenav } from '@angular/material/sidenav';
import { OnlineService } from 'src/app/services/online.service';
import { Phase } from 'src/app/model/phase';
import { PhaseService } from 'src/app/services/phase.service';
import { RoleEnum } from 'src/app/model/roleUser';
import { StringUtils } from 'src/app/utils/stringUtils';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import { RoleUser } from '../../../model/roleUser';
import { SpinnerService } from '../../../services/spinner.service';
import { GlobalStateService } from '../../../services/globalState.service';
import { LangFlag, TranslationService } from '../../../services/translation.service';

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  id: number;
  name: string;
  expandable: boolean;
  level: number;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Output() filterEmitter = new EventEmitter<number>();

  @ViewChild('sidenav', { static: true }) public sidenav: MatSidenav;
  @ViewChild(FooterComponent, { static: true }) footer: FooterComponent;

  isLoggedIn$: Observable<boolean>;
  isLoggedIn: boolean;
  phases: Phase[];

  user = new User();
  folders: InternalEquipmentFolder[] = [];

  disableOfflineButton = false;

  sideBarHide: boolean;

  profils: RoleUser[];
  idAppSelected: number;
  appActive: string;
  selectedGroup: number;

  currentUser: User;

  public langs: LangFlag[];
  public activeFlag: string;
  public activeNameFlag: string;

  private _transformer = (node: InternalEquipmentFolder, level: number) => {
    return {
      expandable: !!this.getFolders(node.id) && this.getFolders(node.id).length > 0,
      name: node.name,
      level,
      id: node.id
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => this.getFolders(node.id)
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  private destroy$ = new Subject<void>();

  constructor(
    private route: Router,
    private loginService: LoginService,
    private drawerService: DrawerService,
    private onlineService: OnlineService,
    private userService: UserService,
    private phaseService: PhaseService,
    private internalEquipmentFolderService: InternalEquipmentFolderService,
    private router: Router,
    private elRef: ElementRef,
    private cookieService: CookieService,
    private spinnerService: SpinnerService,
    private globalStateService: GlobalStateService,
    public translationService: TranslationService) { }

  ngOnInit(): void {
    this.onlineService.online$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.disableOfflineButton = !res;
    });

    this.disableOfflineButton = !this.onlineService.latestOnline;

    this.currentUser = this.userService.currentProfile;

    this.isLoggedIn$ = this.loginService.isLoggedIn$;
    this.isLoggedIn$.pipe(takeUntil(this.destroy$)).subscribe(item => {
      this.isLoggedIn = item;

      if (this.isLoggedIn === true && this.currentUser.idActiveApp === AppTypeEnum.THERMAL) {
        this.reloadPhases();
      }
    });

    this.drawerService.setDrawer(this.sidenav);
    this.userService.currentProfile?.profiles.forEach(pr => {
      const existsApp = this.profils?.find(ps => ps.idApp === pr.idApp);

      if (!existsApp) {
        if (!this.profils) {
          this.profils = [];
        }
        this.profils.push(pr);
      }
    })

    this.route.events.pipe(takeUntil(this.destroy$)).subscribe(val => {
      if (val instanceof NavigationStart) {
        if (!val.url.startsWith('/login') && !val.url.startsWith('/signup')) {
          this.updateSidebar();
        }

        const sidenavContent = this.elRef.nativeElement.querySelectorAll('mat-sidenav-content')[0];

        sidenavContent.scrollTo(0, 0);
      }
    });

    if (this.isLoggedIn === true && this.currentUser.idActiveApp === AppTypeEnum.THERMAL) {
      this.reloadPhases();
    }

    this.langs = this.translationService.configuredLangs;

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateSidebar(): void {
    if (this.isLoggedIn) {
      this.userService.profile().pipe(takeUntil(this.destroy$)).subscribe((item: User) => {
        if (item.username == null) {
          this.loginService.logout();
        }

        this.user = item;

        this.filterEmitter.next(item.unreadNotifications);
      });

      const currentUser = this.userService.currentProfile;

      this.selectedGroup = currentUser.profiles.find(p => p.idGroup === currentUser.idActiveProfile)?.idGroup;

      if (currentUser.idActiveApp === AppTypeEnum.THERMAL) {
        this.reloadPhases();
      }

      this.appActive = this.userService.currentProfile.activeApp;

      this.activeFlag = this.langs.find(la => la.lang === this.translationService.currentLang)?.flag;
      this.activeNameFlag = this.translationService.currentLang;
      this.internalEquipmentFolderService.getAll().subscribe((res: InternalEquipmentFolder[]) => {
        this.folders = res;
        this.dataSource.data = this.getFolders();
      });
    }
  }

  showThermal(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }

    return currentUser.idActiveApp === AppTypeEnum.THERMAL;
  }

  showCalibrates(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }

    return currentUser.idActiveApp === AppTypeEnum.CALIBRATES;

  }

  showAir(): boolean {
    const currentUser = this.userService.currentProfile;
    if (currentUser == null) {
      return false;
    }

    return currentUser.idActiveApp === AppTypeEnum.AIR;
  }

  showCharacterization(): boolean {
    const currentUser = this.userService.currentProfile;

    if (currentUser == null) {
      return false;
    }

    return currentUser.showCharacterization && (this.showThermal() || this.showCalibrates());
  }

  showManageClients(): boolean {
    const idRole = this.user.idActiveRole;

    const rolesCanManage = [RoleEnum.ADMIN, RoleEnum.MANAGER, RoleEnum.RESPONSABLE];

    return this.user.admin || rolesCanManage.includes(idRole);
  }

  showCalibrationPlan(): boolean {
    const idRole = this.user.idActiveRole;

    const rolesCanManage = [RoleEnum.ADMIN, RoleEnum.MANAGER];

    return rolesCanManage.includes(idRole) && this.user.showCalibrationPlan;
  }

  getFolders(idParent: number = null): InternalEquipmentFolder[] {
    let res = this.folders?.filter(f => f.idFather === idParent);

    if (idParent == null) {
      res = this.folders?.filter(f => f.idFather == null);
    }

    return res != null ? res : [];
  }

  reloadPhases(): void {
    this.phases = [];
    this.phaseService.findAll().pipe(takeUntil(this.destroy$)).subscribe((item: Phase[]) => this.phases = item);
  }

  checkRouterDisabledOffline(url: string): string {
    this.currentUser = this.userService.currentProfile;

    if (!url.includes('admin') && !url.includes('clients')) {
      if (this.route.url.includes('thermal')) {
        url = '/thermal'+url;
      } else if (this.route.url.includes('air')) {
        url = '/air'+url;
      } else if (this.route.url.includes('calibrates')) {
        url = '/calibrates'+url;
      } else {
       if (this.currentUser) {
        if (this.currentUser.idActiveApp === AppTypeEnum.AIR) {
          url = '/air/'+url;
        } else if (this.currentUser.idActiveApp === AppTypeEnum.THERMAL) {
          url = '/thermal/'+url;
        } else if (this.currentUser.idActiveApp === AppTypeEnum.CALIBRATES) {
          url = '/calibrates/'+url;
        }
       }
      }
    }
    return url;
  }

  getInitials(): string {
    let res = '';
    if (this.user.fullName) {
      res = StringUtils.getInitials(this.user.fullName);
    }
    return res;
  }

  goToProtocol(phase: Phase): void {
    this.goToPage('protocols', Constants.FIELD_ID_PHASE, phase.id);
  }

  goToExecutions(phase: Phase): void {
    this.goToPage('executions', Constants.FIELD_ID_PHASE, phase.id);
  }

  goToInternalEquipment(folder: InternalEquipmentFolder | ExampleFlatNode): void {
    this.goToPage('internalEquipments', Constants.FIELD_ID_FOLDER, folder.id);
  }

  goToPage(page: string, field: string, id: number): void {
    let url: string;
    this.currentUser = this.userService.currentProfile;

    if (!page.includes('admin') && !page.includes('clients')) {
      if (this.route.url.includes('thermal')) {
        url = '/thermal/'+page;
      } else if (this.route.url.includes('air')) {
        url = '/air/'+page;
      } else if (this.route.url.includes('calibrates')) {
        url = '/calibrates/'+page;
      } else {
       if (this.currentUser) {
        if (this.currentUser.idActiveApp === AppTypeEnum.AIR) {
          url = '/air/'+page;
        } else if (this.currentUser.idActiveApp === AppTypeEnum.THERMAL) {
          url = '/thermal/'+page;
        } else if (this.currentUser.idActiveApp === AppTypeEnum.CALIBRATES) {
          url = '/calibrates/'+page;
        }
       }
      }
    }

    if (id != null) {
      url = `${url}?${field}=${id}`;
    }

    void this.router.navigateByUrl(url);
  }

  redirectionPage(): void {
    void this.router.navigateByUrl('/');
  }

  getThemeIconPath(): string {
    return 'assets/icons/';
  }

  onSelectionOption(option: number): void {
    this.spinnerService.show();
    this.userService.changeProfile(this.selectedGroup, option).pipe(takeUntil(this.destroy$)).subscribe(item => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.userService.saveProfile(item);
      if (option  === AppTypeEnum.AIR) {
        void this.sidenav.close();
        this.globalStateService.setHomeAirState('air-state');
        void this.router.navigate(['air']);
      } else if (option  === AppTypeEnum.THERMAL) {
        void this.sidenav.close();
        this.globalStateService.setHomeThermalState('thermal-state');
        void this.router.navigate(['thermal']);
      } else if (option  === AppTypeEnum.CALIBRATES) {
        void this.sidenav.close();
        this.globalStateService.setHomeCalibratesState('calibrates-state');
        void this.router.navigate(['calibrates']);
      }
      this.spinnerService.hide();
    }, () => {
      this.spinnerService.hide();
    });
  }

  showChangeLangIcon(): boolean {
    return this.langs?.length > 1;
  }

  public changeLang(lang: LangFlag): void {
    this.activeNameFlag = lang.lang;
    this.activeFlag = lang.flag;
    this.translationService.changeLang(lang.lang);
  }
}
