import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sensor } from 'src/app/model/sensor';
import { StringUtils } from 'src/app/utils/stringUtils';

export interface DialogDataEditSensors {
  sensors: string;
}

@Component({
  selector: 'app-protocol-edit-dialog-edit-sensors',
  templateUrl: './protocol-edit-dialog-edit-sensors.component.html'
})
export class ProtocolEditDialogEditSensorsComponent {

  constructor(private dialogRef: MatDialogRef<ProtocolEditDialogEditSensorsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataEditSensors) { }

  onOkClick(): void {
    const sensors: Sensor[] = [];

    this.data.sensors.split('\n').filter(s => StringUtils.isNotEmpty(s)).forEach(s => {
      const sensor = new Sensor();
      sensor.location = s;
      sensors.push(sensor);
    });

    this.dialogRef.close(sensors);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
