<div class="main-container-client" fxLayout="column">

    <div class="appImageContainer-client">
      <img src="assets/img/qualipharma.svg" class="appImage" alt="Logo de Qloud">
    </div>
  
   <div fxLayout="row" fxFlex="100">
        <div fxFlex="65" fxLayout="column" style="margin-top: 40px;">
            <div fxFlex="100" fxLayout="column">
                <div fxFlex="100" >
                   <span class="title">Bienvenido</span>
                   <br /> <br /><br />
                    <span class="subtitle">al nuevo portal de cliente de Qualipharma</span>
                </div>
            </div>

        </div>
        <div fxFlex="30" style="margin: auto;">
            <mat-card class="loginBoxClient">
                <mat-card-content>
                  <form #userlogin="ngForm" class="form">
                    <mat-form-field class="full-width">
                      <input matInput placeholder="{{ 'login.username.label' | translate }}" name="username" autocomplete="username"
                        [(ngModel)]="username" required (keydown.enter)="login()">
                      <mat-error>
                        {{ 'login.username.error' | translate }}
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="full-width">
                      <input matInput placeholder="{{ 'login.password.label' | translate }}" name="password"
                        autocomplete="current-password" [(ngModel)]="password" required [type]="showPassword ? 'text' : 'password'"
                        (keydown.enter)="login()">
                      <mat-error>
                        {{ 'login.password.error' | translate }}
                      </mat-error>
            
                      <button mat-icon-button matSuffix (click)="showPassword = !showPassword">
                        <mat-icon>{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                      </button>
                    </mat-form-field>
                    <p *ngIf="error" class="error">
                      {{ error }}
                    </p>
                  </form>
                </mat-card-content>
                <mat-card-actions>
                  <button mat-raised-button class="btnLogin" name="loginButton" type="button" (click)="login()" color="primary">
                    {{ 'button.login' | translate }}
                  </button><br/>
                  <a class="under-card-link" style="cursor: pointer;" (click)="forgotPassword()">
                    {{ 'login.forgottenPassword.label' | translate }}
                  </a>
                </mat-card-actions>
              </mat-card>
        </div>
   </div>
   <br/>
   <br/>
   <div [fxFlex]="100" fxLayout="column">
        <span style="font-weight: 700; font-size: 20px;"> En este nuevo portal podrás: </span>
        <br />
        <span style="font-size: 16px;"> 1. <u>Navegar sin esfuerzo a través de todos tus informes de calibraciones y ensayos</u>, y descargarlos sin necesidad de utilizar papel. </span>
        <br />
        <span style="font-size: 16px;"> 2. Estar seguro. Hemos implementado las últimas medidas de seguridad para garantizar que <u>tus datos esten protegidos en todo momento. Tu tranquilidad es nuestra prioridad</u> </span>
        <br />
        <span style="font-size: 16px;"> 3.<u> Mantenerte actualizado</u> con notificaciones instantáneas y comunicación directa. Estamos más cerca de ti que nunca.</span>
        <br />
        <span style="font-size: 16px;"> 4. Disfrutar de <u>funcionalidades avanzadas para poder realizar tus procesos internos</u>: gestionar tu plan de calibraciones, programar tu plan anual en el        sistema, revisar y firmar informes en la herramienta, y mucho más. </span>
   </div>
  
  
  </div>