import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppTypeEnum } from 'src/app/model/appType';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-home-qualificates',
  templateUrl: './home-qualificates.component.html',
  styleUrls: ['./home-qualificates.component.scss']
})
export class HomeQualificatesComponent implements OnInit {

  roouterPath: boolean;

  constructor(
    private router: Router,
    private themeService: ThemeService
  ) { }

  ngOnInit(): void {
  }

  getTheme(): string {
    return this.themeService.getThemeHome(AppTypeEnum.QUALIFICATES);
  }

  goToPage(page: string): void {
    this.roouterPath = true;
    void this.router.navigateByUrl(page);
    
  }

  getLogo(): string {
    return 'assets/img/qloud_qualificates.png';
  }

  getText(): string {
    return 'landing.main.qualificates';
  }

  newProtocolqualificates(): void {
    void this.router.navigateByUrl('/qualificates/protocol?id=0');
  }

  newExecutionqualificates(): void {
    void this.router.navigateByUrl('/qualificates/execution?id=0');
  }

}
