export class ValidatorSpecifics {

    lowTemp: number;
    highTemp: number;
    verifTemp: number;

    lowDesv: number;
    highDesv: number;
    verifDesv: number;

    minStability: number;
    minStabilityTime: number;

    lowTempProtocol: number;
    highTempProtocol: number;
    verifTempProtocol: number;

    lowDesvProtocol: number;
    highDesvProtocol: number;
    verifDesvProtocol: number;

    minStabilityProtocol: number;
    minStabilityTimeProtocol: number;

    valueChangeReason: string;

    showDiffInDeviations: boolean;
    showDiffInObservations: boolean;
    fromProtocol: boolean;

}
