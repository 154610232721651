<h1 mat-dialog-title>{{tituloDialog}}</h1>

<div mat-dialog-content class="thermal-theme" style="min-height: 50vh;">

    <div fxLayout="row" class="flexwrap">

        <div fxFlex="50" fxFlex.lt-md="100%">
            <mat-form-field class="campoFormulario">
                <mat-label>{{ 'protocolEdit.dialog.essay.essayType.label' | translate }}</mat-label>
                <mat-select name="data.essay.idType" [(ngModel)]="data.essay.idType"
                    (selectionChange)="onChangeTypeSelect($event)" [disabled]="data.disabled">
                    <mat-option *ngFor="let item of essaysPossibles" [value]="item.id">
                        {{ 'essayType.' + item.translation | translate }}
                    </mat-option>
                </mat-select>
                <mat-error>
                    {{ 'protocolEdit.dialog.essay.essayType.error' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100%">
            {{ 'protocolEdit.dialog.essay.variablesToUse.label' | translate }}:
            <div *ngFor="let item of variablesPossibles; let i = index">
                <mat-checkbox (change)="onChangeVariableCheck($event)" [value]="item"
                    [disabled]="data.disabled || item.required" [checked]="isVariableChecked(item)">
                    {{'variable.' + item.translation | translate }}
                </mat-checkbox>
            </div>
        </div>

    </div>

    <div fxLayout="column">
        <div fxFlex="100%">
            <mat-form-field class="campoFormulario" style="width: 100%">
                <input matInput placeholder="{{ 'protocolEdit.essays.title.label' | translate }}"
                    [(ngModel)]="data.essay.title" name="essayTitle">
            </mat-form-field>
        </div>
        <div fxFlex="100%">
            <mat-form-field class="campoFormulario" style="width: 100%">
                <textarea rows="5" matAutosizeMinRows="5" matInput
                    placeholder="{{ 'protocolEdit.essays.additionalInfo' | translate }}"
                    [(ngModel)]="data.essay.additionalInfo" name="additionalInfo" style="resize: none;"></textarea>
            </mat-form-field>
        </div>
    </div>

    <mat-tab-group style="width: 100%;" (selectedTabChange)="onEssayTabChange($event)">

        <mat-tab *ngFor="let tab of data.essay.essayValues; let i = index"
            label="{{'variable.' + tab.translationVariable | translate }}" style="min-height: 50vh;">

            <mat-toolbar color="secondary">
                {{ 'protocolEdit.dialog.essay.fieldsTitle' | translate }}
            </mat-toolbar>

            <div fxLayout="row">
                <div fxFlex="33" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                        <mat-label>{{ 'protocolEdit.dialog.essay.unit.label' | translate }}</mat-label>
                        <mat-select name="unit{{tab.idVariable}}" [(ngModel)]="tab.idUnit" required
                            (selectionChange)="onUnitChange(indexVariable, $event)"
                            [disabled]="variableUnits?.length < 2">
                            <mat-option *ngFor="let item of variableUnits" [value]="item.id">
                                {{item.unit}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            {{ 'protocolEdit.dialog.essay.unit.error' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row" class="flexwrap">
                <div fxFlex="50" fxFlex.lt-md="100%"
                    *ngFor="let field of data.essay.essayValues[i].fields; let indexField = index">

                    <!-- STRING -->
                    <div *ngIf="field.idTypeField == 1">
                        <mat-form-field class="campoFormulario">
                            <input matInput
                                placeholder="{{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}"
                                [name]="field.translationField" [(ngModel)]="field.value" [errorStateMatcher]="matcher"
                                (ngModelChange)="setValue(tab.idVariable, field, -1, $event)" [disabled]="data.disabled"
                                [required]="field.required">
                            <mat-error>
                                {{ 'protocolEdit.dialog.essay.' + field.translationField + '.error' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- NUMÉRICO -->
                    <div *ngIf="field.idTypeField == 2">
                        <mat-form-field class="campoFormulario">
                            <input matInput
                                placeholder="{{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}"
                                [name]="field.translationField" [(ngModel)]="field.value" [errorStateMatcher]="matcher"
                                (ngModelChange)="setValue(tab.idVariable, field, -1, $event)" type="number"
                                [disabled]="data.disabled" [required]="field.required">
                            <mat-error>
                                {{ 'protocolEdit.dialog.essay.' + field.translationField + '.error' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- TOLERANCIA -->
                    <div *ngIf="field.idTypeField == 3">
                        <div fxFlex="40%" fxFlex.lt-md="100%">
                            <mat-form-field class="campoFormulario">
                                <input matInput
                                    placeholder="{{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}"
                                    [name]="field.translationField" [(ngModel)]="field.value[0]"
                                    (ngModelChange)="setValue(tab.idVariable, field, 0, $event)"
                                    [errorStateMatcher]="matcher" type="number" [disabled]="data.disabled"
                                    [required]="field.required">
                                <mat-error>
                                    {{ 'protocolEdit.dialog.essay.' + field.translationField + '.error' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="40%" fxFlex.lt-md="100%">
                            ±
                            <mat-form-field class="campoFormulario">
                                <input matInput
                                    placeholder="{{ 'protocolEdit.dialog.essay.valueTolerance.label' | translate }}"
                                    [name]="field.translationField + 'Tolerance'" [(ngModel)]="field.value[1]"
                                    (ngModelChange)="setValue(tab.idVariable, field, 1, $event)"
                                    [errorStateMatcher]="matcher" type="number" [disabled]="data.disabled"
                                    [required]="field.required">
                                <mat-error>
                                    {{ 'protocolEdit.dialog.essay.valueTolerance.error' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- DIA HORA MIN -->
                    <div *ngIf="field.idTypeField == 4">
                        <div fxLayout="row">
                            <div fxFlex="34%" fxFlex.lt-md="100%">
                                <mat-form-field class="campoFormulario">
                                    <input matInput [name]="field.translationField + 'Day'" [(ngModel)]="field.value[0]"
                                        (ngModelChange)="setValue(tab.idVariable, field, 0, $event)"
                                        placeholder="{{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}: {{ 'common.days.label' | translate }}"
                                        [errorStateMatcher]="matcher" type="number" [disabled]="data.disabled"
                                        [required]="field.required" min="0" min="0">
                                </mat-form-field>
                            </div>
                            <div fxFlex="33%" fxFlex.lt-md="100%">
                                <mat-form-field class="campoFormulario">
                                    <input matInput [name]="field.translationField + 'Hour'"
                                        [(ngModel)]="field.value[1]"
                                        (ngModelChange)="setValue(tab.idVariable, field, 1, $event)"
                                        placeholder="{{ 'common.hours.label' | translate }}"
                                        [errorStateMatcher]="matcher" type="number" [disabled]="data.disabled"
                                        [required]="field.required" min="0" min="0">
                                </mat-form-field>
                            </div>
                            <div fxFlex="33%" fxFlex.lt-md="100%">
                                <mat-form-field class="campoFormulario">
                                    <input matInput [name]="field.translationField + 'Minute'"
                                        [(ngModel)]="field.value[2]"
                                        (ngModelChange)="setValue(tab.idVariable, field, 2, $event)"
                                        placeholder="{{ 'common.minutes.label' | translate }}"
                                        [errorStateMatcher]="matcher" type="number" [disabled]="data.disabled"
                                        [required]="field.required" min="0">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <!-- SELECT_ONE -->
                    <div *ngIf="field.idTypeField == 6">
                        <span>
                            {{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}
                        </span>
                        <br />
                        <mat-radio-group class="vertical-radio-group" [required]="field.required">
                            <mat-radio-button class="vertical-radio-button" *ngFor="let doma of field.fieldDomain"
                                [value]="doma.value">
                                {{ 'protocolEdit.dialog.essay.' + doma.translation + '.label' | translate }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <!-- SELECT_MULTI -->
                    <div *ngIf="field.idTypeField == 7">
                        <span>
                            {{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}
                            <span *ngIf="field.required">*</span>
                        </span>
                        <mat-selection-list [disabled]="data.disabled"
                            (selectionChange)="setValue(tab.idVariable, field, -1, $event)">
                            <mat-list-option *ngFor="let doma of field.fieldDomain" checkboxPosition="before"
                                [disabled]="data.disabled" [value]="doma.value"
                                [selected]="isSelectedMulti(field.value, doma.value)">
                                {{ 'protocolEdit.dialog.essay.' + doma.translation + '.label' | translate }}
                            </mat-list-option>
                        </mat-selection-list>
                    </div>

                    <!-- BOOLEAN -->
                    <div *ngIf="field.idTypeField == 8">
                        <mat-checkbox [value]="field.value" [checked]="field.value == 'true'" [disabled]="data.disabled"
                            (change)="setValue(tab.idVariable, field, -1, $event)">
                            {{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}
                        </mat-checkbox>
                    </div>

                    <!-- AREA -->
                    <div *ngIf="field.idTypeField == 9">
                        <mat-form-field class="campoFormulario">
                            <textarea matInput [value]="field.value" [name]="field.translationField" rows="5"
                                (input)="setValue(tab.idVariable, field, 0, $event)" [required]="field.required"
                                placeholder="{{ 'protocolEdit.dialog.essay.' + field.translationField + '.label' | translate }}"></textarea>
                            <mat-error>
                                {{ 'protocolEdit.dialog.essay.' + field.translationField + '.error' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>

                </div>
                <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="data.essay.essayValues[i].fields.length == 0"
                    style="margin-top: 2em;margin-bottom: 2em;">
                    {{ 'protocolEdit.dialog.essay.fields.empty' | translate }}
                </div>
            </div>

            <mat-toolbar color="secondary">
                {{ 'protocolEdit.dialog.essay.criteriaTitle' | translate }}

                <button mat-icon-button color="accent" (click)="openNewCriteria(i)">
                    <mat-icon>add</mat-icon>
                </button>
            </mat-toolbar>

            <div fxLayout="row" class="flexwrap">
                <div fxFlex>

                    <table mat-table matSort #criteriaTable [dataSource]="data.essay.essayValues[i].criterias"
                        style="width: 95%;">

                        <!-- criteria Column -->
                        <ng-container matColumnDef="criteria">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'protocolEdit.dialog.essay.criteria.cols.criteria' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item"> {{ showCriteria(item) }} </td>
                        </ng-container>

                        <!-- Button edit Column -->
                        <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
                            <td mat-cell *matCellDef="let element; let criteriaIndex = index">
                                <button mat-icon-button (click)="editCriteria(i, criteriaIndex)">
                                    <mat-icon>mode_edit</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <!-- Button delete Column -->
                        <ng-container matColumnDef="delete">
                            <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
                            <td mat-cell *matCellDef="let element; let criteriaIndex = index">
                                <button mat-icon-button (click)="removeCriteria(i, criteriaIndex)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColsCriteria"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColsCriteria;"></tr>

                    </table>

                </div>
            </div>

            <mat-toolbar color="secondary" *ngIf="showSensorsSection()">
                {{ 'protocolEdit.dialog.essay.sensorsTitle' | translate }}

                <button mat-icon-button color="accent" (click)="openManualEditSensors(tab.idVariable)"
                    *ngIf="showSensorsSection() && showOptionalSensorsSection()">
                    <mat-icon>format_list_numbered</mat-icon>
                </button>

                <button mat-icon-button color="primary" (click)="openDialogPhoto(tab.idVariable)"
                    *ngIf="!data.disabled && canUploadPhoto() && isPhotoUploaded(tab.idVariable)">
                    <mat-icon>image</mat-icon>
                </button>
                <button mat-icon-button color="accent" (click)="fileInput.click()"
                    *ngIf="!data.disabled && canUploadPhoto() && !isPhotoUploaded(tab.idVariable)">
                    <mat-icon>image</mat-icon>
                </button>
                <input accept="image/*" hidden type="file" (change)="onFileUpload(tab.idVariable, $event)" #fileInput>
            </mat-toolbar>

            <mat-checkbox *ngIf="showOptionalSensorsCheck()" (change)="onChangeCopySensorsCheck($event)"
                [value]="isCheckedCopySensors()" [checked]="isCheckedCopySensors()" [disabled]="data.disabled">
                {{ 'protocolEdit.dialog.essay.sensors.copy' | translate }}
            </mat-checkbox>

            <div fxLayout="row" class="flexwrap" *ngIf="showSensorsSection() && showOptionalSensorsSection()">
                <div fxFlex="40" fxFlex.lt-md="100%">
                    <mat-form-field class="campoFormulario">
                        <input matInput [name]="numSensor" [(ngModel)]="numSensor[tab.idVariable]" required
                            (ngModelChange)="setNumSensor(tab.idVariable, $event)" maxlength="2"
                            placeholder="{{ 'protocolEdit.dialog.essay.sensors.numSensor.label' | translate }}"
                            [errorStateMatcher]="matcher" type="number" [disabled]="data.disabled"
                            onkeypress="return event.charCode >= 48" min="1" max="99">
                        <mat-error>
                            {{ 'protocolEdit.dialog.essay.sensors.numSensor.error' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="40">
                    <mat-form-field class="campoFormulario">
                        <input matInput [name]="percentSensorsCanFail"
                            [(ngModel)]="data.essay.essayValues[i].percentSensorsCanFail" required
                            placeholder="{{ 'protocolEdit.dialog.essay.criteria.percentSensorsCanFail.label' | translate }}"
                            [errorStateMatcher]="matcher" type="number" [disabled]="data.disabled"
                            onkeypress="return event.charCode >= 48" min="0" max="99">
                        <mat-error>
                            {{ 'protocolEdit.dialog.essay.criteria.percentSensorsCanFail.error' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="100%">
                    <div fxLayout="row" class="flexwrap"
                        *ngFor="let sensor of data.essay.essayValues[i].sensors; let indexSensor = index">
                        <div fxFlex="20" fxFlex.lt-md="100%">
                            <mat-form-field class="campoFormulario">
                                <input matInput name="numSensor{{indexSensor}}" value="{{ indexSensor + 1 }}" disabled
                                    placeholder="{{ 'protocolEdit.dialog.essay.sensors.sensorNum.label' | translate }}">
                            </mat-form-field>
                        </div>
                        <div fxFlex="70" fxFlex.lt-md="100%">
                            <mat-form-field class="campoFormulario">
                                <input matInput name="numSensor{{indexSensor}}" [(ngModel)]="sensor.location"
                                    [required]="!variableHasImage(data.essay.essayValues[i])"
                                    placeholder="{{ 'protocolEdit.dialog.essay.sensors.location.label' | translate }}"
                                    [disabled]="data.disabled">
                                <mat-error>
                                    {{ 'protocolEdit.dialog.essay.sensors.location.error' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="10" fxFlex.lt-md="100%" *ngIf="sensor.hasBioindicator">
                            {{ 'protocolEdit.dialog.essay.sensors.bioStamp' | translate }}
                        </div>
                        <div fxFlex="10" fxFlex.lt-md="100%" *ngIf="sensor.hasEndotoxin">
                            {{ 'protocolEdit.dialog.essay.sensors.endotoxinStamp' | translate }}
                        </div>
                    </div>
                </div>
            </div>

        </mat-tab>
    </mat-tab-group>

</div>
<div mat-dialog-actions align="end" class="thermal-theme">
    <button mat-raised-button color="primary" (click)="onOkClick()" *ngIf="!data.disabled">
        {{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
</div>