/* eslint-disable max-len */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { LoginService } from '../services/login.service';
import { SnackBarService } from '../services/snackBar.service';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private loginService: LoginService, private snackbarService: SnackBarService, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        let token: string = localStorage.getItem('token');
        let request = req;
        if (token && (!request.url.startsWith('api/public') || (!request.url.startsWith('auth/login')))) {
            request = req.clone({
                setHeaders: {
                    Authorization: `${token}`
                },
                withCredentials: true
            });
        }
        return next.handle(request).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              if (event.url?.includes('/api/auth')) {
                if (event.ok) {
                    token = event.headers.get('Authorization');
                    if (token) {
                        localStorage.setItem('token',token);
                    }
                 } else {
                   console.log('Error');
                 }
              }
            }
            return event;
          }),
          catchError((err:HttpErrorResponse) => this.handleAuthError(err)));
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        // Handle your auth error or rethrow

        // Si da un error y vamos contra el login, dejamos que sea la pantalla de login la que se encargue de manejarlo
        if (err != null && err.url != null && err.url.includes('login')) {
            return throwError(err);
        }

        // En caso de error de no autenticado, invalidamos la sesión
        if (err != null && (err.status === HttpStatusCode.Unauthorized || err.status === HttpStatusCode.Forbidden)) {
            const msg = 'No tiene permiso para realizar esta acción. Si piensa que es un error, contacte con su administrador del sistema';
            this.snackbarService.sendError(msg);
            this.loginService.clearSession();

            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to
            // handle it as well.
            return of(err.message);
        }
        return throwError(err);
    }

}
