<h1 mat-dialog-title>{{'confirmPassword.title' | translate}}</h1>

<div mat-dialog-content [class]="getTheme()">

  <p> {{'confirmPassword.text' | translate}} </p>

  <div fxLayout="row" class="flexwrap">
    <div fxFlex>
      <mat-form-field class="campoFormulario">
        <input matInput placeholder="{{ 'login.password.label' | translate }}" name="password"
          autocomplete="current-password" [(ngModel)]="password" required [type]="hidePassword ? 'password' : 'text'"
          (keydown.enter)="onOkClick()">
        <mat-error>
          {{ 'login.password.error' | translate }}
        </mat-error>

        <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
          <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

</div>

<div mat-dialog-actions align="end" [class]="getTheme()">
  <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
  <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
</div>