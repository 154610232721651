<h1 mat-dialog-title>{{ 'protocolEdit.attachments.dialog.title' | translate }}</h1>
<form>
  <div mat-dialog-content class="thermal-theme" *ngIf="data.attachment">

    {{ 'protocolEdit.attachments.cols.filename' | translate }}: {{ data.attachment.filename }}

    <mat-form-field class="campoFormulario">
      <input matInput placeholder="{{ 'protocolEdit.attachments.dialog.name' | translate }}" name="attName"
        [(ngModel)]="data.attachment.name">
    </mat-form-field>

    <mat-form-field class="campoFormulario">
      <mat-label>{{ 'protocolEdit.attachments.dialog.type' | translate }}</mat-label>
      <mat-select name="idType" [ngModel]="data.attachment.idType" (selectionChange)="onTypeChange($event)">
        <mat-option *ngFor="let type of data.types" [value]="type.id">
          {{ 'attachmentType.' + type.translation | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>
  <div mat-dialog-actions class="getTheme()">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()" cdkFocusInitial>
      {{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>