<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form>
  <div mat-dialog-content class="air-theme" fxLayout="column">

    <div fxLayout="row" class="flexwrap">
      <div fxFlex="45" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput [(ngModel)]="data.field.name" name="name" required
            placeholder="{{ 'protocolEdit.dialog.area.name.label' | translate }}">
          <mat-error>
            {{ 'protocolEdit.dialog.area.name.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="25" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'calibrationPlan.customFieldInternalEquipment.dialog.type' | translate }}</mat-label>
          <mat-select name="unit" [(ngModel)]="data.field.idType">
            <mat-option *ngFor="let item of fieldTypes" [value]="item.id">
              {{ showNameTypeName(item.name) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex="25" fxFlex.lt-md="100%" style="margin-top: 1em;">
        <span> {{ 'calibrationPlan.customFieldInternalEquipment.dialog.filtrable' | translate }} </span>
        <mat-radio-group [(ngModel)]="data.field.filtrable" name="filtrable" [disabled]="!isFiltrable()">
          <mat-radio-button [value]="true"> {{'common.yes' | translate}} </mat-radio-button>
          <mat-radio-button [value]="false"> {{'common.no' | translate}} </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div fxFlex="25" *ngIf="validateSelect()">
      <button mat-raised-button color="primary" (click)="newCustomSelect()">{{ 'button.add' | translate }}</button>
    </div>

    <div fxLayout="row" class="flexwrap" *ngFor="let item of selectValueCustom;  let indexCustom = index" style="margin-top: 15px;">
      <div fxFlex="30" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput [(ngModel)]="item.value" [name]="'name'+indexCustom" required
            placeholder="{{ 'calibration.plan.custom.field.select.value' | translate }}">
          <mat-error>
            {{ 'calibration.plan.custom.field.select.value.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="30" fxFlex.lt-md="100%">
        <button mat-icon-button (click)="deleteCustomSelect(indexCustom)" matTooltip="{{ 'button.delete' | translate }}">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

  </div>
  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>