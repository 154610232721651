<form>
    <div mat-dialog-content class="air-theme">

        <div fxLayout="row" class="flexwrap">

            <div fxFlex="50" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <mat-label>{{ 'protocolEdit.dialog.equipment.type.label' | translate }}</mat-label>
                    <mat-select name="idType" [(ngModel)]="data.filter.idType"
                        (selectionChange)="onChangeTypeFilter($event)" [disabled]="!enableEditEssay()">
                        <mat-option *ngFor="let item of this.data.filterTypes" [value]="item.id">
                            {{ 'filterType.' + item.translation | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{ 'protocolEdit.dialog.equipment.type.error' | translate }}    
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="showFiltersType">
                <mat-form-field class="campoFormulario">
                    <mat-label>{{ 'protocolEdit.dialog.equipment.type.label' | translate }}</mat-label>
                    <mat-select name="idTypeFilter" [(ngModel)]="data.filter.idTypeFilter" [disabled]="!enableEditEssay()">
                        <mat-option *ngFor="let item of this.data.accessoryFilterTypes" [value]="item.id">
                            {{ 'filterType.' + item.translation | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{ 'protocolEdit.dialog.equipment.type.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" class="flexwrap">
            <div fxFlex="50" fxFlex.lt-md="100%">
                <mat-radio-group name="shape" (change)="onShapeChange($event)" [(ngModel)]="data.filter.shape"
                [disabled]="!enableEditEssay()">
                    <mat-radio-button [value]="1">{{ 'filterShape.rectangular' | translate }}</mat-radio-button>
                    <mat-radio-button [value]="2">{{ 'filterShape.circular' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div fxLayout="row" class="flexwrap">
            <div fxFlex="30" fxFlex.lt-md="100%" *ngIf="showRectangular">
                <div fxLayout="column">
                    <div>
                        <mat-form-field class="campoFormulario">
                            <input matInput placeholder="{{ 'filterType.filter.air.filter.length' | translate }}"
                                name="length" type="number"  min="0" [(ngModel)]="data.filter.length" [disabled]="!enableEditEssay()">
                            <mat-error>
                                {{ 'protocolEdit.dialog.room.length.error' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div fxFlex="30" fxFlex.lt-md="100%" *ngIf="showRectangular">
                <div fxLayout="column">
                    <div>
                        <mat-form-field class="campoFormulario">
                            <input matInput placeholder="{{ 'filterType.filter.air.filter.width' | translate }}"
                                name="width" type="number"  min="0" [(ngModel)]="data.filter.width" [disabled]="!enableEditEssay()">
                            <mat-error>
                                {{ 'protocolEdit.dialog.room.height.error' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div fxFlex="30" fxFlex.lt-md="100%" *ngIf="showRectangular">
                <div fxLayout="column">
                    <div>
                        <mat-form-field class="campoFormulario">
                            <input matInput placeholder="{{ 'filterType.filter.air.filter.height' | translate }}"
                                name="height" type="number"  min="0" [(ngModel)]="data.filter.height" [disabled]="!enableEditEssay()">
                            <mat-error>
                                {{ 'protocolEdit.dialog.room.height.error' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div fxFlex="30" fxFlex.lt-md="100%" *ngIf="showDiameter">
                <div fxLayout="column">
                    <div>
                        <mat-form-field class="campoFormulario">
                            <input matInput placeholder="{{ 'filterType.filter.air.filter.diameter' | translate }}"
                                name="size{{indexValue}}" type="number"  min="0" [(ngModel)]="data.filter.diameter"
                                [disabled]="!enableEditEssay()">
                            <mat-error>
                                {{ 'protocolEdit.dialog.room.diameter.error' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div fxFlex="40" fxFlex.lt-md="100%" *ngIf="showTotalFiltersSecondStage()">
                <mat-form-field class="campoFormulario">
                    <input matInput required min="0" name="totalFiltersSecondStage"
                        [(ngModel)]="data.filter.totalFiltersSecondStage" type="number"
                        placeholder="{{ 'protocolEdit.dialog.equipment.totalFiltersSecondStage.label' | translate }}"
                        [disabled]="!enableEditEssay()">
                    <mat-error>
                        {{ 'protocolEdit.dialog.equipment.totalFiltersSecondStage.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div fxLayout="row" class="flexwrap" *ngIf="data.isExecution">

            <div fxFlex="100%">
                <mat-radio-group name="changeFilter" [(ngModel)]="changeFilter" [disabled]="!enableEditEssay()">
                    <span> {{ 'appPlanEdit.administration.changeFilter.label' | translate }} </span>
                    <mat-radio-button [value]="true">{{ 'common.yes' | translate }}</mat-radio-button>
                    <mat-radio-button [value]="false">{{ 'common.no' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>

            <div fxFlex="40" fxFlex.lt-md="100%" *ngIf="changeFilter">
                <mat-form-field class="campoFormulario">
                    <input matInput required name="maker" [(ngModel)]="data.filter.maker"
                        placeholder="{{ 'protocolEdit.dialog.equipment.maker.label' | translate }}">
                    <mat-error>
                        {{ 'protocolEdit.dialog.equipment.maker.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxFlex="40" fxFlex.lt-md="100%" *ngIf="changeFilter">
                <mat-form-field class="campoFormulario">
                    <input matInput required name="serialNum" [(ngModel)]="data.filter.serialNum"
                        placeholder="{{ 'protocolEdit.dialog.equipment.serialNum.label' | translate }}"
                        [disabled]="!enableEditEssay()">
                    <mat-error>
                        {{ 'protocolEdit.dialog.equipment.serialNum.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxFlex="20" fxFlex.lt-md="100%" *ngIf="changeFilter">
                <button mat-icon-button (click)="uploadCertificateInput.click()" color="accent"
                    matTooltip="{{ 'button.upload-certificate' | translate }}"
                    [disabled]="!enableEditEssay()">
                    <mat-icon>cloud_upload</mat-icon>
                </button>
                <input accept="application/pdf" hidden type="file" (change)="uploadCertificate($event)"
                    #uploadCertificateInput>
                <button mat-icon-button color="accent" (click)="downloadCertificate()"
                    *ngIf="data.filter.idCertificate != null" matTooltip="{{ 'button.download' | translate }}">
                    <mat-icon>cloud_download</mat-icon>
                </button>
            </div>
        </div>

    </div>

    <div mat-dialog-actions class="air-theme">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>

</form>