export class EssayExecutionAirInternalEquipment {
    id: number;

    idEquipment: number;
    idEssay: number;

    equipmentName: string;

}

