import { NumberUtils } from './numberUtils';

export class ArrayUtils {

  static sum(arr: number[]): number {
    return arr.reduce((a, b) => a + b, 0);
  }

  static isEmpty(array: any[]): boolean {
    if (array == null) {
      return true;
    }

    return Array.isArray(array) && array.length === 0;
  }

  static isNotEmpty(array: any[]): boolean {
    return !this.isEmpty(array);
  }

  static max(array: number[]): number {
    return this.isEmpty(array) ? null : array.reduce((a, b) => Math.max(a, b));
  }

  static min(array: number[]): number {
    return this.isEmpty(array) ? null : array.reduce((a, b) => Math.min(a, b));
  }

  static flat<T>(array: T[][]): T[] {
    const flatten = a => Array.isArray(a) ? [].concat(...a.map(flatten)) : a as T;

    return flatten(array) as T[];
  }

  static coalesce<T>(array: T[]): T[] {
    const coal = (...args) => args.find(_ => ![undefined, null].includes(_));

    return coal(array) as T[];
  }

  static unique<T>(value: T, index: number, self: T[]): boolean {
    return self.indexOf(value) === index;
  }

  static groupBy(list: any[], keyGetter: (item: any) => any): Map<any, any> {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  static calcAvg(list: number[], fixDecimals = true): number {
    if (this.isEmpty(list)) {
      return 0;
    }

    let numberDecimals = 10;

    if (fixDecimals) {
      numberDecimals = Math.max(...list.map(l => NumberUtils.countDecimals(l))) || numberDecimals;
    }

    const avg = NumberUtils.average(list);

    return +(avg).toFixed(numberDecimals);
  }

  static removeRepeated<T>(list: T[]): T[] {
    return list.filter((v, i) => list.indexOf(v) === i);
  }

  static sortByField<T>(list: T[], field: string): T[] {
    return list.sort(this.dynamicSort(field));
  }

  static moveToIndex<T>(arr: T[], fromIndex: number, toIndex: number): T[] {
    if (this.isEmpty(arr)) {
      return arr;
    }

    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);

    if (toIndex !== 0) {
      arr.splice(toIndex, 0, element);
    } else {
      arr.unshift(element);
    }

    return arr;
  }

  static moveToFirst<T>(arr: T[], index: number): T[] {
    return this.moveToIndex(arr, index, 0);
  }

  static moveToPrevious<T>(arr: T[], index: number): T[] {
    if (index > 0) {
      arr = this.moveToIndex(arr, index, index - 1);
    }

    return arr;
  }

  static moveToNext<T>(arr: T[], index: number): T[] {
    if (index < arr.length) {
      arr = this.moveToIndex(arr, index, index + 1);
    }

    return arr;
  }

  static moveToLast<T>(arr: T[], index: number): T[] {
    return this.moveToIndex(arr, index, arr.length);
  }

  private static dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a, b) => {
      // next line works with strings and numbers,  and you may want to customize it to your needs

      const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    };
  }

}
