<mat-tab-group #generalTabs (selectedTabChange)="onTabChange($event)">
    <mat-tab #generalTab label="{{ 'executionEdit.general.title' | translate }}">

        <ng-template mat-tab-label>
            {{ 'executionEdit.general.title' | translate }}
        </ng-template>

        <form>
            <mat-card class="card">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'executionEdit.general.title' | translate }}
                    </mat-toolbar>

                    <div fxLayout="row" class="flexwrap" fxLayout.xs="column">

                        <div fxFlex>
                            <div fxLayout="column">
                                <div fxFlex *ngIf="execution.documentCode != null">
                                    <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
                                        <div fxFlex>
                                            <mat-form-field class="campoFormulario">
                                                <input matInput name="documentCode"
                                                    [(ngModel)]="execution.documentCode"
                                                    placeholder="{{ 'executionEdit.general.documentCode.label' | translate }}" disabled>
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex="65px" fxFlex.lt-md="50px"
                                         *ngIf="canEditDocumentCode() && canAdd()">
                                            <button mat-icon-button (click)="editDocumentCode()">
                                                <mat-icon>mode_edit</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div fxFlex [hidden]="execution?.id != null">
                                    <span> {{ 'executionEdit.protocol.useExisting' | translate }} </span>
                                    <mat-radio-group [ngModel]="useExistingProtocol" name="useExistingProtocol"
                                        (change)="onUseExistingProtocolChange($event)" [disabled]="true">
                                        <mat-radio-button [value]="true"> {{'common.yes' | translate}}
                                        </mat-radio-button>
                                        <div fxFlex="15px"></div>
                                        <mat-radio-button [value]="false"> {{'common.no' | translate}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div fxFlex [hidden]="execution.id != null || !useExistingProtocol">
                                    <mat-form-field class="campoFormulario">
                                        <input matInput required [ngModel]="execution.idProtocol"
                                            [disabled]="execution.id != null"
                                            placeholder="{{ 'executionEdit.general.protocol.label' | translate }}"
                                            #protocolAutocomplete #trigger="matAutocompleteTrigger"
                                            [matAutocomplete]="auto" (keyup)="lookupProtocol($event)"
                                            [ngModelOptions]="{standalone: true}">
                                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                                            [displayWith]="displayProtocol" (optionSelected)="onProtocolChange($event)">

                                            <mat-option *ngIf="loadingAutocomplete$ | async">
                                                <mat-spinner diameter="50" class="centerIcon"></mat-spinner>
                                            </mat-option>

                                            <ng-container *ngIf="!(loadingAutocomplete$ | async)">
                                                <mat-option *ngFor="let prot of protocolsFiltered" [value]="prot">
                                                    {{ displayProtocol(prot) }}
                                                </mat-option>
                                            </ng-container>

                                        </mat-autocomplete>
                                        <mat-error>
                                            {{ 'executionEdit.general.protocol.error' | translate }}
                                        </mat-error>

                                        <button matSuffix mat-icon-button color="accent" (click)="openSearchProtocol()">
                                            <mat-icon style="font-size: large;">search</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </div>

                                <div fxFlex *ngIf="execution.id != null && protocol?.id != null">
                                    <div fxFlex *ngIf="protocol">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput
                                                placeholder="{{ 'executionEdit.general.protocol.label' | translate }}"
                                                name="protocolDocumentCode" [ngModel]="protocol?.documentCode" disabled>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="65px" fxFlex.lt-md="50px">
                                        <button mat-icon-button (click)="downloadProtocolPdf()">
                                            <mat-icon class="hidden-sm">cloud_download</mat-icon>
                                        </button>
                                    </div>
                                </div>


                                <div fxFlex="50" fxFlex.lt-md="100%">
                                    <mat-form-field class="campoFormulario">
                                        <input matInput name="clientDocumentCode"
                                            [(ngModel)]="execution.clientDocumentCode" [errorStateMatcher]="matcher"
                                            placeholder="{{ 'executionEdit.general.clientDocumentCode.label' | translate }}">
                                    </mat-form-field>
                                </div>

                                <div fxFlex="50">
                                    <mat-form-field class="campoFormulario" *ngIf="protocol?.id != null">
                                        <mat-label>{{ 'executionEdit.protocol.process.label' | translate
                                            }}</mat-label>
                                        <mat-select name="idProcess" [(ngModel)]="execution.idProcess"
                                            [disabled]="!enableEditTest()">
                                            <mat-option *ngFor="let item of processes" [value]="item.id">
                                                {{ 'processType.' + item.translation | translate }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div fxFlex>

                            <div fxLayout="row" class="flexwrap" fxLayout.xs="column" [hidden]="execution.id == null">
                                <div fxFlex="50" fxFlex.lt-md="100%">
                                    <mat-form-field class="campoFormulario">
                                        <input matInput name="regUser" disabled [(ngModel)]="execution.regUser"
                                            placeholder="{{ 'executionEdit.general.regUser.label' | translate }}">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="50" fxFlex.lt-md="100%">
                                    <mat-form-field class="campoFormulario">
                                        <input matInput name="modUser" disabled [(ngModel)]="execution.modUser"
                                            placeholder="{{ 'executionEdit.general.modUser.label' | translate }}">
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" class="flexwrap">
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="regFc"
                                                [ngModel]="execution.regFc | fixDate" disabled
                                                placeholder="{{ 'executionEdit.general.regFc.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="50" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="modFc"
                                                [ngModel]="execution.modFc | fixDate" disabled
                                                placeholder="{{ 'executionEdit.general.modFc.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div fxFlex="50" fxFlex.lt-md="100%">
                                    <div fxLayout="row" fxLayout.xs="column" class="flexwrap"
                                        [hidden]="execution.id == null">
                                        <div fxFlex fxFlex.lt-md="100%">
                                            <mat-form-field class="campoFormulario">
                                                <input matInput name="status" disabled
                                                    value="{{ execution.id != null ? ('executionStatus.' + execution.statusTranslation | translate) : '' }}"
                                                    placeholder="{{ 'protocolEdit.general.status.label' | translate }}">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <div fxLayout="row" class="flexwrap">
                                    <div fxFlex="100" fxFlex.lt-md="100%" *ngIf="execution.currentVersion != null">
                                        <div fxLayout="row">
                                            <div fxFlex="50">
                                                <mat-form-field class="campoFormulario">
                                                    <input matInput name="currentVersion"
                                                        [ngModel]="execution.currentVersion" disabled
                                                        placeholder="{{ 'executionEdit.general.currentVersion.label' | translate }}">
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="50">
                                                <mat-form-field class="campoFormulario">
                                                    <input matInput name="signedVersion"
                                                        [ngModel]="execution.signedVersion" disabled
                                                        placeholder="{{ 'executionEdit.general.signedVersion.label' | translate }}">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </mat-card-content>
            </mat-card>

            <mat-card class="card" [hidden]="execution.id == null">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'executionEdit.referenceDocs.title' | translate }}

                        <button mat-icon-button color="accent" (click)="openNewRefDoc()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </mat-toolbar>

                    <br />

                    <div fxLayout="row">

                        <div fxFlex fxFlex.lt-md="100%">

                            <table mat-table matSort #refDocTable [dataSource]="execution.referenceDocs"
                                style="width: 95%;">

                                <!-- code Column -->
                                <ng-container matColumnDef="code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        {{ 'executionEdit.referenceDocs.code.label' | translate }}
                                    </th>
                                    <td mat-cell *matCellDef="let item"> {{ item.code }} </td>
                                </ng-container>

                                <!-- name Column -->
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        {{ 'executionEdit.referenceDocs.name.label' | translate }}
                                    </th>
                                    <td mat-cell *matCellDef="let item" style="white-space: pre;"> {{ item.name }} </td>
                                </ng-container>

                                <!-- Button edit Column -->
                                <ng-container matColumnDef="edit">
                                    <th mat-header-cell *matHeaderCellDef> {{ 'button.edit' | translate }} </th>
                                    <td mat-cell *matCellDef="let element; let refDocIndex = index">
                                        <button mat-icon-button (click)="editRefDoc(refDocIndex)">
                                            <mat-icon>mode_edit</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <!-- Button delete Column -->
                                <ng-container matColumnDef="delete">
                                    <th mat-header-cell *matHeaderCellDef> {{ 'button.delete' | translate }} </th>
                                    <td mat-cell *matCellDef="let element; let refDocIndex = index">
                                        <button mat-icon-button (click)="removeRefDoc(refDocIndex)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColsRefDoc"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColsRefDoc;"></tr>

                            </table>
                        </div>
                    </div>
                </mat-card-content>

            </mat-card>

            <mat-card class="card" *ngIf="!useExistingProtocol || protocol?.id != null">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ (useExistingProtocol || protocol?.id != null ?
                        'executionEdit.protocol.title' :
                        'executionEdit.execution.title') | translate }}
                        <div fxFlex="65px" fxFlex.lt-md="50px" *ngIf="canEditDocumentCode()">
                            <button mat-icon-button (click)="editDataExecution()">
                                <mat-icon>mode_edit</mat-icon>
                            </button>
                        </div>
                    </mat-toolbar>

                    <div fxLayout="row" fxLayout.xs="column" class="flexwrap">
                        <div fxFlex="50" fxFlex.lt-md="100%">
                            <mat-form-field class="campoFormulario">
                                <mat-label>{{ 'executionEdit.general.client.label' | translate
                                    }}</mat-label>
                                <mat-select #clientSelect required name="idClient"
                                    name="idClient" [disabled]="+execution.signedVersion > 0">
                                    <mat-select-filter *ngIf="clientSelect.focused" [displayMember]="'name'"
                                        [array]="clients" (filteredReturn)="clientsFiltered =$event"
                                        [noResultsMessage]="'common.noResults' | translate"
                                        [placeholder]="'common.selectFilterPlaceholder' | translate">
                                    </mat-select-filter>
                                    <mat-option *ngFor="let client of clientsFiltered" [value]="client.id">
                                        {{ client.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    {{ 'executionEdit.general.client.error' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxFlex="33" fxFlex.lt-md="100%">
                            <mat-form-field class="campoFormulario" *ngIf="!useExistingProtocol">
                                <mat-label>{{ 'executionEdit.protocol.process.label' | translate
                                    }}</mat-label>
                                <mat-select name="idProcess" [(ngModel)]="execution.idProcess" required>
                                    <mat-option *ngFor="let item of processes" [value]="item.id">
                                        {{ 'processType.' + item.translation | translate }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    {{ 'executionEdit.protocol.process.error' | translate }}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="campoFormulario" *ngIf="protocol?.id != null">
                                <mat-label>{{ 'executionEdit.protocol.process.label' | translate
                                    }}</mat-label>
                                <mat-select disabled name="idProcessProtocol" [(ngModel)]="protocol.idProcess">
                                    <mat-option *ngFor="let item of processes" [value]="item.id">
                                        {{ 'processType.' + item.translation | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxFlex="50" fxFlex.lt-md="100%">
                            <mat-form-field class="campoFormulario">
                                <input matInput name="projectNo" [(ngModel)]="execution.projectNo" required
                                    [errorStateMatcher]="matcher"
                                    placeholder="{{ 'executionEdit.general.projectNo.label' | translate }}">
                                <mat-error>
                                    {{ 'executionEdit.general.projectNo.label' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxFlex="50" fxFlex.lt-md="100%">
                            <mat-form-field class="campoFormulario">
                                <mat-label>{{ 'executionEdit.general.projectManager.label' |
                                    translate }}
                                </mat-label>
                                <mat-select name="projectManager"
                                    [(ngModel)]="execution.usernameProjectManager">
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let item of respField" [value]="item.username">
                                        {{ item.fullName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="card" [hidden]="execution.id == null">
                <mat-card-content>
                    <br />

                    <mat-toolbar color="secondary">
                        {{ 'protocolEdit.equipment.title' | translate }}

                        <button mat-icon-button color="accent" (click)="openDialogEquipment()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </mat-toolbar>

                    <mat-accordion>
                        <mat-expansion-panel class="overflowX"
                            *ngFor="let equipment of execution.equipments; let indexEquipment = index"
                            (afterExpand)="equipmentTab = indexEquipment" (closed)="equipmentTab = null">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div style="margin: auto 0;display: block;">
                                        {{ equipment.equipment }}
                                        -
                                        {{ equipment.model }}({{ equipment.serialNumber }})
                                    </div>
                                    <button mat-icon-button color="accent" *ngIf="equipmentTab === indexEquipment"
                                        (click)="openDialogEditEquipment(indexEquipment)">
                                        <mat-icon style="font-size: 20px;">mode_edit</mat-icon>
                                    </button>

                                    <button mat-icon-button color="accent" *ngIf="equipmentTab === indexEquipment"
                                        (click)="deleteEquipment(indexEquipment)">
                                        <mat-icon style="font-size: 20px;">delete</mat-icon>
                                    </button>
                                    <button mat-icon-button color="accent"
                                        *ngIf="equipmentTab === indexEquipment && false"
                                        (click)="duplicateEquipment(indexEquipment)">
                                    </button>
                                    <!--<button mat-icon-button color="accent"
                                        *ngIf="equipmentTab === indexEquipment"
                                        (click)="openDialogEssayEquipment(indexEquipment)">
                                        <mat-icon style="font-size: 20px;">add</mat-icon>
                                    </button>-->

                                </mat-panel-title>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                    </mat-accordion>
                </mat-card-content>
            </mat-card>
            <mat-card class="card" [hidden]="execution.id == null">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'protocolEdit.testQualification.title' | translate }}
                    </mat-toolbar>

                    <div fxLayout="column" class="flexwrap"
                        *ngFor="let test of testQualificationTypeList; let indexTest = index">
                        <div fxLayout="column">
                            <div fxFlex="100" style="padding: 15px 0;" *ngIf="test.testQTypes?.length > 0">
                                <span style="font-weight: 600;">{{'testQualification.title.' + test.qualificationType |
                                    translate}}</span>
                            </div>
                            <div fxFlex="100" *ngFor="let item of test.testQTypes" style="padding: 10px 0;">
                                <mat-checkbox [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}"
                                    (change)="onSelectedItem(item, indexTest, $event)" [disabled]="true">
                                    <span class="test-item-verification">
                                        {{'testQualification.' + item.translation | translate}}
                                    </span>
                                    <button mat-raised-button *ngIf="item.checked" (click)="createTestsSelected(item)"
                                        style="box-shadow: none !important">
                                        <mat-icon fxHide.lt-sm [ngStyle]="isCompletedTest(item) ? {'color': 'green'} : {'color': 'none'}">display_settings</mat-icon>
                                    </button>
                                </mat-checkbox>
                                <button mat-icon-button color="accent" *ngIf ="item.haveDeviations"
                                    (click)="showDeviationSelected(item.idExecutionTest)">
                                    <mat-icon>note_add</mat-icon>
                                </button>
                            </div>
                            
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="card">
                <mat-card-actions>
                    <div fxFlex></div>
                    <button mat-raised-button color="primary" *ngIf="showRevertSign()" (click)="revertSign()">
                        {{ 'button.revertSign' | translate }} </button>
                    <div fxFlex="5px" *ngIf="showExecute()"></div>
                    <button mat-raised-button color="primary" *ngIf="showExecute()" (click)="execute()">
                        {{ 'button.advance' | translate }}</button>
                    <button mat-raised-button color="primary" (click)="saveExecution()">{{ 'button.save' | translate }}
                    </button>
                    <div fxFlex="5px"></div>
                    <div matTooltip="{{ 'button.disabled.offline' | translate }}">
                        <button mat-raised-button color="primary" *ngIf="showManualSign()" (click)="manualSign()">
                            {{ 'button.sign-manual' | translate }}
                        </button>
                    </div>
                    <div fxFlex="5px"></div>
                    <button mat-raised-button color="primary" *ngIf="showAutomaticSign()" (click)="prepareToSign()">
                        {{ 'button.sign-automatic' | translate }}
                    </button>
                    <div fxFlex="5px"></div>
                    <button mat-raised-button color="accent" *ngIf="execution.documentCode != null"
                        (click)="downloadPdf()">{{ 'button.generateDoc' | translate }}
                    </button>
                    <div fxFlex="5px"></div>
                    <button mat-raised-button color="secondary" (click)="cancel()">{{ 'button.cancel' | translate
                        }}</button>
                </mat-card-actions>
            </mat-card>
            <app-execution-q-edit-attachments #attachmentsComponent>
            </app-execution-q-edit-attachments>
        </form>
    </mat-tab>
    
    <mat-tab label="{{ 'executionEdit.historical.title' | translate }}" *ngIf="execution.id != null">
        <!-- <app-execution-edit-q-audit [idExecution]="execution.id" [documentCode]="execution.documentCode">
        </app-execution-edit-q-audit>-->
    </mat-tab>
</mat-tab-group>
