/* eslint-disable max-len */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FieldType } from 'src/app/model/fieldType';
import { InternalEquipmentCustomField } from 'src/app/model/internalEquipmentCustomField';
import { InternalEquipmentCustomFieldsService } from 'src/app/services/internalEquipmentCustomFields.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Constants } from 'src/app/utils/constants';
import { InternalEquipmentCustomSelectValue } from '../../../model/internalEquipmentCustomSelectValue';
import { FieldTypeEnum } from '../../../model/fieldType';
import { InternalEquipmentCustomSelectValueService } from '../../../services/internalEquipmentCustomSelectValue.service';


export interface DialogDataField {
  field: InternalEquipmentCustomField;
  isEdit: boolean;
}

@Component({
  selector: 'app-calibration-plan-edit-custom-field',
  templateUrl: './calibration-plan-edit-custom-field.component.html'
})
export class CalibrationPlanEditCustomFieldComponent implements OnInit {

  tituloDialog: string;
  fieldTypes: FieldType[];
  selectValueCustom: InternalEquipmentCustomSelectValue[];

  constructor(
    private dialogRef: MatDialogRef<CalibrationPlanEditCustomFieldComponent>,
    private internalEquipmentCustomFieldsService: InternalEquipmentCustomFieldsService,
    private internalEquipmentCustomSelectValueService: InternalEquipmentCustomSelectValueService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataField
  ) {
    if (data.isEdit) {
      this.tituloDialog = this.translate.instant('protocolEdit.dialog.area.title.edit', { name: data.field.name }) as string;
    } else {
      this.tituloDialog = this.translate.instant('calibrationPlan.customFieldInternalEquipment.dialog.create.title') as string;
    }
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.internalEquipmentCustomFieldsService.findAllTypes().subscribe((res: FieldType[]) => {
      const typeAloweds = Constants.CALIBRATION_PLAN_ALLOWED_FIELD_TYPES;

      this.fieldTypes = res.filter((type) => typeAloweds.includes(type.id));
      this.spinnerService.hide();

      if (this.data.field.idType === FieldTypeEnum.SELECT_ONE) {
        this.internalEquipmentCustomSelectValueService.findByIdField(this.data.field.id).subscribe((resCustom: InternalEquipmentCustomSelectValue[]) => {
          this.selectValueCustom = resCustom;
        });
      }
    });
  }

  onOkClick(): void {
    this.spinnerService.show();
    const errs: string[] = [];

    if (this.data.field.name == null) {
      errs.push(this.translate.instant('calibrationPlan.dialog.field.name.error') as string);
    }
    if (this.data.field.idType == null) {
      errs.push(this.translate.instant('calibrationPlan.dialog.field.type.error') as string);
    }

    if (!this.isFiltrable()) {
      this.data.field.filtrable = false;
    }

    if (errs.length === 0) {
      // TODO:
      this.data.field.order = 1;
      this.internalEquipmentCustomFieldsService.save(this.data.field).subscribe((res: InternalEquipmentCustomField) => {
       if (FieldTypeEnum.SELECT_ONE === this.data.field.idType) {
        this.selectValueCustom.forEach(e => e.idField = res.id);
        this.internalEquipmentCustomSelectValueService.save(this.selectValueCustom).subscribe(() => {
          this.spinnerService.hide();
          this.dialogRef.close(this.data.field);
        });
       } else {
        this.spinnerService.hide();
        this.dialogRef.close(this.data.field);
       }
      });
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showNameTypeName(name: string): string {
    return this.translate.instant('customField.'.concat(name).concat('.type')) as string;
  }

  isFiltrable(): boolean {
    return Constants.CALIBRATION_PLAN_FILTERABLES_FIELD_TYPES.includes(this.data.field.idType);
  }

  newCustomSelect(): void {
    if (!this.selectValueCustom) {
      this.selectValueCustom = [];
    }

    const data = new InternalEquipmentCustomSelectValue();
    this.selectValueCustom.push(data)
  }

  deleteCustomSelect(index: number): void {
    this.selectValueCustom.splice(index, 1);
  }

  validateSelect(): boolean {
    return this.data.field.idType === FieldTypeEnum.SELECT_ONE;
  }

}
