import { Observable, from, of } from 'rxjs';

import { AttachmentType } from '../model/attachment';
import { DateUtils } from '../utils/dateUtils';
import { DexieService } from './dexieService';
import { EssayProtocolAir } from '../model/essayProtocolAir';
import { FileUtils } from '../utils/fileUtils';
import { FilterAir } from '../model/filterAir';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { OnlineService } from './online.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { TypeUploadAirEnum } from '../utils/essayUtils';

@Injectable()
export class AttachmentAirService {

    constructor(private http: HttpClient,
        private dexieService: DexieService,
        private onlineService: OnlineService) {
    }

    uploadGeneric(file: File, idType: number = AttachmentType.OTHERS): Observable<any> {
        const url = environment.airUrl + '/attachment/';
        const form = new FormData();
        form.append('file', file, file.name);
        form.append('idType', idType.toString());

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadAttachmentToProtocol(idProtocol: number, file: File): Observable<any> {
        const url = environment.airUrl + `/attachment/protocol/${idProtocol}/attachment/`;
        const form = new FormData();
        form.append('file', file, file.name);

        return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadAttachmentToProtocol(idProtocol: number, idAttachment: number): Observable<any> {
        const url = environment.airUrl + `/attachment/protocol/${idProtocol}/attachment/${idAttachment}`;

        return this.http.get(url, { responseType: 'blob' });
    }

    deleteAttachmentToProtocol(idProtocol: number, idAttachment: number): Observable<any> {
        const url = environment.airUrl + `/attachment/protocol/${idProtocol}/attachment/${idAttachment}`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    uploadAttachmentToExecution(idExecution: number, file: File, type: number = 99): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.airUrl + `/attachment/execution/${idExecution}/attachment/`;
            const form = new FormData();
            form.append('file', file, file.name);

            if (type != null) {
                form.append('type', type.toString());
            }

            return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return of(this.saveAttachedToDexie(file, 'file', idExecution, null, null));
        }
    }

    downloadAttachmentToExecution(idExecution: number, idAttachment: number): Observable<any> {
        const url = environment.airUrl + `/attachment/execution/${idExecution}/attachment/${idAttachment}`;

        return this.http.get(url, { responseType: 'blob' });
    }

    deleteAttachmentToExecution(idExecution: number, idAttachment: number): Observable<any> {
        const url = environment.airUrl + `/attachment/execution/${idExecution}/attachment/${idAttachment}`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getAllTypesForProtocol(): Observable<any> {
        const url = environment.coreUrl + '/attachment/types/protocol/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getAllTypesForProtocolAir(): Observable<any> {
        const url = environment.coreUrl + '/attachment/types/protocol/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getAllTypesForExecution(): Observable<any> {
        const url = environment.coreUrl + '/attachment/types/execution/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getAllTypesForExecutionAir(): Observable<any> {
        const url = environment.coreUrl + '/attachment/types/protocol/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    updateAttachmentToProtocol(idProtocol: number, idAttachment: number, idType: number, name: string): Observable<any> {
        const url = environment.airUrl + `/attachment/protocol/attachment/${idAttachment}`;

        const body = {
            idParent: idProtocol,
            idAttachment, idType, name
        };

        return this.http.post(url, body).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    updateAttachmentToExecution(idExecution: number, idAttachment: number, idType: number, name: string): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.airUrl + `/attachment/execution/attachment/${idAttachment}`;

            const body = {
                idParent: idExecution,
                idAttachment, idType, name
            };

            return this.http.post(url, body).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from([]);
        }
    }

    uploadExcelToEssayExecution(idExecution: number, essay: EssayProtocolAir | number, file: File): Observable<any> {
        let idEssay: string | number = null;
        if (typeof essay === 'number') {
            idEssay = essay;
        } else {
            idEssay = essay.id;
        }

        if (this.onlineService.latestOnline) {
            const url = environment.airUrl + `/attachment/execution/${idExecution}/essay/${idEssay}/excel`;

            const form = new FormData();
            form.append('file', file, file.name);

            return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            if (typeof essay !== 'number') {
                if (essay.id != null) {
                    idEssay = essay.id;
                } else {
                    idEssay = essay.idOffline;
                }
                void FileUtils.toBase64(file).then((res: string) => {{
                    essay.essayFile = {fileName: file.name, file:res, type:'excel', typeUpload: TypeUploadAirEnum.TYPE_ESSAY};
                }});

            }
            return of(this.saveAttachedToDexie(file, 'excel', idExecution, null, idEssay));
        }
    }

    uploadVideoToEssayExecution(idExecution: number, essay: EssayProtocolAir | number, file: File): Observable<any> {
        let idEssay: string | number = null;
        if (typeof essay === 'number') {
            idEssay = essay;
        } else {
            idEssay = essay.id;
        }

        if (this.onlineService.latestOnline) {
            const url = environment.airUrl + `/attachment/execution/${idExecution}/essay/${idEssay}/video`;

            const form = new FormData();
            form.append('file', file, file.name);

            return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            if (typeof essay !== 'number') {
                if (essay.id != null) {
                    idEssay = essay.id;
                } else {
                    idEssay = essay.idOffline;
                }
                void FileUtils.toBase64(file).then((res: string) => {{
                    essay.essayFile = {fileName: file.name, file:res, type:'video', typeUpload: TypeUploadAirEnum.TYPE_ESSAY};
                }});
            }
            return of(this.saveAttachedToDexie(file, 'video', idExecution, null, idEssay));
        }
    }

    downloadExcelToEssayExecution(idExecution: number, idEssay: number, idExcel: number): Observable<any> {
        const url = environment.airUrl + `/attachment/execution/${idExecution}/essay/${idEssay}/excel/${idExcel}`;

        return this.http.get(url, { responseType: 'blob' });
    }

    downloadVideoToEssayExecution(idExecution: number, idEssay: number): Observable<any> {
        const url = environment.airUrl + `/attachment/execution/${idExecution}/essay/${idEssay}/video`;

        return this.http.get(url, { responseType: 'blob' });
    }

    streamVideoToEssayExecution(idExecution: number, idEssay: number): Observable<any> {
        const url = environment.airUrl + `/attachment/execution/${idExecution}/essay/${idEssay}/videoStream`;

        return this.http.get(url, { responseType: 'blob' });
    }

    getUrlVideoToEssayExecution(idExecution: number, idEssay: number): Observable<any> {
        const url = environment.airUrl + `/attachment/execution/${idExecution}/essay/${idEssay}/urlVideo`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    deleteVideoToEssayExecution(idExecution: number, idEssay: number): Observable<any> {
        const url = environment.airUrl + `/attachment/execution/${idExecution}/essay/${idEssay}/video`;

        return this.http.delete(url, { responseType: 'blob' });
    }

    uploadCertificateToEssayExecution(idExecution: number, essay: EssayProtocolAir | number, file: File): Observable<any> {
        let idEssay: string | number = null;
        if (typeof essay === 'number') {
            idEssay = essay;
        } else {
            idEssay = essay.id;
        }

        if (this.onlineService.latestOnline) {
            const url = environment.airUrl + `/attachment/execution/${idExecution}/essay/${idEssay}/certificate`;

            const form = new FormData();
            form.append('file', file, file.name);

            return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            if (typeof essay !== 'number') {
                if (essay.id != null) {
                    idEssay = essay.id;
                } else {
                    idEssay = essay.idOffline;
                }
                void FileUtils.toBase64(file).then((res: string) => {{
                    essay.essayFile = {fileName: file.name, file:res, type:'certificate', typeUpload: TypeUploadAirEnum.TYPE_ESSAY};
                }});
            }
            return of(this.saveAttachedToDexie(file, 'certificate', idExecution, null, idEssay));
        }
    }

    downloadCertificateToEssayExecution(idExecution: number, idEssay: number): Observable<any> {
        const url = environment.airUrl + `/attachment/execution/${idExecution}/essay/${idEssay}/certificate`;

        return this.http.get(url, { responseType: 'blob' });
    }

    uploadCertificateToFilter(idExecution: number, filter: FilterAir | number, file: File): Observable<any> {
        let idFilter: string | number = null;
        if (typeof filter === 'number') {
            idFilter = filter;
        } else {
            idFilter = filter.id;
        }

        if (this.onlineService.latestOnline) {
            const url = environment.airUrl + `/attachment/execution/${idExecution}/filter/${idFilter}/certificate`;

            const form = new FormData();
            form.append('file', file, file.name);

            return this.http.post(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            if (typeof filter !== 'number') {
                if (filter.id != null) {
                    idFilter = filter.id;
                } else {
                    idFilter = filter.idOffline;
                }
                void FileUtils.toBase64(file).then((res: string) => {{
                   filter.essayFile =  {fileName: file.name, file:res, type:'certificate',  typeUpload: TypeUploadAirEnum.TYPE_FILTER};
                }});
            }

            return of(this.saveAttachedToDexie(file, 'certificate', idExecution, idFilter, null));

        }
    }

    downloadCertificateToFilter(idExecution: number, idFilter: number): Observable<any> {
        const url = environment.airUrl + `/attachment/execution/${idExecution}/filter/${idFilter}/certificate`;

        return this.http.get(url, { responseType: 'blob' });
    }

    private async saveAttachedToDexie(file: File, type: string, idExecution: number, idFilter: string | number,
        idEssay: string | number): Promise<number> {
        return new Promise<number>((resolve) => void FileUtils.toBase64(file).then((res: string) => {
            void this.dexieService.attached.put({
                idExecution,
                idFilter,
                idEssay,
                type,
                blob: res,
                fileName: file.name,
                timestamp: DateUtils.toDateTimeStr(new Date()),
                deleted: false,
                dateDeleted:  DateUtils.toDateTimeStr(new Date())
            }).then(() => resolve(null));
        }));
    }

}
