<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form [formGroup]="form">
  <div mat-dialog-content class="thermal-theme">

    <div fxLayout="row" class="flexwrap">

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.criticalInstr.equipment.label' | translate }}"
            formControlName="equipment" [(ngModel)]="data.criticalInstr.equipment" required
            [errorStateMatcher]="matcher">
          <mat-error>
            {{ 'protocolEdit.dialog.criticalInstr.equipment.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.criticalInstr.internalId.label' | translate }}"
            formControlName="internalId" [(ngModel)]="data.criticalInstr.internalId" [errorStateMatcher]="matcher">
          <mat-error>
            {{ 'protocolEdit.dialog.criticalInstr.internalId.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput placeholder="{{ 'protocolEdit.dialog.criticalInstr.procediment.label' | translate }}"
            formControlName="procediment" [(ngModel)]="data.criticalInstr.procediment" required
            [errorStateMatcher]="matcher">
          <mat-error>
            {{ 'protocolEdit.dialog.criticalInstr.procediment.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

    </div>

  </div>
  <div mat-dialog-actions class="thermal-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>