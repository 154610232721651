import { CompressedGas } from './compressedGasesAir';
import { EquipmentAir } from './equipment';
import { RoomAir } from './roomAir';

export class Area {
    id: number;

    idProtocol: number;
    idExecution: number;

    name: string;
    nameProtocol: string;

    equipments: EquipmentAir[] = [];
    rooms: RoomAir[] = [];
    compressedGases: CompressedGas[] = [];

    idOffline: string;

    fromProtocol: boolean;
    reaseonChange: string;
    showDiffInDeviations = true;
    showDiffInObservations: boolean;
}