<br />

<h1 class="errorTitle">{{ 'maintenanceMode.title' | translate }}</h1>

<div class="main-container">
    <div class="centerText">
        <img src="assets/img/maintenance.gif" class="errorImage">
    </div>

    <br /><br />

    <h2 class="centerText">{{ 'maintenanceMode.text1' | translate }}</h2>
    <h3 class="centerText">{{ 'maintenanceMode.text2' | translate }}</h3>
</div>