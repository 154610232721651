import { GenericFilter } from './genericClass';

export class LoginAccess {
    username: string;
    date: Date;
    ip: string;
    correct: boolean;
}

export class LoginAccessFilter extends GenericFilter {
    username: string;
}
