import * as moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';

import { TranslationService } from '../services/translation.service';

@Pipe({
  name: 'fixDateTime'
})
export class FixDateTime implements PipeTransform {

  constructor(private translateService: TranslationService) {

  }

  transform(value: Date | string | any): any {

    if (value == null) {
      return value;
    }

    if (value instanceof Date && isNaN(value.getTime())) {
      return null;
    }

    moment.locale(this.translateService.currentLang);
    return moment.utc((value as Date)).local().format('DD/MMM/YYYY HH:mm:ss').replace('./', '/').toUpperCase();
  }

}
