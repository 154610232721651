import { Observable, from } from 'rxjs';

import { DexieService } from './dexieService';
import { FilterAirType } from '../model/filterAirType';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { OnlineService } from './online.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class FilterAirTypeService {

    constructor(
        private http: HttpClient,
        private dexieService: DexieService,
        private onlineService: OnlineService) {
    }

    findByEquipmentId(idEquipment: number): Observable<any> {
        const url = environment.coreUrl + `/air/essay/equipment/${idEquipment}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findFieldsById(idEssay: number): Observable<any> {
        const url = environment.coreUrl + `/air/essay/${idEssay}/fields`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAll(): Observable<any> {
        if (this.onlineService.latestOnline) {
            const url = environment.coreUrl + '/filterAirType/';

            return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
        } else {
            return from(this.dexieService.filterTypes.toArray().timeout(30000));
        }
    }

    saveFilterTypeToDexie(): Promise<void> {
        return new Promise<void>((resolve) => this.findAll().subscribe((res: FilterAirType[]) => {
            if (res) {
                void this.dexieService.filterTypes.clear().then(() => {
                    res.forEach(type => {
                        void this.dexieService.filterTypes.put(type, type.id).then(() => resolve());
                    });
                });
            }
        }));
    }
}
