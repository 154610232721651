import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IsothermalCharacterizationAudit, IsothermalCharacterizationAuditFilter } from 'src/app/model/isothermalCharacterization';
import { Subject, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { IsothermalCharacterizationAuditDataSource } from 'src/app/model/isothermalCharacterizationAuditDataSource';
import { IsothermalCharacterizationService } from 'src/app/services/isothermalCharacterization.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-isothermal-characterization-edit-audit',
  templateUrl: './isothermal-characterization-edit-audit.component.html'
})
export class IsothermalCharacterizationEditAuditComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() idIsothermalCharacterization: number;
  @Input() documentCode: string;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public filter: IsothermalCharacterizationAuditFilter;

  public displayedColumns = ['date', 'username', 'action', 'status', 'currentVersion', 'changes', 'download'];
  public dataSource: IsothermalCharacterizationAuditDataSource;

  private destroy$ = new Subject<void>();

  constructor(
    private isothermalCharacterizationService: IsothermalCharacterizationService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) {
    this.spinnerService.show();
  }

  ngOnInit(): void {
    this.filter = new IsothermalCharacterizationAuditFilter();
    this.getAudit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.getAudit())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  getAudit(): void {

    this.dataSource = new IsothermalCharacterizationAuditDataSource(this.isothermalCharacterizationService);

    this.filter.sortBy = this.sort.active || 'date';
    this.filter.sortDirection = this.sort.direction || 'asc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 10;

    this.dataSource.loadIsothermalCharacterizationAudit(this.idIsothermalCharacterization, this.filter);
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));

  }

  downloadAuditPdf(): void {
    this.spinnerService.show();

    this.isothermalCharacterizationService.downloadAuditPdf(this.idIsothermalCharacterization).pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        saveAs(res, this.translate.instant('isothermalCharacterizationEdit.files.audit'));
      }, error => {
        console.error(error);
      }, () => {
        this.spinnerService.hide();
      });
  }

  showDownloadSignedPdf(prot: IsothermalCharacterizationAudit): boolean {
    return prot.canDownload;
  }

  downloadSignedPdf(ex: IsothermalCharacterizationAudit): void {

    this.spinnerService.show();

    this.isothermalCharacterizationService.downloadPdfVersion(this.idIsothermalCharacterization, ex.currentVersion)
      .pipe(takeUntil(this.destroy$)).subscribe(res => {
        const name = this.getNameForIsothermalCharacterization(this.documentCode, ex.currentVersion);
        saveAs(res, name);
        this.spinnerService.hide();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('isothermalCharacterizationEdit.form.downloadPdf.error.notFound'));
        this.spinnerService.hide();
      });
  }

  private getNameForIsothermalCharacterization(documentCode: string, version: string): string {
    let name = documentCode + ' v.' + version.toString().padStart(2, '0');
    name = this.translate.instant('isothermalCharacterizationEdit.files.report', { name }) as string;

    return name;
  }

}
