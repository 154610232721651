import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Group, GroupFilter } from 'src/app/model/group';
import { User, UserFilter } from 'src/app/model/user';

import { ManageGroupsService } from 'src/app/services/manageGroups.service';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-manage-users-filter',
  templateUrl: './manage-users-filter.component.html'
})
export class ManageUsersFilterComponent implements OnInit, OnDestroy {

  @Output() filterEmitter = new EventEmitter<UserFilter>();

  filter: UserFilter;

  expandedFilter = true;

  matcher = new MyErrorStateMatcher();
  form: FormGroup;

  currentUser: User;

  groups: Group[];

  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private manageGroupsService: ManageGroupsService) { }

  ngOnInit(): void {
    this.currentUser = this.userService.currentProfile;

    this.groups = [];
    this.manageGroupsService.findAll(new GroupFilter()).pipe(takeUntil(this.destroy$))
      .subscribe(res => this.groups = res.content as Group[]);

    this.cleanFilter();
    this.filter.idGroup = this.currentUser.idActiveProfile;

    this.form = this.fb.group({
      email: [this.filter.email],
      fullName: [this.filter.fullName],
      username: [this.filter.username],
      idGroup: [this.filter.idGroup]
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showAdminFilter(): boolean {
    return !this.currentUser.manager;
  }

  doFilter(): void {
    this.expandedFilter = false;

    this.filterEmitter.emit(this.filter);
  }

  cleanFilter(): void {
    this.filter = new UserFilter();

    if (!this.showAdminFilter()) {
      this.filter.idGroup = this.currentUser.idActiveProfile;
    }
  }

}
