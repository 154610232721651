import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, merge } from 'rxjs';
import { User, UserFilter } from 'src/app/model/user';
import { takeUntil, tap } from 'rxjs/operators';

import { Location } from '@angular/common';
import { ManageUsersService } from 'src/app/services/manageUsers.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { UsersDataSource } from 'src/app/model/userDataSource';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html'
})
export class ManageUsersComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: UsersDataSource;
  filter: UserFilter = new UserFilter();

  displayedColumns: string[] = [
    'username', 'email', 'fullName', 'edit', 'activate', 'more'
  ];

  currentUser: User;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private location: Location,
    private userService: UserService,
    private usersService: ManageUsersService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService) { }

  new(): void {
    void this.router.navigateByUrl('/admin/user');
  }

  editRow(id: string): void {
    void this.router.navigateByUrl('/admin/user?username=' + id);
  }

  ngOnInit(): void {
    this.currentUser = this.userService.currentProfile;
    this.loadUserList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => this.loadUserList()),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  doFilter(event: UserFilter): void {
    this.filter = event;

    this.loadUserList();
  }

  highlight(user: User): string {
    let res = '';
    if (!user.active) {
      res = 'deactivate';
    }
    return res;
  }

  loadUserList(): void {
    this.dataSource = new UsersDataSource(this.usersService);

    this.filter.sortBy = this.sort.active || 'documentCode';
    this.filter.sortDirection = this.sort.direction || 'asc';
    this.filter.pageIndex = this.paginator.pageIndex || 0;
    this.filter.pageSize = this.paginator.pageSize || 5;

    this.dataSource.loadUsers(this.filter);
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  canEdit(user: User): boolean {
    return !this.currentUser.manager || (user.admin && user.manager) || !user.admin;
  }

  activate(username: string): void {
    this.usersService.activate(username, true).pipe(takeUntil(this.destroy$)).subscribe((obs: Observable<any>) => {
      obs.subscribe(() => {
        this.snackBarService.sendSuccess(this.translate.instant('manageUsers.form.activateUser.ok', { username }) as string);

        this.loadUserList();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('manageUsers.form.activateUser.error', { username }) as string);
      });
    }, () => {
      this.snackBarService.sendError(this.translate.instant('manageUsers.form.activateUser.error', { username }) as string);
    });
  }

  deactivate(username: string): void {
    this.usersService.activate(username, false).pipe(takeUntil(this.destroy$)).subscribe((obs: Observable<any>) => {
      obs.subscribe(() => {
        this.snackBarService.sendSuccess(this.translate.instant('manageUsers.form.deactivateUser.ok', { username }) as string);

        this.loadUserList();
      }, () => {
        this.snackBarService.sendError(this.translate.instant('manageUsers.form.deactivateUser.error', { username }) as string);
      });
    }, () => {
      this.snackBarService.sendError(this.translate.instant('manageUsers.form.deactivateUser.error', { username }) as string);
    });
  }

  cancel(): void {
    this.location.back();
  }
}
