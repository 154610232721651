<mat-toolbar>
    <mat-toolbar-row class="header-client">
      <a mat-list-item routerLink="/portal-client" fxFlex="20%" fxLayout fxLayoutGap="20px" fxLayoutAlign="center">
        <img src="assets/img/qualipharma.svg" class="appImage" alt="Logo de Qloud">
      </a>
  
      <div fxFlex="80" fxLayout fxLayoutAlign="flex-end" class="main-menu">
  
        <div fxFlex="13">
            <ul fxLayout>
                <li>
                  <button mat-menu-item [routerLink]="'/notifications'"
                    [ngStyle]="{'margin-right':getUnreadNotifications() ? '12px' : '0' }">
                    <mat-icon class="bell-icon" [matBadge]="getUnreadNotifications()" matBadgeColor="accent">
                      notifications
                    </mat-icon>
                  </button>
                </li>
        
                <li>
                  <button mat-menu-item name="myMenu" [matMenuTriggerFor]="auth">
                    <div>
                      <img src="assets/img/user-icon.png" style="width: 55%;" alt="Logo de Qloud">
                    </div>
                  </button>
        
                  <mat-menu #auth="matMenu">
                    <button mat-menu-item [routerLink]="'/perfil'">
                      <mat-icon>account_circle</mat-icon>
                      <span>{{'menu.profile' | translate}}</span>
                    </button>
                    <mat-divider></mat-divider>
                    <button mat-menu-item name="logoutButton" (click)="logout()">
                      <mat-icon>exit_to_app</mat-icon>
                      <span>{{'signout' | translate}}</span>
                    </button>
                  </mat-menu>
                </li>
      
              </ul>
        </div>
  
      </div>
  
    </mat-toolbar-row>
  </mat-toolbar>