import * as _ from 'lodash-es';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    IsothermalCharacterization,
    IsothermalCharacterizationAuditFilter,
    IsothermalCharacterizationFilter
} from '../model/isothermalCharacterization';

import { DateUtils } from '../utils/dateUtils';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { IsothermalCharacterizationReportFilter } from '../model/attachment';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class IsothermalCharacterizationService {

    constructor(private http: HttpClient) {
    }

    findAll(filter: IsothermalCharacterizationFilter): Observable<any> {
        const url = environment.calibratesUrl + '/isothermalCharacterization/';

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    getExcel(filter: IsothermalCharacterizationFilter): Observable<any> {
        const url = environment.calibratesUrl + '/isothermalCharacterization/excel';

        return this.http.post(url, filter, { responseType: 'blob' });
    }

    findOne(id: number): Observable<any> {
        const url = environment.calibratesUrl + `/isothermalCharacterization/${id}`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    create(iso: IsothermalCharacterization, token: string): Observable<any> {
        const url = environment.calibratesUrl + '/isothermalCharacterization/create';

        return this.http.post(url, {
            isothermalCharacterization: iso,
            token
        }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    save(original: IsothermalCharacterization): Observable<any> {
        const url = environment.calibratesUrl + `/isothermalCharacterization/${original.id}`;

        const iso = _.cloneDeep(original);

        iso.variables.forEach(essay => {
            if (essay.fcStartEssay != null) {
                essay.fcStartEssay = DateUtils.anyToDate(essay.fcStartEssay as Date);
                essay.fcStartEssay = new Date(essay.fcStartEssay.toString().split('GMT')[0] + ' UTC').toISOString()
                    .split('.')[0] + '.000+0000';
            }

            if (essay.fcEndEssay != null) {
                essay.fcEndEssay = DateUtils.anyToDate(essay.fcEndEssay as Date);
                essay.fcEndEssay = new Date(essay.fcEndEssay.toString().split('GMT')[0] + ' UTC').toISOString()
                    .split('.')[0] + '.000+0000';
            }

            essay.sensors.forEach(s => {
                delete s.data;
            });
        });

        return this.http.put(url, iso).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    execute(iso: IsothermalCharacterization, token: string): Observable<any> {
        const url = environment.calibratesUrl + `/isothermalCharacterization/${iso.id}/execute`;

        const form = new FormData();
        form.append('forceAccording', JSON.stringify(iso.according));
        form.append('reasonForce', iso.reason);
        form.append('token', token);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    manualSign(
        iso: IsothermalCharacterization, token: string, forceAccording: boolean, reasonForce: string, document: File): Observable<any> {
        const url = environment.calibratesUrl + `/isothermalCharacterization/${iso.id}/validate`;

        const form = new FormData();
        form.append('document', document, document.name);
        form.append('forceAccording', JSON.stringify(forceAccording));
        form.append('reasonForce', reasonForce);
        form.append('token', token);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    // eslint-disable-next-line max-len
    automaticSign(id: number, token: string, forceAccording: boolean, reasonForce: string, checkFullReport: boolean, dataTableInclude: string): Observable<any> {
        const url = environment.calibratesUrl + `/isothermalCharacterization/${id}/sign`;

        const form = new FormData();
        form.append('forceAccording', JSON.stringify(forceAccording));
        form.append('reasonForce', reasonForce);
        form.append('token', token);
        form.append('checkFullReport', JSON.stringify(checkFullReport));
        form.append('dataTableInclude', dataTableInclude);
        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    invalidate(iso: IsothermalCharacterization, token: string): Observable<any> {
        const url = environment.calibratesUrl + `/isothermalCharacterization/${iso.id}/invalidate/`;

        return this.http.put(url, { token }).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    delete(id: number): Observable<any> {
        const url = environment.calibratesUrl + `/isothermalCharacterization/${id}`;

        return this.http.delete(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadPdf(id: number, config: IsothermalCharacterizationReportFilter = new IsothermalCharacterizationReportFilter()):
        Observable<any> {
        const url = environment.calibratesUrl + `/report/isothermalCharacterization/${id}`;

        const headers = new HttpHeaders({
            lang: config.lang
        });

        const data = {
            toSign: config.toSign,
            dataTableInclude: config.dataTableInclude,
            reason: config.reason
        };

        return this.http.post(url, data, { headers, responseType: 'blob' });
    }

    downloadPdfIsothermalCharacterizationVersion(isothermalCharacterization: IsothermalCharacterization): Observable<any> {
        return this.downloadPdfVersion(isothermalCharacterization.id, isothermalCharacterization.currentVersion);
    }

    downloadPdfVersion(idProtocol: number, version: string): Observable<any> {
        const url = environment.calibratesUrl + `/report/isothermalCharacterization/${idProtocol}/version/${version}`;

        return this.http.post(url, {}, { responseType: 'blob' });
    }

    downloadReportFullPdf(id: number, config = new IsothermalCharacterizationReportFilter()):
        Observable<any> {
        const url = environment.calibratesUrl + `/report/isothermalCharacterization/${id}/full`;

        const data = {
            toSign: config.toSign,
            dataTableInclude: config.dataTableInclude,
            reason: config.reason};

        return this.http.post(url, data).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    downloadAuditPdf(id: number): Observable<any> {
        const url = environment.calibratesUrl + `/report/isothermalCharacterization/${id}/audit`;

        return this.http.get(url, { responseType: 'blob' });
    }

    getAudit(id: number, filter: IsothermalCharacterizationAuditFilter): Observable<any> {
        const url = environment.calibratesUrl + `/isothermalCharacterization/${id}/audit`;

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    discardTime(idIsothermalCharacterization: number, idEssay: number, startDate: Date, endDate: Date): Observable<any> {
        const url = environment.calibratesUrl + `/isothermalCharacterization/${idIsothermalCharacterization}/essay/${idEssay}/discardTime`;

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: { startDate, endDate }
        };

        return this.http.delete(url, options).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    revertSign(id: number, reason: string, token: string): Observable<any> {
        const url = environment.calibratesUrl + `/isothermalCharacterization/${id}/revertSign`;

        const form = new FormData();
        form.append('token', token);
        form.append('reason', reason);

        return this.http.put(url, form).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    correctSensors(id: number, idEssayVariable: number, checks: number[]): Observable<any> {
        const url = environment.calibratesUrl + `/isothermalCharacterization/${id}/essay/${idEssayVariable}/correctSensors`;

        return this.http.put(url, checks).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    checkCopyToGroup(id: number, idGroup: number): Observable<any> {
        const url = environment.calibratesUrl + `/isothermalCharacterization/clonate/${id}/toGroup/${idGroup}/check`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    copyToGroup(id: number, idGroup: number): Observable<any> {
        const url = environment.calibratesUrl + `/isothermalCharacterization/clonate/${id}/toGroup/${idGroup}`;

        return this.http.put(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    cloneCharacterization(id: number): Observable<any> {
        const url = environment.calibratesUrl + `/isothermalCharacterization/clonate-characterization/${id}`;

        return this.http.post(url, {}).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
