<div class="main-container">
  <form #clientListForm="ngForm">

    <div class="toolbar-list">

      <div class="toolbar-list-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <h1>{{ 'clientList.title' | translate }}</h1>
        </div>
        <div class="breadcrumbs">
          <a href="#">{{ 'common.start.label' | translate }}</a> /
          <span>{{ 'clientList.title' | translate }}</span>
        </div>
      </div>

      <div class="toolbar-list-actions">
        <button mat-raised-button (click)="new()">
          <mat-icon fxHide.lt-sm>add</mat-icon> {{ 'button.new' | translate }}
        </button>

        <button mat-raised-button (click)="massiveUpload.click()">{{ 'button.massive-create' | translate }}</button>
        <input accept=".xlsx, .xls, .csv" hidden type="file" (change)="uploadMassive($event)" #massiveUpload>
      </div>
      <div class="toolbar-back-button">
        <a (click)="cancel()" mat-button>
          <mat-icon>undo</mat-icon> {{ 'button.back' | translate }}
        </a>
      </div>
    </div>

    <app-client-list-filter (filterEmitter)="doFilter($event)" class="filter-container">
    </app-client-list-filter>

    <div class="table-container">

      <table mat-table matSort [dataSource]="dataSource">

        <!-- name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'clientList.cols.name' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)">
            {{ item.name }}
          </td>
        </ng-container>

        <!-- address Column -->
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'clientList.cols.address' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)">
            {{ item.address }}
          </td>
        </ng-container>

        <!-- postalCode Column -->
        <ng-container matColumnDef="postalCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'clientList.cols.postalCode' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)">
            {{ item.postalCode }}
          </td>
        </ng-container>

        <!-- city Column -->
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'clientList.cols.city' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)">
            {{ item.city }}
          </td>
        </ng-container>

        <!-- state Column -->
        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'clientList.cols.state' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)">
            {{ item.state }}
          </td>
        </ng-container>

        <!-- country Column -->
        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'clientList.cols.country' | translate }} </th>
          <td mat-cell *matCellDef="let item" [ngClass]="highlight(item)">
            {{ item.country }}
          </td>
        </ng-container>

        <!-- Button edit Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef colspan="2" fxHide.lt-lg>
            {{ 'clientList.cols.actions' | translate }} </th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button (click)="editRow(element.id)" matTooltip="{{ 'button.edit' | translate }}">
              <mat-icon>mode_edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button activate Column -->
        <ng-container matColumnDef="activate">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"></th>
          <td mat-cell *matCellDef="let element" fxHide.lt-lg class="action">
            <button mat-icon-button *ngIf="!element.active" (click)="activate(element)" matTooltip="{{ 'clientList.cols.activate' | translate }}">
              <mat-icon class="deactivate">clear</mat-icon>
            </button>
            <button mat-icon-button *ngIf="element.active" (click)="deactivate(element)" matTooltip="{{ 'clientList.cols.deactivate' | translate }}">
              <mat-icon class="activate">done</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Button more Column -->
        <ng-container matColumnDef="more" stickyEnd>
          <th mat-header-cell *matHeaderCellDef fxHide fxShow.lt-lg></th>
          <td mat-cell *matCellDef="let element" fxHide fxShow.lt-lg class="action">

            <mat-menu #appMenu="matMenu" class="moreActions">
              <ng-template matMenuContent let-name="element">
                <button mat-button (click)="editRow(element.id)">
                  <mat-icon>mode_edit</mat-icon> {{ 'button.edit' | translate }}
                </button>
                <button mat-button *ngIf="!element.active" (click)="activate(element)">
                  <mat-icon class="deactivate">clear</mat-icon> {{ 'clientList.cols.activate' | translate }}
                </button>
                <button mat-button *ngIf="element.active" (click)="deactivate(element)">
                  <mat-icon class="activate">done</mat-icon> {{ 'clientList.cols.deactivate' | translate }}
                </button>
              </ng-template>
            </mat-menu>

            <button mat-icon-button [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="element">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </div>

    <div class="table-footer-container">
      <div class="actions">
        <button mat-raised-button (click)="exportTable()" class="stroked-primary">
          <mat-icon fxHide.lt-sm>cloud_download</mat-icon> {{ 'button.downloadXls' | translate }}
        </button>
        <a [routerLink]="['.']" [fragment]="'table-container'" class="back-to-top">
          {{ 'button.backToTop' | translate }} </a>
      </div>
      <div class="paginator">
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                       [length]="dataSource.totalItems$ | async" lang="es">
        </mat-paginator>
      </div>
    </div>
  </form>
</div>
