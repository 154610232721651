<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<form>

  <div mat-dialog-content [class]="getTheme()" style="overflow-y: hidden;">

    <mat-form-field class="campoFormulario" *ngIf="showChangeLangIcon()">
      <mat-label>{{ 'common.reportType.options.language.label' | translate }}</mat-label>
      <mat-select [(ngModel)]="lang" [ngModelOptions]="{standalone: true}">
        <mat-option *ngFor="let item of langs" [value]="item.lang">
          <mat-icon svgIcon="{{item.flag}}"></mat-icon>
          {{item.fullLang}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <br /><br />

  <div mat-dialog-actions [class]="getTheme()">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()" cdkFocusInitial>
      {{ 'button.accept' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>