import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';

@Injectable()
export class OldBrowserInterceptor implements CanActivate {

    constructor(private router: Router) { }

    canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        const valid = !this.isIE();

        if (!valid) {
            void this.router.navigate(['/old-browser']);
            return false;
        } else {
            return true;
        }
    }

    isIE(): boolean {
        const ua = window.navigator.userAgent;

        return /MSIE|Trident/.test(ua);
    }
}
