import * as moment from 'moment';

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AppTypeEnum } from 'src/app/model/appType';
import { Client } from 'src/app/model/client';
import { ClientService } from 'src/app/services/client.service';
import { CustomDatepickerHeaderComponent } from '../../shared/datepicker-custom-header/datepicker-custom-header.component';
import { GenericClass } from 'src/app/model/genericClass';
import { IsothermalCharacterizationFilter } from 'src/app/model/isothermalCharacterization';
import { IsothermalCharacterizationStatusService } from 'src/app/services/isothermalCharacterizationStatus.service';
import { MyErrorStateMatcher } from 'src/app/utils/errorStateMatcher';
import { Subject } from 'rxjs';
import { User } from 'src/app/model/user';
import { UsersService } from 'src/app/services/users.service';
import { takeUntil } from 'rxjs/operators';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';


import { MatDatepickerInputEvent } from '@angular/material/datepicker';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};


@Component({
  selector: 'app-isothermal-characterization-list-filter',
  templateUrl: './isothermal-characterization-list-filter.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class IsothermalCharacterizationListFilterComponent implements OnInit, OnDestroy {

  @Output() filterEmitter = new EventEmitter<IsothermalCharacterizationFilter>();

  filter: IsothermalCharacterizationFilter;

  clients: Client[];
  clientsFiltered: Client[];

  statuses: GenericClass[];
  users: User[];
  projectManagers: User[];

  expandedFilter = true;

  matcher = new MyErrorStateMatcher();
  form: FormGroup;

  customDatepickerHeader = CustomDatepickerHeaderComponent;

  private destroy$ = new Subject<void>();

  constructor(
    fb: FormBuilder,
    private clientService: ClientService,
    private isothermalCharacterizationStatusService: IsothermalCharacterizationStatusService,
    private usersService: UsersService) {
    this.cleanFilter();

    this.form = fb.group({
      idClient: [this.filter.idClient],
      documentCode: [this.filter.documentCode],
      clientDocumentCode: [this.filter.clientDocumentCode],
      idStatus: [this.filter.idStatus],

      regUser: [this.filter.regUser],

      projectNo: [this.filter.projectNo],

      regFcStart: [this.filter.regFcStart],
      modFcStart: [this.filter.modFcStart],
      regFcEnd: [this.filter.regFcEnd],
      modFcEnd: [this.filter.modFcEnd],

      realizationFcStart: [this.filter.realizationFcStart],
      realizationFcEnd: [this.filter.realizationFcEnd],

      workStartFcStart: [this.filter.workStartFcStart],
      workStartFcEnd: [this.filter.workStartFcEnd],
      workEndFcStart: [this.filter.workEndFcStart],
      workEndFcEnd: [this.filter.workEndFcEnd],

      equipment: [this.filter.equipment],
      equipmentMaker: [this.filter.equipmentMaker],
      equipmentModel: [this.filter.equipmentModel],
      equipmentSerialNum: [this.filter.equipmentSerialNum],
      equipmentIdInternal: [this.filter.equipmentIdInternal],

      department: [this.filter.department]
    });
  }

  ngOnInit(): void {
    this.clients = [];
    this.clientService.findAll().pipe(takeUntil(this.destroy$)).subscribe((data: Client[]) => {
      const empty = new Client();
      empty.id = null;
      empty.name = '---';

      data = [empty].concat(data);
      this.clients = data;
      this.clientsFiltered = this.clients.slice();
    });

    this.statuses = [];
    this.isothermalCharacterizationStatusService.findAll().pipe(takeUntil(this.destroy$))
      .subscribe((data: GenericClass[]) => this.statuses = data);

    this.users = [];
    this.usersService.findAllFromGroupAppType(AppTypeEnum.CALIBRATES).pipe(takeUntil(this.destroy$))
      .subscribe((data: User[]) => this.users = data);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  doFilter(): void {
    this.expandedFilter = false;

    this.filterEmitter.emit(this.filter);
  }

  cleanFilter(): void {
    this.filter = new IsothermalCharacterizationFilter();
  }

  saveDate(field: string, event: MatDatepickerInputEvent<Date>): void {

    let dateValue = event.value;
    if (dateValue != null) {
      /*while (dateValue.getFullYear() < 1970) {
       dateValue.setFullYear(dateValue.getFullYear() + 100);
       }*/
      this.filter[field] = new Date(dateValue);
    } else {
      this.filter[field] = null;
    }
  }

}
