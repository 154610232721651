import {
    Calibration,
    CalibrationAsFound,
    CalibrationAsLeft,
    CalibrationAsLeftValue,
    CalibrationVariable,
    ExternalCalibration,
    ExternalCalibrationValue,
    ExternalCalibrationVariable
} from '../model/calibration';

import { InternalEquipment } from '../model/internalEquipment';
import { StringUtils } from './stringUtils';
import { TranslateService } from '@ngx-translate/core';
import { VariableTypeEnum } from '../model/variable';

export class CalibrationUtils {

    static objectToAsFound(item: CalibrationAsFound | any): CalibrationAsFound {

        if (item instanceof CalibrationAsFound) {
            return item;
        } else {
            const s = new CalibrationAsFound();

            for (const prop in item) {
                if (prop !== 'values') {
                    s[prop] = item[prop];
                }
            }

            return s;
        }
    }

    static objectToAsLeft(item: CalibrationAsLeft | any): CalibrationAsLeft {

        if (item instanceof CalibrationAsLeft) {
            return item;
        } else {
            const s = new CalibrationAsLeft();

            for (const prop in item) {
                if (prop !== 'values') {
                    s[prop] = item[prop];
                }
            }

            const vals: CalibrationAsLeftValue[] = [];
            item.values.forEach(valu => {
                const d = new CalibrationAsLeftValue();

                d.id = valu.id;
                d.value = valu.value;

                vals.push(d);
            });

            s.values = vals;

            return s;
        }
    }

    static objectToExternalCalibration(item: ExternalCalibration | any): ExternalCalibration {
        if (item instanceof ExternalCalibration) {
            return item;
        } else {
            const extCalib = new ExternalCalibration();

            for (const prop in item) {
                if (prop !== 'variables') {
                    extCalib[prop] = item[prop];
                }
            }

            const vals: ExternalCalibrationVariable[] = [];
            item.variables.forEach(valu => {
                const extVariable = new ExternalCalibrationVariable();

                for (const prop in valu) {
                    if (prop !== 'values') {
                        extVariable[prop] = valu[prop];
                    }
                }

                const values: ExternalCalibrationValue[] = [];
                valu.values.forEach(vl => {
                    const v = new ExternalCalibrationValue();

                    v.id = vl.id;
                    v.average = vl.average;
                    v.point = vl.point;
                    v.uncertainty = vl.uncertainty;

                    values.push(v);
                });
                extVariable.values = values;

                vals.push(extVariable);
            });

            extCalib.variables = vals;

            return extCalib;
        }
    }

    public static getNameForCalibration(calibration: Calibration, eq: InternalEquipment, translate: TranslateService): string {
        const documentCode = calibration.certificateNum;
        const versionName = 'v.' + calibration.currentVersion.toString().padStart(2, '0');
        let equipment = null;
        let serialNum = null;
        let clientName = null;
        let identificative = null;

        if (eq != null) {
            equipment = eq.equipment;

            if (StringUtils.isNotEmpty(eq.serialNum)) {
                serialNum = '(' + eq.serialNum + ')';
            }

            if (eq.client != null) {
                clientName = eq.client.name;
            }

            if (eq.name != null) {
                identificative = '(' + eq.name + ')';
            }
        }

        const name = [documentCode, versionName, clientName, equipment, identificative, serialNum]
            .filter((s: string) => StringUtils.isNotEmpty(s)).join(' ');

        return translate.instant('calibrateEquipmentEdit.files.certificate', { name }) as string;
    }

    public static showExtraField(variable: CalibrationVariable): boolean {
        return this.getExtraFieldName(variable) != null;
    }

    public static getExtraFieldName(variable: CalibrationVariable): string {
        const res: string = null;

        /*
        if (variable.idVariable === VariableTypeEnum.SPEED) {
            res = 'radius';
        }
        */

        return res;
    }

}
