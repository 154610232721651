<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form>
  <div mat-dialog-content class="air-theme">

    <div fxLayout="row" class="flexwrap">
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <input matInput [(ngModel)]="data.area.name" name="name" required
            placeholder="{{ 'protocolEdit.dialog.area.name.label' | translate }}">
          <mat-error>
            {{ 'protocolEdit.dialog.area.name.error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" fxFlex.lt-md="100%" *ngIf="data.isExecution && data.area.fromProtocol">
        <mat-form-field class="campoFormulario">
          <input matInput [ngModel]="data.area.nameProtocol" name="nameProtocol" disabled
            placeholder="{{ 'protocolEdit.dialog.area.name.label' | translate }}  {{ 'common.inProtocol' | translate }}">
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="column" class="flexwrap" *ngIf="data.isExecution && data.area.fromProtocol && showReason()">
      <div fxFlex>
        <mat-form-field class="campoFormulario">
          <textarea matInput name="reasonChange" [(ngModel)]="data.area.reasonChange" required
            placeholder="{{ 'protocolEdit.dialog.confirmSave.reason.label' | translate }}">
            <mat-error>
              {{ 'common.reasonChangeError' | translate }}
            </mat-error>
          </textarea>
        </mat-form-field>
      </div>
      <div fxFlex>
        <mat-checkbox name="showDiffInDeviations" [(ngModel)]="data.area.showDiffInDeviations">
          {{ 'executionEdit.sensorConfig.showDiffInDeviations.label' | translate }}
        </mat-checkbox>
      </div>
      <div fxFlex>
        <mat-checkbox name="showDiffInObservations" [(ngModel)]="data.area.showDiffInObservations">
          {{ 'executionEdit.sensorConfig.showDiffInObservations.label' | translate }}
        </mat-checkbox>
      </div>
    </div>

    <div fxLayout="row" class="flexwrap">
      <div fxFlex *ngIf="data.isExecution && !data.withoutProtocol && !data.isEdit">
        <textarea rows="5" matAutosizeMinRows="5" matInput
          placeholder="{{ 'executionEdit.sensorConfig.addingReason.label' | translate }}"
          [(ngModel)]="data.area.reaseonChange" required style="resize: none;"></textarea>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>