import { EssayFile } from './essayFile';

export class FilterAir {
    id: number;

    idEquipment: number;
    idRoom: number;
    idType: number;
    idTypeFilter: number;

    height: number;
    length: number;
    width: number;
    diameter: number;

    shape: number;
    filterName: string;

    idOffline: string;
    idOfflineDexie: string;
    totalFiltersSecondStage = 0;

    maker: string;
    serialNum: string;
    idCertificate: number;
    reasonChangeFilter: string;

    essayFile: EssayFile;
}

export enum FilterAirTypeEnum {
    FILTER = 1,
    DIFFUSER = 2,
    IMPULSION_RACK = 3,
    FILTER_WITH_DIFFUSER = 4,
    EXTRACTION_RACK = 5
}

export enum FilterAirEnum {
    HEPA_IMPULSION = 1,
    HEPA_EXTRACTION_1 = 2,
    HEPA_EXTRACTION_2 = 3,
    VEILED_FILTERS = 5,
    ACTIVATED_CARBON = 6,
    PREFILTERS = 7
}

export enum ShapeEnum {
    RECTANGULAR = 1,
    CIRCULAR = 2
}
