import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ThemeService } from 'src/app/services/theme.service';

export interface DialogDataReason {
  title: string;
  reason: string;
}

@Component({
  selector: 'app-reason-dialog',
  templateUrl: './reason-dialog.component.html'
})
export class ReasonDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<ReasonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataReason,
    private themeService: ThemeService,
    private translate: TranslateService,
    public snackBarService: SnackBarService) {
    if (data.title == null) {
      data.title = 'common.reasonChange';
    }
  }

  onOkClick(): void {
    const errs = [];

    if (this.data.reason == null || this.data.reason === '') {
      errs.push(this.translate.instant('common.reasonChangeError'));
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.reason);
    } else {
      const error = errs.join('\n');
      this.snackBarService.sendError(error);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTheme(): string {
    return this.themeService.getTheme();
  }

}
