import { Location, ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router, Scroll } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { merge, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Equipment } from 'src/app/model/equipment';
import { GenericWarnError } from 'src/app/model/genericClass';
import { Protocol, ProtocolFilter, ProtocolStatus } from 'src/app/model/protocol';
import { ProtocolsDataSource } from 'src/app/model/protocolDataSource';
import { User } from 'src/app/model/user';
import { OnlineService } from 'src/app/services/online.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ThemeService } from 'src/app/services/theme.service';
import { UserService } from 'src/app/services/user.service';
import { Constants } from 'src/app/utils/constants';
import { EquipmentUtils } from 'src/app/utils/equipmentUtils';
import { ProtocolService } from '../../../services/protocol.service';
import { CheckWarnsErrorsComponent } from '../../shared/check-warns-errors/check-warns-errors.component';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { SelectGroupToCloneComponent } from '../../shared/select-group-to-clone/select-group-to-clone.component';
import { ProtocolEditGenerateReportComponent } from '../protocol-edit/protocol-edit-generate-report.component';
import { ProtocolListFilterComponent } from './protocol-list-filter.component';

export interface DialogDataProtocolListConfig {
  isList: boolean;
}

@Component({
  selector: 'app-protocol-list',
  templateUrl: './protocol-list.component.html'
})
export class ProtocolListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild('filterComponent', { static: true }) filterComponent: ProtocolListFilterComponent;

  dataSource: ProtocolsDataSource;
  filter: ProtocolFilter = new ProtocolFilter();

  currentUser: User;

  disableOfflineButton = false;

  hasFiltered = false;

  displayedColumns = [
    'documentCode', 'projectNo', 'client', 'equipment', 'serialNum', 'internalId', 'status', 'department', 'realizationDate'
  ];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private location: Location,
    private protocolService: ProtocolService,
    private onlineService: OnlineService,
    private userService: UserService,
    private translate: TranslateService,
    private themeService: ThemeService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private viewportScroller: ViewportScroller,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataProtocolListConfig,
    public dialogRef: MatDialogRef<ProtocolListComponent>) {

    if (data.isList == null) {
      data.isList = true;
    }

    this.router.events.pipe(filter(e => e instanceof Scroll)).pipe(takeUntil(this.destroy$)).subscribe((e: Scroll) => {
      setTimeout(() => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
    });
  }

  get pageTitle(): string {
    return this.translate.instant('protocolList.title') as string;
  }

  ngOnInit(): void {
    this.currentUser = this.userService.currentProfile;

    if (this.data.isList) {
      this.displayedColumns.push('download', 'edit', 'createNewExecution', 'delete', 'clone', 'more');
    } else {
      this.displayedColumns.push('select');
      this.filterComponent.isList = this.data.isList;
    }

    this.onlineService.online$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.disableOfflineButton = !res;

      this.loadProtocolList();
    });

    this.disableOfflineButton = !this.onlineService.latestOnline;

    const excludedCols: string[] = [];

    if (!this.currentUser.showClone) {
      excludedCols.push('clone');
    }

    this.displayedColumns = this.displayedColumns.filter(c => !excludedCols.includes(c));
    const filterResult = localStorage.getItem('filter');
    if (filterResult) {
      this.hasFiltered = true;
      this.filter = JSON.parse(filterResult) as ProtocolFilter;
    }
    this.loadProtocolList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadProtocolList())).pipe(takeUntil(this.destroy$)).subscribe();
  }

  new(): void {
    void this.router.navigateByUrl('/thermal/protocol?id=0');
  }

  editRow(id: number): void {
    void this.router.navigateByUrl(`/thermal/protocol?id=${id}`);
  }

  deleteRow(id: number): void {
    if (id < 0) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.translate.instant('protocolList.form.delete.confirm') as string
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result === true) {
        this.spinnerService.show();

        this.protocolService.delete(id).pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.loadProtocolList();

          this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.delete.ok') as string);

          this.spinnerService.hide();
        }, () => {
          this.spinnerService.hide();

          this.snackBarService.sendError(this.translate.instant('protocolEdit.form.delete.error') as string);
        });
      }
    });

  }

  canClone(prot: Protocol): boolean {
    return prot?.idStatus === ProtocolStatus.FIRMADO;
  }

  cloneRow(id: number): void {
    const dialogRefSelectGroupToClone = this.dialog.open(SelectGroupToCloneComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRefSelectGroupToClone.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((idGroup: number) => {
      if (idGroup != null) {

        this.spinnerService.show();

        this.protocolService.checkCopyToGroup(id, idGroup).subscribe((res: GenericWarnError) => {
          this.spinnerService.hide();

          const dialogRef = this.dialog.open(CheckWarnsErrorsComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {
              warns: res.warns,
              errors: res.errors
            }
          });

          dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((canContinue: boolean) => {
            if (canContinue === true) {
              this.spinnerService.show();
              this.protocolService.copyToGroup(id, idGroup).subscribe(() => {
                this.spinnerService.hide();

                this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.clone.ok') as string);
              }, () => {
                this.spinnerService.hide();
                this.snackBarService.sendError(this.translate.instant('protocolEdit.form.clone.error') as string);
              });
            }
          });
        }, () => {
          this.spinnerService.hide();
          this.snackBarService.sendError(this.translate.instant('protocolEdit.form.clone.error') as string);
        });
      }
    });
  }

  getThemeIconPath(): string {
    const url = this.router.url;
    return this.themeService.getThemeIconPathSelection(url);
  }

  doFilter(event: ProtocolFilter): void {
    this.filter = event;

    this.hasFiltered = true;

    this.loadProtocolList();
  }

  loadProtocolList(): void {
    this.dataSource = new ProtocolsDataSource(this.protocolService);

    this.filter.sortBy = this.sort.active || 'documentCode';
    this.filter.sortDirection = this.sort.direction || 'desc';
    this.filter.pageIndex = this.paginator?.pageIndex || 0;
    this.filter.pageSize = this.paginator?.pageSize || 10;

    if (this.hasFiltered) {
      this.saveSearchFilter(this.filter);
      this.dataSource.loadProtocols(this.filter);
    }
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  downloadPdf(protocol: Protocol): void {
    this.dialog.open(ProtocolEditGenerateReportComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: { protocol }
    });
  }

  canCreateNewExecution(prot: Protocol): boolean {
    const allowedStatuses = [ProtocolStatus.FIRMADO];

    return allowedStatuses.includes(prot.idStatus);
  }

  createNewExecution(prot: Protocol): void {
    if (prot?.id != null) {
      void this.router.navigateByUrl(`thermal/execution?id=0&${Constants.FIELD_ID_PROTOCOL}=${prot.id}`);
    }
  }

  showEquipment(eqs: Equipment[]): string {
    return EquipmentUtils.showEquipmentsShort(eqs);
  }

  showSerialNumEquipment(eqs: Equipment[]): string {
    const res = [];
    eqs.forEach(item => {
      res.push(item.serialNum);
    });

    return res.join('\n');
  }

  showInternalIdEquipment(eqs: Equipment[]): string {
    const res = [];
    eqs.forEach(item => {
      res.push(item.internalId);
    });

    return res.join('\n');
  }

  canDelete(prot: Protocol): boolean {
    const notAllowedStatuses = [ProtocolStatus.FIRMADO];

    return !notAllowedStatuses.includes(prot.idStatus) && prot.usedIn === 0;
  }

  canSelect(prot: Protocol): boolean {
    const allowedStatuses = [ProtocolStatus.FIRMADO];

    return allowedStatuses.includes(prot.idStatus);
  }

  exportTable(): void {
    this.filter.cols = this.displayedColumns;

    this.spinnerService.show();
    this.protocolService.getExcel(this.filter).pipe(takeUntil(this.destroy$)).subscribe((res: Blob) => {
      const name = this.pageTitle + '.xlsx';
      saveAs(res, name);
      this.spinnerService.hide();
    }, error => {
      console.error(error);
      this.spinnerService.hide();
    });
  }

  cancel(): void {
    this.location.back();
  }

  onProtocolSelect(id: number): void {
    if (this.dialogRef) {
      this.dialogRef.close(id);
    }
  }

  saveSearchFilter(execution: ProtocolFilter): void {
    localStorage.setItem('filter', JSON.stringify(execution));
  }

}
