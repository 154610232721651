<div mat-dialog-content [class]="getTheme()" style="white-space: pre-line;">
  {{ data.message }}
</div>
<div mat-dialog-actions [class]="getTheme()">
  <div fxFlex></div>
  <button mat-raised-button color="primary" (click)="onOkClick()" *ngIf="data.canOk">
    {{ data.messageOk }}</button>
  <button mat-raised-button color="secondary" (click)="onNoClick()" *ngIf="data.canCancel">
    {{ data.messageCancel }}
  </button>
</div>