import { GenericClass, GenericFilter } from './genericClass';

export class AppPlanType extends GenericClass {

    idsPhasesAllowed: number[];

    maxUsers: number;
    maxExecutions: number;
    canGeneratePreReport: boolean;
    canManageUsers: boolean;

    showInPayments: boolean;

    initialPayment: number;
    monthlyPayment: number;
}

export class AppPlanFilter extends GenericFilter {
    name: string;
}
