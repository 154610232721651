import { Component, OnInit, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sensor } from 'src/app/model/sensor';
import { Subject } from 'rxjs';
import { GenericClass } from 'src/app/model/genericClass';
import { BioindicatorResponsibleService } from 'src/app/services/bioindicatorResponsible.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

export interface DialogDataProtocolBioIndicators {
  sensors: Sensor[];
  responsible: number;
  type: string;
}

@Component({
  selector: 'app-protocol-edit-bioindicators-details',
  templateUrl: './protocol-edit-bioindicators-details.component.html'
})
export class ProtocolEditBioindicatorsDetailsComponent implements OnInit, OnDestroy {

  bioCount: number;
  checked = new Map<string, boolean>();

  bioindicatorResponsibles: GenericClass[];

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogDataProtocolBioIndicators,
    public dialogRef: MatDialogRef<ProtocolEditBioindicatorsDetailsComponent>,
    private bioindicatorResponsibleService: BioindicatorResponsibleService,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    private cd: ChangeDetectorRef) {
    if (data.type == null) {
      data.type = 'bioindicator';
    }
  }

  ngOnInit(): void {
    if (this.data.sensors == null || this.data.sensors.length === 0) {
      this.snackBarService.sendError(
        this.translate.instant(`protocolEdit.dialog.${this.data.type}s.form.error.sensorsNotConfig`) as string);

      this.dialogRef.close();
    }

    this.bioindicatorResponsibleService.findAll().pipe(takeUntil(this.destroy$))
      .subscribe((res: GenericClass[]) => this.bioindicatorResponsibles = res);

    this.bioCount = this.data.sensors.length;
    this.checked = new Map<string, boolean>();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChangeCheck(event: MatCheckboxChange): void {
    const sensor = event.source.value as unknown as Sensor;

    this.checked.set(sensor.location, event.checked);
  }

  resetChecks(): void {
    this.checked.forEach((value, key) => this.checked.set(key, false));
    this.cd.detectChanges();
  }

  disableChecks(sensor: Sensor): boolean {
    const checks = [];

    this.checked.forEach((value, key) => {
      if (key === sensor.location) {
        return false;
      }

      if (value) {
        checks.push(key);
      }
    });

    return checks.length >= this.bioCount;
  }

  onOkClick(): void {

    if (this.data.sensors.length < this.bioCount) {
      this.snackBarService.sendError(this.translate.instant(`protocolEdit.dialog.${this.data.type}s.form.error.atLeastOne`,
        { count: this.data.sensors.length }) as string);
      return;
    } else if (1 > this.bioCount) {
      this.snackBarService.sendError(this.translate.instant(`protocolEdit.dialog.${this.data.type}s.form.error.atLeastOne`) as string);
      return;
    } else if (this.data.sensors.length === this.bioCount) {
      this.data.sensors.forEach(sen => this.markSensor(sen, true));
    } else {
      const checks = [];

      this.checked.forEach((value, key) => {
        if (value) {
          checks.push(key);
        }
      });

      if (checks.length !== this.bioCount) {
        this.snackBarService.sendError(this.translate.instant(`protocolEdit.dialog.${this.data.type}s.form.error.lengthCheck`,
          { count: this.bioCount }) as string);

        return;
      }

      this.data.sensors.forEach(sen => sen = this.markSensor(sen, checks.includes(sen.location)));
    }

    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private markSensor(sen: Sensor, value: boolean): Sensor {

    if (this.data.type === 'endotoxin') {
      sen.hasEndotoxin = value;
    } else {
      sen.hasBioindicator = value;
    }

    return sen;
  }

}
