import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ProtocolQ, ProtocolQFilter } from 'src/app/model/protocol';
import { ProtocolsQDataSource } from 'src/app/model/protocolQDataSource';
import { ProtocolQService } from 'src/app/services/protocolQ.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ProtocolQEditGenerateReportComponent } from '../protocol-edit/protocol-q-edit-generate-report/protocol-q-edit-generate-report.component';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ProtocolStatus } from '../../../model/protocol';
import { CheckWarnsErrorsComponent } from '../../shared/check-warns-errors/check-warns-errors.component';
import { GenericWarnError } from '../../../model/genericClass';
import { SelectGroupToCloneComponent } from '../../shared/select-group-to-clone/select-group-to-clone.component';

export interface DialogDataProtocolListConfig {
  isList: boolean;
}

@Component({
  selector: 'app-protocol-q-list',
  templateUrl: './protocol-q-list.component.html'
})
export class ProtocolQListComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  filter: ProtocolQFilter = new ProtocolQFilter();
  hasFiltered = false;
  dataSource: ProtocolsQDataSource;
  trueProtocol: ProtocolQ;

  displayedColumns = ['documentCode', 'projectNo', 'client', 'status', 'realizationDate', 'download', 'edit', 'delete', 'clone'];

  private destroy$ = new Subject<void>();

  constructor(
    private protocolService: ProtocolQService,
    private router: Router,
    private spinnerService: SpinnerService,
    public dialog: MatDialog,
    private translate: TranslateService,
    public snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataProtocolListConfig
  ) {
    if (data.isList == null) {
      data.isList = true;
    }
  }

  ngOnInit(): void {
    this.loadProtocolList();

  }

  doFilter(event: ProtocolQFilter): void {
    this.filter = event;

    this.hasFiltered = true;

    this.loadProtocolList();
  }

  loadProtocolList(): void {
    this.dataSource = new ProtocolsQDataSource(this.protocolService);

    this.filter.sortBy = this.sort.active || 'documentCode';
    this.filter.sortDirection = this.sort.direction || 'desc';
    this.filter.pageIndex = this.paginator?.pageIndex || 0;
    this.filter.pageSize = this.paginator?.pageSize || 10;
    if (this.hasFiltered) {
      this.saveSearchFilter(this.filter);
      this.dataSource.loadProtocols(this.filter);
    }
    this.dataSource.loading$.pipe(takeUntil(this.destroy$)).subscribe(message => this.spinnerService.next(message));
  }

  saveSearchFilter(execution: ProtocolQFilter): void {
    localStorage.setItem('filter', JSON.stringify(execution));
  }

  canClone(prot: ProtocolQ): boolean {
    return prot?.idStatus === ProtocolStatus.FIRMADO;
  }

  editRow(id: number): void {
    void this.router.navigateByUrl(`/qualificates/protocol?id=${id}`);
  }

  new(): void {
    void this.router.navigateByUrl('/qualificates/protocol?id=0');
  }

  downloadPdf(protocol: ProtocolQ): void {
    this.spinnerService.show();
    this.protocolService.findOne(protocol.id).pipe(takeUntil(this.destroy$)).subscribe(result => {
      protocol = result;    
      this.spinnerService.hide();

      this.dialog.open(ProtocolQEditGenerateReportComponent, {
        minWidth: '20%',
        maxHeight: '95vh',
        data: { protocol }
      });
    });
  }

  deleteRow(id: number): void {
    if (id < 0) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.translate.instant('protocolList.form.delete.confirm') as string
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result === true) {
        this.spinnerService.show();

        this.protocolService.delete(id).pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.loadProtocolList();

          this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.delete.ok') as string);

          this.spinnerService.hide();
        }, () => {
          this.spinnerService.hide();

          this.snackBarService.sendError(this.translate.instant('protocolEdit.form.delete.error') as string);
        });
      }
    });
  }

  cloneRow(id: number): void {
    const dialogRefSelectGroupToClone = this.dialog.open(SelectGroupToCloneComponent, {
      minWidth: '20%',
      maxHeight: '95vh',
      data: {}
    });

    dialogRefSelectGroupToClone.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((idGroup: number) => {
      if (idGroup != null) {

        this.spinnerService.show();

        this.protocolService.checkCopyToGroup(id, idGroup).subscribe((res: GenericWarnError) => {
          this.spinnerService.hide();

          const dialogRef = this.dialog.open(CheckWarnsErrorsComponent, {
            minWidth: '20%',
            maxHeight: '95vh',
            data: {
              warns: res.warns,
              errors: res.errors
            }
          });

          dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((canContinue: boolean) => {
            if (canContinue === true) {
              this.spinnerService.show();
              this.protocolService.copyToGroup(id, idGroup).subscribe(() => {
                this.spinnerService.hide();

                this.snackBarService.sendSuccess(this.translate.instant('protocolEdit.form.clone.ok') as string);
              }, () => {
                this.spinnerService.hide();
                this.snackBarService.sendError(this.translate.instant('protocolEdit.form.clone.error') as string);
              });
            }
          });
        }, () => {
          this.spinnerService.hide();
          this.snackBarService.sendError(this.translate.instant('protocolEdit.form.clone.error') as string);
        });
      }
    });
  }

}
